import { mockAgency } from '../../utils/mock-agency';
import { mockCurrency } from '../../utils/mock-currency';
import { mockOutlook } from '../../utils/mock-outlook';
import { mockRattings } from '../../utils/mock-rattings';

export default function customSelectData(
  inputType: string,
  rattingType?: string,
) {
  const renderOptions = (inputType: string) => {
    let options: any = [];
    switch (inputType) {
      case 'currency':
        options = mockCurrency;
        break;
      case 'agency':
        options = mockAgency;
        break;
      case 'outlook':
        options = mockOutlook;
        break;
      case 'ratting':
        options = mockRattings(rattingType || '');
        break;
    }
    return options;
  };
  return renderOptions(inputType);
}
