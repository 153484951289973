import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { baseApi } from './api/baseApi';
import { authReducer } from './slice/authSlice';
import errorSlice from './slice/errorSlice';
import notificationSlice from './slice/notificationSlice';
import loaderSlice from './slice/loaderSlice';
import peopleFilterSlice from './slice/peopleFilterSlice';
import counterPartyFilterSlice from './slice/counterPartySlice';
import indicationFilterSlice from './slice/indicationFIlterSlice';
import modalSlice from './slice/modalSlice';
import { persistStore } from 'redux-persist';
import avatarSlice from './slice/avatarSlice';
import groupFilterSlice from './slice/groupFilterSlice';
import legalEntityFilterSlice from './slice/legalEntityFilterSlice';

export const store = configureStore({
  reducer: {
    loader: loaderSlice,
    auth: authReducer,
    error: errorSlice,
    notification: notificationSlice,
    peopleFilter: peopleFilterSlice,
    legalEntityFilter: legalEntityFilterSlice,
    groupFilter: groupFilterSlice,
    modal: modalSlice,
    counterPartyFillter: counterPartyFilterSlice,
    indicationFilter: indicationFilterSlice,
    avatar: avatarSlice,
    [baseApi.reducerPath]: baseApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      baseApi.middleware,
      //logger
    ),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
