import { createContext, useState } from "react";
import { Outlet } from "react-router-dom";
import ContentNavBar from "../Admin/ContentNavBar";
import Pipe from "../../assets/img/Pipe.svg";

export const ContextPipe = createContext<any>(null);

export default function PipeContent() {
  const [searchTxt, setSearchTxt] = useState<string | null>(null);

  return (
    <>
      <ContentNavBar
        title="Pipe"
        logo={Pipe}
        setTxt={(txt: any) => setSearchTxt(txt)}
        txt={searchTxt}
      />
      <ContextPipe.Provider value={{ txt: searchTxt }}>
        <Outlet />
      </ContextPipe.Provider>
    </>
  );
}
