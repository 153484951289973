import React, { useState } from 'react';
import { EntityDeals } from '../../../../@types/deals/deals';
import {
  useDeleteDealsMutation,
  useLazyGetDealByMandateIdQuery,
} from '../../../../redux/api/ws/deals/deals';
import {
  filterColumn,
  isCan,
  titleRequestError,
} from '../../../../utils/helper-function';
import Table from '../../../CofarcoTable/Table';
import ColumnsDealList from './ColumnDeals';
import { updateModalAction } from '../../../../redux/slice/modalSlice';
import { useDispatch } from 'react-redux';
import DeleteModal from '../../../Modal/DeleteModal';
import { updateNotificationMessage } from '../../../../redux/slice/notificationSlice';
import AddBtn from '../../../Button/AddBtn';
import { Menu } from '../../../../@types/common-types';

type IProps = {
  selectedMandateId: number | null;
};
export default function DealLimitsList(props: IProps) {
  const [getDealsByMandate, { data: dealList, isLoading }] =
    useLazyGetDealByMandateIdQuery();

  const dispatch = useDispatch();
  const [modalConfirmDelete, setModalConfirmDelete] = useState(false);
  const [selectedDeals, setSelectedDeals] = useState<EntityDeals | null>(null);

  React.useEffect(() => {
    if (props.selectedMandateId) {
      getDealsByMandate({
        id: props.selectedMandateId || 0,
      });
    }
  }, [props.selectedMandateId]);

  const [
    deletedDeals,
    {
      isLoading: isLoadingDelete,
      isSuccess: isSuccessDelete,
      isError: isErrorDelete,
      error: errorResponse,
    },
  ] = useDeleteDealsMutation();

  const handleShowModalDelete = () => {
    setModalConfirmDelete(!modalConfirmDelete);
  };

  React.useEffect(() => {
    if (isSuccessDelete) {
      handleShowModalDelete();
    }
  }, [isSuccessDelete]);

  React.useEffect(() => {
    if (isErrorDelete) {
      handleShowModalDelete();
      dispatch(
        updateNotificationMessage({
          show: true,
          title: titleRequestError,
          body: errorResponse?.data,
        }),
      );
    }
  }, [isErrorDelete]);

  const handleSorted = React.useCallback(
    (key: string, ruls: 'ASC' | 'DESC') => {
      if (props.selectedMandateId) {
        getDealsByMandate({
          id: props.selectedMandateId || 0,
          sort: `[{"property":"${key}","direction":"${ruls}"}]`,
        });
      }
    },
    [props.selectedMandateId],
  );
  const editDeal = (id: number) => {
    const deals = (dealList?.deal || []).find((el: any) => el.id === id);
    if (deals) {
      dispatch(
        updateModalAction({
          data: deals,
          isAdd: true,
          type: 'deals',
        }),
      );
    }
  };

  const handleDeleteDeal = (id: number) => {
    const foundDeals = (dealList?.deal || []).find((el) => el.id === id);
    if (foundDeals) {
      setSelectedDeals(foundDeals);
      handleShowModalDelete();
    }
  };

  const { columns, uncheckedId } = ColumnsDealList({
    deals: dealList?.deal || [],
    handleSorted,
    editDeal: editDeal,
    handleDeleteDeal: handleDeleteDeal,
  });

  const validDelete = () => {
    if (selectedDeals?.id) {
      deletedDeals({ id: selectedDeals?.id || -1 });
    }
  };

  React.useEffect(() => {
    if (isSuccessDelete) {
      handleShowModalDelete();
    }
  }, [isSuccessDelete]);

  React.useEffect(() => {
    if (isErrorDelete) {
      handleShowModalDelete();
      dispatch(
        updateNotificationMessage({
          show: true,
          title: titleRequestError,
          body: errorResponse?.data,
        }),
      );
    }
  }, [isErrorDelete]);

  const handleCreateDeals = () => {
    dispatch(
      updateModalAction({
        data: {
          mandate_id: props.selectedMandateId,
        },
        isAdd: true,
        type: 'deals',
      }),
    );
  };

  return (
    <>
      <div className="content-table">
        <Table
          dataSource={dealList?.deal || []}
          columns={filterColumn<EntityDeals>(columns, uncheckedId)}
          loading={isLoading}
          onRow={(record: any) => {
            return {
              onClick: () => {
                if (isCan('write', Menu.deal)) editDeal(record?.id);
              },
            };
          }}
        />
        <DeleteModal
          handleClose={handleShowModalDelete}
          show={modalConfirmDelete}
          validDelete={() => validDelete()}
          groupeName={selectedDeals?.number || ''}
          isLoadingDelete={isLoadingDelete}
          title="Delete Deals"
        />
      </div>
      {isCan('write', Menu.deal) && (
        <AddBtn
          disabled={false}
          onClick={() => {
            handleCreateDeals();
          }}
          title="Add Deal"
        />
      )}
    </>
  );
}
