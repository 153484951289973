import { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Col,
  Form,
  FormControl,
  ModalProps,
  Row,
} from 'react-bootstrap';
import { isEqual, pick, uniq } from 'lodash';
import FormModal from '../../../Modal/FormModal/FormModal';
import { Indication } from '../../../../@types/Indication/indication';
import useIndicationModal from './useIndicationModal';
import SelectDropDown from '../../../common/DropDown/SelectDropDown';
import classes from './IndicationModal.module.scss';
import { DropDownOptionObjectType } from '../../../common/DropDown/DropdownSelect';
import { EntityEnquiries } from '../../../../@types/enquiries';
import { ReactComponent as UnderWriter } from '../../../../assets/img/underwriter.svg';
import { ReactComponent as Partner } from '../../../../assets/img/partner.svg';
import { ReactComponent as Financier } from '../../../../assets/img/financy.svg';
import EnquiryInfosComponent from './EnquiryInfos';
import { useListCounterPartyQuery } from '../../../../redux/api/ws/counterparties/get';
import { Divider, Radio, Table } from 'antd';
import { useSearchContactQuery } from '../../../../redux/api/ws/indication/get';
import ReactDatePicker from 'react-datepicker';
import CustomInput from '../../../common/CustomInput/CustomInput';
import { IoCalendarSharp, IoClose } from 'react-icons/io5';
import { RiFolderUploadFill } from 'react-icons/ri';
import moment from 'moment';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import FormError from '../../../FormError/FormError';
import { FaUser } from 'react-icons/fa';
import DeleteModal from '../../../Modal/DeleteModal';
import { BusinessType, ConterParty } from '../../../../@types/conterParty';
import { People } from '../../../../@types/people';
import AddModal from '../../../Modal/AddModal';
import {
  checkIsEdit,
  isCan,
  addThousandSeparator,
} from '../../../../utils/helper-function';
import { Menu } from '../../../../@types/common-types';
import HeaderCalendar from '../../../Calendar/HeaderCalendar';
import ModalUnsavedChange from '../../../common/Modal/ModalUnsavedChange';

const IndicationModal = ({
  defaultFormValues,
  onSubmittedForm,
  onClose,
  onClickPeople,
  /*onClickEnquiry,
  onClickUnderwriter,
  onClickClient,
  onCLickObligor,*/
  ...props
}: Props) => {
  const {
    terms,
    selectedEnquiry,
    enquiries,
    isEnquiryLoading,
    columnAttachments,
    defaultValues,
    attachments,
    modalDeleteAttach,
    selectedAttachment,
    underwriter,
    people,
    modalAttach,
    isLoadingAttachment,
    enquiryState,
    setEnquiryState,
    setForceDate,
    setModalDeleteAttach,
    setEnquiryQuery,
    setSelectedEnquiry,
    getEnquiry,
    onSubmitForm,
    handleClose,
    register,
    watchFormValue,
    setFormValue,
    getFormValue,
    setFomError,
    clearFormErrors,
    onDeleteAttachment,
    onAddAttachment,
    onAddAttachmentDesc,
    getLegalEntity,
    errors,
    isShowModalUnsavedChange,
    showOtherModal,
    dataToShowOnOtherModal,
    setIsShowModalUnsavedChange,
    dirtyFields,
  } = useIndicationModal({
    defaultFormValues,
    onSubmittedForm,
  });

  const [searchContact] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [businessType, setBusinesType] = useState<BusinessType | undefined>(
    undefined,
  );

  const handleWatchCurrencyError = () => {
    if (
      (watchFormValue('currency') !== null &&
        watchFormValue('currency') !== '') ||
      watchFormValue('amount') == 0
    ) {
      clearFormErrors('currency');
    } else {
      setFomError('currency', { message: 'Currency required' });
    }
  };

  const getBusinessType = (mandate_type: string) => {
    switch (mandate_type) {
      case 'financing':
        setBusinesType('financier');
        break;
      case 'insurance':
        setBusinesType('underwriter');
        break;
      default:
        setBusinesType('partner');
        break;
    }
  };

  const { data: markets, isLoading } = useListCounterPartyQuery({
    start: 0,
    page: 1,
    query: '',
    businessType,
  });

  const allMarkets = useMemo(() => {
    return (markets?.counterparty ?? []).map((d) => ({
      key: d,
      value: `${d.name}`,
    }));
  }, [markets]);

  const { data: contacts } = useSearchContactQuery({
    query: searchContact,
    counterpartyId: watchFormValue('underwriter_id'),
  });

  useEffect(() => {
    if (defaultFormValues?.enquiry_id) {
      getEnquiry({ id: defaultFormValues?.enquiry_id })
        .then((res) => {
          if (res && res.data) {
            setSelectedEnquiry(res.data.enquiry);
          }
        })
        .finally(() => {
          setShowModal(true);
        });
    } else {
      setShowModal(true);
    }
  }, [defaultFormValues]);

  useEffect(() => {
    if (selectedEnquiry) {
      const bType = (selectedEnquiry as any).mandate.mandate_type;
      getBusinessType(bType);
    }
  }, [selectedEnquiry]);

  useEffect(() => {
    if (props.show) {
      setForceDate(new Date());
    }
  }, [props.show]);

  useEffect(() => {
    const id = setTimeout(() => {
      if (getFormValue('approached') === 1) {
        if (
          getFormValue('contact_id') === null ||
          (getFormValue('contact_id') as number) <= 0
        ) {
          setFomError('contact_id', {
            message: 'Contact must be present',
          });
        }
        if (!getFormValue('contacted_date')) {
          setFomError('contacted_date', {
            message: 'Contact date must be present',
          });
        }
        if (!getFormValue('channel')) {
          setFomError('channel', {
            message: 'Channel must be present',
          });
        }
      }
    }, 2500);
    return () => clearTimeout(id);
  }, [watchFormValue('approached')]);

  const disabledWrite = !!(
    !isCan('write', Menu.indication) &&
    checkIsEdit(defaultFormValues?.id as number)
  );

  useEffect(() => {
    getEnquiry({ id: enquiryState?.id })
      .then((res) => {
        if (res && res.data) {
          setSelectedEnquiry(res.data.enquiry);
        }
      })
      .finally(() => {
        setShowModal(true);
      });
  }, [enquiryState]);

  return (
    <>
      <DeleteModal
        title="Delete attachments"
        show={modalDeleteAttach}
        groupeName={`${selectedAttachment?.filename ?? ''}`}
        entityTitle={'attachments'}
        validDelete={() => onDeleteAttachment()}
        handleClose={() => setModalDeleteAttach(false)}
      />
      <AddModal
        title="Add attachment's description"
        show={modalAttach.show}
        validSave={(c) => {
          onAddAttachmentDesc(c);
        }}
      />
      <FormModal
        {...props}
        header={
          defaultFormValues ? (
            <div className="d-flex">
              <div className={`ms-3 ${classes.modalHeaderTitleEdit}`}>
                Indication{' '}
                {defaultFormValues?.number
                  ? '- ' + defaultFormValues?.number
                  : ''}
              </div>
            </div>
          ) : (
            <div className={`ms-3 ${classes.modalHeaderTitle}`}>
              New - Indication
            </div>
          )
        }
        show={props.show && showModal}
        contentClassName={
          selectedEnquiry ? undefined : `${classes.modalContent}`
        }
        onSubmitForm={(e) => onSubmitForm(e)}
        ishiddenfooter={
          disabledWrite || (!defaultFormValues && !selectedEnquiry)
        }
        isDisabledSave={
          !(
            (!selectedEnquiry && enquiryState !== undefined) ||
            (watchFormValue('underwriter_id') > 0 &&
              Object.keys(errors).length <= 0 &&
              !isEqual(
                pick(
                  defaultValues,
                  Object.keys(defaultValues).filter((k) => !k.endsWith('Key')),
                ),
                pick(
                  watchFormValue(),
                  Object.keys(defaultValues).filter((k) => !k.endsWith('Key')),
                ),
              ))
          )
        }
        handleClose={function (): void {
          handleClose();
          setSelectedEnquiry(undefined);
          setEnquiryState(undefined);
          if (onClose) onClose();
        }}
      >
        {!defaultFormValues && !selectedEnquiry && (
          <div className="d-flex">
            <SelectDropDown
              dropDownClass="px-0"
              title="Enquiry"
              id="enquiry_id"
              preValue={''}
              loading={isEnquiryLoading}
              minWidthFormInput={400}
              onSearch={(d, v) => {
                setEnquiryQuery(v);
              }}
              options={uniq(
                enquiries.map((e) => ({
                  key: e,
                  value: `${(e as any).complete_number}`,
                })),
              )}
              onSelectOption={(e) => {
                setEnquiryState(
                  (e as DropDownOptionObjectType).key as EntityEnquiries,
                );
                if (e) {
                  setFormValue(
                    'enquiry_id',
                    ((e as DropDownOptionObjectType).key as EntityEnquiries).id,
                  );
                } else {
                  setFormValue('enquiry_id', -1);
                }
              }}
            />
          </div>
        )}
        {showModal && (defaultFormValues || selectedEnquiry) && (
          <>
            <Row className="pt-4">
              <Col className="pe-4">
                <div className="d-flex align-items-baseline">
                  <Form.Label className="txt-with-dropdown2">
                    Market ID<span className="span-required">*</span>
                  </Form.Label>
                  <div className="d-flex flex-column w-100">
                    <SelectDropDown
                      // noSearch
                      dropDownClass="px-0"
                      id="underwriter_id"
                      // error={"test error"}
                      error={errors.underwriter_id?.message}
                      placeholder=""
                      preValue={
                        (getFormValue('underwriter_idKey') ?? '') as string
                      }
                      onSearch={(d, v) => {
                        const filtered = allMarkets.filter((m) =>
                          m.value.toLowerCase().includes(v.toLowerCase()),
                        );
                        d(filtered);
                      }}
                      {...register('underwriter_id')}
                      loading={isLoading}
                      options={allMarkets}
                      onBlur={() => {
                        if (watchFormValue('underwriter_id') <= 0) {
                          setFomError('underwriter_id', {
                            message: 'Market ID required',
                          });
                        }
                      }}
                      onSelectOption={(e) => {
                        if (e) {
                          setFormValue(
                            'underwriter_id',
                            (e as DropDownOptionObjectType).key.id,
                          );
                          setFormValue(
                            'underwriter_idKey',
                            (e as DropDownOptionObjectType).value,
                          );
                          clearFormErrors('underwriter_id');
                        } else {
                          setFormValue('underwriter_id', 0);
                          setFormValue('underwriter_idKey', '');
                          setFomError('underwriter_id', {
                            message: 'Market ID required',
                          });
                        }
                        setFormValue('contact_id', null);
                        setFormValue('contact_idKey', '');
                      }}
                      icon={
                        <div className="d-flex justify-content-end ms-3 mt-n1">
                          <span
                            className={`${
                              watchFormValue('underwriter_id') !== null &&
                              (watchFormValue('underwriter_id') as number) > 0
                                ? 'bg-danger'
                                : 'bg-gray'
                            } px-2`}
                            style={{
                              cursor: 'pointer',
                              paddingTop: 4,
                              paddingBottom: 6,
                              borderRadius: 2,
                            }}
                            onClick={() => {
                              if (
                                (watchFormValue('underwriter_id') as number) >
                                  0 &&
                                underwriter !== null &&
                                businessType !== undefined
                              ) {
                                showOtherModal(underwriter, businessType);
                              }
                            }}
                          >
                            {businessType === 'underwriter' && (
                              <UnderWriter color="white" />
                            )}
                            {businessType === 'financier' && (
                              <Financier color="white" />
                            )}
                            {businessType === 'partner' && (
                              <Partner color="white" />
                            )}
                          </span>
                        </div>
                      }
                    />
                  </div>
                </div>
                <div className="d-flex align-items-baseline pt-3">
                  <Form.Label className="txt-with-dropdown2">
                    Contacted
                  </Form.Label>
                  <div className="d-flex flex-column w-100">
                    <div className="d-flex w-100">
                      <Radio.Group
                        onChange={(t) => {
                          setFormValue('approached', t.target.value);
                          if (t.target.value === 0) {
                            clearFormErrors('contact_id');
                            clearFormErrors('contacted_date');
                            clearFormErrors('channel');
                          } else {
                            if (
                              getFormValue('contact_id') === null ||
                              (getFormValue('contact_id') as number) <= 0
                            ) {
                              setFomError('contact_id', {
                                message: 'Contact must be present',
                              });
                            }
                            if (!getFormValue('contacted_date')) {
                              setFomError('contacted_date', {
                                message: 'Contact date must be present',
                              });
                            }
                            if (!getFormValue('channel')) {
                              setFomError('channel', {
                                message: 'Channel must be present',
                              });
                            }
                          }
                        }}
                        value={watchFormValue('approached')}
                      >
                        <Radio value={1} className="mr-5">
                          Yes
                        </Radio>
                        <Radio value={0} className="mx-5">
                          No
                        </Radio>
                      </Radio.Group>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-baseline pt-3">
                  <Form.Label className="txt-with-dropdown2">
                    Contact{' '}
                    {watchFormValue('approached') === 1 && (
                      <span className="span-required">*</span>
                    )}
                  </Form.Label>
                  <div className="d-flex flex-column w-100">
                    <SelectDropDown
                      dropDownClass="px-0"
                      id="contact_id"
                      placeholder=""
                      onBlur={() => {
                        if (
                          watchFormValue('approached') === 1 &&
                          (getFormValue('contact_id') === null ||
                            (getFormValue('contact_id') as number) <= 0)
                        ) {
                          setFomError('contact_id', {
                            message: 'Contact must be present',
                          });
                        } else {
                          clearFormErrors('contact_id');
                        }
                      }}
                      disabled={watchFormValue('underwriter_id') <= 0}
                      preValue={watchFormValue('contact_idKey')}
                      error={errors.contact_id?.message}
                      noSearch
                      // onSearch={(d, v) => {
                      //   setSearchContact(v);
                      // }}
                      options={(contacts?.people ?? []).map((d) => ({
                        key: d,
                        value: `${d.firstname} ${d.lastname}`,
                      }))}
                      onSelectOption={(e) => {
                        if (e) {
                          setFormValue(
                            'contact_id',
                            (e as DropDownOptionObjectType).key.id,
                          );
                          setFormValue(
                            'contact_idKey',
                            (e as DropDownOptionObjectType).value,
                          );
                          if (
                            getFormValue('contacted_date') &&
                            getFormValue('channel') !== ''
                          ) {
                            setFormValue('approached', 1);
                          }
                          clearFormErrors('contact_id');
                        } else {
                          setFormValue('contact_id', null);
                          setFormValue('contact_idKey', '');
                          if (watchFormValue('approached') === 1) {
                            setFomError('contact_id', {
                              message: 'Contact must be present',
                            });
                          } else {
                            clearFormErrors('contact_id');
                          }
                        }
                      }}
                      icon={
                        <div className="d-flex justify-content-end ms-3 mt-n1">
                          <span
                            className={`${
                              watchFormValue('contact_id') !== null &&
                              (watchFormValue('contact_id') as number) > 0
                                ? 'bg-danger'
                                : 'bg-gray'
                            } px-2`}
                            style={{
                              cursor: 'pointer',
                              paddingTop: 4,
                              paddingBottom: 6,
                              borderRadius: 2,
                            }}
                            onClick={() => {
                              if (
                                onClickPeople &&
                                watchFormValue('contact_id') !== null &&
                                (watchFormValue('contact_id') as number) > 0 &&
                                people
                              )
                                showOtherModal(people, 'people');
                            }}
                          >
                            <FaUser color="white" />
                          </span>
                        </div>
                      }
                    />
                  </div>
                </div>
                <Form.Group className={`d-flex pt-3`} style={{ height: 58 }}>
                  <Form.Label
                    className="label-center-fixed"
                    style={{ width: 240 }}
                  >
                    Date contacted{' '}
                    {watchFormValue('approached') === 1 && (
                      <span className="span-required">*</span>
                    )}
                  </Form.Label>
                  <div
                    className="d-flex"
                    style={{
                      flexDirection: 'column',
                      width: '100%',
                      marginRight: '4px',
                      maxWidth: props.maxWidth,
                      position: 'relative',
                    }}
                  >
                    <ReactDatePicker
                      className={`form-control picker-select`}
                      autoComplete="off"
                      placeholderText=""
                      renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                      }) =>
                        HeaderCalendar({
                          date,
                          changeYear,
                          changeMonth,
                          decreaseMonth,
                          increaseMonth,
                          prevMonthButtonDisabled,
                          nextMonthButtonDisabled,
                        })
                      }
                      selected={
                        watchFormValue('contacted_date')
                          ? moment(watchFormValue('contacted_date')).toDate()
                          : null
                      }
                      value={
                        getFormValue('contacted_date')
                          ? moment(getFormValue('contacted_date')).format(
                              'DD/MM/YYYY',
                            )
                          : undefined
                      }
                      customInput={
                        <CustomInput
                          className="mx-0"
                          value={
                            watchFormValue('contacted_date')
                              ? moment(watchFormValue('contacted_date')).format(
                                  'DD/MM/YYYY',
                                )
                              : undefined
                          }
                          onChange={() => {}}
                          onBlur={() => {
                            if (
                              getFormValue('approached') &&
                              !getFormValue('contacted_date')
                            ) {
                              setFomError('contacted_date', {
                                message: 'Contacted date required',
                              });
                            } else {
                              clearFormErrors('contacted_date');
                            }
                          }}
                          rightIcon={
                            <div className="d-flex">
                              {watchFormValue('contacted_date') && (
                                <IoClose
                                  color="#851001"
                                  onClick={() => {
                                    setFormValue('contacted_date', null);
                                    if (getFormValue('approached')) {
                                      setFomError('contacted_date', {
                                        message: 'Contacted date required',
                                      });
                                    } else {
                                      clearFormErrors('contacted_date');
                                    }
                                  }}
                                  size={20}
                                  className="mt-2 me-1"
                                />
                              )}
                              <IoCalendarSharp
                                color="#851001"
                                size={20}
                                className="mt-2 me-1"
                              />
                            </div>
                          }
                        />
                      }
                      onChange={(e) => {
                        if (!e) {
                          if (getFormValue('approached') === 1) {
                            setFomError('contacted_date', {
                              message: 'Contacted date required',
                            });
                          } else {
                            clearFormErrors('contacted_date');
                          }
                        } else {
                          clearFormErrors('contacted_date');
                        }
                        setFormValue(
                          'contacted_date',
                          e ? moment(e).format('YYYY-MM-DD') : null,
                        );
                        if (
                          e &&
                          getFormValue('contact_id') &&
                          getFormValue('channel') !== ''
                        ) {
                          setFormValue('approached', 1);
                        }
                      }}
                    />
                    <FormError
                      message={errors.contacted_date?.message}
                      style={{
                        position: 'absolute',
                        bottom: '-1.25rem',
                        left: '0',
                      }}
                    />
                  </div>
                </Form.Group>

                <div className="d-flex w-100 align-items-baseline pt-3">
                  <Form.Label className="txt-with-dropdown2">
                    Channel{' '}
                    {watchFormValue('approached') === 1 && (
                      <span className="span-required">*</span>
                    )}
                  </Form.Label>
                  <div className="d-flex flex-column w-100">
                    <SelectDropDown
                      dropDownClass="px-0"
                      id="channel"
                      noSearch
                      onBlur={() => {
                        if (
                          watchFormValue('approached') === 1 &&
                          getFormValue('channel') === ''
                        ) {
                          setFomError('channel', {
                            message: 'Channel must be present',
                          });
                        } else {
                          clearFormErrors('channel');
                        }
                      }}
                      error={errors.channel?.message}
                      preValue={
                        terms.find((t) => t.key === getFormValue('channel'))
                          ?.value
                      }
                      options={terms.filter((t) => t.type === 'channelType')}
                      onSelectOption={(e) => {
                        if (e) {
                          setFormValue(
                            'channel',
                            (e as DropDownOptionObjectType).key,
                          );
                          clearFormErrors('channel');
                          if (
                            getFormValue('contact_id') &&
                            getFormValue('contacted_date')
                          ) {
                            setFormValue('approached', 1);
                          }
                        } else {
                          if (watchFormValue('approached') === 1) {
                            setFomError('channel', {
                              message: 'Channel must be present',
                            });
                          } else {
                            clearFormErrors('channel');
                          }
                          setFormValue('channel', '');
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex w-100 pt-3" style={{ maxHeight: 53 }}>
                  <Form.Label className="txt-center-fix label-center-fixed fix-datepicker-show">
                    Indication
                  </Form.Label>
                  <div className="d-flex flex-center w-100">
                    <div className="w-50">
                      <SelectDropDown
                        noPadding
                        dropDownClass="px-0"
                        id="indication_type"
                        disabled={watchFormValue('approached') === 0}
                        preValue={
                          terms.find(
                            (t) => t.key === getFormValue('indication_type'),
                          )?.value
                        }
                        options={terms.filter(
                          (t) => t.type === 'indicationType',
                        )}
                        onSelectOption={(e) => {
                          if (e) {
                            const indicationKey = (
                              e as DropDownOptionObjectType
                            ).key;
                            setFormValue('indication_type', indicationKey);
                            if (indicationKey === 'nty') {
                              setFormValue('amount', 0);
                            }
                            if (
                              ['nty', 'eoi', 'nbi'].includes(indicationKey) &&
                              watchFormValue('indication_date') === null
                            ) {
                              setFomError('indication_date', {
                                message: 'Date required',
                              });
                            } else {
                              clearFormErrors('indication_date');
                            }
                          } else {
                            setFormValue('indication_type', '');
                            clearFormErrors('indication_date');
                          }
                        }}
                      />
                    </div>
                    <div className="d-flex w-50">
                      <Form.Label className="label-center-fixed">
                        Date&nbsp;
                        {['nty', 'eoi', 'nbi'].includes(
                          watchFormValue('indication_type'),
                        ) && <span className="span-required">*&nbsp;</span>}
                      </Form.Label>
                      <div
                        className="d-flex flex-column w-100"
                        style={{ marginRight: 3 }}
                      >
                        <ReactDatePicker
                          className={`form-control picker-select ${classes.datePicker}`}
                          autoComplete="off"
                          placeholderText=""
                          disabled={watchFormValue('approached') === 0}
                          value={
                            watchFormValue('indication_date')
                              ? moment(
                                  watchFormValue('indication_date'),
                                ).format('DD/MM/YYYY')
                              : undefined
                          }
                          renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                          }) =>
                            HeaderCalendar({
                              date,
                              changeYear,
                              changeMonth,
                              decreaseMonth,
                              increaseMonth,
                              prevMonthButtonDisabled,
                              nextMonthButtonDisabled,
                            })
                          }
                          customInput={
                            <CustomInput
                              rightIcon={
                                watchFormValue('approached') === 1 ? (
                                  <div className="d-flex">
                                    {watchFormValue('indication_date') && (
                                      <IoClose
                                        color="#851001"
                                        onClick={() => {
                                          setFormValue('indication_date', null);
                                          if (
                                            ['nty', 'eoi', 'nbi'].includes(
                                              watchFormValue('indication_type'),
                                            )
                                          ) {
                                            setFomError('indication_date', {
                                              message: 'Date required',
                                            });
                                          }
                                        }}
                                        size={20}
                                        className="mt-2 me-1"
                                      />
                                    )}
                                    <IoCalendarSharp
                                      color="#851001"
                                      size={20}
                                      className="mt-2 me-1"
                                    />
                                  </div>
                                ) : (
                                  <></>
                                )
                              }
                            />
                          }
                          onChange={(e) => {
                            setFormValue(
                              'indication_date',
                              e ? moment(e).format('YYYY-MM-DD') : null,
                            );
                            if (watchFormValue('indication_type')) {
                              clearFormErrors('indication_date');
                            }
                          }}
                        />
                        <FormError message={errors.indication_date?.message} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-center align-items-baseline w-100 pt-3">
                  <Form.Label style={{ width: 235 }}>Line</Form.Label>
                  <div className="d-flex flex-column w-100">
                    <div className="d-flex  flex-center w-100 align-items-baseline">
                      <Form.Label className="w-50 my-auto">
                        <div
                          className={`d-flex  flex-row mx-0 ${classes.element} py-2 my-0`}
                        >
                          <div className={`d-flex flex-fill `}>
                            <FormControl
                              type="text"
                              value={addThousandSeparator(
                                watchFormValue('amount'),
                                "'",
                              )}
                              // max accepted by the api 10 , but plus the separator => 13
                              // maxLength={13}
                              disabled={
                                watchFormValue('indication_type') === 'nty' ||
                                watchFormValue('approached') === 0
                              }
                              className={`${classes.formNumber} flex-fill rounded-0`}
                              {...register('amount', {
                                onChange: (e) => {
                                  const line = e.target.value;
                                  const d = line
                                    .toString()
                                    .match(/\d/g)
                                    ?.join('');
                                  if (!d || d === '') {
                                    setFormValue('amount', 0);
                                  } else {
                                    setFormValue('amount', +d);
                                  }
                                  if (parseInt(d) >= 0) {
                                    handleWatchCurrencyError();
                                  } else {
                                    clearFormErrors('currency');
                                  }
                                },
                                validate: function (v) {
                                  return !isNaN(
                                    parseInt(v.toString().replace('.', '')),
                                  )
                                    ? true
                                    : 'Not a valid price';
                                },
                              })}
                            />
                          </div>
                          {watchFormValue('indication_type') !== 'nty' &&
                            watchFormValue('approached') === 1 && (
                              <div className="d-flex justify-content-end flex-fill">
                                <div
                                  className="flex flex-colummn justify-content-center pt-2 pe-2"
                                  style={{ color: '#D9D9D9' }}
                                >
                                  <span>|</span>
                                </div>
                                <div
                                  className={`d-flex flex-column justify-content-between pe-2`}
                                >
                                  <div className="py-0 my-0">
                                    <IoIosArrowUp
                                      onClick={() => {
                                        const curAmount =
                                          parseInt(
                                            watchFormValue('amount')
                                              .toString()
                                              .replaceAll("'", ''),
                                          ) + 1;
                                        setFormValue('amount', curAmount);
                                        handleWatchCurrencyError();
                                      }}
                                      style={{
                                        fontSize: 16,
                                        // marginTop: 16,
                                        cursor: 'pointer',
                                        color: '#851001',
                                      }}
                                    />
                                  </div>
                                  <div className="py-0 my-0">
                                    <IoIosArrowDown
                                      onClick={() => {
                                        const curAmount = Math.max(
                                          parseInt(
                                            watchFormValue('amount')
                                              .toString()
                                              .replaceAll("'", ''),
                                          ) - 1,
                                          0,
                                        );
                                        setFormValue('amount', curAmount);
                                        handleWatchCurrencyError();
                                      }}
                                      style={{
                                        fontSize: 16,
                                        // marginBottom: 8,
                                        cursor: 'pointer',
                                        color: '#851001',
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                        </div>
                      </Form.Label>
                      <div className="d-flex align-items-baseline ">
                        <Form.Label className="mx-1 d-flex my-auto">
                          Currency{' '}
                          {watchFormValue('amount') > 0 && (
                            <span className="span-required">&nbsp;*</span>
                          )}
                        </Form.Label>
                        <div className="d-flex flex-column w-100 ms-3">
                          <SelectDropDown
                            dropDownClass="ps-0"
                            id="currency"
                            disabled={
                              watchFormValue('amount') < 1 ||
                              watchFormValue('approached') === 0
                            }
                            placeholder=""
                            noSearch
                            error={errors.currency?.message}
                            preValue={
                              terms.find(
                                (t) =>
                                  t.key ===
                                  ((getFormValue('currency') ?? '') as string),
                              )?.value
                            }
                            options={terms.filter((t) => t.type === 'currency')}
                            onSelectOption={(e) => {
                              if (e) {
                                setFormValue(
                                  'currency',
                                  (e as DropDownOptionObjectType).key,
                                );
                                clearFormErrors('currency');
                              } else {
                                setFormValue('currency', '');
                                setFomError('currency', {
                                  message: 'Currency must be present',
                                });
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Form.Group className={`d-flex align-items-baseline pt-3`}>
                  <Form.Label className="my-auto" style={{ width: 240 }}>
                    Price
                  </Form.Label>
                  <div
                    className="d-flex"
                    style={{
                      flexDirection: 'column',
                      width: '100%',
                      marginRight: '4px',
                      maxWidth: props.maxWidth,
                    }}
                  >
                    <Form.Control
                      type="text"
                      // className={`${errors["indication_price"] && "invalid"}`}
                      value={getFormValue('indication_price')}
                      {...register('indication_price', {
                        onChange: (e) => {
                          const price = e.target.value;
                          setFormValue('indication_price', price);
                        },
                      })}
                    />
                  </div>
                </Form.Group>
                <Form.Group className={`d-flex align-items-start pt-4`}>
                  <Form.Label style={{ width: 240 }}>
                    Comments/Subjectivities
                  </Form.Label>
                  <div
                    className="d-flex"
                    style={{
                      flexDirection: 'column',
                      width: '100%',
                      marginRight: '4px',
                      maxWidth: props.maxWidth,
                    }}
                  >
                    <Form.Control
                      as="textarea"
                      rows={5}
                      className={`${errors['comments'] && 'invalid'}`}
                      {...register('comments')}
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col className={`${classes.enquiryInfo} py-4`}>
                <EnquiryInfosComponent
                  selectedEnquiry={selectedEnquiry}
                  terms={terms}
                  onClickEnquiry={(eq) => {
                    showOtherModal(
                      {
                        ...eq,
                        client_name: eq?.mandate?.client.name,
                      },
                      'enquiry',
                    );
                  }}
                  onClickClient={(c) => showOtherModal(c, 'client')}
                  onCLickObligor={(o) => {
                    getLegalEntity({ id: o.id.toString() }).then((res) => {
                      if (res.data && res.data.legalentity) {
                        const found = res.data.legalentity;
                        showOtherModal(
                          {
                            ...found,
                            group_id: found?.group?.id,
                            group_name: found?.group?.name,
                          },
                          'legal_entity',
                        );
                      }
                    });
                  }}
                />
              </Col>
            </Row>
            <Divider />
            <Row>
              {/* {watchFormValue("id") > 0 && ( */}
              <>
                <div className="content-table mt-2">
                  <div className="font-bold mb-2">Attachment(s)</div>
                  <div className="content-table">
                    <Table
                      className="no-background"
                      rowClassName={classes.tablelight}
                      dataSource={attachments}
                      columns={columnAttachments}
                      rowKey="id"
                      pagination={false}
                      loading={isLoadingAttachment}
                    />
                  </div>
                  {!disabledWrite && (
                    <div className="d-flex">
                      <Button
                        className="btn red mt-3 py-2"
                        // disabled={dataSourceLegalEntity.length > 0}
                        onClick={() => {
                          document.getElementById('attachment')?.click();
                        }}
                      >
                        <RiFolderUploadFill className="me-2" />
                        <span className="ms-1">Upload</span>
                      </Button>
                      <input
                        type="file"
                        className="invisible"
                        id="attachment"
                        onChange={(e) => {
                          if (getFormValue('id') > 0) {
                            onAddAttachment(
                              defaultFormValues?.id ?? '',
                              e.target.files,
                            );
                          }
                          e.target.files = null;
                          e.target.value = '';
                        }}
                      />
                    </div>
                  )}
                </div>
              </>
              {/* )} */}
            </Row>
          </>
        )}
      </FormModal>

      <ModalUnsavedChange
        showModalUnsaved={isShowModalUnsavedChange}
        dirtiFields={dirtyFields}
        handleCancel={() => setIsShowModalUnsavedChange(false)}
        handleLeave={() =>
          showOtherModal(
            dataToShowOnOtherModal?.data,
            `${dataToShowOnOtherModal?.type}`,
            true,
          )
        }
      />
    </>
  );
};

export default IndicationModal;

type Props = {
  defaultFormValues?: Indication | null;
  onSubmittedForm?: () => void;
  onClose?: () => void;
  onClickPeople?: (p: People) => void;
  onClickEnquiry?: (en: EntityEnquiries) => void;
  onClickUnderwriter?: (underwriter: ConterParty) => void;
  onClickClient?: (client: ConterParty) => void;
  onCLickObligor?: (obligor: ConterParty) => void;
} & ModalProps;
