import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  EntityCofarcoTeam,
  InportFormCreateCorfacroTeam,
} from "../../../@types/cofarco-teams/type";
import { People } from "../../../@types/people";
import { useCreateCofarcoTeamsMutation } from "../../../redux/api/ws/databases/cofarco-teams";
import { useAppDispatch } from "../../../hooks/redux";
import { updateNotificationMessage } from "../../../redux/slice/notificationSlice";
import { updateModalAction } from "../../../redux/slice/modalSlice";
import { isEmpty } from "lodash";
import { getDifferentKeys } from "../../../utils/helper-function";
type IProps = {
  closingModal: (user: EntityCofarcoTeam | null) => void;
  show: boolean;
};

export default function UseModalCofarcoTeam(props: IProps) {
  const [modalConfirm, setModalConfirm] = useState(false);
  const [isCompletedForm, setIsCompletedForm] = useState(false);
  const [editPeople, setEditPeople] = useState<People | undefined | null>();
  const [showModalFormPeople, setShowModalFormPeople] =
    useState<boolean>(false);
  const [peopleSelected, setPeopleSelected] = useState<Array<People>>([]);
  const [createCofarcoTeam, { isLoading: isLoadingCreateCofarcoTeam }] =
    useCreateCofarcoTeamsMutation();

  const [isShowModalUnsavedChange, setIsShowModalUnsavedChange] =
    useState<boolean>(false);
  const [showOtherModaldata, setShowOtherModal] = useState<any>();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, defaultValues },
    setError,
    clearErrors,
    watch,
    getValues,
    setValue,
    reset,
  } = useForm<InportFormCreateCorfacroTeam>({ mode: "onChange" });
  const dispatch = useAppDispatch();

  const handleModalConfirm = () => {
    setModalConfirm(!modalConfirm);
  };

  const submit = async (data: InportFormCreateCorfacroTeam) => {
    try {
      const respose = await createCofarcoTeam(data).unwrap();

      if (respose && respose.success) {
        props.closingModal(respose.user);
        // handleModalConfirm();
        dispatch(
          updateNotificationMessage({
            title: "Successfully added",
            body: `Add  Cofarco Team`,
            show: true,
          })
        );
      }
    } catch (error: any) {
      props.closingModal(null);
    }
  };

  React.useEffect(() => {
    reset({
      email: "",
      password: "",
      password_confirmation: "",
      people_id: 0,
      people_name: "",
    });
  }, [props.show]);

  React.useEffect(() => {
    const subscription = watch((value) => {
      const isCompletedRequired =
        value.email &&
        value.password &&
        value.password_confirmation &&
        value.people_id;

      setIsCompletedForm(isCompletedRequired ? true : false);
    });
    return () => subscription.unsubscribe();
  }, [watch, register]);

  const handleFormModalSubmit = () => {
    setShowModalFormPeople(false);
    setEditPeople(undefined);
  };

  const handleSelectedPeople = (p: People | undefined) => {
    p && setPeopleSelected([...peopleSelected, p]);
  };

  const showPeople = (peopleId: number, noCheck?: boolean) => {
    const listDiffs = getDifferentKeys(defaultValues!, watch());

    if (peopleId) {
      const people = peopleSelected.find(
        (el) => el.id?.toString() === peopleId?.toString()
      );
      if (people) {
        if (!isEmpty(listDiffs) && !noCheck) {
          setIsShowModalUnsavedChange(true);
          setShowOtherModal(peopleId);
        } else {
          dispatch(
            updateModalAction({
              data: people,
              isAdd: true,
              type: "people",
            })
          );
          setIsShowModalUnsavedChange(false);
          setShowOtherModal(undefined);
        }
      }
    }
  };

  return {
    submit,
    isLoadingCreateCofarcoTeam,
    register,
    getValues,
    errors,
    watch,
    handleSubmit,

    setValue,
    reset,
    modalConfirm,
    setModalConfirm,
    handleModalConfirm,
    isCompletedForm,
    editPeople,
    setEditPeople,
    showModalFormPeople,
    setShowModalFormPeople,
    handleFormModalSubmit,
    setPeopleSelected,
    handleSelectedPeople,
    showPeople,
    isDirty,
    setError,
    clearErrors,
    isShowModalUnsavedChange,
    setIsShowModalUnsavedChange,
    showOtherModaldata,
    dirtyFields: getDifferentKeys(defaultValues!, watch()),
  };
}
