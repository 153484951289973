import { Col, Row } from 'antd';
import { DashboardRev } from './DashboardRev';
import { useDasboardAmountsCard } from '../../hooks/dashboard/useDashboardAmountsCard';
import { DashboardCardAmount } from './DashboadCardAmount';

export const DashboardAmountsCards = () => {
  const { formatNumber, alerts } = useDasboardAmountsCard();

  return (
    <Row gutter={[24, 24]}>
      <Col xs={24} md={8}>
        <DashboardRev formatNumber={formatNumber} />
      </Col>
      {alerts.map((data, index) => (
        <DashboardCardAmount key={index} data={data} />
      ))}
    </Row>
  );
};
