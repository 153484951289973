import { Button, Modal, Container, FormControl } from 'react-bootstrap';
import { ReactComponent as Loader } from '../../assets/img/Loading.svg';
import classes from './Modal.module.scss';
import { useState } from 'react';

type Props = {
  show: boolean;
  title?: string;
  isLoading?: boolean;
  validSave: (c: string) => void;
};

const AddModal = (props: Props) => {
  const [content, setContent] = useState<string>('');
  return (
    <Modal
      show={props.show}
      className={classes.modalInfo}
      contentClassName={classes.modalContent}
      dialogClassName={classes.modalDialog}
      onHide={() => {
        setContent('');
      }}
      centered
      backdrop='static'
      close
    >
      <form className='modal-confirm'>
        <Modal.Header className={classes.modalHeader} closeVariant='white'>
          <Modal.Title>{props.title ?? ''}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className='centered'>
            <FormControl
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
          </Container>
        </Modal.Body>
        <Modal.Footer className={`${classes.modalFooter} justify-content-end`}>
          <Button
            className={`btn primary ${
              content.trim().length >= 3
                ? 'active-link'
                : classes.buttonSaveDisable
            } mx-2 `}
            onClick={() => {
              if (content.trim().length >= 3) {
                const c = `${content}`;
                props.validSave(c);
                setContent('');
              }
            }}
          >
            {props.isLoading ? <Loader /> : 'Save'}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddModal;
