import React from 'react';
import HeaderTab from '../../HeaderTab/HeaderTab';
import FormModal from '../../Modal/FormModal/FormModal';
import UseModalEnquiries from './UseModalEnquiries';
import ChildreenForm from './Form/ChildrenForm';
import { InputFormEnquiries } from '../../../@types/enquiries';
import ChildrenMarket from './Market/ChildrenMarket';
import PromptModal from '../../Modal/PromptModal';
import { Form } from 'react-bootstrap';
import SelectDropDown from '../../common/DropDown/SelectDropDown';
import {
  useSearchCounterPartyMarketMutation,
  useSearchEnquiryInMarketMutation,
} from '../../../redux/api/ws/enquiries/enquiries';
import { isCan, makeOptions } from '../../../utils/helper-function';
import { ConterParty } from '../../../@types/conterParty';
import { DropDownOptionObjectType } from '../../common/DropDown/DropdownSelect';
import { isExist } from '../../../utils/helper-function';
import { Menu } from '../../../@types/common-types';

type IProps = {
  show: boolean;
  handleClose: (mandate: any | null) => void;
  defaultFormValues: InputFormEnquiries | null;
};

export default function ModalEnquiries(props: IProps) {
  const [activeMenu, setActiveMenu] = React.useState<number>(2);

  const [selectedMarket, setSelectedMarket] = React.useState<
    Array<ConterParty>
  >([]);

  const provider = UseModalEnquiries({
    closingModal: props.handleClose,
    defaultFormValues: props.defaultFormValues,
  });

  const [seachMarket, { isLoading }] = useSearchCounterPartyMarketMutation();

  const [seachEnquiry, { data: enquryList, isLoading: isLoadingEnqury }] =
    useSearchEnquiryInMarketMutation();

  const isFinancierPrompt =
    provider.selectedMandateById?.mandate_type === 'hedge' ||
    provider.selectedMandateById?.mandate_type === 'financing' ||
    provider.selectedMandateById?.mandate_type === 'other'
      ? true
      : false;

  const searchMarket = async (value: string) => {
    try {
      const resp = await seachMarket({
        query: `%${value}%`,
        businessType: isFinancierPrompt ? 'financier' : 'underwriter',
        page: 1,
        start: 0,
      }).unwrap();
      setSelectedMarket(resp.counterparty);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSeachEnquiry = async (value: string) => {
    seachEnquiry({
      query: `%${value}%`,
    }).unwrap();
  };

  const promptTitle = isFinancierPrompt ? 'Financier' : 'Underwriter';

  const hiddenFooter =
    activeMenu === 2 || (!isCan('write', Menu.enquiry) && provider.isEdit);

  React.useEffect(() => {
    const isInUpdateMode =
      props.show &&
      !isExist(props.defaultFormValues?.id) &&
      !isExist(props.defaultFormValues?.mandate_id);
    if (isInUpdateMode) {
      provider.handleMandateId('');
    }
  }, [props.show]);

  const runSearchModal = React.useCallback(
    async (key: keyof any, value: string) => {
      if (value !== undefined && value !== null) {
        if (key === 'market') {
          await searchMarket(value);
        }
        if (key === 'panel') {
          await handleSeachEnquiry(value);
        }
      }
    },
    [isFinancierPrompt],
  );

  return (
    <>
      <FormModal
        show={props.show}
        handleClose={() => {
          provider.resetForm();
          setActiveMenu(1);
          props.handleClose(null);
        }}
        ishiddenfooter={hiddenFooter}
        isLoading={false}
        onSubmitForm={provider.handleSubmit(provider.submit)}
        header={
          <HeaderTab
            title={provider.modalTitle}
            activeMenu={activeMenu}
            setActiveMenu={setActiveMenu}
            titleRigth={`Market Feedback`}
            isEdit={provider.isEdit}
          />
        }
        isDisabledSave={!provider.isDirty || !provider.isCompletedForm}
        children={
          <>
            {activeMenu === 1 && (
              <ChildreenForm
                mandateListe={provider.mandateListe}
                runSearch={(key: keyof InputFormEnquiries, value: string) =>
                  provider.runSearch(key, value)
                }
                clearFormErrors={provider.clearFormErrors}
                setFormError={provider.setFormError}
                register={provider.register}
                errors={provider.errors}
                watch={provider.watch}
                setValue={provider.setValue}
                selectedMandate={provider.selectedMandate}
                legalEntityList={provider.legalEntityList}
                selectedMandateById={provider.selectedMandateById}
                control={provider.control}
                partenerList={provider.partenerList}
                isPartenerLoading={provider.isPartenerLoading}
                isMandateLoading={provider.isMandateLoading}
                partenersPeople={provider.partenersPeople}
                showMandate={(id: number | string) => provider.showMandate(id)}
                showLegalEntity={(idClient: number | null) =>
                  provider.showLegalEntity(idClient)
                }
                showCofarcoTeams={(id: string | number) =>
                  provider.showCofarcoTeams(id)
                }
                showLegalEntityClientId={() =>
                  provider.showLegalEntityClientId()
                }
                showPartner={(idClient: number) =>
                  provider.showPartner(idClient)
                }
                showPeople={(idClient: number) => provider.showPeople(idClient)}
                showTopObligorEntity={() => provider.showTopObligorEntity()}
                isLoadingAttachement={provider.isLoadingAttachement}
                onAddAttachment={(f: FileList | null) =>
                  provider.onAddAttachment(f)
                }
                hiddenFooter={hiddenFooter}
                provider={provider}
                showOtherModal={provider.showOtherModal}
                handleShowOtherModal={provider.handleShowOtherModal}
                handleLeaveModalWithoutSave={
                  provider.handleLeaveModalWithoutSave
                }
              />
            )}
            {activeMenu === 2 && (
              <ChildrenMarket
                id={props.defaultFormValues?.id || 0}
                addMarket={provider.handlePrompt}
                addEnquiry={provider.handlePromptEnquiry}
                individual_limits={
                  provider.watch('individual_limits') as number
                }
              />
            )}
          </>
        }
      />
      {provider.showPrompt && (
        <PromptModal
          show={provider.showPrompt}
          addClick={() => provider.addClick()}
          handleClose={() => {
            setSelectedMarket([]);
            provider.handlePrompt();
          }}
          title="New - Market"
          isDisabled={isExist(provider?.selectedMarket?.id) ? false : true}
          isLoading={provider.isLoadingCreateMarket}
        >
          <div className="my-3">
            <Form.Group className="d-flex align-items-center w-100">
              <Form.Label className="txt mx-4 my-auto">
                {promptTitle}
              </Form.Label>

              <div className="d-flex flex-column">
                <SelectDropDown
                  modalId="enquiries-client"
                  id="client_legal_entity_id"
                  placeholder=""
                  minWidthFormInput={250}
                  loading={isLoading}
                  onSearch={(d, v) => runSearchModal('market', v)}
                  options={makeOptions(selectedMarket ?? [], 'name', 'id')}
                  onSelectOption={(e) => {
                    const selected = selectedMarket?.find(
                      (el) => el.id == (e as DropDownOptionObjectType).key,
                    );
                    if (selected) {
                      provider?.setSelectedMarket(selected);
                    }
                  }}
                />
              </div>
            </Form.Group>
          </div>
        </PromptModal>
      )}

      {provider.showPromptEnquiry && (
        <PromptModal
          show={provider.showPromptEnquiry}
          addClick={() => provider.addClickPanel()}
          handleClose={() => provider.handlePromptEnquiry()}
          title="New - Panel"
          isDisabled={isExist(provider?.selectedEnquiry?.id) ? false : true}
          isLoading={provider.isLoadingCreateMarket}
        >
          <div className="my-3">
            <Form.Group className="d-flex align-items-center w-100">
              <Form.Label className="txt mx-4 my-auto">Enquiry</Form.Label>

              <div className="d-flex flex-column">
                <SelectDropDown
                  modalId="enquiries-client"
                  id="client_legal_entity_id"
                  placeholder=""
                  loading={isLoadingEnqury}
                  onSearch={(d, v) => runSearchModal('panel', v)}
                  options={makeOptions(
                    enquryList?.enquiry || [],
                    'complete_number',
                    'id',
                  )}
                  onSelectOption={(e) => {
                    const selected = enquryList?.enquiry?.find(
                      (el) => el.id === (e as DropDownOptionObjectType).key,
                    );
                    if (selected) {
                      provider?.setSelectedEnquiry(selected);
                    }
                  }}
                />
              </div>
            </Form.Group>
          </div>
        </PromptModal>
      )}
    </>
  );
}
