import { Button, Modal, Container } from "react-bootstrap";
import classes from "./Modal.module.scss";
import { useAppDispatch } from "../../hooks/redux";
import { updateNotificationMessage } from "../../redux/slice/notificationSlice";

type Props = {
  show: boolean;
  title?: string;
  body: string;
};

const NotificationModal = (props: Props) => {
  const dispatch = useAppDispatch();
  return (
    <Modal
      show={props.show}
      onHide={() => {
        dispatch(
          updateNotificationMessage({ title: "", body: "", show: false })
        );
      }}
      className={classes.modalInfo}
      contentClassName={classes.modalContent}
      dialogClassName={classes.modalDialog}
      size="lg"
      centered
      style={{zIndex: 999999}}
    >
      <form className="modal-confirm">
        <Modal.Header
          className={classes.modalHeader}
          closeVariant="white"
          closeButton
        >
          <Modal.Title>{props.title ?? ""}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="centered">
            <h1 className={classes.contentText}>{props.body}</h1>
          </Container>
        </Modal.Body>
        <Modal.Footer
          className={`${classes.modalFooter} d-flex justify-content-center`}
        >
          <Button
            className="btn primary active-link px-4 py-2 mx-2"
            onClick={() => {
              dispatch(
                updateNotificationMessage({ title: "", body: "", show: false })
              );
            }}
          >
            Ok
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default NotificationModal;
