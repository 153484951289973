import { Navigate } from 'react-router-dom';
import { useAuth } from '../../hooks/authContext';
import { useEffect } from 'react';
import { useLazyCofarcoTeamByIdQuery } from '../../redux/api/ws/databases/cofarco-teams';
import { Menu, User } from '../../@types/common-types';
import NotFound from '../../pages/NotFound';
import { isAdmin } from '../../utils/helper-function';

const PATH_NOT_FOUND = '/';

const pathByRights = [
  {
    right: 'dashboard',
    path: '/dashboard',
  },
  {
    right: 'pipe',
    path: '/pipe',
  },
  {
    right: 'mandate',
    path: '/mandates',
  },
  {
    right: 'deal',
    path: '/deals',
  },
  {
    right: 'participation',
    path: '/participations',
  },
  {
    right: 'enquiry',
    path: '/enquiries',
  },
  {
    right: 'indication',
    path: '/indication',
  },
  {
    right: 'note',
    path: '/segregated-accounts',
  },
  {
    right: 'payment',
    path: '/segregated-accounts',
  },
  {
    right: 'client',
    path: '/counterparty',
  },
  {
    right: 'client',
    path: '/counterparty',
  },
  {
    right: 'financier',
    path: '/counterparty',
  },
  {
    right: 'underwriter',
    path: '/counterparty',
  },
  {
    right: 'partner',
    path: '/counterparty',
  },
  {
    right: 'obligor',
    path: '/counterparty',
  },
  {
    right: 'group',
    path: '/database',
  },
  {
    right: 'legal_entity',
    path: '/database',
  },
  {
    right: 'people',
    path: '/database',
  },
  {
    right: 'user',
    path: '/database',
  },
  {
    right: 'history',
    path: '/history',
  },
];

const getPathFirstReadTrueRight = (user: User | null): string => {
  if (!user) return PATH_NOT_FOUND;
  for (const key in user?.rights) {
    if (user.rights[key].read) {
      const objFind = pathByRights.find((el) => el.right === key);
      if (objFind) return objFind.path;
      return PATH_NOT_FOUND;
    }
  }
  return PATH_NOT_FOUND; // None found
};

const WrapperPermission = ({
  children,
  permission,
}: {
  children: JSX.Element;
  permission: Menu;
}) => {
  const auth = useAuth();

  const [getUser] = useLazyCofarcoTeamByIdQuery();

  useEffect(() => {
    if (auth.user) {
      getUser({ id: auth.user.id });
    }
  }, []);

  const checkHasPermission = (): boolean => {
    if (isAdmin()) return true;
    return auth.user?.rights[permission]?.read ?? false;
  };

  const path = getPathFirstReadTrueRight(auth.user);

  if (!checkHasPermission()) {
    if (permission === Menu.dashboard) return <Navigate to={path} />;
    return <NotFound />;
  }

  return children;
};

export default WrapperPermission;
