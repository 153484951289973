import { Button, Modal } from 'react-bootstrap';
import classes from './ModalUnsaved.module.scss';
import { Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { EntityCofarcoTeam, IPwd } from '../../../@types/cofarco-teams/type';
import { useRef, useState } from 'react';
import { useUpdateCofarcoPwdMutation } from '../../../redux/api/ws/databases/cofarco-teams';
import { User } from '../../../@types/common-types';
import { updateNotificationMessage } from '../../../redux/slice/notificationSlice';
import { useAppDispatch } from '../../../hooks/redux';
import { VisibilityOff, Visibility } from '@mui/icons-material';

const ModalChangePassword = ({
  showModalPassword,
  handleCancel,
  user,
}: Props) => {
  const [showPassWord, setShowPassWord] = useState<boolean>(false);
  const [showConfirmPassWord, setShowConfirmPassWord] =
    useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<IPwd>({
    mode: 'onChange',
    defaultValues: { password: '', password_confirmation: '' },
  });

  const dispatch = useAppDispatch();

  const password = useRef({});
  password.current = watch('password', '');

  const [updatePwd] = useUpdateCofarcoPwdMutation();

  const handleClose = () => {
    handleCancel();
    reset();
  };

  const handleModifyPass = () => {
    handleSubmit(async (data: IPwd) => {
      try {
        if (!user?.id) return;
        await updatePwd({
          data: {
            ...data,
            people_id: user?.people_id,
            email: user?.email,
          },
          id: user?.id,
        }).then((res) => {
          console.log(res);
          if (
            res &&
            (res as { data: { success: boolean; user: EntityCofarcoTeam } })
              .data.success
          ) {
            handleClose();
            dispatch(
              updateNotificationMessage({
                title: 'Modify password',
                body: `Your password has been updated !`,
                show: true,
              }),
            );
          }
        });
      } catch (error: any) {
        console.log(error);
      }
    })();
  };

  return (
    <Modal
      show={showModalPassword}
      keyboard={false}
      scrollable={true}
      centered
      enforceFocus
      backdropClassName="modal-backdrop"
      id="modalUnsaved"
      onHide={() => handleClose()}
    >
      <Modal.Header>
        <Modal.Title>Modify Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <TextField
              InputLabelProps={{
                style: { color: 'dimgrey', backgroundColor: '#fff' },
              }}
              label="New Password "
              type={showPassWord ? 'text' : 'password'}
              fullWidth
              autoComplete="new-password"
              {...register('password', {
                required: true,
                minLength: 8,
              })}
              error={!!errors.password}
              helperText={
                errors.password?.type === 'required'
                  ? 'Password is required'
                  : errors.password?.type === 'minLength'
                    ? 'Password must have at least 8 characters'
                    : ''
              }
              InputProps={{
                endAdornment: watch('password') && (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassWord(!showPassWord)}
                      onMouseDown={(event) => {
                        event.preventDefault();
                      }}
                      edge="end"
                    >
                      {showPassWord ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item>
            <TextField
              InputLabelProps={{
                style: { color: 'dimgrey', backgroundColor: '#fff' },
              }}
              label="Confirm Password "
              type={showConfirmPassWord ? 'text' : 'password'}
              fullWidth
              autoComplete="new-password"
              {...register('password_confirmation', {
                required: true,
                minLength: 8,
                validate: (txt: string) =>
                  txt.toString() === password.current?.toString(),
              })}
              error={!!errors.password_confirmation}
              helperText={
                errors.password_confirmation?.type === 'required'
                  ? 'Password is required'
                  : errors.password_confirmation?.type === 'minLength'
                    ? 'Password must have at least 8 characters'
                    : errors.password_confirmation?.type === 'validate'
                      ? 'Password does not match'
                      : ''
              }
              InputProps={{
                endAdornment: watch('password_confirmation') && (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowConfirmPassWord(!showConfirmPassWord)
                      }
                      onMouseDown={(event) => {
                        event.preventDefault();
                      }}
                      edge="end"
                    >
                      {showConfirmPassWord ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end">
          <div className="d-inline px-2">
            <Button
              variant="default"
              onClick={() => handleClose()}
              className={`${classes.btnCancel}`}
            >
              Cancel
            </Button>
          </div>
          <div className="d-inline px-2">
            <Button
              className={`${classes.btnSave}`}
              onClick={() => handleModifyPass()}
            >
              Modify
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalChangePassword;

type Props = {
  dirtiFields?: any;
  showModalPassword: boolean;
  handleLeave: () => void;
  handleCancel: () => void;
  user: User | null;
};
