import type { ColumnsType, ColumnType } from 'antd/es/table';
import React, { useState } from 'react';
import FilterTab from '../../../../utils/filter-tab';
import { ReactComponent as ArrowDown } from '../../../../assets/img/Arrow-Down-Gray.svg';
import { ReactComponent as ArrowUP } from '../../../../assets/img/Arrow-UP-Gray.svg';
import UseConstante from '../../../../hooks/use-constante';
import {
  exportDateUi,
  exportedDropdownValue,
  handleRegion,
  isCan,
  remplaceTxtValue,
} from '../../../../utils/helper-function';
import { Menu, TermConst } from '../../../../@types/common-types';
import { EntityEnquiries } from '../../../../@types/enquiries';
import FlagItem from '../../../../components/FlagItem/FlagItem';
import classes from '../../../Enquiries/Enquiry.module.scss';
import { ReactComponent as Edit } from '../../../../assets/img/Edit.svg';
import { ReactComponent as Delete } from '../../../../assets/img/Delete.svg';
import { useGetCountryQuery } from '../../../../redux/api/ws/databases/groups';
import { EntityCountry } from '../../../../@types/group/types';
type IProps = {
  handleSorted: (key: string, ruls: 'ASC' | 'DESC') => void;
  enquiryes: Array<EntityEnquiries>;
  editEnquiry: (id: number) => void;
  handleDeleteEnquiry: (id: number) => void;
};
type DataIndex = keyof EntityEnquiries;

export default function ColumnEnquiryList(props: IProps) {
  const [dropDownFilterState, setDropDownFilterState] =
    useState<boolean>(false);
  const [uncheckedId, setUncheckedId] = useState<Array<number>>([]);
  const terms = UseConstante();

  const onChangeFilterColumn = (key: number | undefined) => {
    const found = uncheckedId?.find((uncheckedTitle) => uncheckedTitle === key);
    if (found) {
      setUncheckedId(uncheckedId.filter((t) => t !== key));
    } else {
      setUncheckedId([...uncheckedId, key || 0]);
    }
  };
  const { data: responseCountry } = useGetCountryQuery();

  const countries: Array<EntityCountry> = responseCountry?.country || [];

  const getColumnSearchProps = (
    dataIndex: DataIndex | 'startDate' | 'enquiryType',
  ): ColumnType<EntityEnquiries> => ({
    filterDropdown: ({ close }) =>
      FilterTab<EntityEnquiries>({
        columns: columns,
        dataIndex: dataIndex,
        handleSorted: (dataIndex: string, rule: 'ASC' | 'DESC') =>
          props.handleSorted(dataIndex, rule),
        onChangeFilterColumn: (key: number | undefined) =>
          onChangeFilterColumn(key),
        uncheckedId: uncheckedId,
        close: () => close(),
      }),
    align: 'left',

    filterIcon: () => {
      return dropDownFilterState ? (
        <ArrowDown width={12} height={12} />
      ) : (
        <ArrowUP width={12} height={12} />
      );
    },
    onFilterDropdownOpenChange: (visible) => {
      setDropDownFilterState(visible);
    },
    render: (text: string) => <span>{text}</span>,
  });

  const columns: ColumnsType<EntityEnquiries> = React.useMemo(
    () =>
      [
        {
          title: 'ID',
          dataIndex: 'number',
          key: 1,
          ...getColumnSearchProps('id'),
          render: (text: string) => <span> {text}</span>,
        },

        {
          title: 'CLIENT ID',
          dataIndex: 'client_name',
          key: 2,
          ...getColumnSearchProps('client_name'),
          render: (text: string) => {
            if (text) {
              return (
                <span>
                  <b>{text}</b>
                </span>
              );
            }
          },
        },
        {
          title: 'ENQUIRY TYPE',
          dataIndex: 'enquiry_type',
          key: 3,
          ...getColumnSearchProps('enquiryType'),
          render: (text: string) => {
            if (text) {
              return (
                <span className={`gray-rounded`}>
                  <b>
                    {exportedDropdownValue(
                      terms?.getConst(TermConst.dealType) || [],
                      text,
                    )}
                  </b>
                </span>
              );
            }
          },
        },
        {
          title: 'TOP OBLIGOR',
          dataIndex: 'obligor_name',
          key: 4,
          ...getColumnSearchProps('obligor_name'),
          render: (text: string) => {
            if (text) {
              return (
                <span>
                  <b>{text}</b>
                </span>
              );
            }
          },
        },
        {
          title: 'COUNTRY',
          dataIndex: 'country_id',
          key: 5,
          ...getColumnSearchProps('country_id'),
          render: (text: string) => {
            const region = handleRegion(countries, text);
            return (
              region && (
                <FlagItem code={region.code} region_name={region?.name} />
              )
            );
          },
        },
        {
          title: 'STATUS',
          dataIndex: 'status',
          key: 6,
          ...getColumnSearchProps('status'),
          render: (text: string) => {
            if (text) {
              return (
                <span
                  className={`${
                    text === 'on_hold' || text === 'closed'
                      ? 'gray-rounded-flou'
                      : classes.enqRoundedOrange
                  } `}
                >
                  <b>
                    {exportedDropdownValue(
                      terms?.getConst(TermConst.enquiryStatus) || [],
                      text,
                    )}{' '}
                  </b>
                </span>
              );
            }
          },
        },
        {
          title: 'OUTCOME',
          dataIndex: 'outcome',
          key: 7,
          ...getColumnSearchProps('outcome'),
          render: (text: string) => {
            if (text) {
              return (
                <span className={`gray-rounded-flou`}>
                  {remplaceTxtValue(
                    exportedDropdownValue(
                      terms?.getConst(TermConst.enquiryOutcome),
                      text,
                    ),
                    'No Deal -',
                    '',
                  )}
                </span>
              );
            }
          },
        },
        {
          title: 'START DATE',
          dataIndex: 'start_date',
          key: 8,
          ...getColumnSearchProps('startDate'),
          render: (text: string) => {
            if (text) {
              return (
                <span>
                  <b>{exportDateUi(text)?.toString()}</b>
                </span>
              );
            }
          },
        },
        {
          title: '',
          dataIndex: 'id',
          key: 10,
          width: 120,
          onCell: () => {
            return {
              onClick: (event: any) => {
                event.stopPropagation();
              },
            };
          },
          hidden:
            !isCan('write', Menu.enquiry) && !isCan('delete', Menu.enquiry),
          render: (text: number) => {
            return (
              <>
                {isCan('write', Menu.mandate) && (
                  <span style={{ minWidth: 95 }}>
                    <i
                      className="rounded-icon"
                      onClick={() => props.editEnquiry(text)}
                    >
                      <Edit height="30" />
                    </i>
                  </span>
                )}
                {isCan('delete', Menu.mandate) && (
                  <span style={{ minWidth: 55 }}>
                    <i
                      className="rounded-icon"
                      onClick={() => props.handleDeleteEnquiry(text)}
                    >
                      <Delete height="30" />
                    </i>
                  </span>
                )}
              </>
            );
          },
        },
      ].filter((el) => !el.hidden),
    [props.enquiryes, dropDownFilterState, uncheckedId],
  );

  return { columns, uncheckedId };
}
