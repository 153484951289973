import { Card, Table } from 'antd';
import { useDashboardWhatsNew } from '../../hooks/dashboard/useDashboardWhatsNew';

export const DashboardWhatsNew = () => {
  const { isLoading, animation, onRow, rows, columns, blink } =
    useDashboardWhatsNew();

  return (
    <Card className="dashboard-table-wrapper" size="small">
      <span className="base-card-title">What's new</span>
      <Table
        size="small"
        loading={isLoading}
        rowKey={(record) => `${record.object_id}-${record.log_at}`}
        className="dashboard-table"
        id="dashboard-whatsnew-table"
        pagination={false}
        showHeader={false}
        rowClassName={(record) =>
          `selectRowClassName ${blink(record) ? animation : 'black-icons'}`
        }
        columns={columns}
        onRow={(record) => onRow(record)}
        dataSource={rows}
        scroll={{ y: 405 }}
      />
    </Card>
  );
};
