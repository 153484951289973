import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  EntityParticipation,
  InputFormParticipation,
} from '../../../@types/participation/participation';
import {
  amountFormat,
  checkIsEdit,
  floorNumber,
  isExist,
  nullingEmptyValues,
  setDefaultValues,
  setEmptyUndefinedEditValues,
} from '../../../utils/helper-function';
import { useGetMandateByIdMutation } from '../../../redux/api/ws/deals/deals';
import {
  useCreateParticipationsMutation,
  useGetDealsByIdMutation,
  useUpdateParticipationsByDealIdMutation,
  useUpdateParticipationsMutation,
} from '../../../redux/api/ws/participations/participations';
import { BusinessType, ConterParty } from '../../../@types/conterParty';
import { useSearchCofarcoMutation } from '../../../redux/api/ws/mandates/mandates';
import {
  useGetLegalEntityByIdAndCounterPartyIdMutation,
  useSearchLegalEntityNameMutation,
} from '../../../redux/api/ws/databases/legale-entities';
import { LegalEntity } from '../../../@types/legal-entities';
import { EntityDeals } from '../../../@types/deals/deals';
import { EntityMandate } from '../../../@types/mandates/mandates';
import { useAppDispatch } from '../../../hooks/redux';
import { updateNotificationMessage } from '../../../redux/slice/notificationSlice';
import { updateModalAction } from '../../../redux/slice/modalSlice';
import { useLazyGetCounterPartyByIdQuery } from '../../../redux/api/ws/counterparties/get';
import { FunctionName } from '../../../@types/common-types';
type IProps = {
  defaultFormValues: EntityParticipation | null;
  closingModal: (itemsDataSource: any | null) => void;
};
export default function UseModalParticipations(props: IProps) {
  const [isShowModalUnsavedChange, setIsShowModalUnsavedChange] =
    useState<boolean>(false);
  const [showOtherModal, setShowOtherModal] = useState<{
    id: any;
    func: string;
  }>();

  const [activeMenu, setActiveMenu] = useState<number>(1);

  const [isEdit, setIsEdit] = useState<boolean>(true);
  const [isFormEditing, setIsFormEditing] = useState<boolean>(false);
  const [isCompletedForm, setIsCompletedForm] = useState(false);

  const [selectedDeal, setSelectedDeal] = useState<EntityDeals | null>();
  const [selectedMandate, setSelectedMandate] =
    useState<EntityMandate | null>();

  const [getMandate, { data: mandate }] = useGetMandateByIdMutation();
  const [getDeals, { data: deals, isLoading: isGetDealLoading }] =
    useGetDealsByIdMutation();
  const [counterpartyList, setCounterpartyList] = useState<Array<ConterParty>>(
    [],
  );
  const [getCofarco, { isLoading: isGetCoLoading }] =
    useSearchCofarcoMutation();
  const dispatch = useAppDispatch();

  const [getOptionsLegalEntity, { isLoading: isGetOpLeLoading }] =
    useSearchLegalEntityNameMutation();
  const [updateParticipations] = useUpdateParticipationsMutation();

  const [updateParticipationsByDeals] =
    useUpdateParticipationsByDealIdMutation();

  const [createParticipations] = useCreateParticipationsMutation();

  const [getFinancier] = useLazyGetCounterPartyByIdQuery();

  const [getSelectedLegalEntity, { isLoading: isGetSelectedLeLoading }] =
    useGetLegalEntityByIdAndCounterPartyIdMutation();

  const [legalEntityList, setLegalEntityList] = useState<Array<LegalEntity>>(
    [],
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid, dirtyFields },
    control,
    watch,
    getValues,
    setValue,
    reset,
    setError: setFormError,
    clearErrors: clearFormErrors,
  } = useForm<InputFormParticipation>({
    mode: 'onChange',
    defaultValues: {
      currency: props.defaultFormValues?.currency || 'gbp',
    },
  });

  const counterpartyId = watch('counterpartyId');

  const resetForm = () => {
    const defaultValue = setDefaultValues<any>(watch());
    reset({
      ...defaultValue,
    });
    setIsEdit(false);
    setIsFormEditing(false);
    setSelectedMandate(null);
    setSelectedMandate(null);
    setCounterpartyList([]);
    setLegalEntityList([]);
  };

  const submit = async (data: InputFormParticipation) => {
    const isUpdated = checkIsEdit(data.id || '');
    const paramsMake = {
      ...data,
      minimum_deposit: data.minimumDeposit,
      participant_reference: data.participantReference,
      premium_rate: data.premiumRate,
      amount: floorNumber(data.amount || 0),
      underwriter_legal_entity_id: data.underwriterLegalEntityId,
    };
    if (isUpdated) {
      try {
        const floorValue: any = setEmptyUndefinedEditValues(paramsMake);

        const response = props.defaultFormValues?.isUpdateByDeals
          ? await updateParticipationsByDeals({
              data: {
                ...floorValue,
                counterparty_id: floorValue.counterpartyId,
                lead_line: floorValue.leadLine,
              },
              dealId: selectedDeal?.id as number,
            }).unwrap()
          : await updateParticipations(floorValue).unwrap();
        if (response && response.success) {
          dispatch(
            updateNotificationMessage({
              title: 'Update Participations',
              body: `Participations ${response.participation?.number} has been updated !`,
              show: true,
            }),
          );
          props.closingModal(null);
        }
        resetForm();
      } catch (error: any) {
        console.log(error);
      }
    } else {
      try {
        const response = await createParticipations(
          nullingEmptyValues({
            ...paramsMake,
            deal_id: props.defaultFormValues?.deal_id,
            counterparty_id: data.counterpartyId,
          }),
        ).unwrap();
        if (response && response.success) {
          dispatch(
            updateNotificationMessage({
              title: 'New Participation ',
              body: `Participation ${response.participation.number} has been added  !`,
              show: true,
            }),
          );
          props.closingModal(null);
        }
        resetForm();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleChangeToogle = (itemActive: number) => {
    setActiveMenu(itemActive);
  };

  const handlCounterPartie = async (text: string, type: BusinessType) => {
    try {
      const response = await getCofarco({
        page: 1,
        start: 0,
        limit: 100,
        query: `%${text?.trim()}%`,
        businessType: type,
      }).unwrap();
      const tempOption = response?.counterparty;
      setCounterpartyList(tempOption);
    } catch (error) {
      return [];
    }
  };

  const handleDefaultLegalEntity = async (
    counterpartyId: number,
    legalEntityId: number,
  ) => {
    try {
      const response = await getSelectedLegalEntity({
        counterpartyId,
        underwriterType: 'stamp',
        legalEntityId,
      }).unwrap();
      setLegalEntityList([response.legalentity]);
    } catch (error) {
      return [];
    }
  };

  const getLegalNameOption = async (text: string, counterpartyId: number) => {
    try {
      const response = await getOptionsLegalEntity({
        page: 1,
        start: 0,
        limit: 100,
        query: `${text?.trim()}`,
        counterpartyId: props.defaultFormValues?.isUpdateByDeals
          ? counterpartyId
          : undefined,
        underwriterType: props.defaultFormValues?.isUpdateByDeals
          ? 'stamp'
          : undefined,
      }).unwrap();

      setLegalEntityList(response.legalentity || []);
    } catch (error) {
      return [];
    }
  };

  const getMandatesById = async (id: number) => {
    try {
      const response = await getMandate({
        id,
      }).unwrap();
      setSelectedMandate(response?.mandate);
      return response;
    } catch (error) {
      return [];
    }
  };

  const getDealsById = async (id: number) => {
    try {
      const response = await getDeals({
        id,
      }).unwrap();
      setSelectedDeal(response?.deal);
      if (!props.defaultFormValues?.currency) {
        setValue('currency', response?.deal?.currency);
      }
      if (!props.defaultFormValues?.premium_rate) {
        setValue('premiumRate', response?.deal?.premium_rate);
      }
      if (!props.defaultFormValues?.minimum_deposit) {
        setValue('minimumDeposit', response?.deal?.minimum_deposit);
      }
      return response;
    } catch (error) {
      return [];
    }
  };

  React.useEffect(() => {
    if (props.defaultFormValues) {
      const selectParticipations = props.defaultFormValues;
      if (selectParticipations.counterparty) {
        setCounterpartyList([selectParticipations.counterparty]);
        if (selectParticipations.underwriter_legal_entity_id)
          handleDefaultLegalEntity(
            selectParticipations.counterparty_id,
            selectParticipations.underwriter_legal_entity_id,
          );
      }
      reset({
        counterpartyId: selectParticipations.counterparty_id,
        underwriterLegalEntityId:
          selectParticipations.underwriter_legal_entity_id,
        amount: amountFormat(
          Number.parseFloat(
            (selectParticipations?.amount as string) || '0.00',
          )?.toFixed(2),
        ) as string,
        currency: selectParticipations?.currency,
        id: selectParticipations.id,
        minimumDeposit: selectParticipations.minimum_deposit,
        participantReference: selectParticipations.participant_reference,
        premiumRate: selectParticipations.premium_rate,
        status: selectParticipations.status,
        leadLine: isExist(selectParticipations.lead_line) ? true : false,
      });
      if (selectParticipations.mandate_id) {
        getMandatesById(selectParticipations.mandate_id);
      }
      if (selectParticipations?.deal_id) {
        getDealsById(selectParticipations?.deal_id);
      }
    }
  }, [props.defaultFormValues]);

  React.useEffect(() => {
    const subscription = watch((value) => {
      let isCompletedCurrency = false;
      const amountValue = (floorNumber(value?.amount) as number) || 0;
      if (amountValue > 0) {
        isExist(value.currency)
          ? (isCompletedCurrency = true)
          : (isCompletedCurrency = false);
      } else {
        isCompletedCurrency = true;
      }

      const isCompletedRequired = value.counterpartyId;
      setIsCompletedForm(
        isCompletedRequired && isCompletedCurrency ? true : false,
      );
      setIsEdit(isExist(value.id) && value.id !== -1 ? true : false);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const runSearch = React.useCallback(
    async (key: keyof InputFormParticipation, value: string) => {
      if (key === 'counterpartyId') {
        const type =
          selectedMandate?.mandate_type === 'insurance'
            ? 'underwriter'
            : 'financier';
        await handlCounterPartie(value, type);
      }
      if (key === 'underwriterLegalEntityId') {
        getLegalNameOption(value, counterpartyId);
      }
    },
    [selectedMandate],
  );

  const showLegalEntity = (idLgl: number | null) => {
    const found = (legalEntityList || []).find(
      (el) => el.id?.toString() === idLgl?.toString(),
    );
    if (found) {
      dispatch(
        updateModalAction({
          data: {
            ...found,
            group_id: found?.group?.id,
            group_name: found?.group?.name,
          },
          isAdd: true,
          type: 'legal_entity',
        }),
      );
    }
  };

  const showCounterParty = async () => {
    const financierId = counterpartyId;
    if (financierId) {
      const resp = await getFinancier({
        id: financierId?.toString(),
      }).unwrap();
      if (resp?.success) {
        dispatch(
          updateModalAction({
            data: resp?.counterparty,
            isAdd: true,
            type:
              selectedMandate?.mandate_type === 'insurance'
                ? 'underwriter'
                : 'financier',
          }),
        );
      }
    }
  };

  const handleShowOtherModal = (id: any, func: FunctionName) => {
    if (isDirty && isEdit) {
      setIsShowModalUnsavedChange(true);
      setShowOtherModal({ id, func });
    } else {
      handleLeaveModalWithoutSave(id, func);
    }
  };

  const handleLeaveModalWithoutSave = (id: any, func: FunctionName) => {
    showFunctions(id, func);
    setIsShowModalUnsavedChange(false);
    isEdit && resetForm();
  };

  const showFunctions = useCallback(
    (id: any, func: FunctionName) => {
      switch (func) {
        case 'legal_entity':
          showLegalEntity(id);
          break;
        case 'counterparty':
          showCounterParty();
          break;
        default:
          break;
      }
    },
    [showLegalEntity, showLegalEntity],
  );

  return {
    activeMenu,
    setActiveMenu,
    register,
    handleSubmit,
    control,
    watch,
    getValues,
    setValue,
    reset,
    errors,
    isDirty,
    setFormError,
    clearFormErrors,
    resetForm,
    submit,
    isCompletedForm,
    handleChangeToogle,
    isEdit,
    runSearch,
    counterpartyList,
    legalEntityList,
    isGetEntityLoading: isGetOpLeLoading || isGetSelectedLeLoading,
    mandate,
    deals,
    selectedDeal,
    selectedMandate,
    isFormEditing,
    isValid,
    isLoading: isGetDealLoading,
    isGetCoLoading,
    isShowModalUnsavedChange,
    handleShowOtherModal,
    handleLeaveModalWithoutSave,
    setIsShowModalUnsavedChange,
    showFunctions,
    showOtherModal,
    dirtyFields,
  };
}
