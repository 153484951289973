import { NavLink } from "react-router-dom";
import { ReactComponent as ClientLogo } from "../../assets/img/client.svg";
import { ReactComponent as FinancyLogo } from "../../assets/img/financy.svg";
import { ReactComponent as UnderWriterLogo } from "../../assets/img/underwriter.svg";
import { ReactComponent as PartnerLogo } from "../../assets/img/partner.svg";
import { ReactComponent as ObligorLogo } from "../../assets/img/obligor.svg";
import { isCanReadMenu } from "../../utils/helper-function";
import { Menu } from "../../@types/common-types";
type Props = {
  activeMenu: number;
  onClickItem: (route: string, index: number) => void;
};

const CounterPartiesItems = ({ activeMenu, onClickItem }: Props) => {
  return (
    <ul>
      {isCanReadMenu(Menu.client) && (
        <li onClick={() => onClickItem("/counterparty/client", 1)}>
          <NavLink
            className={`btn-nav btn primary ${activeMenu === 1 ? "active-link" : ""
              }`}
            to="#"
          >
            <ClientLogo className="me-1" />
            Clients
          </NavLink>
        </li>
      )}
      {isCanReadMenu(Menu.underwriter) && (
        <li onClick={() => onClickItem("/counterparty/underwriter", 3)}>
          <NavLink
            className={`btn-nav btn primary ${activeMenu === 3 ? "active-link" : ""
              }`}
            to="#"
          >
            <UnderWriterLogo className="me-1" />
            Underwriters
          </NavLink>
        </li>
      )}
      {isCanReadMenu(Menu.financier) && (
        <li onClick={() => onClickItem("/counterparty/financier", 2)}>
          <NavLink
            className={`btn-nav btn primary ${activeMenu === 2 ? "active-link" : ""
              }`}
            to="#"
          >
            <FinancyLogo className="me-1" />
            Financiers
          </NavLink>
        </li>
      )}
      {isCanReadMenu(Menu.partner) && (
        <li onClick={() => onClickItem("/counterparty/partner", 4)}>
          <NavLink
            className={`btn-nav btn primary ${activeMenu === 4 ? "active-link" : ""
              }`}
            to="#"
          >
            <PartnerLogo className="me-1" />
            Partners
          </NavLink>
        </li>
      )}
      {isCanReadMenu(Menu.obligor) && (
        <li onClick={() => onClickItem("/counterparty/obligor", 5)}>
          <NavLink
            className={`btn-nav btn primary ${activeMenu === 5 ? "active-link" : ""
              }`}
            to="#"
          >
            <ObligorLogo className="me-1" /> Obligors
          </NavLink>
        </li>
      )}
    </ul>
  );
};

export default CounterPartiesItems;
