import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Menu } from '../../@types/common-types';
import IndicationActionContent from './IndicationActionContent';
import classes from './IndicationTableContent.module.scss';
import {
  Indication,
  IndicationFilterType,
} from '../../@types/Indication/indication';
import { useListIndicationQuery } from '../../redux/api/ws/indication/get';
import { Table } from 'antd';
import TableDisplay from '../Pagination/TableDisplay';
import TablePagination from '../Pagination/TablePagination';
import useIndication from './useIndication';
import { debounce, isEqual, omit } from 'lodash';
import IndicationFilter from './IndicationFilter/IndicationFIlter';
import DeleteModal from '../Modal/DeleteModal';
import { isCan } from '../../utils/helper-function';

export const ContextIndication = createContext<any>(null);

const ONE_MINUTE = 60000;

const IndicationTableContent = () => {
  const [indications, setIndications] = useState<Indication[]>([]);
  const [filter, setFilter] = useState<IndicationFilterType>({});
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(20);
  const [totalRow, setTotalRow] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>(1);
  const { txt } = useContext(ContextIndication);

  const {
    indicationsColumns,
    currentColumns,
    filterData,
    sortData,
    showDeleteModal,
    indication,
    deleteLoading,
    defaultFilter,
    onAddElement,
    setShowDeleteModal,
    updateFilter,
    toggleFilterCollapse,
    getFilter,
    onEditElement,
    deleteIndicationCallback,
  } = useIndication();

  const onChangePaginate = (p: string) => {
    if (!isNaN(parseInt(p)) && isFinite(parseInt(p))) {
      setCurrentPage(parseInt(p));
    }
  };

  const { data, isLoading, refetch } = useListIndicationQuery(
    {
      page: currentPage,
      start: (currentPage - 1) * perPage,
      limit: perPage,
      filter: getFilter(filter),
      sort: sortData,
    },
    {
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
      refetchOnFocus: true,
    },
  );

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, ONE_MINUTE);
    return () => clearInterval(interval);
  }, []);

  const onSearch = () => {
    setCurrentPage(1);
    if (!isEqual(filterData, filter)) {
      setFilter(filterData);
    }
  };

  useEffect(() => {
    if (data) {
      setIndications(data.indication);
      setTotalRow(data.total);
      setTotalPage(Math.ceil(data.total / perPage));
    }
  }, [data]);

  const debounceSearch = useCallback(
    debounce((txt) => {
      setFilter({
        ...filterData,
        filter: {
          property: 'filter',
          value: `%${txt}%`,
          operator: 'like',
        },
      });
    }, 750),
    [],
  );

  useEffect(() => {
    if (txt) {
      debounceSearch(txt);
    } else {
      setFilter(filterData);
    }
  }, [txt]);

  useEffect(() => {
    if (
      Object.values(omit(filterData, ['team', 'result_type'])).length === 0 &&
      isEqual(filterData, defaultFilter)
    ) {
      setFilter(filterData);
    }
  }, [filterData]);

  return (
    <IndicationActionContent
      activeLink={Menu.indication}
      handleFilter={() => toggleFilterCollapse()}
      addBtn={onAddElement}
    >
      <IndicationFilter
        updateFilter={updateFilter}
        onSearch={onSearch}
        filterData={filterData}
      />
      <DeleteModal
        title="Delete indication"
        show={showDeleteModal}
        groupeName={`${indication?.number ?? ''}`}
        isLoadingDelete={deleteLoading}
        validDelete={() => deleteIndicationCallback()}
        handleClose={() => setShowDeleteModal(false)}
      />

      <div style={{ maxWidth: '100%', overflowX: 'hidden', overflowY: 'clip' }}>
        <div style={{ maxWidth: '100%', overflowX: 'auto', overflowY: 'clip' }}>
          <Table
            tableLayout="fixed"
            loading={isLoading}
            rowClassName={(_: any, index: number) =>
              `${index % 2 === 1 ? classes.tablelight : ''} ${classes.tablerow}`
            }
            columns={indicationsColumns.filter(
              (c) =>
                c.key && currentColumns.find((f) => f === c.key) !== undefined,
            )}
            className={`${classes.table}`}
            dataSource={indications}
            pagination={false}
            rowKey="id"
            onRow={(record: any) => {
              return {
                onClick: () => {
                  if (
                    isCan('write', Menu.indication) ||
                    isCan('read', Menu.indication)
                  ) {
                    const ind = indications?.find((el) => el.id === record?.id);
                    if (ind) onEditElement(ind);
                  }
                },
              };
            }}
          />
        </div>
        <div className="row mx-0">
          <div className="col-6">
            <TableDisplay
              currentPage={currentPage}
              perPage={perPage}
              totalRow={totalRow}
              options={[10, 25, 50, 100]}
              onChage={(e) => setPerPage(e)}
            />
          </div>
          <div className="col-6">
            <TablePagination
              onStart={(v) => setCurrentPage(v)}
              onPrevious={(v) => setCurrentPage(v)}
              onNext={(v) => setCurrentPage(v)}
              onEnd={(v) => setCurrentPage(v)}
              currentPage={currentPage}
              totalPage={totalPage}
              onChange={(e) => onChangePaginate(e.target.value)}
            />
          </div>
        </div>
      </div>
    </IndicationActionContent>
  );
};

export default IndicationTableContent;
