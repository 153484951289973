import { Worker, Viewer } from "@react-pdf-viewer/core";
import {
  RenderCurrentScaleProps,
  RenderZoomInProps,
  RenderZoomOutProps,
  zoomPlugin,
} from "@react-pdf-viewer/zoom";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import {
  fullScreenPlugin,
  RenderEnterFullScreenProps,
} from "@react-pdf-viewer/full-screen";
import { getFilePlugin, RenderDownloadProps } from "@react-pdf-viewer/get-file";

// Import styles
import "@react-pdf-viewer/full-screen/lib/styles/index.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";

const PdfViewer = ({ noteUrl, customPdfName }: Props) => {
  const zoomPluginInstance = zoomPlugin({ enableShortcuts: false });
  const { CurrentScale, ZoomIn, ZoomOut } = zoomPluginInstance;
  const getFilePluginInstance = getFilePlugin({
    fileNameGenerator: () => {
      return `${customPdfName}`;
    },
  });
  const { Download } = getFilePluginInstance;

  const fullScreenPluginInstance = fullScreenPlugin();
  const { EnterFullScreen } = fullScreenPluginInstance;

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <div
        style={{
          border: "1px solid rgba(0, 0, 0, 0.1)",
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div
          style={{
            alignItems: "center",
            backgroundColor: "#eeeeee",
            borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
            display: "flex",
            justifyContent: "space-between",
            padding: "2px 0px 0px 4px",
          }}
        >
          <div>
            <Download>
              {(props: RenderDownloadProps) => (
                <span
                  style={{
                    backgroundColor: "#851001",
                    border: "none",
                    borderRadius: "4px",
                    color: "#ffffff",
                    cursor: "pointer",
                    padding: "4px",
                  }}
                  onClick={props.onClick}
                >
                  <FileDownloadIcon
                    titleAccess="Download this pdf note"
                    style={{ fontSize: 20 }}
                  />
                </span>
              )}
            </Download>

            <EnterFullScreen>
              {(props: RenderEnterFullScreenProps) => (
                <span
                  style={{
                    backgroundColor: "#851001",
                    border: "none",
                    borderRadius: "4px",
                    color: "#ffffff",
                    cursor: "pointer",
                    padding: "4px",
                    marginInlineStart: "8px",
                  }}
                  onClick={props.onClick}
                >
                  <OpenInFullIcon
                    titleAccess="Open in FullScreen"
                    style={{ fontSize: 20 }}
                  />
                </span>
              )}
            </EnterFullScreen>
          </div>

          <div
            style={{
              alignItems: "center",
              backgroundColor: "#eeeeee",
              borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
              display: "flex",
              justifyContent: "center",
              padding: "4px",
            }}
          >
            <div style={{ padding: "0px 2px" }}>
              <ZoomOut>
                {(prop: RenderZoomOutProps) => (
                  <span
                    style={{
                      backgroundColor: "#851001",
                      border: "none",
                      borderRadius: "4px",
                      color: "#ffffff",
                      cursor: "pointer",
                      padding: "4px",
                    }}
                    onClick={prop.onClick}
                  >
                    <ZoomOutIcon />
                  </span>
                )}
              </ZoomOut>
            </div>
            <div style={{ padding: "0px 2px" }}>
              <CurrentScale>
                {(prop: RenderCurrentScaleProps) => (
                  <>{`${Math.round(prop.scale * 100)}%`}</>
                )}
              </CurrentScale>
            </div>
            <div style={{ padding: "0px 2px" }}>
              <ZoomIn>
                {(prop: RenderZoomInProps) => (
                  <span
                    style={{
                      backgroundColor: "#851001",
                      border: "none",
                      borderRadius: "4px",
                      color: "#ffffff",
                      cursor: "pointer",
                      padding: "4px",
                    }}
                    onClick={prop.onClick}
                  >
                    <ZoomInIcon />
                  </span>
                )}
              </ZoomIn>
            </div>
          </div>
        </div>
        <div
          style={{
            flex: 1,
            overflow: "hidden",
          }}
        >
          <Viewer
            fileUrl={noteUrl}
            plugins={[
              zoomPluginInstance,
              fullScreenPluginInstance,
              getFilePluginInstance,
            ]}
          />
        </div>
      </div>
    </Worker>
  );
};

export default PdfViewer;

type Props = {
  noteUrl: string;
  customPdfName: string;
};
