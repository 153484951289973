import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  BrokerItem,
  EntityAttachementDeals,
  EntityDeals,
  InputFormDeals,
} from '../../../@types/deals/deals';
import { useAppDispatch } from '../../../hooks/redux';
import {
  amountFormat,
  checkIsEdit,
  concatString,
  exportDateUi,
  exportDateWs,
  floorNumber,
  getObjectDifferences,
  initPercentage,
  isAcceptedFile,
  isExist,
  nullingEmptyValues,
  setDefaultValues,
  setEmptyUndefinedEditValues,
} from '../../../utils/helper-function';
import { useCallback } from 'react';

import {
  useGetOwnerQuery,
  useGetPeopleByConterPartyIdMutation,
  useSearchCofarcoMutation,
  useSearchMandateNameMutation,
} from '../../../redux/api/ws/mandates/mandates';
import { BusinessType, ConterParty } from '../../../@types/conterParty';
import {
  useGetLegalEntityByIdMutation,
  useSearchLegalEntityNameMutation,
} from '../../../redux/api/ws/databases/legale-entities';
import {
  useCreateDealsMutation,
  useGetDealsAttachementMutation,
  useGetMandateByIdMutation,
  useUploadDealsAttachementMutation,
  useUpdateDealsMutation,
  useLazyGetLimitsByDealsIdQuery,
  useLazyGetParticipationByDealsIdQuery,
  useGetBrokerageMutation,
} from '../../../redux/api/ws/deals/deals';
import {
  EntityMandate,
  EntityOwnerName,
} from '../../../@types/mandates/mandates';
import { LegalEntity } from '../../../@types/legal-entities/index';
import { People } from '../../../@types/people';
import { updateNotificationMessage } from '../../../redux/slice/notificationSlice';
import { updateModalAction } from '../../../redux/slice/modalSlice';
import { useLazyCofarcoTeamByIdQuery } from '../../../redux/api/ws/databases/cofarco-teams';
import { useLazyGetCounterPartyByIdQuery } from '../../../redux/api/ws/counterparties/get';
import { useGetCounterPartyPartnerByIdMutation } from '../../../redux/api/ws/enquiries/enquiries';
import { Item } from './Forms/ColumnBrokerage';
import { usePostBrokerageMutation } from '../../../redux/api/ws/deals/deals';
import { FunctionName } from '../../../@types/common-types';
import { isEmpty } from 'lodash';
import { useLazyPeopleByIdQuery } from '../../../redux/api/ws/databases/people';

type IProps = {
  defaultFormValues: any | null;
  closingModal: (itemsDataSource: EntityDeals | null) => void;
};

export default function UseModalDeals(props: IProps) {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, dirtyFields },
    control,
    watch,
    getValues,
    setValue,
    reset,
    setError: setFormError,
    clearErrors: clearFormErrors,
  } = useForm<InputFormDeals>({
    mode: 'onBlur',
    defaultValues: {
      insured: '% 0.0',
      insured_gross: '0.00',
      deductible: '0.00',
      amount: '0.00',
      individual_limits: '0.00',
      gross_brokerage: '% 0.000000',
    },
  });
  const partner_id = watch('partner_id');
  const mandate_id = watch('mandate_id');
  const number = watch('number');
  const brokerageTable = watch('brokerageTable');
  const id = watch('id');

  const [
    getLimitsByDeals,
    { data: dataSourceLimits, isLoading: isLoadingGetLimits },
  ] = useLazyGetLimitsByDealsIdQuery();

  const [
    getParticipationByDeals,
    { data: dataSourceParticipation, isLoading: isLoadingGetParticipation },
  ] = useLazyGetParticipationByDealsIdQuery();

  const [mandateType, setMandateType] = useState<string>('');
  const [activeMenu, setActiveMenu] = useState<number>(1);

  const [isFormEditing, setIsFormEditing] = useState<boolean>(false);
  const [isCompletedForm, setIsCompletedForm] = useState(false);
  const [brokerAttachementDataSource, setBrokerAttachementDataSource] =
    useState<Array<Item>>([]);
  const [isShowModalUnsavedChange, setIsShowModalUnsavedChange] =
    useState<boolean>(false);
  const [showOtherModal, setShowOtherModal] = useState<{
    id: any;
    func: string;
  }>();
  const dispatch = useAppDispatch();
  const [
    searchMandate,
    { isLoading: isLoadingSearchMandate, isLoading: isLoadingMandate },
  ] = useSearchMandateNameMutation();
  const [
    getOptionsLegalEntity,
    { data: legalEntityOption, isLoading: isLoadingLegalEntity },
  ] = useSearchLegalEntityNameMutation();

  const [createDeals] = useCreateDealsMutation();

  const [updateDeals] = useUpdateDealsMutation();

  const [getMandate] = useGetMandateByIdMutation();

  const [getLegalEntityById] = useGetLegalEntityByIdMutation();

  const [getCofarcoTeam] = useLazyCofarcoTeamByIdQuery();

  const [getPartner, { isLoading: isGetPartnerLoading }] =
    useGetCounterPartyPartnerByIdMutation();

  const [getBrokerage] = useGetBrokerageMutation();

  const [
    addAttachment,
    {
      data: responseAttachement,
      isSuccess: isSuccessAttachement,
      isLoading: isLoadingAttachement,
    },
  ] = useUploadDealsAttachementMutation();

  const [mandatesById, setMandatesById] = useState<EntityMandate | null>();

  const [getCofarco, { data: clients, isLoading }] = useSearchCofarcoMutation();

  const [getPeopleByCounterPartie] = useGetPeopleByConterPartyIdMutation();

  const [createBrokerage] = usePostBrokerageMutation();

  const [
    getAttachement,
    {
      isSuccess: isSuccessGetAttachement,
      data: attachementResponse,
      reset: resetAttachementDeals,
    },
  ] = useGetDealsAttachementMutation();

  const [clientList, setClientList] = useState<Array<ConterParty>>([]);
  const [partenerList, setPartenerList] = useState<Array<ConterParty>>([]);
  const [mandatesList, setMandatesList] = useState<Array<EntityMandate>>([]);
  const [attachementsDataSource, setAttachementsDataSource] = useState<
    Array<EntityAttachementDeals>
  >(attachementResponse?.attachment || []);
  const [getFinancier] = useLazyGetCounterPartyByIdQuery();
  const [txtView, setTxtView] = useState<{
    top_obligor_country_id: string;
    obligor: any;
    leadLine: any;
  }>({
    top_obligor_country_id: '',
    obligor: null,
    leadLine: null,
  });

  const [legalEntityList, setLegalEntityList] = useState<Array<LegalEntity>>(
    [],
  );

  const [partnerEntityList, setPartnerEntityList] = useState<
    Array<LegalEntity>
  >([]);

  const [partenersPeople, setPartenersPeople] = useState<Array<People>>([]);

  const [getPeopleById] = useLazyPeopleByIdQuery();

  const { data: ownerList } = useGetOwnerQuery();

  const optionUserNameOwner =
    ownerList?.user
      .map((el: EntityOwnerName) => {
        return {
          ...el,
          in_charge_user_id: el.id,
          userName: concatString(el.lastname, el.firstname),
        };
      })
      ?.filter((el: EntityOwnerName) => el.team === 'cstf') || [];

  const resetForm = () => {
    const defaultValue = setDefaultValues<InputFormDeals>(watch());
    reset({
      ...defaultValue,
      insured: '%0.0',
      insured_gross: '0.00',
      deductible: '0.00',
      amount: '0.00',
      individual_limits: '0.00',
      gross_brokerage: '% 0.000000',
    });
    setTxtView({
      top_obligor_country_id: '',
      obligor: null,
      leadLine: null,
    });

    setClientList([]);
    setPartenerList([]);
    setPartnerEntityList([]);
    setPartenersPeople([]);
    setMandatesList([]);
    setMandatesById(null);
    setIsFormEditing(false);
    setBrokerAttachementDataSource([]);
    setAttachementsDataSource([]);
    resetAttachementDeals();
    setActiveMenu(1);
  };

  useEffect(() => {
    if (activeMenu === 2) {
      getParticipationByDeals({ id });
    }
    if (activeMenu === 3) {
      getLimitsByDeals({ id });
    }
  }, [activeMenu]);

  const sortedParticipation = (sortParams: string) => {
    getParticipationByDeals({ id, sort: sortParams });
  };

  const sortedLimits = (sortParams: string) => {
    getLimitsByDeals({ id, sort: sortParams });
  };

  const getMandatesByName = async (text: string) => {
    try {
      const response = await searchMandate({
        page: 1,
        start: 0,
        limit: 100,
        query: `%${text?.trim()}%`,
      }).unwrap();
      setMandatesList(response.mandate);
      return response;
    } catch (error) {
      return [];
    }
  };

  const getMandatesById = async (id: number) => {
    try {
      const response = await getMandate({
        id,
      }).unwrap();
      setMandatesById(response.mandate || []);
      return response;
    } catch (error) {
      return [];
    }
  };

  const handlCounterPartie = async (
    text?: string,
    type?: BusinessType,
    id?: number,
  ) => {
    try {
      const params = {
        page: 1,
        start: 0,
        limit: 100,
        query: text ? `%${text?.trim()}%` : undefined,
        businessType: type || 'client',
      };

      const response = await getCofarco(
        id
          ? {
              ...params,
              filter: JSON.stringify([
                { property: 'id', operator: '=', value: id },
              ]),
            }
          : { ...params },
      ).unwrap();
      const tempOption = response.counterparty;
      if (type === 'client') {
        setClientList(tempOption);
      }
      if (type === 'partner') {
        setPartenerList(tempOption);
      }
    } catch (error) {
      return [];
    }
  };

  const getLegalNameOption = async (
    text: string | undefined,
    counterpartyId: number,
    type: string,
  ) => {
    try {
      const response = await getOptionsLegalEntity({
        page: 1,
        start: 0,
        limit: 100,
        query: text ? `%${text?.trim()}%` : text,
        counterpartyId: counterpartyId,
      }).unwrap();
      if (type === 'client_legal_entity_id') {
        setLegalEntityList(response.legalentity || []);
      }
      if (type === 'partner_legal_entity_id') {
        setPartnerEntityList(response.legalentity || []);
      }
    } catch (error) {
      return [];
    }
  };

  const getPeople = async (
    id: number | string,
    type: string = 'partner_contact_id',
    query?: string,
  ) => {
    if (id) {
      try {
        const resp = await getPeopleByCounterPartie({ id, query }).unwrap();
        const floorData =
          resp.people?.map((el: any) => ({
            ...el,
            userName: concatString(el.lastname, el.firstname),
          })) || [];
        if (type === 'partner_contact_id') {
          setPartenersPeople(floorData);
          return;
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handlePartnerCounterParty = async (id: number) => {
    try {
      const resp = await getPartner({ id }).unwrap();
      setPartenerList([resp.counterparty]);
    } catch (error) {
      return [];
    }
  };

  const runSearch = useCallback(
    async (key: keyof InputFormDeals, value: string) => {
      if (value !== undefined && value !== null) {
        if (key === 'mandate_id') {
          await getMandatesByName(value);
        }
        if (key === 'client_id') {
          await handlCounterPartie(value, 'client');
        }
        if (key === 'partner_id') {
          await handlCounterPartie(value, 'partner');
        }
        if (key === 'client_legal_entity_id') {
          const found = mandatesList?.find((el) => el.id === mandate_id);
          if (found?.client_id) {
            await getLegalNameOption(
              value,
              found?.client_id,
              'client_legal_entity_id',
            );
          }
        }
        if (key === 'partner_legal_entity_id' && partner_id) {
          await getLegalNameOption(
            value,
            partner_id,
            'partner_legal_entity_id',
          );
        }
        if (key === 'partner_contact_id' && partner_id) {
          await getPeople(partner_id as number, 'partner_contact_id', value);
        }
      }
    },
    [mandatesList, mandate_id, partner_id],
  );

  React.useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'mandate_id') {
        if (value.mandate_id) {
          getMandatesById(value.mandate_id);

          const found = mandatesList?.find((el) => el.id === value.mandate_id);

          if (found) {
            setClientList([found.client]);
            setValue('client_id', found.client_id);
            setMandateType(found.mandate_type);
            setValue('in_charge_user_id', found.in_charge_user_id);
            getLegalNameOption(
              undefined,
              found.client_id,
              'client_legal_entity_id',
            );
          }
          setValue('client_legal_entity_id', null);
          setLegalEntityList([]);
        } else {
          setMandatesById(null);
          setValue('client_id', null);
          setValue('client_legal_entity_id', null);
          setClientList([]);
          setLegalEntityList([]);
        }
      }
      if (name === 'partner_id') {
        if (isExist(value.partner_id)) {
          getLegalNameOption(
            undefined,
            value.partner_id as number,
            'partner_legal_entity_id',
          );

          getPeople(
            value.partner_id as number,
            'partner_contact_id',
            undefined,
          );
        } else {
          setValue('partner_legal_entity_id', null);
          setValue('partner_contact_id', null);
          setPartnerEntityList([]);
          setPartenersPeople([]);
        }
      }
      if (name === 'amount') {
        if (Number(value.amount) >= 0) {
          setValue('currency', null);
          clearFormErrors('currency');
        } else {
          setFormError('currency', {
            message: 'Currency is required',
          });
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, mandatesList]);

  const submit = async (data: InputFormDeals) => {
    const isUpdated = checkIsEdit(data.id || '');

    const params: InputFormDeals = {
      ...data,
      inception_date: exportDateWs(data.inception_date) as string,
      expiry_date: exportDateWs(data.expiry_date) as string,
      non_renewal_notice_date: exportDateWs(
        data.non_renewal_notice_date,
      ) as string,
      insured: floorNumber(data?.insured || 0.0),
      amount: floorNumber(data?.amount || 0),
      individual_limits: floorNumber(data?.individual_limits),
      deductible: floorNumber(data?.deductible),
      insured_gross: floorNumber(data?.insured_gross),
      gross_brokerage: floorNumber(data?.gross_brokerage),
      in_charge_user_id: data?.in_charge_user_id || null,
      partner_contact_id: data.partner_contact_id || null,
      partner_id: data?.partner_id || null,
    };
    if (isUpdated) {
      try {
        const objectDiff = getObjectDifferences(
          props.defaultFormValues,
          params,
        );
        const response = await updateDeals(
          setEmptyUndefinedEditValues({ ...objectDiff, id }),
        ).unwrap();

        if (response && response.success) {
          if (isExist(brokerageTable)) {
            const makeBroker: Array<BrokerItem> =
              brokerAttachementDataSource?.map((el) => {
                return {
                  dealId: id,
                  partnerId: el.brokerId?.key || null,
                  partnerContactId: el.brokerContact?.key || null,
                  partnerRole: el.brokerRole?.toString(),
                  netBrokerage: floorNumber(el.netBroker) as string,
                  brokerReference: el.brokerReference,
                };
              });
            await createBrokerage({
              brokers: makeBroker,
              id: response?.deal?.id,
            }).unwrap();
          }
          props.closingModal(null);
          dispatch(
            updateNotificationMessage({
              title: 'Update Deals',
              body: `Deals ${response.deal?.number} has been updated !`,
              show: true,
            }),
          );
        }
        resetForm();
      } catch (error: any) {
        console.log(error);
      }
    } else {
      try {
        const response = await createDeals(nullingEmptyValues(params)).unwrap();
        if (response && response.success) {
          if (isExist(brokerageTable)) {
            const makeBroker: Array<BrokerItem> =
              brokerAttachementDataSource?.map((el) => {
                return {
                  dealId: response?.deal?.id,
                  partnerId: el.brokerId?.key || null,
                  partnerContactId: el.brokerContact?.key || null,
                  partnerRole: el.brokerRole?.toString(),
                  netBrokerage: floorNumber(el.netBroker) as string,
                  brokerReference: el.brokerReference,
                };
              });
            await createBrokerage({
              brokers: makeBroker,
              id: response?.deal?.id,
            }).unwrap();
          }
          dispatch(
            updateNotificationMessage({
              title: 'New Deals',
              body: `Deals  ${response.deal?.number} has been added  !`,
              show: true,
            }),
          );
          props.closingModal(response.deal);
        }
        resetForm();
      } catch (error: any) {
        // if (errorCreate) {
        //   dispatch(
        //     updateNotificationMessage({
        //       title: 'Error',
        //       body: `Error ${errorCreate?.message}`,
        //       show: true,
        //     })
        //   );
        // }
      }
    }
  };

  React.useEffect(() => {
    if (props.defaultFormValues) {
      const selectedDeals = props.defaultFormValues;
      if (selectedDeals?.mandate_id) {
        try {
          getMandatesById(selectedDeals?.mandate_id).then((resp: any) => {
            if (resp && resp?.mandate) {
              setMandatesList([resp?.mandate]);
              /*if (resp?.mandate && resp?.mandate?.in_charge_user_id) {
                                setValue('in_charge_user_id', resp?.mandate.in_charge_user_id);
                            }*/

              if (resp?.mandate?.client) {
                setClientList([resp?.mandate?.client]);
                setValue('client_id', resp?.mandate?.client?.id);
                getLegalNameOption(
                  undefined,
                  resp?.mandate?.client?.id,
                  'client_legal_entity_id',
                );
              }
              setMandateType(resp?.mandate.mandate_type);
              if (resp?.mandate?.client?.id) {
                handlCounterPartie(
                  undefined,
                  'client',
                  resp?.mandate?.client?.id,
                );
              }
            }
          });
        } catch (error) {
          console.log(error);
        }

        if (selectedDeals?.client_legal_entity_id) {
          try {
            getLegalEntityById({
              id: selectedDeals?.client_legal_entity_id,
            }).then((resp: any) => {
              if (resp?.data?.legalentity) {
                setLegalEntityList([resp?.data?.legalentity]);
              }
            });
          } catch (error) {
            console.log(error);
          }
        }

        if (selectedDeals?.partner_id) {
          handlePartnerCounterParty(selectedDeals?.partner_id);
          if (selectedDeals?.partner_contact_id) {
            getPeople(selectedDeals?.partner_id);
          }
          if (selectedDeals?.partner_legal_entity_id) {
            try {
              getLegalEntityById({
                id: selectedDeals?.partner_legal_entity_id,
              }).then((resp: any) => {
                if (resp?.data?.legalentity) {
                  setPartnerEntityList([resp?.data?.legalentity]);
                }
              });
            } catch (error) {
              console.log(error);
            }
          }
        }

        setTxtView({
          top_obligor_country_id: selectedDeals?.top_obligor_country_id,
          obligor: selectedDeals?.obligor,
          leadLine: selectedDeals?.lead_line,
        });
      }
      if (selectedDeals?.id) {
        try {
          getAttachement({ id: selectedDeals?.id });
        } catch (error) {
          console.log(error);
        }
      }

      reset({
        ...selectedDeals,
        amount: amountFormat(
          Number.parseFloat(selectedDeals?.amount || 0)?.toFixed(2),
        ),
        expiry_date: exportDateUi(selectedDeals?.expiry_date) as string,
        inception_date: exportDateUi(selectedDeals.inception_date) as string,
        non_renewal_notice_date: exportDateUi(
          selectedDeals.non_renewal_notice_date,
        ) as string,
        insured_gross: amountFormat(selectedDeals?.insured_gross),
        insured: initPercentage(selectedDeals.insured),
        deductible: amountFormat(
          Number.parseFloat(selectedDeals?.deductible || 0)?.toFixed(2),
        ),
        gross_brokerage: selectedDeals.gross_brokerage
          ? '% ' + selectedDeals.gross_brokerage
          : '% 0.000000',
      });
    }
  }, [props.defaultFormValues]);

  const handleChangeToogle = (itemActive: number) => {
    setActiveMenu(itemActive);
  };

  const onAddAttachment = (files: FileList | null) => {
    if (files && files.length > 0) {
      const filteType = files?.[0]?.type;
      isAcceptedFile(filteType) &&
        addAttachment({ id, data: files?.[0] }).then((resp: any) => {
          if (resp) {
            setValue('attachements', 'add', {
              shouldDirty: true,
            });
          }
        });
      return;
    }
  };

  useEffect(() => {
    if (isSuccessAttachement) {
      setAttachementsDataSource([
        ...attachementsDataSource,
        responseAttachement?.attachment as EntityAttachementDeals,
      ]);
    }
  }, [isSuccessAttachement]);

  React.useEffect(() => {
    const subscription = watch((value) => {
      let isCompletedCurrency = false;
      const isAmountExist = ((floorNumber(value?.amount) as number) || 0) > 0;
      if (isAmountExist) {
        isExist(value.currency)
          ? (isCompletedCurrency = true)
          : (isCompletedCurrency = false);
      } else {
        isCompletedCurrency = true;
      }

      const isCompletedRequired =
        isExist(value.deal_type) &&
        isExist(value.mandate_id) &&
        isExist(value.client_id) &&
        isExist(value.client_legal_entity_id);

      const isInsurranceanBound =
        isExist(value.status) &&
        value.status === 'bound' &&
        isExist(mandateType) &&
        mandateType == 'insurance';

      const isInCompleteBound =
        isInsurranceanBound &&
        (!isExist(value.risk_mode) ||
          !isExist(value.calculation_basis) ||
          (value.calculation_basis !== 'verticalised' &&
            !isExist(value.minimum_deposit)));

      const isInsurenceNullType =
        isExist(mandateType) &&
        mandateType === 'insurance' &&
        !isExist(value.deal_type);

      const isEndedWithNullExpiryDate =
        isExist(value.status) &&
        value.status === 'ended' &&
        !isExist(value.expiry_date);
      const isBoundedWithNullInceptionDate =
        isExist(value.status) &&
        value.status === 'bound' &&
        !isExist(value.inception_date);
      const isBoundWithNoMinimum =
        isExist(value.status) &&
        value.status === 'bound' &&
        !isExist(value.minimum_deposit) &&
        mandateType === 'insurance';

      const isFormCompleted =
        Boolean(isCompletedRequired) &&
        isCompletedCurrency &&
        !isEndedWithNullExpiryDate &&
        !isBoundedWithNullInceptionDate &&
        !isBoundWithNoMinimum &&
        !isInCompleteBound &&
        !isInsurenceNullType;

      setIsCompletedForm(isFormCompleted);
    });
    return () => subscription.unsubscribe();
  }, [watch, errors, setValue, mandateType]);

  React.useEffect(() => {
    setAttachementsDataSource(attachementResponse?.attachment || []);
  }, [isSuccessGetAttachement, attachementResponse?.attachment]);

  const showMandate = (id: string | number) => {
    const found = (mandatesList || []).find((el) => el.id === id);
    if (found) {
      dispatch(
        updateModalAction({
          data: found,
          isAdd: true,
          type: 'mandate',
        }),
      );
    }
  };

  const showClient = async (idClient: number, type?: string) => {
    if (type === 'client_id' && idClient) {
      const data = clientList?.find(
        (el) => el.id?.toString() === idClient?.toString(),
      );
      if (data) {
        dispatch(
          updateModalAction({
            data: data,
            isAdd: true,
            type: 'client',
          }),
        );
      }
      return;
    } else {
      if (idClient) {
        const resp = await getFinancier({
          id: idClient?.toString(),
        }).unwrap();
        if (resp?.success) {
          dispatch(
            updateModalAction({
              data: resp?.counterparty,
              isAdd: true,
              type: mandateType === 'insurance' ? 'underwriter' : 'financier',
            }),
          );
        }
      }
      return;
    }
  };

  const showLegalEntity = (idLgl: number | null) => {
    const found = (legalEntityList || []).find(
      (el) => el.id?.toString() === idLgl?.toString(),
    );
    if (found) {
      dispatch(
        updateModalAction({
          data: {
            ...found,
            group_id: found?.group?.id,
            group_name: found?.group?.name,
          },
          isAdd: true,
          type: 'legal_entity',
        }),
      );
    }
  };

  const showPartnerLegalEntity = (idLgl: number | null) => {
    const found = (partnerEntityList || []).find(
      (el) => el.id?.toString() === idLgl?.toString(),
    );
    if (found) {
      dispatch(
        updateModalAction({
          data: {
            ...found,
            group_id: found?.group?.id,
            group_name: found?.group?.name,
          },
          isAdd: true,
          type: 'legal_entity',
        }),
      );
    }
  };

  const showCofarcoTeams = async (id: string | number) => {
    const found = (optionUserNameOwner || []).find((el) => el.id === id);
    if (found) {
      const resp = await getCofarcoTeam({ id: found?.id }).unwrap();

      if (resp.user && resp.success) {
        const userResult: any = resp.user;
        const tempRigth = userResult?.rights || {};
        /*if (tempRigth?.history) {
          delete tempRigth.history;
        }*/
        const tempDataSourceRigth = [];
        for (const property in tempRigth) {
          tempDataSourceRigth.push({
            ...tempRigth[property],
            object: property,
          });
        }
        dispatch(
          updateModalAction({
            data: {
              ...userResult,
              entry_date: exportDateUi(userResult?.entry_date) as string,
              departure_date: exportDateUi(
                userResult?.departure_date,
              ) as string,
              username: concatString(
                userResult?.people?.firstname || '',
                userResult?.people?.lastname || '',
                ' ',
              ),
              history: userResult?.rights?.['history']?.read,
              data_source_rigth: tempDataSourceRigth,
            },
            isAdd: true,
            type: 'cofarco_team',
          }),
        );
      }
    }
  };

  const showObligor = () => {
    if (txtView?.obligor) {
      dispatch(
        updateModalAction({
          data: {
            obligor: txtView.obligor,
            top_obligor_country_id: txtView.top_obligor_country_id,
          },
          isAdd: true,
          type: 'obligor',
        }),
      );
    }
  };

  const showPartner = (id: number | null) => {
    const found = (partenerList || []).find((el) => el.id === id);
    if (found) {
      dispatch(
        updateModalAction({
          data: found,
          isAdd: true,
          type: 'partner',
        }),
      );
    }
  };

  const showPeople = (idPrt: number | null) => {
    const found = (partenersPeople || []).find((el) => el.id === idPrt);
    if (found) {
      dispatch(
        updateModalAction({
          data: found,
          isAdd: true,
          type: 'people',
        }),
      );
    }
  };

  const fetchBrokerage = async () => {
    const resp = await getBrokerage({
      dealId: id,
    }).unwrap();

    const tempDataS = resp?.counterparty?.map((el: ConterParty, index) => ({
      brokerId: {
        key: el?.id,
        value: el?.name,
        legal_entities: el.legal_entities
          ? el.legal_entities
          : el.legal_entity
            ? [el.legal_entity]
            : [],
      },
      brokerContact:
        el?.broker_contact_id && el?.broker_contact_fullname
          ? {
              key: el?.broker_contact_id,
              value: el?.broker_contact_fullname,
            }
          : undefined,
      brokerRole: el?.broker_role ? el?.broker_role?.split(',') : [],
      netBroker: initPercentage(el?.broker_net_brokerage) as string,
      brokerReference: el?.broker_reference as string,
      id: el.id,
      key: index + 1,
      editable: true,
    }));
    setBrokerAttachementDataSource(tempDataS);
  };

  useEffect(() => {
    if (id) {
      fetchBrokerage();
    }
  }, [id]);

  const isEdit = Boolean(checkIsEdit(watch('id') || ''));

  const handleShowOtherModal = (id: any, func: FunctionName, type?: string) => {
    if (isDirty && isEdit) {
      setIsShowModalUnsavedChange(true);
      setShowOtherModal({ id, func });
    } else {
      handleLeaveModalWithoutSave(id, func, type);
    }
  };

  const handleLeaveModalWithoutSave = (
    id: any,
    func: FunctionName,
    type?: string,
  ) => {
    showFunctions(id, func, type);
    setIsShowModalUnsavedChange(false);
    isEdit && resetForm();
  };

  const showFunctions = useCallback(
    (id: any, func: FunctionName, type?: string) => {
      switch (func) {
        case 'mandate':
          showMandate(id);
          break;
        case 'client':
          showClient(id, type);
          break;
        case 'legal_entity':
          showLegalEntity(id);
          break;
        case 'people':
          showPeople(id);
          break;
        case 'partner':
          showPartner(id);
          break;
        case 'cofarco_team':
          showCofarcoTeams(id);
          break;
        case 'obligor':
          showObligor();
          break;
        case 'partner_legal_entity':
          showPartnerLegalEntity(id);
          break;
        default:
          break;
      }
    },
    [showClient, showLegalEntity, showPeople, showPartner, showCofarcoTeams],
  );

  const showPeopleModal = (p: any) => {
    const result = ownerList?.user.filter((el) => el.id === p) || [];
    if (result.length > 0) {
      const owner = result[0];
      getPeopleById({ id: owner.people_id }).then((res) => {
        if (res.data?.people) {
          if (!isEmpty(dirtyFields) && isEdit) {
            setIsShowModalUnsavedChange(true);
            setShowOtherModal(p);
          } else {
            dispatch(
              updateModalAction({
                data: res.data?.people,
                isAdd: true,
                type: 'people',
              }),
            );
            setIsShowModalUnsavedChange(false);
            isEdit && resetForm();
          }
        }
      });
    }
  };

  return {
    register,
    handleSubmit,
    errors,
    control,
    watch,
    getValues,
    setValue,
    resetForm,
    isFormEditing,
    submit,
    runSearch,
    mandatesList,
    isLoadingSearchMandate,
    clients,
    isLoading: isGetPartnerLoading || isLoading,
    legalEntityOption,
    setFormError,
    clearFormErrors,
    isLoadingLegalEntity,
    isLoadingMandate,
    mandatesById,
    clientList,
    legalEntityList,
    mandateType,
    partenerList,
    partnerEntityList,
    partenersPeople,
    txtView,
    activeMenu,
    setActiveMenu,
    handleChangeToogle,
    number,
    id,
    attachementsDataSource,
    onAddAttachment,
    isLoadingAttachement,
    optionUserNameOwner,
    isDirty,
    isCompletedForm,
    dataSourceLimits,
    isLoadingGetLimits,
    dataSourceParticipation,
    isLoadingGetParticipation,
    sortedParticipation,
    sortedLimits,
    brokerAttachementDataSource,
    setBrokerAttachementDataSource,
    getMandatesByName,
    setAttachementsDataSource,
    dirtyFields,
    isShowModalUnsavedChange,
    setIsShowModalUnsavedChange,
    showFunctions,
    handleShowOtherModal,
    handleLeaveModalWithoutSave,
    showOtherModal,
    isEdit,
    showPeopleModal,
  };
}
