import { LoginResponse } from '../@types/common-types';

export enum STORAGE_KEY {
  REFRESH_TOKEN = 'refresh_token',
  TOKEN = 'token',
  EXPIRATION_DATE = 'expiration_date',
}

export const setUserSession = (user: LoginResponse) => {
  if (user) {
    localStorage.setItem(STORAGE_KEY.TOKEN, user.token ?? '');
    localStorage.setItem(
      STORAGE_KEY.EXPIRATION_DATE,
      user.expiration_date || '',
    );
    localStorage.setItem(STORAGE_KEY.REFRESH_TOKEN, user?.refreshToken || '');
  }
};

export const clearSession = () => {
  localStorage.removeItem(STORAGE_KEY.TOKEN);
  localStorage.removeItem(STORAGE_KEY.EXPIRATION_DATE);
  localStorage.removeItem(STORAGE_KEY.REFRESH_TOKEN);
};
