import React, { createContext, useState } from 'react';
import { Outlet } from 'react-router-dom';
import ContentNavBar from '../Admin/ContentNavBar';
import Mandates from '../../assets/img/Mandates.svg';
export const ContextMandate = createContext<any>(null);

const MandatesContent: React.FC = () => {
  const [searchTxt, setSearchTxt] = useState<string | null>(null);

  return (
    <>
      <ContentNavBar
        title='Mandates'
        logo={Mandates}
        setTxt={(txt: any) => setSearchTxt(txt)}
        txt={searchTxt}
      />
      <ContextMandate.Provider value={{ txt: searchTxt }}>
        <Outlet />
      </ContextMandate.Provider>
    </>
  );
};

export default MandatesContent;
