export const financialFields = [
  {
    key: "transactional_finance",
    name: "Transactional Finance",
  },
  {
    key: "repo",
    name: "Repo",
  },
  {
    key: "limited_recourse",
    name: "Limited Recourse Pre-Payment",
  },
  {
    key: "forfeiting",
    name: "Forfeiting",
  },
  {
    key: "factoring",
    name: "Factoring",
  },
  {
    key: "borrowing_base",
    name: "Borrowing Base",
  },
  {
    key: "rcf",
    name: "RCF",
  },
  {
    key: "lc_confirmation",
    name: "LC Confirmation",
  },
  {
    key: "import_lc",
    name: "Import LC",
  },
  {
    key: "payment_risk_assumption",
    name: "Payment Risk Assumption",
  },
  {
    key: "bonding",
    name: "Bonding",
  },
  {
    key: "term_loan",
    name: "Term Loan",
  },
  {
    key: "mining_finance",
    name: "Mining Finance",
  },
  {
    key: "based_lending",
    name: "Reserve Based Lending",
  },
  {
    key: "project_finance",
    name: "Project Finance",
  },
  {
    key: "acquisition_finance",
    name: "Acquisition Finance",
  },
  {
    key: "junior_debt",
    name: "Junior Debt",
  },
  {
    key: "equity_capital",
    name: "Equity Capital",
  },
  {
    key: "africa_active",
    name: "Africa Active",
  },
  {
    key: "asia_active",
    name: "Asia Active",
  },
  {
    key: "cis_active",
    name: "Cis Active",
  },
  {
    key: "europe_active",
    name: "Europe Active",
  },
  {
    key: "latam_active",
    name: "Latam Active",
  },
  {
    key: "middle_east_active",
    name: "Middle East Active",
  },
  {
    key: "north_america_active",
    name: "North America Active",
  },
];
