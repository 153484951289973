import React from "react";

import { InputFormDeals } from "../../../@types/deals/deals";
import FormModal from "../../Modal/FormModal/FormModal";
import UseModalDeals from "./UseModalDeals";
import Forms from "./Forms/Forms";
import HeaderMultiple from "../../HeaderTab/HeaderMultiple";
import ParticipationList from "./ParticipationList/ParticipationList";
import LimitsList from "./LimitsList/LimitsList";
import { concatTitle, isCan, isExist } from "../../../utils/helper-function";
import { Menu } from "../../../@types/common-types";
import { isEmpty } from "lodash";

type IProps = {
  show: boolean;
  handleClose: (deals: any | null) => void;
  defaultFormValues: InputFormDeals | null;
};

export default function ModalDeals(props: IProps) {
  const provider = UseModalDeals({
    defaultFormValues: props.defaultFormValues,
    closingModal: props.handleClose,
  });

  const menus = [
    {
      id: 1,
      title: `Deals ${concatTitle(provider.number)} ${concatTitle(
        provider.mandatesById?.number
      )}`,
    },
    {
      id: 2,
      title: `Participations List ${concatTitle(provider.number)} ${concatTitle(
        provider.mandatesById?.number
      )}`,
    },
    {
      id: 3,
      title: `Limits List ${concatTitle(provider.number)} ${concatTitle(
        provider.mandatesById?.number
      )}`,
    },
  ];

  const isHaveRelatedEntity =
    Boolean(provider.dataSourceParticipation?.participation?.length) ||
    provider.dataSourceLimits?.limit?.length > 0;

  const hiddenFooter =
    !!(provider.activeMenu === 2 || provider.activeMenu === 3) ||
    (!isCan("write", Menu.deal) && provider.isEdit);

  React.useEffect(() => {
    if (props.show && !isExist(props.defaultFormValues?.id)) {
      provider.getMandatesByName("");
    }
  }, [props.show]);

  const market = provider.watch("market");
  const deal_type = provider.watch("deal_type");


  return (
    <>
      <FormModal
        show={props.show}
        handleClose={() => {
          provider.resetForm();
          props.handleClose(null);
        }}
        ishiddenfooter={!!hiddenFooter}
        isDisabledSave={
          !provider.isDirty ||
          !provider.isCompletedForm ||
          !isEmpty(provider.errors)
        }
        onSubmitForm={provider.handleSubmit(provider.submit)}
        header={
          <HeaderMultiple
            activeMenu={provider.activeMenu}
            isEdit={provider.isEdit}
            menus={menus}
            setActiveMenu={(p: number) => provider.handleChangeToogle(p)}
            createTitle={"New - Deals"}
          />
        }
      >
        <>
          {provider.activeMenu === 1 && (
            <Forms
              register={provider.register}
              errors={provider.errors}
              watch={provider.watch}
              setValue={provider.setValue}
              dirtyFields={provider.dirtyFields}
              getValue={provider.getValues}
              clearFormErrors={provider.clearFormErrors}
              control={provider.control}
              runSearch={(key: keyof InputFormDeals, value: string) =>
                provider.runSearch(key, value)
              }
              onAddAttachment={(f: FileList | null) =>
                provider.onAddAttachment(f)
              }
              setFormError={provider.setFormError}
              clientList={provider.clientList || []}
              isLoadingClient={provider.isLoading}
              legalentityList={provider.legalEntityList || []}
              partnerEntityList={provider.partnerEntityList || []}
              partenersPeople={provider.partenersPeople || []}
              mandatesList={provider.mandatesList || []}
              partenerList={provider.partenerList}
              isLoadingSearchMandate={provider.isLoadingMandate}
              isLoadingLegalEntity={provider.isLoadingLegalEntity}
              mandatesById={provider.mandatesById}
              mandateType={provider.mandateType}
              txtView={provider.txtView}
              attachementsDataSource={provider.attachementsDataSource}
              setAttachementsDataSource={provider.setAttachementsDataSource}
              isLoadingAttachement={provider.isLoadingAttachement}
              optionUserNameOwner={provider.optionUserNameOwner}
              brokerAttachementDataSource={provider.brokerAttachementDataSource}
              isHaveRelatedEntity={isHaveRelatedEntity}
              setBrokerAttachementDataSource={
                provider.setBrokerAttachementDataSource
              }
              hiddenFooter={hiddenFooter}
              showOtherModal={provider.showOtherModal}
              handleShowOtherModal={provider.handleShowOtherModal}
              handleLeaveModalWithoutSave={provider.handleLeaveModalWithoutSave}
              provider={provider}
              showPeopleModal={provider.showPeopleModal}
            />
          )}
          {provider.activeMenu === 2 && (
            <ParticipationList
              isLoading={provider.isLoadingGetParticipation}
              dataSource={provider.dataSourceParticipation?.participation ?? []}
              selectedDeals={props.defaultFormValues}
              mandateType={provider.mandateType}
              sortedParticipation={provider.sortedParticipation}
              id={provider.id}
            />
          )}

          {provider.activeMenu === 3 && (
            <LimitsList
              isLoading={provider.isLoadingGetLimits}
              dataSource={provider.dataSourceLimits?.limit}
              selectedDeals={props.defaultFormValues}
              sortedLimits={provider.sortedLimits}
              market={market}
              deal_type={deal_type}
            />
          )}
        </>
      </FormModal>
    </>
  );
}
