import React, { useState } from 'react';
import { EntityEnquiries } from '../../../../@types/enquiries';
import {
  useDeleteEnquiriesMutation,
  useLazyGetEnquiriesByMandateIdQuery,
} from '../../../../redux/api/ws/enquiries/enquiries';
import {
  filterColumn,
  isCan,
  titleRequestError,
} from '../../../../utils/helper-function';
import Table from '../../../CofarcoTable/Table';
import ColumnEnquiryList from './ColumnEnquiryList';
import { useDispatch } from 'react-redux';
import { updateModalAction } from '../../../../redux/slice/modalSlice';
import DeleteModal from '../../../Modal/DeleteModal';
import { updateNotificationMessage } from '../../../../redux/slice/notificationSlice';
import AddBtn from '../../../Button/AddBtn';
import { Menu } from '../../../../@types/common-types';

type IProps = {
  selectedMandateId: number | null;
};
export default function EnquiriesList(props: IProps) {
  const [getEnquryByMandate, { data: enquiriesList, isLoading }] =
    useLazyGetEnquiriesByMandateIdQuery();

  const [selectedEnquiries, setSelectedEnquiries] =
    useState<EntityEnquiries | null>();

  const [modalConfirmDelete, setModalConfirmDelete] = useState(false);

  const [
    deletedEnquiries,
    {
      isLoading: isLoadingDelete,
      isSuccess: isSuccessDelete,
      isError: isErrorDelete,
      error: errorResponse,
    },
  ] = useDeleteEnquiriesMutation();

  const dispatch = useDispatch();

  const handleSorted = React.useCallback(
    (key: string, ruls: 'ASC' | 'DESC') => {
      if (props.selectedMandateId) {
        getEnquryByMandate({
          id: props.selectedMandateId || 0,
          sort: `[{"property":"${key}","direction":"${ruls}"}]`,
        });
      }
    },
    [props.selectedMandateId],
  );

  React.useEffect(() => {
    if (props.selectedMandateId) {
      getEnquryByMandate({
        id: props.selectedMandateId || 0,
      });
    }
  }, [props.selectedMandateId]);

  React.useEffect(() => {
    if (isSuccessDelete) {
      handleShowModalDelete();
    }
  }, [isSuccessDelete]);

  React.useEffect(() => {
    if (isErrorDelete) {
      handleShowModalDelete();
      dispatch(
        updateNotificationMessage({
          show: true,
          title: titleRequestError,
          body: errorResponse?.data,
        }),
      );
    }
  }, [isErrorDelete]);

  const validDelete = () => {
    if (selectedEnquiries?.id) {
      deletedEnquiries({ id: selectedEnquiries?.id || -1 });
    }
  };

  const editEnquiry = (id: number) => {
    const enq = (enquiriesList?.enquiry || []).find((el: any) => el.id === id);
    if (enq) {
      dispatch(
        updateModalAction({
          data: enq,
          isAdd: true,
          type: 'enquiry',
        }),
      );
    }
  };

  const handleShowModalDelete = () => {
    setModalConfirmDelete(!modalConfirmDelete);
  };

  const handleDeleteEnquiry = (id: number) => {
    const foundEnquiries = (enquiriesList?.enquiry || []).find(
      (el) => el.id === id,
    );
    if (foundEnquiries) {
      setSelectedEnquiries(foundEnquiries || null);
      handleShowModalDelete();
    }
  };

  const { columns, uncheckedId } = ColumnEnquiryList({
    enquiryes: enquiriesList?.enquiry || [],
    handleSorted,
    editEnquiry: editEnquiry,
    handleDeleteEnquiry: handleDeleteEnquiry,
  });

  const handleCreateEnquiries = () => {
    dispatch(
      updateModalAction({
        data: {
          mandate_id: props.selectedMandateId,
        },
        isAdd: true,
        type: 'enquiry',
      }),
    );
  };

  return (
    <>
      <div className="content-table">
        <Table
          dataSource={enquiriesList?.enquiry || []}
          columns={filterColumn<EntityEnquiries>(columns, uncheckedId)}
          loading={isLoading}
          onRow={(record: any) => {
            return {
              onClick: () => {
                if (isCan('write', Menu.enquiry)) editEnquiry(record?.id);
              },
            };
          }}
        />

        <DeleteModal
          title="Delete Enquiries"
          handleClose={handleShowModalDelete}
          show={modalConfirmDelete}
          validDelete={() => validDelete()}
          groupeName={selectedEnquiries?.number || ''}
          isLoadingDelete={isLoadingDelete}
        />
      </div>
      {isCan('write', Menu.enquiry) && (
        <AddBtn
          disabled={false}
          onClick={() => {
            handleCreateEnquiries();
          }}
          title="Add Enquiry"
        />
      )}
    </>
  );
}
