import React from 'react';
import { TermConst } from '../@types/common-types';
import { Term } from '../@types/terms';
import { useListTermsQuery } from '../redux/api/ws/terms/get';

export default function UseConstante() {
  const { data: dataTerms, isSuccess } = useListTermsQuery(undefined, {
    refetchOnMountOrArgChange: false,
  });

  const combinned = ((dataTerms?.term as Array<Term>) || []).reduce(
    (acc: any, curr: any) => {
      if (!acc[curr.type]) {
        acc[curr.type] = [];
      }
      acc[curr.type].push(curr);
      return acc;
    },
    {}
  );

  const getConst = React.useCallback(
    (p: TermConst) => {
      return combinned[p];
    },
    [isSuccess]
  );

  return { getConst, isSuccess };
}
