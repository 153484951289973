import React, { useState } from 'react';
import { InputFormLimits } from '../../../../../@types/limit/types';
import { useForm } from 'react-hook-form';
import {
  useCreateParticipationDealLimitMutation,
  useGetDealsByIdMutation,
} from '../../../../../redux/api/ws/participations/participations';
import { EntityMandate } from '../../../../../@types/mandates/mandates';
import { EntityDeals } from '../../../../../@types/deals/deals';
import {
  amountFormat,
  exportDateUi,
  exportDateWs,
  floorNumber,
  getObjectDifferences,
  isExist,
  nullingEmptyValues,
  setDefaultValues,
  setEmptyUndefinedEditValues,
} from '../../../../../utils/helper-function';
import { useGetGroupeByIdMutation } from '../../../../../redux/api/ws/databases/groups';
import { useSearchLegalEntityNameMutation } from '../../../../../redux/api/ws/databases/legale-entities';
import { useAppDispatch } from '../../../../../hooks/redux';
import { updateNotificationMessage } from '../../../../../redux/slice/notificationSlice';
import { updateModalAction } from '../../../../../redux/slice/modalSlice';
import { useGetLegalEntityByIdMutation } from '../../../../../redux/api/ws/databases/legale-entities';
import {
  useGetMandateByIdMutation,
  useUpdateDealObligorLimitMutation,
} from '../../../../../redux/api/ws/deals/deals';

type IProps = {
  defaultFormValues: any | null;
  closingModal: (itemsDataSource: any | null) => void;
};
export default function UseFormCreateDealObligorLimit(props: IProps) {
  const [isCompletedForm, setIsCompletedForm] = useState(false);

  const [activeMenu, setActiveMenu] = useState<number>(1);

  const handleChangeToogle = (itemActive: number) => {
    setActiveMenu(itemActive);
  };

  const [selectedMandate, setSelectedMandate] =
    useState<EntityMandate | null>();

  const dispatch = useAppDispatch();
  const [getLegalEntity, { isLoading: isGetLEbyIdLoading }] =
    useGetLegalEntityByIdMutation();
  const [getGroup, { data: selectedGroup, reset: resetCached }] =
    useGetGroupeByIdMutation();

  const [isShowModalUnsavedChange, setIsShowModalUnsavedChange] =
    useState<boolean>(false);

  const [getMandate] = useGetMandateByIdMutation();
  const [getDeals, { isLoading: isGetDealLoading }] = useGetDealsByIdMutation();
  const [searchLegalEntity, { isLoading: isSearchLELoading }] =
    useSearchLegalEntityNameMutation();

  const [createDealLimit] = useCreateParticipationDealLimitMutation();

  const [updateDealLimit] = useUpdateDealObligorLimitMutation();

  const [legalEntityList, setLegalEntityList] = useState<Array<any>>([]);

  const [selectedDeal, setSelectedDeal] = useState<EntityDeals | null>();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid, dirtyFields },
    control,
    watch,
    getValues,
    setValue,
    reset,
    resetField,
    setError: setFormError,
    clearErrors: clearFormErrors,
  } = useForm<InputFormLimits>({
    mode: 'onBlur',
    defaultValues: {
      limit_date: selectedDeal?.inception_date,
      expiry_date: selectedDeal?.expiry_date,
      amount: '0.00',
      currency: props.defaultFormValues?.currency || '',
    },
  });

  const isEdit = props.defaultFormValues?.event === 'edit';

  const counterparty_id = watch('counterparty_id');
  const obligor_legal_entity_id = watch('obligor_legal_entity_id');
  const country_id = watch('country_id');
  const currency = watch('currency');
  const amount = watch('amount');
  const status = watch('status');
  const nature = watch('nature');

  const getLegalEntityById = async (id: number) => {
    try {
      const response = await getLegalEntity({
        id,
      }).unwrap();
      setValue('obligor_legal_entity_id', response?.legalentity?.id);
      setValue('country_id', response?.legalentity?.country_id);
      setLegalEntityList([response?.legalentity]);
      if (response?.legalentity?.group_id) {
        getGroup({ id: response?.legalentity?.group_id });
      }
      return response;
    } catch (error) {
      return [];
    }
  };

  const getMandatesById = async (id: number) => {
    try {
      const response = await getMandate({
        id,
      }).unwrap();
      setSelectedMandate(response?.mandate);
      return response;
    } catch (error) {
      return [];
    }
  };

  const getDealsById = async (id: number) => {
    try {
      const response = await getDeals({
        id,
      }).unwrap();
      setSelectedDeal(response?.deal);
      if (props.defaultFormValues?.event !== 'edit') {
        setValue('currency', response?.deal?.currency);
        setValue(
          'limit_date',
          exportDateUi(response?.deal?.inception_date) as string,
        );
        setValue(
          'expiry_date',
          exportDateUi(response?.deal?.expiry_date) as string,
        );
      }
      return response;
    } catch (error) {
      return [];
    }
  };

  const handleLegalEntity = async (text: string) => {
    try {
      const response = await searchLegalEntity({
        page: 1,
        start: 0,
        limit: 100,
        query: `%${text?.trim()}%`,
      }).unwrap();

      const tempOption = response?.legalentity;
      setLegalEntityList(tempOption);
    } catch (error) {
      return [];
    }
  };

  const resetForm = () => {
    const defaultValue = setDefaultValues<InputFormLimits>(watch());
    resetCached();
    reset({
      ...defaultValue,
      amount: 0,
      currency: 'usd',
      status: undefined,
      comments: '',
    });
    resetField('expiry_date');
    resetField('limit_date');
    setLegalEntityList([]);
  };

  const submit = async (data: InputFormLimits) => {
    const params: InputFormLimits = {
      counterparty_id: null,
      limit_id: null,
      participation_id: null,
      id: props.defaultFormValues?.id,
      amount: floorNumber(data.amount || 0),
      comments: data.comments,
      obligor_legal_entity_id: data.obligor_legal_entity_id,
      status: data.status,
      deal_id: props.defaultFormValues?.deal_id as number,
      nature: data.nature,
      pricing: data.pricing,
      currency: data.currency,
      limit_date: exportDateWs(data.limit_date) as string,
      expiry_date: exportDateWs(data.expiry_date) as string,
      type: 'deal_obligor',
    };
    if (props.defaultFormValues?.event === 'edit') {
      try {
        const objectDiff = getObjectDifferences(
          props.defaultFormValues,
          params,
        );
        const response = await updateDealLimit({
          form: setEmptyUndefinedEditValues({
            ...objectDiff,
            id: props.defaultFormValues.id,
          }),
        }).unwrap();
        if (response && response.success) {
          dispatch(
            updateNotificationMessage({
              title: 'Update Deals Obligor Limit',
              body: `Deals Obligor Limit ${response.limit?.number} has been updated !`,
              show: true,
            }),
          );
          props.closingModal(null);
        }
        resetForm();
      } catch (error: any) {
        console.log(error);
      }
    } else {
      try {
        const response = await createDealLimit({
          form: nullingEmptyValues(params),
          participationId: props.defaultFormValues?.participation_id as number,
        }).unwrap();

        if (response && response.success) {
          dispatch(
            updateNotificationMessage({
              title: 'New Deal Obligor Limit',
              body: `Deal Obligor Limit  ${response.limit.number} has been added !`,
              show: true,
            }),
          );
          props.closingModal(null);
        }
        resetForm();
      } catch (error) {
        console.log(error);
      }
    }
  };

  React.useEffect(() => {
    if (props.defaultFormValues) {
      const selectLimits = props.defaultFormValues;
      if (selectLimits.event === 'edit') {
        reset({
          ...selectLimits,
          amount: amountFormat(
            Number.parseFloat(selectLimits.amount || 0)?.toFixed(2),
          ),
          limit_date: exportDateUi(selectLimits?.limit_date) as string,
          expiry_date: exportDateUi(selectLimits?.expiry_date) as string,
        });
      } else {
        reset({
          amount: '0.00',
          currency: 'usd',
          status: undefined,
          limit_date: undefined,
          expiry_date: undefined,
          pricing: '',
          comments: '',
        });
      }
      if (selectLimits.legal_entity_id) {
        getLegalEntityById(selectLimits.legal_entity_id);
      }
      if (selectLimits?.mandate_id) {
        getMandatesById(selectLimits?.mandate_id);
      }
      if (selectLimits?.deal_id) {
        getDealsById(selectLimits?.deal_id);
      }
    }
  }, [props.defaultFormValues]);

  const runSearch = React.useCallback(
    async (key: keyof InputFormLimits, value: string) => {
      if (key === 'obligor_legal_entity_id') {
        handleLegalEntity(value);
      }
    },
    [],
  );

  const showLegalEntity = () => {
    const found = (legalEntityList || []).find(
      (el) => el.id?.toString() === obligor_legal_entity_id?.toString(),
    );
    if (found) {
      dispatch(
        updateModalAction({
          data: {
            ...found,
            group: selectedGroup?.group,
            group_name: selectedGroup?.group.name,
          },
          isAdd: true,
          type: 'legal_entity',
        }),
      );
    }
  };

  const handleShowOtherModal = () => {
    if (isDirty && isEdit) {
      setIsShowModalUnsavedChange(true);
    } else {
      handleLeaveModalWithoutSave();
    }
  };

  const handleLeaveModalWithoutSave = () => {
    isEdit && resetForm();
    showLegalEntity();
    setIsShowModalUnsavedChange(false);
  };

  React.useEffect(() => {
    const subscription = watch((value) => {
      let isCompletedCurrency = false;
      const isCompletedRequired = isExist(value.obligor_legal_entity_id);
      if (((floorNumber(value?.amount) as number) || 0) > 0) {
        isExist(value.currency)
          ? (isCompletedCurrency = true)
          : (isCompletedCurrency = false);
      } else {
        isCompletedCurrency = true;
      }
      setIsCompletedForm(
        isCompletedRequired && isCompletedCurrency ? true : false,
      );
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return {
    register,
    handleSubmit,
    errors,
    isDirty,
    isValid,
    control,
    watch,
    getValues,
    setValue,
    reset,
    setFormError,
    clearFormErrors,
    resetForm,
    submit,
    isCompletedForm,
    selectedDeal,
    runSearch,
    counterparty_id,
    legalEntityList,
    obligor_legal_entity_id,
    showLegalEntity,
    country_id,
    selectedGroup,
    currency,
    amount,
    status,
    selectedMandate,
    nature,
    getGroup,
    resetCached,
    isLoading: isGetDealLoading,
    isGetLELoading: isGetLEbyIdLoading || isSearchLELoading,
    handleShowOtherModal,
    isShowModalUnsavedChange,
    handleLeaveModalWithoutSave,
    setIsShowModalUnsavedChange,
    dirtyFields,
    activeMenu,
    setActiveMenu,
    handleChangeToogle,
    isEdit,
  };
}
