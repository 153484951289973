import {
  useLazyWhatsNewQuery,
  useWhatsNewSeenMutation,
} from '../../redux/api/ws/dashboard/dashboard';
import { useEffect, useState } from 'react';
import {
  CounterPartyBusiness,
  DashboardWhatsNewData,
  ModelsEnum,
} from '../../@types/dashboard';
import { useLazyMandatesQuery } from '../../redux/api/ws/mandates/mandates';
import { useAppDispatch } from '../redux';
import { updateModalAction } from '../../redux/slice/modalSlice';
import { useLazyDealsQuery } from '../../redux/api/ws/deals/deals';
import { useLazyEnquiriesQuery } from '../../redux/api/ws/enquiries/enquiries';
import { useLazyNotesQuery } from '../../redux/api/ws/segregatedaccounts/notes';
import { useLazyListIndicationQuery } from '../../redux/api/ws/indication/get';
import { useLazyListPeopleQuery } from '../../redux/api/ws/databases/people';
import { useLazyListCounterPartyQuery } from '../../redux/api/ws/counterparties/get';
import { isCan } from '../../utils/helper-function';
import { Menu } from '../../@types/common-types';
import { useWhatsNewUtils } from './useWhatsNewUtils';
import { ColumnsType } from 'antd/es/table';
import { Avatar } from '@mui/material';
import { config } from '../../data/config';
import { Space } from 'antd';
import { MainMenu } from '../../data/main-menu';
import { ReactComponent as ClientLogo } from '../../assets/img/client.svg';
import { ReactComponent as FinancyLogo } from '../../assets/img/financy.svg';
import { ReactComponent as UnderWriterLogo } from '../../assets/img/underwriter.svg';
import { ReactComponent as PartnerLogo } from '../../assets/img/partner.svg';
import { ReactComponent as ObligorLogo } from '../../assets/img/obligor.svg';

export const useDashboardWhatsNew = () => {
  const defaultLimit = 20;
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState<DashboardWhatsNewData[]>([]);
  const [laod, { data, isLoading }] = useLazyWhatsNewQuery({});
  const { getStatus, getLastColumnValue, getType, getName, blink } =
    useWhatsNewUtils();
  //Handle click row
  const dispatch = useAppDispatch();
  const [getMandate] = useLazyMandatesQuery();
  const [getDeal] = useLazyDealsQuery();
  const [getEnquiry] = useLazyEnquiriesQuery();
  const [getNotes] = useLazyNotesQuery();
  const [getIndication] = useLazyListIndicationQuery();
  const [getPeople] = useLazyListPeopleQuery();
  const [getCounterParty] = useLazyListCounterPartyQuery();
  const [objectSeen] = useWhatsNewSeenMutation();

  const [animation, setAnimation] = useState('blink-row');

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAnimation('blink-row-stop');
    }, 10000); // Replace animation after 10 seconds

    return () => clearTimeout(timeout);
  }, [animation]);

  const getTableScroll = () =>
    document.querySelector<HTMLElement>(
      '#dashboard-whatsnew-table .ant-table-body',
    );

  const addRows = (newRows?: DashboardWhatsNewData[]) => {
    if (!newRows) return;

    if (newRows.length) {
      const _rows = [...rows, ...newRows];
      setRows(_rows);
      if (_rows.length <= defaultLimit) return;
      setTimeout(() => {
        const expTable = getTableScroll();
        if (expTable) {
          expTable.scrollTo({
            behavior: 'smooth',
            top: expTable.scrollHeight - expTable.clientHeight - 150,
          });
        }
      }, 100);
    }
  };

  const scrollHandler = (event: any) => {
    const maxScroll = event.target.scrollHeight - event.target.clientHeight;
    const currentScroll = event.target.scrollTop;

    if (currentScroll === maxScroll) {
      const pageCount = Math.ceil((data?.total || 0) / defaultLimit);
      if (page < pageCount) {
        const nexPage = page + 1;
        setPage(nexPage);
        laod({ limit: defaultLimit, page: nexPage }).then((res) => {
          addRows(res.data?.whatsNew);
        });
      }
    }
  };

  useEffect(() => {
    const expTable = getTableScroll();

    if (expTable) {
      expTable.addEventListener('scroll', scrollHandler);
      return () => {
        // Cleanup: remove the scroll event listener when the component is unmounted
        expTable.removeEventListener('scroll', scrollHandler);
      };
    }
  }, [page, rows]);

  const socketHandler = () => {
    window.Echo.channel('messages').listen('.activity', (data: any) => {
      if (!data) return;
      setPage(1);
      laod({ limit: defaultLimit, page: 1 }).then((res) => {
        if (res.data) {
          setRows(res.data.whatsNew);
          setAnimation('blink-row');
          setTimeout(() => {
            setAnimation('blink-row-stop');
          }, 10000);
        }
      });
    });
  };

  useEffect(() => {
    let ignore = false;
    if (page == 1 && !rows.length) {
      laod({ limit: defaultLimit, page }).then((res) => {
        if (!ignore) {
          addRows(res.data?.whatsNew);
          // listen once time
          socketHandler();
        }
      });
    }

    return () => {
      ignore = true;
    };
  }, []);

  const refreshData = () => {
    laod({ limit: rows.length, page: 1 }).then((res) => {
      setRows(res.data?.whatsNew || []);
    });
  };

  const onClickRow = async (row: DashboardWhatsNewData) => {
    const type = getType(row.object_type);
    let mn = type === ModelsEnum.counterParty ? row.business : type;
    mn = mn.toLocaleLowerCase() as Menu;

    if (!(isCan('write', mn as Menu) || isCan('read', mn as Menu))) {
      return;
    }

    const params = {
      limit: 1,
      filter: `[{"property":"id","value":${row.object_id},"operator":"="}]`,
      page: 1,
      start: 0,
      sort: '',
    };

    switch (type) {
      case ModelsEnum.mandate:
        const { data: _mData } = await getMandate(params);
        if (_mData?.mandate?.length === 1) {
          dispatch(
            updateModalAction({
              data: _mData.mandate[0],
              isAdd: true,
              type: ModelsEnum.mandate.toLowerCase(),
            }),
          );
        }

        break;
      case ModelsEnum.deal:
        const { data: _dData } = await getDeal(params);
        if (_dData?.deal?.length === 1) {
          dispatch(
            updateModalAction({
              data: _dData.deal.at(0),
              isAdd: true,
              type: 'deals',
            }),
          );
        }
        break;
      case ModelsEnum.enquiry:
        const { data: _Edata } = await getEnquiry(params);

        if (_Edata?.enquiry?.length === 1) {
          dispatch(
            updateModalAction({
              data: _Edata.enquiry.at(0),
              isAdd: true,
              type: ModelsEnum.enquiry.toLowerCase(),
            }),
          );
        }
        break;
      case ModelsEnum.indication:
        const { data: _iData } = await getIndication({
          page: params.page,
          start: 0,
          filter: params.filter,
        });
        if (_iData?.indication?.length) {
          dispatch(
            updateModalAction({
              data: _iData.indication.at(0),
              isAdd: true,
              type: ModelsEnum.indication.toLowerCase(),
            }),
          );
        }
        break;
      case ModelsEnum.counterParty:
        if (row.business === CounterPartyBusiness.obligor) return;
        const { data: _cData } = await getCounterParty({
          ...params,
          businessType: row.business,
          sort: [],
        });
        if (_cData?.counterparty?.length) {
          dispatch(
            updateModalAction({
              data: _cData.counterparty.at(0),
              isAdd: true,
              type: row.business,
            }),
          );
        }
        break;
      case ModelsEnum.people:
        const { data: _pData } = await getPeople({
          page: params.page,
          limit: params.limit,
          filter: params.filter,
          start: 0,
        });
        if (_pData?.people?.length) {
          dispatch(
            updateModalAction({
              data: _pData.people.at(0),
              isAdd: true,
              type: ModelsEnum.people.toLowerCase(),
            }),
          );
        }
        break;
      case ModelsEnum.note:
        const { data: _nData } = await getNotes(params);
        if (_nData?.note?.length) {
          dispatch(
            updateModalAction({
              data: _nData.note.at(0),
              isAdd: true,
              type: 'notes_details',
            }),
          );
        }
        break;
    }

    objectSeen({ objectId: row.object_id }).then(() => {
      refreshData();
    });
  };

  const onRow = (record: DashboardWhatsNewData) => {
    return getType(record.object_type) === ModelsEnum.counterParty &&
      record.business === CounterPartyBusiness.obligor
      ? {}
      : {
          onClick: () => onClickRow(record),
        };
  };
  const columns: ColumnsType<DashboardWhatsNewData> = [
    {
      dataIndex: 'changes',
      width: 46,
      key: 'changes',
      render: (value, record) => (
        <Avatar
          sx={{ width: 30, height: 30 }}
          src={`${config.baseUrl}user/avatar/${record.user_id}/${record.avatar_filename}`}
        >
          {value?.name?.substring(0, 2)}
        </Avatar>
      ),
    },
    {
      dataIndex: 'log_at',
      key: 'log_at',
      width: 153,
      render(value: string) {
        const date = new Date(value);
        return (
          <Space size={24}>
            <b>
              {date.toLocaleDateString('fr-FR', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })}
            </b>
            {date.toLocaleString([], {
              minute: '2-digit',
              hour: '2-digit',
              second: 'numeric',
              hour12: false,
            })}
          </Space>
        );
      },
    },
    {
      key: 'object_type',
      width: 45,
      dataIndex: 'object_type',
      render: (type: string, record) => {
        const _type = getType(type).toLowerCase();
        let Image = MainMenu.find((m) => m.key === _type)!.imgRed;

        if (_type === ModelsEnum.counterParty.toLowerCase()) {
          switch (record.business) {
            case CounterPartyBusiness.client:
              Image = ClientLogo;
              break;
            case CounterPartyBusiness.financier:
              Image = FinancyLogo;
              break;
            case CounterPartyBusiness.obligor:
              Image = ObligorLogo;
              break;
            case CounterPartyBusiness.partner:
              Image = PartnerLogo;
              break;
            case CounterPartyBusiness.underwriter:
              Image = UnderWriterLogo;
              break;
          }
        }
        return <Image className="status-icons" width={24} height={24} />;
      },
    },
    {
      title: 'type',
      dataIndex: 'type',
      width: 85,
      key: 'type',
      render: (_, record) => (
        <b style={{ textTransform: 'capitalize' }}>{getStatus(record)}</b>
      ),
    },
    {
      key: 'mandate_name',
      dataIndex: 'mandate_name',
      render: (_, record) => getName(record),
      ellipsis: true,
    },
    {
      key: 'client_name',
      dataIndex: 'client_name',
      render: (_, record) => getLastColumnValue(record),
      ellipsis: true,
    },
  ];
  return {
    isLoading,
    animation,
    onRow,
    rows,
    columns,
    blink,
  };
};
