import React, { createContext, useState } from "react";
import { Outlet } from "react-router-dom";
import ContentNavBar from "../Admin/ContentNavBar";
import Deals from "../../assets/img/Deals.svg";
export const ContextDeal = createContext<any>(null);

export default function DealsContent() {
  const [searchTxt, setSearchTxt] = useState<string | null>(null);

  return (
    <>
      <ContentNavBar
        title="Deals"
        logo={Deals}
        setTxt={(txt: any) => setSearchTxt(txt)}
        txt={searchTxt}
      />
      <ContextDeal.Provider value={{ txt: searchTxt }}>
        <Outlet />
      </ContextDeal.Provider>
    </>
  );
}
