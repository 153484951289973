import AddGroup from "../../assets/img/add.svg";
import { NavLink } from "react-router-dom";
type IProps = {
  title: string;
  onClick: () => void;
  disabled?: boolean;
};
export default function AddBtn(props: IProps) {
  return (
    <div className="d-flex my-3 ">
      <NavLink
        className={`btn red ${props.disabled ? "disabled" : ""}`}
        to="#"
        onClick={props.onClick}
      >
        <img src={AddGroup} alt="" className="pr-2" /> {props.title}
      </NavLink>
    </div>
  );
}
