export const mockRattings = (type: string) => {
  return type === 'moodys'
    ? [
        {
          key: 'non',
          value: 'non rated',
        },
        {
          key: 'C',
          value: 'C',
        },
        {
          key: 'Ca',
          value: 'Ca',
        },
        {
          key: 'Caa',
          value: 'Caa',
        },
        {
          key: 'B3',
          value: 'B3',
        },
        {
          key: 'B2',
          value: 'B2',
        },
        {
          key: 'B1',
          value: 'B1',
        },
        {
          key: 'Ba3',
          value: 'Ba3',
        },
        {
          key: 'Ba2',
          value: 'Ba2',
        },
        {
          key: 'Ba1',
          value: 'Ba1',
        },
        {
          key: 'Baa3',
          value: 'Baa3',
        },
        {
          key: 'Baa2',
          value: 'Baa2',
        },
        {
          key: 'Baa1',
          value: 'Baa1',
        },
        {
          key: 'A3',
          value: 'A3',
        },
        {
          key: 'A2',
          value: 'A2',
        },
        {
          key: 'A1',
          value: 'A1',
        },
        {
          key: 'Aa3',
          value: 'Aa3',
        },
        {
          key: 'Aa2',
          value: 'Aa2',
        },
        {
          key: 'Aa1',
          value: 'Aa1',
        },
        {
          key: 'Aaa',
          value: 'Aaa',
        },
      ]
    : type === 'sp' || type === 'fitch'
    ? [
        {
          key: 'non',
          value: 'non rated',
        },
        {
          key: 'D',
          value: 'D',
        },
        {
          key: 'C',
          value: 'C',
        },
        {
          key: 'CC',
          value: 'CC',
        },
        {
          key: 'CCC',
          value: 'CCC',
        },
        {
          key: 'B-',
          value: 'B-',
        },
        {
          key: 'B',
          value: 'B',
        },
        {
          key: 'B+',
          value: 'B+',
        },
        {
          key: 'BB-',
          value: 'BB-',
        },
        {
          key: 'BB',
          value: 'BB',
        },
        {
          key: 'BB+',
          value: 'BB+',
        },
        {
          key: 'BBB-',
          value: 'BBB-',
        },
        {
          key: 'BBB',
          value: 'BBB',
        },
        {
          key: 'BBB+',
          value: 'BBB+',
        },
        {
          key: 'A-',
          value: 'A-',
        },
        {
          key: 'A',
          value: 'A',
        },
        {
          key: 'A+',
          value: 'A+',
        },
        {
          key: 'AA-',
          value: 'AA-',
        },
        {
          key: 'AA',
          value: 'AA',
        },
        {
          key: 'AA+',
          value: 'AA+',
        },
        {
          key: 'AAA',
          value: 'AAA',
        },
      ]
    : type === 'ambest'
    ? [
        {
          key: 'non',
          value: 'non rated',
        },
        {
          key: 'D',
          value: 'D',
        },
        {
          key: 'C-',
          value: 'C-',
        },
        {
          key: 'C+',
          value: 'C+',
        },
        {
          key: 'C++',
          value: 'C++',
        },
        {
          key: 'B-',
          value: 'B-',
        },
        {
          key: 'B',
          value: 'B',
        },
        {
          key: 'B+',
          value: 'B+',
        },
        {
          key: 'B++',
          value: 'B++',
        },
        {
          key: 'A-',
          value: 'A-',
        },
        {
          key: 'A',
          value: 'A',
        },
        {
          key: 'A+',
          value: 'A+',
        },
        {
          key: 'A++',
          value: 'A++',
        },
      ]
    : [];
};
