import { useState } from "react";
import NavBar from "./NavBar";
import SideNav from "./SideNav";
import { Outlet } from "react-router";

const AdminBase = () => {
  const [menu, setMenu] = useState(true);

  return (
    <>
      <SideNav menu={menu} setMenu={(menu) => setMenu(menu)} />
      <div className={`container rightSide ${!menu ? "no-txt-menu" : ""} `}>
        <NavBar />

        <Outlet />
      </div>
    </>
  );
};

export default AdminBase;
