import { Col, Row, Form } from 'react-bootstrap';
import DropdownSelect, { DropDownProps } from './DropdownSelect';
import { useEffect } from 'react';

type Props = {
  title?: string;
  icon?: React.ReactNode;
  contentStyle?: string;
  dropDownClass?: string;
  iconsClass?: string;
  isRequired?: boolean;
  clearError?: () => void;
} & DropDownProps;

const SelectDropDown = ({
  title,
  icon,
  dropDownClass = 'me-1 px-0',
  ...props
}: Props) => {

  useEffect(() => {
    if (!props.isRequired && !props.value) {
      props.clearError?.();
    }
  }, [props.value, props.isRequired]);


  return (
    <>
      {title !== undefined ? (
        <div className='d-flex align-items-baseline'>
          <Form.Label className='txt-with-dropdown'>{title}</Form.Label>
          <div className='d-flex flex-column w-100'>
            <Row
              className={`${props.contentStyle}`}
              style={{ minWidth: 150 }}
            >
              <Col
                className='px-0 mx-0 my-0'
                onClick={(e) => e.stopPropagation()}
              >
                {icon ? (
                  <Row className='mx-0 px-0 pe-4 d-flex align-items-baseline' >
                    <div className='col mx-0 px-0'>
                      <DropdownSelect {...props} />
                    </div>
                    <div
                      style={{ width: '30px', }}
                      className={`${props.iconsClass ?? ''} my-auto`}
                    >
                      {icon}
                    </div>
                  </Row>
                ) : (
                  <Row className={`${dropDownClass}`}>
                    <DropdownSelect {...props} />
                  </Row>
                )}
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        <Row className={`${props.contentStyle}`}>
          <Col
            className='px-0 mx-0 my-0'
            onClick={(e) => e.stopPropagation()}
            style={{ minWidth: 150 }}
          >
            {icon ? (
              <Row className='mx-0 px-0 pe-4 d-flex align-items-baseline' >
                <div className='col mx-0 px-0'>
                  <DropdownSelect {...props} />
                </div>
                <div
                  style={{ width: '30px' }}
                  className={`${props.iconsClass ?? '' } my-auto`}
                >
                  {icon}
                </div>
              </Row>
            ) : (
              <Row className={`${dropDownClass}`}>
                <DropdownSelect {...props} />
              </Row>
            )}
          </Col>
        </Row>
      )}
    </>
  );
};

export default SelectDropDown;
