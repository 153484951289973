import React, { useState } from 'react';
import { ReactComponent as ArrowDown } from '../../../../assets/img/Arrow-Down-Gray.svg';
import { ReactComponent as ArrowUP } from '../../../../assets/img/Arrow-UP-Gray.svg';
import FilterTab from '../../../../utils/filter-tab';
import type { ColumnType } from 'antd/es/table';
import {
  amountFormat,
  exportDateUi,
  isCan,
  isExist,
  limitedText,
  onHeaderCell,
} from '../../../../utils/helper-function';
import UseConstante from '../../../../hooks/use-constante';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ReactComponent as IconCall } from '../../../../assets/img/IconCall.svg';
import { ReactComponent as IconMes } from '../../../../assets/img/IconMes.svg';
import { ReactComponent as IconWrapper } from '../../../../assets/img/IconWrapper.svg';
import { ReactComponent as IconCofarco } from '../../../../assets/img/IconCofarco.svg';
import classes from './ChildrenMarket.module.scss';
import { DataTypeMarket } from '../../../../@types/enquiries';
import { ReactComponent as Edit } from '../../../../assets/img/Edit.svg';
import { ReactComponent as Delete } from '../../../../assets/img/Delete.svg';
import { Menu } from '../../../../@types/common-types';
import './ColumnMarket.scss';

type DataIndex = keyof DataTypeMarket;

type IProps = {
  handleSorted: (key: string, ruls: 'ASC' | 'DESC') => void;
  indication: Array<DataTypeMarket>;
  handleChecked: (p: Partial<DataTypeMarket>) => void;
  handleDelete: (p: number) => void;
  viewMarket: (p: number) => void;
};
export default function ColumnsEnquiriesMarket(props: IProps) {
  const [dropDownFilterState, setDropDownFilterState] =
    useState<boolean>(false);
  const terms = UseConstante();

  const [uncheckedId, setUncheckedId] = useState<Array<number>>([]);

  const onChangeFilterColumn = (key: number | undefined) => {
    const found = uncheckedId?.find((uncheckedTitle) => uncheckedTitle === key);
    if (found) {
      setUncheckedId(uncheckedId.filter((t) => t !== key));
    } else {
      setUncheckedId([...uncheckedId, key || 0]);
    }
  };

  const handleStyle = (indType: string) => {
    if (indType === 'nbi') {
      return classes.nbiIndicationBtn;
    }
    if (indType === 'nty') {
      return classes.ntyIndicationBtn;
    }
    if (indType === 'eoi') {
      return classes.eoyIndicationBtn;
    }
    if (indType === 'nr') {
      return classes.nrIndicationBtn;
    }
    if (indType === 'pending') {
      return classes.pendIndicationBtn;
    }

    return classes.pendingIndicationBtn;
  };

  const getColumnSearchProps = (
    dataIndex:
      | DataIndex
      | 'underwriterId'
      | 'contactId'
      | 'contactedDate'
      | 'channel'
      | 'indicationType'
      | 'indicationDate'
      | 'amount'
      | 'indicationPrice'
      | 'comments',
  ): ColumnType<DataTypeMarket> => ({
    filterDropdown: ({ close }) =>
      FilterTab<DataTypeMarket>({
        columns: columns?.filter((el: any) => {
          return el.key != 10;
        }),
        dataIndex: dataIndex,
        handleSorted: (dataIndex: string, rule: 'ASC' | 'DESC') =>
          props.handleSorted(dataIndex, rule),
        onChangeFilterColumn: (key: number | undefined) =>
          onChangeFilterColumn(key),
        uncheckedId: uncheckedId,
        close: () => close(),
      }),

    filterIcon: () => {
      return dropDownFilterState ? (
        <ArrowDown width={12} height={12} />
      ) : (
        <ArrowUP width={12} height={12} />
      );
    },
    onFilterDropdownOpenChange: (visible) => {
      setDropDownFilterState(visible);
    },
    render: (text: string) => <span>{text}</span>,
  });

  const handleFont = (txt: string): any => {
    let nodes;
    switch (txt) {
      case 'email':
        nodes = <IconWrapper fontSize={15} />;
        break;
      case 'dialogue':
        nodes = <IconMes />;
        break;
      case 'meeting':
        nodes = <IconCofarco />;
        break;
      case 'call':
        nodes = <IconCall />;
        break;
      case 'other':
        nodes = (
          <div>
            <span>
              <b>Other</b>
            </span>
          </div>
        );
        break;
      default:
        nodes = (
          <div>
            <span>
              <b>-</b>
            </span>
          </div>
        );
        break;
    }
    return nodes;
  };

  const columns: any & {
    editable?: boolean;
  } = React.useMemo(
    () =>
      [
        {
          title: '',
          dataIndex: 'id',
          key: 10,
          width: 90,
          align: 'center',
          render: (text: string, rec: Partial<DataTypeMarket>) => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: 60,
                marginRight: -25,
              }}
            >
              <span>
                <Form.Check
                  type="radio"
                  checked={rec.approached === 1 ? true : false}
                  // onClick={(evt: any) => {
                  //   isCan('write', Menu.indication) &&
                  //     props.handleChecked({
                  //       ...rec,
                  //       approached: rec.approached === 1 ? 0 : 1,
                  //     });
                  // }}
                  onChange={() => null}
                />
              </span>
            </div>
          ),
        },
        {
          title: 'UNDERWRITER ID',
          dataIndex: 'underwriter',
          onHeaderCell: onHeaderCell,
          editable: isCan('write', Menu.indication) ? true : false,
          key: 1,
          ...getColumnSearchProps('underwriterId'),
          render: (text: string) => <span> {text}</span>,
        },
        {
          title: 'CONTACT',
          dataIndex: 'contact',
          key: 2,
          editable: isCan('write', Menu.indication) ? true : false,
          ...getColumnSearchProps('contactId'),
          onHeaderCell: onHeaderCell,
          render(text: any, record: any) {
            return {
              props: {},
              children:
                (isExist(record.contacted_date) || isExist(record.channel)) &&
                !isExist(record.contact) ? (
                  <div className="isEmptyRequired">
                    <b>* is required</b>
                  </div>
                ) : (
                  <span>{text ? text : '-'}</span>
                ),
            };
          },
          // render: (text: string) => {
          //   if (text) {
          //     return <span>{text}</span>;
          //   }
          //   return <span className='py-2 text-center'>-</span>;
          // },
        },
        {
          title: 'SUBMISSION DATE',
          dataIndex: 'contacted_date',
          key: 3,
          editable: isCan('write', Menu.indication) ? true : false,
          ...getColumnSearchProps('contactedDate'),
          onHeaderCell: onHeaderCell,
          render(text: any, record: any) {
            return {
              props: {},
              children:
                (isExist(record.contact) || isExist(record.channel)) &&
                !isExist(record.contacted_date) ? (
                  <div className="isEmptyRequired">
                    <b>* is required</b>
                  </div>
                ) : (
                  <b>{text ? exportDateUi(text)?.toString() : '-'}</b>
                ),
            };
          },
        },
        {
          title: 'CHANNEL',
          dataIndex: 'channel',
          onHeaderCell: onHeaderCell,
          editable: isCan('write', Menu.indication) ? true : false,
          key: 4,
          ...getColumnSearchProps('channel'),

          render(text: any, record: any) {
            return {
              props: {},
              children:
                (isExist(record.contact) || isExist(record.contacted_date)) &&
                !isExist(record.channel) ? (
                  <div className="isEmptyRequired">
                    <b>* is required</b>
                  </div>
                ) : (
                  <span className="d-flex align-items justify-content-center ">
                    {handleFont(text)}
                  </span>
                ),
            };
          },
          align: 'center',
          width: 90,
        },
        {
          title: 'INDICATION',
          dataIndex: 'indication_type',
          onHeaderCell: onHeaderCell,
          editable: () => (isCan('write', Menu.indication) ? true : false),

          key: 5,
          ...getColumnSearchProps('indicationType'),
          render: (text: string) => {
            return <b className={handleStyle(text)}> {text}</b>;
          },
          width: 90,
          align: 'center',
        },
        {
          title: 'INDICATION DATE',
          editable: isCan('write', Menu.indication) ? true : false,
          dataIndex: 'indication_date',
          onHeaderCell: onHeaderCell,
          key: 6,
          ...getColumnSearchProps('indicationDate'),
          render: (text: string) => {
            if (text) {
              return <b>{exportDateUi(text)?.toString()}</b>;
            }
            return <span className="py-2">-</span>;
          },
        },
        {
          title: 'LINE',
          dataIndex: 'amount',
          editable: isCan('write', Menu.indication) ? true : false,
          onHeaderCell: onHeaderCell,
          key: 7,
          ...getColumnSearchProps('amount'),
          render: (text: string, rec: any) => {
            if (text && rec.indication_type !== 'nty') {
              return <b>{amountFormat(text)}</b>;
            }
            return <span className="py-2">-</span>;
          },
        },
        {
          title: 'PRICE',
          dataIndex: 'indication_price',
          editable: isCan('write', Menu.indication) ? true : false,
          onHeaderCell: onHeaderCell,
          key: 8,
          ...getColumnSearchProps('indicationPrice'),
          render: (text: string) => {
            if (text) {
              return (
                <span>
                  <b>{text}</b>
                </span>
              );
            }
            return <span className="py-2">-</span>;
          },
        },
        {
          title: 'COMMENTS',
          dataIndex: 'comments',
          onHeaderCell: onHeaderCell,
          editable: isCan('write', Menu.indication) ? true : false,
          key: 9,
          ...getColumnSearchProps('comments'),
          render: (text: string) => {
            if (text) {
              return (
                <OverlayTrigger
                  placement="auto"
                  overlay={
                    <Tooltip color="red">
                      <b>{text}</b>
                    </Tooltip>
                  }
                >
                  <b>{limitedText(text, 18)}</b>
                </OverlayTrigger>
              );
            }
            return <span className="py-2">-</span>;
          },
        },
        {
          title: '',
          dataIndex: 'id',
          key: 11,
          width: 120,
          hidden:
            !isCan('write', Menu.indication) &&
            !isCan('delete', Menu.indication),
          render: (text: number) => {
            return (
              <div style={{ minWidth: 'max-content', maxWidth: 'max-content' }}>
                {isCan('write', Menu.indication) && (
                  <span style={{ minWidth: 95 }}>
                    <i
                      className="rounded-icon"
                      onClick={() => props.viewMarket(text)}
                    >
                      <Edit height="30" />
                    </i>
                  </span>
                )}

                {isCan('delete', Menu.indication) && (
                  <span style={{ minWidth: 55 }}>
                    <i
                      className="rounded-icon"
                      onClick={() => props.handleDelete(text)}
                    >
                      <Delete height="30" />
                    </i>
                  </span>
                )}
              </div>
            );
          },
        },
      ].filter((el) => !el.hidden),
    [props.indication, dropDownFilterState, uncheckedId, terms],
  );

  return { columns, uncheckedId };
}
