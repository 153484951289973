import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Checkbox, Typography } from 'antd';
import { assignWith, isEmpty, pick, pickBy } from 'lodash';
import { useForm } from 'react-hook-form';
import { ColumnsType } from 'antd/es/table';
import { useListTermsQuery } from '../../../../redux/api/ws/terms/get';
import {
  UnderwriterFormInputs,
  ConterParty,
  LegalEntity,
  ChoiceType,
} from '../../../../@types/conterParty';
import {
  useLazyPeopleByIdQuery,
  useLazySearchPeopleQuery,
} from '../../../../redux/api/ws/databases/people';
import { People } from '../../../../@types/people';
import UseDynamicsTable from '../../../dynamic-table/use-dynamics-table';
import { ReactComponent as Edit } from '../../../../assets/img/Edit.svg';
import { ReactComponent as Delete } from '../../../../assets/img/Delete.svg';
import DropdownSelect, {
  DropDownOptionObjectType,
  DropDownOptionType,
} from '../../../common/DropDown/DropdownSelect';
import { useSearchLegalEntityNameMutation } from '../../../../redux/api/ws/databases/legale-entities';
import {
  useCreateCounterPartyMutation,
  useEditCounterPartyMutation,
  useSetCounterPartyLegalentityMutation,
} from '../../../../redux/api/ws/counterparties/post';
import classes from './UnderwriterModal.module.scss';
import { useAppDispatch } from '../../../../hooks/redux';
import { updateNotificationMessage } from '../../../../redux/slice/notificationSlice';
import { updateModalAction } from '../../../../redux/slice/modalSlice';
import { Button } from 'react-bootstrap';
import { isCan, isExist } from '../../../../utils/helper-function';
import { Menu } from '../../../../@types/common-types';

type TLegalEntity = Pick<LegalEntity, 'id' | 'name'> & {
  key: any;
  legal_entity?: LegalEntity;
  temp_legal_entity?: LegalEntity;
  roles: string[];
  temp_roles?: string[];
};

const useUnderwriterModal = ({
  defaultFormValues,
  onSubmittedForm,
}: {
  onSubmittedForm?: () => void;
  defaultFormValues?: ConterParty | null;
}) => {
  const choiceThee: { key: ChoiceType; value: string }[] = [
    {
      key: 'yes',
      value: 'Yes',
    },
    {
      key: 'no',
      value: 'No',
    },
    {
      key: 'unknown',
      value: "Don't Know",
    },
  ];

  const chekcOptions = [
    { label: "Lloyd's Syndicate", value: 'lloyds' },
    { label: 'Managing Agent', value: 'mga' },
    { label: 'Stamp', value: 'stamp' },
  ];
  const defaultValues: UnderwriterFormInputs = useMemo(
    () => ({
      business: 'underwriter',
      id: -1,
      name: '',
      commercial_name: '',
      type: '',
      country_id: '',
      legal_entity_id: -1,
      activity: '',
      market: '',
      main_people_id: null,
      most_senior_people_id: null,
      isDirty: 1,
      informations: {
        access: undefined,
        m2m: undefined,
      },
    }),
    [],
  );
  const [isCompletedForm, setIsCompletedForm] = useState(false);

  const formValues = useMemo(
    () =>
      defaultFormValues
        ? ({
            ...pick(defaultFormValues, Object.keys(defaultValues)),
          } as UnderwriterFormInputs)
        : defaultValues,
    [defaultFormValues, defaultValues],
  );

  const [isShowModalUnsavedChange, setIsShowModalUnsavedChange] =
    useState<boolean>(false);
  const [showOtherModaldata, setShowOtherModal] = useState<any>();

  const {
    handleSubmit,
    register,
    // trigger: triggerFormValidation,
    watch: watchFormValue,
    reset: resetForm,
    setValue: setFormValue,
    getValues: getFormValue,
    formState: { errors, isDirty, dirtyFields },
  } = useForm<UnderwriterFormInputs>({
    shouldUseNativeValidation: false,
    defaultValues: formValues,
    reValidateMode: 'onChange',
    mode: 'all',
  });

  const updateDataSourceWithRoles = (
    key: any,
    v: string,
    legal_entity: any,
    roles: string[],
    isSave: boolean = false,
    isCancel: boolean = false,
  ) => {
    const index = dataSourceLegalEntity.findIndex((f) => f.key === key);
    if (index > -1) {
      const newDataSource = [...dataSourceLegalEntity];
      const ds = newDataSource[index];
      if (isSave) {
        newDataSource[index] = {
          ...ds,
          name: v,
          temp_legal_entity: undefined,
          legal_entity: legal_entity,
          roles: roles,
          temp_roles: undefined,
        };
        // setFormValue("legal_entity_id", legal_entity.id);
        setFormValue('isUpdatedTable', true, { shouldDirty: true });
      } else if (isCancel) {
        const removedEmptyEntity = newDataSource?.filter(
          (el) => el.legal_entity !== undefined,
        );
        setDataSourceLegalEntity(removedEmptyEntity);
        return;
      } else {
        newDataSource[index] = {
          ...newDataSource[index],
          temp_roles: roles,
          name: v,
          temp_legal_entity: legal_entity as LegalEntity,
        };
      }
      setDataSourceLegalEntity(newDataSource);
    }
  };
  const updateRoles = (
    key: any,
    roles: string[],
    isSave: boolean = false,
    isCancel: boolean = false,
  ) => {
    const index = dataSourceLegalEntity.findIndex((f) => f.key === key);
    if (index > -1) {
      const newDataSource = [...dataSourceLegalEntity];
      const ds = newDataSource[index];
      if (isSave) {
        newDataSource[index] = {
          ...ds,
          roles: roles,
          temp_roles: undefined,
        };
        // setFormValue("legal_entity_id", legal_entity.id);
      } else if (isCancel) {
        newDataSource[index] = {
          ...newDataSource[index],
          temp_roles: undefined,
        };
      } else {
        newDataSource[index] = {
          ...newDataSource[index],
          temp_roles: roles,
        };
      }
      setDataSourceLegalEntity(newDataSource);
    }
  };

  const updateDataSource = (
    key: any,
    v: string,
    legal_entity: any,
    isSave: boolean = false,
    isCancel: boolean = false,
  ) => {
    const index = dataSourceLegalEntity.findIndex((f) => f.key === key);
    if (index > -1) {
      const newDataSource = [...dataSourceLegalEntity];
      if (isSave) {
        newDataSource[index] = {
          ...newDataSource[index],
          name: v,
          temp_legal_entity: undefined,
          legal_entity: legal_entity,
        };
        // setFormValue("legal_entity_id", legal_entity.id);
      } else if (isCancel) {
        newDataSource[index] = {
          ...newDataSource[index],
          name: newDataSource[index].legal_entity?.name ?? '',
          temp_legal_entity: undefined,
        };
      } else {
        newDataSource[index] = {
          ...newDataSource[index],
          name: v,
          temp_legal_entity: legal_entity as LegalEntity,
        };
      }
      setDataSourceLegalEntity(newDataSource);
    }
  };

  const columnLegalEntity: ColumnsType<TLegalEntity> = [
    {
      title: 'LEGAL ENTITY',
      dataIndex: 'name',
      key: 1,
      className: 'w-50 text-start ms-0',
      render: (text: number, record: TLegalEntity) => {
        const editable = isEdittingLegalEntity(record);
        return editable ? (
          <div className="d-flex flex-column">
            <div style={{ maxWidth: 420 }}>
              <DropdownSelect
                preValue={record.name}
                onSelectOption={(e) => {
                  updateDataSource(
                    record.key,
                    (e as DropDownOptionObjectType).value as string,
                    (e as DropDownOptionObjectType).key,
                  );
                }}
                options={[]}
                onSearch={(d, e) => searchLegalEntity(e, d)}
                loading={isLoading}
              />
            </div>
            <div className="d-flex justify-content-end py-4">
              <div className="d-inline px-2">
                <Button
                  className={`${classes.btnSave}`}
                  disabled={editable && !isExist(record.name)}
                  onClick={() => {
                    updateDataSourceWithRoles(
                      record.key,
                      record.temp_legal_entity?.name ?? '',
                      record.temp_legal_entity,
                      record.temp_roles ?? record.roles,
                      true,
                    );
                    setLegalEntityEditingKey('');
                  }}
                >
                  Update
                </Button>
              </div>
              <div className="d-inline px-2">
                <Button
                  variant="default"
                  onClick={() => {
                    updateDataSourceWithRoles(
                      record.key,
                      record.legal_entity?.name ?? '',
                      undefined,
                      [],
                      false,
                      true,
                    );
                    setLegalEntityEditingKey('');
                  }}
                  className={`${classes.btnCancel}`}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="py-3 ps-3">{record.name}</div>
        );
      },
    },
    {
      title: 'ROLES',
      dataIndex: 'roles',
      className: 'h-100 d-flex',
      key: 2,
      render: (text: number, record: TLegalEntity) => {
        const editable = isEdittingLegalEntity(record);

        return editable ? (
          <div className="h-100 pt-3">
            <Checkbox.Group
              className="d-flex"
              options={chekcOptions}
              defaultValue={record.roles}
              onChange={(values) => {
                updateRoles(record.key, values as string[]);
              }}
            />
          </div>
        ) : (
          <div className="py-3 ps-3">
            {record.roles
              .map((p) => chekcOptions.find((r) => r.value === p)?.label ?? '')
              .join(', ') ?? ''}
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: 'id',
      key: 3,
      width: 120,
      hidden:
        !isCan('write', Menu.legal_entity) &&
        !isCan('delete', Menu.legal_entity),
      render: (text: number, record: TLegalEntity) => {
        const editable = isEdittingLegalEntity(record);
        return editable ? (
          <span></span>
        ) : (
          <>
            {isCan('write', Menu.legal_entity) && (
              <Typography.Link
                disabled={editinKeyLegalEntity !== ''}
                onClick={() => {
                  updateDataSource(
                    record.key,
                    record.name,
                    record.legal_entity,
                  );
                  editLegalEntity(record);
                }}
                style={{ marginRight: 10 }}
              >
                <Edit height="30" />
              </Typography.Link>
            )}
            {isCan('delete', Menu.legal_entity) && (
              <Typography.Link
                disabled={editinKeyLegalEntity !== ''}
                onClick={() => {
                  // removeItemLegalEntity(record.key);
                  setDeleteModal({
                    show: true,
                    id: record.key,
                    legal_entity: record.legal_entity,
                  });
                  // setFormValue("legal_entity_id", 0);
                }}
              >
                <Delete height="30" />
              </Typography.Link>
            )}
          </>
        );
      },
    },
  ].filter((el) => !el.hidden);

  const {
    edit: editLegalEntity,
    mergedColumns: mergedLegalEntity,
    isEditing: isEdittingLegalEntity,
    isInModeEditing,
    editingKey: editinKeyLegalEntity,
    form: formLegalEntity,
    dataSource: dataSourceLegalEntity,
    removeItem: removeItemLegalEntity,
    add: addLegalEntity,
    setDataSource: setDataSourceLegalEntity,
    setEditingKey: setLegalEntityEditingKey,
  } = UseDynamicsTable<TLegalEntity>({
    rowItem: {
      id: 0,
      key: 0,
      name: '',
      roles: ['stamp'],
      legal_entity: undefined,
    },
    column: columnLegalEntity,
  });

  const { data: terms } = useListTermsQuery(undefined, {
    refetchOnMountOrArgChange: false,
  });
  const [searchPeople] = useLazySearchPeopleQuery();
  const [searchLegalEntityQuery, { isLoading }] =
    useSearchLegalEntityNameMutation();
  const [createCounterParty] = useCreateCounterPartyMutation();
  const [editCounterParty] = useEditCounterPartyMutation();
  const [setCounterPartyLegalEntity] = useSetCounterPartyLegalentityMutation();
  const [getPeopleById] = useLazyPeopleByIdQuery();
  const dispatch = useAppDispatch();

  const [deleteModal, setDeleteModal] = useState<{
    show: boolean;
    id?: number;
    legal_entity?: LegalEntity;
  }>({ show: false, id: undefined });

  const closeDeleteLegalEntity = () => {
    setDeleteModal({ show: false, id: undefined });
    if (dataSourceLegalEntity?.length > 0) {
      setFormValue('isUpdatedTable', true, { shouldDirty: true });
    }
  };
  const deleteLegalEntity = (id: number) => {
    removeItemLegalEntity(id);
    setFormValue('legal_entity_id', 0);
    closeDeleteLegalEntity();
  };

  const [peoples, setPeoples] = useState<People[]>([]);

  const isEdit = isExist(formValues?.id);

  const showPeopleModal = (p: number | string, noCheck?: boolean) => {
    getPeopleById({ id: p }).then((res) => {
      if (res.data?.people) {
        if (!isEmpty(dirtyFields) && !noCheck && isEdit) {
          setIsShowModalUnsavedChange(true);
          setShowOtherModal(p);
        } else {
          dispatch(
            updateModalAction({
              data: res.data?.people,
              isAdd: true,
              type: 'people',
            }),
          );
          setIsShowModalUnsavedChange(false);
          isEdit && resetForm(formValues);
        }
      }
    });
  };

  const searchLegalEntity = useCallback(
    (
      value: string,
      dipatch?: Dispatch<SetStateAction<DropDownOptionType[]>>,
    ) => {
      // setIsLeagEntityLoading(true);
      searchLegalEntityQuery({
        page: 1,
        start: 0,
        limit: 100,
        query: `%${value}%`,
      })
        .then((resp) => {
          const rep = resp as any;
          if (rep.data) {
            const datas = (rep.data.legalentity as LegalEntity[])
              .filter((f) => f && f.name.trim() !== '')
              .map((e) => ({ key: e, value: e.name ?? '' }));
            // setLegalEntities(datas);
            if (dipatch) dipatch(datas);
          }
        })
        .finally(() => {
          // setIsLeagEntityLoading(false);
        });
    },
    [],
  );

  const searchPeopleCallback = useCallback(
    (
      legal_entity_id: number[],
      v: string,
      callback: (people: People[]) => void,
    ) => {
      if (legal_entity_id.length === 0) {
        return callback([]);
      }
      searchPeople({
        key: 'name',
        value: v,
        params: { limit: 1000, legalEntityId: legal_entity_id },
      }).then((res) => {
        if (res.data && res.data.people) {
          callback(res.data.people);
        }
      });
    },
    [],
  );

  const handleClose = () => {
    resetForm(defaultValues);
    setDataSourceLegalEntity([]);
  };

  const onSubmitForm = (e: any) => {
    e.preventDefault();
    handleSubmit(saveCounterParty, onError)();
  };
  const onError = () => null;

  const setCounterPartyLegalEntityCallback = useCallback(
    (id: number, data: any) => {
      setCounterPartyLegalEntity({ data, id })
        .then(() => {})
        .finally(() => {
          handleClose();
          if (onSubmittedForm) onSubmittedForm();
        });
    },
    [],
  );

  const saveCounterParty = useCallback(
    (data: any) => {
      const les = dataSourceLegalEntity
        .filter((e) => e.legal_entity)
        .map((e) => ({
          legalEntityId: e.legal_entity?.id,
          roles: e.roles,
        }));

      if (data.id && data.id > 0) {
        const dataToedit = assignWith(
          {},
          pickBy(
            data,
            (v, k) =>
              Object.keys(formValues).includes(k) &&
              (formValues as Record<string, any>)[k] !== v &&
              !k.startsWith('_') &&
              !['legal_entity_id'].includes(k),
          ) as Record<string, any>,
          (_, value) => (typeof value == 'undefined' ? '' : value),
        );
        editCounterParty({
          data: {
            ...dataToedit,
            //legal_entity_id: dataSourceLegalEntity?.[0]?.legal_entity?.id,
          },
          id: data.id,
        })
          .then((resp: any) => {
            dispatch(
              updateNotificationMessage({
                show: true,
                title: 'Underwriter update',
                body: 'Successfully updated !',
              }),
            );
            if (resp.data && resp.data.counterparty && les.length > 0) {
              const c = resp.data.counterparty as ConterParty;
              setCounterPartyLegalEntityCallback(c.id, les);
            } else {
              if (onSubmittedForm) onSubmittedForm();
            }
          })
          .finally(() => {
            handleClose();
          });
      } else {
        createCounterParty({
          data: {
            ...data,
            legal_entity_id: dataSourceLegalEntity[0].legal_entity?.id,
          },
          businessType: 'underwriter',
        })
          .then((resp: any) => {
            dispatch(
              updateNotificationMessage({
                show: true,
                title: 'Underwriter add',
                body: 'Successfully added !',
              }),
            );
            if (resp.data && resp.data.counterparty && les.length > 0) {
              const c = resp.data.counterparty as ConterParty;
              setCounterPartyLegalEntityCallback(c.id, les);
            } else {
              if (onSubmittedForm) onSubmittedForm();
            }
          })
          .finally(() => {
            handleClose();
          });
      }
    },
    [formValues, dataSourceLegalEntity],
  );

  useEffect(() => {
    const all_terms = terms?.term;
    resetForm(defaultFormValues as UnderwriterFormInputs);
    if (all_terms) {
      setFormValue(
        '_type',
        all_terms.find((t) => t.key === defaultFormValues?.type)?.value ?? '',
      );
      setFormValue(
        '_activity',
        all_terms.find((t) => t.key === defaultFormValues?.activity)?.value ??
          '',
      );
      setFormValue(
        '_market',
        all_terms.find((t) => t.key === defaultFormValues?.market)?.value ?? '',
      );
    }
    searchPeopleCallback(
      defaultFormValues?.legal_entities &&
        defaultFormValues?.legal_entities.length > 0
        ? defaultFormValues?.legal_entities.map((l) => l.id)
        : [defaultFormValues?.legal_entity_id ?? 0],
      '',
      (people) => {
        const mainp = people.find(
          (t) => t.id === defaultFormValues?.main_people_id,
        );
        if (mainp) {
          setFormValue(
            '_main_people_id',
            `${mainp?.firstname} ${mainp?.lastname}`,
          );
          setFormValue('_main_people', mainp);
        }
        const mostp = people.find(
          (t) => t.id === defaultFormValues?.most_senior_people_id,
        );
        if (mostp) {
          setFormValue(
            '_most_senior_people_id',
            `${mostp?.firstname} ${mostp?.lastname}`,
          );
          setFormValue('_most_senior_people', mostp);
        }
      },
    );
  }, [defaultFormValues, terms?.term]);

  useEffect(() => {
    if (defaultFormValues?.legal_entity) {
      if (
        defaultFormValues?.legal_entities &&
        defaultFormValues?.legal_entities.length > 0
      ) {
        setDataSourceLegalEntity([
          // ...dataSource,
          ...(defaultFormValues?.legal_entities || []).map((e, index) => ({
            key: index + 1,
            id: index + 1,
            roles: (e.pivot?.roles as string | undefined)?.split(',') ?? [],
            name: e.name,
            legal_entity: e,
          })),
          // .filter(
          //     (p) => p.legal_entity?.id !== defaultFormValues.legal_entity?.id
          //   ) ?? []
        ]);
      }
    } else {
      if (
        defaultFormValues?.legal_entities &&
        defaultFormValues?.legal_entities.length > 0
      ) {
        const dataS = defaultFormValues?.legal_entities.map((e, index) => ({
          key: index,
          id: index,
          roles: (e.pivot?.roles as string | undefined)?.split(',') ?? [],
          name: e.name,
          legal_entity: e,
        }));

        setDataSourceLegalEntity(dataS);
      }
    }
  }, [defaultFormValues]);

  useEffect(() => {
    if (dataSourceLegalEntity.length > 0) {
      searchPeopleCallback(
        dataSourceLegalEntity
          .filter((e) => e.legal_entity)
          .map((e) => e.legal_entity?.id ?? -1),
        '',
        (data) => {
          setPeoples(data);
        },
      );
    } else {
      setPeoples([]);
    }
  }, [dataSourceLegalEntity]);

  useEffect(() => {
    const subscription = watchFormValue((value) => {
      const isCompletedRequired = isExist(value.name);
      setIsCompletedForm(isCompletedRequired ? true : false);
    });
    return () => subscription.unsubscribe();
  }, [watchFormValue]);

  return {
    errors,
    defaultValues: formValues,
    terms: terms?.term ?? [],
    peoples,
    choiceThee,
    searchPeopleCallback,
    onSubmitForm,
    register,
    watchFormValue,
    resetForm,
    setFormValue,
    getFormValue,
    mergedLegalEntity,
    formLegalEntity,
    dataSourceLegalEntity,
    columnLegalEntity,
    addLegalEntity,
    setDataSourceLegalEntity,
    saveCounterParty,
    handleClose,
    showPeopleModal,
    deleteModal,
    closeDeleteLegalEntity,
    deleteLegalEntity,
    isInModeEditing,
    setLegalEntityEditingKey,
    isDirty,
    isCompletedForm,
    isShowModalUnsavedChange,
    setIsShowModalUnsavedChange,
    showOtherModaldata,
  };
};

export default useUnderwriterModal;
