export const mockEquitySize = [
  {
    key: '0 to 5m$',
    value: '0_to_5',
  },
  {
    key: '5 to 10m$',
    value: '5_to_10',
  },
  {
    key: '10 to 50m$',
    value: '10_to_50',
  },
  {
    key: '50 to 250m$',
    value: '50_to_250',
  },
  {
    key: '250 to 1bn$',
    value: '250_to_1bn',
  },
  {
    key: '>1bn$',
    value: 'more_1bn',
  },
];
