import { Form, FormControl, FormControlProps } from 'react-bootstrap';
import classes from './TierForm.module.scss';
import { forwardRef, useMemo, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import FormError from '../../../../FormError/FormError';

type Props = {
  title?: string;
  icon?: React.ReactNode;
  errorMessage?: string;
  prev?: string;
  tagType: string;
  setIsValueTiersUpdate: (p: boolean) => void;
} & FormControlProps;

const TierForm = forwardRef<HTMLInputElement, Props>(
  ({ title, icon, errorMessage, prev, ...props }: Props, ref) => {
    const [value] = useState<string>((props.value ?? '') as string);

    const openWindow = () => {
      if (!errorMessage && value?.trim().length > 0) {
        window.open(`${prev ?? 'tel'}:${value}`, '_self');
      }
    };

    const labelRef = useRef<HTMLDivElement>(null);

    const labelWidth = useMemo(() => {
      if (labelRef.current) {
        return labelRef.current.offsetWidth;
      }
      return 0;
    }, [labelRef.current]);

    return (
      <>
        <Form.Group className="d-flex d-flex align-items-center">
          <div ref={labelRef}>
            <Form.Label htmlFor={props.id} className={`txt`}>
              {title}
            </Form.Label>
          </div>
          <div className="w-100 mx-1">
            <FormControl
              {...props}
              id="filed-input-tier"
              value={(props.value ?? '') as string}
              onChange={(e) => {
                const v: string = e?.target?.value as string;
                const match = /^[\d()+-]+$/im.test(v);
                if (props.onChange) {
                  if (props.tagType === 'email') {
                    props.onChange(e);
                  }

                  if (props.tagType === 'phone') {
                    if (match || isEmpty(v)) props.onChange(e);
                  }
                }
              }}
              as="input"
              ref={ref}
              className={`${classes.fromControl} me-1 mx-2 ${props.className}`}
            />
          </div>

          <div className="ps-2">
            <div
              className="p-1 mt-1 "
              style={{
                backgroundColor:
                  !errorMessage && (props.value as string)?.trim().length > 0
                    ? '#851001'
                    : '#f1f1f1',
                color: 'white',
              }}
              onClick={openWindow}
            >
              {icon}
            </div>
          </div>
        </Form.Group>
        <div className="text-start">
          <FormError
            message={errorMessage as string}
            meesageStyle={{
              fontSize: 12,
            }}
            style={{
              marginInlineStart: labelWidth + 10,
              width: '100px !important',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          />
        </div>
      </>
    );
  },
);

TierForm.displayName = 'TierForm';

export default TierForm;
