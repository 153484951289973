import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { languageResource } from './i18n/index';

// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = languageResource;

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
