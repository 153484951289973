import { ColumnType, ColumnsType } from 'antd/es/table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { updateModalAction } from '../../redux/slice/modalSlice';
import { useAppDispatch } from '../redux';
import { concatString, exportDateUi, isCan } from '../../utils/helper-function';
import classes from '../../components/Dashboard/Dashboard.module.scss';
import { Menu } from '../../@types/common-types';
import { useDeleteCofarcoTeamMutation } from '../../redux/api/ws/databases/cofarco-teams';
import { EntityCofarcoTeam } from '../../@types/cofarco-teams/type';
import { ReactComponent as Edit } from '../../assets/img/Edit.svg';
import { ReactComponent as Delete } from '../../assets/img/Delete.svg';
import { ReactComponent as ArrowDown } from '../../assets/img/Arrow-Down-Gray.svg';
import { ReactComponent as ArrowUP } from '../../assets/img/Arrow-UP-Gray.svg';
import FilterTab from '../../utils/filter-tab';
import { capitalize } from 'lodash';
import { People } from '../../@types/people';

const useTeamResult = () => {
  const dispatch = useAppDispatch();

  const [deleteUser, { isLoading }] = useDeleteCofarcoTeamMutation();

  const [showmodalDelete, setShowmodalDelete] = useState<boolean>(false);

  const [selectedUser, setSelectedUser] = useState<People | null>(null);

  const [dropDownFilterState, setDropDownFilterState] =
    useState<boolean>(false);
  const [uncheckedId, setUncheckedId] = useState<Array<number>>([]);

  const onChangeFilterColumn = (key: number | undefined) => {
    const found = uncheckedId?.find((uncheckedTitle) => uncheckedTitle === key);
    if (found) {
      setUncheckedId(uncheckedId.filter((t) => t !== key));
    } else {
      setUncheckedId([...uncheckedId, key || 0]);
    }
  };

  useEffect(() => {
    console.log('showmodalDelete', showmodalDelete);
  }, [showmodalDelete]);

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<People> => ({
    filterDropdown: ({ close }) =>
      FilterTab<People>({
        columns: columns,
        dataIndex: dataIndex,
        handleSorted: () => {},
        onChangeFilterColumn: (key: number | undefined) =>
          onChangeFilterColumn(key),
        uncheckedId: uncheckedId,
        close: () => close(),
      }),
    filterIcon: () => {
      return dropDownFilterState ? (
        <ArrowDown width={12} height={12} />
      ) : (
        <ArrowUP width={12} height={12} />
      );
    },
    onFilterDropdownOpenChange: (visible) => {
      setDropDownFilterState(visible);
    },
    render: (text: string) => <span>{capitalize(text)}</span>,
  });

  const columns: ColumnsType<People> = useMemo(
    () =>
      [
        {
          title: 'FIRST NAME',
          dataIndex: 'firstname',
          key: 'firstname',
          defaultSortOrder: 'ascend',
          ...getColumnSearchProps('firstname'),
          className: classes.fixedSizeHeaderName,
          render: (value: any, record: EntityCofarcoTeam) => (
            <div
              style={{
                minWidth: '100px',
                maxWidth: '100px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              <span>{record.people.firstname ?? ''}</span>
            </div>
          ),
        },
        {
          title: 'LAST NAME',
          dataIndex: 'lastname',
          key: 'lastname',
          defaultSortOrder: 'ascend',
          ellipsis: true,
          ...getColumnSearchProps('lastname'),
          className: classes.fixedSizeHeaderName,
          render: (value: any, record: EntityCofarcoTeam) => (
            <div
              style={{
                minWidth: '80px',
                maxWidth: '80px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              <span>{record.people.lastname ?? ''}</span>
            </div>
          ),
        },
        {
          title: 'EMAIL',
          dataIndex: 'professional_mail',
          key: 'professional_mail',
          ellipsis: true,
          ...getColumnSearchProps('professional_mail'),
          className: classes.fixedSizeHeader,
          defaultSortOrder: 'ascend',
          onCell: () => {
            return {
              onClick: (event: any) => {
                event.stopPropagation();
              },
            };
          },
          render: (value: any, record: EntityCofarcoTeam) => (
            <div
              style={{
                minWidth: '150px',
                maxWidth: '150px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              <a className={classes.email} href={`mailto:${value}`}>
                <span>{record.people.professional_mail}</span>
              </a>
            </div>
          ),
        },
        {
          title: 'PHONE NUMBER',
          dataIndex: 'phone_number',
          className: classes.fixedSizeHeaderPhone,
          key: 'phone_number',
          ...getColumnSearchProps('phone_number'),
          defaultSortOrder: 'ascend',
          ellipsis: true,
          onCell: () => {
            return {
              onClick: (event: any) => {
                event.stopPropagation();
              },
            };
          },
          render: (value: any, record: EntityCofarcoTeam) => (
            <div
              style={{
                minWidth: '100px',
                maxWidth: '100px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              <a className={classes.email} href={`tel:${value}`}>
                <span>{record.people.mobile_number ?? ''}</span>
              </a>
            </div>
          ),
        },
        {
          title: 'Team',
          dataIndex: 'team',
          ellipsis: true,
          key: 'team',
          className: classes.fixedSizeHeader,
          ...getColumnSearchProps('team'),
          defaultSortOrder: 'ascend',
          render: (value: any, record: EntityCofarcoTeam) => (
            <div
              style={{
                minWidth: '150px',
                maxWidth: '150px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              <span>{record?.team}</span>
            </div>
          ),
        },
        {
          title: 'DEPARTMENT',
          dataIndex: 'department',
          key: 'department',
          ...getColumnSearchProps('department'),
          defaultSortOrder: 'ascend',
          className: classes.fixedSizeHeader,
          render: (value: any, record: EntityCofarcoTeam) => (
            <div
              style={{
                minWidth: '150px',
                maxWidth: '150px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              <span>{record?.people.department}</span>
            </div>
          ),
        },

        {
          title: '',
          key: 'action',
          className: classes.actionRow,
          onCell: () => {
            return {
              onClick: (event: any) => {
                event.stopPropagation();
              },
            };
          },
          width: 120,
          hidden: !isCan('write', Menu.user) && !isCan('delete', Menu.user),
          render: (_: any, record: any) => (
            <div className="d-flex">
              {isCan('write', Menu.user) && (
                <span
                  className={`mx-1 rounded-icon  ${classes.action}`}
                  onClick={() => editUser(record)}
                >
                  <Edit height="30" />
                </span>
              )}
              {isCan('delete', Menu.user) && (
                <span
                  className={`mx-1 rounded-icon ${classes.action}`}
                  onClick={() => handleDelete(record)}
                >
                  <Delete height="30" />
                </span>
              )}
            </div>
          ),
        },
      ].filter((el) => !el.hidden) as ColumnsType<People>,
    [uncheckedId, dropDownFilterState],
  );

  const editUser = async (cof: EntityCofarcoTeam) => {
    if (cof) {
      const tempRigth = cof.rights || {};
      /*if (tempRigth?.history) {
        delete tempRigth.history;
      }*/
      const tempDataSourceRigth = [];
      for (const property in tempRigth) {
        tempDataSourceRigth.push({
          ...tempRigth[property],
          object: property,
        });
      }

      dispatch(
        updateModalAction({
          data: {
            ...cof,
            entry_date: exportDateUi(cof?.entry_date) as string,
            departure_date: exportDateUi(cof?.departure_date) as string,
            username: concatString(
              cof?.people?.firstname || '',
              cof?.people?.lastname || '',
              ' ',
            ),
            history: cof?.rights?.['history']?.read,
            data_source_rigth: tempDataSourceRigth,
          },
          isAdd: true,
          type: 'cofarco_team',
        }),
      );
    }
  };

  const deleteUserCallback = useCallback(() => {
    if (selectedUser) {
      deleteUser({ id: selectedUser.id }).then(() => {
        setShowmodalDelete(false);
      });
    }
  }, [selectedUser, setShowmodalDelete]);

  const handleDelete = useCallback(
    (record: EntityCofarcoTeam) => {
      setShowmodalDelete(true);
      setSelectedUser(record.people);
    },
    [setShowmodalDelete, setSelectedUser],
  );

  return {
    columns,
    editUser,
    deleteUserCallback,
    setShowmodalDelete,
    showmodalDelete,
    selectedUser,
    setSelectedUser,
    handleDelete,
    isLoadingDelete: isLoading,
    uncheckedId,
    groupName: selectedUser?.firstname + ' ' + selectedUser?.lastname,
  };
};

export default useTeamResult;

type DataIndex = keyof People;
