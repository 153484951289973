import { ColumnType, ColumnsType } from 'antd/es/table';
import { ConterParty } from '../../@types/conterParty';
import { useCallback, useMemo, useState } from 'react';
import { updateModalAction } from '../../redux/slice/modalSlice';
import { useAppDispatch } from '../redux';
import {
  amountFormat,
  exportedDropdownValue,
  handleRegion,
  isCan,
} from '../../utils/helper-function';
import classes from '../../components/Dashboard/Dashboard.module.scss';
import { ReactComponent as Edit } from '../../assets/img/Edit.svg';
import { ReactComponent as Delete } from '../../assets/img/Delete.svg';
import { useDeleteParticipationDealLimitMutation } from '../../redux/api/ws/participations/participations';
import UseConstante from '../use-constante';
import { Menu, TermConst } from '../../@types/common-types';
import { useGetCountryQuery } from '../../redux/api/ws/databases/groups';
import { EntityCountry } from '../../@types/group/types';
import FlagItem from '../../components/FlagItem/FlagItem';
import { EntityLimits } from '../../@types/limit/types';
import { ReactComponent as ArrowDown } from '../../assets/img/Arrow-Down-Gray.svg';
import { ReactComponent as ArrowUP } from '../../assets/img/Arrow-UP-Gray.svg';
import FilterTab from '../../utils/filter-tab';
import { capitalize } from 'lodash';

const useLimitResult = () => {
  const dispatch = useAppDispatch();

  const [deleteLimit, { isLoading }] =
    useDeleteParticipationDealLimitMutation();

  const [showmodalDelete, setShowmodalDelete] = useState<boolean>(false);

  const [selectedLimit, setSelectedLimit] = useState<EntityLimits | null>(null);

  const { data: responseCountry } = useGetCountryQuery();
  const countries: Array<EntityCountry> = responseCountry?.country || [];

  const terms = UseConstante();

  const [dropDownFilterState, setDropDownFilterState] =
    useState<boolean>(false);
  const [uncheckedId, setUncheckedId] = useState<Array<number>>([]);

  const onChangeFilterColumn = (key: number | undefined) => {
    const found = uncheckedId?.find((uncheckedTitle) => uncheckedTitle === key);
    if (found) {
      setUncheckedId(uncheckedId.filter((t) => t !== key));
    } else {
      setUncheckedId([...uncheckedId, key || 0]);
    }
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex,
  ): ColumnType<EntityLimits> => ({
    filterDropdown: ({ close }) =>
      FilterTab<EntityLimits>({
        columns: columns,
        dataIndex: dataIndex,
        handleSorted: () => {},
        onChangeFilterColumn: (key: number | undefined) =>
          onChangeFilterColumn(key),
        uncheckedId: uncheckedId,
        close: () => close(),
      }),
    filterIcon: () => {
      return dropDownFilterState ? (
        <ArrowDown width={12} height={12} />
      ) : (
        <ArrowUP width={12} height={12} />
      );
    },
    onFilterDropdownOpenChange: (visible) => {
      setDropDownFilterState(visible);
    },
    render: (text: string) => <span>{text}</span>,
  });

  const columns: ColumnsType<EntityLimits> = useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'number',
        ...getColumnSearchProps('number'),
        key: 1,
      },
      {
        title: 'DEAL ID',
        dataIndex: 'number',
        ...getColumnSearchProps('number'),
        render: (text: any, deal: EntityLimits) => {
          return deal?.deal?.number;
        },
        key: 2,
      },
      {
        title: 'CLIENT',
        dataIndex: 'client_name',
        ...getColumnSearchProps('client_name'),
        key: 3,
        render: (text: string) => {
          return (
            <div
              style={{
                minWidth: '150px',
                maxWidth: '150px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              <span title={text}> {text}</span>
            </div>
          );
        },
      },
      {
        title: 'DEAL TYPE',
        dataIndex: 'number',
        ...getColumnSearchProps('number'),
        render: (text: any, deal: EntityLimits) => {
          return `Contrat ${capitalize(deal?.deal?.deal_type)}`;
        },
        key: 4,
      },
      {
        title: 'OBLIGOR',
        dataIndex: 'obligor_name',
        ...getColumnSearchProps('obligor_name'),
        key: 5,
        render: (obligor_name: string) => {
          return (
            <div style={{ maxWidth: '200px' }} className={classes.ellipsis}>
              <span>{capitalize(obligor_name)}</span>
            </div>
          );
        },
      },
      {
        title: 'OBLIGOR COUNTRY',
        dataIndex: 'obligor_country_id',
        key: 6,
        ...getColumnSearchProps('obligor_country_id'),
        render: (obligor_country_id: string) => {
          const region = handleRegion(countries, obligor_country_id);
          return (
            region && (
              <div
                style={{
                  maxWidth: '180px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  <FlagItem code={region.code} region_name={region?.name} />
                </span>
              </div>
            )
          );
        },
      },
      {
        title: 'FINANCIAL/UNDERWRITER',
        dataIndex: 'lead_line_name',
        ...getColumnSearchProps('lead_line_name'),
        key: 7,
      },
      {
        title: 'STATUS',
        dataIndex: 'status',
        ...getColumnSearchProps('status'),
        render: (status: string) => {
          if (status) {
            return (
              <span className={`gray-rounded`}>
                <b>
                  {capitalize(
                    exportedDropdownValue(
                      terms?.getConst(TermConst.limitStatus) || [],
                      status,
                    ),
                  ).replaceAll('_', ' ')}
                </b>
              </span>
            );
          }
        },
        key: 8,
      },
      {
        title: 'AMOUNT',
        dataIndex: 'amount',
        key: 9,
        ...getColumnSearchProps('amount'),
        render: (amount: number) => {
          return amountFormat(amount);
        },
      },
      {
        title: 'CURRENCY',
        dataIndex: 'currency',
        key: 10,
        ...getColumnSearchProps('currency'),
        render: (currency: string) => currency.toUpperCase(),
      },
      {
        title: '',
        dataIndex: 'action',
        className: classes.actionRow,
        onCell: () => {
          return {
            onClick: (event: any) => {
              event.stopPropagation();
            },
          };
        },
        render: (_: any, record: any) => (
          <div className="d-flex">
            {isCan('write', Menu.participation) && (
              <span
                className={`mx-1 rounded-icon  ${classes.action}`}
                onClick={() => editLimit(record)}
              >
                <Edit height="30" />
              </span>
            )}
            {isCan('delete', Menu.participation) && (
              <span
                className={`mx-1 rounded-icon ${classes.action}`}
                onClick={() => handleDelete(record)}
              >
                <Delete height="30" />
              </span>
            )}
          </div>
        ),
      },
    ],
    [uncheckedId, dropDownFilterState, countries, terms],
  );

  const editLimit = (record: ConterParty) => {
    console.log('record', record);

    dispatch(
      updateModalAction({
        data: {
          ...record,
          event: 'edit',
        },
        isAdd: true,
        type: 'limits',
      }),
    );
  };

  const deleteLimitCallback = useCallback(() => {
    if (selectedLimit) {
      deleteLimit({ id: selectedLimit.id }).then(() => {
        setShowmodalDelete(false);
      });
    }
  }, [selectedLimit, setShowmodalDelete]);

  const handleDelete = useCallback(
    (record: EntityLimits) => {
      setSelectedLimit(record);
      setShowmodalDelete(true);
    },
    [setSelectedLimit, setShowmodalDelete],
  );

  return {
    columns,
    editLimit,
    deleteLimitCallback,
    setShowmodalDelete,
    showmodalDelete,
    selectedLimit,
    setSelectedLimit,
    handleDelete,
    isLoadingDelete: isLoading,
    uncheckedId,
  };
};

export default useLimitResult;
type DataIndex =
  | keyof EntityLimits
  | 'deal'
  | 'obligor_country_id'
  | 'lead_line_name'
  | 'status'
  | 'obligor_name';
