export const mockCity = [
  {
    key: '13196',
    value: '13196',
  },
  {
    key: '59279 Loon-Plage',
    value: '59279 Loon-Plage',
  },
  {
    key: '628048',
    value: '628048',
  },
  {
    key: 'Aachen',
    value: 'Aachen',
  },
  {
    key: 'Aarhus C',
    value: 'Aarhus C',
  },
  {
    key: 'Aberdeen',
    value: 'Aberdeen',
  },
  {
    key: 'Abidjan',
    value: 'Abidjan',
  },
  {
    key: 'Abu Dhabi',
    value: 'Abu Dhabi',
  },
  {
    key: 'Abuja',
    value: 'Abuja',
  },
  {
    key: 'Accra',
    value: 'Accra',
  },
  {
    key: 'Amsterdam',
    value: 'Amsterdam',
  },
  {
    key: 'Antwerp',
    value: 'Antwerp',
  },
  {
    key: 'Athens',
    value: 'Athens',
  },
  {
    key: 'Baar',
    value: 'Baar',
  },
  {
    key: 'Bagnolet',
    value: 'Bagnolet',
  },
  {
    key: 'Bahia',
    value: 'Bahia',
  },
  {
    key: 'Bamako',
    value: 'Bamako',
  },
  {
    key: 'Barcelona',
    value: 'Barcelona',
  },
  {
    key: 'Basel',
    value: 'Basel',
  },
  {
    key: 'Bayamon',
    value: 'Bayamon',
  },
  {
    key: 'Beijing',
    value: 'Beijing',
  },
  {
    key: 'Beirut',
    value: 'Beirut',
  },
  {
    key: 'Bellinzona',
    value: 'Bellinzona',
  },
  {
    key: 'Belo Horizonte',
    value: 'Belo Horizonte',
  },
  {
    key: 'Berkshire',
    value: 'Berkshire',
  },
  {
    key: 'Bertrange',
    value: 'Bertrange',
  },
  {
    key: 'Birkirkara',
    value: 'Birkirkara',
  },
  {
    key: 'Bishopsgate',
    value: 'Bishopsgate',
  },
  {
    key: 'Boadilla Del Monte',
    value: 'Boadilla Del Monte',
  },
  {
    key: 'Bogota',
    value: 'Bogota',
  },
  {
    key: 'Bois Colombes',
    value: 'Bois Colombes',
  },
  {
    key: 'Bordeaux',
    value: 'Bordeaux',
  },
  {
    key: 'Bordeaux cédex',
    value: 'Bordeaux cédex',
  },
  {
    key: 'Boston',
    value: 'Boston',
  },
  {
    key: 'Bouguenais',
    value: 'Bouguenais',
  },
  {
    key: 'Bridgend',
    value: 'Bridgend',
  },
  {
    key: 'Bridgetown',
    value: 'Bridgetown',
  },
  {
    key: 'Brigend',
    value: 'Brigend',
  },
  {
    key: 'Bristol',
    value: 'Bristol',
  },
  {
    key: 'Bruges',
    value: 'Bruges',
  },
  {
    key: 'Brussels',
    value: 'Brussels',
  },
  {
    key: 'Bryanston',
    value: 'Bryanston',
  },
  {
    key: 'Cairo',
    value: 'Cairo',
  },
  {
    key: 'Calgary',
    value: 'Calgary',
  },
  {
    key: 'Capellen',
    value: 'Capellen',
  },
  {
    key: 'Cergy Pointoise',
    value: 'Cergy Pointoise',
  },
  {
    key: 'Charenton-le-Pont',
    value: 'Charenton-le-Pont',
  },
  {
    key: 'Chatellerault',
    value: 'Chatellerault',
  },
  {
    key: 'Chicago',
    value: 'Chicago',
  },
  {
    key: 'Chingola',
    value: 'Chingola',
  },
  {
    key: 'Cologne',
    value: 'Cologne',
  },
  {
    key: 'Colombus',
    value: 'Colombus',
  },
  {
    key: 'Cotonou',
    value: 'Cotonou',
  },
  {
    key: 'Courbevoie',
    value: 'Courbevoie',
  },
  {
    key: 'Curacao',
    value: 'Curacao',
  },
  {
    key: 'Dakar-Fann',
    value: 'Dakar-Fann',
  },
  {
    key: 'Dallas',
    value: 'Dallas',
  },
  {
    key: 'Dar Es Salaam',
    value: 'Dar Es Salaam',
  },
  {
    key: 'Darien',
    value: 'Darien',
  },
  {
    key: 'Doha',
    value: 'Doha',
  },
  {
    key: 'Dordrecht',
    value: 'Dordrecht',
  },
  {
    key: 'Douala',
    value: 'Douala',
  },
  {
    key: 'Douglas',
    value: 'Douglas',
  },
  {
    key: 'Dubai',
    value: 'Dubai',
  },
  {
    key: 'Dublin',
    value: 'Dublin',
  },
  {
    key: 'Duisburg',
    value: 'Duisburg',
  },
  {
    key: 'Dunkeld',
    value: 'Dunkeld',
  },
  {
    key: 'Durham',
    value: 'Durham',
  },
  {
    key: 'Dusseldorf',
    value: 'Dusseldorf',
  },
  {
    key: 'East Croydon',
    value: 'East Croydon',
  },
  {
    key: 'Edam',
    value: 'Edam',
  },
  {
    key: 'Edinburgh',
    value: 'Edinburgh',
  },
  {
    key: 'Eschborn',
    value: 'Eschborn',
  },
  {
    key: 'essen',
    value: 'essen',
  },
  {
    key: 'EVERGEM',
    value: 'EVERGEM',
  },
  {
    key: 'Forbach',
    value: 'Forbach',
  },
  {
    key: 'Fort Lee',
    value: 'Fort Lee',
  },
  {
    key: 'Frankfurt',
    value: 'Frankfurt',
  },
  {
    key: 'Frankfurt am Main',
    value: 'Frankfurt am Main',
  },
  {
    key: 'Fujairah',
    value: 'Fujairah',
  },
  {
    key: 'Gandhi Nagar',
    value: 'Gandhi Nagar',
  },
  {
    key: 'Gauteng',
    value: 'Gauteng',
  },
  {
    key: 'Geneva',
    value: 'Geneva',
  },
  {
    key: 'Genève',
    value: 'Genève',
  },
  {
    key: 'Gent',
    value: 'Gent',
  },
  {
    key: 'Glattbrugg',
    value: 'Glattbrugg',
  },
  {
    key: 'Grand Cayman',
    value: 'Grand Cayman',
  },
  {
    key: 'Guadalajara',
    value: 'Guadalajara',
  },
  {
    key: 'Guayaquil',
    value: 'Guayaquil',
  },
  {
    key: 'Guernsey',
    value: 'Guernsey',
  },
  {
    key: 'Haarlem',
    value: 'Haarlem',
  },
  {
    key: 'Hamburg',
    value: 'Hamburg',
  },
  {
    key: 'Hamilton',
    value: 'Hamilton',
  },
  {
    key: 'Hampshire',
    value: 'Hampshire',
  },
  {
    key: 'High Wickham',
    value: 'High Wickham',
  },
  {
    key: 'Highland Heights',
    value: 'Highland Heights',
  },
  {
    key: 'Hong Kong',
    value: 'Hong Kong',
  },
  {
    key: 'Houston',
    value: 'Houston',
  },
  {
    key: 'Houton, Texas',
    value: 'Houton, Texas',
  },
  {
    key: 'Huelva',
    value: 'Huelva',
  },
  {
    key: 'Hünenberg See',
    value: 'Hünenberg See',
  },
  {
    key: 'Hünnenberg',
    value: 'Hünnenberg',
  },
  {
    key: 'Hunt Valley',
    value: 'Hunt Valley',
  },
  {
    key: 'Ikoyi',
    value: 'Ikoyi',
  },
  {
    key: 'Industrial City',
    value: 'Industrial City',
  },
  {
    key: 'Islamabad',
    value: 'Islamabad',
  },
  {
    key: 'Istanbul',
    value: 'Istanbul',
  },
  {
    key: 'Jakarta',
    value: 'Jakarta',
  },
  {
    key: 'Jebel Ali, Dubai',
    value: 'Jebel Ali, Dubai',
  },
  {
    key: 'Jeddah',
    value: 'Jeddah',
  },
  {
    key: 'Johannesburg',
    value: 'Johannesburg',
  },
  {
    key: 'Kiev',
    value: 'Kiev',
  },
  {
    key: 'Kill, county Kildare',
    value: 'Kill, county Kildare',
  },
  {
    key: 'Kitwe',
    value: 'Kitwe',
  },
  {
    key: 'Köln',
    value: 'Köln',
  },
  {
    key: 'Körfez',
    value: 'Körfez',
  },
  {
    key: 'Kowloon',
    value: 'Kowloon',
  },
  {
    key: 'La Creche',
    value: 'La Creche',
  },
  {
    key: 'La Defense',
    value: 'La Defense',
  },
  {
    key: 'La Plaine St Denis',
    value: 'La Plaine St Denis',
  },
  {
    key: 'La Tour-de-Trême',
    value: 'La Tour-de-Trême',
  },
  {
    key: 'Lagis',
    value: 'Lagis',
  },
  {
    key: 'Lagos',
    value: 'Lagos',
  },
  {
    key: 'Las Palmas de Gran Canaria',
    value: 'Las Palmas de Gran Canaria',
  },
  {
    key: 'Lausanne',
    value: 'Lausanne',
  },
  {
    key: 'Lesaka',
    value: 'Lesaka',
  },
  {
    key: 'Leudelange (Leideleng)',
    value: 'Leudelange (Leideleng)',
  },
  {
    key: 'Levallois-Perret',
    value: 'Levallois-Perret',
  },
  {
    key: 'Libreville',
    value: 'Libreville',
  },
  {
    key: 'Liege',
    value: 'Liege',
  },
  {
    key: 'Lille',
    value: 'Lille',
  },
  {
    key: 'Limassol',
    value: 'Limassol',
  },
  {
    key: 'Lisbon',
    value: 'Lisbon',
  },
  {
    key: 'Lomé',
    value: 'Lomé',
  },
  {
    key: 'London',
    value: 'London',
  },
  {
    key: 'Luanda',
    value: 'Luanda',
  },
  {
    key: 'Lubumbashi',
    value: 'Lubumbashi',
  },
  {
    key: 'Lucens',
    value: 'Lucens',
  },
  {
    key: 'Lugano',
    value: 'Lugano',
  },
  {
    key: 'Lumezzane S.A. (BS)',
    value: 'Lumezzane S.A. (BS)',
  },
  {
    key: 'Lusaka',
    value: 'Lusaka',
  },
  {
    key: 'Luxembourg',
    value: 'Luxembourg',
  },
  {
    key: 'Luzern',
    value: 'Luzern',
  },
  {
    key: 'Lyon',
    value: 'Lyon',
  },
  {
    key: 'Madrid',
    value: 'Madrid',
  },
  {
    key: 'Maidenhead',
    value: 'Maidenhead',
  },
  {
    key: 'Mainz',
    value: 'Mainz',
  },
  {
    key: 'Majuro',
    value: 'Majuro',
  },
  {
    key: 'Manama',
    value: 'Manama',
  },
  {
    key: 'Mantova',
    value: 'Mantova',
  },
  {
    key: 'Marbach',
    value: 'Marbach',
  },
  {
    key: 'Maroussi-Athens',
    value: 'Maroussi-Athens',
  },
  {
    key: 'Marseille',
    value: 'Marseille',
  },
  {
    key: 'Mauritius',
    value: 'Mauritius',
  },
  {
    key: 'Melboune',
    value: 'Melboune',
  },
  {
    key: 'Meudon',
    value: 'Meudon',
  },
  {
    key: 'Miami',
    value: 'Miami',
  },
  {
    key: 'Middelfart',
    value: 'Middelfart',
  },
  {
    key: 'Midland',
    value: 'Midland',
  },
  {
    key: 'Milan',
    value: 'Milan',
  },
  {
    key: 'Milano',
    value: 'Milano',
  },
  {
    key: 'Moerdijk',
    value: 'Moerdijk',
  },
  {
    key: 'Montevideo',
    value: 'Montevideo',
  },
  {
    key: 'Montréal',
    value: 'Montréal',
  },
  {
    key: 'Montreux',
    value: 'Montreux',
  },
  {
    key: 'Montrouge',
    value: 'Montrouge',
  },
  {
    key: 'Morges',
    value: 'Morges',
  },
  {
    key: 'Moscow',
    value: 'Moscow',
  },
  {
    key: 'Mumbai',
    value: 'Mumbai',
  },
  {
    key: 'München',
    value: 'München',
  },
  {
    key: 'Munich',
    value: 'Munich',
  },
  {
    key: 'Muscat',
    value: 'Muscat',
  },
  {
    key: 'Muscle Shoals',
    value: 'Muscle Shoals',
  },
  {
    key: 'Nairobi',
    value: 'Nairobi',
  },
  {
    key: 'Nairobi, Kenya',
    value: 'Nairobi, Kenya',
  },
  {
    key: 'nanterre',
    value: 'nanterre',
  },
  {
    key: 'Neuilly sur Sein',
    value: 'Neuilly sur Sein',
  },
  {
    key: 'New Delhi',
    value: 'New Delhi',
  },
  {
    key: 'New Jersey',
    value: 'New Jersey',
  },
  {
    key: 'New York',
    value: 'New York',
  },
  {
    key: 'New`York',
    value: 'New`York',
  },
  {
    key: 'Nice',
    value: 'Nice',
  },
  {
    key: 'Nimes',
    value: 'Nimes',
  },
  {
    key: 'Noisy-le-Grand',
    value: 'Noisy-le-Grand',
  },
  {
    key: 'Nyon',
    value: 'Nyon',
  },
  {
    key: 'Oslo',
    value: 'Oslo',
  },
  {
    key: 'Ottawa',
    value: 'Ottawa',
  },
  {
    key: 'Oudenaarde',
    value: 'Oudenaarde',
  },
  {
    key: 'Oxford',
    value: 'Oxford',
  },
  {
    key: 'OXON',
    value: 'OXON',
  },
  {
    key: 'Panama City',
    value: 'Panama City',
  },
  {
    key: 'Parigny',
    value: 'Parigny',
  },
  {
    key: 'Paris',
    value: 'Paris',
  },
  {
    key: 'Paris La Defense',
    value: 'Paris La Defense',
  },
  {
    key: 'Parsippany',
    value: 'Parsippany',
  },
  {
    key: 'Pau',
    value: 'Pau',
  },
  {
    key: 'Perth',
    value: 'Perth',
  },
  {
    key: 'Perth, Western Australia,',
    value: 'Perth, Western Australia,',
  },
  {
    key: 'Petit Lancy',
    value: 'Petit Lancy',
  },
  {
    key: 'Philadelphia',
    value: 'Philadelphia',
  },
  {
    key: 'Pointe-a-Pierre',
    value: 'Pointe-a-Pierre',
  },
  {
    key: 'Port Louis',
    value: 'Port Louis',
  },
  {
    key: 'Portsmouth',
    value: 'Portsmouth',
  },
  {
    key: 'Princeton',
    value: 'Princeton',
  },
  {
    key: 'Pully',
    value: 'Pully',
  },
  {
    key: 'Puteaux',
    value: 'Puteaux',
  },
  {
    key: 'Rangoun',
    value: 'Rangoun',
  },
  {
    key: 'REIMS',
    value: 'REIMS',
  },
  {
    key: 'Remich',
    value: 'Remich',
  },
  {
    key: 'Rio de Janeiro',
    value: 'Rio de Janeiro',
  },
  {
    key: 'Rolle',
    value: 'Rolle',
  },
  {
    key: 'Rotterdam',
    value: 'Rotterdam',
  },
  {
    key: 'Rueil Malmaison',
    value: 'Rueil Malmaison',
  },
  {
    key: 'Rümlang',
    value: 'Rümlang',
  },
  {
    key: 'Safat',
    value: 'Safat',
  },
  {
    key: 'Saint Louis',
    value: 'Saint Louis',
  },
  {
    key: 'San Antonio',
    value: 'San Antonio',
  },
  {
    key: 'Sandton',
    value: 'Sandton',
  },
  {
    key: 'Santa Fee',
    value: 'Santa Fee',
  },
  {
    key: 'Santa Marta',
    value: 'Santa Marta',
  },
  {
    key: 'São Paulo',
    value: 'São Paulo',
  },
  {
    key: 'Schaan',
    value: 'Schaan',
  },
  {
    key: 'Schaffhausen',
    value: 'Schaffhausen',
  },
  {
    key: 'Schiphol',
    value: 'Schiphol',
  },
  {
    key: 'Shanghai',
    value: 'Shanghai',
  },
  {
    key: 'Shangyu',
    value: 'Shangyu',
  },
  {
    key: 'Singapore',
    value: 'Singapore',
  },
  {
    key: 'Singapour',
    value: 'Singapour',
  },
  {
    key: 'Slatina',
    value: 'Slatina',
  },
  {
    key: "St Julian's",
    value: "St Julian's",
  },
  {
    key: 'Stamford',
    value: 'Stamford',
  },
  {
    key: 'Steinhausen',
    value: 'Steinhausen',
  },
  {
    key: 'Strassen',
    value: 'Strassen',
  },
  {
    key: 'Subiaco',
    value: 'Subiaco',
  },
  {
    key: 'Surrey',
    value: 'Surrey',
  },
  {
    key: 'Teheran',
    value: 'Teheran',
  },
  {
    key: 'Tel Aviv',
    value: 'Tel Aviv',
  },
  {
    key: 'The Hague',
    value: 'The Hague',
  },
  {
    key: 'Thessaloniki',
    value: 'Thessaloniki',
  },
  {
    key: 'Thônex',
    value: 'Thônex',
  },
  {
    key: 'Tokyo',
    value: 'Tokyo',
  },
  {
    key: 'Toronto',
    value: 'Toronto',
  },
  {
    key: 'Tortola',
    value: 'Tortola',
  },
  {
    key: 'Trieste',
    value: 'Trieste',
  },
  {
    key: 'Tunis',
    value: 'Tunis',
  },
  {
    key: 'Tyumen Region',
    value: 'Tyumen Region',
  },
  {
    key: 'Utrecht',
    value: 'Utrecht',
  },
  {
    key: 'Valencia',
    value: 'Valencia',
  },
  {
    key: 'Vancouver',
    value: 'Vancouver',
  },
  {
    key: 'Vienna',
    value: 'Vienna',
  },
  {
    key: 'Wallisellen',
    value: 'Wallisellen',
  },
  {
    key: 'Wanchai',
    value: 'Wanchai',
  },
  {
    key: 'Washington',
    value: 'Washington',
  },
  {
    key: 'West Washington',
    value: 'West Washington',
  },
  {
    key: 'White Plains',
    value: 'White Plains',
  },
  {
    key: 'Wilmington',
    value: 'Wilmington',
  },
  {
    key: 'Winterthur',
    value: 'Winterthur',
  },
  {
    key: 'Woking',
    value: 'Woking',
  },
  {
    key: 'Wollerau',
    value: 'Wollerau',
  },
  {
    key: 'Zagreb',
    value: 'Zagreb',
  },
  {
    key: 'Zhlobin',
    value: 'Zhlobin',
  },
  {
    key: 'Zug',
    value: 'Zug',
  },
  {
    key: 'Zürich',
    value: 'Zürich',
  },
];
