import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { baseApi } from '../../baseApi';
import {
  IndicationAttachment,
  IndicationResponse,
} from '../../../../@types/Indication/indication';
import {
  ApiFilter,
  ApiPagination,
  ApiResponse,
  Modify,
} from '../../../../@types';
import { url } from '../../url';
import {
  EnquiriesResponse,
  SingleEnquiriesResponse,
} from '../../../../@types/enquiries';
import { PeopleResponse } from '../../../../@types/people';
import {
  ConterParty,
  ConterPartyResponse,
} from '../../../../@types/conterParty';
import { Method } from '../../../../@types/common-types';

const indicationApi = baseApi.injectEndpoints({
  endpoints: (build: EndpointBuilder<any, any, any>) => {
    return {
      listIndication: build.query<
        IndicationResponse,
        ApiPagination & ApiFilter
      >({
        query(arg) {
          return {
            url: url.indication.list,
            params: {
              ...arg,
              getEnquiryDetails: true,
              getObligorName: true,
              getUnderwriterName: true,
              _dc: new Date().getTime(),
            },
          };
        },
        providesTags: (result) =>
          result
            ? result.indication.map((e) => ({
                type: 'Indication',
                id: e.id,
              }))
            : ['Indication'],
      }),
      listEnquiry: build.query<
        EnquiriesResponse,
        ApiFilter & { query: string; date?: Date }
      >({
        query: ({ ...params }) => {
          return {
            url: url.enquiries.list,
            params: {
              ...params,
              withClientAndMandate: true,
              getMandate: true,
              getClient: true,
              getObligor: true,
              dc: new Date().getTime(),
            },
          };
        },
      }),
      getEnquiryById: build.query<SingleEnquiriesResponse, { id: any }>({
        query: (params) => {
          return {
            url: `${url.enquiries.list}/${params.id}`,
            params: {
              getMandate: true,
              getClient: true,
              getObligor: true,
              withClientAndName: true,
              dc: new Date().getTime(),
            },
          };
        },
      }),
      searchContact: build.query<
        PeopleResponse,
        { query: string; counterpartyId: number }
      >({
        query: ({ query, counterpartyId }) => {
          return {
            url: url.people.list,
            params: {
              start: 0,
              page: 1,
              limit: 100,
              allEntities: true,
              query,
              counterpartyId,
            },
          };
        },
        providesTags: ['LIST_PEOPLE_BY_SEARCH'],
      }),
      getCounterParty: build.query<
        Modify<ConterPartyResponse, { counterparty: ConterParty }>,
        { id: string | number }
      >({
        query: ({ id }) => {
          return {
            url: `${url.counterparty.list}/${id}`,
            params: { getLegalEntity: 1, _dc: new Date().getTime() },
            method: Method.GET,
          };
        },
      }),
      getIndicationAttachment: build.query<
        Modify<ApiResponse, { attachment: IndicationAttachment[] }>,
        ApiPagination & ApiFilter & { id: string | number }
      >({
        query: ({ id, ...params }) => {
          return {
            url: `attachment/Indication/${id}`,
            params: { ...params, _dc: new Date().getTime() },
            method: Method.GET,
          };
        },
        providesTags: (result) =>
          result
            ? result.attachment.map((e) => ({
                type: 'IndicationAttachment',
                id: e.id,
              }))
            : ['IndicationAttachment'],
      }),
    };
  },
});

export const {
  useListIndicationQuery,
  useLazyListIndicationQuery,
  useListEnquiryQuery,
  useLazyGetEnquiryByIdQuery,
  useSearchContactQuery,
  useGetCounterPartyQuery,
  useLazyGetCounterPartyQuery,
  useGetIndicationAttachmentQuery,
} = indicationApi;
