export const shouldValueAfterSelect = (
  rate: string,
  amount: string,
  maxAllocation?: number,
) => {
  const net_rate = Number(rate || '0');
  const note_amount = Number(amount || '0');
  let val = 0;
  if (net_rate < note_amount || !note_amount) {
    val = net_rate;
  } else val = note_amount;

  if (maxAllocation !== undefined) {
    if (maxAllocation < 0) maxAllocation = 0;
    return `${Math.min(maxAllocation, val)}`;
  }
  return `${val}`;
};

export const getShouldValue = (
  net_rate: string,
  numberValue: string,
  maxAllocation?: number,
) => {
  // const max = getDefAllocation();
  const max = Number.parseFloat(net_rate || '0');
  const vToNumber = Number(numberValue);

  if (maxAllocation !== undefined) {
    if (maxAllocation < 0) maxAllocation = 0;
    return Math.min(max, vToNumber, maxAllocation);
  }
  return Math.min(max, vToNumber);
};
