import { Button, Modal, Container } from 'react-bootstrap';
import { ReactComponent as Loader } from '../../assets/img/Loading.svg';
import classes from './Modal.module.scss';

type Props = {
  show: boolean;
  title: string;
  handleClose: () => void;
  addClick: () => void;
  children: React.ReactNode;
  isDisabled: boolean;
  isLoading: boolean;
};

const PromptModal = (props: Props) => {
  return (
    <Modal
      show={props.show}
      onHide={() => props.handleClose()}
      className={classes.modalInfo}
      contentClassName={classes.modalContent}
      dialogClassName={classes.modalDialog}
      size='lg'
      centered
    >
      <form className='modal-confirm'>
        <Modal.Header
          className={classes.modalHeader}
          closeVariant='white'
          closeButton
        >
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className='centered'>{props.children}</Container>
        </Modal.Body>
        <Modal.Footer className={classes.modalFooter}>
          <Button
            className={`btn primary active-link mx-2 ${
              props.isDisabled || props.isLoading ? 'disabled' : ''
            }
            `}
            onClick={() => props.addClick()}
          >
            {props.isLoading ? <Loader /> : 'Add'}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default PromptModal;
