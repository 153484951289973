import React, {useCallback, useMemo, useState} from 'react';
import {
    Button,
    Col,
    Container,
    Form,
    FormControl,
    Row,
} from 'react-bootstrap';
import SelectDropDown from '../../../../common/DropDown/SelectDropDown';
import CofarcoFormText from '../../../../Form/CofarcoFormText';
import {ReactComponent as AddIban} from '../../../../../assets/img/AddIban.svg';
import {ReactComponent as AddIbanInactif} from '../../../../../assets/img/AddIbanInactif.svg';

import AddGroup from '../../../../../assets/img/add.svg';
import {useFilteredNotesMutation} from '../../../../../redux/api/ws/segregatedaccounts/payement';
import {
    amountFormat,
    exportedDropdownValue,
    floorNumber,
    formatTitleText,
    getUniqueArray,
    handleRegion,
    isExist,
    makeOptions,
    onHeaderCell,
} from '../../../../../utils/helper-function';
import DropdownSelect, {
    DropDownOptionObjectType,
} from '../../../../common/DropDown/DropdownSelect';
import {TermConst} from '../../../../../@types/common-types';
import UseConstante from '../../../../../hooks/use-constante';
import {Table, Typography} from 'antd';
import {ReactComponent as Edit} from '../../../../../assets/img/Edit.svg';
import {ReactComponent as Delete} from '../../../../../assets/img/Delete.svg';
import {EntityNotes} from '../../../../../@types/segregatedaccounts/notes';
import {ReactComponent as LegalentitiesActif} from '../../../../../assets/img/LegalentitiesActif.svg';
import {ReactComponent as LegalentitiesInactif} from '../../../../../assets/img/LegalentitiesInactif.svg';
import {IoIosArrowDown, IoIosArrowUp} from 'react-icons/io';
import DeleteModal from '../../../../Modal/DeleteModal';
import {useAppDispatch} from '../../../../../hooks/redux';
import {updateNotificationMessage} from '../../../../../redux/slice/notificationSlice';
import {EntityCountry} from '../../../../../@types/group/types';
import {useGetCountryQuery} from '../../../../../redux/api/ws/databases/groups';
import CofarcoCalendar from '../../../../Calendar/CofarcoCalendar';
import RowContent from '../../../../Form/RowContent';
import classes from '../../../../../components/CofarcoTable/CofarcoTable.module.scss';
import CofarcoNumberAmount from '../../../../Form/CofarcoNumberAmount';
import classe from './forms.module.scss';
import {cloneDeep, uniqBy} from 'lodash';
import {FormModalPaymentProps, Item} from './Type';

export default function Forms(props: FormModalPaymentProps) {
    const amount = props.watch('amount');
    const direction = props.watch('direction');
    const owner_bank_account_id = props.watch('owner_bank_account_id');
    const currency = props.watch('currency');
    const entity_bank_account_id = props.watch('entity_bank_account_id');
    const legal_entity_id = props.watch('legal_entity_id');
    const terms = UseConstante();
    const showErrorCurrency = () => {
        props.setFormError('currency', {message: 'Currency is required'});
    };
    const dispatch = useAppDispatch();

    const [modalConfirmDelete, setModalConfirmDelete] = useState(false);
    const [editingKey, setEditingKey] = useState<Item | null>(null);
    const [lunchFilterNote, {isLoading: isGetNotesLoading}] =
        useFilteredNotesMutation();
    const [selectedNotesDataSource, setSelectedNotesDataSource] =
        useState<Item | null>(null);

    const [listNoteFilter, setListNoteFilter] = useState<
        Array<EntityNotes | any>
    >([]);

    const isShowTable = amount && owner_bank_account_id;

    const isEditing = (record: any) =>
        record?.key?.toString() === editingKey?.key?.toString() ? true : false;

    const refsNotesPayment = useMemo(() => {
        return props.notesDataSource?.map(
            (el) =>
                `${el.number?.value} - ${el.legal_entity} - ${el.note_amount} ${el.note_currency}`,
        );
    }, [props.notesDataSource]);

    const getNotes = async (text?: string) => {
        const direction = props.watch('direction');
        const noteTypes =
            direction === 'debit' ? 'ccnb,ccni,pcni,pcnu' : 'cdnb,pdni,cdnu,pdnu';
        try {
            const resp = await lunchFilterNote({
                query: text ? `%${text?.trim()}%` : undefined,
                params: {
                    noteTypes,
                },
            }).unwrap();

            const floorResult = resp.note?.map((el) => {
                const tempNote = el?.complete_ref?.split(' - ') || [];
                let tempNoteCurr = '';
                let tempNoteAmmount = '';
                if (tempNote && tempNote?.[2]) {
                    const tempS = tempNote?.[2]?.trim();
                    const splitSelected = tempS?.split(' ');
                    tempNoteAmmount = splitSelected?.[0] || '';
                    tempNoteCurr = splitSelected?.[1] || '';
                }
                return {
                    ...el,
                    legal_entity: el?.legal_entity?.name || '',
                    currency: el?.currency?.toUpperCase() || '',
                    deal_id: el?.deal?.number || el?.deal_id || '',
                    deal_number: el?.deal?.number || el?.deal_id || '',
                    net_rate: tempNoteAmmount,
                    note_currency: tempNoteCurr,
                    complete_ref: `${el?.number} - ${
                        el?.legal_entity?.name || ''
                    } - ${tempNoteAmmount} ${tempNoteCurr}`,
                    number: {
                        key: el?.id,
                        value: el?.number,
                    },
                };
            });
            const filteredNoteRefs = floorResult?.filter(
                (el) => !refsNotesPayment?.includes(`${el.complete_ref}`),
            );
            setListNoteFilter(filteredNoteRefs);
            return filteredNoteRefs;
        } catch (error) {
            return [];
        }
    };

    const cancel = () => {
        const currentEdit = props.notesDataSource?.find(
            (el) => el.key?.toString() === editingKey?.key?.toString(),
        );
        const isAlreadySave = currentEdit?.id !== 0;

        if (isAlreadySave) {
            const restoreV = props.notesDataSource?.map((el) => {
                if (el.id?.toString() === editingKey?.id?.toString()) {
                    return {
                        ...editingKey,
                        isCurrentEdit: false,
                    };
                } else {
                    return el;
                }
            });
            props.setNotesDataSource(restoreV);
        } else {
            const tempD = props.notesDataSource?.filter(
                (el) => el.isCurrentEdit !== true,
            );
            props.setNotesDataSource(tempD);
        }
        setEditingKey(null);
    };

    const edit = async (record: any) => {
        const defaultV = record.number?.value
            ? [
                {
                    ...record,
                    complete_ref: `${record?.number?.value} - ${record.legal_entity} - ${record.note_amount} -${record.note_currency}`,
                    id: record.number?.key,
                },
            ]
            : [];
        if (defaultV.length > 0) {
            setListNoteFilter(defaultV);
        } else {
            await getNotes(record?.number?.value ?? '');
        }

        setEditingKey({...record});
    };

    const getDefAllocation = useCallback(() => {
        let excludeIndex = -1;
        if (editingKey && typeof editingKey.key == 'number') {
            excludeIndex = editingKey.key - 1;
        }
        const summAll = (props?.notesDataSource || []).reduce(
            (acc, curr, index) => {
                if (index !== excludeIndex) {
                    return acc + parseFloat(curr.note_amount || '0');
                }
                return acc;
            },
            0,
        );
        return Math.max((floorNumber(amount) as number) - summAll, 0);
    }, [props?.notesDataSource, amount]);

    const addNotes = () => {
        const sizeOfDataSource = props?.notesDataSource?.length;
        const lastItemId =
            (props?.notesDataSource[sizeOfDataSource - 1]?.key as number) ?? 0;

        const emptyRow = {
            number: null,
            legal_entity: '',
            deal_id: '',
            note_amount: getDefAllocation()?.toFixed(2)?.toString(),
            note_currency: '',
            note_comments: '',
            net_rate: '0.00',
            currency: '',
            id: 0,
            key: lastItemId + 1,
            isCurrentEdit: true,
            deal_number: '',
        };
        props?.setNotesDataSource([...(props?.notesDataSource || {}), emptyRow]);
        edit(emptyRow);
    };

    const shouldValueAfterSelect = (rate: string, amount: string) => {
        const net_rate = Number(rate || '0');
        const note_amount = Number(amount || '0');
        let val = 0;
        if (net_rate < note_amount || !note_amount) {
            val = net_rate;
        } else val = note_amount;

        const maxAllocation = getDefAllocation() || Number(amount || '0');
        return `${Math.min(maxAllocation, val)}`;
    };

    const updateDataSource = (
        key: any,
        dataIndex: string,
        v: string | { key: any; value: any } | number | Item | undefined,
        isSave: boolean = false,
        isCancel: boolean = false,
    ) => {
        const index = props?.notesDataSource.findIndex((f) => f.key === key);

        if (index > -1) {
            // const newDataSource = [...props?.notesDataSource];
            const newDataSource = cloneDeep(props?.notesDataSource);
            const legEnt = uniqBy([...listNoteFilter, v], 'complete_ref')?.find(
                (el) => el.id === newDataSource[index]?.number?.key,
            );
            if (isSave) {
                const isDifferentDevise =
                    legEnt?.note_currency?.toLocaleLowerCase() !==
                    currency?.toLocaleLowerCase();

                if (isDifferentDevise) {
                    dispatch(
                        updateNotificationMessage({
                            title: 'Warning',
                            body: `Payment allocation can only be in the currency of the related Note regardless of the Payment currency`,
                            show: true,
                        }),
                    );
                }
                newDataSource[index].note_amount = shouldValueAfterSelect(
                    legEnt.net_rate,
                    newDataSource[index]?.note_amount,
                );
                newDataSource[index] = {
                    ...newDataSource[index],
                    number: legEnt?.number,
                    deal_id: legEnt?.deal_id,
                    deal_number: legEnt?.deal?.number || '',
                    legal_entity: legEnt?.legal_entity || '',
                    currency: legEnt?.currency?.toUpperCase() || '',
                    net_rate: legEnt?.net_rate,
                    note_currency: legEnt?.note_currency,
                    isCurrentEdit: false,
                    complete_ref: legEnt?.complete_ref,
                };
                setEditingKey(null);
            } else if (isCancel) {
                newDataSource[index] = {
                    ...newDataSource[index],
                };
            } else {
                const note = listNoteFilter.find((el) => el.id === (v as any)?.key);
                const rate = note?.net_rate || '';
                if (rate) {
                    newDataSource[index].note_amount = shouldValueAfterSelect(
                        rate,
                        newDataSource[index].note_amount,
                    );
                    newDataSource[index].net_rate = rate;
                }

                newDataSource[index] = {
                    ...newDataSource[index],
                    [dataIndex]: v,
                };
            }
            props.setValue('attachedNotes', 'add', {
                shouldDirty: true,
            });
            props?.setNotesDataSource(newDataSource);
        }
    };

    const handleShowModalDelete = () => {
        setModalConfirmDelete(!modalConfirmDelete);
    };

    const handleDeleteAttached = (id: number | string) => {
        const found: any = props?.notesDataSource?.find(
            (el) => el.key?.toString() === id?.toString(),
        );

        if (found) {
            setSelectedNotesDataSource(found);
            handleShowModalDelete();
        }
    };

    const removeItem = (key: number) => {
        const filteredDataSource = props?.notesDataSource?.filter(
            (el) => el.key?.toString() !== key?.toString(),
        );
        props?.setNotesDataSource(filteredDataSource);
        props.setValue('attachedNotes', 'add', {
            shouldDirty: true,
        });
        handleShowModalDelete();
    };

    const {data: responseCountry} = useGetCountryQuery();

    const countries: Array<EntityCountry> = responseCountry?.country || [];

    const runSearch = React.useCallback(
        async (value: string, record: any, key: string, dipatch?: any) => {
            const defaultV = record.number?.value
                ? [
                    {
                        ...record,
                        complete_ref: record?.number.value,
                        id: record.number?.key,
                    },
                ]
                : [];
            let chargedNote: any[] = [...defaultV];

            if (value === '' || defaultV.length === 0) {
                chargedNote = await getNotes(value);
            }
            if (key === 'number' && value !== undefined && value !== null) {
                const res = chargedNote
                    ?.filter((word) =>
                        word?.complete_ref
                            ?.toLowerCase()
                            .includes(value?.split(' - ')[0].toLowerCase()),
                    )
                    .map((el) => ({
                        ...el,
                        complete_ref:
                            el.complete_ref?.split(' - ').length >= 3
                                ? el.complete_ref
                                : `${el.complete_ref} - ${el.legal_entity} - ${el.note_amount} -${el.note_currency}`,
                    }));
                if (dipatch) dipatch(makeOptions(res || [], 'complete_ref', 'id'));
            }
        },
        [],
    );

    const getShouldValue = (net_rate: string, numberValue: string) => {
        const maxAllocation = getDefAllocation();
        const max = Number.parseFloat(net_rate || '0');
        const vToNumber = Number(numberValue);
        return Math.min(max, vToNumber, maxAllocation);
    };

    const columns = React.useMemo(
        () => [
            {
                title: 'Note ID',
                dataIndex: 'number',
                key: 1,
                editable: true,
                required: false,
                onHeaderCell: onHeaderCell,
                render: (text: { key: any; value: any }, record: any) => {
                    const editable = isEditing(record);
                    return editable ? (
                        <div className="d-flex flex-column">
                            <div style={{width: 500}}>
                                <DropdownSelect
                                    preValue={record.complete_ref}
                                    loading={isGetNotesLoading}
                                    showOnlyLoader={isGetNotesLoading}
                                    onSearch={(d, e) => runSearch(e, record, 'number', d)}
                                    onSelectOption={(e) => {
                                        updateDataSource(record.key, 'number', {
                                            key: (e as DropDownOptionObjectType).key as string,
                                            value: (e as DropDownOptionObjectType).value,
                                        });
                                    }}
                                    options={
                                        isGetNotesLoading
                                            ? []
                                            : isExist(text?.key)
                                                ? makeOptions(
                                                    [...listNoteFilter] || [],
                                                    'complete_ref',
                                                    'id',
                                                )
                                                : getUniqueArray(
                                                    makeOptions(
                                                        [...listNoteFilter] || [],
                                                        'complete_ref',
                                                        'id',
                                                    ),
                                                    props.notesDataSource?.map((el) => el.number?.key),
                                                )
                                    }
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="py-3 ps-3">{text?.value}</div>
                    );
                },
            },
            {
                title: 'Deal ID',
                dataIndex: 'deal_number',
                key: 2,
                onHeaderCell: onHeaderCell,
                editable: false,
                required: false,
                render: (text: string) => (
                    <div
                        style={{maxWidth: 180}}
                        className={classe.ellipsis}
                        title={text}
                    >
                        {text}
                    </div>
                ),
            },
            {
                title: 'Legal Entity',
                dataIndex: 'legal_entity',
                key: 2,
                onHeaderCell: onHeaderCell,
                editable: false,
                required: false,
                render: (text: string) => (
                    <div
                        style={{maxWidth: 180}}
                        className={classe.ellipsis}
                        title={text}
                    >
                        {text}
                    </div>
                ),
            },
            {
                title: 'Note Amount',
                dataIndex: 'net_rate',
                onHeaderCell: onHeaderCell,
                key: 3,
                editable: false,
                required: false,
                render: (text: string) => {
                    return (
                        <span>
              {amountFormat(Number.parseFloat(text || '0')?.toFixed(2))}
            </span>
                    );
                },
            },
            {
                title: 'Note Currency',
                dataIndex: 'note_currency',
                onHeaderCell: onHeaderCell,
                key: 4,
                editable: false,
                required: false,
            },
            {
                title: 'Allocation',
                dataIndex: 'note_amount',
                onHeaderCell: onHeaderCell,
                key: 5,
                editable: true,
                required: false,
                render: (text: number, record: any) => {
                    const editable = isEditing(record);
                    return editable ? (
                        <div className="d-flex w-100 align-items-baseline">
                            <div className="d-flex flex-column w-100">
                                <div
                                    className="d-flex  flex-center w-100 align-items-baseline"
                                    style={{
                                        minWidth: '140px',
                                    }}
                                >
                                    <Form.Label className="w-100">
                                        <div
                                            className={`d-flex  flex-row mx-0  elementFormNumber py-2 my-0`}
                                        >
                                            <div className={`d-flex flex-fill`}>
                                                <FormControl
                                                    type="text"
                                                    value={text}
                                                    className={`formNumber flex-fill rounded-0 ${
                                                        Number(record.note_amount) <= 0 && 'invalid'
                                                    }`}
                                                    onChange={(e) => {
                                                        const value: any = e.target.value || '';
                                                        const numberValue = value
                                                            .replace(/[^0-9.]/g, '')
                                                            .replace('.', 'x')
                                                            .replace(/\./g, '')
                                                            .replace('x', '.') as string;
                                                        if (numberValue) {
                                                            // if (numberValue.endsWith(".")) return;
                                                            updateDataSource(
                                                                record.key,
                                                                'note_amount',
                                                                getShouldValue(record.net_rate, numberValue),
                                                            );
                                                            return;
                                                        }
                                                        updateDataSource(
                                                            record.key,
                                                            'note_amount',
                                                            undefined,
                                                        );
                                                    }}
                                                    onBlur={() => {
                                                        const tempV = record.note_amount;
                                                        const isEndWith = tempV
                                                            ?.toString()
                                                            ?.endsWith('.00');
                                                        const restoreValue = String(tempV || '').replaceAll(
                                                            '.00',
                                                            '',
                                                        );
                                                        if (isEndWith) {
                                                            updateDataSource(
                                                                record.key,
                                                                'note_amount',
                                                                restoreValue?.trim() || 0,
                                                            );
                                                        } else {
                                                            const makeV =
                                                                parseInt(restoreValue) <= 0
                                                                    ? restoreValue || 0
                                                                    : restoreValue || 0;
                                                            updateDataSource(
                                                                record.key,
                                                                'note_amount',
                                                                makeV,
                                                            );
                                                        }
                                                    }}
                                                    onFocus={() => {
                                                        const tempV = record.note_amount;
                                                        const floorValue = tempV
                                                            ?.toString()
                                                            ?.replaceAll('.00', '');
                                                        updateDataSource(
                                                            record.key,
                                                            'note_amount',
                                                            floorValue?.trim(),
                                                        );
                                                    }}
                                                />
                                            </div>
                                            <div className="d-flex justify-content-end flex-fill">
                                                <div
                                                    className="flex flex-colummn justify-content-center pt-2 pe-2"
                                                    style={{color: '#D9D9D9'}}
                                                >
                                                    <span>|</span>
                                                </div>
                                                <div
                                                    className={`d-flex flex-column justify-content-between`}
                                                >
                                                    <div className="py-0 my-0">
                                                        <IoIosArrowUp
                                                            onClick={() => {
                                                                updateDataSource(
                                                                    record.key,
                                                                    'note_amount',
                                                                    getShouldValue(
                                                                        record.net_rate,
                                                                        `${parseInt(text?.toString()) + 1}`,
                                                                    ),
                                                                );
                                                            }}
                                                            style={{
                                                                fontSize: 16,
                                                                // marginTop: 16,
                                                                cursor: 'pointer',
                                                                color: '#851001',
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="py-0 my-0">
                                                        <IoIosArrowDown
                                                            onClick={() => {
                                                                updateDataSource(
                                                                    record.key,
                                                                    'note_amount',
                                                                    getShouldValue(
                                                                        record.net_rate,
                                                                        `${Math.max(text - 1, 0.01)}`,
                                                                    ),
                                                                );
                                                            }}
                                                            style={{
                                                                fontSize: 16,
                                                                // marginBottom: 8,
                                                                cursor: 'pointer',
                                                                color: '#851001',
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Form.Label>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="py-3 ps-3">
                            {amountFormat(
                                Number.parseFloat(text?.toString() || '0')?.toFixed(2),
                            )}
                        </div>
                    );
                },
            },
            {
                title: 'Allocation Currency',
                dataIndex: 'currency',
                key: 6,
                editable: false,
                required: false,
                onHeaderCell: onHeaderCell,
            },
            {
                title: 'Comment',
                dataIndex: 'note_comments',
                onHeaderCell: onHeaderCell,
                key: 7,
                editable: true,
                required: false,
                render: (text: string, record: any) => {
                    const editable = isEditing(record);
                    return editable ? (
                        <div className="d-flex w-100 align-items-baseline">
                            <div className="d-flex flex-column w-100">
                                <Form.Label className="w-100">
                                    <div className={`d-flex  flex-row mx-0  py-2 my-0`}>
                                        <FormControl
                                            as={'textarea'}
                                            type="text"
                                            rows={2}
                                            value={text}
                                            style={{minWidth: '140px'}}
                                            className={` flex-fill rounded-0`}
                                            onChange={(e) => {
                                                const value: any = e.target.value;
                                                updateDataSource(record.key, 'note_comments', value);
                                            }}
                                        />
                                    </div>
                                </Form.Label>
                            </div>
                        </div>
                    ) : (
                        <div
                            style={{maxWidth: 150}}
                            className={`py-3 ps-3 ${classe.ellipsis}`}
                            title={formatTitleText(text)}
                        >
                            {text}
                        </div>
                    );
                },
            },
            {
                title: '',
                dataIndex: 'id',
                key: 4,
                width: 150,
                onHeaderCell: onHeaderCell,
                render: (text: number, record: Item) => {
                    const editable = isEditing(record);
                    return editable ? (
                        <span>
              <Typography.Link
                  style={{marginRight: 8, color: 'gray'}}
                  onClick={() =>
                      updateDataSource(record.key, 'save', record, true)
                  }
                  // disabled={!isExist(record.number?.value)}
                  disabled={
                      Number(record.note_amount) <= 0 ||
                      !isExist(record.note_amount) ||
                      !isExist(record.number?.value)
                  }
              >
                Update
              </Typography.Link>
              <Typography.Link
                  style={{marginRight: 8, color: 'gray'}}
                  onClick={cancel}
              >
                Cancel
              </Typography.Link>
            </span>
                    ) : (
                        <>
                            <Typography.Link
                                disabled={editingKey !== null}
                                onClick={() => {
                                    edit(record);
                                }}
                                style={{marginRight: 10}}
                            >
                                <Edit height="30"/>
                            </Typography.Link>
                            <Typography.Link
                                disabled={editingKey !== null}
                                onClick={() => {
                                    handleDeleteAttached(record?.key);
                                }}
                            >
                                <Delete height="30"/>
                            </Typography.Link>
                        </>
                    );
                },
            },
        ],
        [
            listNoteFilter,
            editingKey,
            props.notesDataSource,
            selectedNotesDataSource,
            isGetNotesLoading,
        ],
    );

    return (
        <Container className="mt-4">
            <Row>
                <Col>
                    <RowContent>
                        <div className="d-flex  align-items-baseline pt-3">
                            <Form.Label className="radio">
                                Debit/Credit <span className="span-required">*</span>
                            </Form.Label>
                            <div style={{flexDirection: 'column', width: '100%'}}>
                                <div className="d-flex" style={{maxHeight: '1.5rem'}}>
                                    <div className="m2-4">
                                        <Form.Check
                                            type="radio"
                                            id="Yes"
                                            label="Debit"
                                            value={'debit'}
                                            checked={direction === 'debit'}
                                            onChange={(e) =>
                                                props.setValue('direction', e.target.value, {
                                                    shouldDirty: true,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="mx-4">
                                        <Form.Check
                                            type="radio"
                                            id="No"
                                            label="Credit"
                                            value={'credit'}
                                            checked={direction === 'credit'}
                                            onChange={(e) =>
                                                props.setValue('direction', e.target.value, {
                                                    shouldDirty: true,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </RowContent>

                    <RowContent>
                        <Form.Group className={`d-flex align-items-baseline`}>
                            <Form.Label className="txt-with-dropdown">
                                Cofarco Account <span className="span-required">*</span>
                            </Form.Label>

                            <div className="d-flex flex-column w-100">
                                <SelectDropDown
                                    noPadding
                                    error={props.errors?.owner_bank_account_id?.message}
                                    modalId="mandate"
                                    noSearch
                                    {...props.register('owner_bank_account_id', {
                                        required: true,
                                    })}
                                    preValue={exportedDropdownValue(
                                        props.bankList,
                                        owner_bank_account_id,
                                    )}
                                    options={props.bankList}
                                    onSelectOption={(e) => {
                                        props.setValue(
                                            'owner_bank_account_id',
                                            (e as DropDownOptionObjectType).key,
                                            {shouldDirty: true},
                                        );
                                        if (e) {
                                            props.clearFormErrors('owner_bank_account_id');
                                        } else {
                                            props.setFormError('owner_bank_account_id', {
                                                message: 'Cofarco Account is required',
                                            });
                                        }
                                    }}
                                    onBlur={() => {
                                        if (!isExist(owner_bank_account_id)) {
                                            props.setFormError('owner_bank_account_id', {
                                                message: 'Cofarco Account is required',
                                            });
                                        }
                                    }}
                                />
                            </div>
                        </Form.Group>
                    </RowContent>
                    <RowContent>
                        <Form.Group className="d-flex align-items-baseline w-100">
                            <Form.Label className="txt txt-center-fix">Amount </Form.Label>
                            <div className="d-flex flex-row  justify-content-center w-100">
                                <div className="w-100">
                                    <CofarcoNumberAmount
                                        errors={props.errors}
                                        key_form="amount"
                                        type="number"
                                        class="w-100"
                                        register={props.register}
                                        setValue={props.setValue}
                                        watch={props.watch}
                                        isAceeptFloat={true}
                                    />
                                </div>
                            </div>
                        </Form.Group>
                    </RowContent>
                    <RowContent>
                        <Form.Group className={`d-flex align-items-baseline`}>
                            <Form.Label className="txt-with-dropdown">Currency</Form.Label>
                            <div className={`d-flex w-100 flex-column `}>
                                <SelectDropDown
                                    noPadding
                                    disabled={(amount as number) < 1}
                                    modalId="mandate"
                                    preValue={exportedDropdownValue(
                                        terms?.getConst(TermConst.currency),
                                        currency,
                                    )}
                                    {...props.register('currency')}
                                    noSearch
                                    options={terms
                                        ?.getConst(TermConst.currency)
                                        ?.filter(
                                            (el: any) => el?.key !== 'xof' && el?.key !== 'cny',
                                        )}
                                    onSelectOption={(e) => {
                                        const isCleared =
                                            isExist(amount) &&
                                            !isExist((e as DropDownOptionObjectType).key);
                                        if (isCleared) {
                                            showErrorCurrency();
                                            props.setValue('currency', null);
                                            return;
                                        }

                                        props.setValue(
                                            'currency',
                                            (e as DropDownOptionObjectType).key,
                                            {shouldDirty: true},
                                        );
                                        props.clearFormErrors('currency');
                                    }}
                                    onBlur={() => {
                                        const isEmptyCurrency =
                                            isExist(amount) && !isExist(currency);
                                        if (isEmptyCurrency) {
                                            showErrorCurrency();
                                        }
                                    }}
                                    error={props.errors?.currency?.message}
                                />
                            </div>
                        </Form.Group>
                    </RowContent>
                    <RowContent>
                        <Form.Group className="d-flex" style={{alignItems: 'baseline'}}>
                            <Form.Label className="txt">Payment Date</Form.Label>
                            <CofarcoCalendar
                                name="payment_date"
                                control={props.control}
                                setFormValue={props.setValue}
                                watchFormValue={props.watch}
                            />
                        </Form.Group>
                    </RowContent>
                    <RowContent>
                        <Form.Group className="d-flex" style={{alignItems: 'baseline'}}>
                            <Form.Label className="txt">Value Date</Form.Label>

                            <CofarcoCalendar
                                name="value_date"
                                control={props.control}
                                setFormValue={props.setValue}
                                watchFormValue={props.watch}
                            />
                        </Form.Group>
                    </RowContent>
                </Col>
                <Col>
                    <RowContent>
                        <></>
                    </RowContent>

                    <RowContent>
                        <Form.Group className={`d-flex align-items-baseline`}>
                            <Form.Label className="txt-with-dropdown">
                                Legal Entity <span className="span-required">*</span>
                            </Form.Label>

                            <div className="d-flex flex-column w-100">
                                <SelectDropDown
                                    noPadding
                                    modalId="mandate"
                                    error={props.errors?.legal_entity_id?.message}
                                    {...props.register('legal_entity_id', {required: true})}
                                    preValue={exportedDropdownValue(
                                        makeOptions(props.legalentity || [], 'name', 'id'),
                                        legal_entity_id,
                                    )}
                                    icon={
                                        <span style={{cursor: 'pointer'}}>
                      {legal_entity_id ? (
                          <LegalentitiesActif
                              onClick={() => props.showLegalEntity(legal_entity_id)}
                          />
                      ) : (
                          <LegalentitiesInactif/>
                      )}
                    </span>
                                    }
                                    loading={props.isLoadingLegalEntity}
                                    onSearch={(d, v) => props.runSearch('legal_entity_id', v)}
                                    options={makeOptions(props.legalentity || [], 'name', 'id')}
                                    onSelectOption={(e: any) => {
                                        props.setValue(
                                            'legal_entity_id',
                                            (e as DropDownOptionObjectType).key,
                                            {shouldDirty: true},
                                        );
                                        if (e) {
                                            props.clearFormErrors('legal_entity_id');
                                        } else {
                                            props.setFormError('legal_entity_id', {
                                                message: 'Legal Entity is required',
                                            });
                                        }
                                    }}
                                    onBlur={() => {
                                        if (!isExist(legal_entity_id)) {
                                            props.setFormError('legal_entity_id', {
                                                message: 'Legal Entity is required',
                                            });
                                        }
                                    }}
                                />
                            </div>
                        </Form.Group>
                    </RowContent>

                    <RowContent>
                        <Form.Group className="d-flex align-items-center w-100 pt-2">
                            <Form.Label className="txt">Country</Form.Label>

                            <div className="d-flex flex-column w-100">
                                {handleRegion(countries, props?.countryId)?.name || ''}
                            </div>
                        </Form.Group>
                    </RowContent>

                    <div className={`${direction !== 'debit' ? 'd-none' : ''} mb-2`}>
                        <RowContent>
                            <Form.Group className={`d-flex align-items-baseline`}>
                                <Form.Label className="txt-with-dropdown">
                                    Beneficiary IBAN
                                </Form.Label>

                                <div className="d-flex flex-column w-100">
                                    <SelectDropDown
                                        noPadding
                                        modalId="mandate"
                                        icon={
                                            legal_entity_id ? (
                                                <span style={{cursor: 'pointer'}}>
                          <AddIban
                              onClick={() => props.setShowModalAddIban(true)}
                          />
                        </span>
                                            ) : (
                                                <span>
                          <AddIbanInactif/>
                        </span>
                                            )
                                        }
                                        noSearch
                                        preValue={exportedDropdownValue(
                                            props.ibanList,
                                            entity_bank_account_id,
                                        )}
                                        options={props.ibanList}
                                        onSelectOption={(e) =>
                                            props.setValue(
                                                'entity_bank_account_id',
                                                (e as DropDownOptionObjectType).key,
                                                {
                                                    shouldDirty: true,
                                                },
                                            )
                                        }
                                        {...props.register('entity_bank_account_id')}
                                    />
                                </div>
                            </Form.Group>
                        </RowContent>
                    </div>

                    <Form.Group className="d-flex align-items-start w-100">
                        <Form.Label className="txt_comments">Payment Comments</Form.Label>

                        <div className="d-flex flex-column w-100 mb-3">
                            <Form.Control
                                as="textarea"
                                style={{
                                    height: '100px',
                                }}
                                className=" w-100"
                                {...props.register('comments')}
                            />
                        </div>
                    </Form.Group>

                    <RowContent>
                        <CofarcoFormText
                            errors={props.errors}
                            key_form="deal_partner_reference"
                            class="w-100 align-items-baseline pt-2"
                            label="Bank Reference"
                            register={props.register('reference')}
                        />
                    </RowContent>
                </Col>
            </Row>
            {isShowTable ? (
                <>
                    <div style={{height: '1px', backgroundColor: '#DBDDDF'}}/>
                    <Row>
                        <>
                            <div className="content-table pt-4">
                                <div className="font-bold mb-2">Attached Note(s)</div>
                                <div
                                    style={{
                                        maxWidth: '100%',
                                    }}
                                >
                                    <div className="content-table">
                                        <Table
                                            dataSource={props.notesDataSource || []}
                                            columns={columns}
                                            pagination={false}
                                            rowClassName={(_, index) =>
                                                `editable-row ${
                                                    index % 2 === 1
                                                        ? classes.tablelight
                                                        : classes.tableWhite
                                                } ${classes.tablerow}`
                                            }
                                            loading={props.isGetPaymentNoteLoading}
                                        />
                                    </div>
                                </div>
                                {!props.hiddenFooter && (
                                    <div className="d-flex">
                                        <Button
                                            className="btn red mt-3 py-1"
                                            onClick={() => addNotes()}
                                            disabled={isExist(editingKey?.key) ? true : false}
                                        >
                                            <img src={AddGroup} alt="" className="pr-2"/>
                                            New Note Attachment
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </>
                    </Row>
                </>
            ) : (
                <></>
            )}

            <DeleteModal
                handleClose={handleShowModalDelete}
                show={modalConfirmDelete}
                validDelete={() => removeItem(selectedNotesDataSource?.key as number)}
                title="Remove Note"
                groupeName={selectedNotesDataSource?.number?.value}
                entityTitle="attached Notes"
            />
        </Container>
    );
}
