import { NavLink } from 'react-router-dom';
import { Menu } from '../../@types/common-types';
import { isCanReadMenu } from '../../utils/helper-function';
type Props = {
  activeMenu: number;
  onClickItem: (route: string, index: number) => void;
};

const DatabaseItems = ({ activeMenu, onClickItem }: Props) => {
  return (
    <ul>
      {isCanReadMenu(Menu.people) && (
        <li onClick={() => onClickItem('/database/people', 3)}>
          <NavLink
            className={`btn-nav btn primary  ${
              activeMenu === 3 ? 'active-link' : ''
            }`}
            to='#'
          >
            <span className='icon-People'></span>People
          </NavLink>
        </li>
      )}
      {isCanReadMenu(Menu.legal_entity) && (
        <li onClick={() => onClickItem('/database/legal-entity', 2)}>
          <NavLink
            className={`btn-nav btn primary  ${
              activeMenu === 2 ? 'active-link' : ''
            }`}
            to='#'
          >
            <span className='icon-Legal-Entities'></span>Legal Entities
          </NavLink>
        </li>
      )}
      {isCanReadMenu(Menu.group) && (
        <li onClick={() => onClickItem('/database/group', 1)}>
          <NavLink
            className={`btn-nav btn primary  ${
              activeMenu === 1 ? 'active-link' : ''
            }`}
            to='#'
          >
            <span className='icon-Grouppp'></span>Groups
          </NavLink>
        </li>
      )}
    </ul>
  );
};

export default DatabaseItems;
