import { Modal, Container, Button } from 'react-bootstrap';
import classes from './Modal.module.scss';

import './Modal.module.scss';

type Props = {
  show: boolean;
  handleClose: () => void;
  title: string;
  confirm: () => void;
  children: React.ReactNode;
};

const ConfirmModal = (props: Props) => {
  return (
    <Modal
      show={props.show}
      onHide={() => props.handleClose()}
      className={classes.modalInfo}
      contentClassName={classes.modalContent}
      dialogClassName={classes.modalDialog}
      size='lg'
      centered
    >
      <form className='modal-confirm'>
        <Modal.Header
          className={classes.modalHeader}
          closeVariant='white'
          closeButton
        >
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className='centered'>{props.children}</Container>
        </Modal.Body>
        <Modal.Footer>
          <div className='d-inline px-2'>
            <Button
              variant='default'
              onClick={props.handleClose}
              className={`btnCancel`}
            >
              No
            </Button>
          </div>
          <div className='d-inline px-2'>
            <Button
              type='button'
              className={`btnSave`}
              onClick={() => props.confirm()}
            >
              Yes
            </Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ConfirmModal;
