import React, { useState } from 'react';
import { People } from '../../../../@types/people';
import DropdownSelect, {
  DropDownOptionObjectType,
} from '../../../common/DropDown/DropdownSelect';
import {
  capitalizeFirstLetter,
  exportedDropdownValue,
  getUniqueArray,
  limitedText,
  makeOptions,
} from '../../../../utils/helper-function';
import { Typography } from 'antd';
import { ReactComponent as Edit } from '../../../../assets/img/Edit.svg';
import { ReactComponent as Delete } from '../../../../assets/img/Delete.svg';
import { useSearchCounterpartyMutation } from '../../../../redux/api/ws/deals/deals';
import { Select } from 'antd';
import UseConstante from '../../../../hooks/use-constante';
import { TermConst } from '../../../../@types/common-types';
import CofarcoNumberPercentageForm from '../../../Form/CofarcoNumberPercentageForm';
import { Form } from 'react-bootstrap';
import { useSearchPeopleByCounterPartyIdMutation } from '../../../../redux/api/ws/databases/people';

export type Item = {
  key: number | string;
  id: number;
  brokerId: { key: any; value: any } | null | undefined;
  brokerContact: { key: any; value: any } | null | undefined;
  brokerRole: Array<any>;
  netBroker: string;
  brokerReference: string;
  isCurrentEdit?: boolean;
};

type IProps = {
  brokerAttachementDataSource: Array<Item>;
  setBrokerAttachementDataSource: any;
  setValue: any;
  handleRemoveItem: (item: any) => void;
  handleChangeNetBroker: () => void;
};

export default function ColumnBrokerage(props: IProps) {
  const [editingKey, setEditingKey] = useState<Item | null>(null);
  const [pagination, setPagination] = useState({
    page: 1,
    query: '',
    stopped: false,
  });
  const [listCounterParty, setListCounterParty] = useState<Array<People | any>>(
    [],
  );

  const [brokerContactList, setBrokerContactList] = useState<
    Array<People | any>
  >([]);

  const isEditing = (record: any) =>
    record?.key?.toString() === editingKey?.key?.toString() ? true : false;

  const [lunchFilterCounterparty] = useSearchCounterpartyMutation();

  const [searchPeopleByCounterPartyId] =
    useSearchPeopleByCounterPartyIdMutation();

  const terms = UseConstante();

  const updateDataSource = (
    key: any,
    dataIndex: string,
    v: string | { key: any; value: any } | number | Item | undefined,
    isSave: boolean = false,
    isCancel: boolean = false,
  ) => {
    const index = props?.brokerAttachementDataSource.findIndex(
      (f) => f.key === key,
    );
    if (index > -1) {
      const newDataSource = [...(props?.brokerAttachementDataSource || [])];
      if (isSave) {
        newDataSource[index] = {
          ...newDataSource[index],
          isCurrentEdit: false,
        };
        setEditingKey(null);
      } else if (isCancel) {
        newDataSource[index] = {
          ...newDataSource[index],
        };
      } else {
        newDataSource[index] = {
          ...newDataSource[index],
          isCurrentEdit: true,
          [dataIndex]: v,
        };
      }
      props.setValue('brokerageTable', 'add', {
        shouldDirty: true,
      });
      props?.setBrokerAttachementDataSource(newDataSource);
    }
  };

  const getCounterparty = async (text: string) => {
    try {
      const resp = await lunchFilterCounterparty({
        query: `%${text?.trim()}%`,
        page: pagination.page,
      }).unwrap();

      const floorResult = resp.counterparty || [];

      if (!floorResult.length) {
        setPagination({ ...pagination, stopped: true });
        return;
      }

      const tempOption = makeOptions(floorResult, 'name', 'id') || [];

      const uniqueValue = getUniqueArray(
        [...listCounterParty, ...tempOption],
        props.brokerAttachementDataSource?.map((el) => el.brokerId?.key),
      );

      setListCounterParty(uniqueValue);
    } catch (error) {
      return [];
    }
  };

  const getBrokerContactByLegalEntity = async (
    legalEntityId: number[] | string[],
  ) => {
    try {
      const resp = await searchPeopleByCounterPartyId({
        params: {
          filter: [
            { property: 'legalEntityId', value: legalEntityId, operator: 'in' },
          ],
        },
      }).unwrap();
      const floorResult = resp.people;
      const tempOption = floorResult.map((d) => ({
        key: d.id,
        value: `${d.firstname} ${d.lastname}`,
      }));
      setBrokerContactList(tempOption);
    } catch (error) {
      return [];
    }
  };
  const runSearch = React.useCallback(
    async (key: keyof Item, value: string) => {
      if (value !== undefined && value !== null) {
        if (key === 'brokerId') {
          setPagination({ ...pagination, query: value });
          await getCounterparty(value);
        }
        if (key === 'brokerContact') {
          await getBrokerContactByLegalEntity([value]);
        }
      }
    },
    [],
  );

  const cancel = () => {
    const currentEdit = props.brokerAttachementDataSource?.find(
      (el) => el.key?.toString() === editingKey?.key?.toString(),
    );
    const isAlreadySave = currentEdit?.id !== 0;

    if (isAlreadySave) {
      const restoreV = props.brokerAttachementDataSource?.map((el) => {
        if (el.id?.toString() === editingKey?.id?.toString()) {
          return {
            ...editingKey,
            isCurrentEdit: false,
          };
        } else {
          return el;
        }
      });
      props.setBrokerAttachementDataSource(restoreV);
    } else {
      const tempD = props.brokerAttachementDataSource?.filter(
        (el) => el.isCurrentEdit !== true,
      );
      props.setBrokerAttachementDataSource(tempD);
    }
    setEditingKey(null);
  };

  const edit = (record: Item) => {
    setListCounterParty(record.brokerId ? [record.brokerId] : []);
    setBrokerContactList(record?.brokerContact ? [record?.brokerContact] : []);
    setEditingKey({ ...record });
  };

  const handleDelete = (id: number) => {
    const found: any = props?.brokerAttachementDataSource?.find(
      (el) => el.key?.toString() === id?.toString(),
    );
    if (found) {
      props.handleRemoveItem(found);
    }
  };

  const handleBrokerRole = (role: Array<string>) => {
    if (role?.length > 0) {
      const toUppercaseFirst = role?.map((el) => capitalizeFirstLetter(el));

      const allValue = toUppercaseFirst?.join(', ');

      return allValue;
    }
    return '';
  };

  // Handle scroll event
  const handleScroll = (e: any) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;

    if (scrollHeight - scrollTop === clientHeight && !pagination.stopped) {
      setPagination({ ...pagination, page: pagination.page + 1 });
      getCounterparty(pagination.query);
    }
  };

  const columnBroker: any = [
    {
      title: 'BROKER ID',
      dataIndex: 'brokerId',
      key: 1,
      editable: true,
      required: false,
      width: 250,
      render: (text: { key: any; value: any }, record: any) => {
        const editable = isEditing(record);
        return editable ? (
          <Form.Group className={`d-flex`} style={{ marginTop: 8 }}>
            <div className="d-flex flex-column">
              <div style={{ maxWidth: 420 }}>
                <DropdownSelect
                  noPadding
                  onScroll={handleScroll}
                  modalId="brokerId"
                  preValue={exportedDropdownValue(listCounterParty, text?.key)}
                  onSelectOption={(e: any) => {
                    updateDataSource(record.key, 'brokerId', {
                      key: (e as DropDownOptionObjectType).key as string,
                      value: (e as DropDownOptionObjectType).value,
                      legal_entities: e.legal_entities,
                    } as any);
                    // updateDataSource(record.key, "legal_entities", e.legal_entities);
                    if (e && e.key) {
                      const legalEntityIds = e.legal_entities.map(
                        (l: any) => l.id,
                      );
                      getBrokerContactByLegalEntity(legalEntityIds);
                    } else {
                      //restore broker contact value
                      setBrokerContactList([]);
                      updateDataSource(record.key, 'brokerContact', undefined);
                      // updateDataSource(record.key, "brokerId", undefined);
                    }
                  }}
                  onSearch={(d, v) => runSearch('brokerId', v)}
                  options={listCounterParty}
                />
              </div>
            </div>
          </Form.Group>
        ) : (
          <div>
            <b> {limitedText(text?.value, 38)}</b>
          </div>
        );
      },
    },
    {
      title: 'BROKER CONTACT',
      dataIndex: 'brokerContact',
      key: 2,
      width: 250,
      render: (text: { key: any; value: any }, record: any) => {
        const editable = isEditing(record);
        return editable ? (
          <Form.Group className={`d-flex`} style={{ marginTop: 8 }}>
            <div className="d-flex flex-column">
              <div style={{ maxWidth: 420 }}>
                <DropdownSelect
                  noPadding
                  modalId="brokerContact"
                  disabled={!record.brokerId}
                  preValue={exportedDropdownValue(brokerContactList, text?.key)}
                  noSearch
                  onSelectOption={(e) => {
                    if (e === '') {
                      const legalEntityIds =
                        record.brokerId?.legal_entities?.map(
                          (l: any) => l.id,
                        ) ?? [];
                      getBrokerContactByLegalEntity(legalEntityIds);
                    }
                    updateDataSource(record.key, 'brokerContact', {
                      key: (e as DropDownOptionObjectType).key as string,
                      value: (e as DropDownOptionObjectType).value,
                    });
                  }}
                  // onSearch={(d, v) => {

                  //   console.log("tempD>>>",tempD,'>>>>',record)
                  //   // runSearch("brokerContact", v)
                  // }}
                  options={brokerContactList}
                />
              </div>
            </div>
          </Form.Group>
        ) : (
          <div>
            <b> {limitedText(text?.value?.toString(), 38)}</b>
          </div>
        );
      },
    },
    {
      title: 'BROKER ROLE',
      dataIndex: 'brokerRole',
      key: 3,
      width: 250,
      render: (text: Array<string>, record: any) => {
        const editable = isEditing(record);
        return editable ? (
          <Form.Group
            className={`d-flex`}
            style={{ marginTop: 8, minWidth: 220 }}
          >
            <div className="d-flex flex-column w-100">
              <Select
                mode="multiple"
                size="middle"
                style={{
                  height: 39,
                  overflowY: 'scroll',
                }}
                placeholder="Please select"
                // value={text}
                defaultValue={text}
                onChange={(_: any, option: any) => {
                  const v = option?.map((el: any) => el.value);
                  updateDataSource(record.key, 'brokerRole', v);
                }}
                options={terms
                  .getConst(TermConst.dealBrokerRole)
                  ?.map((el: any) => ({
                    label: el.value,
                    value: el.key,
                  }))}
              />
            </div>
          </Form.Group>
        ) : (
          <div>
            <b> {handleBrokerRole(text)}</b>
          </div>
        );
      },
    },
    {
      title: 'NET BROKER',
      dataIndex: 'netBroker',
      key: 4,
      width: 180,
      render: (text: string, record: any) => {
        const editable = isEditing(record);
        return editable ? (
          <Form.Group className={`d-flex`} style={{ marginTop: 8 }}>
            <CofarcoNumberPercentageForm
              value={text}
              unitFormat={6}
              setValue={(v: string | undefined) => {
                updateDataSource(record.key, 'netBroker', v);
                props.handleChangeNetBroker();
              }}
              maxValue={100}
            />
          </Form.Group>
        ) : (
          <div>
            <b> {limitedText(text, 38)}</b>
          </div>
        );
      },
    },
    {
      title: 'BROKER REFERENCE',
      dataIndex: 'brokerReference',
      key: 5,
      width: 200,
      render: (text: string, record: any) => {
        const editable = isEditing(record);
        return editable ? (
          <Form.Group className={`d-flex`}>
            <div
              className="d-flex"
              style={{
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <Form.Control
                type={'text'}
                value={text}
                onChange={(evt) => {
                  updateDataSource(
                    record.key,
                    'brokerReference',
                    evt.target.value,
                  );
                }}
              />
            </div>
          </Form.Group>
        ) : (
          <div>
            <b> {limitedText(text, 38)}</b>
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: 'id',
      key: 6,
      width: 150,
      fixed: 'left',
      render: (text: number, record: Item) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              style={{ marginRight: 8, color: 'gray' }}
              onClick={() => {
                updateDataSource(record.key, 'save', record, true);
                props.handleChangeNetBroker();
              }}
            >
              Update
            </Typography.Link>
            <Typography.Link
              style={{ marginRight: 8, color: 'gray' }}
              onClick={cancel}
            >
              Cancel
            </Typography.Link>
          </span>
        ) : (
          <>
            <Typography.Link
              disabled={editingKey !== null}
              onClick={() => {
                edit(record);
              }}
              style={{ marginRight: 10 }}
            >
              <Edit height="30" />
            </Typography.Link>
            <Typography.Link
              disabled={editingKey !== null}
              onClick={() => {
                handleDelete(record?.key as number);
              }}
            >
              <Delete height="30" />
            </Typography.Link>
          </>
        );
      },
    },
  ];

  return { columnBroker, editingKey, edit };
}
