import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IndicationFilterType } from '../../@types/Indication/indication'

export interface IndicationFilterState {
    data: IndicationFilterType
  }

  const initialState: IndicationFilterState = {
    data: {},
  }

  export const indicationFilterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
      setIndicationFilter: (state, action: PayloadAction<IndicationFilterType>) => {
        state.data = action.payload
        return state
      },
    
    },
  })
  
  export const { setIndicationFilter } = indicationFilterSlice.actions
  export default indicationFilterSlice.reducer