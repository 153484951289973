import * as React from "react";

 const NotFoundContent = () => {
  return (
    <div className="d-flex align-items-center flex-col h-100">
      <div className="w-100 justify-content-center d-flex flex-col">
      <h3>Not found </h3>
      </div>
    </div>
  );
};

export default NotFoundContent
