import React from 'react';
import { NavLink } from 'react-bootstrap';

type IProps = {
  createTitle: string;
  isEdit: boolean | number;
  activeMenu: number;
  setActiveMenu: (p: number) => void;
  menus: Array<{ title: string; id: number }>;
};
export default function HeaderMultiple(props: IProps) {
  return (
    <>
      {props.isEdit ? (
        <div className='d-flex justify-content-start'>
          {props.menus.map((el, index) => (
            <NavLink
              key={index}
              className={`p-2 mx-1 ${
                props.activeMenu === el.id
                  ? 'form-group-title'
                  : 'btn-list-entity'
              } `}
              onClick={() => props.setActiveMenu(el.id)}
            >
              <span> {el.title}</span>
            </NavLink>
          ))}
        </div>
      ) : (
        <span> {props.createTitle}</span>
      )}
    </>
  );
}
