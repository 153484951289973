import { Row } from 'react-bootstrap';
import TierForm from '../TierForm/TierForm';
import { MdOutlineCall } from 'react-icons/md';
import { UseFormRegister, UseFormWatch } from 'react-hook-form';
import { PeopleFormInputs } from '../../../../../@types/people';

type Props = {
  register: UseFormRegister<PeopleFormInputs>;
  watchValue: UseFormWatch<PeopleFormInputs>;
  setIsValueTiersUpdate: (p: boolean) => void;
};

const PhoneIcon = () => {
  return <MdOutlineCall style={{ fontSize: 20, cursor: 'pointer' }} />;
};

const PhonePeople = ({
  register,
  watchValue,
  setIsValueTiersUpdate,
}: Props) => {
  return (
    <>
      <div className='font-bold px-4'>Numbers</div>
      <div className='bg-white px-4 py-3 mx-4 mt-4'>
        <Row className='my-2'>
          <TierForm
            {...register('phone_number')}
            value={watchValue('phone_number')}
            title='Phone Number'
            icon={<PhoneIcon />}
            tagType='phone'
            setIsValueTiersUpdate={(p: boolean) => {
              setIsValueTiersUpdate(p);
            }}
          />
        </Row>
        <Row className='my-2'>
          <TierForm
            {...register('company_number')}
            value={watchValue('company_number')}
            title='Company Number'
            icon={<PhoneIcon />}
            tagType='phone'
            setIsValueTiersUpdate={(p: boolean) => setIsValueTiersUpdate(p)}
          />
        </Row>
        <Row className='my-2'>
          <TierForm
            {...register('mobile_number')}
            value={watchValue('mobile_number')}
            title='Mobile Number'
            icon={<PhoneIcon />}
            tagType='phone'
            setIsValueTiersUpdate={(p: boolean) => setIsValueTiersUpdate(p)}
          />
        </Row>
        <Row className='my-2'>
          <TierForm
            {...register('other_number')}
            value={watchValue('other_number')}
            title='Other Number'
            tagType='phone'
            icon={<PhoneIcon />}
            setIsValueTiersUpdate={(p: boolean) => setIsValueTiersUpdate(p)}
          />
        </Row>
        <Row className='my-2'>
          <TierForm
            {...register('fax_number')}
            value={watchValue('fax_number')}
            title='Fax Number'
            icon={<PhoneIcon />}
            tagType='phone'
            setIsValueTiersUpdate={(p: boolean) => setIsValueTiersUpdate(p)}
          />
        </Row>
      </div>
    </>
  );
};

export default PhonePeople;
