import { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import { orderBy, uniq } from "lodash";
import { Col, Form, ModalProps, Row } from "react-bootstrap";
import { Divider, Table, Form as AntForm, Button, Radio } from "antd";
import FormModal from "../../../Modal/FormModal/FormModal";
import classes from "./PartnerModal.module.scss";
import { PartnerConterParty } from "../../../../@types/conterParty";
import AddGroup from "../../../../assets/img/add.svg";
import usePartnerModal from "./usePartnerModal";
import SelectDropDown from "../../../common/DropDown/SelectDropDown";
import { DropDownOptionObjectType } from "../../../common/DropDown/DropdownSelect";
import { People } from "../../../../@types/people";
import CofarcoFormText from "../../../Form/CofarcoFormText";
import ReactDatePicker from "react-datepicker";
import CustomInput from "../../../common/CustomInput/CustomInput";
import moment from "moment";
import DeleteModal from "../../../Modal/DeleteModal";
import { IoCalendarSharp } from "react-icons/io5";
import { checkIsEdit, isCan } from "../../../../utils/helper-function";
import { Menu } from "../../../../@types/common-types";
import RowContent from "../../../Form/RowContent";
import HeaderCalendar from "../../../Calendar/HeaderCalendar";
import ModalUnsavedChange from "../../../common/Modal/ModalUnsavedChange";

type Props = {
  defaultFormValues?: PartnerConterParty | null;
  onSubmittedForm?: () => void;
  onClose?: () => void;
} & ModalProps;

const PartnerModal = ({
  defaultFormValues,
  onSubmittedForm,
  onClose,
  ...props
}: Props) => {
  const {
    terms,
    defaultInputValues,
    errors,
    peoples,
    searchPeopleCallback,
    onSubmitForm,
    register,
    watchFormValue,
    setFormValue,
    getFormValue,
    handleClose,
    mergedLegalEntity,
    formLegalEntity,
    dataSourceLegalEntity,
    addLegalEntity,
    showPeopleModal,
    deleteModal,
    closeDeleteLegalEntity,
    isInModeEditing,
    deleteLegalEntity,
    isCompletedForm,
    isDirty,
    isShowModalUnsavedChange,
    setIsShowModalUnsavedChange,
    showOtherModaldata,
  } = usePartnerModal({ defaultFormValues, onSubmittedForm });

  const [isLegalEntityEdit, setIsLegalEntityEdit] = useState<boolean>(false);

  useEffect(() => {
    setIsLegalEntityEdit(false);
  }, [defaultFormValues]);

  const hiddenFooter = !!(
    !isCan("write", Menu.partner) &&
    checkIsEdit(defaultFormValues?.id as number)
  );

  return (
    <>
      <DeleteModal
        title="Delete Legal Entity"
        show={deleteModal.show}
        groupeName={`${deleteModal?.legal_entity?.name ?? ""}`}
        entityTitle={"Legal Entities List"}
        validDelete={() => deleteLegalEntity(deleteModal.id ?? -1)}
        handleClose={() => closeDeleteLegalEntity()}
      />
      <FormModal
        {...props}
        ishiddenfooter={hiddenFooter}
        header={
          defaultInputValues.id !== -1 ? (
            <div className="d-flex">
              <div
                className={`ms-3 ${
                  isLegalEntityEdit
                    ? classes.modalHeaderTitleEditNotActive
                    : classes.modalHeaderTitleEdit
                }`}
                onClick={() => {
                  setIsLegalEntityEdit(false);
                }}
              >
                Partner - {defaultInputValues.name}
              </div>

              <div
                className={`ms-3 ${
                  isLegalEntityEdit
                    ? classes.modalHeaderTitleEdit
                    : classes.modalHeaderTitleEditNotActive
                }`}
                onClick={() => {
                  setIsLegalEntityEdit(true);
                }}
              >
                Legal Entity(ies) List
              </div>
            </div>
          ) : (
            <div className={`ms-3 ${classes.modalHeaderTitle}`}>
              New - Partner
            </div>
          )
        }
        handleClose={() => {
          setIsLegalEntityEdit(false);
          handleClose();
          if (onClose) onClose();
        }}
        onSubmitForm={onSubmitForm}
        isDisabledSave={
          !isDirty ||
          Object.keys(errors).length > 0 ||
          !isCompletedForm ||
          dataSourceLegalEntity.filter((f) => f.legal_entity).length === 0
        }
      >
        {!isLegalEntityEdit && (
          <>
            <Row>
              <Col className="px-3">
                <RowContent>
                  <CofarcoFormText
                    label="ID"
                    class="w-100 align-items-baseline"
                    errors={errors}
                    key_form="name"
                    register={register("name", {
                      required: true,
                      validate: (value) => value.length > 0 || "Required",
                    })}
                    isRequired
                    txtError="Required"
                  />
                </RowContent>
                <RowContent>
                  <CofarcoFormText
                    label="Commercial Name"
                    class="w-100 align-items-baseline"
                    errors={errors}
                    key_form="commercial_name"
                    register={register("commercial_name")}
                    txtError="Required"
                  />
                </RowContent>

                <RowContent>
                  <SelectDropDown
                    noPadding
                    dropDownClass="px-0"
                    title="Type"
                    id="activityType"
                    preValue={getFormValue("_type")}
                    onSearch={(d, v) => {
                      d(
                        uniq(
                          terms.filter(
                            (f) =>
                              (f.opt === 8 || f.opt === 28 || f.opt == 25) &&
                              (f.type === "activityType" ||
                                f.type === "activity") &&
                              f.value.toLowerCase().includes(v.toLowerCase())
                          )
                        )
                      );
                    }}
                    options={orderBy(
                      uniq(
                        terms.filter(
                          (f) =>
                            (f.opt === 8 || f.opt === 28 || f.opt == 25) &&
                            (f.type === "activityType" || f.type === "activity")
                        )
                      ),
                      ["key"]
                    )}
                    onSelectOption={(e) => {
                      setFormValue(
                        "type",
                        (e as DropDownOptionObjectType).key as string,
                        { shouldDirty: true }
                      );
                      setFormValue(
                        "_type",
                        (e as DropDownOptionObjectType).value as string,
                        { shouldDirty: true }
                      );

                      if (
                        ((e as DropDownOptionObjectType).key as string) ===
                        "other"
                      ) {
                        setFormValue("activity", "other", {
                          shouldDirty: true,
                        });
                        setFormValue("_activity", "Other", {
                          shouldDirty: true,
                        });
                      } else {
                        setFormValue("activity", "", { shouldDirty: true });
                        setFormValue("_activity", "", { shouldDirty: true });
                      }
                    }}
                  />
                </RowContent>
                <RowContent>
                  <SelectDropDown
                    noPadding
                    dropDownClass="px-0"
                    title="Role"
                    id="roleActivity"
                    placeholder=""
                    disabled={watchFormValue("activity") === "other"}
                    preValue={getFormValue("_activity")}
                    onSearch={(d, v) => {
                      d(
                        uniq(
                          terms.filter(
                            (f) =>
                              f.type === "activity" &&
                              (f.opt === 24 || f.opt === 28) &&
                              f.dependences === watchFormValue("type") &&
                              f.value.toLowerCase().includes(v.toLowerCase())
                          )
                        )
                      );
                    }}
                    options={uniq(
                      terms.filter(
                        (f) =>
                          f.type === "activity" &&
                          (f.opt === 24 || f.opt === 28) &&
                          f.dependences === watchFormValue("type")
                      )
                    )}
                    onSelectOption={(e) => {
                      setFormValue(
                        "activity",
                        (e as DropDownOptionObjectType).key as string,
                        { shouldDirty: true }
                      );
                      setFormValue(
                        "_activity",
                        (e as DropDownOptionObjectType).value as string,
                        { shouldDirty: true }
                      );
                    }}
                  />
                </RowContent>
              </Col>

              <Col>
                <RowContent>
                  <SelectDropDown
                    noPadding
                    dropDownClass="px-0"
                    title="Main Contact"
                    id="main_people"
                    placeholder=""
                    preValue={(getFormValue("_main_people_id") ?? "") as string}
                    onSearch={(d, v) => {
                      searchPeopleCallback(
                        dataSourceLegalEntity
                          .filter((f) => f.legal_entity)
                          .map((e) => e.legal_entity?.id ?? 0),
                        v,
                        (data) => {
                          d(
                            data.map((d) => ({
                              key: d,
                              value: `${d.firstname} ${d.lastname}`,
                            }))
                          );
                        }
                      );
                    }}
                    options={peoples.map((d) => ({
                      key: d,
                      value: `${d.firstname} ${d.lastname}`,
                    }))}
                    onSelectOption={(e) => {
                      if (e) {
                        setFormValue(
                          "main_people_id",
                          (e as DropDownOptionObjectType).key.id as number,
                          { shouldDirty: true }
                        );
                        setFormValue(
                          "_main_people",
                          (e as DropDownOptionObjectType).key as People,
                          { shouldDirty: true }
                        );
                      } else {
                        setFormValue("main_people_id", null, {
                          shouldDirty: true,
                        });
                        setFormValue("_main_people", undefined, {
                          shouldDirty: true,
                        });
                      }
                    }}
                    icon={
                      <FaUser
                        style={{
                          fontSize: 22,
                          cursor: "pointer",
                          color: watchFormValue("main_people_id")
                            ? "#6d1206"
                            : "",
                        }}
                        onClick={() => {
                          const v = getFormValue("main_people_id");
                          if (v) {
                            showPeopleModal(v);
                          }
                        }}
                      />
                    }
                  />
                </RowContent>

                <RowContent>
                  <SelectDropDown
                    noPadding
                    dropDownClass="px-0"
                    title="Most Senior Contact"
                    id="most_senior_people"
                    placeholder=""
                    preValue={
                      (getFormValue("_most_senior_people_id") ?? "") as string
                    }
                    onSearch={(d, v) => {
                      searchPeopleCallback(
                        dataSourceLegalEntity
                          .filter((f) => f.legal_entity)
                          .map((e) => e.legal_entity?.id ?? 0),
                        v,
                        (data) => {
                          d(
                            data.map((d) => ({
                              key: d,
                              value: `${d.firstname} ${d.lastname}`,
                            }))
                          );
                        }
                      );
                    }}
                    options={peoples.map((d) => ({
                      key: d,
                      value: `${d.firstname} ${d.lastname}`,
                    }))}
                    onSelectOption={(e) => {
                      if (e) {
                        setFormValue(
                          "most_senior_people_id",
                          (e as DropDownOptionObjectType).key.id as number,
                          { shouldDirty: true }
                        );
                        setFormValue(
                          "_most_senior_people",
                          (e as DropDownOptionObjectType).key as People,
                          { shouldDirty: true }
                        );
                      } else {
                        setFormValue("most_senior_people_id", null, {
                          shouldDirty: true,
                        });
                        setFormValue("_most_senior_people", undefined, {
                          shouldDirty: true,
                        });
                      }
                    }}
                    icon={
                      <FaUser
                        style={{
                          fontSize: 22,
                          cursor: "pointer",
                          color: getFormValue("most_senior_people_id")
                            ? "#6d1206"
                            : "",
                        }}
                        onClick={() => {
                          const v = getFormValue("most_senior_people_id");
                          if (v) {
                            showPeopleModal(v);
                          }
                        }}
                      />
                    }
                  />
                </RowContent>
                <RowContent>
                  <Form.Group
                    className="d-flex align-items-baseline"
                    style={{ paddingTop: ".7rem" }}
                  >
                    <Form.Label htmlFor="forLastName" className={`txt`}>
                      Terms of Business
                    </Form.Label>
                    <div className="d-flex w-100 pe-1">
                      <Radio.Group
                        onChange={(t) => {
                          setFormValue(
                            "informations",
                            {
                              ...getFormValue("informations"),
                              terms_of_business: t.target.value,
                            },
                            { shouldDirty: true }
                          );
                        }}
                        value={watchFormValue("informations.terms_of_business")}
                      >
                        <Radio value={"yes"}>Yes</Radio>
                        <Radio value={"no"}>No</Radio>
                      </Radio.Group>
                    </div>
                  </Form.Group>
                </RowContent>
                <RowContent>
                  <Form.Group className="d-flex">
                    <Form.Label
                      htmlFor="forLastName"
                      className={`txt pt-1`}
                      style={{ whiteSpace: "pre-wrap" }}
                    >
                      {"Terms of Business \nDate"}
                    </Form.Label>

                    <div className="d-flex w-100 pe-1">
                      <ReactDatePicker
                        renderCustomHeader={({
                          date,
                          changeYear,
                          changeMonth,
                          decreaseMonth,
                          increaseMonth,
                          prevMonthButtonDisabled,
                          nextMonthButtonDisabled,
                        }) =>
                          HeaderCalendar({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                          })
                        }
                        className={`form-control picker-select ${classes.datePicker}`}
                        autoComplete="off"
                        placeholderText="Select a date"
                        selected={
                          watchFormValue("informations.terms_of_business_date")
                            ? new Date(
                                watchFormValue(
                                  "informations.terms_of_business_date"
                                ) as string
                              )
                            : null
                        }
                        value={
                          watchFormValue("informations.terms_of_business_date")
                            ? moment(
                                watchFormValue(
                                  "informations.terms_of_business_date"
                                )
                              ).format("DD/MM/YYYY")
                            : undefined
                        }
                        customInput={
                          <CustomInput
                            rightIcon={
                              <IoCalendarSharp
                                color="#851001"
                                className="mt-2 me-1"
                                size={20}
                              />
                            }
                          />
                        }
                        onChange={(e) =>
                          setFormValue(
                            "informations",
                            {
                              ...getFormValue("informations"),
                              terms_of_business_date: e
                                ? moment(e).format("YYYY-MM-DD")
                                : null,
                            },
                            { shouldDirty: true }
                          )
                        }
                      />
                    </div>
                  </Form.Group>
                </RowContent>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col className="mt-0">
                {watchFormValue("id") <= 0 && (
                  <>
                    <div className="font-bold mt-0">Legal Entities List</div>
                    <div className="content-table mt-4">
                      <div className="content-table">
                        <AntForm form={formLegalEntity} component={false}>
                          <Table
                            className="no-background"
                            style={{ backgroundColor: "transparent" }}
                            rowClassName={classes.antdTable}
                            dataSource={dataSourceLegalEntity}
                            columns={mergedLegalEntity}
                            rowKey="id"
                            pagination={false}
                          />
                        </AntForm>
                      </div>
                      <Button
                        className="btn red mt-3 py-0"
                        disabled={isInModeEditing}
                        onClick={() => addLegalEntity()}
                      >
                        <img src={AddGroup} alt="" className="pr-2" /> Add Legal
                        Entity
                      </Button>
                    </div>
                  </>
                )}
              </Col>
            </Row>
          </>
        )}
        {isLegalEntityEdit && (
          <Row>
            {watchFormValue("id") > 0 && (
              <>
                <div className="content-table mt-4">
                  <div className="content-table">
                    <AntForm form={formLegalEntity} component={false}>
                      <Table
                        className="no-background"
                        rowClassName={classes.antdTable}
                        dataSource={dataSourceLegalEntity}
                        columns={mergedLegalEntity}
                        rowKey="id"
                        pagination={false}
                      />
                    </AntForm>
                  </div>
                  {!hiddenFooter && (
                    <Button
                      className="btn red mt-3 py-0"
                      disabled={isInModeEditing}
                      onClick={() => addLegalEntity()}
                    >
                      <img src={AddGroup} alt="" className="pr-2" /> Add Legal
                      Entity
                    </Button>
                  )}
                </div>
              </>
            )}
          </Row>
        )}
      </FormModal>
      <ModalUnsavedChange
        showModalUnsaved={isShowModalUnsavedChange}
        handleCancel={() => setIsShowModalUnsavedChange(false)}
        handleLeave={() => showPeopleModal(showOtherModaldata, true)}
        // dirtiFields={dirtyFields}
      />
    </>
  );
};

export default PartnerModal;
