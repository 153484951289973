import { Button, Modal } from "react-bootstrap";
import { transformcaseTextToText } from "../../../utils/helper-function";
import classes from "./ModalUnsaved.module.scss";
import { isEmpty } from "lodash";

const ModalUnsavedChange = ({
  dirtiFields,
  showModalUnsaved,
  handleCancel,
  handleLeave,
}: Props) => {
  return (
    <Modal
      show={showModalUnsaved}
      keyboard={false}
      scrollable={true}
      centered
      enforceFocus
      backdropClassName="modal-backdrop"
      id="modalUnsaved"
    >
      <Modal.Header>
        <Modal.Title>Unsaved Changes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to leave? You have unsaved changes.</p>
        {!isEmpty(dirtiFields) && (
          <>
            Verify the following fields:
            {Object.keys(dirtiFields).map((key, index) => {
              return (
                <span key={index}>
                  <b>{transformcaseTextToText(key)} </b>
                  {index !== Object.keys(dirtiFields).length - 1 ? ", " : ""}
                </span>
              );
            })}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end">
          <div className="d-inline px-2">
            <Button
              variant="default"
              onClick={() => handleCancel()}
              className={`${classes.btnCancel}`}
            >
              Cancel
            </Button>
          </div>
          <div className="d-inline px-2">
            <Button
              className={`${classes.btnSave}`}
              onClick={() => handleLeave()}
            >
              Leave
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalUnsavedChange;

type Props = {
  dirtiFields?: any;
  showModalUnsaved: boolean;
  handleLeave: () => void;
  handleCancel: () => void;
};
