import React, { useState } from 'react';
import { Form, FormControl, FormControlProps } from 'react-bootstrap';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { isNumber, floorNumber } from '../../utils/helper-function';
import { ReactComponent as Warning } from '../../assets/img/Warning.svg';

type IProps = FormControlProps & {
  register: any;
  watch: any;
  setValue: any;
  key_form: string;
  errors?: any;
  txtError?: string;
  isRequired?: boolean;
  txtErrorValidate?: string;
  class?: string;
  onBlur?: any;
  unitFormat?: string;
  maxValue?: number;
  disabled?: boolean;
  setFocus?: any;
};
export default function CofarcoNumberRateFormate(props: IProps) {
  const valueForm = props.watch(props.key_form);
  const ref: any = React.useRef(null);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  return (
    <Form.Label className="w-100">
      <div
        className={`d-flex  elementFormNumber flex-row mx-0  py-2 my-0 ${
          props?.disabled ? 'disabled-content' : ''
        }`}
      >
        <div className={`d-flex flex-fill `}>
          <FormControl
            type="text"
            value={valueForm}
            disabled={props.disabled}
            ref={ref}
            className={`flex-fill formNumber rounded-0`}
            {...props.register(props?.key_form, {
              onChange: (e: any) => {
                if (e?.target?.value) {
                  const value: any = e.target.value;
                  const d = props.maxValue
                    ? Number(value) <= props.maxValue && isNumber(value)
                    : isNumber(value);
                  if (d) {
                    props.setValue(props?.key_form, value, {
                      shouldDirty: true,
                    });
                    return;
                  }
                  props.setValue(props?.key_form, valueForm || 0, {
                    shouldDirty: true,
                  });
                  return;
                }
                props.setValue(props?.key_form, undefined, {
                  shouldDirty: true,
                });
              },
            })}
            onBlur={() => {
              setIsFocused(false);
              const removePercent = floorNumber(
                valueForm?.toString() as string,
              );
              const value = Number.parseFloat(removePercent || 0);
              props.setValue(props?.key_form, value.toFixed(6) + ' %', {
                shouldDirty: true,
              });
            }}
            onFocus={() => {
              setIsFocused(true);
              const floorValue = floorNumber(valueForm?.toString() as string);
              props.setValue(props?.key_form, floorValue?.toString()?.trim(), {
                shouldDirty: true,
              });
            }}
          />
        </div>
        <div
          className={`d-flex justify-content-end flex-fill ${
            props?.disabled ? 'disabled' : ''
          }`}
        >
          <div
            className="flex flex-colummn justify-content-center pt-2 pe-2"
            style={{ color: '#D9D9D9' }}
          >
            <span>|</span>
          </div>
          <div className={`d-flex flex-column justify-content-between`}>
            <div className="py-0 my-0">
              <IoIosArrowUp
                onClick={() => {
                  if (props.setFocus) {
                    props.setFocus(props?.key_form);
                  }
                  const floorValue = (valueForm?.toString() as string)
                    .replaceAll(',', '')
                    .replaceAll(' %', '');

                  const v = Number(floorValue?.toString() || '0') + 1;

                  if (props.maxValue) {
                    if (Number(v) <= props.maxValue) {
                      props.setValue(props?.key_form, v?.toFixed(6), {
                        shouldDirty: true,
                      });
                      return;
                    }
                  } else {
                    props.setValue(props?.key_form, v?.toFixed(6)?.toString(), {
                      shouldDirty: true,
                    });
                  }
                }}
                style={{
                  fontSize: 16,
                  cursor: 'pointer',
                  color: '#851001',
                }}
              />
            </div>
            <div className="py-0 my-0">
              <IoIosArrowDown
                onClick={() => {
                  if (props.setFocus) {
                    props.setFocus(props?.key_form);
                  }

                  const floorValue = (valueForm?.toString() as string)
                    .replaceAll(',', '')
                    .replaceAll(' %', '');

                  const value = Math.max(
                    Number(floorValue?.toString() || '0') - 1,
                    0,
                  );

                  props.setValue(props?.key_form, value?.toFixed(6), {
                    shouldDirty: true,
                  });
                }}
                style={{
                  fontSize: 16,
                  // marginBottom: 8,
                  cursor: 'pointer',
                  color: '#851001',
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {isFocused && Number(props.maxValue) > 0 && (
        <div style={{ position: 'absolute' }}>
          <Warning style={{ fontSize: 4 }} width={10} />{' '}
          <span style={{ fontSize: 12 }}>
            The maximum value for this field is 99
          </span>
        </div>
      )}
    </Form.Label>
  );
}
