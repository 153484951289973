import { Col, Form, FormControl, ModalProps, Row } from 'react-bootstrap';
import { useMemo, useRef } from 'react';

import ReactDatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { isEqual } from 'lodash';
import validator from 'validator';
import classes from './PeopleModal.module.scss';
import { FaBuilding, FaLinkedin } from 'react-icons/fa';
import { Checkbox, Divider } from 'antd';
import usePeopleModal from './usePeopleModal';
import { DropDownOptionObjectType } from '../../../common/DropDown/DropdownSelect';
import { People } from '../../../../@types/people';
import TitleForm from './TitleForm/TitleForm';
import SelectDropDown from '../../../common/DropDown/SelectDropDown';
import EmailPeople from './Email/EmailPeople';
import PhonePeople from './Phone/PhonePeople';
import moment from 'moment';
import FormModal from '../../../Modal/FormModal/FormModal';
import { LegalEntity } from '../../../../@types/legal-entities';
import { useEffect } from 'react';
import toCamelCase from '../../../../utils/toCamelCase';
import CofarcoFormText from '../../../Form/CofarcoFormText';
import {
  checkIsEdit,
  isCan,
  isExist,
  isToday,
} from '../../../../utils/helper-function';
import { Menu } from '../../../../@types/common-types';
import RowContent from '../../../Form/RowContent';
import { ReactComponent as CalendarIcon } from '../../../../assets/img/Calendar.svg';
import HeaderCalendar from '../../../Calendar/HeaderCalendar';
import { IoClose } from 'react-icons/io5';
import CustomInput from '../../../common/CustomInput/CustomInput';
import ModalUnsavedChange from '../../../common/Modal/ModalUnsavedChange';
import FormError from '../../../FormError/FormError';
import ConfirmModal from '../../../Modal/ConfirmModal';

dayjs.extend(customParseFormat);

type Props = {
  onClose?: (isEdit: boolean) => void;
  defaultFormValues?: People | null;
  onSubmittedForm?: (isEdit: boolean) => void;
  onChangeLegalEntity?: (legalentity: LegalEntity) => void;
} & ModalProps;

const PeopleModal = ({
  onClose,
  onSubmittedForm,
  onChangeLegalEntity,
  defaultFormValues,
  ...props
}: Props) => {
  const {
    errors,
    legalEntities,
    isLeagEntityLoading,
    defaultValues,
    legalEntitiesCounterParties,
    peopleCounterParty,
    textTerms,
    register,
    setFormValue,
    getFormValue,
    searchLegalEntity,
    onSubmitForm,
    onCloseModal,
    watchFormValue,
    handleSelectCounterParty,
    setLegalEntitiesCounterParties,
    getLegaleEntitiesByIdQuery,
    setLegalEntities,
    peopleDefaultCounterParty,
    isShowModalUnsavedChange,
    setIsShowModalUnsavedChange,
    showOtherModaldata,
    onClickLegalEntity,
    dirtyFields,
    isValidLinkedInUrl,
    pPxits,
    setPexists,
    confirmCreationExisting,
  } = usePeopleModal({
    defaultFormValues: defaultFormValues,
    onSubmittedForm: onSubmittedForm,
  });

  const labelRef = useRef<HTMLLabelElement>(null);

  const labelWidth = useMemo(() => {
    if (labelRef.current) {
      return labelRef.current.offsetWidth;
    }
    return 0;
  }, [labelRef.current]);

  const handleClose = (isEdit: boolean) => {
    if (onClose) onClose(isEdit);
    onCloseModal();
    setLegalEntitiesCounterParties([]);
    setLegalEntities([]);
  };

  useEffect(() => {
    if (defaultFormValues) {
      const lentity = legalEntities.find(
        (f) =>
          ((f as DropDownOptionObjectType).key as LegalEntity).id ===
          defaultFormValues.legal_entity_id,
      );
      if (lentity && onChangeLegalEntity) {
        onChangeLegalEntity(
          (lentity as DropDownOptionObjectType).key as LegalEntity,
        );
      }
    }
  }, [legalEntities, defaultFormValues]);

  const dateClassName = (date: any) => {
    if (isToday(date)) {
      return 'custom-datepicker-day-today';
    }
    if (date > new Date()) {
      return 'custom-datepicker-day-disabled';
    }
    return null;
  };

  return (
    <FormModal
      {...props}
      ishiddenfooter={
        !!(
          !isCan('write', Menu.people) &&
          checkIsEdit(defaultFormValues?.id as number)
        )
      }
      header={
        defaultValues.id !== -1 ? (
          <div className={`ms-3 ${classes.modalHeaderTitleEdit}`}>
            People - {defaultValues.firstname} {defaultValues.lastname}
          </div>
        ) : (
          <div className={`ms-3 ${classes.modalHeaderTitle}`}>New - People</div>
        )
      }
      isDisabledSave={
        // Object.keys(errors).length > 0 || !isDirty || isValueTiersUpdated
        (Object.keys(errors).length > 0 ||
          isEqual(defaultValues, watchFormValue()) ||
          watchFormValue('firstname') === '' ||
          watchFormValue('lastname') === '') &&
        isEqual(
          peopleDefaultCounterParty.map((e) => e.id),
          peopleCounterParty.map((e) => e.id),
        )
      }
      onSubmitForm={onSubmitForm}
      handleClose={() => handleClose(defaultValues.id !== -1)}
    >
      <Row className="pt-4">
        <Col className="px-3">
          <RowContent>
            <Form.Group
              className="d-flex align-items-baseline "
              style={{ paddingTop: '0.5rem' }}
            >
              <Form.Label htmlFor="forFirstName" className={`txt`}>
                Title
              </Form.Label>
              <TitleForm
                onChange={(e) => setFormValue('title', e)}
                value={watchFormValue('title')}
              />
            </Form.Group>
          </RowContent>
          <RowContent>
            <CofarcoFormText
              label="First Name"
              class="w-100 align-items-baseline"
              errors={errors}
              key_form="firstname"
              register={register('firstname', {
                required: true,
              })}
              isRequired
              txtError="Required"
            />
          </RowContent>
          <RowContent>
            <CofarcoFormText
              label="Last Name"
              class="w-100 align-items-baseline"
              errors={errors}
              key_form="lastname"
              register={register('lastname', {
                required: true,
              })}
              isRequired
              txtError="Required"
            />
          </RowContent>
          <RowContent>
            <div className="d-flex align-items-baseline">
              <Form.Label className="txt-with-dropdown">
                Legal Entity
              </Form.Label>
              <div className="d-flex flex-column w-100">
                <SelectDropDown
                  noPadding
                  icon={
                    <FaBuilding
                      onClick={() => {
                        const id = getFormValue('legal_entity_id');
                        if (id && onClickLegalEntity) {
                          getLegaleEntitiesByIdQuery({
                            id: id.toString(),
                          }).then((res) => {
                            if (res.data) {
                              onClickLegalEntity(res.data.legalentity);
                            }
                          });
                        }
                      }}
                      style={{
                        fontSize: 22,
                        cursor: 'pointer',
                        color:
                          watchFormValue('legal_entity_name') !== ''
                            ? '#6d1206'
                            : '',
                      }}
                    />
                  }
                  modalId="modal-people"
                  preValue={getFormValue('legal_entity_name')}
                  loading={isLeagEntityLoading}
                  onSearch={(d, e) => searchLegalEntity(e, d)}
                  onSelectOption={(e) => {
                    if (e) {
                      setFormValue(
                        'legal_entity_id',
                        (e as any).key.id as number,
                        {
                          shouldDirty: true,
                        },
                      );
                      setFormValue(
                        'legal_entity_name',
                        (e as DropDownOptionObjectType).value as string,
                        {
                          shouldDirty: true,
                        },
                      );
                      if (onChangeLegalEntity) {
                        onChangeLegalEntity((e as any).key as LegalEntity);
                      }
                    } else {
                      setFormValue('legal_entity_id', -1, {
                        shouldDirty: true,
                      });
                      setFormValue('legal_entity_name', '', {
                        shouldDirty: true,
                      });
                      setLegalEntitiesCounterParties([]);
                    }
                  }}
                  options={legalEntities}
                />
              </div>
            </div>
          </RowContent>

          <RowContent>
            <Form.Group className="d-flex align-items-baseline pt-2">
              <Form.Label htmlFor="forLastName" className="txt-counter">
                Counterparties
              </Form.Label>
              <div className=" w-100">
                {legalEntitiesCounterParties.map((c, i) => {
                  return (
                    <span key={c.id}>
                      {i !== 0 && i % 2 === 0 && (
                        <div className="mt-2 d-block" />
                      )}
                      <span>
                        <Checkbox
                          style={{ width: '150px' }}
                          onChange={() => handleSelectCounterParty(c)}
                          checked={peopleCounterParty
                            .map((e) => e.id)
                            .includes(c.id)}
                          key={c.id}
                        >
                          {toCamelCase(c.name, 0, ' ')}
                        </Checkbox>
                      </span>
                    </span>
                  );
                })}
              </div>
            </Form.Group>
          </RowContent>
          <RowContent>
            <div className="d-flex align-items-baseline">
              <Form.Label className="txt-with-dropdown">
                Departement{' '}
              </Form.Label>
              <div className="d-flex flex-column w-100 ps-n1">
                <SelectDropDown
                  allowNew
                  noPadding
                  modalId="modal-people"
                  preValue={getFormValue('department')}
                  options={
                    textTerms
                      ? textTerms.term
                          .filter((e) => e.type === 'department')
                          .map((e) => e.text)
                      : []
                  }
                  onSearch={(d, v) => {
                    if (textTerms) {
                      d(
                        textTerms.term
                          .filter(
                            (e) =>
                              e.type === 'department' &&
                              e.text.toLowerCase().includes(v.toLowerCase()),
                          )
                          .map((e) => e.text),
                      );
                    }
                  }}
                  onSelectOption={(e) =>
                    setFormValue('department', e as string, {
                      shouldDirty: true,
                    })
                  }
                />
              </div>
            </div>
          </RowContent>
        </Col>
        <Col>
          <RowContent>
            <div className="d-flex align-items-baseline">
              <Form.Label className="txt-with-dropdown">
                Business Title
              </Form.Label>
              <div className="d-flex flex-column w-100 ps-n1">
                <SelectDropDown
                  noPadding
                  // title="Business Title"
                  allowNew
                  modalId="modal-people"
                  preValue={getFormValue('business_title')}
                  options={
                    textTerms
                      ? textTerms.term
                          .filter((e) => e.type === 'business_title')
                          .map((e) => e.text)
                      : []
                  }
                  onSearch={(d, v) => {
                    if (textTerms) {
                      d(
                        textTerms.term
                          .filter(
                            (e) =>
                              e.type === 'business_title' &&
                              e.text.toLowerCase().includes(v.toLowerCase()),
                          )
                          .map((e) => e.text),
                      );
                    }
                  }}
                  onSelectOption={(e) =>
                    setFormValue('business_title', e as string, {
                      shouldDirty: true,
                    })
                  }
                />
              </div>
            </div>
          </RowContent>

          <RowContent>
            <Form.Group
              style={{ marginTop: '.5rem', position: 'relative' }}
              className="d-flex align-items-baseline w-100 relative "
            >
              <Form.Label htmlFor="forLastName" className={`txt`}>
                Know Since
              </Form.Label>
              <div className="d-flex w-100 ">
                <ReactDatePicker
                  className={`form-control picker-select ${classes.datePicker}`}
                  dayClassName={dateClassName}
                  renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) =>
                    HeaderCalendar({
                      date,
                      changeYear,
                      changeMonth,
                      decreaseMonth,
                      increaseMonth,
                      prevMonthButtonDisabled,
                      nextMonthButtonDisabled,
                    })
                  }
                  customInput={
                    <CustomInput
                      className="mx-0"
                      onChange={() => {}}
                      rightIcon={
                        <div className="d-flex">
                          <div>
                            {isExist(
                              watchFormValue('known_since')?.toString(),
                            ) && (
                              <IoClose
                                color="#851001"
                                size={20}
                                className="mt-2"
                                onClick={() => {
                                  setFormValue('known_since', null);
                                }}
                              />
                            )}
                          </div>

                          <div style={{ position: 'relative', marginTop: 8 }}>
                            <span>
                              <CalendarIcon style={{ height: 20, width: 36 }} />
                            </span>
                          </div>
                        </div>
                      }
                    />
                  }
                  autoComplete="off"
                  placeholderText="Select a date"
                  maxDate={new Date()}
                  selected={
                    watchFormValue('known_since')
                      ? moment(watchFormValue('known_since')).toDate()
                      : null
                  }
                  value={
                    watchFormValue('known_since')
                      ? moment(watchFormValue('known_since')).format('MM/YYYY')
                      : undefined
                  }
                  onChange={(e) =>
                    setFormValue('known_since', e ? e : null, {
                      shouldDirty: true,
                    })
                  }
                />
              </div>
            </Form.Group>
          </RowContent>
          <Row>
            <Form.Group className="d-flex align-items-start">
              <Form.Label
                htmlFor="forMoreInfos"
                className="mt-1 my-auto-text-area txt-two-line-textarea"
              >
                Additionnal Information
              </Form.Label>
              <FormControl
                {...register('additional_information')}
                as="textarea"
                rows={6}
                className={`${classes.fromControl}`}
                id="forMoreInfos"
              />
            </Form.Group>
          </Row>
          <RowContent>
            <Form.Group className="d-flex flex-row align-items-baseline mt-4">
              <div className="d-flex flex-row align-items-baseline w-100">
                <Form.Label
                  htmlFor="forLinkeding"
                  className={`txt my-auto`}
                  ref={labelRef}
                >
                  Linkedin Profil
                </Form.Label>
                <FormControl
                  {...register('linkedin_profile', {
                    validate: isValidLinkedInUrl,
                  })}
                  as="input"
                  className={`${classes.fromControl} me-1 `}
                  id="forLinkeding"
                />
              </div>
              <div className="my-auto">
                <FaLinkedin
                  className="mb-1 mx align-self-baseline"
                  onClick={() => {
                    const linkedUrl = getFormValue('linkedin_profile');
                    if (
                      linkedUrl.trim().length > 0 &&
                      validator.isURL(linkedUrl)
                    ) {
                      window.open(linkedUrl, '_blank');
                    }
                  }}
                  style={{
                    fontSize: 22,
                    cursor: 'pointer',
                    color:
                      watchFormValue('linkedin_profile').trim().length > 0
                        ? '#851001'
                        : '#e0e0e0',
                  }}
                />
              </div>
              <FormError
                message={'yes'}
                style={{
                  width: '100%',
                  position: 'absolute',
                  bottom: '-1.25rem',
                  left: '0',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              />
            </Form.Group>
            {errors.linkedin_profile && (
              <FormError
                message="Invalid LinkedIn URL"
                classNameStyle="text-start"
                meesageStyle={{ fontSize: 12 }}
                style={{ marginInlineStart: labelWidth }}
              />
            )}
          </RowContent>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col>
          <EmailPeople
            watchValue={watchFormValue}
            register={register}
            errors={errors}
            setIsValueTiersUpdate={() => {}}
          />
        </Col>
        <Col className={`ps-4 ${classes.withBorder}`}>
          <PhonePeople
            watchValue={watchFormValue}
            register={register}
            setIsValueTiersUpdate={() => {}}
          />
        </Col>
      </Row>

      <ModalUnsavedChange
        showModalUnsaved={isShowModalUnsavedChange}
        handleCancel={() => setIsShowModalUnsavedChange(false)}
        handleLeave={() => onClickLegalEntity(showOtherModaldata, true)}
        dirtiFields={dirtyFields}
      />
      {/*** Creation confirmation if exists*/}
      <ConfirmModal
        show={pPxits}
        handleClose={() => setPexists(false)}
        title="A contact already exists with the same first and last name"
        confirm={confirmCreationExisting}
      >
        <div style={{ fontSize: 20 }}>
          Are you sure you want to add this new contact ?
        </div>
      </ConfirmModal>
    </FormModal>
  );
};

export default PeopleModal;
