import type { ColumnsType, ColumnType } from 'antd/es/table';
import React, { useState } from 'react';
import FilterTab from '../../../../utils/filter-tab';
import { ReactComponent as ArrowDown } from '../../../../assets/img/Arrow-Down-Gray.svg';
import { ReactComponent as ArrowUP } from '../../../../assets/img/Arrow-UP-Gray.svg';
import UseConstante from '../../../../hooks/use-constante';
import {
  amountFormat,
  exportedDropdownValue,
  isCan,
  limitedText,
} from '../../../../utils/helper-function';
import { Menu, TermConst } from '../../../../@types/common-types';
import { EntityParticipation } from '../../../../@types/participation/participation';
import { ReactComponent as Edit } from '../../../../assets/img/Edit.svg';
import { ReactComponent as Delete } from '../../../../assets/img/Delete.svg';

type IProps = {
  handleSorted: (key: string, ruls: 'ASC' | 'DESC') => void;
  participations: Array<EntityParticipation>;
  editParticipation: (id: number) => void;
  handleDeleteParticipation: (id: number) => void;
  mandateType: string;
};
type DataIndex = keyof EntityParticipation;

export default function ColumnParticipation(props: IProps) {
  const [dropDownFilterState, setDropDownFilterState] =
    useState<boolean>(false);
  const [uncheckedId, setUncheckedId] = useState<Array<number>>([]);
  const terms = UseConstante();

  const onChangeFilterColumn = (key: number | undefined) => {
    const found = uncheckedId?.find((uncheckedTitle) => uncheckedTitle === key);
    if (found) {
      setUncheckedId(uncheckedId.filter((t) => t !== key));
    } else {
      setUncheckedId([...uncheckedId, key || 0]);
    }
  };

  const getColumnSearchProps = (
    dataIndex:
      | DataIndex
      | 'participant'
      | 'counterpartyId'
      | 'premiumRate'
      | 'participantReference'
      | 'minimumDeposit'
      | 'cp1.name',
  ): ColumnType<EntityParticipation> => ({
    filterDropdown: ({ close }) =>
      FilterTab<EntityParticipation>({
        columns: columns,
        dataIndex: dataIndex,
        handleSorted: (dataIndex: string, rule: 'ASC' | 'DESC') =>
          props.handleSorted(dataIndex, rule),
        onChangeFilterColumn: (key: number | undefined) =>
          onChangeFilterColumn(key),
        uncheckedId: uncheckedId,
        close: () => close(),
      }),
    align: 'left',

    filterIcon: () => {
      return dropDownFilterState ? (
        <ArrowDown width={12} height={12} />
      ) : (
        <ArrowUP width={12} height={12} />
      );
    },
    onFilterDropdownOpenChange: (visible) => {
      setDropDownFilterState(visible);
    },
    render: (text: string) => <span>{text}</span>,
  });

  const columns: ColumnsType<EntityParticipation> = React.useMemo(
    () =>
      [
        {
          title: 'ID',
          dataIndex: 'number',
          key: 1,
          ...getColumnSearchProps('number'),
          render: (text: string) => <span> {text}</span>,
        },

        {
          title: 'PARTICIPANT',
          dataIndex: 'participant',
          key: 2,
          ...getColumnSearchProps('cp1.name'),
          render: (text: number, rec: Partial<EntityParticipation>) => {
            if (rec?.counterparty) {
              return (
                <span>
                  <b>{rec.counterparty?.name}</b>
                </span>
              );
            }
          },
        },
        {
          title:
            props.mandateType !== 'insurance' ? 'COMMERCIAL NAME' : 'STAMP',
          dataIndex: 'underwriter_legal_entity',
          key: 3,
          width: 35,
          ...getColumnSearchProps('counterpartyId'),
          render: (legEnt: string, rec: Partial<EntityParticipation>) => {
            return (
              <span
                style={{
                  maxWidth: 125,
                }}
              >
                <b>
                  {props.mandateType !== 'insurance'
                    ? limitedText(rec.counterparty?.commercial_name || '', 25)
                    : limitedText(rec.underwriter_legal_entity || '', 25)}
                </b>
              </span>
            );
          },
        },
        {
          title: 'AMOUNT',
          dataIndex: 'amount',
          key: 4,
          ...getColumnSearchProps('amount'),
          render: (text: number) => {
            return <span>{amountFormat(text?.toFixed(2))}</span>;
          },
        },
        {
          title: 'CURRENCY',
          dataIndex: 'currency',
          key: 5,
          ...getColumnSearchProps('currency'),
          render: (text: string) => {
            if (text) {
              return (
                <span>
                  <b>{text?.toUpperCase()}</b>
                </span>
              );
            }
          },
        },
        {
          title: 'STATUS',
          dataIndex: 'status',
          key: 6,
          ...getColumnSearchProps('status'),
          render: (text: string) => {
            if (text) {
              return (
                <span
                  className={`status`}
                  style={{
                    borderColor: text !== 'off_risk' ? '#EB5D57' : '#A5A9A8',
                    color: text !== 'off_risk' ? '#EB5D57' : '#A5A9A8',
                    maxWidth: 125,
                    minWidth: 125,
                  }}
                >
                  {exportedDropdownValue(
                    terms?.getConst(TermConst.participationStatus) || [],
                    text,
                  )}
                </span>
              );
            }
          },
        },
        {
          title: 'PARTICIPANT REFERENCE',
          dataIndex: 'participant_reference',
          key: 7,
          ...getColumnSearchProps('participantReference'),
          render: (text: string) => {
            if (text) {
              return (
                <span>
                  <b>{text}</b>
                </span>
              );
            }
          },
        },
        {
          title: 'MINIMUM DEPOSIT',
          dataIndex: 'minimum_deposit',
          key: 8,
          ...getColumnSearchProps('minimumDeposit'),
          render: (text: string) => {
            if (text) {
              return (
                <span>
                  <b>{text}</b>
                </span>
              );
            }
          },
        },

        {
          title: 'PREMIUM RATE',
          dataIndex: 'premium_rate',
          key: 9,
          ...getColumnSearchProps('premiumRate'),
          render: (text: string) => {
            if (text) {
              return (
                <span>
                  <b>{limitedText(text.toUpperCase() || '', 25)}</b>
                </span>
              );
            }
          },
        },
        {
          title: '',
          dataIndex: 'id',
          key: 10,
          width: 120,
          onCell: () => {
            return {
              onClick: (event: any) => {
                event.stopPropagation();
              },
            };
          },
          hidden:
            !isCan('write', Menu.participation) &&
            !isCan('delete', Menu.participation),
          render: (text: number) => {
            return (
              <>
                {isCan('write', Menu.mandate) && (
                  <span style={{ minWidth: 95 }}>
                    <i
                      className="rounded-icon"
                      onClick={() => props.editParticipation(text)}
                    >
                      <Edit height="30" />
                    </i>
                  </span>
                )}
                {isCan('delete', Menu.mandate) && (
                  <span style={{ minWidth: 55 }}>
                    <i
                      className="rounded-icon"
                      onClick={() => props.handleDeleteParticipation(text)}
                    >
                      <Delete height="30" />
                    </i>
                  </span>
                )}
              </>
            );
          },
        },
      ].filter((el) => !el.hidden),
    [props.participations, props.mandateType, dropDownFilterState, uncheckedId],
  );

  return { columns, uncheckedId };
}
