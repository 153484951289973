import { uniq } from 'lodash';
import { Col, ModalProps, Row } from 'react-bootstrap';
import { Divider, Table, Form as AntForm, Button } from 'antd';
import FormModal from '../../../Modal/FormModal/FormModal';
import classes from './ClientModal.module.scss';
import { ClientFormInputs, ConterParty } from '../../../../@types/conterParty';
import AddGroup from '../../../../assets/img/add.svg';
import useClientModal from './useClientModal';
import SelectDropDown from '../../../common/DropDown/SelectDropDown';
import {
  DropDownOptionObjectType,
  DropDownOptionType,
} from '../../../common/DropDown/DropdownSelect';
import { FaUser } from 'react-icons/fa';
import { useEffect, useMemo, useState } from 'react';
import { People } from '../../../../@types/people';
import KycForm from './Kyc/KycForm';

import {
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import CofarcoFormText from '../../../Form/CofarcoFormText';
import DeleteModal from '../../../Modal/DeleteModal';
import { checkIsEdit, isCan } from '../../../../utils/helper-function';
import { Menu } from '../../../../@types/common-types';
import RowContent from '../../../Form/RowContent';
import ModalUnsavedChange from '../../../common/Modal/ModalUnsavedChange';

type Props = {
  defaultFormValues?: ConterParty | null;
  onSubmittedForm?: () => void;
  onClose?: () => void;
} & ModalProps;

const ClientModal = ({
  defaultFormValues,
  onSubmittedForm,
  onClose,
  ...props
}: Props) => {
  const {
    terms,
    users,
    defaultValues,
    errors,
    searchPeopleCallback,
    onSubmitForm,
    register,
    watchFormValue,
    isInModeEditing,
    setFormValue,
    getFormValue,
    handleClose,
    showPeopleModal,
    mergedLegalEntity,
    formLegalEntity,
    dataSourceLegalEntity,
    peoples,
    addLegalEntity,
    deleteModal,
    closeDeleteLegalEntity,
    deleteLegalEntity,
    isDirty,
    isCompletedForm,
    isShowModalUnsavedChange,
    setIsShowModalUnsavedChange,
    showOtherModaldata
  } = useClientModal({ defaultFormValues, onSubmittedForm });

  const [isLegalEntityEdit, setIsLegalEntityEdit] = useState<boolean>(false);

  const marketKey = watchFormValue('market');
  const id = watchFormValue('id');
  const year = new Date().getFullYear();
  const marketSegment = useMemo(() => {
    return terms.filter(
      (f) =>
        f.type === 'marketCom' && f.dependences && f.dependences === marketKey
    );
  }, [terms, marketKey]);

  useEffect(() => {
    setIsLegalEntityEdit(false);
  }, [defaultFormValues]);

  const hiddenFooter = !!(!isCan('write', Menu.client) && checkIsEdit(id));

  const optionPeople: Array<any> = peoples.map((d) => ({
    key: d,
    value: `${d.firstname} ${d.lastname}`,
  }));

  return (
    <>
      <DeleteModal
        title='Delete Legal Entity'
        show={deleteModal.show}
        groupeName={`${deleteModal?.legal_entity?.name ?? ''}`}
        entityTitle={'Legal Entity List'}
        validDelete={() => deleteLegalEntity(deleteModal.id ?? -1)}
        handleClose={() => closeDeleteLegalEntity()}
      />
      <FormModal
        {...props}
        ishiddenfooter={hiddenFooter}
        header={
          defaultValues.id !== -1 ? (
            <div className='d-flex'>
              <div
                className={`ms-3 ${
                  isLegalEntityEdit
                    ? classes.modalHeaderTitleEditNotActive
                    : classes.modalHeaderTitleEdit
                }`}
                onClick={() => {
                  setIsLegalEntityEdit(false);
                }}
              >
                Client - {defaultValues.name}
              </div>

              <div
                className={`ms-3 ${
                  isLegalEntityEdit
                    ? classes.modalHeaderTitleEdit
                    : classes.modalHeaderTitleEditNotActive
                }`}
                onClick={() => {
                  setIsLegalEntityEdit(true);
                }}
              >
                Legal Entity(ies) List
              </div>
            </div>
          ) : (
            <div className={`ms-3 ${classes.modalHeaderTitle}`}>
              New - Client
            </div>
          )
        }
        handleClose={() => {
          if (onClose) onClose();
          handleClose();
          setIsLegalEntityEdit(false);
        }}
        onSubmitForm={(e) => {
          onSubmitForm(e);
        }}
        isDisabledSave={
          !isDirty ||
          Object.keys(errors).length > 0 ||
          !isCompletedForm ||
          dataSourceLegalEntity.filter((f) => f.legal_entity).length === 0
        }
      >
        {!isLegalEntityEdit && (
          <>
            <Row className='py-4'>
              <Col className='px-3'>
                <RowContent>
                  <CofarcoFormText
                    label='ID'
                    class='w-100 align-items-baseline'
                    errors={errors}
                    key_form='name'
                    register={register('name', {
                      required: true,
                      validate: (value) => value.length > 0 || 'Required',
                    })}
                    isRequired
                    txtError='Required'
                  />
                </RowContent>

                <RowContent>
                  <CofarcoFormText
                    label='Commercial Name'
                    class='w-100 align-items-baseline'
                    errors={errors}
                    key_form='commercial_name'
                    register={register('commercial_name')}
                    txtError='Required'
                  />
                </RowContent>
                <RowContent>
                  <SelectDropDown
                    noPadding
                    dropDownClass='px-0'
                    title='Type'
                    id='activityType'
                    // placeholder="Type"
                    preValue={getFormValue('_type')}
                    onSearch={(d, v) => {
                      d(
                        uniq(
                          terms.filter(
                            (f) =>
                              f.type === 'activityType' &&
                              f.opt % 2 === 1 &&
                              f.value.toLowerCase().includes(v.toLowerCase())
                          )
                        )
                      );
                    }}
                    options={uniq(
                      terms.filter(
                        (f) => f.type === 'activityType' && f.opt % 2 === 1
                      )
                    )}
                    onSelectOption={(e) => {
                      setFormValue(
                        'type',
                        (e as DropDownOptionObjectType).key as string,
                        { shouldDirty: true }
                      );
                      setFormValue(
                        '_type',
                        (e as DropDownOptionObjectType).value as string,
                        { shouldDirty: true }
                      );
                    }}
                  />
                </RowContent>

                <RowContent>
                  <SelectDropDown
                    noPadding
                    dropDownClass='px-0'
                    title='Client Status'
                    id='clientStatus'
                    placeholder=''
                    preValue={getFormValue('_status')}
                    onSearch={(d, v) => {
                      d(
                        uniq(
                          terms.filter(
                            (f) =>
                              f.type === 'clientStatus' &&
                              f.value.toLowerCase().includes(v.toLowerCase())
                          )
                        )
                      );
                    }}
                    options={uniq(
                      terms.filter((f) => f.type === 'clientStatus')
                    )}
                    onSelectOption={(e) => {
                      setFormValue(
                        'informations.clientStatus',
                        (e as DropDownOptionObjectType).key as string,
                        { shouldDirty: true }
                      );
                      setFormValue(
                        '_status',
                        (e as DropDownOptionObjectType).value as string,
                        { shouldDirty: true }
                      );
                    }}
                  />
                </RowContent>

                <RowContent>
                  <SelectDropDown
                    noPadding
                    dropDownClass='px-0'
                    title='Main Contact'
                    id='main_people'
                    placeholder=''
                    preValue={`${
                      peoples.find(
                        (p) => p.id === getFormValue('main_people_id')
                      )?.firstname ?? ''
                    } ${
                      peoples.find(
                        (p) => p.id === getFormValue('main_people_id')
                      )?.lastname ?? ''
                    }`}
                    onSearch={(d, v) => {
                      searchPeopleCallback(
                        dataSourceLegalEntity.map(
                          (e) => e.legal_entity?.id ?? -1
                        ),
                        v,
                        (data) => {
                          d(
                            data.map((d) => ({
                              key: d,
                              value: `${d.firstname} ${d.lastname}`,
                            }))
                          );
                        }
                      );
                    }}
                    options={optionPeople}
                    onSelectOption={(e) => {
                      if (e) {
                        setFormValue(
                          'main_people_id',
                          (e as DropDownOptionObjectType).key.id as number,
                          { shouldDirty: true }
                        );
                        setFormValue(
                          '_main_people',
                          (e as DropDownOptionObjectType).key as People,
                          { shouldDirty: true }
                        );
                      } else {
                        setFormValue('main_people_id', null, {
                          shouldDirty: true,
                        });
                        setFormValue('_main_people', undefined, {
                          shouldDirty: true,
                        });
                      }
                    }}
                    icon={
                      <FaUser
                        style={{
                          fontSize: 22,
                          cursor: 'pointer',
                          color: watchFormValue('main_people_id')
                            ? '#6d1206'
                            : '',
                        }}
                        onClick={() => {
                          const v = getFormValue('main_people_id');
                          if (v) {
                            showPeopleModal(v);
                          }
                        }}
                      />
                    }
                  />
                </RowContent>
              </Col>

              <Col>
                <RowContent>
                  <SelectDropDown
                    noPadding
                    dropDownClass='px-0'
                    title='Most Senior Contact'
                    id='most_senior_people'
                    placeholder=''
                    // preValue={
                    //   (getFormValue('_most_senior_people_id') ?? '') as string
                    // }
                    preValue={`${
                      peoples.find(
                        (p) =>
                          p.id?.toString() ===
                          (getFormValue('most_senior_people_id')?.toString() ??
                            '')
                      )?.firstname ?? ''
                    } ${
                      peoples.find(
                        (p) =>
                          p.id?.toString() ===
                          (getFormValue('most_senior_people_id')?.toString() ||
                            '')
                      )?.lastname ?? ''
                    }`}
                    onSearch={(d, v) => {
                      searchPeopleCallback(
                        dataSourceLegalEntity.map(
                          (e) => e.legal_entity?.id ?? -1
                        ),
                        v,
                        (data) => {
                          d(
                            data.map((d) => ({
                              key: d,
                              value: `${d.firstname} ${d.lastname}`,
                            }))
                          );
                        }
                      );
                    }}
                    options={optionPeople}
                    // options={peoples.map((d) => ({
                    //   key: d,
                    //   value: `${d.firstname} ${d.lastname}`,
                    // }))}
                    onSelectOption={(e) => {
                      if (e) {
                        console.log('onSelectOption', e);
                        setFormValue(
                          'most_senior_people_id',
                          (e as DropDownOptionObjectType).key.id as number,
                          { shouldDirty: true }
                        );
                        setFormValue(
                          '_most_senior_people',
                          (e as DropDownOptionObjectType).key as People,
                          { shouldDirty: true }
                        );
                      } else {
                        setFormValue('most_senior_people_id', null, {
                          shouldDirty: true,
                        });
                        setFormValue('_most_senior_people', undefined, {
                          shouldDirty: true,
                        });
                      }
                    }}
                    icon={
                      <FaUser
                        style={{
                          fontSize: 22,
                          cursor: 'pointer',
                          color: getFormValue('most_senior_people_id')
                            ? '#6d1206'
                            : '',
                        }}
                        onClick={() => {
                          const v = getFormValue('most_senior_people_id');
                          if (v) {
                            showPeopleModal(v);
                          }
                        }}
                      />
                    }
                  />
                </RowContent>

                <RowContent>
                  <SelectDropDown
                    noPadding
                    dropDownClass='px-0'
                    title='Market'
                    id='Market'
                    placeholder=''
                    preValue={getFormValue('_market')}
                    onSearch={(d, v) => {
                      d(
                        uniq(
                          terms.filter(
                            (f) =>
                              f.type === 'market' &&
                              f.value.toLowerCase().includes(v.toLowerCase())
                          )
                        )
                      );
                    }}
                    options={uniq(terms.filter((f) => f.type === 'market'))}
                    onSelectOption={(e) => {
                      setFormValue(
                        'market',
                        (e as DropDownOptionObjectType).key as string,
                        { shouldDirty: true }
                      );
                      if (
                        ((e as DropDownOptionObjectType).key as string) ===
                        'global'
                      ) {
                        setFormValue('market_commodity', 'com_global', {
                          shouldDirty: true,
                        });
                        setFormValue('_market_commodity', 'Global', {
                          shouldDirty: true,
                        });
                      } else {
                        setFormValue('market_commodity', '', {
                          shouldDirty: true,
                        });
                        setFormValue('_market_commodity', '', {
                          shouldDirty: true,
                        });
                      }
                      setFormValue(
                        '_market',
                        (e as DropDownOptionObjectType).value as string,
                        { shouldDirty: true }
                      );
                      setFormValue('market_other', '', { shouldDirty: true });
                    }}
                  />
                </RowContent>

                {watchFormValue('market') === 'other' && (
                  <RowContent>
                    <CofarcoFormText
                      label='Other'
                      class='w-100 align-items-baseline'
                      errors={errors}
                      key_form='market_other'
                      register={{ ...register('market_other') }}
                    />
                  </RowContent>
                )}
                {watchFormValue('market') !== 'other' && (
                  <RowContent>
                    <SelectDropDown
                      noPadding
                      dropDownClass='px-0'
                      title='Market Segment'
                      id='Market Segment'
                      placeholder=''
                      disabled={watchFormValue('market') === 'global'}
                      preValue={
                        watchFormValue('market') === 'global'
                          ? 'Global'
                          : watchFormValue('_market_commodity')
                      }
                      onSearch={(d, v) => {
                        d(
                          uniq(
                            marketSegment.filter((f) =>
                              f.value.toLowerCase().includes(v.toLowerCase())
                            )
                          )
                        );
                      }}
                      options={uniq(marketSegment)}
                      onSelectOption={(e) => {
                        setFormValue(
                          'market_commodity',
                          (e as DropDownOptionObjectType).key as string,
                          { shouldDirty: true }
                        );
                        setFormValue(
                          '_market_commodity',
                          (e as DropDownOptionObjectType).value as string,
                          { shouldDirty: true }
                        );
                      }}
                    />
                  </RowContent>
                )}

                <RowContent>
                  <SelectDropDown
                    noPadding
                    dropDownClass='px-0'
                    title='Onboarding Year'
                    id='client-onboarding-year'
                    preValue={
                      (getFormValue('informations.onboardingYear') ??
                        '') as string
                    }
                    onSearch={(d, v) => {
                      d(
                        uniq(
                          [...Array(28)]
                            .map((i, index) => `${year - index}`)
                            .filter((f) =>
                              f.toLowerCase().includes(v.toLowerCase())
                            )
                        )
                      );
                    }}
                    options={[...Array(28)].map(
                      (i, index) => `${year - index}`
                    )}
                    onSelectOption={(e) =>
                      setFormValue(
                        'informations',
                        {
                          ...getFormValue('informations'),
                          onboardingYear: e as string as unknown as number,
                        },
                        { shouldDirty: true }
                      )
                    }
                  />
                </RowContent>

                <RowContent>
                  <SelectDropDown
                    noPadding
                    dropDownClass='px-0'
                    title='Team'
                    id='team'
                    placeholder=''
                    preValue={getFormValue('_team')}
                    onSearch={(d, v) => {
                      d(
                        uniq(
                          terms.filter(
                            (f) =>
                              f.type === 'staffTeam' &&
                              f.value.toLowerCase().includes(v.toLowerCase())
                          )
                        )
                      );
                    }}
                    options={uniq(terms.filter((f) => f.type === 'staffTeam'))}
                    onSelectOption={(e) => {
                      setFormValue(
                        'informations',
                        {
                          ...getFormValue('informations'),
                          team: (e as DropDownOptionObjectType).key as string,
                        },
                        { shouldDirty: true }
                      );
                      setFormValue(
                        '_team',
                        (e as DropDownOptionObjectType).value as string,
                        { shouldDirty: true }
                      );
                    }}
                  />
                </RowContent>

                <RowContent>
                  <SelectDropDown
                    noPadding
                    dropDownClass='px-0'
                    title='Relationship Manager'
                    id='relationship-manager'
                    placeholder=''
                    preValue={getFormValue('_managerUserFullname')}
                    onSearch={(d, v) => {
                      d(
                        uniq(
                          users
                            .filter((f) =>
                              f.fullname.toLowerCase().includes(v.toLowerCase())
                            )
                            .map(
                              (f) =>
                                ({
                                  ...f,
                                  key: f,
                                  value: `${f.fullname}`,
                                } as DropDownOptionType)
                            )
                        )
                      );
                    }}
                    options={uniq(
                      users.map(
                        (f) =>
                          ({
                            ...f,
                            key: f,
                            value: `${f.fullname}`,
                          } as DropDownOptionType)
                      )
                    )}
                    onSelectOption={(e) => {
                      setFormValue(
                        'informations',
                        {
                          ...getFormValue('informations'),
                          managerUserId: (e as DropDownOptionObjectType).key
                            .people_id as number,
                        },
                        { shouldDirty: true }
                      );
                      setFormValue(
                        '_managerUserFullname',
                        (e as DropDownOptionObjectType).value as string,
                        { shouldDirty: true }
                      );
                    }}
                  />
                </RowContent>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col>
                <KycForm
                  register={
                    register as unknown as UseFormRegister<
                      Pick<ClientFormInputs, 'informations'>
                    >
                  }
                  watchValue={
                    watchFormValue as unknown as UseFormWatch<
                      Pick<ClientFormInputs, 'informations'>
                    >
                  }
                  setValue={
                    setFormValue as unknown as UseFormSetValue<
                      Pick<ClientFormInputs, 'informations'>
                    >
                  }
                  errors={errors}
                />
              </Col>
              <Col className='mt-0'>
                {watchFormValue('id') <= 0 && (
                  <>
                    <div className='font-bold mt-0'>Legal Entities List</div>
                    <div className='content-table mt-4'>
                      <div className='content-table'>
                        <AntForm form={formLegalEntity} component={false}>
                          <Table
                            className='no-background'
                            style={{ backgroundColor: 'transparent' }}
                            rowClassName={classes.antdTable}
                            dataSource={dataSourceLegalEntity}
                            columns={mergedLegalEntity}
                            rowKey='id'
                            pagination={false}
                          />
                        </AntForm>
                      </div>
                      <Button
                        className='btn red mt-3 py-0'
                        disabled={isInModeEditing}
                        onClick={() => addLegalEntity()}
                      >
                        <img src={AddGroup} alt='' className='pr-2' /> Add Legal
                        Entity
                      </Button>
                    </div>
                  </>
                )}
              </Col>
            </Row>
          </>
        )}
        {isLegalEntityEdit && (
          <Row>
            {watchFormValue('id') > 0 && (
              <>
                <div className='content-table mt-4'>
                  <div className='content-table'>
                    <AntForm form={formLegalEntity} component={false}>
                      <Table
                        className='no-background'
                        style={{ backgroundColor: 'transparent' }}
                        rowClassName={classes.antdTable}
                        dataSource={dataSourceLegalEntity}
                        columns={mergedLegalEntity}
                        rowKey='id'
                        pagination={false}
                      />
                    </AntForm>
                  </div>
                  {!hiddenFooter && (
                    <Button
                      className='btn red mt-3 py-0'
                      disabled={isInModeEditing}
                      onClick={() => addLegalEntity()}
                    >
                      <img src={AddGroup} alt='' className='pr-2' /> Add Legal
                      Entity
                    </Button>
                  )}
                </div>
              </>
            )}
          </Row>
        )}
      </FormModal>
      <ModalUnsavedChange
        showModalUnsaved={isShowModalUnsavedChange}
        handleCancel={() => setIsShowModalUnsavedChange(false)}
        handleLeave={() => showPeopleModal(showOtherModaldata , true)}
        // dirtiFields={dirtyFields}
      />
    </>
  );
};

export default ClientModal;
