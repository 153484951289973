import React, { useState } from 'react';
import { Checkbox } from 'antd';
import { Divider } from 'antd';
import { ReactComponent as Column } from '../assets/img/Column.svg';
import { ReactComponent as Up } from '../assets/img/Up.svg';
import { ReactComponent as Down } from '../assets/img/Down.svg';
import { ReactComponent as ArrowDown } from '../assets/img/Arrow-Down-Gray.svg';
import { ReactComponent as ArrowUP } from '../assets/img/Arrow-UP-Gray.svg';
import type { ColumnsType } from 'antd/es/table';

type IProps<T> = {
  handleSorted: (dataIndex: string, ruls: 'ASC' | 'DESC') => void;
  dataIndex: string;
  columns: ColumnsType<T>;
  onChangeFilterColumn: (key: number | undefined) => void;
  uncheckedId: Array<number>;
  close: () => void;
  isDisabledSort?: boolean;
};
export default function FilterTab<T>({
  handleSorted,
  dataIndex,
  columns,
  onChangeFilterColumn,
  uncheckedId,
  close,
  isDisabledSort,
}: IProps<T>) {
  const [isShowFilterColumn, setIsShowFilterColumn] = useState<boolean>(false);
  return (
    <div
      style={{
        padding: '8px',
        border: '1px solid #DBDDDF',
        borderRadius: '8px',
      }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <div>
        <span
          className={isDisabledSort ? 'span-icon-disabled' : 'span-icon'}
          onClick={() => {
            if (!isDisabledSort) {
              handleSorted(dataIndex, 'ASC');
              close();
            }
          }}
        >
          <i>
            <Up />
          </i>
          Sort Ascending
        </span>
        <span
          className={isDisabledSort ? 'span-icon-disabled' : 'span-icon'}
          onClick={() => {
            if (!isDisabledSort) {
              handleSorted(dataIndex, 'DESC');
              close();
            }
          }}
        >
          <i>
            <Down />
          </i>
          Sort Descending
        </span>
      </div>
      <Divider style={{ margin: '10px 0px' }} />
      <div>
        <span
          className='span-icon'
          style={{ position: 'relative' }}
          onClick={() => setIsShowFilterColumn(!isShowFilterColumn)}
        >
          <i className='mr-2'>
            <Column />
          </i>
          Column
          <i style={{ float: 'right' }}>
            {isShowFilterColumn ? <ArrowUP /> : <ArrowDown />}
          </i>
        </span>
        <div className={`${isShowFilterColumn ? 'd-bloc' : 'd-none'}`}>
          {columns.map(
            (col, index) =>
              index < columns.length - 1 && (
                <span className='span-icon' key={index}>
                  <Checkbox
                    onClick={() => {
                      onChangeFilterColumn((col.key as number) || 0);
                      close();
                    }}
                    checked={
                      uncheckedId?.find((c) => c === col.key) ? false : true
                    }
                    disabled={
                      uncheckedId.length === columns.length - 2 &&
                      !uncheckedId?.find((c) => c === col.key)
                    }
                  >
                    {col.title?.toString()}
                  </Checkbox>
                </span>
              )
          )}
        </div>
      </div>
    </div>
  );
}
