import { ReactNode } from "react";
import { ContextDeal } from "./components/Deals/DealsContent";
import { ContextCounterparty } from "./components/CounterParties/CounterPartiesContent";
import { ContextEnquiry } from "./components/Enquiries/EnquiriesContent";
import { ContextHistory } from "./components/History/HistoryContent";
import { ContextParticipation } from "./components/Participations/ParticipationContent";
import { ContextPipe } from "./components/Pipe/PipeContent";
import { ContextMandate } from "./components/Mandates/MandatesContent";
import { ContextDataBase } from "./components/Database/DatabaseContent";
import { ContextSegregated } from "./components/SegregatedAccounts/SegregatedContent";
import { ContextIndication } from "./components/Indication/IndicationTableContent";

const QuickProviderSearch = ({ children }: Props) => {
  return (
    <ContextDeal.Provider value={{ txt: "" }}>
      <ContextCounterparty.Provider value={{ txt: "" }}>
        <ContextEnquiry.Provider value={{ txt: "" }}>
          <ContextHistory.Provider value={{ txt: "" }}>
            <ContextIndication.Provider value={{ txt: "" }}>
              <ContextParticipation.Provider value={{ txt: "" }}>
                <ContextPipe.Provider value={{ txt: "" }}>
                  <ContextMandate.Provider value={{ txt: "" }}>
                    <ContextDataBase.Provider value={{ txt: "" }}>
                      <ContextSegregated.Provider value={{ txt: "" }}>
                        {children}
                      </ContextSegregated.Provider>
                    </ContextDataBase.Provider>
                  </ContextMandate.Provider>
                </ContextPipe.Provider>
              </ContextParticipation.Provider>
            </ContextIndication.Provider>
          </ContextHistory.Provider>
        </ContextEnquiry.Provider>
      </ContextCounterparty.Provider>
    </ContextDeal.Provider>
  );
};

export default QuickProviderSearch;

type Props = {
  children: ReactNode;
};
