import React, { useState } from 'react';
import { Button, Form as AntForm, Input, Select, Table, Form } from 'antd';
import AddGroup from '../../assets/img/add.svg';
import customSelectData from './custom-form';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { checkIban } from '../../redux/api/axiosBaseQuery';
import { url } from '../../redux/api/url';
import NotifModalPreventLoading from '../Modal/NotifModalPreventLoading';
import classes from '../CofarcoTable/CofarcoTable.module.scss';
import HeaderCalendar from '../Calendar/HeaderCalendar';
import { isToday } from '../../utils/helper-function';

const IBAN = require('iban');

type IProps<T> = {
  dataSource: Array<T>;
  column: Array<any>;
  add: () => void;
  mergedColumns: any;
  form: any;
  txtAdd: string;
  entityId?: string | number;
  isEditing: boolean;
  hiddenBtn: boolean;
};

export interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType:
    | 'number'
    | 'text'
    | 'currency'
    | 'agency'
    | 'outlook'
    | 'ratting'
    | 'date'
    | 'iban'
    | 'bic';
  record: any;
  index: number;
  children: React.ReactNode;
  entityId?: number | string;
}

export default function Index<T extends object>(props: IProps<T>) {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [isErrorIban, setIsErrorIban] = useState<any>({
    show: false,
    iban: '',
    legName: '',
  });

  const dateClassName = (date: any) => {
    if (isToday(date)) {
      return 'custom-datepicker-day-today';
    }
    if (date > new Date()) {
      return 'custom-datepicker-day-disabled';
    }
    return null;
  };

  const agency = Form.useWatch('agency', props.form);

  const validateIban = async (rule: any, value: any) => {
    if (!IBAN.isValid(value)) {
      return Promise.reject(`Iban ${value}  not valid`);
    } else {
      try {
        const ibans: any = await checkIban(url.legalEntity.check_iban, value);
        const isInMyEntity = ibans.bankaccount.find(
          (el: any) => el.legal_entity_id === props.entityId,
        );
        if (ibans.bankaccount?.[0]?.legal_entity && !isInMyEntity) {
          setIsErrorIban({
            show: true,
            iban: value,
            legName: ibans.bankaccount[0].legal_entity.name,
          });

          return Promise.reject(
            `IBAN ${value} is already use by another Legal Entity`,
          );
        }
        return Promise.resolve();
      } catch (error) {
        return Promise.reject('Error checking iban');
      }
    }
  };

  const validationForm = (inputType: string, title: string) => {
    if (inputType === 'iban') {
      return [
        {
          required: true,
          validator: validateIban,
        },
      ];
    }
    if (inputType === 'bic') {
      return [
        {
          required: false,
          message: `The maximum length for this field is 12`,
          validator(rule: any, value: any) {
            if (value?.toString().length > 12) {
              return Promise.reject('The maximum length for this field is 12');
            }
            return Promise.resolve();
          },
        },
      ];
    }
    if (
      inputType === 'currency' ||
      inputType === 'ratting' ||
      inputType === 'outlook' ||
      inputType === 'date'
    ) {
      return [
        {
          required: true,
        },
      ];
    } else {
      return [
        {
          required: false,
          message: `Please Input ${title}!`,
        },
      ];
    }
  };

  const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    children,
    ...restProps
  }) => {
    const selectType =
      inputType === 'currency' ||
      inputType === 'agency' ||
      inputType === 'outlook' ||
      inputType === 'ratting';

    const inputNode =
      inputType === 'date' ? (
        <DatePicker
          onChange={(date: Date) => {
            setStartDate(date);
          }}
          dayClassName={dateClassName}
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) =>
            HeaderCalendar({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            })
          }
          selected={startDate}
          dateFormat="dd/MM/yyyy"
          className="form-control picker-select"
          name="date"
          maxDate={moment().toDate()}
          popperContainer={(props: any) => {
            return <div className="custom-datepicker-fixed" {...props} />;
          }}
          popperPlacement="top-start"
          required={true}
        />
      ) : selectType ? (
        <Select
          options={customSelectData(inputType, agency)}
          style={{ width: '170px' }}
        />
      ) : (
        <Input />
      );

    return (
      <td {...restProps}>
        {editing ? (
          <AntForm.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={validationForm(inputType, title)}
          >
            {inputNode}
          </AntForm.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  return (
    <>
      <div className="content-table">
        <NotifModalPreventLoading
          title="IBAN already use"
          body={`IBAN ${isErrorIban.iban} is already use by another Legal Entity ${isErrorIban.legName} And cannot be use.`}
          show={isErrorIban.show}
          closeModal={() =>
            setIsErrorIban({
              show: false,
              iban: '',
              legName: '',
            })
          }
        />
        <AntForm form={props.form} component={false}>
          <Table
            dataSource={props.dataSource}
            columns={props.mergedColumns}
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            rowClassName={(_, index) =>
              `${index % 2 === 1 ? classes.tablelight : ''} ${classes.tablerow}`
            }
            rowKey="id"
            pagination={false}
          />
        </AntForm>
      </div>
      {props?.hiddenBtn && (
        <Button
          className={`btn red mt-3 py-0 `}
          disabled={props.isEditing}
          onClick={() => props.add()}
        >
          <img src={AddGroup} alt="" className="pr-2" />
          {props.txtAdd}
        </Button>
      )}
    </>
  );
}
