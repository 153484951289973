import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  EntityCountry,
  EntityGroupe,
  InputFieldsGroup,
} from '../../../../@types/group/types';
import {
  useCreateGroupeMutation,
  useGetCountryQuery,
  useGetLegalentityInGroupeMutation,
  useUpdateGroupeMutation,
} from '../../../../redux/api/ws/databases/groups';
import {
  checkIsEdit,
  exportDateUi,
  exportDateWs,
  handleRegion,
  isExist,
  nullingEmptyValues,
  setDefaultValues,
} from '../../../../utils/helper-function';
import Flag from 'react-world-flags';
import { Dispatch, SetStateAction } from 'react';
import { DropDownOptionType } from '../../../common/DropDown/DropdownSelect';
import { mockRugular } from '../../../../utils/mock-regulator';
import { mockEquitySize } from '../../../../utils/mock-equity-size';
import { People } from '../../../../@types/people';
import UseFetchPeople from '../../../../hooks/UseFetchPeople';
import { useAppDispatch } from '../../../../hooks/redux';
import { updateModalAction } from '../../../../redux/slice/modalSlice';
import { updateNotificationMessage } from '../../../../redux/slice/notificationSlice';
import { useListTextQuery } from '../../../../redux/api/ws/terms/get';
import { isEmpty } from 'lodash';

type IProps = {
  defaultFormValues: InputFieldsGroup | null | undefined;
  closingModal: (group: EntityGroupe | null) => void;
};
export default function UseModalGroup(props: IProps) {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, dirtyFields },
    clearErrors,
    setError,
    control,
    watch,

    getValues,
    setValue,
    reset,
  } = useForm<InputFieldsGroup>({
    mode: 'onChange',
    //defaultValues: props.defaultFormValues || {},
  });

  const providerGroup = UseFetchPeople();
  const [confirModal, setConfirModal] = useState<boolean>(false);
  const [isCompletedForm, setIsCompletedForm] = useState(false);
  const [createdDataSource, setCreatedDataSource] = useState<EntityGroupe>();
  const [activeMenu, setActiveMenu] = useState<number>(1);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [peopleSelected, setPeopleSelected] = useState<Array<People>>([]);
  const dispatch = useAppDispatch();

  const [isShowModalUnsavedChange, setIsShowModalUnsavedChange] =
    useState<boolean>(false);
  const [showOtherModaldata, setShowOtherModal] = useState<any>();

  const originCountryId = watch('origin_country_name');
  const regulatorName = watch('regulator_name')?.toString();
  const equitySize = watch('equity_size')?.toString();

  const [editPeople, setEditPeople] = useState<People | undefined | null>();
  const [showModalFormPeople, setShowModalFormPeople] =
    useState<boolean>(false);

  const [
    getSelectedLegalEntity,
    {
      data: selectedLegalEntity,

      isLoading: isLoadingLegalEntity,
    },
  ] = useGetLegalentityInGroupeMutation();

  const { data: responseCountry } = useGetCountryQuery();

  const { data: textTerms, isLoading, refetch } = useListTextQuery(undefined);

  const countries: Array<EntityCountry> = responseCountry?.country || [];

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
    },

    {
      title: 'COUNTRY',
      dataIndex: 'country_id',
      key: 'country_id',
      render: (text: string) => {
        const region = handleRegion(countries, text);
        return (
          <span>
            {region && (
              <i className="rounded-icon">
                <Flag code={region.code} height="15" />
              </i>
            )}
            {region ? region?.name : ''}
          </span>
        );
      },
    },
    {
      title: 'TYPE',
      dataIndex: 'type',
      key: 'type',
    },
  ];

  const modalTitle = isExist(props.defaultFormValues?.id)
    ? 'Group - ' + props.defaultFormValues?.name
    : ' New - Group';
  const [
    updateGroupe,
    { isLoading: isLoadingUpdateGroupe, isSuccess: isSuccessUpdateGroup },
  ] = useUpdateGroupeMutation();

  const [
    createGroupe,
    { isLoading: isLoadingCreateGroupe, isSuccess: isSuccessCreateGroup },
  ] = useCreateGroupeMutation();

  const showLegalEntityList = async () => {
    if (props.defaultFormValues?.id) {
      await getSelectedLegalEntity({
        page: 1,
        start: 0,
        limit: 25,
        selectedGroupe: props.defaultFormValues.id,
      });
    }
  };

  const submitGroup = async (data: InputFieldsGroup) => {
    if (data?.origin_country_name) {
      delete data.origin_country_name;
    }
    const params = {
      ...data,
      id: data.id ? data.id : -1,
      equity_size: data.equity_size,
      kyc_validation_date: exportDateWs(data.kyc_validation_date),
      next_review_date: exportDateWs(data.next_review_date),
      origin_country_id: data.origin_country_id,
      regulator_name: data.regulator_name,
      ultimate_beneficial_owner_1: data.ultimate_beneficial_owner_1 || '',
      ultimate_beneficial_owner_2: data.ultimate_beneficial_owner_2 || '',
      ultimate_beneficial_owner_3: data.ultimate_beneficial_owner_3 || '',
      ultimate_beneficial_owner_4: data.ultimate_beneficial_owner_4 || '',
    };

    const isUpdated = checkIsEdit(data.id);

    if (isUpdated) {
      try {
        const response = await updateGroupe(params).unwrap();
        if (response && response.success) {
          dispatch(
            updateNotificationMessage({
              title: 'Update Group',
              body: `Group ${response.group?.name} has been updated !`,
              show: true,
            }),
          );
        }
        props.closingModal(null);
        resetForm();
      } catch (error: any) {
        console.log(error);
      }
    } else {
      try {
        const respose = await createGroupe(nullingEmptyValues(params)).unwrap();
        if (respose && respose.success) {
          dispatch(
            updateNotificationMessage({
              title: 'Add Group',
              body: `Group ${respose.group?.name} has been added !`,
              show: true,
            }),
          );

          setCreatedDataSource(respose.group);
        }
        props.closingModal(respose.group);
        resetForm();
      } catch (error: any) {
        console.log(error);
      }
    }
    refetch();
  };

  React.useEffect(() => {
    const subscription = watch((value) => {
      const isCompletedRequired =
        value.name && value.ownership && value.origin_country_id;

      const isRequiredInceptionDate = isExist(value.kyc_rate) ? true : false;

      let isCompletedKYVvettingDate: boolean = false;
      if (isRequiredInceptionDate) {
        isCompletedKYVvettingDate = isExist(value.kyc_validation_date as any)
          ? true
          : false;
        if (isExist(value.kyc_validation_date as any)) {
          clearErrors('kyc_validation_date');
        }
      } else {
        isCompletedKYVvettingDate = true;
        clearErrors('kyc_validation_date');
      }

      const isValidateForm = isCompletedKYVvettingDate && isCompletedRequired;

      setIsCompletedForm(isValidateForm ? true : false);
      setIsEdit(isExist(value.id) && value.id !== -1 ? true : false);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  React.useEffect(() => {
    if (props.defaultFormValues) {
      const selectedGroup = props.defaultFormValues;
      providerGroup.fetchPeople(
        selectedGroup as EntityGroupe,
        (defaultValue, allPeople) => {
          setPeopleSelected(allPeople);
          reset({
            ...defaultValue,
            next_review_date: exportDateUi(
              selectedGroup.next_review_date as string,
            ),

            kyc_validation_date: exportDateUi(
              selectedGroup.kyc_validation_date as string,
            ),
            regulated: selectedGroup.regulated ? true : false,
          });
        },
      );
    }
  }, [props.defaultFormValues]);

  const country = React.useCallback(() => {
    return (
      responseCountry?.country.map((el) => ({
        key: el.key,
        value: el.name,
      })) || []
    );
  }, [responseCountry]);

  const resetForm = () => {
    const defaultValue = setDefaultValues<InputFieldsGroup>(watch());
    reset(defaultValue);
    setIsEdit(false);
  };

  const handleFormModalSubmit = () => {
    setShowModalFormPeople(false);
    setEditPeople(undefined);
  };

  const showPeople = (peopleId: number, noCheck?: boolean) => {
    if (peopleId) {
      const people = peopleSelected?.find(
        (el) => el.id?.toString() === peopleId?.toString(),
      );
      if (people) {
        if (!isEmpty(dirtyFields) && !noCheck && isEdit) {
          setShowOtherModal(peopleId);
          setIsShowModalUnsavedChange(true);
        } else {
          dispatch(
            updateModalAction({ data: people, isAdd: true, type: 'people' }),
          );
          setIsShowModalUnsavedChange(false);
          setShowOtherModal(undefined);
          isEdit && resetForm();
        }
      }
    }
  };

  const handleChangeToogle = (id: number) => {
    if (id === 2) {
      showLegalEntityList();
    }
    setActiveMenu(id);
  };

  const handleSelectedPeople = (p: People | undefined) => {
    p && setPeopleSelected([...peopleSelected, p]);
  };

  const regulators = textTerms?.term
    .filter((e) => e.type === 'regulator_name')
    .map((e) => e.text);

  const runSearch = React.useCallback(
    async (
      value: string,
      key: string,
      dipatch?: Dispatch<SetStateAction<DropDownOptionType[]>>,
    ) => {
      if (key === 'origin_country_id') {
        const res = country()?.filter((word) =>
          word.value?.toLowerCase().includes(value?.toLowerCase()),
        );
        if (dipatch) dipatch(res);
      }

      if (key === 'regulator_name') {
        const res =
          regulators?.filter((word) =>
            word?.toLowerCase().includes(value?.toLowerCase()),
          ) ?? [];
        if (dipatch) dipatch(res);
      }

      if (key === 'equity_size') {
        const res = mockEquitySize?.filter((word) =>
          word.value.includes(value),
        );
        if (dipatch) dipatch(res);
      }
    },
    [country, mockRugular, mockEquitySize, regulators],
  );

  return {
    submitGroup,
    isSuccessUpdateGroup,
    isSuccessCreateGroup,
    isLoadingUpdateGroupe,
    isLoadingCreateGroupe,
    register,
    handleSubmit,
    errors,
    control,
    watch,
    getValues,
    setValue,
    isCompletedForm,
    reset,
    createdDataSource,
    columns,
    activeMenu,
    originCountryId,
    regulatorName,
    equitySize,
    setActiveMenu,
    runSearch,
    country,
    modalTitle,
    isEdit,
    resetForm,
    confirModal,
    setConfirModal,
    editPeople,
    showModalFormPeople,
    setShowModalFormPeople,
    handleFormModalSubmit,
    showPeople,
    setEditPeople,
    showLegalEntityList,
    selectedLegalEntity,
    handleChangeToogle,
    isLoadingLegalEntity,
    handleSelectedPeople,
    isDirty,
    clearErrors,
    setError,
    regulators,
    isRgNameLoading: isLoading,
    isShowModalUnsavedChange,
    setIsShowModalUnsavedChange,
    showOtherModaldata,
    dirtyFields,
  };
}
