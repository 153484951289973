import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { ReactComponent as Delete } from '../../../../assets/img/Delete.svg';
import { ReactComponent as Eye } from '../../../../assets/img/Eye.svg';

import { useListUserQuery } from '../../../../redux/api/ws/terms/get';
import { EntityAttachementEnquiry } from '../../../../@types/enquiries';
import { RootState } from '../../../../redux/store';
import { useSelector } from 'react-redux';
import { API_URL } from '../../../../data/config';
import { formateSize } from '../../../../utils/helper-function';

type IProps = {
  handleDeleteAtt: (item: EntityAttachementEnquiry) => void;
};
export default function ColumnUpload(props: IProps) {
  const { data: users } = useListUserQuery(null);
  const auth = useSelector((state: RootState) => state.auth);

  const exportPdf = (id: number) => {
    const token = auth.token;
    if (id) {
      const subUrl = API_URL + 'attachment/' + id + '?token=' + token;
      window.open(subUrl, '_blank');
    }
  };
  const columnAttachments: ColumnsType<EntityAttachementEnquiry> = [
    {
      title: 'ATTACHMENT(S)',
      dataIndex: 'filename',
      key: 'filename',
      render: (text) => {
        return (
          <div>
            <b> {text}</b>
          </div>
        );
      },
    },

    {
      title: 'SIZE',
      dataIndex: 'size',
      key: 'size',
      render: (text) => {
        return <div style={{ fontWeight: 'bolder' }}>{formateSize(text)}</div>;
      },
    },
    {
      title: 'UPLOAD DATE',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (text) => {
        return (
          <div>
            <b>{moment(text).format('DD/MM/YYYY')}</b>{' '}
          </div>
        );
      },
    },
    {
      title: 'UPLOADED BY',
      dataIndex: 'create_user_id',
      key: 'create_user_id',
      render: (text) => {
        return (
          <div>
            <b>{(users?.user ?? []).find((u) => u.id === text)?.fullname}</b>
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'action',
      width: 120,
      render(id, record) {
        return (
          <>
            <span>
              <i
                className="rounded-icon"
                onClick={() => exportPdf(record?.id || id)}
              >
                <Eye height="30" />
              </i>
            </span>

            <span>
              <i
                className="rounded-icon"
                onClick={() => props.handleDeleteAtt(record)}
              >
                <Delete height="30" />
              </i>
            </span>
          </>
        );
      },
    },
  ];

  return { columnAttachments };
}
