import { useEffect, useState } from "react";
import classes from "./TitleForm.module.scss";
type Props = {
  onChange?: (e: string) => void;
  value?: string;
};
const TitleForm = ({ value, onChange }: Props) => {
  const handleChange = (e: string) => {
    setSelected(e);
    if (onChange) onChange(e);
  };
  const [selected, setSelected] = useState<string | undefined>(value);

  useEffect(() => {
    setSelected(value);
  }, [value]);
  
  return (
    <div className="d-flex w-100">
      <div
        onClick={() => handleChange("Mr")}
        className={`${classes.title} ${
          selected === "Mr" ? classes.titleChoose : classes.title
        }`}
      >
        Mr
      </div>
      <div
        onClick={() => handleChange("Mrs")}
        className={` ${
          selected === "Mrs" ? classes.titleChoose : classes.title
        }`}
      >
        Mrs
      </div>
    </div>
  );
};

export default TitleForm;
