import { ReactComponent as ArrowLeft } from "../../assets/img/ArrowLeft.svg";
import { ReactComponent as ArrowRigth } from "../../assets/img/ArrowRigth.svg";
import { getMonth, months, years } from "../../utils/helper-function";

export default function HeaderCalendar({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}: any) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <button
        disabled={prevMonthButtonDisabled}
        className="roundedBtn mx-1"
        onClick={(e) => {
          e.preventDefault();
          decreaseMonth();
        }}
      >
        <ArrowLeft />
      </button>
      <select
        value={date.getFullYear()}
        onChange={({ target: { value } }: any) => changeYear(value)}
        className="mx-1 form-control"
      >
        {years.map((option: any) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <select
        value={months[getMonth(date)]}
        onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
        className="mx-1 form-control"
      >
        {months.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <button
        onClick={(e) => {
          e.preventDefault();
          increaseMonth();
        }}
        disabled={nextMonthButtonDisabled}
        className="roundedBtn mx-1"
      >
        <ArrowRigth />
      </button>
    </div>
  );
}
