const toCamelCase = (
  str: string,
  index: number = 1,
  join: string = ''
): string => {
  const parts = str.split(/[^a-zA-Z0-9]/);
  for (let i = index; i < parts.length; i++) {
    parts[i] = parts[i].charAt(0).toUpperCase() + parts[i].slice(1);
  }
  return parts.join(join);
};

export default toCamelCase;
