import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
  checkIsEdit,
  isCan,
  isExist,
  validURL,
} from '../../../../utils/helper-function';
import Table from '../../../CofarcoTable/Table';
import { Checkbox } from 'antd';
import 'react-datepicker/dist/react-datepicker.css';
import { EntityGroupe, InputFieldsGroup } from '../../../../@types/group/types';
import { Controller } from 'react-hook-form';
import { mockEquitySize } from '../../../../utils/mock-equity-size';
import CofarcoFormText from '../../../Form/CofarcoFormText';
import OwnerShip from './OwnerShip/OwnerShip';
import UltimateBeneficial from './UltimateBeneficial/UltimateBeneficial';
import Kyc from './Kyc/Kyc';
import SelectDropDown from '../../../common/DropDown/SelectDropDown';
import { DropDownOptionObjectType } from '../../../common/DropDown/DropdownSelect';
import UseModalGroup from './UseModalGroup';
import HeaderTab from '../../../HeaderTab/HeaderTab';
import FormModal from '../../../Modal/FormModal/FormModal';
import { People } from '../../../../@types/people';
import { Menu } from '../../../../@types/common-types';
import RowContent from '../../../Form/RowContent';
import ModalUnsavedChange from '../../../common/Modal/ModalUnsavedChange';
import { ReactComponent as ArrowRigth } from '../../../../assets/img/ArrowRigth.svg';

type IProps = {
  show: boolean;
  handleClose: (group: EntityGroupe | null) => void;
  defaultFormValues: InputFieldsGroup | null | undefined;
};

const ModalGroup = (props: IProps) => {

  const provider = UseModalGroup({
    defaultFormValues: props.defaultFormValues,
    closingModal: props.handleClose,
  });

  const id = provider.watch('id');
  const origin_country_id = provider.watch('origin_country_id');

  const goToWebsite = (website: string) => website && website.trim() !== '' && window.open(website, '_blank');

  return (
    <>
      <FormModal
        show={props.show}
        header={
          <HeaderTab
            isEdit={provider.isEdit}
            title={provider.modalTitle}
            activeMenu={provider.activeMenu}
            setActiveMenu={(p: number) => provider.handleChangeToogle(p)}
            titleRigth='Legal Entity(ies) List'
          />
        }
        handleClose={() => {
          provider.resetForm();
          provider.setActiveMenu(1);
          props.handleClose(null);
        }}
        isDisabledSave={
          !provider.isDirty ||
          provider.isLoadingCreateGroupe ||
          !provider.isCompletedForm
        }
        isLoading={provider.isLoadingCreateGroupe}
        onSubmitForm={provider.handleSubmit(provider.submitGroup)}
        ishiddenfooter={
          !!(
            provider.activeMenu === 2 ||
            (!isCan('write', Menu.deal) && checkIsEdit(id))
          )
        }
        children={
          <>
            {provider.activeMenu === 1 && (
              <Container className='mt-4'>
                <Row>
                  <Col>
                    <RowContent>
                      <CofarcoFormText
                        label='Name'
                        class='w-100 align-items-baseline'
                        errors={provider.errors}
                        key_form='name'
                        register={provider.register('name', {
                          required: true,
                        })}
                        isRequired
                        txtError='Required'
                      />
                    </RowContent>
                    <RowContent>
                      <OwnerShip
                        paddingTop='0.7rem'
                        errors={provider.errors}
                        register={provider.register('ownership', {
                          required: true,
                        })}
                      />
                    </RowContent>
                    <RowContent>
                      <div className='d-flex align-items-baseline'>
                        <Form.Label className='txt-with-dropdown'>
                          Origin Country{' '}
                          <span className='span-required'>*</span>
                        </Form.Label>
                        <div className='d-flex flex-column w-100'>
                          <SelectDropDown
                            modalId='modal-group'
                            error={provider.errors?.origin_country_id?.message}
                            preValue={provider.originCountryId}
                            {...provider.register('origin_country_id', {
                              required: true,
                            })}
                            loading={false}
                            onSearch={(d, e) =>
                              provider.runSearch(e, 'origin_country_id', d)
                            }
                            onSelectOption={(e) => {
                              provider.setValue(
                                'origin_country_name',
                                (e as DropDownOptionObjectType).value,
                                { shouldDirty: true }
                              );
                              provider.setValue(
                                'origin_country_id',
                                (e as DropDownOptionObjectType).key,
                                { shouldDirty: true }
                              );
                              if (e) {
                                provider.clearErrors('origin_country_id');
                                return;
                              }
                              provider.setError('origin_country_id', {
                                message: 'Origin Country is required',
                              });
                            }}
                            options={provider.country()}
                            onBlur={() => {
                              if (!isExist(origin_country_id as string)) {
                                provider.setError('origin_country_id', {
                                  message: 'Origin Country is required',
                                });
                              }
                            }}
                          />
                        </div>
                      </div>
                    </RowContent>
                    <RowContent>
                      <div
                        className='d-flex align-items-baseline'
                        style={{ paddingTop: '0.9rem' }}
                      >
                        <Form.Label
                          className='radio-echange'
                          htmlFor='exchange'
                        >
                          Exchange
                        </Form.Label>
                        <div className='ml-2 ml-spacer'>
                          <Controller
                            name='exchange'
                            control={provider.control}
                            render={({ field }) => (
                              <Checkbox
                                {...field}
                                checked={field.value}
                                id='exchange'
                                onChange={(e) =>
                                  field.onChange(e.target.checked)
                                }
                              />
                            )}
                          />
                        </div>
                      </div>
                    </RowContent>

                    <RowContent>
                      <CofarcoFormText
                        label='Exchange Code'
                        class='w-100 align-items-baseline'
                        errors={provider.errors}
                        key_form='exchange_code'
                        register={provider.register('exchange_code')}
                      />
                    </RowContent>
                  </Col>

                  <Col>
                    <RowContent>
                      <div
                        className='d-flex align-items-baseline'
                        style={{ paddingTop: '.9rem' }}
                      >
                        <Form.Label className='radio-echange'>
                          Regulated
                        </Form.Label>
                        <div className='ml-spacer'>
                          <Controller
                            name='regulated'
                            control={provider.control}
                            defaultValue={false}
                            render={({ field }) => (
                              <Checkbox
                                {...provider.register('regulated')}
                                {...field}
                                checked={field.value}
                                onChange={(e) =>
                                  field.onChange(e.target.checked)
                                }
                              />
                            )}
                          />
                        </div>
                      </div>
                    </RowContent>
                    <RowContent>
                      <div className='regulator d-flex align-items-baseline'>
                        <Form.Label className='txt-with-dropdown'>
                          Regulator Name
                        </Form.Label>
                        <div className='d-flex flex-column w-100'>
                          <SelectDropDown
                            allowNew
                            modalId='modal-people'
                            preValue={provider.regulatorName}
                            withSafariKeysContacts={true}
                            loading={provider.isRgNameLoading}
                            onSearch={(d, e) => provider.runSearch(e, 'regulator_name', d)}
                            onSelectOption={(e) => {
                              if (typeof e === "string") {
                                provider.setValue(
                                  'regulator_name',
                                  e,
                                  { shouldDirty: true }
                                );
                              } else {
                                provider.setValue(
                                  'regulator_name',
                                  (e as DropDownOptionObjectType).value,
                                  { shouldDirty: true }
                                );
                              }

                            }}
                            options={provider.regulators}
                          />
                        </div>
                      </div>
                    </RowContent>
                    <RowContent>
                      <div className='equity d-flex align-items-baseline'>
                        <Form.Label className='txt-with-dropdown'>
                          Equity Size
                        </Form.Label>
                        <div className='d-flex flex-column w-100'>
                          <SelectDropDown
                            noSearch
                            modalId='modal-people'
                            preValue={provider.equitySize}
                            loading={false}
                            onSearch={(d, e) =>
                              provider.runSearch(e, 'equity_size', d)
                            }
                            onSelectOption={(e) => {
                              provider.setValue(
                                'equity_size',
                                (e as DropDownOptionObjectType).value,
                                { shouldDirty: true }
                              );
                            }}
                            options={mockEquitySize}
                          />
                        </div>
                      </div>
                    </RowContent>
                    <RowContent>
                      <div className='d-flex align-items-center w-100  mr-1 pt-2'>
                        <CofarcoFormText
                          label='Website'
                          class='w-100 align-items-baseline'
                          errors={provider.errors}
                          key_form='website'
                          register={provider.register('website', {
                            validate: (txt: string) => {
                              if (txt) {
                                return validURL(txt);
                              }
                              return true;
                            },
                          })}
                          txtErrorValidate=' The correct format of the Website is http://www.example.com'
                        />
                        <span>
                          <i className='rounded-icon' onClick={() => goToWebsite(provider.getValues('website'))}>
                            <ArrowRigth height='30' />
                          </i>
                        </span>
                      </div>
                    </RowContent>
                  </Col>
                </Row>
                <div
                  className='divider'
                  style={{ marginTop: '24px', marginBottom: '24px' }}
                />
                <Row>
                  <UltimateBeneficial
                    watch={provider.watch}
                    setValue={provider.setValue}
                    showPeople={(peopleId: number) =>
                      provider.showPeople(peopleId)
                    }
                    setSelectedPeople={(p: People | undefined) =>
                      provider.handleSelectedPeople(p)
                    }
                  />
                  <Kyc
                    control={provider.control}
                    register={provider.register('kyc_rate')}
                    setFormValue={provider.setValue}
                    watchFormValue={provider.watch}
                    clearFormErrors={provider.clearErrors}
                    errors={provider.errors}
                    setFormError={provider.setError}
                  />
                </Row>
              </Container>
            )}
            {provider.activeMenu === 2 && (
              <Container className='p-4'>
                <Table
                  dataSource={provider.selectedLegalEntity?.legalentity || []}
                  columns={provider.columns}
                  loading={provider.isLoadingLegalEntity}
                />
              </Container>
            )}
          </>
        }
      />

      <ModalUnsavedChange
        showModalUnsaved={provider.isShowModalUnsavedChange}
        handleCancel={() => provider.setIsShowModalUnsavedChange(false)}
        handleLeave={() => provider.showPeople(provider.showOtherModaldata, true)}
      />

    </>
  );
};

export default ModalGroup;
