import React from "react";
import Flag from "react-world-flags";

type IProps = {
  code: string;
  region_name?: string;
};
export default function FlagItem(props: IProps) {
  return (
    <span
      className="d-flex"
      style={{ flexDirection: "row", alignItems: "center" }}
    >
      <div
        className="rounded-icon d-flex"
        style={{
          width: 25,
          backgroundColor: "white",
          borderRadius: "50%",
        }}
      >
        <Flag
          code={props.code}
          height="20"
          width={"20"}
          style={{ borderRadius: "50%", objectFit: "cover" }}
        />
      </div>
      <span
        style={{
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflowX: "clip",
        }}
      >
        {props.region_name && <span>{props.region_name || ""}</span>}
      </span>
    </span>
  );
}
