import { Modal, ModalProps, Form, Button } from 'react-bootstrap';
import classes from './FormModal.module.scss';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { isExist } from '../../../utils/helper-function';
import { ReactComponent as Loader } from '../../../assets/img/Loading.svg';
import { useAppSelector } from '../../../hooks/redux';
import { useEffect } from 'react';

type Props = {
  header?: React.ReactNode;
  handleClose: () => void;
  children?: React.ReactNode;
  onSubmitForm?: (e: any) => void;
  isDisabledSave?: boolean;
  ishiddenfooter?: boolean;
  isLoading?: boolean;
} & ModalProps;

const FormModal = ({
  header,
  children,
  isDisabledSave,
  ishiddenfooter,
  handleClose,
  onSubmitForm,
  isLoading,
  ...props
}: Props) => {
  const { show } = useAppSelector((state) => state.modal);
  // const [showModalUnsaved, setShowModalUnsaved] = useState(false);

  // const handleCloseModal = () => {
  //   if (show) {
  //     if (!isDisabledSave) {
  //       setShowModalUnsaved(true);
  //     } else {
  //       handleClose();
  //     }
  //   }
  // };

  // const handleLeaveUnsaved = () => {
  //   setShowModalUnsaved(false);
  //   handleClose();
  // }

  useEffect(() => {
    if (!show) {
      handleClose();
    }
  }, [show]);

  return (
    <>
      <Modal
        {...props}
        className={`modalAddGroup ${classes.modal}`}
        dialogClassName={`modal-people ${classes.modalDialog}`}
        contentClassName={`${classes.modalContent}`}
        enforceFocus={true}
        restoreFocus={true}
        backdrop={true}
        size="sm"
      >
        <Modal.Header
          className={`d-flex justify-content-between ${classes.modalHeader}`}
        >
          {header}
          <IoIosCloseCircleOutline
            onClick={handleClose}
            style={{ color: '#ffffff', fontSize: 20, cursor: 'pointer' }}
          />
        </Modal.Header>
        <Modal.Body className={`${classes.modalBody} pt-4 px-5`}>
          <Form autoComplete="off" onSubmit={onSubmitForm}>
            {children}
            <div
              className={`justify-content-center py-4 ${
                isExist(ishiddenfooter) && ishiddenfooter === true
                  ? 'd-none'
                  : 'd-flex'
              }`}
            >
              <div className="d-inline px-2">
                <Button
                  variant="default"
                  onClick={handleClose}
                  className={`${classes.btnCancel}`}
                >
                  Cancel
                </Button>
              </div>
              <div className="d-inline px-2">
                <Button
                  disabled={isDisabledSave || isLoading}
                  type="submit"
                  className={`${classes.btnSave}`}
                >
                  {isLoading ? <Loader /> : 'Save'}
                </Button>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* modal to say if there are unsaved changes */}

      {/* <Modal
        show={showModalUnsaved}
        keyboard={false}
        scrollable={true}
        centered
        enforceFocus
        backdropClassName='modal-backdrop'
        id='modalUnsaved'
      >
        <Modal.Header>
          <Modal.Title>Unsaved Changes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to leave? You have unsaved changes.</p>
          {
            props.dirtiFields && (
              <>
                  Verify the following fields:
                {
                  Object.keys(props.dirtiFields).map((key, index) => {
                    return (
                      <>
                        <span key={index}> <b>{transformcaseTextToText(key)} </b></span>
                        {index !== Object.keys(props.dirtiFields).length - 1 ? ', ' : ''}
                      </>
                    )
                  })
                }
              </>

            )
          }
        </Modal.Body>
        <Modal.Footer>

          <div className='d-flex justify-content-end'>
            <div className='d-inline px-2'>
              <Button
                variant='default'
                onClick={() => setShowModalUnsaved(false)}
                className={`${classes.btnCancel}`}
              >
                Cancel
              </Button>
            </div>
            <div className='d-inline px-2'>
              <Button
                className={`${classes.btnSave}`}
                onClick={() => handleLeaveUnsaved()}
              >
                Leave
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal > */}
    </>
  );
};

export default FormModal;
