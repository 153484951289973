import { Checkbox, Divider } from 'antd';
import type { ColumnType } from 'antd/es/table';
import { ReactComponent as ArrowDown } from '../assets/img/Arrow-Down-Gray.svg';
import { ReactComponent as ArrowUP } from '../assets/img/Arrow-UP-Gray.svg';
import { ReactComponent as Column } from '../assets/img/Column.svg';
import { ReactComponent as Up } from '../assets/img/Up.svg';
import { ReactComponent as Down } from '../assets/img/Down.svg';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { useState } from 'react';

type Props = {
  width?: number;
  minLength?: number;
  onAsc?: () => void;
  onDesc?: () => void;
  columns: string[];
  currentColums: string[];
  setCurrentColumn: (currentColums: string[]) => void;
};

const DropDownColum = ({
  columns,
  currentColums,
  minLength,
  width,
  onAsc,
  onDesc,
  setCurrentColumn,
}: Props) => {
  const [showColumn, setShowColumn] = useState<boolean>(false);
  const closeTableDropDown = () => {
    const btn = document.querySelector<HTMLSpanElement>('.ant-dropdown-open');
    if (btn) {
      btn.click();
    }
  };
  return (
    <div
      style={{
        padding: '8px',
        border: '1px solid #DBDDDF',
        borderRadius: '8px',
        width: width ? width : 160,
      }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <div>
        <span
          onClick={
            onAsc
              ? () => {
                  closeTableDropDown();
                  if (onAsc) onAsc();
                }
              : undefined
          }
          className={`${onAsc ? 'span-icon' : 'span-icon-disabled'}`}
        >
          <i>
            <Up />
          </i>
          <span className="ms-1">Sort Ascending</span>
        </span>
        <span
          onClick={
            onDesc
              ? () => {
                  closeTableDropDown();
                  if (onDesc) onDesc();
                }
              : undefined
          }
          className={`${onDesc ? 'span-icon' : 'span-icon-disabled'}`}
        >
          <i>
            <Down />
          </i>
          <span className="ms-1">Sort Descending</span>
        </span>
      </div>
      <Divider style={{ margin: '10px 0px' }} />
      <div>
        <div
          className="span-icon d-flex justify-content-between"
          onClick={() => setShowColumn(!showColumn)}
        >
          <span>
            <i>
              <Column />
            </i>
            <span className="ms-1">Column</span>
          </span>
          <span>
            {showColumn ? (
              <FaChevronUp style={{ color: '#6d1206' }} />
            ) : (
              <FaChevronDown style={{ color: '#6d1206' }} />
            )}
          </span>
        </div>
        {showColumn &&
          columns.map(
            (col, index) =>
              index < columns.length - 1 && (
                <span className="span-icon" key={index}>
                  <Checkbox
                    onClick={() => {
                      if (currentColums.includes(col)) {
                        setCurrentColumn(
                          currentColums.filter((e) => e !== col),
                        );
                      } else {
                        setCurrentColumn([...currentColums, col]);
                      }
                    }}
                    checked={currentColums.includes(col)}
                    disabled={
                      currentColums.includes(col) &&
                      currentColums.length <= (minLength ?? 3)
                    }
                  >
                    {col
                      .replaceAll('_', ' ')
                      .split('_')
                      .map((e) => {
                        return `${e.charAt(0).toUpperCase()?.toString()}${e
                          .slice(1)
                          .toString()}`;
                      })}
                  </Checkbox>
                </span>
              ),
          )}
      </div>
    </div>
  );
};

export default function customTableColumn<T>(props: Props): ColumnType<T> {
  return {
    filterDropdown: <DropDownColum {...props} />,
    filterIcon: (filtered: boolean) =>
      filtered ? (
        <ArrowDown width={12} height={12} />
      ) : (
        <ArrowUP width={12} height={12} />
      ),
    onFilter: () => {
      return true;
    },
  };
}
