import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { baseApi } from '../../baseApi';
import { ApiTermResponse, TermText } from '../../../../@types/terms';
import { PeopleUser } from '../../../../@types';
const termsGet = baseApi.injectEndpoints({
  endpoints: (build: EndpointBuilder<any, any, any>) => {
    return {
      listTerms: build.query<ApiTermResponse, undefined>({
        query() {
          return {
            url: `terms`,
            params: {
              _dc: new Date().getTime(),
            },
          };
        },
      }),
      listText: build.query<{ success: boolean; term: TermText[] }, undefined>({
        query() {
          return {
            url: `terms/text`,
            params: {
              _dc: new Date().getTime(),
            },
            providesTags: ["TEXT_TERMS_SITE"],
          };
        },
      }),
      listUser: build.query<{ success: boolean; user: PeopleUser[] }, any>({
        query() {
          return {
            url: `user/names`,
            params: {
              _dc: new Date().getTime(),
            },
          };
        },
      }),
    };
  },
});

export const {
  useLazyListTermsQuery,
  useListTermsQuery,
  useListTextQuery,
  useListUserQuery,
} = termsGet;
