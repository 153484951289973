import { Container } from 'react-bootstrap';
import { useGetCounterPartyByLegalEntityIdQuery } from '../../../../../redux/api/ws/databases/legale-entities';
import classes from './CounterPatyList.module.scss';
import Table from '../../../../CofarcoTable/Table';
import {
  capitalizeFirstLetter,
  isCan,
} from '../../../../../utils/helper-function';
import { People } from '../../../../../@types/people';
import { Menu } from '../../../../../@types/common-types';
import { useLazyListPeopleQuery } from '../../../../../redux/api/ws/databases/people';
import { useAppDispatch } from '../../../../../hooks/redux';
import { updateModalAction } from '../../../../../redux/slice/modalSlice';
import { ModelsEnum } from '../../../../../@types/dashboard';

type IProps = {
  selectedLegalEntityId: number | null;
  people: People | undefined;
};

export default function CounterPatyList(props: IProps) {
  const { data: counterParty, isLoading } =
    useGetCounterPartyByLegalEntityIdQuery({
      id: props.selectedLegalEntityId || 0,
    });

  const columns = [
    {
      title: 'COUNTERPARTY TYPE',
      dataIndex: 'business',
      key: 'business',
      render: (text: string, rec: Partial<any>) => {
        return (
          <span>
            <b>{capitalizeFirstLetter(rec?.business)}</b>
          </span>
        );
      },
    },

    {
      title: 'COUNTERPARTY ID',
      dataIndex: 'name',
      key: 'name',
      render: (text: string) => {
        return (
          <span>
            <b>{text}</b>
          </span>
        );
      },
    },
    {
      title: 'MAIN CONTACT',
      dataIndex: 'main_contact',
      key: 'main_contact',
      render: (text: string) => {
        return (
          <span>
            <b>{text}</b>
          </span>
        );
      },
    },
  ];

  const dispatch = useAppDispatch();
  const [getPeople] = useLazyListPeopleQuery();

  const showPeople = async (peopleId: number) => {
    const params = {
      limit: 1,
      filter: `[{"property":"id","value":${peopleId},"operator":"="}]`,
      page: 1,
      start: 0,
      sort: '',
    };
    const { data: _pData } = await getPeople({
      page: params.page,
      limit: params.limit,
      filter: params.filter,
      start: 0,
    });
    if (_pData?.people?.length) {
      dispatch(
        updateModalAction({
          data: _pData.people.at(0),
          isAdd: true,
          type: ModelsEnum.people.toLowerCase(),
        }),
      );
    }
  };

  return (
    <Container className="mt-1" style={{ backgroundColor: '#F1F2F2' }}>
      <div>
        <div className={`${classes.counterList} mt-4`}>
          <Container>
            <Table
              dataSource={counterParty?.counterparty || []}
              columns={columns}
              loading={isLoading}
              onRow={(record: any) => {
                return {
                  onClick: () => {
                    if (
                      record?.main_people_id &&
                      record?.main_people_id > 0 &&
                      (isCan('write', Menu.people) ||
                        isCan('read', Menu.people))
                    ) {
                      showPeople(record?.main_people_id);
                    }
                  },
                };
              }}
            />
          </Container>
        </div>
      </div>
    </Container>
  );
}
