import React, { useState } from 'react';

import { EntityParticipation } from '../../../../@types/participation/participation';
import {
  downloadCsv,
  filterColumn,
  isCan,
  titleRequestError,
} from '../../../../utils/helper-function';
import Table from '../../../CofarcoTable/Table';
import ColumnParticipation from './ColumnParticipation';
import { updateModalAction } from '../../../../redux/slice/modalSlice';
import { useDispatch } from 'react-redux';
import {
  useDeleteParticipationsMutation,
  useExportParticipationsMutation,
} from '../../../../redux/api/ws/participations/participations';
import DeleteModal from '../../../Modal/DeleteModal';
import { updateNotificationMessage } from '../../../../redux/slice/notificationSlice';
import { Menu } from '../../../../@types/common-types';
import { Button } from 'antd';
import { ReactComponent as ExcelIcon } from './../../../../assets/img/excel.svg';
import { ReactComponent as AddGroup } from './../../../../assets/img/add.svg';

type IProps = {
  dataSource: Array<any>;
  isLoading: boolean;
  selectedDeals: any | null;
  mandateType: string;
  sortedParticipation: (sortedParams: string) => void;
  id: number | string;
};
export default function ParticipationList(props: IProps) {
  const dispatch = useDispatch();
  const [selectedParticipation, setSelectedParticipation] =
    useState<EntityParticipation | null>();
  const [modalConfirmDelete, setModalConfirmDelete] = useState(false);
  const [exportParticipations] = useExportParticipationsMutation();
  const [
    deleteParticipation,
    {
      isLoading: isLoadingDelete,
      isSuccess: isSuccessDelete,
      isError: isErrorDelete,
      error: errorResponse,
    },
  ] = useDeleteParticipationsMutation();

  const handleSorted = React.useCallback(
    (key: string, ruls: 'ASC' | 'DESC') => {
      props.sortedParticipation(
        `[{"property":"${key}","direction":"${ruls}"}]`,
      );
    },
    [],
  );

  const handleShowModalDelete = () => {
    setModalConfirmDelete(!modalConfirmDelete);
  };

  const editParticipation = (id: number) => {
    const part = (props.dataSource || []).find((el: any) => el.id === id);
    if (part) {
      dispatch(
        updateModalAction({
          data: {
            ...part,
            isUpdateByDeals: true,
          },
          isAdd: true,
          type: 'participations',
        }),
      );
    }
  };

  const handleDeleteParticipation = (id: number) => {
    const part = (props.dataSource || []).find((el: any) => el.id === id);
    if (part) {
      setSelectedParticipation(part || null);
      handleShowModalDelete();
    }
  };

  const { columns, uncheckedId } = ColumnParticipation({
    participations: props.dataSource || [],
    handleSorted,
    editParticipation: editParticipation,
    handleDeleteParticipation: handleDeleteParticipation,
    mandateType: props.mandateType,
  });

  const validDelete = () => {
    if (selectedParticipation?.id) {
      deleteParticipation({ id: selectedParticipation?.id || -1 });
    }
  };

  React.useEffect(() => {
    if (isSuccessDelete) {
      handleShowModalDelete();
    }
  }, [isSuccessDelete]);

  React.useEffect(() => {
    if (isErrorDelete) {
      handleShowModalDelete();
      dispatch(
        updateNotificationMessage({
          show: true,
          title: titleRequestError,
          body: errorResponse?.data,
        }),
      );
    }
  }, [isErrorDelete]);

  const handleCreateParticipation = () => {
    const deal_id = props.selectedDeals.id || null;
    const mandate_id = props.selectedDeals?.mandate_id || null;
    if (deal_id) {
      dispatch(
        updateModalAction({
          data: {
            deal_id: deal_id,
            mandate_id: mandate_id,
            number: props.selectedDeals?.number,
          },
          isAdd: true,
          type: 'participations',
        }),
      );
    }
  };

  const handleExportParticipation = async () => {
    const filter = [
      {
        property: 'deal_id',
        value: props.id,
        operator: '=',
      },
    ];
    const fields = [
      'number',
      'counterparty_id',
      'underwriter_legal_entity_id',
      'currency',
      'status',
      'participant_reference',
      'minimum_deposit',
      'premium_rate',
      'amount',
    ];
    const res = await exportParticipations({
      fields,
      filter,
    });
    if (res) {
      const rep = res as any;
      downloadCsv(rep.data, 'participations.csv');
    }
  };

  return (
    <>
      <div className="content-table">
        <Table
          dataSource={props.isLoading ? [] : props.dataSource || []}
          columns={filterColumn<EntityParticipation>(columns, uncheckedId)}
          loading={props.isLoading}
          onRow={(record: any) => {
            return {
              onClick: () => {
                if (isCan('write', Menu.participation))
                  editParticipation(record?.id);
              },
            };
          }}
        />
        {isCan('write', Menu.participation) && (
          <div className="d-flex">
            <Button
              className="btn red mt-3"
              onClick={() => handleCreateParticipation()}
            >
              <AddGroup />
              <span className="ms-1">Add Participation</span>
            </Button>

            <Button
              className="btn red mt-3 mx-3"
              onClick={() => handleExportParticipation()}
            >
              <ExcelIcon />
              <span className="ms-1">Export</span>
            </Button>
          </div>
        )}
        <DeleteModal
          title="Delete Enquiries"
          handleClose={handleShowModalDelete}
          show={modalConfirmDelete}
          validDelete={() => validDelete()}
          groupeName={selectedParticipation?.number || ''}
          isLoadingDelete={isLoadingDelete}
        />
      </div>
    </>
  );
}
