type Props = {
  label: React.ReactNode;
  value?: React.ReactNode;
  icon?: React.ReactNode;
};
const IndicationEnquiryInfo = ({ label, value, icon }: Props) => {
  return (
    <div className="d-flex flex-row py-2 px-4">
      <div style={{ width: "40%" }}>{label}</div>
      <div style={{ width: "40%" }}>{value}</div>
      <div style={{ width: "20%" }}>{icon}</div>
    </div>
  );
};

export default IndicationEnquiryInfo;
