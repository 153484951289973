import {
  EntityParticipation,
  InputFormParticipation,
} from '../../../@types/participation/participation';
import { concatTitle, isCan } from '../../../utils/helper-function';
import HeaderMultiple from '../../HeaderTab/HeaderMultiple';
import FormModal from '../../Modal/FormModal/FormModal';
import Forms from './Forms/Forms';
import ParticipationLimitsList from './ParticipationObligorList/LimitsList';
import UseModalParticipations from './UseModalParticipations';
import { Menu } from '../../../@types/common-types';

type IProps = {
  show: boolean;
  handleClose: (mandate: any | null) => void;
  defaultFormValues: EntityParticipation | null;
};

export default function ModalParticipations(props: IProps) {
  const provider = UseModalParticipations({
    defaultFormValues: props.defaultFormValues,
    closingModal: props.handleClose,
  });

  const menus = [
    {
      id: 1,
      title: `Participation ${concatTitle(
        props.defaultFormValues?.number,
      )} ${concatTitle(provider.selectedDeal?.number)} ${concatTitle(
        provider?.selectedMandate?.number,
      )} `,
    },
    {
      id: 2,
      title: `Participation Obligor Limits List ${concatTitle(
        props.defaultFormValues?.number,
      )} ${concatTitle(provider.selectedDeal?.number)} ${concatTitle(
        provider?.selectedMandate?.number,
      )}  `,
    },
  ];

  return (
    <>
      <FormModal
        show={props.show}
        handleClose={() => {
          provider.resetForm();
          props.handleClose(null);
          provider.setActiveMenu(1);
        }}
        isLoading={false}
        onSubmitForm={provider.handleSubmit(provider.submit)}
        isDisabledSave={!provider.isDirty || !provider.isCompletedForm}
        ishiddenfooter={
          !!(provider.activeMenu === 2) ||
          (!isCan('write', Menu.participation) && provider.isEdit)
        }
        header={
          <HeaderMultiple
            activeMenu={provider.activeMenu}
            menus={menus}
            createTitle={`New Participation - ${props.defaultFormValues?.number}  - ${provider?.selectedMandate?.number} `}
            setActiveMenu={(p: number) => provider.handleChangeToogle(p)}
            isEdit={provider.isEdit}
          />
        }
        children={
          <>
            {provider.activeMenu === 1 && (
              <Forms
                register={provider.register}
                errors={provider.errors}
                watch={provider.watch}
                setValue={provider.setValue}
                clearFormErrors={provider.clearFormErrors}
                setFormError={provider.setFormError}
                runSearch={(key: keyof InputFormParticipation, value: string) =>
                  provider.runSearch(key, value)
                }
                control={provider.control}
                counterparty={provider.counterpartyList}
                isCounterpartyLoading={provider.isGetCoLoading}
                legalEntityList={provider.legalEntityList || []}
                isGetEntityLoading={provider.isGetEntityLoading}
                selectedMandate={provider.selectedMandate}
                selectedDeal={provider.selectedDeal}
                isGetDealLoading={provider.isLoading}
                provider={provider}
                handleShowOtherModal={provider.handleShowOtherModal}
                showOtherModal={provider.showOtherModal}
              />
            )}
            {provider.activeMenu === 2 && (
              <ParticipationLimitsList
                selectedParticipation={props.defaultFormValues?.id || 0}
                dealId={provider.selectedDeal?.id || 0}
                mandateId={provider.selectedMandate?.id || 0}
                participation_id={props.defaultFormValues?.id as number}
              />
            )}
          </>
        }
      />
    </>
  );
}
