import { Form } from 'react-bootstrap';
import { UseFormRegister, UseFormWatch } from 'react-hook-form';
import { TermConst } from '../../../../@types/common-types';
import { InputFormMandate } from '../../../../@types/mandates/mandates';
import UseConstante from '../../../../hooks/use-constante';
import { exportedDropdownValue } from '../../../../utils/helper-function';
import { DropDownOptionObjectType } from '../../../common/DropDown/DropdownSelect';
import SelectDropDown from '../../../common/DropDown/SelectDropDown';

type IProps = {
  register: UseFormRegister<InputFormMandate>;
  watch: UseFormWatch<InputFormMandate>;
  setValue: (e: string) => void;
  errors: string | undefined;
  onBlur: any;
};
export default function SourceOfBusinessForm(props: IProps) {
  const terms = UseConstante();
  const business_source = props.watch('business_source');

  return (
    <div>
      <Form.Group className={`d-flex align-items-baseline`}>
        <Form.Label className="txt-with-dropdown">
          Source of Business <span className="span-required">*</span>
        </Form.Label>

        <div className="d-flex flex-column w-100">
          <SelectDropDown
            noPadding
            noSearch
            modalId="mandate"
            preValue={exportedDropdownValue(
              terms?.getConst(TermConst.businessSource),
              business_source as string,
            )}
            {...props.register('business_source', {
              required: true,
            })}
            options={terms?.getConst(TermConst.businessSource)}
            onSelectOption={(e) =>
              props.setValue((e as DropDownOptionObjectType).key)
            }
            onBlur={props.onBlur}
            error={props.errors}
          />
        </div>
      </Form.Group>
    </div>
  );
}
