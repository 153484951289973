import React, { ReactNode, useCallback, useMemo } from 'react';
import './Dashboard.module.scss';
import classe from './Dashboard.module.scss';
import { useLazySeachGlobalQuery } from '../../redux/api/ws/global/global';
import { GlobalSearchResponse } from '../../@types/pipe';
import { isEmpty, uniqBy } from 'lodash';
import UseDealsPage from '../Deals/UseDealsPage';
import { EntityDeals } from '../../@types/deals/deals';
import { filterColumn, isCan } from '../../utils/helper-function';
import { Menu } from '../../@types/common-types';
import DeleteModal from '../Modal/DeleteModal';
import UseMandatePage from '../Mandates/UseMandateContent';
import UseParticipationPage from '../Participations/UseParticipationPage';
import UseNotesContents from '../SegregatedAccounts/Notes/UseNotesContents';
import UsePayementsContents from '../SegregatedAccounts/Payements/UsePayementsContent';
import UseGroupContent from '../Database/Group/UseGroupContent';
import { Table as CofarcoTable } from 'antd';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';
import { EntityMandate } from '../../@types/mandates/mandates';
import {
  EntityLimits,
  EntityParticipation,
} from '../../@types/participation/participation';
import { LegalEntity } from '../../@types/legal-entities';
import UseLegalEntity from '../Database/LegalEntity/UseLegalEntity';
import useCounterPartyresult from '../../hooks/dashboard/useCounterPartyResult';
import { EntityGroupe } from '../../@types/group/types';
import { EntityNotes } from '../../@types/segregatedaccounts/notes';
import { EntityPayements } from '../../@types/segregatedaccounts/payements';
import useLimitResult from '../../hooks/dashboard/useLimitResult';
import usePeopleResult from '../../hooks/dashboard/usePeopleResult';
import { BiReset } from 'react-icons/bi';
import { ConterParty } from '../../@types/conterParty';
import { People } from '../../@types/people';
import useTeamResult from '../../hooks/dashboard/useTeamResult';

type Props = {
  children?: ReactNode;
};
export default function DashboardGlobalSearch({ children }: Props) {
  const [globalSearch, { isLoading, isFetching }] = useLazySeachGlobalQuery();
  const [serashKey, setSerashKey] = React.useState<string>('');
  const [searchResult, setSearchResult] =
    React.useState<GlobalSearchResponse>();
  const handleSearch = (e: any) => {
    e.preventDefault();
    setSerashKey(e.target[0].value);
    globalSearch({ search: e.target[0].value }).then((res) => {
      setSearchResult(res.data);
    });
  };

  const formRef = React.useRef<any>(null);
  const providerDeals = UseDealsPage();
  const providerMandates = UseMandatePage();
  const providerParticipation = UseParticipationPage({
    columnsToRemove: ['counterparty'],
    otherColumns: ['deal_type', 'top_obligor', 'financier_underwriter'],
  });
  const providerNote = UseNotesContents({
    columnsToRemoved: [
      'issuing_date',
      'payment_date',
      'legal_entity',
      //  "settlement_date",
      'status',
      'note',
      'deal_id',
      // "amount",
      // "currency",
    ],
    otherColumnsToAdd: ['type', 'mandate', 'deal'],
  });
  const providerPayment = UsePayementsContents({
    columnsToRemoved: ['owner_bank_account', 'status'],
  });
  const providerPeople = usePeopleResult();
  const providerCounterParty = useCounterPartyresult();
  const providerLimit = useLimitResult();
  const providerGroup = UseGroupContent();
  const providerLegalEntity = UseLegalEntity({ otherColumns: ['type'] });
  const providerTeam = useTeamResult();

  const shouldBeTheOrderResult = [
    'counterparty',
    'people',
    'user',
    'group',
    'legalentity',
    'mandate',
    'deal',
    'participation',
    'limit',
    'note',
    'payment',
  ];

  const labelOrderResult = [
    'Counterparties',
    'People',
    'Cofarco Team items',
    'Groups',
    'Legal Entities',
    'Mandates',
    'Deals',
    'Participations',
    'Limits',
    'Notes',
    'Payments',
  ];

  const icons = [
    <span
      key={1}
      className="icon-Counterparties1"
      style={{ fontSize: '20px' }}
    ></span>,
    <span
      key={2}
      className="icon-People mt-2"
      style={{ fontSize: '24px' }}
    ></span>,
    <span key={3} className="icon-Grouppp" style={{ fontSize: '20px' }}></span>,
    <span
      key={4}
      className="icon-Legal-Entities"
      style={{ fontSize: '24px' }}
    ></span>,
    <span
      key={5}
      className="icon-Vector-Mandate"
      style={{ fontSize: '16px', marginInlineEnd: 1 }}
    ></span>,
    <span key={6} className="icon-Deals" style={{ fontSize: '24px' }}></span>,
    <span
      key={7}
      className="icon-Participations"
      style={{ fontSize: '24px' }}
    ></span>,
    <BsFillExclamationTriangleFill key={8} style={{ marginInlineEnd: 3 }} />,
    <span key={8} className="icon-notes"></span>,
    <span key={9} className="icon-payements"></span>,
  ];

  const resusltArray = useMemo(() => {
    const result: any[] = [];
    if (searchResult) {
      for (const [key, value] of Object.entries(searchResult)) {
        result.push({ key, value });
      }
      result.sort((a, b) => {
        const indexA = shouldBeTheOrderResult.indexOf(a.key);
        const indexB = shouldBeTheOrderResult.indexOf(b.key);
        return indexA - indexB;
      });
      return result;
    }
  }, [
    searchResult?.deal,
    searchResult?.mandate,
    searchResult?.participation,
    searchResult?.legalentity,
    searchResult?.group,
    searchResult?.note,
    searchResult?.payment,
    searchResult?.counterparty,
    searchResult?.limit,
    searchResult?.people,
  ]);

  const noresult = useMemo(() => {
    return (
      resusltArray
        ?.map((item: any) => {
          return item.value;
        })
        .flat().length == 1
    ); // [true]
  }, [resusltArray]);

  const getProvider = useCallback(
    (key: string) => {
      switch (key) {
        case 'deal':
          return providerDeals;
        case 'mandate':
          return providerMandates;
        case 'participation':
          return providerParticipation;
        case 'note':
          return providerNote;
        case 'payment':
          return providerPayment;
        case 'people':
          return providerPeople;
        case 'counterparty':
          return providerCounterParty;
        case 'group':
          return providerGroup;
        case 'legalentity':
          return providerLegalEntity;
        case 'limit':
          return providerLimit;
        case 'user':
          return providerTeam;
        default:
          return providerGroup;
      }
    },
    [
      providerDeals,
      providerMandates,
      providerParticipation,
      providerNote,
      providerPayment,
      providerPeople,
      providerCounterParty,
      providerGroup,
      providerLegalEntity,
      providerLimit,
      providerTeam,
    ],
  );

  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          position: 'fixed',
          top: 30,
          marginBottom: 10,
        }}
      >
        <form onSubmit={handleSearch} ref={formRef}>
          <div className="search">
            <span className="icon-search ml-2"></span>
            <input type="text" placeholder="Global Search" minLength={3} />
            <BiReset
              className={`${classe.btn_reset} mx-2`}
              onClick={() => {
                setSearchResult(undefined);
                setSerashKey('');
                // reset this form
                if (formRef.current) formRef.current.reset();
              }}
            />
          </div>
        </form>
      </div>

      <div
        style={{
          height: 'calc(100vh - 95px)',
          overflowX: 'hidden',
          padding: '0 5px',
        }}
      >
        {(isLoading || isFetching) && (
          <div className="d-flex justify-content-center align-items-center align-content-center h-100">
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status"></div>
            </div>
          </div>
        )}

        {!isFetching &&
          !isFetching &&
          resusltArray &&
          resusltArray.map((item: any) => {
            if (!isEmpty(item.value)) {
              if (
                item.key == 'deal' ||
                item.key == 'mandate' ||
                item.key == 'participation' ||
                item.key == 'legalentity' ||
                item.key == 'group' ||
                item.key == 'note' ||
                item.key == 'payment'
              ) {
                const pr: any = getProvider(item.key);

                return (
                  <div key={item.key + 'div'} className="py-2">
                    <div className="p-2 my-2 bg-secondary rounded-md">
                      <p className="text-white mb-0 fs-6 fw-medium d-flex align-items-center">
                        <span>
                          {icons[shouldBeTheOrderResult.indexOf(item.key)]}
                          &nbsp;
                        </span>
                        <span>
                          {item.value.length}{' '}
                          {
                            labelOrderResult[
                              shouldBeTheOrderResult.indexOf(item.key)
                            ]
                          }{' '}
                          found
                        </span>
                      </p>
                    </div>
                    <div
                      style={{
                        maxWidth: '100%',
                        overflowX: 'hidden',
                        overflowY: 'clip',
                      }}
                    >
                      <div
                        style={{
                          maxWidth: '100%',
                          overflowX: 'scroll',
                          overflowY: 'clip',
                        }}
                      >
                        <CofarcoTable
                          rowKey="index"
                          dataSource={
                            item.key == 'deal'
                              ? uniqBy(item.value, 'id')
                              : item.value
                          }
                          columns={filterColumn<
                            | EntityDeals
                            | EntityMandate
                            | EntityGroupe
                            | EntityParticipation
                            | LegalEntity
                            | EntityNotes
                            | EntityPayements
                          >(
                            pr.columns,
                            item.key == 'legalentity'
                              ? [...pr.uncheckedId, 5]
                              : pr.uncheckedId,
                          )}
                          rowClassName={(_, index) =>
                            `${
                              index % 2 === 1
                                ? classe.tablelight
                                : classe.tableWhite
                            } ${classe.tablerow}`
                          }
                          loading={false}
                          onRow={(record: any) => {
                            return {
                              onClick: () => {
                                switch (item.key) {
                                  case 'deal':
                                    {
                                      if (
                                        isCan('write', Menu.deal) ||
                                        isCan('read', Menu.deal)
                                      )
                                        pr.editDeals(record?.id, record);
                                    }
                                    break;
                                  case 'mandate':
                                    {
                                      if (
                                        isCan('write', Menu.mandate) ||
                                        isCan('read', Menu.mandate)
                                      )
                                        pr.editMandate(record?.id, record);
                                    }
                                    break;
                                  case 'participation':
                                    {
                                      if (
                                        isCan('write', Menu.participation) ||
                                        isCan('read', Menu.participation)
                                      )
                                        pr.editParticipation(
                                          record?.id,
                                          record,
                                        );
                                    }
                                    break;
                                  // case "legalentity":
                                  //   {
                                  //     if (isCan("write", Menu.legal_entity) || isCan("read", Menu.legal_entity))
                                  //       pr.editLegalEntity(record?.id, record);
                                  //   }
                                  //   break;
                                  case 'group':
                                    {
                                      if (
                                        isCan('write', Menu.group) ||
                                        isCan('read', Menu.group)
                                      )
                                        pr.editGroup(record?.id, record);
                                    }
                                    break;
                                  case 'note':
                                    {
                                      if (
                                        isCan('write', Menu.note) ||
                                        isCan('read', Menu.note)
                                      )
                                        pr.editNotes(record?.id, record);
                                    }
                                    break;
                                  case 'payment':
                                    {
                                      if (
                                        isCan('write', Menu.payment) ||
                                        isCan('read', Menu.payment)
                                      )
                                        pr.handleEditPayement(
                                          record?.id,
                                          record,
                                        );
                                    }
                                    break;
                                  default:
                                    break;
                                }
                              },

                              /*style: {
                                cursor: item.key == "note" ? "default" : "",
                              },*/
                            };
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              } else if (
                item.key == 'counterparty' ||
                item.key == 'limit' ||
                item.key == 'people' ||
                item.key == 'user'
              ) {
                const pr: any = getProvider(item.key);
                return (
                  <div key={item.key + 'div'} className="py-2">
                    <div className="p-2 my-2 bg-secondary rounded-md">
                      <p className="text-white mb-0 fs-6 fw-medium d-flex align-items-center">
                        <span>
                          {icons[shouldBeTheOrderResult.indexOf(item.key)]}
                          &nbsp;
                        </span>
                        <span>
                          {item.value.length}{' '}
                          {
                            labelOrderResult[
                              shouldBeTheOrderResult.indexOf(item.key)
                            ]
                          }{' '}
                          found
                        </span>
                      </p>
                    </div>
                    <div
                      style={{
                        maxWidth: '100%',
                        overflowX: 'hidden',
                        overflowY: 'clip',
                      }}
                    >
                      <div
                        style={{
                          maxWidth: '100%',
                          overflowX: 'scroll',
                          overflowY: 'clip',
                        }}
                      >
                        <CofarcoTable
                          dataSource={item.value}
                          columns={filterColumn<
                            ConterParty | EntityLimits | People
                          >(pr.columns, pr.uncheckedId)}
                          rowClassName={(_, index) =>
                            `${
                              index % 2 === 1
                                ? classe.tablelight
                                : classe.tableWhite
                            } ${classe.tablerow}`
                          }
                          loading={false}
                          onRow={(record: any) => {
                            return {
                              onClick: () => {
                                switch (item.key) {
                                  case 'counterparty':
                                    if (
                                      isCan('write', record.business) ||
                                      isCan('read', record.business)
                                    ) {
                                      pr.editCounterParty(record);
                                    }
                                    break;
                                  case 'limit':
                                    if (
                                      isCan('write', Menu.limit) ||
                                      isCan('read', Menu.limit)
                                    ) {
                                      pr.editLimit(record);
                                    }
                                    break;

                                  case 'people':
                                    if (
                                      isCan('write', Menu.people) ||
                                      isCan('read', Menu.people)
                                    ) {
                                      pr.editPeople(record);
                                    }
                                    break;
                                  case 'user':
                                    if (
                                      isCan('write', Menu.user) ||
                                      isCan('read', Menu.user)
                                    ) {
                                      pr.editUser(record);
                                    }
                                    break;

                                  default:
                                    break;
                                }
                              },
                            };
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              }
            }
          })}

        {
          // IF no result
          !isFetching && !isLoading && resusltArray && noresult && (
            <div className="d-flex justify-content-center align-items-center align-content-center h-100">
              <p className="text-center text-secondary fs-6">
                No result found for "{serashKey}"
              </p>
            </div>
          )
        }

        {!serashKey && children}
      </div>

      <DeleteModal
        handleClose={providerDeals.handleShowModalDelete}
        show={providerDeals.modalConfirmDelete}
        validDelete={() => providerDeals.validDelete()}
        groupeName={providerDeals.selectedDeals?.number || ''}
        isLoadingDelete={providerDeals.isLoadingDelete}
        title="Delete Deals"
      />

      <DeleteModal
        handleClose={providerMandates.handleShowModalDelete}
        show={providerMandates.modalConfirmDelete}
        validDelete={() => providerMandates.validDelete()}
        groupeName={providerMandates.selectedMandate?.number || ''}
        isLoadingDelete={providerMandates.isLoadingDelete}
        title="Delete Mandate"
      />

      <DeleteModal
        handleClose={providerParticipation.handleShowModalDelete}
        show={providerParticipation.modalConfirmDelete}
        validDelete={() => providerParticipation.validDelete()}
        groupeName={providerParticipation.selectedP?.number || ''}
        isLoadingDelete={providerParticipation.isLoadingDelete}
        title="Delete Participations"
      />

      <DeleteModal
        handleClose={providerLegalEntity.handleShowModalDelete}
        show={providerLegalEntity.modalConfirmDelete}
        validDelete={() => providerLegalEntity.validDelete()}
        groupeName={providerLegalEntity.selectedLegalEntity?.name || ''}
        isLoadingDelete={providerLegalEntity.isLoadingDelete}
        title="Delete Legal Entity"
      />

      <DeleteModal
        handleClose={() => providerCounterParty.setShowmodalDelete(false)}
        show={providerCounterParty.showmodalDelete}
        validDelete={() => providerCounterParty.deleteCounterPartyCallback()}
        groupeName={providerCounterParty.selectedCounterParty?.name || ''}
        isLoadingDelete={providerCounterParty.isLoadingDelete}
        title="Delete CounterParty"
      />

      <DeleteModal
        handleClose={providerGroup.handleShowModalDelete}
        show={providerGroup.modalConfirmDelete}
        validDelete={() => providerGroup.validDelete()}
        groupeName={providerGroup.selectedGroup?.name || ''}
        isLoadingDelete={providerGroup.isLoadingDelete}
        title="Delete Group"
      />

      <DeleteModal
        handleClose={providerNote.handleShowModalDelete}
        show={providerNote.modalConfirmDelete}
        validDelete={() => providerNote.validDelete()}
        groupeName={providerNote.selectedNotes?.number || ''}
        isLoadingDelete={providerNote.isLoadingDelete}
        title="Delete Notes"
      />

      <DeleteModal
        handleClose={providerPayment.handleShowModalDelete}
        show={providerPayment.modalConfirmDelete}
        validDelete={() => providerPayment.validDelete()}
        groupeName={providerPayment.selectedPayement?.number || ''}
        isLoadingDelete={providerPayment.isLoadingDelete}
        title="Delete Payements"
      />

      <DeleteModal
        handleClose={() => providerLimit.setShowmodalDelete(false)}
        show={providerLimit.showmodalDelete}
        validDelete={() => providerLimit.deleteLimitCallback()}
        groupeName={providerLimit.selectedLimit?.number || ''}
        isLoadingDelete={providerLimit.isLoadingDelete}
        title="Delete Limit"
      />

      <DeleteModal
        handleClose={() => providerPeople.setShowmodalDelete(false)}
        show={providerPeople.showmodalDelete}
        validDelete={() => providerPeople.deletePeopleCallback()}
        groupeName={providerPeople.selectedPeople?.firstname || ''}
        isLoadingDelete={providerPeople.isLoadingDelete}
        title="Delete People"
      />

      <DeleteModal
        handleClose={() => providerTeam.setShowmodalDelete(false)}
        show={providerTeam.showmodalDelete}
        validDelete={() => providerTeam.deleteUserCallback()}
        groupeName={providerTeam?.groupName ?? ''}
        isLoadingDelete={providerTeam.isLoadingDelete}
        title="Delete Cofarco Team"
      />
    </div>
  );
}
