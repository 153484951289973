import React, { createContext, useState } from 'react';
import { Outlet } from 'react-router-dom';
import ContentNavBar from '../Admin/ContentNavBar';
import History from '../../assets/img/History.svg';

export const ContextHistory = createContext<any>(null);

export default function HistoryContent() {
  const [searchTxt, setSearchTxt] = useState<string | null>(null);

  return (
    <>
      <ContentNavBar
        title='History'
        logo={History}
        setTxt={(txt: any) => setSearchTxt(txt)}
        txt={searchTxt}
      />
      <ContextHistory.Provider value={{ txt: searchTxt }}>
        <Outlet />
      </ContextHistory.Provider>
    </>
  );
}
