import { PartnerFormInputs } from "../../../../@types/conterParty";

export const useDefaultPartnerInput = (): PartnerFormInputs => (
    {
        business: 'partner',
        id: -1,
        name: '',
        commercial_name: '',
        type: '',
        country_id: '',
        legal_entity_id: -1,
        activity: '',
        market: '',
        main_people_id: null,
        most_senior_people_id: null,
        isDirty: 1,
        informations: {
            terms_of_business: undefined,
        },
    })