import { BaseQueryApi, BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { config } from '../../data/config';
import { showError } from '../slice/errorSlice';
import { hideLoader, showLoader } from '../slice/loaderSlice';
import { store } from '../store';
import { url as urls } from './url';
import { STORAGE_KEY, setUserSession } from '../../utils/user-session';
import { refresh } from '../slice/authSlice';

type ParamsAxion = {
  url: string;
  method: AxiosRequestConfig['method'];
  data?: AxiosRequestConfig['data'];
  params?: AxiosRequestConfig['params'];
  headers?: AxiosRequestConfig['headers'];
  token?: string | undefined;
};

const fetch = async ({
  url,
  method,
  data,
  params,
  headers,
  token,
}: ParamsAxion) => {
  return await axios({
    url,
    method,
    data,
    params,
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  });
};

const refreshToken = async (
  baseUrl: string,
  request: () => Promise<AxiosResponse<any, any>>,
  api: BaseQueryApi,
) => {
  //
  const _refreshToken = localStorage.getItem(STORAGE_KEY.REFRESH_TOKEN);

  if (!_refreshToken) {
    return null;
  } else {
    try {
      const loginRes = await fetch({
        url: baseUrl + urls.refreshToken,
        method: 'GET',
        token: _refreshToken,
      });

      console.log('REFRESH TOKEN', _refreshToken);

      if (loginRes?.status == 200) {
        setUserSession(loginRes.data);
        api.dispatch(refresh(loginRes.data));
        const response = await request();
        return response;
      } else {
        return null;
      }
    } catch (err) {
      return null;
    }
  }
};

export const axiosBaseQuery = (
  { baseUrl }: { baseUrl: string } = { baseUrl: '' },
): BaseQueryFn<ParamsAxion, unknown, unknown> => {
  return async ({ url, method, data, params, headers }, api) => {
    api.dispatch(showLoader());

    const request = async () => {
      const token: string | undefined =
        localStorage.getItem(STORAGE_KEY.TOKEN) || store.getState().auth.token;

      return await fetch({
        url: baseUrl + url,
        method,
        data,
        params: {
          ...params,
          _dc: new Date().getTime(),
        },
        headers: {
          ...headers,
          Authorization: `Bearer ${token || config.token}`,
        },
        token: token || config.token,
      });
    };

    try {
      const result = await request();
      api.dispatch(hideLoader());
      return { data: result.data };
    } catch (error: any) {
      const err = error as AxiosError;
      const errorMessage: any = err.response?.data || err.message;
      api.dispatch(
        showError({
          message: (errorMessage as any).message ?? errorMessage?.error,
          date: new Date().toDateString(),
        }),
      );

      api.dispatch(hideLoader());
      if (
        err.response &&
        err.response?.status === 401 &&
        !window.location.href.startsWith(`${window.location.origin}/login`)
      ) {
        //
        if (err.response.statusText.toLowerCase() === 'unauthorized') {
          const res = await refreshToken(baseUrl, request, api);
          if (res) return { data: res.data };
          else window.open(`${window.location.origin}/login`, '_self');
        } else {
          window.open(`${window.location.origin}/login`, '_self');
        }
      }
      return {
        error: {
          status: err.response?.status,
          data: (errorMessage as any).message ?? errorMessage,
        },
      };
    }
  };
};

export async function getDataService(url: string) {
  const token = store.getState().auth.token || config.token;
  try {
    const response = await fetch({
      url: config.baseUrl + url,
      method: 'GET',
      token: token,
      params: {
        getLegalEntity: 1,
      },
    });
    return response.data;
  } catch (error) {
    return null;
  }
}

export async function getPeopleByCounterParty(
  url: string,
  counterPartyId: number,
) {
  const token = store.getState().auth.token || config.token;
  //store.dispatch(showLoader());
  try {
    const underwrter = await fetch({
      url: config.baseUrl + `counterparty/${counterPartyId}`,
      method: 'GET',
      token: token,
      params: {
        _dc: new Date().getTime(),
        getLegalEntity: true,
        getGroup: true,
        page: 1,
        start: 0,
        limit: 25,
      },
    });
    const response = await fetch({
      url: config.baseUrl + url,
      method: 'GET',
      token: token,
      params: {
        // counterpartyId: counterPartyId,
        // allEntities: true,
        filter: [
          {
            property: 'legalEntityId',
            operator: 'in',
            value:
              underwrter.data?.counterparty.legal_entities.map(
                (l: any) => l.id,
              ) ?? [],
          },
        ],
        page: 1,
        start: 0,
        limit: 100,
      },
    });
    //store.dispatch(hideLoader());
    return response.data;
  } catch (error) {
    return null;
  }
}

export async function addPanelWs(
  url: string,
  underwriter_id: number,
  enquiry_id: number,
) {
  const token = store.getState().auth.token || config.token;
  //store.dispatch(showLoader());
  try {
    const response = await fetch({
      url: config.baseUrl + url,
      method: 'POST',
      token: token,
      data: {
        amount: 0,
        approached: false,
        channel: '',
        comments: null,
        contact_id: null,
        contacted_date: null,
        currency: '',
        enquiry_id: enquiry_id,
        id: -2,
        indication_date: null,
        indication_price: '',
        indication_type: '',
        underwriter_id: underwriter_id,
      },
      params: {
        enquiryId: enquiry_id,
        getUnderwriter: true,
        getContact: true,
      },
    });
    return response.data;
  } catch (error) {
    return null;
  }
}

export async function checkIban(url: string, iban: string) {
  const token = store.getState().auth.token || config.token;
  //store.dispatch(showLoader());
  try {
    const response = await fetch({
      url: config.baseUrl + url,
      method: 'GET',
      token: token,
      params: {
        iban: iban,
        getLegalEntity: 1,
      },
    });
    //store.dispatch(hideLoader());
    return response.data;
  } catch (error) {
    return null;
  }
}
