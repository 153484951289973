import type { ColumnsType, ColumnType } from 'antd/es/table';
import React, { useState } from 'react';
import FilterTab from '../../../../utils/filter-tab';
import { ReactComponent as ArrowDown } from '../../../../assets/img/Arrow-Down-Gray.svg';
import { ReactComponent as ArrowUP } from '../../../../assets/img/Arrow-UP-Gray.svg';
import UseConstante from '../../../../hooks/use-constante';
import {
  amountFormat,
  exportDateUi,
  exportedDropdownValue,
  handleRegion,
  isCan,
} from '../../../../utils/helper-function';
import { Menu, TermConst } from '../../../../@types/common-types';
import { EntityLimits } from '../../../../@types/limit/types';
import FlagItem from '../../../FlagItem/FlagItem';
import { ReactComponent as Delete } from '../../../../assets/img/Delete.svg';
import { ReactComponent as Edit } from '../../../../assets/img/Edit.svg';
import { useGetCountryQuery } from '../../../../redux/api/ws/databases/groups';
import { EntityCountry } from '../../../../@types/group/types';
type IProps = {
  handleSorted: (key: string, ruls: 'ASC' | 'DESC') => void;
  limitsList: Array<EntityLimits>;
  edit: (id: number, record: EntityLimits) => void;
  handleDelete: (id: number) => void;
  market?: string;
  deal_type?: string;
};
type DataIndex = keyof EntityLimits;

export default function ColumnLimit(props: IProps) {
  const [dropDownFilterState, setDropDownFilterState] =
    useState<boolean>(false);
  const [uncheckedId, setUncheckedId] = useState<Array<number>>([]);
  const terms = UseConstante();

  const onChangeFilterColumn = (key: number | undefined) => {
    const found = uncheckedId?.find((uncheckedTitle) => uncheckedTitle === key);
    if (found) {
      setUncheckedId(uncheckedId.filter((t) => t !== key));
    } else {
      setUncheckedId([...uncheckedId, key || 0]);
    }
  };

  const { data: responseCountry } = useGetCountryQuery();

  const countries: Array<EntityCountry> = responseCountry?.country || [];

  const getColumnSearchProps = (
    dataIndex:
      | DataIndex
      | 'obligor'
      | 'obligorLegalEntityId'
      | 'countryId'
      | 'limitDate'
      | 'expiryDate',
  ): ColumnType<EntityLimits> => ({
    filterDropdown: ({ close }) =>
      FilterTab<EntityLimits>({
        columns: columns,
        dataIndex: dataIndex,
        handleSorted: (dataIndex: string, rule: 'ASC' | 'DESC') =>
          props.handleSorted(dataIndex, rule),
        onChangeFilterColumn: (key: number | undefined) =>
          onChangeFilterColumn(key),
        uncheckedId: uncheckedId,
        close: () => close(),
      }),
    align: 'left',

    filterIcon: () => {
      return dropDownFilterState ? (
        <ArrowDown width={12} height={12} />
      ) : (
        <ArrowUP width={12} height={12} />
      );
    },
    onFilterDropdownOpenChange: (visible) => {
      setDropDownFilterState(visible);
    },
    render: (text: string) => <span>{text}</span>,
  });

  let columns: ColumnsType<EntityLimits> = React.useMemo(
    () =>
      [
        {
          title: 'OBLIGOR',
          dataIndex: 'obligor',
          key: 1,
          ...getColumnSearchProps('obligorLegalEntityId'),
          render: (text: string, rec: Partial<EntityLimits>) => (
            <span> {rec.entity_obligor?.name}</span>
          ),
        },

        {
          title: 'COUNTRY',
          dataIndex: 'country_id',
          key: 2,
          ...getColumnSearchProps('countryId'),
          render: (text: string) => {
            const region = handleRegion(countries, text);
            return (
              region && (
                <FlagItem code={region.code} region_name={region?.name} />
              )
            );
          },
        },
        {
          title: 'AMOUNT',
          dataIndex: 'amount',
          key: 3,
          ...getColumnSearchProps('amount'),
          render: (text: number) => {
            return (
              <span>
                <b> {amountFormat(text?.toFixed(2)) || '0.00'}</b>
              </span>
            );
          },
        },
        {
          title: 'CURRENCY',
          dataIndex: 'currency',
          key: 4,
          ...getColumnSearchProps('currency'),
          render: (text: string) => {
            if (text) {
              return (
                <span>
                  <b>{text?.toUpperCase()}</b>
                </span>
              );
            }
          },
        },
        {
          title: 'STATUS',
          dataIndex: 'status',
          key: 5,
          ...getColumnSearchProps('status'),
          render: (text: string) => {
            if (text) {
              return (
                <span className={`gray-rounded`}>
                  <b>
                    {exportedDropdownValue(
                      terms?.getConst(TermConst.limitStatus) || [],
                      text,
                    )}
                  </b>
                </span>
              );
            }
          },
        },

        {
          title: 'NATURE',
          dataIndex: 'nature',
          key: 6,
          ...getColumnSearchProps('nature'),
          render: (text: string) => {
            if (text) {
              return (
                <span>
                  <b>
                    {exportedDropdownValue(
                      terms?.getConst(TermConst.limitNature) || [],
                      text,
                    )}
                  </b>
                </span>
              );
            }
          },
        },
        {
          title: 'LIMIT DATE',
          dataIndex: 'limit_date',
          key: 7,
          ...getColumnSearchProps('limitDate'),
          render: (text: string) => {
            if (text) {
              return (
                <span>
                  <b>{exportDateUi(new Date(text))?.toString()}</b>
                </span>
              );
            }
          },
        },
        {
          title: 'EXPIRY DATE',
          dataIndex: 'expiry_date',
          key: 8,
          ...getColumnSearchProps('expiryDate'),
          render: (text: string) => {
            if (text) {
              return (
                <span>
                  <b>{exportDateUi(new Date(text))?.toString()}</b>
                </span>
              );
            }
          },
        },
        {
          title: 'PRICING',
          dataIndex: 'pricing',
          key: 8,
          ...getColumnSearchProps('pricing'),
          render: (text: string) => {
            if (text) {
              return (
                <span>
                  <b>{text}</b>
                </span>
              );
            }
          },
        },
        {
          title: '',
          dataIndex: 'id',
          key: 10,
          width: 120,
          hidden:
            !isCan('write', Menu.participation) &&
            !isCan('delete', Menu.participation),
          onCell: () => {
            return {
              onClick: (event: any) => {
                event.stopPropagation();
              },
            };
          },
          render: (text: number, record: EntityLimits) => {
            return (
              <>
                {isCan('write', Menu.participation) && (
                  <span style={{ minWidth: 95 }}>
                    <i
                      className="rounded-icon"
                      onClick={() => props.edit(text, record)}
                    >
                      <Edit height="30" />
                    </i>
                  </span>
                )}

                {isCan('delete', Menu.participation) && (
                  <span style={{ minWidth: 55 }}>
                    <i
                      className="rounded-icon"
                      onClick={() => props.handleDelete(text)}
                    >
                      <Delete height="30" />
                    </i>
                  </span>
                )}
              </>
            );
          },
        },
      ].filter((el) => !el.hidden),
    [props.limitsList, dropDownFilterState, uncheckedId],
  );

  const keyToFilter =
    props.market === 'violence_and_terrorism' ||
    (props.market === 'trade_credit' && props.deal_type === 'pri')
      ? 1
      : -1;

  columns = columns.filter((el) => el.key !== keyToFilter);

  return { columns, uncheckedId };
}
