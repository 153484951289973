import React, { useState } from 'react';
import { Checkbox } from 'antd';
import { InputFormDetailCofarcoTeam } from '../../../@types/cofarco-teams/type';
import { useForm } from 'react-hook-form';
import { ColumnsType } from 'antd/es/table';
import { useUpdateCofarcoMutation } from '../../../redux/api/ws/databases/cofarco-teams';
import {
  exportDateWs,
  handleRigthName,
  setDefaultValues,
  transpose,
} from '../../../utils/helper-function';
import { updateNotificationMessage } from '../../../redux/slice/notificationSlice';
import { useAppDispatch } from '../../../hooks/redux';

export type DataType = {
  object: string;
  read: boolean;
  create: boolean;
  delete: boolean;
  write: boolean;
  extract: boolean;
};

type IProps = {
  defaultFormValues: InputFormDetailCofarcoTeam | null;
  closingModal: any;
};

type CopyValidationData = {
  enquireValidate: string;
  mandate: string;
  invoice: string;
  policy: string;
};

export default function UseModalDetail(props: IProps) {
  const [dataSourceRigth, setDataSourceRigth] = useState<Array<DataType>>([]);
  const [dataSourceRigthCopy, setDataSourceRigthCopy] = useState<
    Array<DataType>
  >([]);
  const [listAllChecked, setListAllChecked] = useState<Array<keyof DataType>>(
    [],
  );
  const [enquireValidate, setEnquireValidate] = useState<string>('');
  const [mandate, setMandate] = useState<string>('');
  const [invoice, setInvoice] = useState<string>('');
  const [policy, setPolicy] = useState<string>('');
  const [isEditingForm, setIsEditingForm] = useState<boolean>(false);
  const [validateCopy, setValidateCopy] = useState<CopyValidationData>({
    enquireValidate: '',
    mandate: '',
    invoice: '',
    policy: '',
  });

  const dispatch = useAppDispatch();
  const {
    register: registerDetail,
    handleSubmit: handleSubmitDetail,
    formState: { errors: errorDetail, isDirty },
    control: controlDetail,
    watch: watchDetail,
    getValues: getValueDetail,
    setValue: setValueDetail,
    reset: resetDetail,
  } = useForm<InputFormDetailCofarcoTeam>({ mode: 'onBlur' });

  const [updateCofarco, { isLoading: isLoadingUpdate }] =
    useUpdateCofarcoMutation();

  const headerComponent = (type: keyof DataType) => {
    return (
      <div className="d-flex flex-row" onClick={() => handleCheckedAll(type)}>
        <span>
          <i color="#1A171B">
            <Checkbox checked={!!listAllChecked.find((el) => el === type)} />
          </i>
        </span>
        <div style={{ paddingLeft: 12 }}>
          <span>{type === 'write' ? 'UPDATE' : type.toLocaleUpperCase()}</span>
        </div>
      </div>
    );
  };

  const specialRights = ['dashboard', 'pipe'];
  const exlureToExtract = ['dashboard'];

  const columns: ColumnsType<DataType> = [
    {
      title: 'Object',
      dataIndex: 'object',
      key: 1,
      render: (text: string) => {
        return (
          <>
            <span>{handleRigthName(text)}</span>
          </>
        );
      },
    },
    {
      title: headerComponent('read'),
      dataIndex: 'read',
      key: 2,
      render: (text: boolean, rec: any) => {
        return (
          <>
            <span className="ml-1">
              <i>
                <Checkbox
                  checked={text}
                  onChange={() => handleChecked(!text, rec, 'read')}
                />
              </i>
            </span>
          </>
        );
      },
    },
    {
      title: headerComponent('create'),
      dataIndex: 'create',
      key: 3,
      render: (text: boolean, rec: any) => {
        return (
          <>
            <span>
              {!specialRights.includes(rec.object as string) && (
                <i>
                  <Checkbox
                    checked={text}
                    onChange={() => handleChecked(!text, rec, 'create')}
                  />
                </i>
              )}
            </span>
          </>
        );
      },
    },

    {
      title: headerComponent('write'),
      dataIndex: 'write',
      key: 4,
      render: (text: boolean, rec: any) => {
        return (
          <>
            <span>
              {!specialRights.includes(rec.object as string) && (
                <i>
                  <Checkbox
                    checked={text}
                    onChange={() => handleChecked(!text, rec, 'write')}
                  />
                </i>
              )}
            </span>
          </>
        );
      },
    },

    {
      title: headerComponent('delete'),
      dataIndex: 'delete',
      key: 5,
      render: (text: boolean, rec: any) => {
        return (
          <>
            {!specialRights.includes(rec.object as string) && (
              <span>
                <i>
                  <Checkbox
                    checked={text}
                    onChange={() => handleChecked(!text, rec, 'delete')}
                  />
                </i>
              </span>
            )}
          </>
        );
      },
    },

    {
      title: headerComponent('extract'),
      dataIndex: 'extract',
      key: 6,
      render: (text: boolean, rec: any) => {
        return (
          <>
            {!exlureToExtract.includes(rec.object as string) && (
              <span>
                <i>
                  <Checkbox
                    checked={text}
                    onChange={() => handleChecked(!text, rec, 'extract')}
                  />
                </i>
              </span>
            )}
          </>
        );
      },
    },
  ];

  const handleChecked = React.useCallback(
    (value: boolean, rec: any, col: any) => {
      const updateDataSource = dataSourceRigth.map((el) => {
        setIsEditingForm(true);
        if (el.object === rec.object) {
          return {
            ...el,
            [col]: value,
          };
        } else {
          return el;
        }
      });
      setDataSourceRigth(updateDataSource);
    },
    [dataSourceRigth],
  );

  const handleCheckedAll = (type: keyof DataType) => {
    const isTrue = listAllChecked.find((el) => el === type);
    const updatedDataSource = dataSourceRigth.map((el: DataType) => {
      return {
        ...el,
        [type]: isTrue ? false : true,
      };
    });

    if (isTrue) {
      setListAllChecked(listAllChecked.filter((el) => el !== type));
    } else {
      setListAllChecked([...listAllChecked, type]);
    }
    setIsEditingForm(true);
    setDataSourceRigth(updatedDataSource);
  };

  const submitDetail = async (data: InputFormDetailCofarcoTeam) => {
    const tempRigths = dataSourceRigth.reduce((a, curr) => {
      if (curr?.object === 'dashboard') {
        return {
          ...a,
          [curr?.object]: {
            read: curr.read,
          },
        };
      }
      if (curr?.object === 'pipe') {
        return {
          ...a,
          [curr?.object]: {
            read: curr.read,
            extract: curr.extract,
          },
        };
      }
      return {
        ...a,
        [curr?.object]: {
          create: curr.create,
          delete: curr.delete,
          read: curr.read,
          write: curr.write,
          extract: curr.extract,
        },
      };
    }, {});

    const tempParams = { ...data };

    if (tempParams?.username) {
      delete tempParams.username;
    }

    const params: InputFormDetailCofarcoTeam = {
      ...tempParams,
      entry_date: exportDateWs(tempParams?.entry_date) || null,
      departure_date: exportDateWs(tempParams?.departure_date) || null,
      rights: {
        ...tempRigths,
        history: {
          read: data.history,
        },
      },
      enquiry_validation: enquireValidate,
      mandate_validation: mandate,
      invoice_raising: invoice,
      policy_validation: policy,
    };
    if (data.is_admin) {
      delete params?.rights;
    }

    try {
      const response = await updateCofarco(params).unwrap();
      if (response && response.success) {
        dispatch(
          updateNotificationMessage({
            title: 'Successfully updated',
            body: `Update Cofarco Team`,
            show: true,
          }),
        );
      }
      props.closingModal();
    } catch (error: any) {
      props.closingModal();
    }
  };

  React.useEffect(() => {
    if (props.defaultFormValues) {
      resetDetail({
        ...props.defaultFormValues,
      });
      setMandate(props.defaultFormValues.mandate_validation);
      setInvoice(props.defaultFormValues.invoice_raising);
      setPolicy(props.defaultFormValues.policy_validation);
      setEnquireValidate(props.defaultFormValues.enquiry_validation);

      const rights = (props.defaultFormValues?.data_source_rigth || []).filter(
        (r) => r.object !== 'history',
      );
      setDataSourceRigth(rights);
      setDataSourceRigthCopy(rights);

      setValidateCopy({
        mandate: props.defaultFormValues.mandate_validation,
        enquireValidate: props.defaultFormValues.enquiry_validation,
        invoice: props.defaultFormValues.invoice_raising,
        policy: props.defaultFormValues.policy_validation,
      });

      const transposed = transpose(
        props.defaultFormValues.data_source_rigth || [],
      );

      const selected: Array<keyof DataType> = [];

      for (const property in transposed) {
        if (transposed[property].every((v: any) => v === true)) {
          selected.push(property as keyof DataType);
        }
      }
      setListAllChecked(selected);
    }
  }, [props.defaultFormValues]);

  const resetForm = () => {
    const defaultValue =
      setDefaultValues<InputFormDetailCofarcoTeam>(watchDetail());
    setIsEditingForm(false);
    resetDetail(defaultValue);
  };

  return {
    columns,
    registerDetail,
    controlDetail,
    watchDetail,
    submitDetail,
    handleSubmitDetail,
    errorDetail,
    getValueDetail,
    setValueDetail,
    enquireValidate,
    setEnquireValidate,
    mandate,
    setMandate,
    invoice,
    setInvoice,
    policy,
    setPolicy,
    dataSourceRigth,
    handleChecked,
    isLoadingUpdate,
    resetDetail,
    isDirty,
    resetForm,
    isEditingForm,
    setIsEditingForm,
    validateCopy,
    dataSourceRigthCopy,
    setDataSourceRigth,
  };
}
