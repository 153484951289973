import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../data/config';
import { axiosBaseQuery } from './axiosBaseQuery';

export const baseApi = createApi({
  tagTypes: [
    'ATTACHEMENT_ENQUIRY',
    'LIST_LEGAL_ENTITY',
    'LIST_PEOPLE_BY_SEARCH',
    'CounterParty',
    'IndicationAttachment',
    'LIST_GROUPE',
  ],
  baseQuery: axiosBaseQuery({
    baseUrl: config.baseUrl,
  }),
  endpoints: () => ({}),
  refetchOnMountOrArgChange: true,
});
