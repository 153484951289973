import { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import classes from './ExportDropdown.module.scss';

import { ReactComponent as ExcelIcon } from './../../assets/img/excel.svg';
import toCamelCase from '../../utils/toCamelCase';
import ClickAwayListener from 'react-click-away-listener';

type Props<T> = {
  columns?: (keyof T)[];
  values?: T;
  defaultSelectedColumns?: (keyof T)[];
  onExport?: (k: (keyof T)[], callback: () => void) => any;
  cbName?: (txt: string) => string;
};

export default function ExportDropdown<T>({
  columns,
  defaultSelectedColumns,
  values,
  onExport,
  cbName,
}: Props<T>) {
  const [selectedColums, setSelectedColums] = useState<(keyof T)[]>(
    defaultSelectedColumns ?? []
  );

  const onSelectElement = (e: keyof T) => {
    if (selectedColums.includes(e)) {
      setSelectedColums(selectedColums.filter((s) => e !== s));
    } else {
      setSelectedColums([...selectedColums, e]);
    }
  };

  const closeDrop = () => {
    setShowDrop(false);
  };
  const clearAll = () => {
    setSelectedColums([]);
  };

  const exportCsv = async () => {
    if (onExport) {
      await onExport(
        (columns
          ? columns
          : values
            ? (Object.keys(values) as (keyof T)[])
            : []
        ).filter((e) => selectedColums.includes(e)),
        () => closeDrop()
      );
      closeDrop();
    }
  };

  useEffect(() => {
    setSelectedColums(defaultSelectedColumns ?? []);
  }, []);

  const [showDrop, setShowDrop] = useState<boolean>();
  return (
    <ClickAwayListener onClickAway={() => { setShowDrop(false) }}    >
      <div className={`mt-1 me-3 position-relative `}>
        <div
          className={`d-flex justify-content-between px-3 py-2 ${classes.dropdownButton}`}
          onClick={() => setShowDrop(!showDrop)}
        >
          <div>Export Fields</div>
          <div className='ms-3 me-1'>
            {showDrop ? (
              <FaChevronUp style={{ color: '#6d1206' }} />
            ) : (
              <FaChevronDown style={{ color: '#6d1206' }} />
            )}
          </div>
        </div>
        <Dropdown.Menu
          align='end'
          show={showDrop}
          className={`${classes.dropdown}`}
        >
          <Dropdown.Header>
            <div
              onClick={clearAll}
              className={`d-flex justify-content-end ${classes.dropClear}`}
            >
              Clear all
            </div>
          </Dropdown.Header>
          <div className={`${classes.dropdownItems}`}>
            {values
              ? Object.keys(values)?.map((e, index) => {
                const key = e as keyof T;
                return (
                  <div
                    className={`mx-2 d-flex`}
                    key={`${index}-${e.toString()}-${new Date().toDateString()}`}
                  >
                    <span
                      onClick={() => onSelectElement(key)}
                      className={` px-3 py-1 ${classes.dropdownItem} ${selectedColums.includes(key) &&
                        classes.dropdownItemHovered
                        }`}
                    >
                      {values[key] as string}
                    </span>
                  </div>
                );
              })
              : columns?.map((e, index) => {
                return (
                  <div
                    className={`mx-2 d-flex`}
                    key={`${index}-${e.toString()}-${new Date().toDateString()}`}
                  >
                    <span
                      onClick={() => onSelectElement(e)}
                      className={` px-3 py-1 ${classes.dropdownItem} ${selectedColums.includes(e) &&
                        classes.dropdownItemHovered
                        }`}
                    >
                      {cbName
                        ? cbName(e.toString())
                        : toCamelCase(e.toString(), 0, ' ')}
                    </span>
                  </div>
                );
              })}
          </div>
          <Dropdown.Header
            className={`${classes.dropFooter} d-flex justify-content-end mx-0 px-0`}
          >
            <div className=' me-3'>
              <NavLink
                className='btn red py-2'
                to='#'
                onClick={() => exportCsv()}
              >
                <span className='me-2'>Export</span>
                <ExcelIcon />
              </NavLink>
            </div>
          </Dropdown.Header>
        </Dropdown.Menu>
      </div>
    </ClickAwayListener>
  );
}
