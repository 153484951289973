import { useMemo } from 'react';
import {
  useRueQuery,
  useRumQuery,
} from '../../redux/api/ws/dashboard/dashboard';
import { amountFormat } from '../../utils/helper-function';

export const useDasboardAmountsCard = () => {
  const formatNumber = (amount: string | number) => {
    const formatted = amountFormat(amount);
    if (formatted.endsWith('.00')) return formatted.replace('.00', '');
    return formatted;
  };

  const { data: rueData } = useRueQuery({});
  const { data: rumData } = useRumQuery({});

  const alerts = useMemo(() => {
    return [
      {
        title: 'RISK UNDER MANAGEMENT',
        amount: `$ ${formatNumber(rumData?.rum || 0)}`,
      },
      {
        title: 'RISK UNDER ENQUIRY',
        amount: `$ ${formatNumber(rueData?.rue || 0)}`,
      },
    ];
  }, [rueData, rumData]);

  return { alerts, formatNumber };
};
