import { useState } from 'react';
import { useListCounterPartyQuery } from '../../../redux/api/ws/counterparties/get';
import { useListEnquiryQuery } from '../../../redux/api/ws/indication/get';
import { useListTermsQuery } from '../../../redux/api/ws/terms/get';

const useIndicationFilter = () => {
  const [underWriterQuery, setUnderWriterQuery] = useState<string>('');
  const [clientQuery, setClientQuery] = useState<string>('');
  const [enquiryQuery, setEnquiryQuery] = useState<string>('');

  const { data: terms } = useListTermsQuery(undefined, {
    refetchOnMountOrArgChange: false,
  });

  const { data: enquiries } = useListEnquiryQuery({
    query: enquiryQuery,
  });
  const { data: underwrites } = useListCounterPartyQuery({
    page: 1,
    start: 0,
    limit: 100,
    query: underWriterQuery,
    businessTypes: 'underwriter,financier',
  });

  const { data: clients } = useListCounterPartyQuery({
    page: 1,
    start: 0,
    limit: 100,
    query: clientQuery,
    businessType: 'client',
  });

  return {
    terms: terms ? terms.term : [],
    underwriters: underwrites ? underwrites.counterparty : [],
    enquiries: enquiries ? enquiries.enquiry : [],
    clients: clients ? clients.counterparty : [],
    setUnderWriterQuery,
    setEnquiryQuery,
    setClientQuery,
  };
};

export default useIndicationFilter;
