import { Col, Card } from 'antd';

type Props = {
  data: {
    title: string;
    amount: string;
  };
};
export const DashboardCardAmount = ({ data }: Props) => {
  return (
    <Col xs={24} md={8}>
      <Card size="small" className="dashboard-base-card" bordered={false}>
        <span data-testid="base-card-title" className="base-card-title">
          {data.title}
        </span>
        <div
          data-testid="dashboard-alert-body"
          className="dashboard-alert-body"
        >
          {data.amount}
        </div>
      </Card>
    </Col>
  );
};
