export const mockAgency = [
  {
    label: 'AM Best',
    value: 'ambest',
  },
  {
    label: 'Fitch',
    value: 'fitch',
  },
  {
    label: "Moody's",
    value: 'moodys',
  },
  {
    label: "Standard & Poor's",
    value: 'sp',
  },
];
