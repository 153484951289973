import { Button, Card, Space, Tooltip } from "antd";
import CofarcoNumberAmount from "../Form/CofarcoNumberAmount";
import { useForm } from "react-hook-form";
import { useEffect, useMemo, useState } from "react";
import {
  useRevQuery,
  useSaveRevMutation,
} from "../../redux/api/ws/dashboard/dashboard";
import { store } from "../../redux/store";

type Props = {
  formatNumber: (amount: any) => string;
};
export const DashboardRev = ({ formatNumber }: Props) => {
  const user = store.getState().auth.user;

  const isAdmin = useMemo(() => {
    return user?.is_admin === 1 && user?.is_inactif === 0;
  }, [user]);

  const { data: dataRev, refetch: refetchRev } = useRevQuery({});
  const [saveRev] = useSaveRevMutation();
  const { register, watch, getValues, setValue } = useForm<{ amount: string }>({
    mode: "onBlur",
    defaultValues: {
      amount: "0",
    },
  });

  useEffect(() => {
    setValue("amount", formatNumber(dataRev?.rev || 0));
  }, [dataRev]);

  const [isReadMode, setReadMode] = useState(true);

  const getValue = () => {
    const { amount } = getValues();
    return Number(amount.trim().replaceAll("'", "") || "");
  };
  const submitSave = async () => {
    const newAmount = getValue();
    setReadMode(true);
    if (newAmount === dataRev?.rev) {
      await refetchRev();
      return;
    }
    saveRev({ amount: newAmount })
      .then(async () => {
        await refetchRev();
      })
      .catch((err) => console.log(err));
  };

  const onBlur = () => {
    setTimeout(() => {
      if (getValue() === dataRev?.rev) {
        setReadMode(true);
      }
    }, 2000);
  };

  const onChangeMode = () => {
    if (isAdmin) {
      setReadMode(false);
    }
  };

  if (!isAdmin) {
    return (
      <Card size="small" className="dashboard-base-card" bordered={false}>
        <span className="base-card-title">12-MONTH REVENUE</span>
        <div className="dashboard-alert-body">
          € {formatNumber(getValues("amount").replaceAll("'", ""))}
        </div>
      </Card>
    );
  }

  return (
    <Card size="small" className="dashboard-base-card" bordered={false}>
      <span className="base-card-title">12-MONTH REVENUE</span>
      {isReadMode ? (
        <Tooltip color="#C72506" title="Click here to edit">
          <div
            onClick={onChangeMode}
            className="dashboard-alert-body"
            style={{ cursor: "pointer" }}
          >
            € {formatNumber(getValues("amount").replaceAll("'", ""))}
          </div>
        </Tooltip>
      ) : (
        <Space size={10} style={{ marginTop: 6 }}>
          <CofarcoNumberAmount
            key_form="amount"
            type="number"
            autoFocus
            onBlur={onBlur}
            register={register}
            setValue={setValue}
            watch={watch}
            isAceeptFloat={true}
          />

          <Button
            size="large"
            style={{
              background: "var(--Rouge-vif)",
              border: 0,
              color: "var(--Blanc)",
              marginBottom: 8,
            }}
            onClick={submitSave}
          >
            Submit
          </Button>
        </Space>
      )}
    </Card>
  );
};
