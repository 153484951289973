import React, { useEffect, useState } from 'react';
import { Typography } from 'antd';
import UseDynamicsTable from '../../../dynamic-table/use-dynamics-table';
import { TPaymentInfo, TRattings } from './ModalLegalEntity';
import { ReactComponent as Edit } from '../../../../assets/img/Edit.svg';
import { ReactComponent as Delete } from '../../../../assets/img/Delete.svg';
import {
  InputFormLegalEntity,
  LegalEntity,
} from '../../../../@types/legal-entities';
import { useForm } from 'react-hook-form';
import {
  useGetCountryQuery,
  useGroupQuery,
} from '../../../../redux/api/ws/databases/groups';
import {
  useCreateLegalEntityMutation,
  useSearchLegalEntityNameMutation,
  useUpdateLegalEntityMutation,
} from '../../../../redux/api/ws/databases/legale-entities';
import { mockCity } from '../../../../utils/mock-city';
import { DropDownOptionType } from '../../../common/DropDown/DropdownSelect';
import { Dispatch, SetStateAction } from 'react';
import {
  checkIsEdit,
  exportDateUi,
  exportDateWs,
  isCan,
  isExist,
  setDefaultValues,
} from '../../../../utils/helper-function';
import { EntityGroupe, InputFieldsGroup } from '../../../../@types/group/types';
import UseFetchPeople from '../../../../hooks/UseFetchPeople';
import { People } from '../../../../@types/people';
import { useAppDispatch } from '../../../../hooks/redux';
import { updateNotificationMessage } from '../../../../redux/slice/notificationSlice';
import { Menu } from '../../../../@types/common-types';
import { mockAgency } from '../../../../utils/mock-agency';
import { updateModalAction } from '../../../../redux/slice/modalSlice';
import { isEmpty } from 'lodash';

type IProps = {
  defaultFormValues: InputFormLegalEntity | null;
  closingModal: (legalEntity: LegalEntity | null) => void;
};
export default function UseModalLegalEntity(props: IProps) {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, dirtyFields },
    control,
    watch,
    getValues,
    setValue,
    reset,
    clearErrors,
    setError,
  } = useForm<InputFormLegalEntity>({
    mode: 'onChange',
    defaultValues: props.defaultFormValues || {},
  });

  const [paramsGroup, setParamsGroup] = useState<TParamsQuery>({
    page: 1,
    start: 0,
    limit: 100,
    sort: JSON.stringify([{ property: 'name', direction: 'ASC' }]),
    filter: '[]',
  });

  const [pLxits, setLexists] = useState(false);

  const {
    data: groups,
    isLoading: isGrpLoading,
    isFetching: isGrpFetching,
  } = useGroupQuery(paramsGroup);

  const [peopleSelected, setPeopleSelected] = useState<Array<People>>([]);
  const [isCompletedForm, setIsCompletedForm] = useState(false);
  const [rattingsFormNotValid, setRattingsFormNotValid] = useState(false);
  const [currencyFormNotValid, setCurrencyFormNotValid] = useState(false);
  const dispatch = useAppDispatch();
  const { data: responseCountry } = useGetCountryQuery();
  const [dataSourceLegalEntity, setDataSourceLegalEntity] = useState<
    Array<LegalEntity>
  >([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isFormEditing, setIsFormEditing] = useState<boolean>(false);

  const [isShowModalUnsavedChange, setIsShowModalUnsavedChange] =
    useState<boolean>(false);
  const [showOtherModaldata, setShowOtherModal] = useState<any>();

  const [dataGroup, setDataGroup] = useState<EntityGroupe | null | undefined>(
    null,
  );
  const [showGroup, setShowGroup] = useState(false);

  const modalTitle = isExist(props.defaultFormValues?.id)
    ? 'Legal Entity - ' + props.defaultFormValues?.name
    : ' New - Legal Entity';
  const [
    updateLegalEntity,
    { isLoading: isLoadingUpdateGroupe, isSuccess: isUpdated },
  ] = useUpdateLegalEntityMutation();
  const groupName = watch('group_name');
  const city = watch('city');
  const country = watch('country_id');
  const id = watch('id');

  const onClickGroup = (gr: any, noCheck?: boolean) => {
    if (!isEmpty(dirtyFields) && !noCheck && isEdit) {
      setIsShowModalUnsavedChange(true);
      setShowOtherModal(gr);
    } else {
      dispatch(
        updateModalAction({
          data: gr,
          isAdd: true,
          type: 'group',
        }),
      );
      setIsShowModalUnsavedChange(false);
      setShowOtherModal(null);
      isEdit && resetForm();
    }
  };

  const [findLegalEntity] = useSearchLegalEntityNameMutation();

  const [
    createLegalEntity,
    { isLoading: isLoadingCreateLegalEntity, isSuccess: isCreated },
  ] = useCreateLegalEntityMutation();

  const [defaultFormValuesGroup, setDefaultFormValuesGroup] =
    useState<InputFieldsGroup | null>(null);

  const providerGroup = UseFetchPeople();

  // update group name option when group name change
  useEffect(() => {
    if (dataGroup) {
      setValue('group_name', dataGroup?.name);
    }
    return () => {
      setValue('group_name', '');
    };
  }, [dataGroup]);

  const columnRating: any = [
    {
      title: 'AGENCY',
      dataIndex: 'agency',
      key: 1,
      editable: true,
      render: (value: any) => <div style={{ minWidth: '180px' }}>{value}</div>,
    },
    {
      title: 'RATING',
      dataIndex: 'ratting',
      key: 2,
      editable: true,
      required: true,
      render: (value: any) => <div style={{ minWidth: '180px' }}>{value}</div>,
    },
    {
      title: 'OUTLOOK',
      dataIndex: 'outlook',
      key: 3,
      editable: true,
      required: true,
      render: (value: any) => <div style={{ minWidth: '180px' }}>{value}</div>,
    },
    {
      title: 'DATE',
      dataIndex: 'date',
      key: 4,
      editable: true,
      required: true,
      render: (value: any) => <div style={{ minWidth: '180px' }}>{value}</div>,
    },
    {
      title: '',
      dataIndex: 'id',
      key: 4,
      width: 150,
      hidden:
        !isCan('write', Menu.legal_entity) &&
        !isCan('delete', Menu.legal_entity),
      render: (text: number, record: TRattings) => {
        const editable = isEdittingRattins(record);
        return editable ? (
          <span>
            <Typography.Link
              style={{ marginRight: 8 }}
              onClick={() => {
                setValue('isUpdateRatting', true, { shouldDirty: true });
                saveRattings(record.key);
              }}
            >
              Update
            </Typography.Link>
            <Typography.Link
              style={{ marginRight: 8 }}
              onClick={cancelRattings}
            >
              Cancel
            </Typography.Link>
          </span>
        ) : (
          <>
            <Typography.Link
              disabled={editinKeyRattings !== ''}
              onClick={() => {
                setIsFormEditing(true);
                const selectedAgency = mockAgency?.find(
                  (el) => el.label === record?.agency,
                );
                editRattings({ ...record, agency: selectedAgency?.value });
              }}
              style={{ marginRight: 10 }}
            >
              <Edit height="30" />
            </Typography.Link>
            <Typography.Link
              disabled={editinKeyRattings !== ''}
              onClick={() => {
                setIsFormEditing(true);
                removeItemRattings(record.key);
              }}
            >
              <Delete height="30" />
            </Typography.Link>
          </>
        );
      },
    },
  ].filter((el) => !el.hidden);

  const {
    edit: editRattings,
    save: saveRattings,
    mergedColumns: mergedRattings,
    cancel: cancelRattings,
    isEditing: isEdittingRattins,
    editingKey: editinKeyRattings,
    form: formRattings,
    dataSource: dataSourceRattings,
    removeItem: removeItemRattings,
    add: addRattings,
    setDataSource: setDataSourceRattings,
    setEditingKey: setEditingRating,
    isInModeEditing: isEditingRatting,
  } = UseDynamicsTable<TRattings>({
    rowItem: {
      id: 0,
      key: 0,
      agency: '',
      ratting: 'non rated',
      outlook: '',
      date: '',
    },
    column: columnRating,
  });

  const column: any = [
    {
      title: 'BANK NAME',
      dataIndex: 'bank_name',
      key: 1,
      editable: true,
      required: false,
    },
    {
      title: 'IBAN',
      dataIndex: 'iban',
      key: 2,

      editable: true,
      required: true,
    },
    {
      title: 'BIC',
      dataIndex: 'bic',
      key: 3,
      editable: true,
      required: false,
    },
    {
      title: 'CURRENCY',
      dataIndex: 'currency',
      key: 4,
      editable: true,
      required: true,
      align: 'center',
    },
    {
      title: 'INFO',
      dataIndex: 'info',
      key: 5,
      editable: true,
      required: false,
    },
    {
      title: '',
      dataIndex: 'id',
      key: 4,
      width: 150,
      hidden:
        !isCan('write', Menu.legal_entity) &&
        !isCan('delete', Menu.legal_entity),
      render: (text: number, record: TPaymentInfo) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              style={{ marginRight: 8, color: 'gray' }}
              onClick={() => {
                setValue('isUpdatePayement', true, { shouldDirty: true });
                save(record.key);
              }}
            >
              Update
            </Typography.Link>
            <Typography.Link
              style={{ marginRight: 8, color: 'gray' }}
              onClick={cancel}
            >
              Cancel
            </Typography.Link>
          </span>
        ) : (
          <>
            <Typography.Link
              disabled={editingKey !== ''}
              onClick={() => {
                setIsFormEditing(true);
                edit(record);
              }}
              style={{ marginRight: 10 }}
            >
              <Edit height="30" />
            </Typography.Link>
            <Typography.Link
              disabled={editingKey !== ''}
              onClick={() => {
                setIsFormEditing(true);
                removeItem(record?.key);
              }}
            >
              <Delete height="30" />
            </Typography.Link>
          </>
        );
      },
    },
  ].filter((el) => !el.hidden);

  const {
    edit,
    save,
    mergedColumns,
    cancel,
    isEditing,
    editingKey,
    isInModeEditing: isEditingPayement,
    setEditingKey,
    form,
    dataSource,
    removeItem,
    add,
    setDataSource,
  } = UseDynamicsTable<TPaymentInfo>({
    rowItem: {
      editable: true,
      id: 0,
      key: 0,
      bank_name: '',
      iban: '',
      bic: '',
      currency: '',
      dataIndex: '',
      info: '',
    },
    column: column,
  });

  const submitCreateLegalEntity = async (params: InputFormLegalEntity) => {
    try {
      const respose = await createLegalEntity(params).unwrap();
      if (respose && respose.success) {
        setDataSourceLegalEntity([
          respose.legalentity,
          ...dataSourceLegalEntity,
        ]);
        dispatch(
          updateNotificationMessage({
            title: 'Add Legal Entity',
            body: 'Successfully added',
            show: true,
          }),
        );
        props.closingModal(respose.legalentity);
      }

      resetForm();
    } catch (error: any) {
      console.log(error);
    }
  };

  const getInputParams = (data: InputFormLegalEntity) => {
    const ibans = dataSource.map((el) => {
      return {
        bank: el.bank_name,
        iban: el.iban,
        bic: el.bic,
        currency: el.currency,
        info: el.info,
        id: 'extModel752-1',
      };
    });

    const ratings = dataSourceRattings.map((el) => ({
      agency: el.agency,
      rating: el.ratting,
      outlook: el.outlook,
      date: exportDateWs(el.date),
      id: 'extModel754-1',
    }));

    return {
      id: data?.id ? data?.id : -1,
      group_id: data.group_id,
      name: data?.name,
      type: data.type,
      address: data.address,
      zip: data.zip,
      city: data.city,
      country_id: data.country_id,
      vat_number: data.vat_number,
      firm_number: data.firm_number,
      ibans,
      ratings,
    };
  };

  const submitEntity = async (data: InputFormLegalEntity) => {
    const isUpdated = checkIsEdit(data.id);
    const params = getInputParams(data);

    if (isUpdated) {
      try {
        const response = await updateLegalEntity(params).unwrap();
        if (response && response.success) {
          dispatch(
            updateNotificationMessage({
              title: 'Update Legal Entity',
              body: 'Successfully Updated',
              show: true,
            }),
          );
          props.closingModal(null);
        }

        resetForm();
      } catch (error: any) {
        console.log(error);
      }
    } else {
      const response: any = await findLegalEntity({
        page: 1,
        start: 0,
        filter: JSON.stringify([
          {
            property: 'name',
            value: params.name,
            operator: 'like',
          },
        ]),
        limit: 0,
        query: undefined,
      });

      if (response?.data?.legalentity?.length) {
        const found = response.data.legalentity[0] as LegalEntity;
        if (found.name.length === params.name.length) {
          setLexists(true);
          // stop the function
          return;
        }
      }
      submitCreateLegalEntity(params);
    }
  };

  const confirmCreationExisting = () => {
    setLexists(false);
    submitCreateLegalEntity(getInputParams(getValues()));
  };
  const runSearch = React.useCallback(
    (
      value: string,
      key: string,
      dipatch?: Dispatch<SetStateAction<DropDownOptionType[]>>,
    ) => {
      if (key === 'group_id') {
        setParamsGroup({
          ...paramsGroup,
          query: `%${value?.trim()}%`,
        });
      } else if (key === 'city') {
        const res =
          mockCity?.filter((word) =>
            word.value
              ?.toLocaleLowerCase()
              .includes(value?.toLocaleLowerCase()),
          ) ?? [];
        if (dipatch) dipatch(res);
      } else if (key === 'country_id') {
        const res =
          (responseCountry?.country || [])
            .map((el) => ({ key: el.key, value: el.name }))
            .filter((word) =>
              word.value
                ?.toLocaleLowerCase()
                .includes(value?.toLocaleLowerCase()),
            ) ?? [];
        if (dipatch) dipatch(res);
      }
    },
    [responseCountry?.country, mockCity],
  );

  React.useEffect(() => {
    if (props.defaultFormValues) {
      const ratting = props.defaultFormValues?.ratings?.map((rat, index) => ({
        ...rat,
        ratting: rat.rating,
        id: index,
        key: index,
        editable: true,
        date: exportDateUi(rat.date),
      }));
      const ibans = props.defaultFormValues?.ibans?.map((iban, index) => ({
        ...iban,
        bank_name: iban.bank,
        id: index,
        key: index,
        editable: true,
      }));
      reset({
        ...props.defaultFormValues,
        group: props.defaultFormValues.group,
        group_name: props.defaultFormValues.group_name,
        id: props.defaultFormValues.id,
        ibans: ibans,
        ratings: ratting,
      });
      setDataSourceRattings(ratting || []);
      setDataSource(ibans || []);
      setIsEdit(
        isExist(props.defaultFormValues.id) && props.defaultFormValues.id !== -1
          ? true
          : false,
      );
      setDataGroup(props.defaultFormValues.group);
    } else {
      reset({ id: -1 });
    }
  }, [props.defaultFormValues]);

  const resetForm = () => {
    const defaultValue = setDefaultValues<InputFormLegalEntity>(watch());
    reset(defaultValue);
    setDataSource([]);
    setDataSourceRattings([]);
    setIsEdit(false);
    setDataGroup(null);
    setIsFormEditing(false);
    setEditingKey('');
    setEditingRating('');
  };

  const handleModalGroup = () => {
    setShowGroup(!showGroup);
    // props.closingModal(null);
  };

  const showGroupClick = (
    group: EntityGroupe,
    callback: (group: InputFieldsGroup | null, p: People[]) => void,
  ) => {
    if (group) {
      providerGroup.fetchPeople(group, (params, allPeople) => {
        setDefaultFormValuesGroup(params);
        setPeopleSelected(allPeople);
        // props.closingModal(null);
        // handleModalGroup();
        callback(params, allPeople);
      });
    }
  };

  React.useEffect(() => {
    const subscription = watch((value) => {
      const isCompletedRequired =
        value.name && (value.country_name || value.country_id);
      setIsCompletedForm(isCompletedRequired ? true : false);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  React.useEffect(() => {
    if (dataSourceRattings.length > 0) {
      setRattingsFormNotValid(
        dataSourceRattings.filter(
          (el) =>
            !el.date ||
            el.date === '' ||
            !el.ratting ||
            el.ratting === '' ||
            !el.outlook ||
            el.outlook === '',
        ).length > 0,
      );
    } else setRattingsFormNotValid(false);
  }, [dataSourceRattings]);

  React.useEffect(() => {
    if (dataSource.length > 0) {
      setCurrencyFormNotValid(
        dataSource.filter(
          (el) =>
            !el.currency || el.currency === '' || !el.iban || el.iban === '',
        ).length > 0,
      );
    } else setCurrencyFormNotValid(false);
  }, [dataSource]);

  return {
    control,
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    errors,
    submitEntity,
    responseCountry,
    column,
    columnRating,
    dataSource,
    dataSourceRattings,
    add,
    addRattings,
    form,
    formRattings,
    mergedRattings,
    mergedColumns,
    dataSourceLegalEntity,
    groupeOption: groups,
    isGrpLoading: isGrpLoading || isGrpFetching,
    runSearch,
    isEdit,
    setIsEdit,
    modalTitle,
    groupName,
    city,
    country,
    isLoadingUpdateGroupe,
    isLoadingCreateLegalEntity,
    reset,
    resetForm,
    isUpdated,
    isCreated,
    dataGroup,
    setDataGroup,
    showGroup,
    setShowGroup,
    handleModalGroup,
    defaultFormValuesGroup,
    showGroupClick,
    peopleSelected,
    isCompletedForm,
    isDirty,
    isFormEditing,
    setIsFormEditing,
    id,
    setEditingKey,
    isEditingRatting,
    isEditingPayement,
    clearErrors,
    setError,
    isShowModalUnsavedChange,
    setIsShowModalUnsavedChange,
    showOtherModaldata,
    dirtyFields,
    onClickGroup,
    rattingsFormNotValid,
    currencyFormNotValid,
    pLxits,
    setLexists,
    confirmCreationExisting,
  };
}

type TParamsQuery = {
  page: number | undefined;
  start: number | undefined;
  limit: number | undefined;
  sort: string;
  query?: string;
  filter: string;
};
