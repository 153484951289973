import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface AvatarState {
  path: string;
}

const initialState: AvatarState = {
  path: "",
};

export const avatarSlice = createSlice({
  name: "avatar",
  initialState,
  reducers: {
    updateAvatar: (state, action: PayloadAction<{ path: string }>) => {
      return { ...state, path: action.payload.path };
    },
  },
});

export const { updateAvatar } = avatarSlice.actions;
export default avatarSlice.reducer;
