import React, { useCallback, useState } from 'react';
import {
  formatTitleText,
  getUniqueArray,
  isExist,
  makeOptions,
  onHeaderCell,
} from '../../../../utils/helper-function';
import { Typography } from 'antd';
import DropdownSelect, {
  DropDownOptionObjectType,
} from '../../../common/DropDown/DropdownSelect';
import {
  EntityNotes,
  NoteTypes,
} from '../../../../@types/segregatedaccounts/notes';
import { ReactComponent as Edit } from '../../../../assets/img/Edit.svg';
import { ReactComponent as Delete } from '../../../../assets/img/Delete.svg';
import { useFilteredNotesAttachedInNotesMutation } from '../../../../redux/api/ws/segregatedaccounts/notes';
import { useAppDispatch } from '../../../../hooks/redux';
import { updateNotificationMessage } from '../../../../redux/slice/notificationSlice';
import classes from '../Notes.module.scss';
import { uniqBy } from 'lodash';
import { shouldValueAfterSelect } from '../../../../utils/shouldAmount';

export interface Item {
  key: number | string;
  id: number;
  noteId: { key: any; value: any } | null | undefined;
  recipients: string;
  noteAmount: string;
  isCurrentEdit?: boolean;
  number?: { key: any; value: any } | null | undefined;
  complete_ref?: string;
}

type IProps = {
  notesDataSource: Array<Item>;
  setNotesDataSource: any;
  setValue: any;
  handleRemoveItem: (item: any) => void;
  netOffNoteId: number;
  currency: string;
  noteType?: NoteTypes;
  maxTotal: number;
};

export default function ColumnAttachedNotes(props: IProps) {
  const [editingKey, setEditingKey] = useState<Item | null>(null);
  const [listNotes, setListNotes] = useState<Array<EntityNotes | any>>([]);
  const dispatch = useAppDispatch();

  const isEditing = (record: any) =>
    record?.key?.toString() === editingKey?.key?.toString() ? true : false;
  const [lunchFilterNotes, { isLoading }] =
    useFilteredNotesAttachedInNotesMutation();

  const getDefAllocation = useCallback(() => {
    let excludeIndex = -1;
    if (editingKey && typeof editingKey.key == 'number') {
      excludeIndex = editingKey.key - 1;
    }
    const summAll = (props?.notesDataSource || []).reduce(
      (acc, curr, index) => {
        if (index !== excludeIndex) {
          return acc + parseFloat(curr.noteAmount || '0');
        }
        return acc;
      },
      0,
    );
    return Math.max(props.maxTotal - summAll, 0);
  }, [props?.notesDataSource, props.maxTotal]);

  const updateDataSource = (
    key: any,
    dataIndex: string,
    v: string | { key: any; value: any } | number | Item | undefined,
    isSave: boolean = false,
    isCancel: boolean = false,
  ) => {
    const index = props?.notesDataSource.findIndex((f) => f.key === key);
    if (index > -1) {
      const newDataSource = [...(props?.notesDataSource || {})];
      if (isSave) {
        const legEnt = uniqBy([...listNotes, v], 'complete_ref')?.find(
          (el) => el.id === newDataSource[index]?.noteId?.key,
        );

        const isDifferentDevise =
          legEnt?.currency?.toLocaleLowerCase() !==
          props.currency?.toLocaleLowerCase();

        if (isDifferentDevise) {
          dispatch(
            updateNotificationMessage({
              title: 'Warning',
              body: `Note allocation can only be in the currency of the related Note regardless of the Note currency`,
              show: true,
            }),
          );
        }
        newDataSource[index] = {
          ...newDataSource[index],
          recipients: legEnt?.recipients,
          isCurrentEdit: false,
          noteId: legEnt?.noteId,
          complete_ref: legEnt?.complete_ref,
        };
        setEditingKey(null);
      } else if (isCancel) {
        newDataSource[index] = {
          ...newDataSource[index],
        };
      } else {
        const note = listNotes.find((el) => el.id === (v as any)?.key);
        const rate = note?.noteAmount || note?.amount || note?.net_rate || '';
        if (rate) {
          const max = getDefAllocation();
          newDataSource[index].noteAmount = shouldValueAfterSelect(
            rate,
            `${max}`,
            max,
          );
        }
        newDataSource[index] = {
          ...newDataSource[index],
          id: note?.id || newDataSource[index].id,
          [dataIndex]: v,
        };
      }
      props.setValue('attachedNote', 'add', {
        shouldDirty: true,
      });
      props?.setNotesDataSource(newDataSource);
    }
  };

  const getOpositeNoteType = () => {
    switch (props.noteType) {
      case NoteTypes.pcni:
        return NoteTypes.pdni;

      case NoteTypes.pdni:
        return NoteTypes.pcni;

      case NoteTypes.pdnu:
        return NoteTypes.pcnu;

      case NoteTypes.pcnu:
        return NoteTypes.pdnu;

      case NoteTypes.ccnb:
        return NoteTypes.cdnb;

      case NoteTypes.cdnb:
        return NoteTypes.ccnb;
      default:
        return props.noteType;
    }
  };

  const getNotes = async (text: string) => {
    try {
      const resp = await lunchFilterNotes({
        query: `${text?.trim()}`,
        netOffNoteId: props.netOffNoteId,
        params: { noteTypes: getOpositeNoteType() },
      }).unwrap();

      const excludeNotes = (props?.notesDataSource || []).map((n) => n.id);
      const floorResult = (resp?.note || [])
        .filter((n) => !excludeNotes.includes(n.id))
        .map((el) => {
          const tempNote = el?.complete_ref?.split(' - ') || [];
          let tempNoteAmmount = '';
          if (tempNote && tempNote?.[2]) {
            const tempS = tempNote?.[2]?.trim();
            const splitSelected = tempS?.split(' ');
            tempNoteAmmount = splitSelected?.[0] || '';
          }
          return {
            ...el,
            recipients: el?.legal_entity?.name || '',
            noteAmount: tempNoteAmmount,
            complete_ref: el?.complete_ref,
            noteId: {
              key: el?.id,
              value: el?.number,
            },
          };
        });
      setListNotes(floorResult);
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const runSearch = React.useCallback(
    async (key: keyof Item, value: string) => {
      if (key === 'noteId' && value !== undefined && value !== null) {
        await getNotes(value);
      }
    },
    [props.netOffNoteId, props.notesDataSource],
  );

  const handleDeleteNotes = (id: number | string) => {
    const found: any = props?.notesDataSource?.find(
      (el) => el.key?.toString() === id?.toString(),
    );

    if (found) {
      props.handleRemoveItem(found);
    }
  };

  const edit = (record: any) => {
    const defaultV = record.noteId?.value
      ? [
          {
            ...record,
            id: record.noteId?.key,
            complete_ref: record.noteId?.value,
          },
        ]
      : [];
    setListNotes(defaultV);
    setEditingKey({ ...record });
  };

  const cancel = () => {
    const currentEdit = props.notesDataSource?.find(
      (el) => el.key?.toString() === editingKey?.key?.toString(),
    );
    const isAlreadySave = currentEdit?.id !== 0;
    if (isAlreadySave) {
      const restoreV = props.notesDataSource?.map((el) => {
        if (el.id?.toString() === editingKey?.id?.toString()) {
          return {
            ...editingKey,
            isCurrentEdit: false,
          };
        } else {
          return el;
        }
      });
      props.setNotesDataSource(restoreV);
    } else {
      const tempD = props.notesDataSource?.filter(
        (el) => el.isCurrentEdit !== true,
      );
      props.setNotesDataSource(tempD);
    }
    setEditingKey(null);
  };

  const columns = [
    {
      title: 'Note ID',
      dataIndex: 'noteId',
      key: 1,
      editable: true,
      required: false,
      onHeaderCell: onHeaderCell,
      render: (text: { key: any; value: any }, record: any) => {
        const editable = isEditing(record);
        return editable ? (
          <div className="d-flex flex-column">
            <div style={{ maxWidth: 420 }}>
              <DropdownSelect
                preValue={record.complete_ref}
                loading={isLoading}
                showOnlyLoader={isLoading}
                onSelectOption={(e) => {
                  updateDataSource(record.key, 'noteId', {
                    key: (e as DropDownOptionObjectType).key as string,
                    value: (e as DropDownOptionObjectType).value,
                  });
                }}
                // onFocus={() => getNotes('')}
                onSearch={(d, v) => runSearch('noteId', v)}
                options={
                  text?.key
                    ? makeOptions(listNotes || [], 'complete_ref', 'id') || []
                    : getUniqueArray(
                        makeOptions(listNotes || [], 'complete_ref', 'id') ||
                          [],
                        props.notesDataSource?.map((el) => el.number?.key),
                      )
                }
              />
            </div>
          </div>
        ) : (
          <div className="py-3 ps-3">{text?.value}</div>
        );
      },
    },
    {
      title: 'Recipient',
      dataIndex: 'recipients',
      key: 2,
      onHeaderCell: onHeaderCell,
      editable: false,
      required: false,
      render: (text: string) => (
        <div
          style={{ maxWidth: 350 }}
          className={classes.ellipsis}
          title={formatTitleText(text)}
        >
          {text}
        </div>
      ),
    },
    {
      title: 'Note Amount',
      dataIndex: 'noteAmount',
      onHeaderCell: onHeaderCell,
      key: 3,
      editable: false,
      required: false,
    },

    {
      title: '',
      dataIndex: 'id',
      key: 4,
      width: 150,
      render: (text: number, record: Item) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              style={{ marginRight: 8, color: 'gray' }}
              onClick={() => {
                updateDataSource(record.key, 'save', record, true);
              }}
              disabled={!isExist(record.noteId?.value) ? true : false}
            >
              Update
            </Typography.Link>
            <Typography.Link
              style={{ marginRight: 8, color: 'gray' }}
              onClick={cancel}
            >
              Cancel
            </Typography.Link>
          </span>
        ) : (
          <>
            <Typography.Link
              disabled={editingKey !== null}
              onClick={() => {
                edit(record);
              }}
              style={{ marginRight: 10 }}
            >
              <Edit height="30" />
            </Typography.Link>
            <Typography.Link
              disabled={editingKey !== null}
              onClick={() => {
                handleDeleteNotes(record?.key);
              }}
            >
              <Delete height="30" />
            </Typography.Link>
          </>
        );
      },
    },
  ];

  return { columns, edit, setEditingKey };
}
