import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { Method } from '../../@types/common-types';

export const datas = (data: any, arg = 'application/json') => {
  if (arg === 'application/json') {
    return JSON.stringify(data);
  } else if (arg === 'multipart/form-data') {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    return formData;
  } else {
    return data;
  }
};

export function postDataService<TInput, TOutput>(
  url: string,
  queryFunction: string,
  arg: string = 'application/json'
) {
  return (build: EndpointBuilder<any, any, any>) => {
    return {
      [queryFunction]: build.mutation<TOutput, TInput>({
        query: (credentials: TInput) => ({
          url: url,
          method: Method.POST,
          data: datas(credentials, arg),
        }),
      }),
    };
  };
}

export const getDataService = (url: string) => ({
  query: (queryParams: any) => {
    const { start, page, limit } = queryParams;
    return {
      url: url,
      params: { start, page, limit },
    };
  },
});
