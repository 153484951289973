import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { persistor, store } from "./redux/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./assets/scss/style.scss";
import "./i18n";
import "react-datepicker/dist/react-datepicker.css";

import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./components/AuthRequired/AuthProvider";
import { ConfigProvider } from "antd";
import QuickProviderSearch from "./ProviderQuickSearch";

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <AuthProvider>
            <ConfigProvider
              theme={{
                components: {
                  Checkbox: {
                    colorPrimary: "#B8413C",
                    colorInfo: "#B8413C",
                    colorTextBase: "#B8413C",
                    colorWarning: "#B8413C",
                    colorBgBase: "#B8413C",
                    colorSuccess: "#B8413C",
                  },
                  Radio: {
                    colorPrimary: "#B8413C",
                    colorInfo: "#B8413C",
                    colorTextBase: "#B8413C",
                    colorWarning: "#B8413C",
                    colorBgBase: "#B8413C",
                    colorSuccess: "#B8413C",
                  },
                },
              }}
            >
              <QuickProviderSearch>
                <App />
              </QuickProviderSearch>
            </ConfigProvider>
          </AuthProvider>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);
reportWebVitals();
