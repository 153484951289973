import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { Method } from '../../../../@types/common-types';
import { ConterParty } from '../../../../@types/conterParty';
import {
  BankEntity,
  InputFormLegalEntity,
  LegalEntity,
  LegalEntityResponse,
  SingleLegalEntityResponse,
} from '../../../../@types/legal-entities';
import { baseApi } from '../../baseApi';
import { url } from '../../url';
import { ApiFilter } from '../../../../@types';

const legalEntities = baseApi.injectEndpoints({
  endpoints: (build: EndpointBuilder<any, any, any>) => {
    return {
      legaleEntities: build.query<
        LegalEntityResponse,
        {
          page: number | undefined;
          start: number | undefined;
          limit: number | undefined;
          sort: string;
          filter: string;
        }
      >({
        query: (queryParams) => {
          const { start, page, limit, sort, filter } = queryParams;
          return {
            url: url.legalEntity.list,
            params: { start, page, limit, sort, filter, getGroup: 1 },
          };
        },
        providesTags: ['LIST_LEGAL_ENTITY'],
      }),

      deleteLegalEntity: build.mutation<
        { success: boolean; legalentity: LegalEntityResponse },
        { id: number }
      >({
        query: (body) => {
          return {
            url: url.legalEntity.list + '/' + body.id,
            method: Method.DELETE,
          };
        },
        invalidatesTags: ['LIST_LEGAL_ENTITY', 'GLOBAL_RESULT_SEARCH'],
      }),

      createLegalEntity: build.mutation<
        { success: boolean; legalentity: LegalEntity },
        InputFormLegalEntity
      >({
        query: (body) => {
          return {
            url: url.legalentity,
            method: Method.POST,
            data: body,
          };
        },
        invalidatesTags: ['LIST_LEGAL_ENTITY'],
      }),

      updateLegalEntity: build.mutation<
        { success: boolean; legalEntity: InputFormLegalEntity },
        InputFormLegalEntity
      >({
        query: (body) => {
          return {
            url: url.legalentity + '/' + body.id,
            method: Method.PUT,
            data: body,
          };
        },
        invalidatesTags: ['LIST_LEGAL_ENTITY', 'GLOBAL_RESULT_SEARCH'],
      }),

      searchLegalEntityName: build.mutation<
        LegalEntityResponse,
        {
          page: number;
          start: number;
          limit: number;
          query: any;
          filter?: string;
          counterpartyId?: number;
          underwriterType?: string;
        }
      >({
        query: (body) => {
          const {
            start,
            page,
            limit,
            query,
            counterpartyId,
            underwriterType,
            filter,
          } = body;
          return {
            url: url.legalEntity.list,
            params: {
              start,
              filter,
              page,
              limit,
              query,
              getGroup: true,
              counterpartyId,
              underwriterType,
            },
            method: Method.GET,
          };
        },
        invalidatesTags: ['LIST_LEGAL_ENTITY'],
      }),

      filterLegalEntity: build.mutation<
        LegalEntityResponse,
        { page: number; start: number; limit: number; filter: any }
      >({
        query: (body) => {
          const { start, page, limit, filter } = body;
          return {
            url: url.legalEntity.list,
            params: { start, page, limit, filter },
            method: Method.GET,
          };
        },
      }),

      legalEntityById: build.query<SingleLegalEntityResponse, { id: string }>({
        query: ({ id }) => {
          return {
            url: `${url.legalEntity.list}/${id}`,
            params: { getGroup: 1, _dc: new Date().getTime() },
            method: Method.GET,
          };
        },
      }),

      checkIban: build.mutation<
        { bankaccount: Array<BankEntity>; success: boolean; total: number },
        { iban: string }
      >({
        query: (body) => {
          return {
            url: url.legalEntity.check_iban,
            params: { iban: body.iban, getLegalEntity: 1 },
            method: Method.GET,
          };
        },
      }),

      getLegalEntityById: build.mutation<
        { legalentity: LegalEntity; success: string },
        { id: string | number }
      >({
        query: ({ id }) => {
          return {
            url: `${url.legalEntity.list}/${id}`,
            params: {
              _dc: new Date().getTime(),
              getGroup: true,
            },
          };
        },
      }),

      getCounterPartyByLegalEntityId: build.query<
        { counterparty: Array<ConterParty>; success: string },
        { id: string | number; query?: string }
      >({
        query: ({ id }) => {
          return {
            url: `${url.legalEntity.counterpartyByLegalEntity}`,
            params: {
              _dc: new Date().getTime(),
              legalEntityId: id,
              page: 1,
              start: 0,
              limit: 25,
            },
          };
        },
      }),

      getLegalEntityByIdAndCounterPartyId: build.mutation<
        {
          legalentity: LegalEntity;
          success: boolean;
        },
        {
          legalEntityId: number;
          counterpartyId?: number;
          underwriterType?: string;
        }
      >({
        query: (body) => {
          const { counterpartyId, underwriterType, legalEntityId } = body;
          return {
            url: url.legalEntity.list + '/' + legalEntityId,
            params: {
              getGroup: true,
              counterpartyId,
              underwriterType,
            },
            method: Method.GET,
          };
        },
      }),
      exportLegalEntity: build.mutation<
        string,
        ApiFilter & { fields?: (keyof LegalEntity)[] } & ApiFilter
      >({
        query: ({ fields, filter }) => {
          return {
            url: url.legalEntity.export,
            data: {
              fields,
            },
            params: {
              filter,
            },
            method: Method.POST,
          };
        },
      }),
    };
  },
});

export const {
  useLegaleEntitiesQuery,
  useDeleteLegalEntityMutation,
  useSearchLegalEntityNameMutation,
  useFilterLegalEntityMutation,
  useCreateLegalEntityMutation,
  useUpdateLegalEntityMutation,
  useCheckIbanMutation,
  useLazyLegaleEntitiesQuery,
  useLazyLegalEntityByIdQuery,
  useGetLegalEntityByIdMutation,
  useGetCounterPartyByLegalEntityIdQuery,
  useGetLegalEntityByIdAndCounterPartyIdMutation,
  useExportLegalEntityMutation,
} = legalEntities;
