export const mockTreeBtn = [
  {
    label: 'Alone',
    value: 'alone',
  },
  {
    label: 'Joint',
    value: 'joint',
  },
  {
    label: 'None',
    value: 'none',
  },
];
