import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CounterPartyFilterType } from '../../@types/conterParty'

export interface CounterPartyFilterState {
    data: CounterPartyFilterType
  }

  const initialState: CounterPartyFilterState = {
    data: {},
  }

  export const counterPartyFilterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
      setCounterPartyFilter: (state, action: PayloadAction<CounterPartyFilterType>) => {
        state.data = action.payload
        return state
      },
    
    },
  })
  
  export const { setCounterPartyFilter } = counterPartyFilterSlice.actions
  export default counterPartyFilterSlice.reducer