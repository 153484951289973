import React from 'react';

type IProps = {
  children: React.ReactNode;
  marginBottom?: string;
  height?: string;
};
export default function RowContent(props: IProps) {
  return (
    <div
      style={{
        minHeight: props.height ?? 55,
        marginBottom: props.marginBottom ?? '0.5em',
        justifyContent: 'flex-start',
      }}
    >
      {props.children}
    </div>
  );
}
