import { Form, FormControl, FormControlProps } from 'react-bootstrap';
import classes from './InputInlineForm.module.scss';
import FormError from '../../FormError/FormError';
import React from 'react';

type Props = {
  isValid?: boolean;
  error?: string;
  title?: React.ReactNode;
  isRequired?: boolean;
} & FormControlProps;
const InputInlineForm = React.forwardRef(
  ({ error, isValid, title, isRequired, ...props }: Props, ref) => {
    return (
      <Form.Group className="d-flex mb-2 mt-2">
        <Form.Label
          htmlFor="forName"
          className={`d-flex me-3 mt-2 ${classes.formLabel}`}
        >
          {title} {isRequired && <span className="span-required">*</span>}
        </Form.Label>
        <div className="w-100 mx-1">
          <FormControl
            {...props}
            ref={ref}
            className={`${
              !isValid ? classes.formControlError : classes.formControl
            }`}
            id="forName"
          />
          {!isValid && <FormError message={error} />}
        </div>
      </Form.Group>
    );
  },
);

InputInlineForm.displayName = 'InputInlineForm';

export default InputInlineForm;
