import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { LegalEntity } from '../../../../@types/legal-entities';
import {
  EntityPayements,
  InputFormBankAccount,
  InputFormPayement,
  InputNoteSegregated,
} from '../../../../@types/segregatedaccounts/payements';
import { useAppDispatch } from '../../../../hooks/redux';
import {
  useGetLegalEntityByIdMutation,
  useSearchLegalEntityNameMutation,
} from '../../../../redux/api/ws/databases/legale-entities';
import {
  useCreatePayementMutation,
  useGetNotesByPayementIdMutation,
  useLazyGetBanksAccountQuery,
  usePostAddIbanMutation,
  usePostNotesMutation,
  useUpdatePayementMutation,
} from '../../../../redux/api/ws/segregatedaccounts/payement';
import { updateModalAction } from '../../../../redux/slice/modalSlice';
import { updateNotificationMessage } from '../../../../redux/slice/notificationSlice';
import {
  amountFormat,
  checkIsEdit,
  exportDateUi,
  exportDateWs,
  floorNumber,
  getObjectDifferences,
  isExist,
  nullingEmptyValues,
  setDefaultValues,
  setEmptyUndefinedEditValues,
} from '../../../../utils/helper-function';
import { Item } from './Forms/Type';
import { isEmpty } from 'lodash';

type IProps = {
  defaultFormValues: InputFormPayement | null;
  closingModal: (itemsDataSource: any | null) => void;
  show: boolean;
};

export default function UseModalPayements(props: IProps) {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, dirtyFields },
    control,
    watch,
    getValues,
    setValue,
    reset,
    setError: setFormError,
    clearErrors: clearFormErrors,
  } = useForm<InputFormPayement>({
    mode: 'onBlur',
    defaultValues: {
      direction: 'credit',
      payment_date: exportDateUi(new Date()) as string,
      value_date: exportDateUi(new Date()) as string,
      amount: '0.00',
    },
  });
  const [countryId, setCountryId] = useState<string>('');

  const id = watch('id');
  const attachedNotes = watch('attachedNotes');
  const [isCompletedForm, setIsCompletedForm] = useState(false);

  const [notesDataSource, setNotesDataSource] = useState<Array<Item>>([]);
  const [createPayement, { isLoading: isCreating }] =
    useCreatePayementMutation();
  const [activeMenu, setActiveMenu] = useState<number>(1);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [legalEntityList, setLegalEntityList] = useState<Array<LegalEntity>>(
    [],
  );
  const legalEntityName = legalEntityList?.find(
    (el) => el.id === watch('legal_entity_id'),
  )?.name;

  const legal_entity_id = watch('legal_entity_id');

  const [isShowModalUnsavedChange, setIsShowModalUnsavedChange] =
    useState<boolean>(false);
  const [idToView, setIdToView] = useState<number | null>(null);
  const [showModalAddIban, setShowModalAddIban] = useState(false);

  const [
    getOptionsLegalEntity,
    { data: legalEntityOption, isLoading: isLoadingLegalEntity },
  ] = useSearchLegalEntityNameMutation();

  const [getNotesByPayement, { isLoading: isGetPaymentNoteLoading }] =
    useGetNotesByPayementIdMutation();

  const [getBankAccount] = useLazyGetBanksAccountQuery();
  const [bankList, setBankList] = useState<Array<any>>([]);
  const [ibanList, setIbanList] = useState<Array<any>>([]);
  const dispatch = useAppDispatch();

  const hanleBank = async (type?: string, legalEntityId?: number | null) => {
    try {
      const subParams = legalEntityId
        ? { legalEntityId }
        : {
            getOwner: true,
            segregated: 1,
            sort: [
              { property: 'bank', direction: 'ASC' },
              { property: 'currency', direction: 'ASC' },
              { property: 'info', direction: 'ASC' },
            ],
          };
      const resp = await getBankAccount({
        page: 1,
        start: 0,
        limit: 25,
        ...subParams,
      }).unwrap();
      const makeB = resp?.bankaccount?.map((el) => ({
        ...el,
        key: el.id,
        value: `${el.bank} - ${el.currency?.toUpperCase()} - ${el.iban} - ${
          el.info
        }`,
      }));
      if (type === 'account') {
        setBankList(makeB);
      }
      if (type === 'iban') {
        setIbanList(makeB);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [getLegalEntityById] = useGetLegalEntityByIdMutation();
  const [createNote, { isLoading: isCreatingNote }] = usePostNotesMutation();
  const [updatePayement, { isLoading: isUpdating }] =
    useUpdatePayementMutation();
  const [postAddIban] = usePostAddIbanMutation();

  const resetForm = () => {
    const defaultValue = setDefaultValues<InputFormPayement>(watch());
    reset({
      ...defaultValue,
      direction: 'credit',
      payment_date: exportDateUi(new Date()) as string,
      value_date: exportDateUi(new Date()) as string,
      amount: '0.00',
    });
    setNotesDataSource([]);
    setLegalEntityList([]);
    setIbanList([]);
    setCountryId('');
    setIsEdit(false);
  };

  const showNotifSuccess = (resP: EntityPayements) => {
    dispatch(
      updateNotificationMessage({
        title: 'New Payement',
        body: `Payement ${resP.number} has been added !`,
        show: true,
      }),
    );
  };

  const showNotifUpdateSuccess = (resP: EntityPayements) => {
    dispatch(
      updateNotificationMessage({
        title: 'Update Payement',
        body: `Payement ${resP.number} has been updated !`,
        show: true,
      }),
    );
  };

  const addIban = async (value: InputFormBankAccount) => {
    try {
      value.legal_entity_id = watch('legal_entity_id');
      await postAddIban(value)?.unwrap();
      hanleBank('iban', watch('legal_entity_id'));
    } catch (error) {
      console.log(error);
    }
  };

  const submit = async (data: InputFormPayement) => {
    const isAmountExist =
      (floorNumber(data?.amount) as number) > 0 &&
      (floorNumber(data?.amount) as number) < 10000000000;
    if (isAmountExist) {
      const isUpdated = checkIsEdit(data.id || '');

      const params = {
        ...data,
        payment_date: exportDateWs(data.payment_date),
        value_date: exportDateWs(data.value_date),
        entity_bank_account_id: data.entity_bank_account_id || null,
        id: data?.id || -1,
        amount: floorNumber(data?.amount),
      };
      if (isUpdated) {
        try {
          const objectDiff = getObjectDifferences(
            props.defaultFormValues,
            params,
          );
          const response: any = await updatePayement(
            setEmptyUndefinedEditValues({
              ...objectDiff,
              id,
              isDirty: 3,
            }),
          ).unwrap();
          if (response && response.success) {
            if (isExist(attachedNotes)) {
              const makeNotesParams: Array<InputNoteSegregated> =
                notesDataSource?.map((el) => {
                  return {
                    noteId: el?.number?.key,
                    amount: el.note_amount,
                    currency: el.note_currency,
                    comments: el.note_comments,
                  };
                });
              await createNote({
                notes: makeNotesParams,
                id: response?.payment?.id,
              }).unwrap();
            }
            showNotifUpdateSuccess(response?.payment);
            props.closingModal(null);
          }
          resetForm();
        } catch (error: any) {
          console.log(error);
        }
      } else {
        try {
          const response: any = await createPayement(
            nullingEmptyValues({
              ...params,
              status: notesDataSource?.length > 0 ? 'assigned' : 'unassigned',
              segregated: true,
            }),
          ).unwrap();
          const makeNotesParams: Array<InputNoteSegregated> =
            notesDataSource?.map((el) => {
              return {
                noteId: el?.number?.key,
                amount: el.note_amount,
                currency: el.note_currency,
                comments: el.note_comments,
              };
            });

          const isPostNotes =
            response && response?.payment?.id && notesDataSource?.length > 0;
          if (isPostNotes) {
            await createNote({
              notes: makeNotesParams,
              id: response?.payment?.id,
            }).unwrap();
          }
          showNotifSuccess(response?.payment);
          props.closingModal(null);
          resetForm();
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      dispatch(
        updateNotificationMessage({
          title: 'Error',
          body: `The amount must be between 0.1 and 10000000000.`,
          show: true,
        }),
      );
    }
  };

  const handleChangeToogle = (itemActive: number) => {
    setActiveMenu(itemActive);
  };

  const getLegalNameOption = async (text: string, limit: number = 30) => {
    try {
      const resp = await getOptionsLegalEntity({
        page: 1,
        start: 0,
        limit: limit || 30,
        query: `%${text?.trim()}%`,
      }).unwrap();
      setLegalEntityList(resp.legalentity);
    } catch (error) {
      return [];
    }
  };

  const runSearch = React.useCallback(
    async (key: keyof InputFormPayement, value: string) => {
      if (key === 'legal_entity_id' && value !== undefined && value !== null) {
        await getLegalNameOption(value);
      }
    },
    [],
  );

  React.useEffect(() => {
    const subscription = watch((value, { name }) => {
      let isCompletedCurrency = false;
      if (((floorNumber(value?.amount) as number) || 0) > 0) {
        isExist(value.currency)
          ? (isCompletedCurrency = true)
          : (isCompletedCurrency = false);
      } else {
        isCompletedCurrency = true;
      }
      const isCompletedRequired =
        value.direction && value.legal_entity_id && value.owner_bank_account_id;
      setIsCompletedForm(!!(isCompletedRequired && isCompletedCurrency));
      setIsEdit(!!isExist(value.id));
      const emptyTargetedAmount =
        name === 'amount' && value.amount?.toString()?.length === 0;
      if (emptyTargetedAmount) {
        setValue('currency', null);
        clearFormErrors('currency');
      }
      if (name === 'legal_entity_id') {
        if (value.legal_entity_id) {
          const selectedL = legalEntityList?.find(
            (el) => el.id === value.legal_entity_id,
          );

          if (selectedL) {
            setCountryId(selectedL.country_id);
          } else {
            setCountryId('');
          }
        }

        if (value.direction === 'debit') {
          hanleBank('iban', value.legal_entity_id as number);
        }
      }
      if (name === 'direction') {
        hanleBank('account');
      }
      if (name === 'owner_bank_account_id') {
        const selectedAmount = bankList?.find(
          (el) => el.key === value.owner_bank_account_id,
        );
        if (selectedAmount) {
          const defaultUnit = selectedAmount?.currency;
          setValue('currency', defaultUnit);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, legalEntityList, bankList]);

  const showLegalEntity = (idLgl: number | null) => {
    if (!isEmpty(dirtyFields) && isEdit) {
      setIsShowModalUnsavedChange(true);
      setIdToView(idLgl);
    } else {
      showLegalEntityWithoutSave(idLgl);
    }
  };

  const showLegalEntityWithoutSave = (idLgl?: number | null) => {
    setIsShowModalUnsavedChange(false);
    const found = (legalEntityList || []).find(
      (el) => el.id?.toString() === (idLgl ?? idToView)?.toString(),
    );
    if (found) {
      dispatch(
        updateModalAction({
          data: {
            ...found,
            group_id: found?.group?.id,
            group_name: found?.group?.name,
          },
          isAdd: true,
          type: 'legal_entity',
        }),
      );
      isEdit && resetForm();
    }
  };

  const handleGetNotesById = async (id: number) => {
    const resp = await getNotesByPayement({ id }).unwrap();
    console.log(resp);
    if (resp.success) {
      try {
        const makeNotesParams: Array<Item> = resp.note?.map(
          (el, index: number) => {
            const tempNote = el.complete_ref?.split(' - ') || [];
            let tempNoteCurr = '';

            let tempNoteAmmount = '';
            if (tempNote && tempNote?.[2]) {
              const tempS = tempNote?.[2]?.trim();
              const splitSelected = tempS?.split(' ');
              tempNoteAmmount = splitSelected?.[0] || '';
              tempNoteCurr = splitSelected?.[1] || '';
            }

            return {
              key: index + 1,
              id: el.id,
              number: { key: el.id, value: el.number },
              net_rate: tempNoteAmmount,
              note_currency: tempNoteCurr,
              note_amount: el?.note_amount,
              note_comments: el?.note_comments,
              legal_entity: el?.legal_entity?.name || '',
              currency: el?.currency?.toUpperCase() || '',
              complete_ref: el?.complete_ref || '',
              deal_id: el?.deal_id as any,
              deal_number: el?.deal?.number || ''
            };
          },
        );
        setNotesDataSource(makeNotesParams);
      } catch (error) {
        console.log(error);
      }
    }
  };

  React.useEffect(() => {
    if (props.defaultFormValues) {
      const selectedPayement = props.defaultFormValues;
      if (selectedPayement?.legal_entity_id) {
        if (selectedPayement?.legal_entity?.country_id)
          setCountryId(selectedPayement?.legal_entity?.country_id);
        try {
          getLegalEntityById({
            id: selectedPayement?.legal_entity_id,
          }).then((resp: any) => {
            if (resp?.data?.legalentity) {
              setLegalEntityList([resp?.data?.legalentity]);
            }
          });
          hanleBank('iban', selectedPayement?.legal_entity_id);
        } catch (error) {
          console.log(error);
        }
      }
      hanleBank('account');
      handleGetNotesById(props?.defaultFormValues?.id);
      reset({
        ...selectedPayement,
        payment_date: exportDateUi(selectedPayement?.payment_date) as string,
        value_date: exportDateUi(selectedPayement?.value_date) as string,
        amount: amountFormat(
          Number.parseFloat(
            selectedPayement?.amount?.toString() || '0',
          )?.toFixed(2),
        ),
      });
    }
  }, [props.defaultFormValues]);

  React.useEffect(() => {
    if (props.show) hanleBank('account');
  }, [props.show]);

  return {
    register,
    handleSubmit,
    control,
    watch,
    getValues,
    setValue,
    reset,
    errors,
    isDirty,
    setFormError,
    clearFormErrors,
    activeMenu,
    isEdit,
    resetForm,
    setActiveMenu,
    submit,
    handleChangeToogle,
    runSearch,
    legalEntityOption,
    isLoadingLegalEntity,
    legalEntityList,
    bankList,
    ibanList,
    isCompletedForm,
    showLegalEntity,
    hanleBank,
    notesDataSource,
    setNotesDataSource,
    countryId,
    isGetPaymentNoteLoading,
    dirtyFields,
    isShowModalUnsavedChange,
    setIsShowModalUnsavedChange,
    showLegalEntityWithoutSave,
    showModalAddIban,
    setShowModalAddIban,
    legalEntityName,
    isSaveLoading: isCreating || isCreatingNote || isUpdating,
    addIban,
    legal_entity_id,
  };
}
