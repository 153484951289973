import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/slice/authSlice";
import { baseApi } from "../../redux/api/baseApi";
import Avatar from "@mui/material/Avatar";
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  Logout,
  PasswordOutlined,
  KeyboardArrowDown,
} from "@mui/icons-material";
import ModalChangePassword from "../common/Modal/ModalChangePassword";
import { useUploadAvatarMutation } from "../../redux/api/ws/databases/cofarco-teams";
import { config } from "../../data/config";
import { Space } from "antd";
import { useCurrencyExchangeRateQuery } from "../../redux/api/ws/dashboard/dashboard";
import { clearSession } from "../../utils/user-session";

const NavBar = () => {
  const dispatch = useAppDispatch();
  const [avatarKey, setAvatarKey] = useState<string | undefined>();
  const avatarRef = useRef<HTMLImageElement | null>(null);
  const navigate = useNavigate();

  const disconnect = () => {
    clearSession();
    dispatch(logout());
    dispatch(baseApi.util.resetApiState());
    navigate("/login");
  };
  const authData = useAppSelector((state) => state.auth);
  const [avatarPath, setAvatarPath] = useState<string>(
    `${config.baseUrl}user/avatar/${authData?.user?.id}/${authData?.user?.avatar_filename}`
  );

  const userName =
    authData?.user?.people?.firstname + " " + authData?.user?.people?.lastname;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (avatarKey) {
      setAvatarPath(avatarKey);
    }
  }, [avatarKey]);

  const [uploadAvatar] = useUploadAvatarMutation();

  const onUploadAvatar = (files: FileList | null) => {
    if (files && files.length > 0 && authData.user?.id) {
      uploadAvatar({ id: authData.user.id, data: files?.[0] }).then(
        (resp: any) => {
          if (resp && resp.data && resp.data.success) {
            setAvatarKey(
              `${config.baseUrl}user/avatar/${authData?.user?.id}/${resp.data.filename}`
            );
          }
        }
      );
      return;
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    disconnect();
    handleClose();
  };

  const handleChangePassword = () => {
    handleClose();
    setOpenChangePswd(true);
  };

  const [openChangePswd, setOpenChangePswd] = useState<boolean>(false);

  const handleArrowClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  //GET CURRENCIES EXCHANGE
  const { data: dataCurrencies } = useCurrencyExchangeRateQuery({});

  return (
    <div className="d-flex headRightSide">
      <Space wrap className="dashboard-currencies">
        {["EUR/USD", "EUR/CHF", "GBP/EUR"].map((exc, index) => {
          const couple = exc.split("/");
          const data = (dataCurrencies?.data || []).find((c) => {
            const from = c.currencyFrom.toLowerCase();
            const to = c.currencyTo.toLowerCase();
            const okFrom = from === couple[0].toLowerCase();
            const okTo = to === couple[1].toLowerCase();
            return okFrom && okTo;
          });
          if (!data) return "";
          return (
            <span
              key={`currency-nav-bar-${index}`}
              style={{ textTransform: "uppercase" }}
            >
              {data?.currencyFrom}/{data?.currencyTo} {data?.rate.toFixed(6)}
            </span>
          );
        })}
      </Space>
      <div className="myAccount">
        <div className="name">
          <Button
            id="basic-button"
            variant="text"
            color="inherit"
            disableRipple
            style={{ textTransform: "none", backgroundColor: "transparent" }}
            endIcon={
              <div onClick={handleArrowClick}>
                <KeyboardArrowDown fontSize="large" />
              </div>
            }
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <div className="p-profil">
              <Avatar
                key={avatarKey}
                ref={avatarRef}
                sx={{ bgcolor: "#851001" }}
                src={avatarPath}
                onClick={() => {
                  document.getElementById("avatar")?.click();
                }}
                alt="Avatar"
              >
                {authData?.user?.people?.firstname?.[0] +
                  "" +
                  authData?.user?.people?.lastname?.[0]}
              </Avatar>
            </div>
            <Typography variant="body2" color="GrayText" onClick={handleClick}>
              {userName}
            </Typography>
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              style: {
                backgroundColor: "#eb5d57",
                color: "#fff",
              },
            }}
          >
            <MenuItem onClick={handleChangePassword}>
              <ListItemIcon>
                <PasswordOutlined style={{ color: "#fff" }} fontSize="small" />
              </ListItemIcon>
              <ListItemText>Modify Password</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleLogOut}>
              <ListItemIcon>
                <Logout style={{ color: "#fff" }} fontSize="small" />
              </ListItemIcon>
              <ListItemText>Disconnect</ListItemText>
            </MenuItem>
          </Menu>
        </div>
      </div>

      <input
        className="d-none"
        type="file"
        id="avatar"
        accept="image/*"
        onChange={(e) => {
          const selectedFile = e.target.files && e.target.files[0];

          if (selectedFile && selectedFile.type.startsWith("image/")) {
            onUploadAvatar(e.target.files);
          } else {
            e.target.files = null;
            e.target.value = "";
            console.error("Please select a valid image file.");
          }
        }}
      />

      <ModalChangePassword
        showModalPassword={openChangePswd}
        handleLeave={() => setOpenChangePswd(false)}
        handleCancel={() => setOpenChangePswd(false)}
        user={authData?.user}
      />
    </div>
  );
};

export default NavBar;
