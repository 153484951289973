import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { Typography } from 'antd';
import { pick, pickBy } from 'lodash';
import { useForm } from 'react-hook-form';
import { ColumnsType } from 'antd/es/table';
import { useListTermsQuery } from '../../../../redux/api/ws/terms/get';
import {
  PartnerFormInputs,
  PartnerConterParty,
  LegalEntity,
  ChoiceType,
} from '../../../../@types/conterParty';
import {
  useLazyPeopleByIdQuery,
  useLazySearchPeopleQuery,
} from '../../../../redux/api/ws/databases/people';
import { People } from '../../../../@types/people';
import UseDynamicsTable from '../../../dynamic-table/use-dynamics-table';
import { ReactComponent as Edit } from '../../../../assets/img/Edit.svg';
import DropdownSelect, {
  DropDownOptionObjectType,
  DropDownOptionType,
} from '../../../common/DropDown/DropdownSelect';
import { useSearchLegalEntityNameMutation } from '../../../../redux/api/ws/databases/legale-entities';
import {
  useCreateCounterPartyMutation,
  useEditCounterPartyMutation,
  useSetCounterPartyLegalentityMutation,
} from '../../../../redux/api/ws/counterparties/post';
import { useAppDispatch } from '../../../../hooks/redux';
import { updateNotificationMessage } from '../../../../redux/slice/notificationSlice';
import { updateModalAction } from '../../../../redux/slice/modalSlice';
import { isExist } from '../../../../utils/helper-function';

type TLegalEntity = Pick<LegalEntity, 'id' | 'name'> & {
  key: any;
  legal_entity?: LegalEntity;
  temp_legal_entity?: LegalEntity;
  roles: string[];
  temp_roles?: string[];
};

const useObligorModal = ({
  defaultFormValues,
  onSubmittedForm,
}: {
  onSubmittedForm?: () => void;
  defaultFormValues?: PartnerConterParty | null;
}) => {
  const choiceThee: { key: ChoiceType; value: string }[] = [
    {
      key: 'yes',
      value: 'Yes',
    },
    {
      key: 'no',
      value: 'No',
    },
    {
      key: 'unknown',
      value: "Don't Know",
    },
  ];

  const defaultValues: PartnerFormInputs = useMemo(
    () => ({
      business: 'partner',
      id: -1,
      name: '',
      commercial_name: '',
      type: '',
      country_id: '',
      legal_entity_id: -1,
      activity: '',
      market: '',
      main_people_id: null,
      most_senior_people_id: null,
      isDirty: 1,
      informations: {
        terms_of_business: undefined,
      },
    }),
    [],
  );

  const formValues = useMemo(() => {
    const data = defaultFormValues
      ? ({
          ...pick(defaultFormValues, Object.keys(defaultValues)),
        } as PartnerFormInputs)
      : defaultValues;

    return {
      ...data,
      informations:
        data.informations && !Array.isArray(data.informations)
          ? data.informations
          : {},
    };
  }, [defaultFormValues, defaultValues]);

  const {
    handleSubmit,
    register,
    // trigger: triggerFormValidation,
    watch: watchFormValue,
    reset: resetForm,
    setValue: setFormValue,
    getValues: getFormValue,
    formState: { errors },
  } = useForm<any>({
    shouldUseNativeValidation: false,
    defaultValues: formValues,
    reValidateMode: 'onChange',
    mode: 'all',
  });

  const updateDataSourceWithRoles = (
    key: any,
    v: string,
    legal_entity: any,
    roles: string[],
    isSave: boolean = false,
    isCancel: boolean = false,
  ) => {
    const index = dataSourceLegalEntity.findIndex((f) => f.key === key);
    if (index > -1) {
      const newDataSource = [...dataSourceLegalEntity];
      const ds = newDataSource[index];
      if (isSave) {
        newDataSource[index] = {
          ...ds,
          name: v,
          temp_legal_entity: undefined,
          legal_entity: legal_entity,
          roles: roles,
          temp_roles: undefined,
        };
        // setFormValue("legal_entity_id", legal_entity.id);
      } else if (isCancel) {
        newDataSource[index] = {
          ...newDataSource[index],
          name: newDataSource[index].legal_entity?.name ?? '',
          temp_legal_entity: undefined,
          temp_roles: undefined,
        };
      } else {
        newDataSource[index] = {
          ...newDataSource[index],
          temp_roles: roles,
          name: v,
          temp_legal_entity: legal_entity as LegalEntity,
        };
      }
      setDataSourceLegalEntity(newDataSource);
    }
  };

  const updateDataSource = (
    key: any,
    v: string,
    legal_entity: any,
    isSave: boolean = false,
    isCancel: boolean = false,
  ) => {
    const index = dataSourceLegalEntity.findIndex((f) => f.key === key);
    if (index > -1) {
      const newDataSource = [...dataSourceLegalEntity];
      if (isSave) {
        newDataSource[index] = {
          ...newDataSource[index],
          name: v,
          temp_legal_entity: undefined,
          legal_entity: legal_entity,
        };
        // setFormValue("legal_entity_id", legal_entity.id);
      } else if (isCancel) {
        newDataSource[index] = {
          ...newDataSource[index],
          name: newDataSource[index].legal_entity?.name ?? '',
          temp_legal_entity: undefined,
        };
      } else {
        newDataSource[index] = {
          ...newDataSource[index],
          name: v,
          temp_legal_entity: legal_entity as LegalEntity,
        };
      }
      setDataSourceLegalEntity(newDataSource);
    }
  };

  const columnLegalEntity: ColumnsType<TLegalEntity> = [
    {
      title: 'LEGAL ENTITY',
      dataIndex: 'name',
      key: 1,
      className: 'w-90 text-start ms-0',
      render: (_, record: TLegalEntity) => {
        const editable = isEdittingLegalEntity(record);
        return editable ? (
          <DropdownSelect
            preValue={record.name}
            onSelectOption={(e) => {
              updateDataSource(
                record.key,
                (e as DropDownOptionObjectType).value as string,
                (e as DropDownOptionObjectType).key,
              );
            }}
            options={[]}
            onSearch={(d, e) => searchLegalEntity(e, d)}
          />
        ) : (
          <div className="py-3 ps-3">{record.name}</div>
        );
      },
    },
    {
      title: '',
      dataIndex: 'id',
      key: 3,
      width: 120,
      render: (_, record: TLegalEntity) => {
        const editable = isEdittingLegalEntity(record);
        return editable ? (
          <span>
            <Typography.Link
              style={{ marginRight: 8 }}
              disabled={editable && !isExist(record.name)}
              onClick={() => {
                updateDataSourceWithRoles(
                  record.key,
                  record.temp_legal_entity?.name ?? '',
                  record.temp_legal_entity,
                  record.temp_roles ?? record.roles,
                  true,
                );
                setLegalEntityEditingKey('');
              }}
            >
              Save
            </Typography.Link>
            <Typography.Link
              style={{ marginRight: 8 }}
              onClick={() => {
                updateDataSourceWithRoles(
                  record.key,
                  record.legal_entity?.name ?? '',
                  undefined,
                  [],
                  false,
                  true,
                );
                setLegalEntityEditingKey('');
              }}
            >
              Cancel
            </Typography.Link>
          </span>
        ) : (
          <div className="d-flex justify-content-end">
            <Typography.Link
              disabled={editinKeyLegalEntity !== ''}
              onClick={() => {
                dispatch(
                  updateModalAction({
                    data: record.legal_entity,
                    isAdd: true,
                    type: 'legal_entity',
                  }),
                );
              }}
              style={{ marginRight: 10 }}
            >
              <Edit height="30" />
            </Typography.Link>
          </div>
        );
      },
    },
  ];

  const {
    mergedColumns: mergedLegalEntity,
    isEditing: isEdittingLegalEntity,
    editingKey: editinKeyLegalEntity,
    form: formLegalEntity,
    dataSource: dataSourceLegalEntity,
    add: addLegalEntity,
    setDataSource: setDataSourceLegalEntity,
    setEditingKey: setLegalEntityEditingKey,
  } = UseDynamicsTable<TLegalEntity>({
    rowItem: {
      id: 0,
      key: 0,
      name: '',
      roles: ['stamp'],
      legal_entity: undefined,
    },
    column: columnLegalEntity,
  });

  const { data: terms } = useListTermsQuery(undefined, {
    refetchOnMountOrArgChange: false,
  });
  const [searchPeople] = useLazySearchPeopleQuery();
  const [searchLegalEntityQuery] = useSearchLegalEntityNameMutation();
  const [createCounterParty] = useCreateCounterPartyMutation();
  const [editCounterParty] = useEditCounterPartyMutation();
  const [setCounterPartyLegalEntity] = useSetCounterPartyLegalentityMutation();
  const [getPeopleById] = useLazyPeopleByIdQuery();
  const dispatch = useAppDispatch();

  const showPeopleModal = (p: People | null | undefined) => {
    getPeopleById({ id: p?.id ?? 0 }).then((res) => {
      if (res.data?.people) {
        dispatch(
          updateModalAction({
            data: res.data?.people,
            isAdd: true,
            type: 'people',
          }),
        );
      }
    });
  };

  const searchLegalEntity = useCallback(
    (
      value: string,
      dipatch?: Dispatch<SetStateAction<DropDownOptionType[]>>,
    ) => {
      // setIsLeagEntityLoading(true);
      searchLegalEntityQuery({
        page: 1,
        start: 0,
        limit: 100,
        query: `%${value}%`,
      })
        .then((resp) => {
          const rep = resp as any;
          if (rep.data) {
            const datas = (rep.data.legalentity as LegalEntity[])
              .filter((f) => f && f.name.trim() !== '')
              .map((e) => ({ key: e, value: e.name ?? '' }));
            // setLegalEntities(datas);
            if (dipatch) dipatch(datas);
          }
        })
        .finally(() => {
          // setIsLeagEntityLoading(false);
        });
    },
    [],
  );

  const searchPeopleCallback = useCallback(
    (
      legal_entity_id: number[],
      v: string,
      callback: (people: People[]) => void,
    ) => {
      searchPeople({
        key: 'name',
        value: v,
        params: { limit: 1000, legalEntityId: legal_entity_id },
      }).then((res) => {
        if (res.data && res.data.people) {
          callback(res.data.people);
        }
      });
    },
    [],
  );

  const handleClose = () => {
    resetForm(defaultValues);
    setDataSourceLegalEntity([]);
  };

  const onSubmitForm = (e: any) => {
    e.preventDefault();
    handleSubmit(saveCounterParty, onError)();
  };
  const onError = () => null;

  const setCounterPartyLegalEntityCallback = useCallback(
    (id: number, data: any) => {
      setCounterPartyLegalEntity({ data, id }).finally(() => {
        handleClose();
        if (onSubmittedForm) onSubmittedForm();
      });
    },
    [],
  );

  const saveCounterParty = useCallback(
    (data: any) => {
      const les = dataSourceLegalEntity
        .filter((e) => e.legal_entity)
        .map((e) => ({
          legalEntityId: e.legal_entity?.id,
          roles: e.roles,
        }));

      if (data.id && data.id > 0) {
        const dataToedit = pickBy(
          data,
          (v, k) =>
            Object.keys(formValues).includes(k) &&
            (formValues as Record<string, any>)[k] !== v &&
            !k.startsWith('_') &&
            !['legal_entity_id'].includes(k),
        ) as Record<string, any>;
        editCounterParty({
          data: {
            ...dataToedit,
            legal_entity_id: dataSourceLegalEntity[0].legal_entity?.id,
          },
          id: data.id,
        })
          .then((resp: any) => {
            dispatch(
              updateNotificationMessage({
                show: true,
                title: 'Partner update',
                body: 'Successfully updated !',
              }),
            );
            if (resp.data && resp.data.counterparty && les.length > 0) {
              const c = resp.data.counterparty as PartnerConterParty;
              setCounterPartyLegalEntityCallback(c.id, les);
            } else {
              if (onSubmittedForm) onSubmittedForm();
            }
          })
          .finally(() => {
            handleClose();
          });
      } else {
        const dataToedit = pickBy(
          data,
          (v, k) =>
            Object.keys(formValues).includes(k) &&
            !k.startsWith('_') &&
            !['legal_entity_id'].includes(k),
        ) as Record<string, any>;
        createCounterParty({
          data: {
            ...dataToedit,
            legal_entity_id: dataSourceLegalEntity[0].legal_entity?.id,
            businessType: 'partner',
          },
          businessType: 'partner',
        })
          .then((resp: any) => {
            dispatch(
              updateNotificationMessage({
                show: true,
                title: 'Partner add',
                body: 'Successfully added !',
              }),
            );
            if (resp.data && resp.data.counterparty && les.length > 0) {
              const c = resp.data.counterparty as PartnerConterParty;
              setCounterPartyLegalEntityCallback(c.id, les);
            } else {
              if (onSubmittedForm) onSubmittedForm();
            }
          })
          .finally(() => {
            handleClose();
          });
      }
    },
    [formValues, dataSourceLegalEntity],
  );

  useEffect(() => {
    const all_terms = terms?.term;
    resetForm(defaultFormValues as PartnerFormInputs);
    if (all_terms) {
      setFormValue(
        '_type',
        all_terms.find((t) => t.key === defaultFormValues?.type)?.value ?? '',
      );
      setFormValue(
        '_activity',
        all_terms.find((t) => t.key === defaultFormValues?.activity)?.value ??
          '',
      );
      setFormValue(
        '_market',
        all_terms.find((t) => t.key === defaultFormValues?.market)?.value ?? '',
      );
    }
    searchPeopleCallback(
      defaultFormValues?.legal_entities &&
        defaultFormValues?.legal_entities.length > 0
        ? defaultFormValues?.legal_entities.map((l) => l.id)
        : [defaultFormValues?.legal_entity_id ?? 0],
      '',
      (people) => {
        const mainp = people.find(
          (t) => t.id === defaultFormValues?.main_people_id,
        );
        if (mainp) {
          setFormValue(
            '_main_people_id',
            `${mainp?.firstname} ${mainp?.lastname}`,
          );
          setFormValue('_main_people', mainp);
        }
        const mostp = people.find(
          (t) => t.id === defaultFormValues?.most_senior_people_id,
        );
        if (mostp) {
          setFormValue(
            '_most_senior_people_id',
            `${mostp?.firstname} ${mostp?.lastname}`,
          );
          setFormValue('_most_senior_people', mostp);
        }
      },
    );
  }, [defaultFormValues, terms?.term]);

  useEffect(() => {
    if (
      defaultFormValues?.legal_entities &&
      defaultFormValues?.legal_entities.length > 0
    ) {
      setDataSourceLegalEntity(
        defaultFormValues?.legal_entities.map((e, index) => ({
          key: index,
          id: index,
          roles: (e.pivot?.roles as string | undefined)?.split(',') ?? [],
          name: e.name,
          legal_entity: e,
        })),
      );
    } else if (defaultFormValues?.legal_entity) {
      setDataSourceLegalEntity([
        {
          key: 0,
          id: 0,
          roles:
            (
              defaultFormValues?.legal_entity.pivot?.roles as string | undefined
            )?.split(',') ?? [],
          name: defaultFormValues?.legal_entity.name,
          legal_entity: defaultFormValues?.legal_entity,
        },
      ]);
      setLegalEntityEditingKey('');
    } else {
      setDataSourceLegalEntity([]);
    }
  }, [defaultFormValues]);

  return {
    errors,
    defaultValues: formValues,
    terms: terms?.term ?? [],
    choiceThee,
    searchPeopleCallback,
    onSubmitForm,
    register,
    watchFormValue,
    resetForm,
    setFormValue,
    getFormValue,
    mergedLegalEntity,
    formLegalEntity,
    dataSourceLegalEntity,
    columnLegalEntity,
    addLegalEntity,
    setDataSourceLegalEntity,
    saveCounterParty,
    handleClose,
    showPeopleModal,
  };
};

export default useObligorModal;
