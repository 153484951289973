import React, { useMemo, useRef, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';

import {
  concatString,
  isExist,
  isValidEmail,
} from '../../../utils/helper-function';
import { useLazySearchPeopleQuery } from '../../../redux/api/ws/databases/people';
import { DropDownOptionObjectType } from '../../common/DropDown/DropdownSelect';
import CofarcoFormText from '../../Form/CofarcoFormText';
import FormModal from '../../Modal/FormModal/FormModal';
import SelectDropDown from '../../common/DropDown/SelectDropDown';
import UseModalCofarcoTeam from './UseModalCofarcoTeam';
import { People } from '../../../@types/people';
import { EntityCofarcoTeam } from '../../../@types/cofarco-teams/type';
import RowContent from '../../Form/RowContent';
import FormError from '../../FormError/FormError';
import { makeStyles } from '@mui/styles';
import ModalUnsavedChange from '../../common/Modal/ModalUnsavedChange';

type Iprops = {
  show: boolean;
  handleClose: (user: EntityCofarcoTeam | null) => void;
  defaultFormValues: any;
};
export default function ModalCofarcoTeam(props: Iprops) {
  const classs = useStyles();

  const labelRef = useRef<HTMLLabelElement>(null);

  const labelWidth = useMemo(() => {
    if (labelRef.current) {
      return labelRef.current.offsetWidth;
    }
    return 0;
  }, [labelRef.current]);

  const provider = UseModalCofarcoTeam({
    closingModal: props.handleClose,
    show: props.show,
  });

  const password = React.useRef({});
  password.current = provider.watch('password', '');
  const people_name = provider.watch('people_name');
  const people_id = provider.watch('people_id');

  const [searchPeople, { data, isLoading: isGetPeopleLoading, isFetching }] =
    useLazySearchPeopleQuery();

  const [options, setOptions] = useState<Array<any>>([]);

  const getPeopleNameOption = async (text: string) => {
    try {
      const resp: any = await searchPeople({
        key: 'firstname',
        value: text?.trim(),
      });

      const tempOptions = (resp?.data?.people || [])?.map((el: any) => ({
        key: el.id,
        value: concatString(el.firstname, el.lastname, ' '),
      }));

      setOptions(tempOptions);
      return options;
    } catch (error) {
      return [];
    }
  };

  const runSearch = React.useCallback(async (value: string) => {
    await getPeopleNameOption(value);
  }, []);

  const handlePeople = (id: number): People | undefined => {
    const found = data?.people?.find((el) => el.id === id);
    if (found) {
      return found;
    }
  };

  React.useEffect(() => {
    if (people_id) {
      const handleMail = data?.people.find((el) => el.id === people_id);
      if (handleMail) {
        provider.setValue('email', handleMail?.professional_mail || '', {
          shouldValidate: true,
        });
      }
    }
  }, [people_id]);

  return (
    <>
      <FormModal
        show={props.show}
        handleClose={() => {
          setOptions([]);
          props.handleClose(null);
        }}
        header={<span> New - Cofarco Team </span>}
        isDisabledSave={
          !provider.isDirty ||
          provider.isLoadingCreateCofarcoTeam ||
          !provider.isCompletedForm
        }
        onSubmitForm={provider.handleSubmit(provider.submit)}
        isLoading={provider.isLoadingCreateCofarcoTeam}
        children={
          <Container className="mt-4">
            <Row>
              <Col>
                <RowContent marginBottom={'0.75rem'}>
                  <Form.Group className="d-flex align-items-baseline">
                    <Form.Label className="txt-with-dropdown">
                      People <span className="span-required">*</span>
                    </Form.Label>
                    <div
                      className="d-flex"
                      style={{
                        flexDirection: 'column',
                        width: '100%',
                        position: 'relative',
                      }}
                    >
                      <SelectDropDown
                        noPadding
                        modalId="modal-people"
                        allowNew={false}
                        {...provider.register('people_id', { required: true })}
                        icon={
                          <span
                            onClick={() =>
                              isExist(people_id) &&
                              provider.showPeople(people_id as number)
                            }
                            className={`icon-People ico `}
                            style={{
                              background: isExist(people_id)
                                ? ' #EB5D57'
                                : '#e7e7e7',
                              borderRadius: '2px',
                              color: '#fff',
                              padding: '10px',
                              position: 'absolute',
                              top: '0',
                              cursor: 'pointer',
                            }}
                          />
                        }
                        preValue={people_name as string}
                        onSearch={(d, e) => runSearch(e)}
                        onSelectOption={(e) => {
                          provider.handleSelectedPeople(
                            handlePeople((e as DropDownOptionObjectType).key),
                          );
                          provider.setValue(
                            'people_id',
                            (e as DropDownOptionObjectType).key,
                          );
                          provider.setValue(
                            'people_name',
                            (e as DropDownOptionObjectType).value,
                          );
                          if (e) {
                            provider.clearErrors('people_id');
                            return;
                          }
                          provider.setError('people_id', {
                            message: 'People is required',
                          });
                        }}
                        onBlur={() => {
                          if (!isExist(people_id)) {
                            provider.setError('people_id', {
                              message: 'People  is required',
                            });
                          }
                        }}
                        loading={isGetPeopleLoading || isFetching}
                        options={options || []}
                      />
                    </div>
                  </Form.Group>
                </RowContent>

                <RowContent marginBottom={'0.75rem'}>
                  <CofarcoFormText
                    class="w-100 align-items-baseline mt-2"
                    label="Email"
                    errors={provider.errors}
                    key_form="email"
                    register={provider.register('email', {
                      required: true,
                      validate: (txt: string) => {
                        return isValidEmail(txt);
                      },
                    })}
                    isRequired
                    txtError="Required"
                    txtErrorValidate='Invalid email format, ex "user@example.com'
                  />
                </RowContent>

                <RowContent marginBottom={'0.75rem'}>
                  <Form.Group className="d-flex align-items-baseline">
                    <Form.Label className="txt  my-auto" ref={labelRef}>
                      Password <span className="span-required">*</span>
                    </Form.Label>
                    <div
                      className="d-flex"
                      style={{ flexDirection: 'column', width: '100%' }}
                    >
                      <Form.Control
                        type="password"
                        className={`${provider.errors.password && 'invalid'}`}
                        {...provider.register('password', {
                          required: true,
                          minLength: 8,
                        })}
                        autoComplete="off"
                      />
                    </div>
                  </Form.Group>
                  {provider.errors.password?.type === 'required' && (
                    <FormError
                      message="Required"
                      style={{
                        marginInlineStart: labelWidth,
                      }}
                      classNameMessage={classs.messageError}
                      classNameStyle={classs.formError}
                    />
                  )}

                  {provider.errors.password?.type === 'minLength' && (
                    <FormError
                      message="The minimum length for this field is 8"
                      style={{
                        marginInlineStart: labelWidth,
                      }}
                      classNameMessage={classs.messageError}
                      classNameStyle={classs.formError}
                    />
                  )}
                </RowContent>

                <RowContent marginBottom={'0.75rem'}>
                  <Form.Group className="d-flex align-items-baseline">
                    <Form.Label className="txt my-auto nowrap">
                      Confirm&nbsp;Password&nbsp;
                      <span className="span-required">*</span>
                    </Form.Label>
                    <div
                      className="d-flex"
                      style={{ flexDirection: 'column', width: '100%' }}
                    >
                      <Form.Control
                        type="password"
                        className={`${
                          provider.errors.password_confirmation && 'invalid'
                        }`}
                        {...provider.register('password_confirmation', {
                          required: true,
                          minLength: 8,
                          validate: (txt: string) =>
                            txt.toString() === password.current?.toString(),
                        })}
                        autoComplete="off"
                      />
                    </div>
                  </Form.Group>

                  {provider.errors.password_confirmation?.type ===
                    'required' && (
                    <FormError
                      message="Required"
                      style={{
                        marginInlineStart: labelWidth,
                      }}
                      classNameMessage={classs.messageError}
                      classNameStyle={classs.formError}
                    />
                  )}

                  {provider.errors.password_confirmation?.type ===
                    'minLength' && (
                    <FormError
                      message="The minimum length for this field is 8"
                      style={{
                        marginInlineStart: labelWidth,
                      }}
                      classNameMessage={classs.messageError}
                      classNameStyle={classs.formError}
                    />
                  )}

                  {provider.errors.password_confirmation?.type ===
                    'validate' && (
                    <FormError
                      message="Passwords does not match"
                      style={{
                        marginInlineStart: labelWidth,
                      }}
                      classNameMessage={classs.messageError}
                      classNameStyle={classs.formError}
                    />
                  )}
                </RowContent>
              </Col>
              <Col />
            </Row>
          </Container>
        }
      />

      <ModalUnsavedChange
        showModalUnsaved={provider.isShowModalUnsavedChange}
        handleCancel={() => provider.setIsShowModalUnsavedChange(false)}
        handleLeave={() =>
          provider.showPeople(provider.showOtherModaldata, true)
        }
        // dirtiFields={provider.dirtyFields}
      />
    </>
  );
}

const useStyles = makeStyles(() => ({
  formError: {
    width: '100% !important',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  messageError: {
    fontSize: 12,
  },
}));
