import { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import { uniq } from "lodash";
import { Col, ModalProps, Row } from "react-bootstrap";
import { Divider, Table, Form as AntForm, Button } from "antd";
import FormModal from "../../../Modal/FormModal/FormModal";
import classes from "./UnderwriterModal.module.scss";
import { ChoiceType, ConterParty } from "../../../../@types/conterParty";
import AddGroup from "../../../../assets/img/add.svg";
import useUnderwriterModal from "./useUnderwriterModal";
import SelectDropDown from "../../../common/DropDown/SelectDropDown";
import { DropDownOptionObjectType } from "../../../common/DropDown/DropdownSelect";
import { People } from "../../../../@types/people";
import CofarcoFormText from "../../../Form/CofarcoFormText";
import DeleteModal from "../../../Modal/DeleteModal";
import { checkIsEdit, isCan } from "../../../../utils/helper-function";
import { Menu } from "../../../../@types/common-types";
import RowContent from "../../../Form/RowContent";
import ModalUnsavedChange from "../../../common/Modal/ModalUnsavedChange";

type Props = {
  defaultFormValues?: ConterParty | null;
  onSubmittedForm?: () => void;
  onClose?: () => void;
} & ModalProps;

const UnderwriterModal = ({
  defaultFormValues,
  onSubmittedForm,
  onClose,
  ...props
}: Props) => {
  const {
    terms,
    defaultValues,
    errors,
    choiceThee,
    peoples,
    searchPeopleCallback,
    onSubmitForm,
    register,
    watchFormValue,
    setFormValue,
    getFormValue,
    handleClose,
    mergedLegalEntity,
    formLegalEntity,
    dataSourceLegalEntity,
    addLegalEntity,
    showPeopleModal,
    deleteModal,
    isInModeEditing,
    closeDeleteLegalEntity,
    deleteLegalEntity,
    setLegalEntityEditingKey,
    isCompletedForm,
    isDirty,
    isShowModalUnsavedChange,
    setIsShowModalUnsavedChange,
    showOtherModaldata,
  } = useUnderwriterModal({ defaultFormValues, onSubmittedForm });

  const [isLegalEntityEdit, setIsLegalEntityEdit] = useState<boolean>(false);

  useEffect(() => {
    setIsLegalEntityEdit(false);
  }, [defaultFormValues]);

  const hiddenFooter = !!(
    !isCan("write", Menu.underwriter) &&
    checkIsEdit(defaultFormValues?.id as number)
  );

  return (
    <>
      <DeleteModal
        title="Delete Legal Entity"
        show={deleteModal.show}
        groupeName={`${deleteModal?.legal_entity?.name ?? ""}`}
        entityTitle={"Legal Entities List"}
        validDelete={() => deleteLegalEntity(deleteModal.id ?? -1)}
        handleClose={() => closeDeleteLegalEntity()}
      />
      <FormModal
        {...props}
        ishiddenfooter={hiddenFooter}
        header={
          defaultValues.id !== -1 ? (
            <div className="d-flex">
              <div
                className={`ms-3 ${
                  isLegalEntityEdit
                    ? classes.modalHeaderTitleEditNotActive
                    : classes.modalHeaderTitleEdit
                }`}
                onClick={() => {
                  setIsLegalEntityEdit(false);
                }}
              >
                Underwriter - {defaultValues.name}
              </div>

              <div
                className={`ms-3 ${
                  isLegalEntityEdit
                    ? classes.modalHeaderTitleEdit
                    : classes.modalHeaderTitleEditNotActive
                }`}
                onClick={() => {
                  setIsLegalEntityEdit(true);
                }}
              >
                Legal Entity(ies) List
              </div>
            </div>
          ) : (
            <div className={`ms-3 ${classes.modalHeaderTitle}`}>
              New - Underwriter
            </div>
          )
        }
        handleClose={() => {
          if (onClose) onClose();
          handleClose();
          setIsLegalEntityEdit(false);
          setLegalEntityEditingKey("");
        }}
        onSubmitForm={(e) => {
          onSubmitForm(e);
          console.log("onSubmitForm", e);
        }}
        isDisabledSave={
          !isDirty ||
          Object.keys(errors).length > 0 ||
          !isCompletedForm ||
          dataSourceLegalEntity.filter((f) => f.legal_entity).length === 0

          //false
          // (watchFormValue('name') === '' ||
          //   dataSourceLegalEntity.filter((f) => f.legal_entity).length === 0 ||
          //   Object.keys(errors).length > 0 ||
          //   isEqual(
          //     defaultValues,
          //     pick(watchFormValue(), Object.keys(defaultValues))
          //   )) &&
          // isEqual(
          //   dataSourceLegalEntity?.map((e) => e.legal_entity?.id),
          //   defaultFormValues?.legal_entities.map((e) => e.id) ?? []
          // ) &&
          // isEqual(
          //   dataSourceLegalEntity?.map((e) => e.roles.join(',')),
          //   defaultFormValues?.legal_entities.map(
          //     (e) => e.pivot?.roles ?? ''
          //   ) ?? []
          // )
        }
      >
        {!isLegalEntityEdit && (
          <>
            <Row className="pt-4">
              <Col className="px-3">
                <RowContent>
                  <CofarcoFormText
                    label="ID"
                    class="w-100 align-items-baseline"
                    errors={errors}
                    key_form="name"
                    register={register("name", {
                      required: true,
                      validate: (value) => value.length > 0 || "Required",
                    })}
                    isRequired
                    txtError="Required"
                  />
                </RowContent>
                <RowContent>
                  <CofarcoFormText
                    label="Commercial Name"
                    class="w-100 align-items-baseline"
                    errors={errors}
                    key_form="commercial_name"
                    register={register("commercial_name")}
                    txtError="Required"
                  />
                </RowContent>
                <RowContent>
                  <SelectDropDown
                    noPadding
                    dropDownClass="px-0"
                    title="Activity"
                    id="clientActivity"
                    placeholder=""
                    preValue={getFormValue("_activity")}
                    onSearch={(d, v) => {
                      d(
                        uniq(
                          terms.filter(
                            (f) =>
                              f.type === "activity" &&
                              (f.opt === 4 || f.opt === 28) &&
                              f.value.toLowerCase().includes(v.toLowerCase())
                          )
                        )
                      );
                    }}
                    options={uniq(
                      terms.filter(
                        (f) =>
                          f.type === "activity" && (f.opt === 4 || f.opt === 28)
                      )
                    )}
                    onSelectOption={(e) => {
                      setFormValue(
                        "activity",
                        (e as DropDownOptionObjectType).key as string,
                        {
                          shouldDirty: true,
                        }
                      );
                      setFormValue(
                        "_activity",
                        (e as DropDownOptionObjectType).value as string,
                        {
                          shouldDirty: true,
                        }
                      );
                    }}
                  />
                </RowContent>

                <RowContent>
                  <SelectDropDown
                    noPadding
                    dropDownClass="px-0"
                    title="Main Contact"
                    id="main_people"
                    placeholder=""
                    preValue={
                      (watchFormValue("_main_people_id") ?? "") as string
                    }
                    onSearch={(d, v) => {
                      searchPeopleCallback(
                        dataSourceLegalEntity
                          .filter((f) => f.legal_entity)
                          .map((e) => e.legal_entity?.id ?? 0),
                        v,
                        (data) => {
                          d(
                            data.map((d) => ({
                              key: d,
                              value: `${d.firstname} ${d.lastname}`,
                            }))
                          );
                        }
                      );
                    }}
                    options={peoples.map((d) => {
                      console.log("d", d);
                      return {
                        key: d,
                        value: `${d.firstname} ${d.lastname}`,
                      };
                    })}
                    onSelectOption={(e) => {
                      if (e) {
                        setFormValue(
                          "main_people_id",
                          (e as DropDownOptionObjectType).key.id as number,
                          {
                            shouldDirty: true,
                          }
                        );
                        setFormValue(
                          "_main_people",
                          (e as DropDownOptionObjectType).key as People,
                          {
                            shouldDirty: true,
                          }
                        );
                      } else {
                        setFormValue("main_people_id", null, {
                          shouldDirty: true,
                        });
                        setFormValue("_main_people", undefined, {
                          shouldDirty: true,
                        });
                      }
                    }}
                    icon={
                      <FaUser
                        style={{
                          fontSize: 22,
                          cursor: "pointer",
                          color: watchFormValue("main_people_id")
                            ? "#6d1206"
                            : "",
                        }}
                        onClick={() => {
                          const v = getFormValue("main_people_id");
                          if (v) {
                            showPeopleModal(v);
                          }
                        }}
                      />
                    }
                  />
                </RowContent>

                <RowContent>
                  <SelectDropDown
                    noPadding
                    dropDownClass="px-0"
                    title="Most Senior Contact"
                    id="most_senior_people"
                    placeholder=""
                    preValue={
                      (watchFormValue("_most_senior_people_id") ?? "") as string
                    }
                    onSearch={(d, v) => {
                      searchPeopleCallback(
                        dataSourceLegalEntity
                          .filter((f) => f.legal_entity)
                          .map((e) => e.legal_entity?.id ?? 0),
                        v,
                        (data) => {
                          d(
                            data.map((d) => ({
                              key: d,
                              value: `${d.firstname} ${d.lastname}`,
                            }))
                          );
                        }
                      );
                    }}
                    options={peoples.map((d) => {
                      console.log("d", d);
                      return {
                        key: d,
                        value: `${d.firstname} ${d.lastname}`,
                      };
                    })}
                    onSelectOption={(e) => {
                      if (e) {
                        setFormValue(
                          "most_senior_people_id",
                          (e as DropDownOptionObjectType).key.id as number,
                          {
                            shouldDirty: true,
                          }
                        );
                        setFormValue(
                          "_most_senior_people",
                          (e as DropDownOptionObjectType).key as People,
                          {
                            shouldDirty: true,
                          }
                        );
                      } else {
                        setFormValue("most_senior_people_id", null, {
                          shouldDirty: true,
                        });
                        setFormValue("_most_senior_people", undefined, {
                          shouldDirty: true,
                        });
                      }
                    }}
                    icon={
                      <FaUser
                        style={{
                          fontSize: 22,
                          cursor: "pointer",
                          color: watchFormValue("most_senior_people_id")
                            ? "#6d1206"
                            : "",
                        }}
                        onClick={() => {
                          const v = getFormValue("most_senior_people_id");
                          if (v) {
                            showPeopleModal(v);
                          }
                        }}
                      />
                    }
                  />
                </RowContent>
              </Col>

              <Col>
                <RowContent>
                  <SelectDropDown
                    noPadding
                    dropDownClass="px-0"
                    title="Access"
                    id="access"
                    placeholder=""
                    preValue={getFormValue("informations.access")}
                    noSearch
                    options={uniq(["direct", "indirect"])}
                    onSelectOption={(e) => {
                      setFormValue(
                        "informations",
                        {
                          ...getFormValue("informations"),
                          access: e as "direct" | "indirect" | undefined,
                        },
                        {
                          shouldDirty: true,
                        }
                      );
                    }}
                  />
                </RowContent>
                <RowContent>
                  <SelectDropDown
                    noPadding
                    dropDownClass="px-0"
                    title="M2M"
                    id="m2m"
                    placeholder=""
                    preValue={
                      choiceThee.find(
                        (f) =>
                          (f.key as ChoiceType) ===
                          (getFormValue("informations.m2m") as ChoiceType)
                      )?.value
                    }
                    noSearch
                    options={uniq(choiceThee.map((e) => e.value))}
                    onSelectOption={(e) => {
                      setFormValue(
                        "informations",
                        {
                          ...getFormValue("informations"),
                          m2m: choiceThee.find(
                            (f) => (f.value as string) === (e as string)
                          )?.key,
                        },
                        {
                          shouldDirty: true,
                        }
                      );

                      setFormValue(
                        "informations",
                        {
                          ...getFormValue("informations"),
                          _m2m: choiceThee.find(
                            (f) => (f.value as string) === (e as string)
                          )?.key,
                        },
                        {
                          shouldDirty: true,
                        }
                      );
                    }}
                  />
                </RowContent>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col className="mt-0">
                {watchFormValue("id") <= 0 && (
                  <>
                    <div className="font-bold mt-0">Legal Entities List</div>
                    <div className="content-table mt-4">
                      <div className="content-table">
                        <AntForm form={formLegalEntity} component={false}>
                          <Table
                            className="no-background"
                            style={{ backgroundColor: "transparent" }}
                            rowClassName={classes.antdTable}
                            dataSource={dataSourceLegalEntity}
                            columns={mergedLegalEntity}
                            rowKey="id"
                            pagination={false}
                          />
                        </AntForm>
                      </div>
                      <Button
                        className="btn red mt-3 py-0"
                        onClick={() => addLegalEntity()}
                        disabled={isInModeEditing}
                      >
                        <img src={AddGroup} alt="" className="pr-2" /> Add Legal
                        Entity
                      </Button>
                    </div>
                  </>
                )}
              </Col>
            </Row>
          </>
        )}
        {isLegalEntityEdit && (
          <Row>
            {watchFormValue("id") > 0 && (
              <>
                <div className="content-table mt-4">
                  <div className="content-table">
                    <AntForm form={formLegalEntity} component={false}>
                      <Table
                        className="no-background"
                        rowClassName={classes.antdTable}
                        dataSource={dataSourceLegalEntity}
                        columns={mergedLegalEntity}
                        rowKey="id"
                        pagination={false}
                      />
                    </AntForm>
                  </div>
                  {!hiddenFooter && (
                    <Button
                      className="btn red mt-3 py-0"
                      disabled={isInModeEditing}
                      onClick={() => addLegalEntity()}
                    >
                      <img src={AddGroup} alt="" className="pr-2" /> Add Legal
                      Entity
                    </Button>
                  )}
                </div>
              </>
            )}
          </Row>
        )}
      </FormModal>
      <ModalUnsavedChange
        showModalUnsaved={isShowModalUnsavedChange}
        handleCancel={() => setIsShowModalUnsavedChange(false)}
        handleLeave={() => showPeopleModal(showOtherModaldata, true)}
        // dirtiFields={dirtyFields}
      />
    </>
  );
};

export default UnderwriterModal;
