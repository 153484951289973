import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import SelectDropDown from "../../../common/DropDown/SelectDropDown";
import HeaderTab from "../../../HeaderTab/HeaderTab";
import FormModal from "../../../Modal/FormModal/FormModal";
import UseModalNotes from "./UseModalNotes";
import CofarcoFormText from "../../../Form/CofarcoFormText";
import {
  exportedDropdownValue,
  isExist,
  makeOptions,
} from "../../../../utils/helper-function";
import {
  DropDownOptionObjectType,
  DropDownOptionType,
} from "../../../common/DropDown/DropdownSelect";
import UseConstante from "../../../../hooks/use-constante";
import { TermConst } from "../../../../@types/common-types";
import CofarcoFormNumber from "../../../Form/CofarcoFormNumber";
import FormError from "../../../FormError/FormError";
import CofarcoNumberAmount from "../../../Form/CofarcoNumberAmount";
import CofarcoNumberPercentage from "../../../Form/CofarcoNumberPercentage";
import { ReactComponent as PartnerIncatif } from "../../../../assets/img/PartnerIncatif.svg";
import { ReactComponent as PartnerActif } from "../../../../assets/img/PartnerActif.svg";
import { ReactComponent as LegalentitiesActif } from "../../../../assets/img/LegalentitiesActif.svg";
import { ReactComponent as LegalentitiesInactif } from "../../../../assets/img/LegalentitiesInactif.svg";
import { ReactComponent as ParticipationActif } from "../../../../assets/img/ParticipationActif.svg";
import { ReactComponent as ParticipationInactif } from "../../../../assets/img/ParticipationInactif.svg";
import { ReactComponent as AddIban } from "../../../../assets/img/AddIban.svg";
import CofarcoCalendar from "../../../Calendar/CofarcoCalendar";
import RowContent from "../../../Form/RowContent";
import IbanFormModal from "../../SegAccCommon/IbanFormModal";
import { InputFormBankAccount } from "../../../../@types/segregatedaccounts/payements";
type IProps = {
  show: boolean;
  handleClose: (mandate: any | null) => void;
  defaultFormValues: any | null;
};

export default function ModalNotes(props: IProps) {
  const provider = UseModalNotes({
    defaultFormValues: props.defaultFormValues,
    closingModal: props.handleClose,
  });
  const terms = UseConstante();

  React.useEffect(() => {
    provider.handleGetDeals("");
  }, [props.show]);

  return (
    <FormModal
      show={props.show}
      handleClose={() => {
        provider.resetForm();
        props.handleClose(null);
      }}
      isLoading={provider.isCreatingNote}
      onSubmitForm={provider.handleSubmit(provider.submit)}
      header={
        <HeaderTab
          isEdit={false}
          title={provider.handleTitle(provider.note_type)}
          noRigth
          setActiveMenu={() => null}
        />
      }
      isDisabledSave={!provider.isCompletedForm || provider.isCreatingNote}
      children={
        <Container className="mt-4">
          <Row>
            <Col>
              <div className="my-2">
                <RowContent>
                  <Form.Group className={`d-flex align-items-baseline`}>
                    <Form.Label className="txt-with-dropdown">
                      Deal <span className="span-required">*</span>
                    </Form.Label>

                    <div className="d-flex flex-column w-100">
                      <SelectDropDown
                        noPadding
                        error={provider.errors?.deal_id?.message}
                        modalId="mandate"
                        loading={provider.isLoadingDeals}
                        options={makeOptions(
                          provider.dealList || [],
                          "complete_number",
                          "id"
                        )}
                        onSearch={(d, v) => provider.runSearch("deal_id", v)}
                        onSelectOption={(e) => {
                          provider.setValue(
                            "deal_id",
                            (e as DropDownOptionObjectType).key,
                            {
                              shouldDirty: true,
                            }
                          );
                          if (e) {
                            provider.clearFormErrors("deal_id");
                            return;
                          } else {
                            provider.setFormError("deal_id", {
                              message: "Deal ID is required",
                            });
                          }
                        }}
                        preValue={exportedDropdownValue(
                          makeOptions(
                            provider.dealList || [],
                            "complete_number",
                            "id"
                          ),
                          provider.deal_id
                        )}
                      />
                    </div>
                  </Form.Group>
                </RowContent>
              </div>
              <div className={`${isExist(provider.deal_id) ? "" : "d-none"}`}>
                <RowContent>
                  <Form.Group className={`d-flex align-items-baseline`}>
                    <Form.Label className="txt-with-dropdown">
                      Note Type <span className="span-required">*</span>
                    </Form.Label>

                    <div className="d-flex flex-column w-100">
                      <SelectDropDown
                        noPadding
                        error={provider.errors?.note_type?.message}
                        modalId="deal-deal_type"
                        noSearch
                        preValue={exportedDropdownValue(
                          terms.getConst(TermConst.noteType),
                          provider.note_type
                        )}
                        options={makeOptions(
                          terms.getConst(TermConst.noteType)
                        )}
                        onSelectOption={(e) => {
                          provider.setValue(
                            "note_type",
                            (e as DropDownOptionObjectType).key,
                            {
                              shouldDirty: true,
                            }
                          );
                          if (e) {
                            provider.clearFormErrors("note_type");
                            return;
                          }
                          provider.setFormError("note_type", {
                            message: "Note Type is required",
                          });
                        }}
                      />
                    </div>
                  </Form.Group>
                </RowContent>
              </div>
              <div className={`${isExist(provider.note_type) ? "" : "d-none"}`}>
                <RowContent>
                  <Form.Group
                    className="d-flex"
                    style={{ alignItems: "baseline" }}
                  >
                    <Form.Label className="txt">
                      Issuing Date <span className="span-required">*</span>{" "}
                    </Form.Label>

                    <CofarcoCalendar
                      required
                      name="issuing_date"
                      control={provider.control}
                      setFormValue={provider.setValue}
                      watchFormValue={provider.watch}
                      setFormError={provider.setFormError}
                      clearFormErrors={provider.clearFormErrors}
                      txtErrorMsg="Issuing Date is required"
                      errors={provider.errors}
                    />
                  </Form.Group>
                </RowContent>
                <div className={`${provider.isHiddeEndorSementnumber}`}>
                  <RowContent>
                    <Form.Group className="d-flex align-items-baseline w-100">
                      <Form.Label className="txt txt-center-fix">
                        Endorsement n°
                      </Form.Label>
                      <div className="d-flex flex-row  justify-content-center  w-100">
                        <div className="w-100">
                          <CofarcoFormNumber
                            minLength={3}
                            acceptFloat={false}
                            register={provider.register}
                            setValue={provider.setValue}
                            watch={provider.watch}
                            key_form="endorsement_number"
                          />
                        </div>
                      </div>
                    </Form.Group>
                  </RowContent>
                </div>
              </div>

              <div
                className={`${
                  provider.note_type === "ccnb" || provider.note_type === "cdnb"
                    ? ""
                    : "d-none"
                }`}
              >
                <RowContent>
                  <Form.Group className={`d-flex align-items-baseline`}>
                    <Form.Label className="txt-with-dropdown">
                      Broker
                    </Form.Label>

                    <div className="d-flex flex-column w-100">
                      <SelectDropDown
                        noPadding
                        icon={
                          <span style={{ cursor: "pointer" }}>
                            {provider.counterparty_id ? (
                              <PartnerActif
                                onClick={() =>
                                  provider.showPartner(provider.counterparty_id)
                                }
                              />
                            ) : (
                              <PartnerIncatif />
                            )}
                          </span>
                        }
                        loading={provider.loadingBroker}
                        modalId="mandate"
                        options={makeOptions(
                          provider.brokerList || [],
                          "name",
                          "id"
                        )}
                        preValue={exportedDropdownValue(
                          makeOptions(provider.brokerList || [], "name", "id"),
                          provider.counterparty_id
                        )}
                        onSearch={(d, v) =>
                          provider.runSearch("counterparty_id", v)
                        }
                        onFocus={() => {
                          provider.handlCounterPartie("", "partner");
                        }}
                        onSelectOption={(e: DropDownOptionType) => {
                          provider.setValue(
                            "counterparty_id",
                            (e as DropDownOptionObjectType).key
                          );
                        }}
                      />
                    </div>
                  </Form.Group>
                </RowContent>
              </div>
              <div
                className={`${
                  provider.note_type === "ccni" ||
                  provider.note_type === "pcni" ||
                  provider.note_type === "pdni"
                    ? ""
                    : "d-none"
                }`}
              >
                <div>
                  <RowContent>
                    <Form.Group className="d-flex align-items-baseline w-100 pt-2">
                      <Form.Label className="txt">Insured</Form.Label>

                      <div className="d-flex flex-column w-100">
                        {provider.selectedCounterParty?.name || "-"}
                      </div>
                    </Form.Group>
                  </RowContent>
                </div>
              </div>
              <div
                className={`${
                  provider.note_type === "cdnu" ||
                  provider.note_type === "pcnu" ||
                  provider.note_type === "pdnu"
                    ? ""
                    : "d-none"
                }`}
              >
                <RowContent>
                  <Form.Group className={`d-flex align-items-baseline`}>
                    <Form.Label className="txt-with-dropdown">
                      Participation
                    </Form.Label>

                    <div className="d-flex flex-column w-100">
                      <SelectDropDown
                        noPadding
                        icon={
                          provider.isShowIconeParticipation ? (
                            <span style={{ cursor: "pointer" }}>
                              {provider.participation_id ? (
                                <ParticipationActif
                                  onClick={() =>
                                    provider.showParticipation(
                                      provider.participation_id
                                    )
                                  }
                                />
                              ) : (
                                <ParticipationInactif />
                              )}
                            </span>
                          ) : null
                        }
                        noSearch
                        modalId="mandate"
                        options={makeOptions(
                          provider.participationList || [],
                          "number_and_counterparty",
                          "id"
                        )}
                        preValue={exportedDropdownValue(
                          makeOptions(
                            provider.participationList || [],
                            "number_and_counterparty",
                            "id"
                          ),
                          provider.participation_id
                        )}
                        onSelectOption={(e: DropDownOptionType) => {
                          provider.setValue(
                            "participation_id",
                            (e as DropDownOptionObjectType).key
                          );
                        }}
                      />
                    </div>
                  </Form.Group>
                </RowContent>
              </div>
              <div
                className={`${
                  isExist(provider.note_type) &&
                  (provider.note_type === "ccni" ||
                    provider.note_type === "pcni" ||
                    provider.note_type === "pdni" ||
                    ((provider.note_type === "pdnu" ||
                      provider.note_type === "pcnu" ||
                      provider.note_type === "cdnu") &&
                      isExist(provider.participation_id)) ||
                    provider.counterparty_id)
                    ? ""
                    : "d-none"
                }`}
              >
                <RowContent>
                  {" "}
                  <Form.Group className={`d-flex align-items-baseline`}>
                    <Form.Label className="txt-with-dropdown">
                      Recipient <span className="span-required">*</span>
                    </Form.Label>

                    <div className="d-flex flex-column w-100">
                      <SelectDropDown
                        noPadding
                        icon={
                          provider.isShowIconeRecipient ? (
                            <span style={{ cursor: "pointer" }}>
                              {provider.legal_entity_id ? (
                                <LegalentitiesActif
                                  onClick={() =>
                                    provider.showLegalEntity(
                                      provider.legal_entity_id
                                    )
                                  }
                                />
                              ) : (
                                <LegalentitiesInactif />
                              )}
                            </span>
                          ) : null
                        }
                        noSearch
                        modalId="mandate"
                        error={provider.errors?.legal_entity_id?.message}
                        options={makeOptions(
                          provider.receipientList || [],
                          "name",
                          "id"
                        )}
                        preValue={exportedDropdownValue(
                          makeOptions(
                            provider.receipientList || [],
                            "name",
                            "id"
                          ),
                          provider.legal_entity_id
                        )}
                        onSelectOption={(e) => {
                          provider.setValue(
                            "legal_entity_id",
                            (e as DropDownOptionObjectType).key,
                            {
                              shouldDirty: true,
                            }
                          );
                          if (e) {
                            provider.clearFormErrors("legal_entity_id");
                            return;
                          }
                          provider.setFormError("legal_entity_id", {
                            message: "Recipient is required",
                          });
                        }}
                      />
                    </div>
                  </Form.Group>
                </RowContent>
              </div>

              <div className={`${provider.subClassIbanReceipt}`}>
                <RowContent>
                  <Form.Group className={`d-flex align-items-baseline`}>
                    <Form.Label className="txt-with-dropdown">
                      Recipient's IBAN
                    </Form.Label>

                    <div className="d-flex flex-column w-100">
                      <SelectDropDown
                        noPadding
                        modalId="mandate"
                        icon={
                          <span style={{ cursor: "pointer" }}>
                            <AddIban
                              onClick={() => provider.setShowModalAddIban(true)}
                            />
                          </span>
                        }
                        noSearch
                        options={provider.ibanList}
                        preValue={exportedDropdownValue(
                          provider.ibanList,
                          provider.entity_bank_account_id
                        )}
                        onSelectOption={(e) =>
                          provider.setValue(
                            "entity_bank_account_id",
                            (e as DropDownOptionObjectType).key,
                            {
                              shouldDirty: true,
                            }
                          )
                        }
                      />
                    </div>
                  </Form.Group>
                </RowContent>
              </div>
              <div
                className={`${
                  isExist(provider.note_type) &&
                  (provider.counterparty_id ||
                    ((provider.note_type === "pcni" ||
                      provider.note_type === "pdni" ||
                      provider.note_type === "pcnu" ||
                      provider.note_type === "pdnu") &&
                      isExist(provider.legal_entity_id)))
                    ? ""
                    : "d-none"
                }`}
              >
                <RowContent>
                  <Form.Group className={`d-flex align-items-baseline`}>
                    <Form.Label className="txt-with-dropdown">
                      Premium Type <span className="span-required">*</span>
                    </Form.Label>

                    <div className="d-flex flex-column w-100">
                      <SelectDropDown
                        noPadding
                        error={provider.errors.premium_type?.message}
                        modalId="deal-deal_type"
                        noSearch
                        preValue={exportedDropdownValue(
                          terms.getConst(TermConst.premiumType),
                          provider.premium_type
                        )}
                        options={makeOptions(
                          terms.getConst(TermConst.premiumType)
                        )}
                        onSelectOption={(e) => {
                          provider.setValue(
                            "premium_type",
                            (e as DropDownOptionObjectType).key,
                            {
                              shouldDirty: true,
                            }
                          );
                          if (e) {
                            provider.clearFormErrors("premium_type");
                            return;
                          }
                          provider.setFormError("premium_type", {
                            message: "Premium Type is required",
                          });
                        }}
                      />
                    </div>
                  </Form.Group>
                </RowContent>
              </div>
            </Col>
            <Col className={`my-2 pb-3`}>
              <div className={`${provider.colClass}`}>
                <Form.Group className="d-flex align-items-start w-100 align-items-start pb-4">
                  <Form.Label
                    className="txt-label-area"
                    style={{ width: "64px", whiteSpace: "pre" }}
                  >
                    {provider.labelCalculatrice}
                    <span className="span-required">*</span>
                  </Form.Label>

                  <div className="d-flex flex-column w-100 ">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      className=" w-100"
                      {...provider.register("premium_calculation_basis", {
                        required: true,
                        onChange: (e) => {
                          if (e.target.value) {
                            provider.clearFormErrors(
                              "premium_calculation_basis"
                            );
                          }
                        },
                      })}
                    />
                    {provider.errors.premium_calculation_basis && (
                      <FormError
                        message={`${provider.labelCalculatrice} is required`}
                      />
                    )}
                  </div>
                </Form.Group>

                <div className={` ${provider.counterparty_id ? "" : "d-none"}`}>
                  <RowContent>
                    <Form.Group className={`d-flex align-items-baseline`}>
                      <Form.Label className="txt-with-dropdown">
                        Broker's role
                      </Form.Label>

                      <div className="d-flex flex-column w-100">
                        <SelectDropDown
                          noPadding
                          noSearch
                          modalId="mandate"
                          options={terms?.getConst(TermConst.brokerRole)}
                          onSelectOption={(e) =>
                            provider.setValue(
                              "broker_role",
                              (e as DropDownOptionObjectType).key,
                              { shouldDirty: true }
                            )
                          }
                          preValue={exportedDropdownValue(
                            terms?.getConst(TermConst.brokerRole),
                            provider.broker_role
                          )}
                        />
                      </div>
                    </Form.Group>
                  </RowContent>
                </div>
                <div>
                  <RowContent>
                    <Form.Group className={`d-flex align-items-baseline`}>
                      <Form.Label className="txt-with-dropdown">
                        Currency <span className="span-required">*</span>
                      </Form.Label>

                      <div className="d-flex flex-column w-100">
                        <SelectDropDown
                          noPadding
                          error={provider.errors?.currency?.message}
                          noSearch
                          modalId="enquiry-exposure_currency"
                          id="enquiry-exposure_currency"
                          placeholder=""
                          preValue={exportedDropdownValue(
                            terms?.getConst(TermConst.currency),
                            provider.currency
                          )}
                          onSelectOption={(e) => {
                            provider.setValue(
                              "currency",
                              (e as DropDownOptionObjectType).key,
                              {
                                shouldDirty: true,
                              }
                            );
                            if (e) {
                              provider.clearFormErrors("currency");
                              return;
                            }
                            provider.setFormError("currency", {
                              message: "Currency is required",
                            });
                          }}
                          options={terms
                            ?.getConst(TermConst.currency)
                            ?.filter(
                              (el: any) =>
                                el?.key !== "xof" && el?.key !== "cny"
                            )}
                        />
                      </div>
                    </Form.Group>
                  </RowContent>
                </div>

                <div>
                  <RowContent>
                    <Form.Group className="d-flex align-items-baseline w-100">
                      <Form.Label className="txt txt-center-fix">
                        {provider.labelAmout}
                      </Form.Label>
                      <div className="d-flex flex-row  justify-content-center  w-100">
                        <div className="w-100">
                          <CofarcoNumberAmount
                            register={provider.register}
                            setValue={provider.setValue}
                            watch={provider.watch}
                            key_form="amount"
                            isAceeptFloat={true}
                          />
                        </div>
                      </div>
                    </Form.Group>
                  </RowContent>
                </div>

                <div
                  className={`${
                    provider.note_type === "pcnu" ||
                    provider.note_type === "pdnu"
                      ? ""
                      : "d-none"
                  }`}
                >
                  <RowContent>
                    <Form.Group className="d-flex align-items-baseline w-100">
                      <Form.Label className="txt txt-center-fix">
                        Brokerage{" "}
                      </Form.Label>
                      <div className="d-flex flex-row  justify-content-center  w-100">
                        <div className="w-100">
                          <CofarcoNumberPercentage
                            register={provider.register}
                            setValue={provider.setValue}
                            watch={provider.watch}
                            key_form="commission_rate"
                            unitFormat={3}
                          />
                        </div>
                      </div>
                    </Form.Group>
                  </RowContent>
                </div>

                <div>
                  <RowContent>
                    <Form.Group className="d-flex align-items-baseline w-100">
                      <Form.Label className="txt txt-center-fix">
                        Tax Amount
                      </Form.Label>
                      <div className="d-flex flex-row  justify-content-center  w-100">
                        <div className="w-100">
                          <CofarcoNumberAmount
                            register={provider.register}
                            setValue={provider.setValue}
                            watch={provider.watch}
                            key_form="tax_amount"
                            isAceeptFloat={true}
                          />
                        </div>
                      </div>
                    </Form.Group>
                  </RowContent>
                </div>
                <div
                  className={`${
                    provider.note_type === "pdni" ||
                    provider.note_type === "ccni"
                      ? ""
                      : "d-none"
                  }`}
                >
                  <RowContent>
                    <Form.Group
                      className="d-flex"
                      style={{ alignItems: "baseline" }}
                    >
                      <Form.Label className="txt">
                        Settlement Due Date
                        {provider.isRequiredSettledDate && (
                          <span className="span-required">*</span>
                        )}
                      </Form.Label>

                      <CofarcoCalendar
                        name="settlement_date"
                        control={provider.control}
                        setFormValue={provider.setValue}
                        required={provider.isRequiredSettledDate ? true : false}
                        errors={provider.errors}
                        txtErrorMsg="Settlement Due Date is required"
                        clearFormErrors={provider.clearFormErrors}
                        watchFormValue={provider.watch}
                        setFormError={provider.setFormError}
                      />
                    </Form.Group>
                  </RowContent>
                </div>
                <div
                  className={`${
                    provider.note_type === "pdni" ||
                    provider.note_type === "pdnu"
                      ? ""
                      : "d-none"
                  }`}
                >
                  <RowContent>
                    <Form.Group
                      className="d-flex"
                      style={{ alignItems: "baseline" }}
                    >
                      <Form.Label className="txt">Payment Due Date</Form.Label>
                      <CofarcoCalendar
                        name="payment_date"
                        control={provider.control}
                        setFormValue={provider.setValue}
                        watchFormValue={provider.watch}
                      />
                    </Form.Group>
                  </RowContent>
                </div>

                <div className={`${provider.subClassRate}`}>
                  <div>
                    <RowContent>
                      <Form.Group className="d-flex align-items-baseline w-100">
                        <Form.Label className="txt txt-center-fix">
                          Gross Rate
                        </Form.Label>
                        <div className="d-flex flex-row  justify-content-center  w-100">
                          <div className="w-100">
                            <CofarcoNumberPercentage
                              register={provider.register}
                              setValue={provider.setValue}
                              watch={provider.watch}
                              key_form="gross_rate"
                              unitFormat={6}
                              isInPrefix
                            />
                          </div>
                        </div>
                      </Form.Group>
                    </RowContent>
                  </div>

                  <div>
                    <RowContent>
                      <Form.Group className="d-flex align-items-baseline w-100">
                        <Form.Label className="txt txt-center-fix">
                          Net Rate
                        </Form.Label>
                        <div className="d-flex flex-row  justify-content-center w-100">
                          <div className="w-100">
                            <CofarcoNumberPercentage
                              register={provider.register}
                              setValue={provider.setValue}
                              watch={provider.watch}
                              key_form="net_rate"
                              unitFormat={6}
                              isInPrefix
                            />
                          </div>
                        </div>
                      </Form.Group>
                    </RowContent>
                  </div>
                </div>

                <div>
                  <RowContent>
                    <Form.Group className={`d-flex align-items-baseline`}>
                      <Form.Label className="txt-with-dropdown">
                        Attention Contact
                      </Form.Label>

                      <div className="d-flex flex-column w-100">
                        <SelectDropDown
                          noPadding
                          modalId="mandate"
                          noSearch
                          options={provider.attPeople?.map((d) => ({
                            key: d.id,
                            value: `${d.firstname} ${d.lastname}`,
                          }))}
                          onSelectOption={(e) =>
                            provider.setValue(
                              "attention_contact_id",
                              (e as DropDownOptionObjectType).key
                            )
                          }
                          preValue={exportedDropdownValue(
                            provider.attPeople?.map((d) => ({
                              key: d.id,
                              value: `${d.firstname} ${d.lastname}`,
                            })),
                            provider.attention_contact_id
                          )}
                        />
                      </div>
                    </Form.Group>
                  </RowContent>
                </div>
                <div>
                  <RowContent>
                    <div style={{ alignItems: "baseline" }}>
                      <CofarcoFormText
                        errors={provider.errors}
                        key_form="client_reference"
                        class={`w-100 ${
                          provider.isUnderWriterReference
                            ? "align-items-start"
                            : "align-items-baseline"
                        }`}
                        label={provider.labelReference}
                        register={provider.register("client_reference")}
                      />
                    </div>
                  </RowContent>
                </div>
              </div>
            </Col>
          </Row>
          <IbanFormModal
            recipientName={
              provider.receipientList?.find(
                (el) => el.id === provider.legal_entity_id
              )?.name || ""
            }
            showModalAddIban={provider.showModalAddIban}
            handleAdd={(value: InputFormBankAccount) => {
              provider.addIban(value);
              provider.setShowModalAddIban(false);
            }}
            legal_entity_id={provider.legal_entity_id}
            handleCancel={() => provider.setShowModalAddIban(false)}
          />
        </Container>
      }
    />
  );
}
