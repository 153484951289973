import { useState } from "react";
import { Col, ModalProps, Row } from "react-bootstrap";
import { Divider, Table, Form as AntForm, Button } from "antd";
import FormModal from "../../../Modal/FormModal/FormModal";
import classes from "./ObligorModal.module.scss";
import { PartnerConterParty } from "../../../../@types/conterParty";
import AddGroup from "../../../../assets/img/add.svg";
import useObligorModal from "./useObligorModal";
import CofarcoFormText from "../../../Form/CofarcoFormText";
import { useGetCountryQuery } from "../../../../redux/api/ws/databases/groups";
import { checkIsEdit, isCan } from "../../../../utils/helper-function";
import { Menu } from "../../../../@types/common-types";

type Props = {
  defaultFormValues?: PartnerConterParty | null;
  onSubmittedForm?: () => void;
  onClose?: () => void;
  top_obligor_country_id?: string;
} & ModalProps;

const ObligorModal = ({
  defaultFormValues,
  onSubmittedForm,
  onClose,
  ...props
}: Props) => {
  const {
    defaultValues,
    errors,
    onSubmitForm,
    register,
    watchFormValue,
    handleClose,
    mergedLegalEntity,
    formLegalEntity,
    dataSourceLegalEntity,
    addLegalEntity,
  } = useObligorModal({ defaultFormValues, onSubmittedForm });

  const { data: responseCountry } = useGetCountryQuery();

  const [isLegalEntityEdit, setIsLegalEntityEdit] = useState<boolean>(false);

  return (
    <FormModal
      {...props}
      ishiddenfooter={
        !!(
          !isCan("write", Menu.obligor) &&
          checkIsEdit(defaultFormValues?.id as number)
        )
      }
      header={
        defaultValues.id !== -1 ? (
          <div className="d-flex">
            <div
              className={`ms-3 ${
                isLegalEntityEdit
                  ? classes.modalHeaderTitleEditNotActive
                  : classes.modalHeaderTitleEdit
              }`}
              onClick={() => {
                setIsLegalEntityEdit(false);
              }}
            >
              Obligor - {defaultValues.name}
            </div>
          </div>
        ) : (
          <div className={`ms-3 ${classes.modalHeaderTitle}`}>New Obligor</div>
        )
      }
      handleClose={() => {
        if (onClose) onClose();
        handleClose();
        setIsLegalEntityEdit(false);
      }}
      onSubmitForm={onSubmitForm}
      isDisabledSave={true}
    >
      {!isLegalEntityEdit && (
        <>
          <Row>
            <Col className="px-3">
              <CofarcoFormText
                label="ID:"
                class="w-100 mt-3"
                errors={errors}
                key_form="name"
                register={{
                  ...register("name", {
                    required: true,
                    validate: (value) => value.length > 0 || "Required",
                  }),
                  disabled: "disabled",
                }}
                isRequired
                txtError="Required"
              />

              <CofarcoFormText
                label="Country :"
                class="w-100 mt-4 "
                errors={errors}
                key_form="country"
                register={{
                  disabled: "disabled",
                  value:
                    responseCountry?.country.find(
                      (f: any) => f.key === props.top_obligor_country_id
                    )?.name ?? "",
                }}
                txtError="Required"
              />

              <CofarcoFormText
                label="Group :"
                class="w-100 mt-4 "
                errors={errors}
                key_form="country"
                register={{ ...register("group_name"), disabled: "disabled" }}
                txtError="Required"
              />
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col className="mt-0">
              {watchFormValue("id") <= 0 && (
                <>
                  <div className="font-bold mt-0">Legal Entity</div>
                  <div className="content-table mt-4">
                    <div className="content-table">
                      <AntForm form={formLegalEntity} component={false}>
                        <Table
                          className="no-background"
                          style={{ backgroundColor: "transparent" }}
                          rowClassName={classes.antdTable}
                          dataSource={dataSourceLegalEntity}
                          columns={mergedLegalEntity}
                          rowKey="id"
                          pagination={false}
                        />
                      </AntForm>
                    </div>
                    <Button
                      className="btn red mt-3 py-0"
                      // disabled={dataSourceLegalEntity.length > 0}
                      onClick={() => addLegalEntity()}
                    >
                      <img src={AddGroup} alt="" className="pr-2" /> Add Legal
                      Entity
                    </Button>
                  </div>
                </>
              )}
            </Col>
          </Row>
        </>
      )}
    </FormModal>
  );
};

//  {
//    isLegalEntityEdit && (
//      <Row>
//        {watchFormValue('id') > 0 && (
//          <>
//            <div className='font-bold mt-0'>Legal Entity</div>
//            <div className='content-table mt-4'>
//              <div className='content-table'>
//                <AntForm form={formLegalEntity} component={false}>
//                  <Table
//                    className='no-background'
//                    rowClassName={classes.antdTable}
//                    dataSource={dataSourceLegalEntity}
//                    columns={mergedLegalEntity}
//                    rowKey='id'
//                    pagination={false}
//                  />
//                </AntForm>
//              </div>
//              {/* <Button
//                   className="btn red mt-3 py-0"
//                   // disabled={dataSourceLegalEntity.length > 0}
//                   onClick={() => addLegalEntity()}
//                 >
//                   <img src={AddGroup} alt="" className="pr-2" /> Add Legal
//                   Entity
//                 </Button> */}
//            </div>
//          </>
//        )}
//      </Row>
//    );
//  }

export default ObligorModal;
