import { SelectOption } from '../@types/common-types';

export const mockRugular: Array<SelectOption> = [
  {
    key: 'ACPR',
    value: 'ACPR',
  },
  {
    key: 'ADGM',
    value: 'ADGM',
  },
  {
    key: 'AMF',
    value: 'AMF',
  },
  {
    key: 'APRA',
    value: 'APRA',
  },
  {
    key: 'ARCEP',
    value: 'ARCEP',
  },
  {
    key: 'Association of British Insurers',
    value: 'Association of British Insurers',
  },
  {
    key: 'Australia Banking Regulator',
    value: 'Australia Banking Regulator',
  },
  {
    key: 'Australian Prudential Regulation Authority',
    value: 'Australian Prudential Regulation Authority',
  },
  {
    key: 'Autorité de Contrôle Prudentiel - ACP',
    value: 'Autorité de Contrôle Prudentiel - ACP',
  },
  {
    key: 'BaFin',
    value: 'BaFin',
  },
  {
    key: 'Banco Central',
    value: 'Banco Central',
  },
  {
    key: 'Bank Al-Maghrib',
    value: 'Bank Al-Maghrib',
  },
  {
    key: 'Bank Negara',
    value: 'Bank Negara',
  },
  {
    key: 'Bank of Russia',
    value: 'Bank of Russia',
  },
  {
    key: 'Bank of Spain',
    value: 'Bank of Spain',
  },
  {
    key: 'Banking Regulations',
    value: 'Banking Regulations',
  },
  {
    key: 'BCEAO',
    value: 'BCEAO',
  },
  {
    key: 'BDDK',
    value: 'BDDK',
  },
  {
    key: 'BEAC',
    value: 'BEAC',
  },
  {
    key: 'Bermuda Monetary Authority',
    value: 'Bermuda Monetary Authority',
  },
  {
    key: 'Capital Markets Authority',
    value: 'Capital Markets Authority',
  },
  {
    key: 'Central Bank of Bahrain',
    value: 'Central Bank of Bahrain',
  },
  {
    key: 'Central Bank of Nigeria',
    value: 'Central Bank of Nigeria',
  },
  {
    key: 'China Banking Regulatory Commission',
    value: 'China Banking Regulatory Commission',
  },
  {
    key: 'CSSF',
    value: 'CSSF',
  },
  {
    key: 'Danish Financial Supervisory Authority (Finanstilsynet)',
    value: 'Danish Financial Supervisory Authority (Finanstilsynet)',
  },
  {
    key: 'De Nederlandsche Bank',
    value: 'De Nederlandsche Bank',
  },
  {
    key: 'Deutsche Bundesbank',
    value: 'Deutsche Bundesbank',
  },
  {
    key: 'EU Insurance Law',
    value: 'EU Insurance Law',
  },
  {
    key: 'European Central Bank',
    value: 'European Central Bank',
  },
  {
    key: 'FCA',
    value: 'FCA',
  },
  {
    key: 'FDIC',
    value: 'FDIC',
  },
  {
    key: 'Federal Reserve System',
    value: 'Federal Reserve System',
  },
  {
    key: 'Financial Conduct Autority',
    value: 'Financial Conduct Autority',
  },
  {
    key: 'Financial Service Authority',
    value: 'Financial Service Authority',
  },
  {
    key: 'Financial Services Agency',
    value: 'Financial Services Agency',
  },
  {
    key: 'Financial Services Authority',
    value: 'Financial Services Authority',
  },
  {
    key: 'Financial Services Board',
    value: 'Financial Services Board',
  },
  {
    key: 'Financial Services Commission - Mauritius',
    value: 'Financial Services Commission - Mauritius',
  },
  {
    key: 'Financial Services Regulation Coordinating Committee',
    value: 'Financial Services Regulation Coordinating Committee',
  },
  {
    key: 'FINMA',
    value: 'FINMA',
  },
  {
    key: 'FSMA',
    value: 'FSMA',
  },
  {
    key: 'Insurance Regulations',
    value: 'Insurance Regulations',
  },
  {
    key: 'MENA Banks Regulation',
    value: 'MENA Banks Regulation',
  },
  {
    key: 'MFSA',
    value: 'MFSA',
  },
  {
    key: 'Monetary Authority of Singapore',
    value: 'Monetary Authority of Singapore',
  },
  {
    key: 'NAIC',
    value: 'NAIC',
  },
  {
    key: 'No Legal Regulation',
    value: 'No Legal Regulation',
  },
  {
    key: 'Not Regulated Bank',
    value: 'Not Regulated Bank',
  },
  {
    key: 'OSFI-BSIF',
    value: 'OSFI-BSIF',
  },
  {
    key: 'Prudential Regulation Authority',
    value: 'Prudential Regulation Authority',
  },
  {
    key: 'Prudentiel Regulation Authority',
    value: 'Prudentiel Regulation Authority',
  },
  {
    key: 'QFCRA',
    value: 'QFCRA',
  },
  {
    key: 'Reserve Bank of India',
    value: 'Reserve Bank of India',
  },
  {
    key: 'SAMA',
    value: 'SAMA',
  },
  {
    key: 'SEC',
    value: 'SEC',
  },
  {
    key: 'South African Reserve Bank',
    value: 'South African Reserve Bank',
  },
];
