import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { baseApi } from "../../baseApi";
import { url } from "../../url";
import { GlobalSearchResponse } from "../../../../@types/pipe";

const global = baseApi.injectEndpoints({
    endpoints: (build: EndpointBuilder<any, any, any>) => {
        return {
            seachGlobal: build.query<GlobalSearchResponse, any>({
                query: ({ search }) => {
                    return {
                        url: url.global.search,
                        params: {
                            _dc: new Date().getTime(),
                            search
                        },
                    };
                },
                providesTags: ["GLOBAL_RESULT_SEARCH"],
            })
        }
    },
});

export const { useLazySeachGlobalQuery } = global;