import React, { useContext, useEffect, useState } from "react";
import {
  EntityParticipation,
  ParticipationFilterType,
} from "../../@types/participation/participation";
import {
  useDeleteParticipationsMutation,
  useExportParticipationsMutation,
  useParticipationsQuery,
} from "../../redux/api/ws/participations/participations";
import * as bootstrap from "bootstrap";
import { useAppDispatch } from "../../hooks/redux";
import ColumnParticipation from "./ColumnParticipations";
import { downloadCsv, titleRequestError } from "../../utils/helper-function";
import { FilterType } from "../../@types";
import { updateNotificationMessage } from "../../redux/slice/notificationSlice";
import { updateModalAction } from "../../redux/slice/modalSlice";
import { exportedParticipations } from "../../utils/exportedParticipations";
import { ContextParticipation } from "./ParticipationContent";
import { debounce } from "lodash";

export default function UseParticipationPage(props?: Props) {
  const [filterData, setFilterData] = useState<ParticipationFilterType>({});
  const [totalData, setTotalData] = useState<number>(0);
  const [modalConfirmDelete, setModalConfirmDelete] = useState(false);

  const [paramsParticipations, setParamsParticipations] = useState<{
    page: number | undefined;
    start: number | undefined;
    limit: number | undefined;
    sort: string;
    filter: string;
  }>({
    page: 1,
    start: 0,
    limit: 20,
    sort: JSON.stringify([{ property: "number", direction: "DESC" }]),
    filter: "[]",
  });

  const [dataSource, setDataSource] = useState<Array<EntityParticipation>>([]);

  const [selectedP, setSelectedP] = useState<EntityParticipation | null>(null);

  const { data, isLoading, isSuccess } =
    useParticipationsQuery(paramsParticipations);
  const [exportParticipations] = useExportParticipationsMutation();

  const { txt } = useContext(ContextParticipation);

  const debounceSearch = React.useCallback(
    debounce((txt) => {
      const params = getFilterParams();
      const tempFilter = [
        ...params,
        { property: "filter", operator: "like", value: `%${txt}%` },
      ];
      setParamsParticipations({
        ...paramsParticipations,
        page: 1,
        start: 0,
        limit: 20,
        filter: JSON.stringify(tempFilter),
      });
    }, 750),
    []
  );

  useEffect(() => {
    if (txt) {
      debounceSearch(txt);
    } else {
      setParamsParticipations({
        page: 1,
        start: 0,
        limit: 20,
        sort: JSON.stringify([{ property: "number", direction: "DESC" }]),
        filter: "[]",
      });
    }
  }, [txt]);

  const [
    deleteParticipation,
    {
      isLoading: isLoadingDelete,
      isSuccess: isSuccessDelete,
      isError: isErrorDelete,
      error: errorResponse,
    },
  ] = useDeleteParticipationsMutation();

  const handleSorted = React.useCallback(
    (key: string, ruls: "ASC" | "DESC") => {
      const paramsFilter = JSON.stringify([{ property: key, direction: ruls }]);
      setParamsParticipations({
        ...paramsParticipations,
        sort: paramsFilter,
      });
    },
    [paramsParticipations]
  );

  const setPerPage = React.useCallback(
    (page: number) => {
      setParamsParticipations({
        ...paramsParticipations,
        limit: page,
      });
    },
    [paramsParticipations]
  );

  const handleTableChange = React.useCallback(
    (current: number) => {
      setParamsParticipations({
        ...paramsParticipations,
        page: current,
      });
    },
    [paramsParticipations]
  );

  const editParticipation = (id: number, data?: EntityParticipation) => {
    const findP = data ?? dataSource?.find((el) => el.id === id);
    if (findP) {
      dispatch(
        updateModalAction({
          data: findP,
          isAdd: true,
          type: "participations",
        })
      );
    }
  };

  const handleShowModalDelete = () => {
    setModalConfirmDelete(!modalConfirmDelete);
  };

  const handleDeleteParticipation = (
    id: number,
    data?: EntityParticipation
  ) => {
    const foundP =
      data ?? dataSource?.find((mdt) => mdt.id?.toString() === id?.toString());
    setSelectedP(foundP || null);
    handleShowModalDelete();
  };

  const { columns, uncheckedId, columnsExport } = ColumnParticipation({
    participations: dataSource,
    handleSorted: handleSorted,
    editParticipation: editParticipation,
    handleDeleteParticipation: handleDeleteParticipation,
    otherColumns: props?.otherColumns,
    columnsToRemove: props?.columnsToRemove,
  });

  const toggleFilterCollapse = () => {
    setFilterData({});
    const collapseSearch = document.getElementById("collapseSearch")!;
    const collapse = new bootstrap.Collapse(collapseSearch, { toggle: true });
    collapse.toggle();
  };
  const dispatch: any = useAppDispatch();

  useEffect(() => {
    if (isSuccess) {
      setDataSource(data?.participation || []);
      setTotalData(data.total);
    }
  }, [isSuccess, data?.participation]);

  const exportParticipationCallback = React.useCallback(
    async (fields: string[]) => {
      const newFields = exportedParticipations
        .filter((e) => fields.includes(e.itemId))
        .map((e) => e.itemId);
      const res = await exportParticipations({
        fields: newFields,
        filter: Object.values(filterData),
      });
      if (res) {
        const rep = res as any;
        downloadCsv(rep.data, "participations.csv");
      }
    },
    [filterData]
  );

  const updateFilter = React.useCallback(
    (key: keyof ParticipationFilterType, value?: string | null) => {
      let newFilter = { ...filterData };
      if (!value || value?.trim() === "") {
        delete newFilter[key];
      } else {
        newFilter = {
          ...newFilter,
          [key]: {
            property: key,
            value: value,
            operator: "=",
          },
        };
      }
      setFilterData(newFilter);
    },
    [filterData]
  );

  const getFilterParams = React.useCallback((): FilterType[] => {
    const values = Object.values(filterData).map((e) => {
      return {
        ...e,
        value: `${typeof e.value === "string" ? e.value?.trim() : e.value}`,
      };
    });

    return values;
  }, [filterData]);

  const getParticipationCallback = () => {
    setParamsParticipations({
      ...paramsParticipations,
      page: 1,
      start: 0,
      limit: 20,
      filter: JSON.stringify(getFilterParams()),
    });
  };

  useEffect(() => {
    const params = getFilterParams();
    if (params.length === 0) {
      setParamsParticipations({
        ...paramsParticipations,
        page: 1,
        start: 0,
        limit: 20,
        filter: JSON.stringify(params),
      });
    }
  }, [filterData]);

  const validDelete = () => {
    if (selectedP?.id) {
      deleteParticipation({ id: selectedP?.id || -1 });
    }
  };

  React.useEffect(() => {
    if (isSuccessDelete) {
      handleShowModalDelete();
    }
  }, [isSuccessDelete]);

  React.useEffect(() => {
    if (isErrorDelete) {
      handleShowModalDelete();
      dispatch(
        updateNotificationMessage({
          show: true,
          title: titleRequestError,
          body: errorResponse?.data,
        })
      );
    }
  }, [isErrorDelete]);

  return {
    dataSource,
    toggleFilterCollapse,
    totalData,
    setPerPage,
    handleTableChange,
    columns,
    uncheckedId,
    isLoading,
    paramsParticipations,
    columnsExport,
    exportParticipationCallback,
    filterData,
    updateFilter,
    getParticipationCallback,

    selectedP,
    modalConfirmDelete,
    handleDeleteParticipation,
    handleShowModalDelete,
    validDelete,
    isLoadingDelete,
    editParticipation,
  };
}

type Props = {
  otherColumns?: string[];
  columnsToRemove?: string[];
};
