import React from 'react';
import { NavLink } from 'react-bootstrap';

type IProps = {
  isEdit: boolean;
  title: string;
  titleRigth?: string;
  activeMenu?: number;
  setActiveMenu: (p: number) => void;
  noRigth?: boolean;
};
export default function HeaderTab(props: IProps) {
  return (
    <>
      {props.isEdit ? (
        <div className='d-flex justify-content-start'>
          <NavLink
            className={`p-2 ${
              props.activeMenu === 1 ? 'form-group-title' : 'btn-list-entity'
            } `}
            onClick={() => props.setActiveMenu(1)}
          >
            <span> {props.title}</span>
          </NavLink>
          <div className={`${props.noRigth ? 'd-none' : ''}`}>
            <NavLink
              className={`ml-spacer p-2  ${
                props.activeMenu === 2 ? 'form-group-title' : 'btn-list-entity'
              } `}
              onClick={() => props.setActiveMenu(2)}
            >
              {props.titleRigth}
            </NavLink>
          </div>
        </div>
      ) : (
        <span> {props.title}</span>
      )}
    </>
  );
}
