export const exportedPayement = [
  {
    itemId: 'number',
    text: 'Number',
  },
  {
    itemId: 'reference',
    text: 'Reference',
  },
  {
    itemId: 'direction',
    text: 'Debit/Credit',
  },
  {
    itemId: 'status',
    text: 'Status',
  },
  {
    itemId: 'amount',
    text: 'Premium Amount',
  },
  {
    itemId: 'currency',
    text: 'Currency',
  },

  {
    itemId: 'value_date',
    text: 'Value Date',
  },
  {
    itemId: 'notes',
    text: 'Notes',
  },
  {
    itemId: 'legal_entity_id',
    text: 'Legal Entity',
  },
  {
    itemId: 'payment_date',
    text: 'Payment Date',
  },
  {
    itemId: 'owner_bank_account_id',
    text: 'Cofarco Account',
  },

  {
    itemId: 'legal_entity_country_id',
    text: 'Country Legal Entity',
  },
  {
    itemId: 'comments',
    text: 'Comments',
  },
  {
    itemId: 'created_at',
    text: 'Created Date',
  },
  {
    itemId: 'updated_at',
    text: 'Updated Date',
  },
];
