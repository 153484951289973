export const mockTenor = [
  {
    value: 'Day',
    key: 'day',
  },
  {
    value: 'Month',
    key: 'month',
  },
  {
    value: 'Year',
    key: 'year',
  },
];
