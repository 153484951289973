import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PeopleFilterType } from '../../@types/people';

export interface PeopleFilterState {
  data: PeopleFilterType;
}

const initialState: PeopleFilterState = {
  data: {},
};

export const peopleFilterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setPeopleFilter: (state, action: PayloadAction<PeopleFilterType>) => {
      state.data = action.payload;
      return state;
    },
  },
});

export const { setPeopleFilter } = peopleFilterSlice.actions;
export default peopleFilterSlice.reducer;
