import React, { useState, useEffect, useContext } from "react";
import * as bootstrap from "bootstrap";
import {
  useDeleteMandateMutation,
  useExportMandatesMutation,
  useMandatesQuery,
} from "../../redux/api/ws/mandates/mandates";
import {
  EntityMandate,
  MandatesFilterType,
} from "../../@types/mandates/mandates";
import ColumnsMandate from "./ColumnsMandate";
import { FilterType } from "../../@types";
import { downloadCsv, titleRequestError } from "../../utils/helper-function";
import { useAppDispatch } from "../../hooks/redux";
import { updateNotificationMessage } from "../../redux/slice/notificationSlice";
import { ParamsTable } from "../../@types/common-types";
import { updateModalAction } from "../../redux/slice/modalSlice";
import { debounce, isEqual } from "lodash";
import { ContextMandate } from "./MandatesContent";

export default function UseMandatePage() {
  const [filterData, setFilterData] = useState<MandatesFilterType>({
    activity: {
      operator: "=",
      property: "activity",
      value: "on",
    },
  });
  const [paramsMandates, setParamsMandates] = useState<ParamsTable>({
    page: 1,
    start: 0,
    limit: 20,
    sort: JSON.stringify([{ property: "number", direction: "DESC" }]),
    filter: JSON.stringify([
      { property: "activity", operator: "=", value: true },
    ]),
  });

  const { txt } = useContext(ContextMandate);

  const [toogleFilterValue, setToogleFilterValue] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const [dataSource, setDataSource] = useState<Array<EntityMandate>>([]);
  const { data, isLoading, isSuccess } = useMandatesQuery(paramsMandates, {
    refetchOnMountOrArgChange: true,
  });
  const [totalData, setTotalData] = useState<number>(0);
  const [selectedMandate, setSelectedMandate] = useState<EntityMandate | null>(
    null
  );
  const [modalConfirmDelete, setModalConfirmDelete] = useState(false);

  const [exportMandates] = useExportMandatesMutation();
  const [
    deleteMandate,
    {
      isLoading: isLoadingDelete,
      isSuccess: isSuccessDelete,
      isError: isErrorDelete,
      error: errorResponse,
    },
  ] = useDeleteMandateMutation();

  const toggleFilterCollapse = () => {
    setFilterData({
      activity: {
        operator: "=",
        property: "activity",
        value: "on",
      },
    });
    const collapseSearch = document.getElementById("collapseSearch")!;
    const collapse = new bootstrap.Collapse(collapseSearch, { toggle: true });
    setToogleFilterValue(!toogleFilterValue);
    collapse.toggle();
  };

  const handleSorted = React.useCallback(
    (key: string, ruls: "ASC" | "DESC") => {
      const paramsFilter = JSON.stringify([{ property: key, direction: ruls }]);
      setParamsMandates({
        ...paramsMandates,
        sort: paramsFilter,
        filter: JSON.stringify(getFilterParams()),
      });
    },
    [paramsMandates, filterData]
  );

  const setPerPage = React.useCallback(
    (page: number) => {
      setParamsMandates({
        ...paramsMandates,
        limit: page,
      });
    },
    [paramsMandates]
  );

  const handleTableChange = React.useCallback(
    (current: number) => {
      setParamsMandates({
        ...paramsMandates,
        page: current,
      });
    },
    [paramsMandates]
  );

  const debounceSearch = React.useCallback(
    debounce((txt) => {
      const params = getFilterParams();
      const tempFilter = [
        ...params,
        { property: "filter", operator: "like", value: `%${txt}%` },
      ];
      setParamsMandates({
        ...paramsMandates,
        page: 1,
        start: 0,
        limit: 20,
        filter: JSON.stringify(tempFilter),
      });
    }, 750),
    []
  );

  useEffect(() => {
    if (txt) {
      debounceSearch(txt);
    } else {
      setParamsMandates({
        page: 1,
        start: 0,
        limit: 20,
        sort: JSON.stringify([{ property: "number", direction: "DESC" }]),
        filter: JSON.stringify([
          { property: "activity", operator: "=", value: true },
        ]),
      });
    }
  }, [txt]);

  const editMandate = (id: number, data?: EntityMandate) => {
    const findMandate = data ?? dataSource?.find((el) => el.id === id);
    if (findMandate) {
      dispatch(
        updateModalAction({
          data: {
            ...findMandate,
          },
          isAdd: true,
          type: "mandate",
        })
      );
    }
  };

  const handleDeleteMandate = (id: number, data?: EntityMandate) => {
    const foundMandate =
      data ??
      dataSource?.find(
        (mdt: EntityMandate) => mdt.id?.toString() === id?.toString()
      );
    setSelectedMandate(foundMandate || null);

    handleShowModalDelete();
  };

  const { columns, uncheckedId, columnsExport } = ColumnsMandate({
    editMandate: editMandate,
    handleSorted: handleSorted,
    handleDeleteMandate: handleDeleteMandate,
    mandate: dataSource,
  });

  const handleShowModalDelete = () => {
    setModalConfirmDelete(!modalConfirmDelete);
  };

  const validDelete = () => {
    if (selectedMandate?.id) {
      deleteMandate({ id: selectedMandate?.id || -1 });
    }
  };

  React.useEffect(() => {
    if (isSuccessDelete) {
      handleShowModalDelete();
    }
  }, [isSuccessDelete]);

  React.useEffect(() => {
    if (isErrorDelete) {
      handleShowModalDelete();
      dispatch(
        updateNotificationMessage({
          show: true,
          title: titleRequestError,
          body: errorResponse?.data,
        })
      );
    }
  }, [isErrorDelete]);

  const handleCreateMandate = () => {
    dispatch(
      updateModalAction({
        data: {
          brokerage: 0,
          fee: 0,
          activity: "off",
        },
        isAdd: true,
        type: "mandate",
      })
    );
  };

  useEffect(() => {
    if (isSuccess) {
      setDataSource(data?.mandate || []);
      setTotalData(data.total);
    }
  }, [isSuccess, data?.mandate]);

  const updateFilter = React.useCallback(
    (key: keyof MandatesFilterType, value?: string | null) => {
      let newFilter = { ...filterData };

      if (!value || value?.trim() === "") {
        delete newFilter[key];
      } else {
        newFilter = {
          ...newFilter,
          [key]: {
            property: key,
            value: value,
            operator: "=",
          },
        };
      }
      setFilterData(newFilter);
    },
    [filterData]
  );

  const getFilterParams = React.useCallback((): FilterType[] => {
    const values = Object.values(filterData).map((e) => {
      if (e.property === "activity") {
        return {
          ...e,
          value: e.value === "on" ? true : e.value === "off" ? false : "not",
        };
      }
      return {
        ...e,
        value: `${typeof e.value === "string" ? e.value?.trim() : e.value}`,
      };
    });
    return values.filter((el) => el.value !== "not");
  }, [filterData]);

  const getMandateCallback = () => {
    setParamsMandates({
      ...paramsMandates,
      page: 1,
      start: 0,
      limit: 20,
      filter: JSON.stringify(getFilterParams()),
    });
  };

  useEffect(() => {
    const params = getFilterParams();

    const isInit = params?.length === 1 && isEqual(params?.[0]?.value, true);
    const restoreFilter = (!toogleFilterValue && params.length === 1) || isInit;
    if (restoreFilter) {
      setParamsMandates({
        ...paramsMandates,
        page: 1,
        start: 0,
        limit: 20,
        filter: JSON.stringify(params),
      });
    }
  }, [filterData]);

  const exportMandateCallback = React.useCallback(
    async (fields: string[]) => {
      const res = await exportMandates({
        fields: fields,
        filter: JSON.stringify(getFilterParams()),
      });
      if (res) {
        const rep = res as any;
        downloadCsv(rep.data, "mandates.csv");
      }
    },
    [filterData]
  );

  return {
    toggleFilterCollapse,
    filterData,
    handleCreateMandate,
    handleSorted,
    dataSource,
    paramsMandates,
    handleTableChange,
    setPerPage,
    columns,
    uncheckedId,
    isLoading,
    totalData,
    modalConfirmDelete,
    selectedMandate,
    handleShowModalDelete,
    validDelete,
    isLoadingDelete,
    updateFilter,
    getMandateCallback,
    exportMandateCallback,
    columnsExport,
    editMandate,
  };
}
