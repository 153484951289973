import { Col, Form } from 'react-bootstrap';
import { Control, UseFormClearErrors, UseFormSetError } from 'react-hook-form';
import RowContent from '../../../../Form/RowContent';
import CofarcoCalendar from '../../../../Calendar/CofarcoCalendar';
import { isExist } from '../../../../../utils/helper-function';

type IProps = {
  control: Control<any>;
  register: any;
  setFormValue: any;
  watchFormValue: any;
  clearFormErrors: UseFormClearErrors<any>;
  setFormError: UseFormSetError<any>;
  errors: any;
};
export default function Kyc(props: IProps) {
  const kyc_rate = props.watchFormValue('kyc_rate');

  const isRequiredVettingDate = isExist(kyc_rate) ? true : false;

  return (
    <Col
      style={{
        borderLeft: 'solid #D9D9D9 1px',
      }}
    >
      <div>
        <Form.Group className="d-flex">
          <div className="inputPerso">
            <div className={`contour`}>
              <span
                style={{
                  position: 'absolute',
                  top: '12px',
                  left: '5px',
                }}
                className="boldered-text"
              >
                KYC Fields
              </span>
              <i
                style={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                }}
              ></i>
            </div>
          </div>
        </Form.Group>
      </div>

      <div className="kyc">
        <div>
          <RowContent>
            <Form.Group
              className="d-flex mb-3 mt-3"
              style={{ alignItems: 'baseline' }}
            >
              <Form.Label className="txt">KYC Grade</Form.Label>
              <Form.Control
                type="text"
                placeholder="Grade"
                {...props.register}
                maxLength="5"
              />
            </Form.Group>
          </RowContent>

          <RowContent>
            <Form.Group
              className="d-flex mb-3"
              style={{ alignItems: 'baseline' }}
            >
              <Form.Label className="txt">
                KYC Vetting Date{' '}
                {isRequiredVettingDate && (
                  <span className="span-required">*</span>
                )}{' '}
              </Form.Label>
              <CofarcoCalendar
                control={props.control}
                name="kyc_validation_date"
                setFormValue={props.setFormValue}
                watchFormValue={props.watchFormValue}
                setFormError={props.setFormError}
                clearFormErrors={props.clearFormErrors}
                errors={props.errors}
                txtErrorMsg="KYC Vetting Date is required"
                required={isRequiredVettingDate ? true : false}
              />
            </Form.Group>
          </RowContent>
          <RowContent>
            <Form.Group
              className="d-flex mb-3 "
              style={{ alignItems: 'baseline' }}
            >
              <Form.Label className="txt">KYC Next Review Date</Form.Label>
              <CofarcoCalendar
                control={props.control}
                name="next_review_date"
                setFormValue={props.setFormValue}
                watchFormValue={props.watchFormValue}
                setFormError={props.setFormError}
                clearFormErrors={props.clearFormErrors}
                errors={props.errors}
              />
            </Form.Group>
          </RowContent>
        </div>
      </div>
    </Col>
  );
}
