import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import classes from '../../../CofarcoTable/CofarcoTable.module.scss';
import { Form as FormAnt, Select } from 'antd';

import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
  UseFormWatch,
  UseFormGetValues,
} from 'react-hook-form';
import {
  exportedCountryName,
  exportedDropdownValue,
  floorNumber,
  isExist,
  makeOptions,
} from '../../../../utils/helper-function';
import SelectDropDown from '../../../common/DropDown/SelectDropDown';
import {
  EntityAttachementDeals,
  InputFormDeals,
} from '../../../../@types/deals/deals';
import {
  EntityMandate,
  EntityOwnerName,
} from '../../../../@types/mandates/mandates';
import { ConterParty } from '../../../../@types/conterParty/index';
import { LegalEntity } from '../../../../@types/legal-entities/index';

import { DropDownOptionObjectType } from '../../../common/DropDown/DropdownSelect';
import { FunctionName, TermConst } from '../../../../@types/common-types';
import UseConstante from '../../../../hooks/use-constante';

import CofarcoFormText from '../../../Form/CofarcoFormText';
import { RiFolderUploadFill } from 'react-icons/ri';

import ColumnUpload from './ColumnUpload';
import Add from '../../../../assets/img/add.svg';
import { People } from '../../../../@types/people';
import Flag from 'react-world-flags';
import ColumnBrokerage, { Item } from './ColumnBrokerage';
import DeleteModal from '../../../Modal/DeleteModal';
import {
  useDeleteDealAttachmentMutation,
  useUpdateAttachementByDealsIdMutation,
} from '../../../../redux/api/ws/deals/deals';

import { ReactComponent as MandateRoundedInactif } from '../../../../assets/img/MandateRoundedInactif.svg';
import { ReactComponent as MandateRoundedActif } from '../../../../assets/img/MandateRoundedActif.svg';

import { ReactComponent as ClientInactif } from '../../../../assets/img/ClientInactif.svg';
import { ReactComponent as ClientActive } from '../../../../assets/img/ClientActive.svg';

import { ReactComponent as LegalentitiesActif } from '../../../../assets/img/LegalentitiesActif.svg';
import { ReactComponent as LegalentitiesInactif } from '../../../../assets/img/LegalentitiesInactif.svg';

import { ReactComponent as ObligorInActif } from '../../../../assets/img/ObligorInActif.svg';
import { ReactComponent as ObligorActif } from '../../../../assets/img/ObligorActif.svg';

import { ReactComponent as UnderwriterActif } from '../../../../assets/img/UnderwriterActif.svg';
import { ReactComponent as UnderwriterInactif } from '../../../../assets/img/UnderwriterInactif.svg';

import { ReactComponent as PartnerIncatif } from '../../../../assets/img/PartnerIncatif.svg';
import { ReactComponent as PartnerActif } from '../../../../assets/img/PartnerActif.svg';

import { ReactComponent as FinancierActif } from '../../../../assets/img/FinancierActif.svg';
import { ReactComponent as FinancierInactif } from '../../../../assets/img/FinancierInactif.svg';

import { ReactComponent as PeopleIncatif } from '../../../../assets/img/PeopleInactif.svg';
import { ReactComponent as PeopleActif } from '../../../../assets/img/PeopleActif.svg';

import CofarcoNumberAmount from '../../../Form/CofarcoNumberAmount';
import CofarcoNumberPercentage from '../../../Form/CofarcoNumberPercentage';
import { useGetCountryQuery } from '../../../../redux/api/ws/databases/groups';
import { FormInstance, Table } from 'antd';
import ConfirmModal from '../../../Modal/ConfirmModal';
import RowContent from '../../../Form/RowContent';
import CofarcoCalendar from '../../../Calendar/CofarcoCalendar';
import ModalUnsavedChange from '../../../common/Modal/ModalUnsavedChange';
import { FaUser } from 'react-icons/fa';
import PropTypes from 'prop-types';

const EditableCell: React.FC<EditableCellProps> = React.memo(
  ({ editable, children, dataIndex, record, handleSave, ...restProps }) => {
    const [editing, setEditing] = useState(false);
    const [commentValue, setCommentValue] = useState<string | null>(
      record?.comments,
    );

    const typeRef = useRef<any>(null);
    const commentRef = useRef<any>(null);
    const terms = UseConstante();

    const toggleEdit = async () => {
      setEditing(true);
    };

    React.useEffect(() => {
      if (dataIndex === 'comments' && editing) {
        if (commentRef?.current) {
          commentRef?.current?.focus();
        }
      }
    }, [editing]);

    const save = async (key: string, value: any) => {
      try {
        if (value === -1) {
          setEditing(false);
          return;
        }
        setEditing(!editing);

        if (key === 'attachment_type') {
          const newValue = {
            ...record,
            attachment_type: value || '',
          };
          handleSave(newValue, key);
          return;
        }

        if (key === 'comments') {
          const newValue = {
            ...record,
            comments: value || '',
          };
          handleSave(newValue, key);
          return;
        }
      } catch (errInfo) {
        console.log(errInfo);
      }
    };

    let childNode = children;

    if (editable) {
      childNode = editing ? (
        <>
          {dataIndex === 'attachment_type' && (
            <Select
              options={[
                { value: '', label: '' },
                ...(terms?.getConst(TermConst.attachmentType) || []).map(
                  (el: any) => ({ label: el?.value, value: el?.key }),
                ),
              ]}
              style={{ minWidth: 150, maxWidth: '100%', width: 150 }}
              onSelect={(value) => {
                save('attachment_type', value || '');
              }}
              onDropdownVisibleChange={(visible) => {
                if (!visible) {
                  setEditing(false);
                }
              }}
              ref={typeRef}
              autoFocus
              defaultActiveFirstOption
              defaultValue={record?.attachment_type}
              onBlur={() => {
                if (typeRef && typeRef.current) {
                  typeRef.current?.blur();
                }
              }}
            />
          )}

          {dataIndex === 'comments' && (
            <Form.Control
              as="textarea"
              style={{ maxWidth: '100%' }}
              value={(commentValue as string) || ''}
              ref={commentRef}
              onFocus={() => {
                const inputElement = commentRef.current;
                if (inputElement) {
                  const { value } = inputElement;
                  inputElement.setSelectionRange(value.length, value.length);
                }

                setCommentValue(record.comments);
              }}
              onChange={(evt) => {
                setCommentValue(evt.target.value || '');
              }}
              onBlur={() => save(dataIndex, commentValue || '')}
            />
          )}
        </>
      ) : (
        <div className="editable-cell-value-wrap" onClick={toggleEdit}>
          {children}
        </div>
      );
    }

    return <td {...(restProps as any)}>{childNode}</td>;
  },
);

EditableCell.propTypes = {
  title: PropTypes.string,
  editable: PropTypes.any,
  children: PropTypes.node,
  dataIndex: PropTypes.any,
  record: PropTypes.any,
  handleSave: PropTypes.any,
};

EditableCell.displayName = 'EditableCell';

type IProps = {
  mandatesList: Array<EntityMandate>;
  clientList: Array<ConterParty>;
  legalentityList: Array<LegalEntity>;
  partnerEntityList: Array<LegalEntity>;
  mandateType: string;
  partenerList: Array<ConterParty>;
  txtView: {
    top_obligor_country_id: string;
    obligor: any;
    leadLine: any;
  };
  watch: UseFormWatch<InputFormDeals>;
  setValue: UseFormSetValue<InputFormDeals>;
  getValue: UseFormGetValues<InputFormDeals>;
  dirtyFields: any;
  runSearch: (key: keyof InputFormDeals, value: string) => Promise<void>;
  register: UseFormRegister<InputFormDeals>;
  errors: FieldErrors<InputFormDeals>;
  control: Control<InputFormDeals, any>;
  setFormError: UseFormSetError<InputFormDeals>;
  clearFormErrors: UseFormClearErrors<InputFormDeals>;
  isLoadingSearchMandate: boolean;
  isLoadingClient: boolean;
  isLoadingLegalEntity: boolean;
  isLoadingAttachement: boolean;
  mandatesById: EntityMandate | null | undefined;
  partenersPeople: Array<People>;
  attachementsDataSource: Array<EntityAttachementDeals>;
  setAttachementsDataSource: any;
  optionUserNameOwner: Array<EntityOwnerName & { userName: string }>;
  onAddAttachment: (files: FileList | null) => void;
  isHaveRelatedEntity: boolean;
  brokerAttachementDataSource: Array<Item>;
  setBrokerAttachementDataSource: any;
  hiddenFooter: boolean;
  handleShowOtherModal: (id: any, func: FunctionName, type?: string) => void;
  handleLeaveModalWithoutSave: (
    id: any,
    func: FunctionName,
    type?: string,
  ) => void;
  showOtherModal: any;
  provider: any;
  showPeopleModal: (p: number | string) => void;
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof any;
  record: any;
  handleSave: (record: any, key: string) => void;
}

export default function ChildrenFormDeals({
  provider,
  showOtherModal,
  handleShowOtherModal,
  ...props
}: IProps) {
  const [modalDeleteAttach, setModalDeleteAttach] = useState<boolean>(false);
  const [modalConfirm, setShowmodalConfirm] = useState<boolean>(false);
  const [lastStatusV, setLastStatusV] = useState<any>();
  const [modalConfirmDelete, setModalConfirmDelete] = useState(false);
  const [updatedDealAttachement] = useUpdateAttachementByDealsIdMutation();

  const [selectedAttachment, setSelectedAttachment] =
    useState<EntityAttachementDeals>();

  const [selectedBrokerDataSource, setSelectedBrokerDataSource] =
    useState<Item | null>(null);
  const { data: responseCountry } = useGetCountryQuery();

  const id = props.watch('id');
  const mandate_id = props.watch('mandate_id');
  const market = props.watch('market');
  const currency = props.watch('currency');
  const status = props.watch('status');
  const client_id = props.watch('client_id');
  const client_legal_entity_id = props.watch('client_legal_entity_id');
  const deal_type = props.watch('deal_type');
  const structure = props.watch('structure');
  const in_charge_user_id = props.watch('in_charge_user_id');
  const partner_id = props.watch('partner_id');
  const partner_legal_entity_id = props.watch('partner_legal_entity_id');
  const partner_contact_id = props.watch('partner_contact_id');
  const booking = props.watch('booking');
  const risk_trigger = props.watch('risk_trigger');
  const amount = props.watch('amount');
  const risk_mode = props.watch('risk_mode');
  const calculation_basis = props.watch('calculation_basis');
  const gross_brokerage = props.watch('gross_brokerage');

  const terms = UseConstante();
  /*const dealOwnerName = props.optionUserNameOwner?.find(
    (el) => el.id === in_charge_user_id,
  );*/

  const partnerContactName = props.partenersPeople?.find(
    (el) => el.id === partner_contact_id,
  );

  const [deleteAttachment] = useDeleteDealAttachmentMutation();

  const typeOption = terms?.getConst(TermConst.dealType)?.filter((el: any) => {
    if (el && el.dependences) {
      const isMultiple = el?.dependences?.split(',');
      const found = isMultiple?.find((t: any) => t === props.mandateType);
      if (found) {
        return el;
      }
    }
  });

  const marketOption = terms
    .getConst(TermConst.dealMarket)
    ?.filter((el: any) => {
      return !el.dependences;
    });

  const coveragOption = terms
    .getConst(TermConst.dealType)
    ?.filter((el: any) => {
      if (el && el.dependences) {
        const isMultiple = el?.dependences?.split(',');
        const found = isMultiple?.find((t: any) => t === market);
        if (found) {
          return el;
        }
      }
    });

  const handleDeleteAtt = (item: EntityAttachementDeals) => {
    setSelectedAttachment(item);
    setModalDeleteAttach(true);
  };

  const onDeleteAttachment = () => {
    try {
      deleteAttachment({ id: selectedAttachment?.id ?? '' })
        .then(() => {
          props.setValue('attachements', 'delete', {
            shouldDirty: true,
          });
          props.setAttachementsDataSource(
            props.attachementsDataSource?.filter(
              (el) => el.id !== selectedAttachment?.id,
            ),
          );
        })
        .finally(() => {
          setModalDeleteAttach(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const columnsProvider = ColumnUpload({ handleDeleteAtt: handleDeleteAtt });

  const handleCloseDeleteBrokerage = () => {
    setModalConfirmDelete(!modalConfirmDelete);
  };

  const handleDeleteItemBrockerage = (item: any) => {
    if (item) {
      setSelectedBrokerDataSource(item);
      handleCloseDeleteBrokerage();
    }
  };

  const isObligor =
    deal_type === 'comprehensive' ||
    deal_type === 'urpa' ||
    props.mandateType !== 'insurance';
  const isShowIndividualLimits =
    market === 'hybrid' || market === 'whole_turnover';

  const showErrorCurrency = () => {
    props.setFormError('currency', {
      message: 'Currency is required',
    });
  };

  const confirmUpdateStatus = () => {
    props.setValue('status', 'ended', {
      shouldDirty: true,
    });
    setLastStatusV(undefined);
    setShowmodalConfirm(!modalConfirm);
  };

  const cancelUpdateStatus = () => {
    props.setValue('status', lastStatusV, {
      shouldDirty: true,
    });
    setShowmodalConfirm(!modalConfirm);
    setLastStatusV(undefined);
  };

  const isShowInsuranceBroker = !!(
    props.mandatesById?.mandate_type === 'insurance'
  );

  const addBroker = () => {
    const sizeOfDataSource = props?.brokerAttachementDataSource.length;
    const lastItemId =
      sizeOfDataSource > 0 ? props?.brokerAttachementDataSource.length : 0;
    const emptyRow: any = {
      brokerId: null,
      brokerContact: '',
      brokerRole: [],
      netBroker: '% 0.000000',
      brokerReference: '',
      id: 0,
      key: (lastItemId || 0) + 1,
      editable: true,
      isCurrentEdit: true,
    };
    props?.setBrokerAttachementDataSource([
      ...(props?.brokerAttachementDataSource || []),
      emptyRow,
    ]);
    columnsBrokerageProvider.edit(emptyRow);
  };

  const validRemoveBrokerageItem = () => {
    const filteredDataSource = props?.brokerAttachementDataSource?.filter(
      (el) => el.key?.toString() !== selectedBrokerDataSource?.key?.toString(),
    );

    if (filteredDataSource) {
      console.log('filteredDataSource', filteredDataSource);

      props?.setBrokerAttachementDataSource(filteredDataSource);
      props.setValue('brokerageTable', 'add', {
        shouldDirty: true,
      });
      handleCloseDeleteBrokerage();
    }
  };

  const handleSave = (row: any, key: string) => {
    const newData = [...(props.attachementsDataSource || [])];
    const index = newData.findIndex((item) => item.id === row.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    if (row.id) {
      const isUpdated = key === 'attachment_type' || key === 'comments';
      if (isUpdated) {
        const params = {
          id: row.id,
          dealId: id,
          attachment_type: row?.attachment_type,
          comments: row?.comments,
          [key]: row[key],
        };
        const tempAttachement = props.attachementsDataSource?.map((el) => {
          if (el.id === params?.id) {
            return {
              ...el,
              comments: params.comments,
              attachment_type: params.attachment_type,
            };
          }
          return el;
        });

        props.setAttachementsDataSource(tempAttachement);
        updatedDealAttachement(params);
      }
    }
  };

  const sumOfNetBrokerage = useMemo(() => {
    const allNetBrokerValue = props?.brokerAttachementDataSource?.map((el) =>
      floorNumber(el.netBroker),
    );
    const sum = allNetBrokerValue?.reduce((acc, curr) => acc + curr, 0);
    return sum;
  }, [props.brokerAttachementDataSource]);

  const verifyBrokerage = useCallback(() => {
    if (sumOfNetBrokerage !== floorNumber(gross_brokerage)) {
      props.setFormError('gross_brokerage', {
        message: `Sum of Net Brokerage not corresponding to Gross Brokerage`,
      });
    } else {
      props.clearFormErrors('gross_brokerage');
    }
  }, [sumOfNetBrokerage, gross_brokerage]);

  useEffect(() => {
    if (props.brokerAttachementDataSource && selectedBrokerDataSource) {
      verifyBrokerage();
    }
  }, [props.brokerAttachementDataSource, selectedBrokerDataSource]);

  const columnsBrokerageProvider = ColumnBrokerage({
    brokerAttachementDataSource: props.brokerAttachementDataSource,
    setBrokerAttachementDataSource: props.setBrokerAttachementDataSource,
    setValue: props.setValue,
    handleRemoveItem: handleDeleteItemBrockerage,
    handleChangeNetBroker: verifyBrokerage,
  });

  const EditableContext = React.createContext<FormInstance<any> | null>(null);

  const handleColumns = columnsProvider.columnAttachments?.map((col: any) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const EditableRow: React.FC<any> = ({ ...props }) => {
    const [form] = FormAnt.useForm();
    return (
      <FormAnt form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </FormAnt>
    );
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const isMinimumDepositRequired = useMemo(() => {
    return (
      props.watch('status') === 'bound' &&
      Boolean(calculation_basis) &&
      calculation_basis !== 'verticalised'
    );
  }, [props.watch('status'), calculation_basis]);

  const getTopObligor = () => {
    if (isObligor) return props.txtView?.obligor?.name;
    if (market === 'violence_and_terrorism') return 'N\\A';
    if (market === 'trade_credit' && deal_type === 'pri') return 'N\\A';
    return props.txtView?.obligor?.name;
  };

  return (
    <Container className="mt-4">
      <DeleteModal
        handleClose={handleCloseDeleteBrokerage}
        show={modalConfirmDelete}
        validDelete={() => validRemoveBrokerageItem()}
        title="Remove Broker"
        groupeName={selectedBrokerDataSource?.brokerId?.value}
        entityTitle="Brokers List"
      />

      <DeleteModal
        title="Delete attachments"
        show={modalDeleteAttach}
        groupeName={`${selectedAttachment?.filename ?? ''}`}
        entityTitle={'attachments'}
        validDelete={() => onDeleteAttachment()}
        handleClose={() => setModalDeleteAttach(!modalDeleteAttach)}
      />
      <ConfirmModal
        show={modalConfirm}
        handleClose={() => cancelUpdateStatus()}
        title="Confirmation"
        confirm={() => {
          confirmUpdateStatus();
        }}
      >
        <h5 className="content-text">
          Changing this deal status to "Ended" will automatically change
          Participations and Limits to "Off risk" after saving changes. Please
          confirm.
        </h5>
      </ConfirmModal>
      <Row className="mb-4">
        <Col>
          <RowContent>
            <Form.Group className="d-flex align-items-baseline w-100">
              <Form.Label className="txt-with-dropdown">
                Mandate ID <span className="span-required">*</span>
              </Form.Label>

              <div className="d-flex flex-column w-100">
                <SelectDropDown
                  noPadding
                  error={props.errors?.mandate_id?.message}
                  icon={
                    <span style={{ cursor: 'pointer' }}>
                      {mandate_id ? (
                        <MandateRoundedActif
                          onClick={() =>
                            handleShowOtherModal(mandate_id, 'mandate')
                          }
                        />
                      ) : (
                        <MandateRoundedInactif />
                      )}
                    </span>
                  }
                  modalId="deal-mandate_id"
                  id="mandate_id"
                  placeholder=""
                  preValue={exportedDropdownValue(
                    makeOptions(
                      props.mandatesList || [],
                      'complete_number',
                      'id',
                    ),
                    mandate_id,
                  )}
                  loading={props.isLoadingSearchMandate}
                  onSearch={(d: any, v: any) =>
                    props.runSearch('mandate_id', v)
                  }
                  options={makeOptions(
                    props.mandatesList || [],
                    'complete_number',
                    'id',
                  )}
                  onSelectOption={(e) => {
                    props.setValue(
                      'mandate_id',
                      (e as DropDownOptionObjectType).key,
                      {
                        shouldDirty: true,
                      },
                    );
                    if (e) {
                      props.clearFormErrors('mandate_id');
                      return;
                    }
                    props.setFormError('mandate_id', {
                      message: 'Mandate ID is required',
                    });
                  }}
                  onBlur={() => {
                    if (!isExist(mandate_id)) {
                      props.setFormError('mandate_id', {
                        message: 'Mandate ID is required',
                      });
                    }
                  }}
                />
              </div>
            </Form.Group>
          </RowContent>
          <RowContent>
            <Form.Group className={`d-flex align-items-baseline w-100 `}>
              <Form.Label className="txt-with-dropdown">
                Client ID <span className="span-required">*</span>
              </Form.Label>

              <div className="d-flex flex-column w-100">
                <SelectDropDown
                  noPadding
                  disabled={!mandate_id}
                  noSearch
                  icon={
                    <span style={{ cursor: 'pointer' }}>
                      {client_id ? (
                        <ClientActive
                          onClick={() =>
                            handleShowOtherModal(
                              client_id,
                              'client',
                              'client_id',
                            )
                          }
                        />
                      ) : (
                        <ClientInactif />
                      )}
                    </span>
                  }
                  error={props.errors?.client_id?.message}
                  modalId="deal-client_id"
                  id="client_id"
                  placeholder=""
                  preValue={exportedDropdownValue(
                    makeOptions(props.clientList || [], 'name', 'id'),
                    client_id,
                  )}
                  loading={props.isLoadingClient}
                  onSearch={(d: any, v: any) => props.runSearch('client_id', v)}
                  options={makeOptions(props.clientList || [], 'name', 'id')}
                  onSelectOption={(e) => {
                    props.setValue(
                      'client_id',
                      (e as DropDownOptionObjectType).key?.toString(),
                      {
                        shouldDirty: true,
                      },
                    );
                    if (e) {
                      props.clearFormErrors('client_id');
                      return;
                    }
                    props.setFormError('client_id', {
                      message: 'Client ID is required',
                    });
                  }}
                  onBlur={() => {
                    if (!isExist(client_id)) {
                      props.setFormError('client_id', {
                        message: 'Client ID is required',
                      });
                    }
                  }}
                />
              </div>
            </Form.Group>
          </RowContent>
          <RowContent>
            <Form.Group className={`d-flex align-items-baseline w-100 `}>
              <Form.Label className="txt-with-dropdown">
                Client Entity <span className="span-required">*</span>
              </Form.Label>

              <div className="d-flex flex-column w-100">
                <SelectDropDown
                  noPadding
                  noSearch
                  disabled={!mandate_id}
                  icon={
                    <span style={{ cursor: 'pointer' }}>
                      {client_legal_entity_id ? (
                        <LegalentitiesActif
                          onClick={() =>
                            handleShowOtherModal(
                              client_legal_entity_id,
                              'legal_entity',
                            )
                          }
                        />
                      ) : (
                        <LegalentitiesInactif />
                      )}
                    </span>
                  }
                  error={props.errors?.client_legal_entity_id?.message}
                  modalId="deals-client_legal_entity_id"
                  id="client_legal_entity_id"
                  placeholder=""
                  preValue={exportedDropdownValue(
                    makeOptions(props.legalentityList || [], 'name', 'id'),
                    client_legal_entity_id,
                  )}
                  loading={props.isLoadingLegalEntity}
                  onSearch={(d: any, v: any) =>
                    props.runSearch('client_legal_entity_id', v)
                  }
                  options={makeOptions(
                    props.legalentityList || [],
                    'name',
                    'id',
                  )}
                  onSelectOption={(e) => {
                    props.setValue(
                      'client_legal_entity_id',
                      (e as DropDownOptionObjectType).key?.toString(),
                      {
                        shouldDirty: true,
                      },
                    );
                    if (e) {
                      props.clearFormErrors('client_legal_entity_id');
                      return;
                    }
                    props.setFormError('client_legal_entity_id', {
                      message: 'Client Entity is required',
                    });
                  }}
                  onBlur={() => {
                    if (!isExist(client_legal_entity_id)) {
                      props.setFormError('client_legal_entity_id', {
                        message: 'Client Entity is required',
                      });
                    }
                  }}
                />
              </div>
            </Form.Group>
          </RowContent>
          <RowContent>
            <Form.Group className={`d-flex align-items-baseline w-100 pt-2`}>
              <Form.Label className="txt-with-dropdown">
                Mandate Name
              </Form.Label>

              <div className="d-flex flex-column w-100">
                {props.mandatesById?.name}
              </div>
            </Form.Group>
          </RowContent>

          <div
            className={`d-bloc ${
              props.mandateType !== 'insurance' ? 'd-none' : ''
            } `}
          >
            <RowContent height="auto" marginBottom="1.5rem">
              <Form.Group className="d-flex align-items-startx   w-100">
                <Form.Label className="label-text-area">Comments</Form.Label>

                <div className="d-flex flex-column w-100">
                  <Form.Control
                    as="textarea"
                    rows={4}
                    className=" w-100"
                    {...props.register('comments')}
                  />
                </div>
              </Form.Group>
            </RowContent>
          </div>

          <div
            className={`${props.mandateType !== 'insurance' ? 'd-none' : ''}`}
          >
            <RowContent>
              <Form.Group className="d-flex align-items-baseline">
                <Form.Label className="txt-with-dropdown">Market </Form.Label>

                <div className="d-flex flex-column w-100">
                  <SelectDropDown
                    noPadding
                    noSearch
                    modalId="deal-market"
                    preValue={exportedDropdownValue(marketOption, market)}
                    options={marketOption}
                    onSelectOption={(e) =>
                      props.setValue(
                        'market',
                        (e as DropDownOptionObjectType).key,
                        {
                          shouldDirty: true,
                        },
                      )
                    }
                  />
                </div>
              </Form.Group>
            </RowContent>
          </div>

          <div
            className={`${props.mandateType !== 'insurance' ? 'd-none' : ''}`}
          >
            <RowContent>
              <Form.Group className="d-flex align-items-baseline">
                <Form.Label className="txt-with-dropdown">
                  Coverage Type <span className="span-required">*</span>
                </Form.Label>

                <div className="d-flex flex-column w-100">
                  <SelectDropDown
                    noPadding
                    disabled={!market}
                    error={props.errors?.deal_type?.message}
                    {...props.register('deal_type', {
                      required:
                        props.mandateType === 'insurance' ? true : false,
                    })}
                    modalId="deal-deal_type"
                    noSearch
                    preValue={exportedDropdownValue(coveragOption, deal_type)}
                    options={coveragOption}
                    onSelectOption={(e) => {
                      props.setValue(
                        'deal_type',
                        (e as DropDownOptionObjectType).key,
                        {
                          shouldDirty: true,
                        },
                      );
                      if (e) {
                        props.clearFormErrors('deal_type');
                        return;
                      }
                      props.setFormError('deal_type', {
                        message: 'Coverage Type  is required',
                      });
                    }}
                    onBlur={() => {
                      if (!isExist(deal_type)) {
                        props.setFormError('deal_type', {
                          message: 'Coverage Type is required',
                        });
                      }
                    }}
                  />
                </div>
              </Form.Group>
            </RowContent>
          </div>

          <div
            className={`${props.mandateType !== 'insurance' ? 'd-none' : ''}`}
          >
            <RowContent>
              <Form.Group className="d-flex align-items-baseline">
                <Form.Label className="txt-with-dropdown">
                  Risk Trigger
                </Form.Label>

                <div className="d-flex flex-column w-100">
                  <SelectDropDown
                    noPadding
                    noSearch
                    modalId="deal-risk_trigger"
                    preValue={exportedDropdownValue(
                      terms?.getConst(TermConst.dealRiskTrigger),
                      risk_trigger,
                    )}
                    options={terms?.getConst(TermConst.dealRiskTrigger)}
                    onSelectOption={(e) =>
                      props.setValue(
                        'risk_trigger',
                        (e as DropDownOptionObjectType).key,
                        {
                          shouldDirty: true,
                        },
                      )
                    }
                  />
                </div>
              </Form.Group>
            </RowContent>
          </div>

          <div
            className={`${props.mandateType === 'insurance' ? 'd-none' : ''}`}
          >
            <RowContent>
              <Form.Group className="d-flex align-items-baseline">
                <Form.Label className="txt-with-dropdown">
                  Type <span className="span-required">*</span>
                </Form.Label>

                <div className="d-flex flex-column w-100">
                  <SelectDropDown
                    noPadding
                    disabled={!mandate_id}
                    modalId="deal-deal_type"
                    noSearch
                    error={props.errors?.deal_type?.message}
                    preValue={exportedDropdownValue(typeOption, deal_type)}
                    options={typeOption}
                    onSelectOption={(e) => {
                      props.setValue(
                        'deal_type',
                        (e as DropDownOptionObjectType).key,
                        {
                          shouldDirty: true,
                        },
                      );
                      if (e) {
                        props.clearFormErrors('deal_type');
                        return;
                      }
                      props.setFormError('deal_type', {
                        message: 'Type is required',
                      });
                    }}
                    onBlur={() => {
                      if (!isExist(deal_type)) {
                        props.setFormError('deal_type', {
                          message: 'Type is required',
                        });
                      }
                    }}
                  />
                </div>
              </Form.Group>
            </RowContent>
          </div>

          <RowContent>
            <Form.Group className="d-flex align-items-baseline">
              <Form.Label className="txt-with-dropdown">Structure</Form.Label>

              <div className="d-flex flex-column w-100">
                <SelectDropDown
                  noPadding
                  noSearch
                  modalId="dela-structure"
                  preValue={exportedDropdownValue(
                    terms?.getConst(TermConst.dealStructure),
                    structure,
                  )}
                  options={terms?.getConst(TermConst.dealStructure)}
                  onSelectOption={(e) =>
                    props.setValue(
                      'structure',
                      (e as DropDownOptionObjectType).key,
                      {
                        shouldDirty: true,
                      },
                    )
                  }
                />
              </div>
            </Form.Group>
          </RowContent>
          <RowContent>
            <Form.Group className="d-flex align-items-baseline w-100 mt-3">
              <Form.Label className="txt">
                {`${isObligor ? 'Obligor' : 'Top Obligor'}`}
              </Form.Label>

              <div className="d-flex flex-column w-100">{getTopObligor()}</div>
              <span className="pe-1">
                {isExist(props.txtView.obligor?.name) &&
                getTopObligor() !== 'N\\A' ? (
                  <span style={{ cursor: 'pointer' }}>
                    <ObligorActif
                      onClick={() => handleShowOtherModal(null, 'obligor')}
                    />
                  </span>
                ) : (
                  <ObligorInActif />
                )}
              </span>
            </Form.Group>
          </RowContent>

          <RowContent>
            <Form.Group className="d-flex align-items-baseline w-100 pt-2">
              <Form.Label className="txt">Country</Form.Label>

              <div className="d-flex flex-column w-100 align-items-baseline">
                {props.txtView?.obligor?.country_id !== '-' &&
                isExist(props.txtView?.obligor?.country_id) ? (
                  <div className="d-flex flex-row ">
                    <Flag
                      code={props.txtView?.top_obligor_country_id}
                      height="20"
                      width={'20'}
                      style={{
                        borderRadius: '50%',
                        objectFit: 'cover',
                        marginRight: 15,
                      }}
                    />
                    {exportedCountryName(
                      responseCountry?.country || [],
                      props.txtView?.top_obligor_country_id,
                    )}
                  </div>
                ) : (
                  '-'
                )}
              </div>
            </Form.Group>
          </RowContent>

          <RowContent>
            <Form.Group className="d-flex align-items-baseline w-100 pt-2">
              <Form.Label className="txt">Lead Line</Form.Label>

              <div className="d-flex flex-column w-100">
                {props.txtView?.leadLine?.name}
              </div>
              <span className="pe-1">
                {isExist(props.txtView?.leadLine?.name) &&
                  (props.mandateType === 'insurance' ? (
                    <span style={{ cursor: 'pointer' }}>
                      <UnderwriterActif
                        onClick={() =>
                          handleShowOtherModal(
                            props.txtView?.leadLine?.id,
                            'client',
                            'leadLine',
                          )
                        }
                      />
                    </span>
                  ) : (
                    <FinancierActif
                      onClick={() =>
                        handleShowOtherModal(
                          props.txtView?.leadLine?.id,
                          'client',
                          'leadLine',
                        )
                      }
                    />
                  ))}

                {!isExist(props.txtView?.leadLine?.name) &&
                  (props.mandateType === 'insurance' ? (
                    <span style={{ cursor: 'pointer' }}>
                      <UnderwriterInactif
                        onClick={() =>
                          handleShowOtherModal(
                            props.txtView?.leadLine?.id,
                            'client',
                            'leadLine',
                          )
                        }
                      />
                    </span>
                  ) : (
                    <FinancierInactif />
                  ))}
              </span>
            </Form.Group>
          </RowContent>

          <div
            className={`${props.mandateType === 'insurance' ? 'd-none' : ''}`}
          >
            <RowContent>
              <Form.Group className="d-flex align-items-baseline w-100">
                <Form.Label className="txt-with-dropdown">Partner </Form.Label>
                <div className="d-flex flex-column w-100">
                  <SelectDropDown
                    noPadding
                    modalId="enquiries-partner_id"
                    id="partner_id_mandate"
                    placeholder=""
                    icon={
                      <span style={{ cursor: 'pointer' }}>
                        {partner_id ? (
                          <PartnerActif
                            onClick={() =>
                              handleShowOtherModal(partner_id, 'partner')
                            }
                          />
                        ) : (
                          <PartnerIncatif />
                        )}
                      </span>
                    }
                    preValue={exportedDropdownValue(
                      makeOptions(props.partenerList || [], 'name', 'id'),
                      partner_id,
                    )}
                    onSelectOption={(e) =>
                      props.setValue(
                        'partner_id',
                        (e as DropDownOptionObjectType).key,
                        { shouldDirty: true },
                      )
                    }
                    options={makeOptions(
                      props.partenerList || [],
                      'name',
                      'id',
                    )}
                    loading={props.isLoadingClient}
                    onSearch={(d, v) => props.runSearch('partner_id', v)}
                  />
                </div>
              </Form.Group>
            </RowContent>
          </div>

          <div
            className={`${props.mandateType === 'insurance' ? 'd-none' : ''}`}
          >
            <RowContent>
              <Form.Group className="d-flex align-items-baseline w-100">
                <Form.Label className="txt-with-dropdown">
                  Partner Entity
                </Form.Label>

                <div className={`d-flex my-1 flex-column w-100`}>
                  <SelectDropDown
                    noPadding
                    noSearch
                    disabled={!partner_id}
                    icon={
                      <span style={{ cursor: 'pointer' }}>
                        {partner_legal_entity_id ? (
                          <LegalentitiesActif
                            onClick={() =>
                              handleShowOtherModal(
                                partner_legal_entity_id,
                                'partner_legal_entity',
                              )
                            }
                          />
                        ) : (
                          <LegalentitiesInactif />
                        )}
                      </span>
                    }
                    modalId="mandate-client"
                    id="enquiries_partner_legal_entity_id"
                    placeholder=""
                    options={makeOptions(props.partnerEntityList, 'name', 'id')}
                    preValue={exportedDropdownValue(
                      makeOptions(props.partnerEntityList || [], 'name', 'id'),
                      partner_legal_entity_id,
                    )}
                    onSearch={(d: any, v: any) =>
                      props.runSearch('partner_legal_entity_id', v)
                    }
                    onSelectOption={(e) =>
                      props.setValue(
                        'partner_legal_entity_id',
                        (e as DropDownOptionObjectType).key?.toString(),
                        {
                          shouldDirty: true,
                        },
                      )
                    }
                  />
                </div>
              </Form.Group>
            </RowContent>
          </div>

          <div
            className={`${props.mandateType === 'insurance' ? 'd-none' : ''}`}
          >
            <RowContent>
              <Form.Group className="d-flex align-items-baseline w-100">
                <Form.Label className="txt-with-dropdown">
                  Partner Contact
                </Form.Label>

                <div className={`d-flex flex-column w-100 `}>
                  <SelectDropDown
                    noSearch
                    disabled={!partner_id}
                    icon={
                      <span style={{ cursor: 'pointer' }}>
                        {partner_contact_id && Boolean(partnerContactName) ? (
                          <PeopleActif
                            onClick={() =>
                              handleShowOtherModal(partner_contact_id, 'people')
                            }
                          />
                        ) : (
                          <PeopleIncatif />
                        )}
                      </span>
                    }
                    modalId="mandate-client"
                    id="enquiries_partner_contact_id"
                    placeholder=""
                    preValue={exportedDropdownValue(
                      makeOptions(props.partenersPeople, 'userName', 'id'),
                      partner_contact_id || 0,
                    )}
                    options={makeOptions(
                      props.partenersPeople,
                      'userName',
                      'id',
                    )}
                    onSearch={(d, v) =>
                      props.runSearch('partner_contact_id', v)
                    }
                    onSelectOption={(e) =>
                      props.setValue(
                        'partner_contact_id',
                        (e as DropDownOptionObjectType).key,
                        { shouldDirty: true },
                      )
                    }
                  />
                </div>
              </Form.Group>
            </RowContent>
          </div>
          <div
            className={`${props.mandateType === 'insurance' ? 'd-none' : ''}`}
          >
            <RowContent>
              <CofarcoFormText
                errors={props.errors}
                key_form="deal_partner_reference"
                class="w-100 pt-2 align-items-center"
                label="Partner Reference Number "
                register={props.register('partner_reference')}
              />
            </RowContent>
          </div>
        </Col>
        <Col>
          <RowContent>
            <Form.Group className="d-flex align-items-baseline w-100">
              <Form.Label className="txt-with-dropdown">Status </Form.Label>

              <div className="d-flex flex-column w-100">
                <SelectDropDown
                  noPadding
                  modalId="deals_status"
                  id="deals_status"
                  preValue={exportedDropdownValue(
                    makeOptions(terms?.getConst(TermConst.dealStatus) || []),
                    status,
                  )}
                  options={makeOptions(
                    terms?.getConst(TermConst.dealStatus) || [],
                  )}
                  noSearch
                  onSelectOption={(e) => {
                    const keyValue = (e as DropDownOptionObjectType)?.key;
                    if (keyValue === 'ended' && props.isHaveRelatedEntity) {
                      props.setValue('status', '');
                      setShowmodalConfirm(true);
                      setLastStatusV(status);
                      return;
                    } else {
                      props.setValue('status', keyValue, {
                        shouldDirty: true,
                      });
                    }
                  }}
                />
              </div>
            </Form.Group>
          </RowContent>
          <div
            className={`${props.mandateType === 'insurance' ? 'd-none' : ''}`}
          >
            <RowContent>
              <Form.Group className="d-flex align-items-baseline w-100">
                <Form.Label className="txt-with-dropdown">Booking </Form.Label>

                <div className="d-flex flex-column w-100">
                  <SelectDropDown
                    noPadding
                    modalId="deals_booking"
                    id="deals_booking"
                    preValue={exportedDropdownValue(
                      makeOptions(terms?.getConst(TermConst.dealBooking) || []),
                      booking,
                    )}
                    options={makeOptions(
                      terms?.getConst(TermConst.dealBooking) || [],
                    )}
                    noSearch
                    onSelectOption={(e) =>
                      props.setValue(
                        'booking',
                        (e as DropDownOptionObjectType).key,
                        {
                          shouldDirty: true,
                        },
                      )
                    }
                  />
                </div>
              </Form.Group>
            </RowContent>
          </div>
          <RowContent>
            <Form.Group className={`d-flex align-items-baseline w-100 `}>
              <Form.Label className="txt txt-center-fix">
                Deal Amount{' '}
              </Form.Label>

              <div className="d-flex flex-row  justify-content-center  w-100">
                <div className="w-100">
                  <CofarcoNumberAmount
                    errors={props.errors}
                    key_form="amount"
                    type="number"
                    class="w-100"
                    register={props.register}
                    setValue={props.setValue}
                    watch={props.watch}
                  />
                </div>
              </div>
            </Form.Group>
          </RowContent>
          <RowContent>
            <Form.Group className={`d-flex align-items-baseline`}>
              <Form.Label className="txt-with-dropdown">
                Deal Currency
              </Form.Label>

              <div className={`d-flex flex-column w-100`}>
                <SelectDropDown
                  noPadding
                  modalId="deal-currency"
                  disabled={(amount as number) < 1}
                  preValue={exportedDropdownValue(
                    terms?.getConst(TermConst.currency),
                    currency,
                  )}
                  noSearch
                  options={terms?.getConst(TermConst.currency)}
                  onSelectOption={(e) => {
                    props.setValue(
                      'currency',
                      (e as DropDownOptionObjectType).key,
                      { shouldDirty: true },
                    );
                    const isCleared =
                      isExist(amount) &&
                      !isExist((e as DropDownOptionObjectType).key);
                    if (isCleared) {
                      showErrorCurrency();
                      return;
                    }
                    props.clearFormErrors('currency');
                  }}
                  onBlur={() => {
                    const isEmptyCurrency =
                      isExist(amount) && !isExist(currency);
                    if (isEmptyCurrency) {
                      showErrorCurrency();
                    }
                  }}
                  error={props.errors?.currency?.message}
                />
              </div>
            </Form.Group>
          </RowContent>
          <RowContent>
            <Form.Group className="d-flex" style={{ alignItems: 'baseline' }}>
              <Form.Label className="txt">
                Inception Date
                {props.watch('status') === 'bound' && (
                  <span className="span-required">&nbsp;*</span>
                )}
              </Form.Label>
              <CofarcoCalendar
                control={props.control}
                name="inception_date"
                setFormValue={props.setValue}
                watchFormValue={props.watch}
                clearFormErrors={props.clearFormErrors}
                setFormError={props.setFormError}
                errors={props.errors}
                required={props.watch('status') === 'bound'}
                disabled={
                  props.watch('status') === 'bound' &&
                  props.watch('inception_date')
                    ? true
                    : false
                }
              />
            </Form.Group>
          </RowContent>
          <RowContent>
            <Form.Group className="d-flex" style={{ alignItems: 'baseline' }}>
              <Form.Label className="txt">
                End Date
                {props.watch('status') === 'ended' && (
                  <span className="span-required">*</span>
                )}
              </Form.Label>
              <CofarcoCalendar
                control={props.control}
                name="expiry_date"
                setFormValue={props.setValue}
                watchFormValue={props.watch}
                clearFormErrors={props.clearFormErrors}
                setFormError={props.setFormError}
                errors={props.errors}
                required={props.watch('status') === 'ended'}
                txtErrorMsg="End Date is required"
                isDirty={props.dirtyFields.expiry_date}
              />
            </Form.Group>
          </RowContent>
          <div
            className={`${props.mandateType !== 'insurance' ? 'd-none' : ''} `}
          >
            <RowContent>
              <Form.Group className="d-flex  w-100 ">
                <Form.Label className="txt" style={{ whiteSpace: 'pre-wrap' }}>
                  {'Non Renewable \nNotice'}
                </Form.Label>

                <CofarcoCalendar
                  control={props.control}
                  name="non_renewal_notice_date"
                  setFormValue={props.setValue}
                  watchFormValue={props.watch}
                  clearFormErrors={props.clearFormErrors}
                  setFormError={props.setFormError}
                  errors={props.errors}
                />
              </Form.Group>
            </RowContent>
          </div>
          <div
            className={`${props.mandateType !== 'insurance' ? 'd-none' : ''} `}
          >
            <RowContent>
              <Form.Group className="d-flex align-items-baseline w-100">
                <Form.Label className="txt txt-center-fix">
                  Insured Percentage
                </Form.Label>
                <div className="d-flex flex-row  justify-content-center  w-100">
                  <div className="w-100">
                    <CofarcoNumberPercentage
                      errors={props.errors}
                      key_form="insured"
                      type="number"
                      class="w-100"
                      register={props.register}
                      setValue={props.setValue}
                      watch={props.watch}
                    />
                  </div>
                </div>
              </Form.Group>
            </RowContent>
          </div>
          {isShowIndividualLimits && (
            <div
              className={`${
                props.mandateType === 'insurance' ? '' : 'd-none'
              } `}
            >
              <RowContent>
                <Form.Group className="d-flex align-items-start w-100">
                  <Form.Label className="txt">
                    {'Aggregate Individual \nLimits'}
                  </Form.Label>
                  <div className="d-flex flex-row  justify-content-center  w-100">
                    <div className="w-100">
                      <CofarcoNumberAmount
                        key_form="individual_limits"
                        type="number"
                        class="w-100"
                        register={props.register}
                        setValue={props.setValue}
                        watch={props.watch}
                      />
                    </div>
                  </div>
                </Form.Group>
              </RowContent>
            </div>
          )}

          {market === 'trade_credit' && (
            <div
              className={`${
                props.mandateType !== 'insurance' ? 'd-none' : ''
              } `}
            >
              <RowContent>
                <Form.Group className="d-flex align-items-start w-100">
                  <Form.Label className="my-auto-text-area txt-two-line">
                    {"Insured's Gross \nExposure"}
                  </Form.Label>
                  <div className="d-flex flex-row  justify-content-start  w-100">
                    <div className="w-100">
                      <CofarcoNumberAmount
                        errors={props.errors}
                        key_form="insured_gross"
                        type="number"
                        class="w-100"
                        register={props.register}
                        setValue={props.setValue}
                        watch={props.watch}
                      />
                    </div>
                  </div>
                </Form.Group>
              </RowContent>
            </div>
          )}

          <div
            className={`${props.mandateType !== 'insurance' ? 'd-none' : ''} `}
          >
            <RowContent>
              <Form.Group className="d-flex align-items-baseline w-100">
                <Form.Label className="txt txt-center-fix">
                  Deductible
                </Form.Label>
                <div className="d-flex flex-row  justify-content-center  w-100">
                  <div className="w-100">
                    <CofarcoNumberAmount
                      errors={props.errors}
                      key_form="deductible"
                      type="number"
                      class="w-100"
                      register={props.register}
                      setValue={props.setValue}
                      watch={props.watch}
                    />
                  </div>
                </div>
              </Form.Group>
            </RowContent>
          </div>
          <div
            className={`${props.mandateType !== 'insurance' ? 'd-none' : ''} `}
          >
            <RowContent>
              <Form.Group className={`d-flex align-items-baseline`}>
                <Form.Label className="txt-with-dropdown">
                  Risk Mode
                  {props.watch('status') === 'bound' && (
                    <span className="span-required">&nbsp;*</span>
                  )}
                </Form.Label>

                <div className="d-flex w-100 flex-column">
                  <SelectDropDown
                    error={props.errors?.risk_mode?.message}
                    noPadding
                    modalId="deal-risk_mode"
                    preValue={exportedDropdownValue(
                      terms?.getConst(TermConst.dealRisk),
                      risk_mode,
                    )}
                    clearError={() => props.clearFormErrors('risk_mode')}
                    isRequired={props.watch('status') === 'bound'}
                    noSearch
                    options={terms?.getConst(TermConst.dealRisk)}
                    onSelectOption={(e) => {
                      if (e) {
                        props.setValue(
                          'risk_mode',
                          (e as DropDownOptionObjectType).key,
                          { shouldDirty: true },
                        );
                        props.clearFormErrors('risk_mode');
                      } else {
                        if (props.watch('status') === 'bound') {
                          props.setFormError('risk_mode', {
                            message: 'Risk Mode is required',
                          });
                        }
                      }
                    }}
                  />
                </div>
              </Form.Group>
            </RowContent>
          </div>
          <div
            className={`${props.mandateType !== 'insurance' ? 'd-none' : ''} `}
          >
            <RowContent>
              <Form.Group className={`d-flex align-items-center`}>
                <Form.Label className="txt-with-dropdown">
                  {'Premium Calculation \nBasis'}
                  {props.watch('status') === 'bound' && (
                    <span className="span-required">&nbsp;*</span>
                  )}
                </Form.Label>

                <div className="d-flex w-100 flex-column">
                  <SelectDropDown
                    error={props.errors?.calculation_basis?.message}
                    isRequired={props.watch('status') === 'bound'}
                    clearError={() =>
                      props.clearFormErrors('calculation_basis')
                    }
                    noPadding
                    modalId="deal-calculation_basis"
                    preValue={exportedDropdownValue(
                      terms?.getConst(TermConst.dealCalculation),
                      calculation_basis,
                    )}
                    noSearch
                    options={terms?.getConst(TermConst.dealCalculation)}
                    onSelectOption={(e) => {
                      if (e) {
                        props.setValue(
                          'calculation_basis',
                          (e as DropDownOptionObjectType).key,
                          { shouldDirty: true },
                        );
                        props.clearFormErrors('calculation_basis');
                      } else {
                        if (props.watch('status') === 'bound') {
                          props.setFormError('calculation_basis', {
                            message: 'Premium Calculation Basis is required',
                          });
                        }
                      }
                    }}
                  />
                </div>
              </Form.Group>
            </RowContent>
          </div>
          <div
            className={`${props.mandateType !== 'insurance' ? 'd-none' : ''} ${
              calculation_basis === 'verticalised' ? 'disabled-content' : ''
            }`}
          >
            <RowContent>
              <CofarcoFormText
                errors={props.errors}
                disabled={calculation_basis === 'verticalised'}
                key_form="deal-premium_rate"
                class="w-100 align-items-baseline"
                label="Risk Premium"
                register={props.register('premium_rate')}
              />
            </RowContent>
          </div>
          <div
            className={`${props.mandateType !== 'insurance' ? 'd-none' : ''}
             ${calculation_basis === 'verticalised' ? 'disabled-content' : ''}`}
          >
            <RowContent>
              <CofarcoFormText
                errors={props.errors}
                clearError={() => props.clearFormErrors('minimum_deposit')}
                disabled={calculation_basis === 'verticalised'}
                key_form="minimum_deposit"
                class="w-100 align-items-center"
                label="Minimum Deposit Premium"
                register={props.register('minimum_deposit', {
                  required: isMinimumDepositRequired,
                })}
                isRequired={isMinimumDepositRequired}
                txtError="Minimum Deposit Premium is required"
              />
            </RowContent>
          </div>

          <RowContent>
            <Form.Group className={`d-flex align-items-baseline`}>
              <Form.Label className="txt-with-dropdown">Deal Owner</Form.Label>

              <div className="d-flex w-100 flex-column">
                <SelectDropDown
                  noPadding
                  /*icon={
                    <span style={{ cursor: 'pointer' }}>
                      {in_charge_user_id && Boolean(dealOwnerName) ? (
                        <PeopleActif
                          onClick={() =>
                            handleShowOtherModal(in_charge_user_id, 'cofarco_team')
                          }
                        />
                      ) : (
                        <PeopleIncatif />
                      )}
                    </span>
                  }*/
                  icon={
                    <FaUser
                      style={{
                        fontSize: 22,
                        cursor: 'pointer',
                        color: props.watch('in_charge_user_id')
                          ? '#6d1206'
                          : '',
                      }}
                      onClick={() => {
                        const v = props.getValue('in_charge_user_id');
                        if (v) {
                          props.showPeopleModal(v);
                        }
                      }}
                    />
                  }
                  modalId="deal-in_charge_user_id"
                  preValue={exportedDropdownValue(
                    makeOptions(
                      props?.optionUserNameOwner || [],
                      'userName',
                      'id',
                    ),
                    in_charge_user_id,
                  )}
                  noSearch
                  options={makeOptions(
                    props?.optionUserNameOwner || [],
                    'userName',
                    'id',
                  )}
                  onSelectOption={(e) =>
                    props.setValue(
                      'in_charge_user_id',
                      (e as DropDownOptionObjectType).key,
                      { shouldDirty: true },
                    )
                  }
                />
              </div>
            </Form.Group>
          </RowContent>
        </Col>
      </Row>
      <div style={{ height: '1px', backgroundColor: '#DBDDDF' }} />
      <Row className={`py-4 ${isShowInsuranceBroker ? '' : 'd-none'}`}>
        <>
          <div className="content-table mt-2">
            <div className="font-bold mb-2">Brokerage</div>

            <div className={`my-3 d-flex flex-row align-items-baseline`}>
              <Form.Group className="d-flex align-items-baseline">
                <Form.Label className="txt">Gross Bokerage</Form.Label>
                <div className="d-flex flex-row  justify-content-left  w-100">
                  <div className="w-40">
                    <CofarcoNumberPercentage
                      errors={props.errors}
                      key_form="gross_brokerage"
                      type="number"
                      class="w-40"
                      unitFormat={6}
                      register={props.register}
                      setValue={(name: any, value: any, options: any) => {
                        props.setValue(name, value, options);
                        verifyBrokerage();
                      }}
                      watch={props.watch}
                      maxValue={100}
                    />
                  </div>
                </div>
              </Form.Group>
              {props.errors['gross_brokerage'] && (
                <div>
                  <span
                    style={{
                      backgroundColor: '#F6DDD9',
                      border: 'red 1px solid',
                      color: '#C0575D',
                    }}
                    className="p-2 w-100"
                  >
                    <b>{props.errors['gross_brokerage']?.message}</b>
                  </span>
                </div>
              )}
            </div>
            <div className="content-table">
              <Table
                // style={{
                //   maxWidth: '100%',
                //   //overflowX: 'scroll',
                //   // overflowY: 'hidden',
                // }}
                dataSource={props.brokerAttachementDataSource}
                columns={columnsBrokerageProvider.columnBroker}
                loading={false}
                pagination={false}
                rowClassName={(_, index) =>
                  `${
                    index % 2 === 1 ? classes.tableWhite : classes.tableWhite
                  } ${classes.tablerow}`
                }
              />
            </div>
            {!props?.hiddenFooter && (
              <div className="d-flex">
                <Button
                  className="btn red mt-3 py-2"
                  disabled={!!isExist(columnsBrokerageProvider.editingKey?.key)}
                  onClick={() => addBroker()}
                >
                  <img src={Add} alt="" className="pr-2" />
                  <span className="ms-1">New Broker Attachment</span>
                </Button>
                <input type="file" className="invisible" />
              </div>
            )}
          </div>
        </>
      </Row>
      <Row>
        <>
          <div className="content-table mt-2">
            <div className="font-bold mb-2">Attachment(s)</div>
            <div className="content-table">
              <div
                style={{
                  maxWidth: '100%',
                  overflowX: 'scroll',
                  overflowY: 'clip',
                }}
              >
                <Table
                  dataSource={props.attachementsDataSource || []}
                  columns={handleColumns}
                  rowClassName={(_, index) =>
                    `editable-row ${
                      index % 2 === 1 ? classes.tablelight : ''
                    } ${classes.tablerow}`
                  }
                  loading={props.isLoadingAttachement}
                  pagination={false}
                  components={components}
                  rowKey="id"
                  tableLayout="auto"
                />
              </div>
            </div>
            {!props?.hiddenFooter && (
              <div className="d-flex">
                <Button
                  className="btn red mt-3 py-2"
                  onClick={() => {
                    document.getElementById('attachmentDeals')?.click();
                  }}
                >
                  <RiFolderUploadFill className="me-2" />
                  <span className="ms-1">Upload</span>
                </Button>
                <input
                  className="d-none"
                  type="file"
                  id="attachmentDeals"
                  onChange={(e) => {
                    props.onAddAttachment(e.target.files);
                    e.target.files = null;
                    e.target.value = '';
                  }}
                />
              </div>
            )}
          </div>
        </>
      </Row>

      <ModalUnsavedChange
        dirtiFields={provider.dirtyFields}
        handleCancel={() => provider.setIsShowModalUnsavedChange(false)}
        handleLeave={() =>
          provider.handleLeaveModalWithoutSave(
            showOtherModal.id,
            showOtherModal.func,
            showOtherModal.type,
          )
        }
        showModalUnsaved={provider.isShowModalUnsavedChange}
      />
    </Container>
  );
}
