export const mockTitle = [
  {
    value: 'Analyst',
    key: 'analyst',
  },
  {
    value: 'Company Secretary',
    key: 'secretary',
  },
  {
    value: 'Director',
    key: 'director',
  },
  {
    value: 'Exec Partner',
    key: 'exec_partner',
  },
  {
    value: 'Intern',
    key: 'intern',
  },
  {
    value: 'IT',
    key: 'it',
  },
  {
    value: 'VP',
    key: 'vp',
  },
];
