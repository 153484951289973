import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { baseApi } from '../../baseApi';
import { ApiFilter } from '../../../../@types';
import { url } from '../../url';
import { Method } from '../../../../@types/common-types';
import { BusinessType, ConterParty } from '../../../../@types/conterParty';

const counterPartyPost = baseApi.injectEndpoints({
  endpoints: (build: EndpointBuilder<any, any, any>) => {
    return {
      createCounterParty: build.mutation<
        { success: boolean; counterparty: ConterParty },
        { data: any; businessType: BusinessType }
      >({
        query: ({ data, businessType }) => {
          return {
            url: `${url.counterparty.list}`,
            params: { _dc: new Date().getTime(), businessType },
            method: Method.POST,
            data,
          };
        },
        // invalidatesTags: (result, error, arg) => ['CounterParty'],
      }),
      setCounterPartyLegalentity: build.mutation<
        { counterparty: ConterParty; success: boolean },
        { data: any; id: string | number }
      >({
        query: ({ id, data }) => {
          return {
            url: `${url.counterparty.list}/${id}/legalEntities`,

            method: Method.PUT,
            // data: datas(
            //   {
            //     legalEntities: JSON.stringify(data),
            //   },
            //   ContentType.FORM_DATA
            // ),
            data: {
              legalEntities: JSON.stringify(data),
            },
          };
        },
        // invalidatesTags: (result, error, arg) => [
        //   { type: 'CounterParty', id: arg.id },
        // ],
        invalidatesTags: () => ['CounterParty'],
      }),
      editCounterParty: build.mutation<
        { counterparty: ConterParty; success: boolean },
        { data: any; id: string | number }
      >({
        query: ({ id, data }) => {
          return {
            url: `${url.counterparty.list}/${id}`,
            params: {
              getLegalEntity: true,
              businessType: 'underwriter',
              _dc: new Date().getTime(),
            },
            method: Method.PUT,
            data: {
              ...data,
              id,
            },
          };
        },
        // invalidatesTags: (result, error, arg) => [
        //   { type: "CounterParty", id: arg.id },
        // ],
      }),
      exportCounterParty: build.mutation<
        string,
        ApiFilter & { fields?: string[] } & {
          businessType: BusinessType;
        }
      >({
        query: ({ fields, filter, businessType }) => {
          return {
            url: `${url.counterparty.export}/${businessType}`,
            data: {
              fields,
            },
            params: {
              businessType,
              filter,
            },
            method: Method.POST,
          };
        },
      }),
    };
  },
});

export const {
  useExportCounterPartyMutation,
  useCreateCounterPartyMutation,
  useEditCounterPartyMutation,
  useSetCounterPartyLegalentityMutation,
} = counterPartyPost;
