import classes from './CofarcoTable.module.scss';
import { Table as CofarTable } from 'antd';

type IProps = {
  dataSource: Array<any>;
  columns: Array<any>;
  loading: boolean;
  scroll?: any;
  emptyText?: string;
  onRow?: any;
  tableLayout?: 'fixed' | 'auto';
  components?: any;
};
export default function Table(props: IProps) {
  return (
    <div className="content-table">
      <CofarTable
        dataSource={props.dataSource}
        columns={props.columns}
        rowClassName={(_, index) =>
          `selectRowClassName ${index % 2 === 1 ? classes.tablelight : classes.tableWhite} ${
            classes.tablerow
          }`
        }
        pagination={false}
        loading={props.loading}
        rowKey={(record) => {
          return `${new Date().getTime()}${record?.id || record?.record_id}`;
        }}
        scroll={props.scroll}
        locale={{
          emptyText: props.emptyText,
        }}
        onRow={props.onRow}
        components={props.components}
        tableLayout={props.tableLayout}
      />
    </div>
  );
}
