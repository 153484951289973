import {
  InputFormBankAccount,
  InputFormPayement,
} from '../../../../@types/segregatedaccounts/payements';
import { isCan, isExist } from '../../../../utils/helper-function';
import HeaderTab from '../../../HeaderTab/HeaderTab';
import FormModal from '../../../Modal/FormModal/FormModal';

import Forms from './Forms/Forms';
import UseModalPayements from './UseModalPayements';
import { Menu } from '../../../../@types/common-types';
import ModalUnsavedChange from '../../../common/Modal/ModalUnsavedChange';
import IbanFormModal from '../../SegAccCommon/IbanFormModal';

type IProps = {
  show: boolean;
  handleClose: (mandate: any | null) => void;
  defaultFormValues: any | null;
};
export default function ModalPayements(props: IProps) {
  const provider = UseModalPayements({
    defaultFormValues: props.defaultFormValues,
    closingModal: props.handleClose,
    show: props.show,
  });

  const modalTitle = isExist(props.defaultFormValues?.id)
    ? 'Payment - ' + props.defaultFormValues?.number
    : 'New - Payment';

  const hiddenFooter = !isCan('write', Menu.payment) && provider.isEdit;
  return (
    <>
      <FormModal
        show={props.show}
        handleClose={() => {
          provider.resetForm();
          props.handleClose(null);
          provider.setActiveMenu(1);
        }}
        isLoading={provider.isSaveLoading}
        onSubmitForm={provider.handleSubmit(provider.submit)}
        isDisabledSave={
          !provider.isDirty ||
          !provider.isCompletedForm ||
          provider.isSaveLoading
        }
        ishiddenfooter={hiddenFooter}
        header={
          <HeaderTab
            isEdit={provider.isEdit}
            title={modalTitle}
            activeMenu={provider.activeMenu}
            setActiveMenu={(p: number) => provider.handleChangeToogle(p)}
            noRigth
          />
        }
        children={
          <>
            {provider.activeMenu === 1 && (
              <Forms
                register={provider.register}
                errors={provider.errors}
                watch={provider.watch}
                setValue={provider.setValue}
                clearFormErrors={provider.clearFormErrors}
                setFormError={provider.setFormError}
                runSearch={(key: keyof InputFormPayement, value: string) =>
                  provider.runSearch(key, value)
                }
                control={provider.control}
                legalentity={provider.legalEntityList || []}
                isLoadingLegalEntity={provider.isLoadingLegalEntity}
                isSaveLoading={provider.isSaveLoading}
                isGetPaymentNoteLoading={provider.isGetPaymentNoteLoading}
                bankList={provider.bankList}
                ibanList={provider.ibanList}
                showLegalEntity={(idL: number | null) =>
                  provider.showLegalEntity(idL)
                }
                notesDataSource={provider.notesDataSource}
                setNotesDataSource={provider.setNotesDataSource}
                countryId={provider.countryId}
                hiddenFooter={hiddenFooter}
                dirtiFields={provider.dirtyFields}
                setShowModalAddIban={provider.setShowModalAddIban}
              />
            )}
          </>
        }
      />

      <ModalUnsavedChange
        showModalUnsaved={provider.isShowModalUnsavedChange}
        handleCancel={() => provider.setIsShowModalUnsavedChange(false)}
        handleLeave={() => provider.showLegalEntityWithoutSave()}
        dirtiFields={provider.dirtyFields}
      />

      <IbanFormModal
        recipientName={provider.legalEntityName}
        showModalAddIban={provider.showModalAddIban}
        handleAdd={(value: InputFormBankAccount) => {
          provider.addIban(value);
          provider.setShowModalAddIban(false);
        }}
        legal_entity_id={provider.legal_entity_id}
        handleCancel={() => provider.setShowModalAddIban(false)}
      />
    </>
  );
}
