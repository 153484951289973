import {
  DashboadWhatsNew,
  DashboardClient,
  DashboardCurrenyExchange,
  DashboardIndustryNews,
  DashboardMarket,
  DashboardReminderData,
  DashboardRev,
  DashboardRue,
  DashboardRum,
  DashboardTodayAgenda,
  DashboardTransaction,
  WhatsNewSeen,
} from "../../../../@types/dashboard";
import { baseApi } from "../../baseApi";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { url } from "../../url";
import { Method } from "../../../../@types/common-types";

const dashboard = baseApi.injectEndpoints({
  endpoints: (build: EndpointBuilder<any, any, any>) => {
    return {
      rev: build.query<DashboardRev, {}>({
        query: () => ({
          url: url.dashboard.rev,
        }),
      }),
      saveRev: build.mutation<DashboardRev, { amount: number }>({
        query: (body) => ({
          url: url.dashboard.rev,
          method: Method.POST,
          data: body,
        }),
      }),
      rum: build.query<DashboardRum, {}>({
        query: () => ({
          url: url.dashboard.rum,
        }),
      }),
      rue: build.query<DashboardRue, {}>({
        query: () => ({
          url: url.dashboard.rue,
        }),
      }),
      transactions: build.query<DashboardTransaction, {}>({
        query: (params) => {
          return {
            url: url.dashboard.transactions,
            params,
          };
        },
        providesTags: [],
      }),
      clients: build.query<DashboardClient, {}>({
        query: (params) => {
          return {
            url: url.dashboard.clients,
            params,
          };
        },
      }),
      markets: build.query<DashboardMarket, {}>({
        query: (params) => {
          return {
            url: url.dashboard.markets,
            params,
          };
        },
      }),
      reminderDeals: build.query<
        DashboardReminderData,
        { page: number; limit: number; route: string }
      >({
        query: ({ page, limit, route }) => {
          return {
            url: `${url.dashboard.reminder}${route}?page=${page}&limit=${limit}`,
          };
        },
      }),
      whatsNew: build.query<DashboadWhatsNew, { page: number; limit: number }>({
        query: ({ page, limit }) => ({
          url: `${url.dashboard.whatsNew}?page=${page}&limit=${limit}`,
        }),
      }),
      whatsNewSeen: build.mutation<WhatsNewSeen, { objectId: number }>({
        query(data) {
          return {
            url: `${url.dashboard.whatsNewSeen}`,
            method: Method.PUT,
            data,
          };
        },
      }),
      seen: build.mutation<WhatsNewSeen, {}>({
        query() {
          return {
            url: `${url.dashboard.seen}`,
            method: Method.GET,
          };
        },
      }),
      outlookTodayAgenda: build.query<DashboardTodayAgenda, {}>({
        query: () => ({ url: `${url.dashboard.outlookTodayAgenda}` }),
      }),
      currencyExchangeRate: build.query<DashboardCurrenyExchange, {}>({
        query: () => ({
          url: `${url.dashboard.currencyExchangeRate}`,
          method: Method.GET,
        }),
      }),
      industryNews: build.query<DashboardIndustryNews, {}>({
        query: () => ({
          url: `${url.dashboard.industryNews}`,
          method: Method.GET,
        }),
      }),
    };
  },
});

export const {
  useRevQuery,
  useSaveRevMutation,
  useTransactionsQuery,
  useClientsQuery,
  useMarketsQuery,
  useRueQuery,
  useRumQuery,
  useLazyReminderDealsQuery,
  useLazyWhatsNewQuery,
  useWhatsNewSeenMutation,
  useSeenMutation,
  useOutlookTodayAgendaQuery,
  useCurrencyExchangeRateQuery,
  useIndustryNewsQuery,
} = dashboard;
