import type { ColumnsType, ColumnType } from 'antd/es/table';
import React, { useState } from 'react';
import { EntityDeals } from '../../../../@types/deals/deals';
import FilterTab from '../../../../utils/filter-tab';
import { ReactComponent as ArrowDown } from '../../../../assets/img/Arrow-Down-Gray.svg';
import { ReactComponent as ArrowUP } from '../../../../assets/img/Arrow-UP-Gray.svg';
import UseConstante from '../../../../hooks/use-constante';
import {
  amountFormat,
  exportDateUi,
  exportedDropdownValue,
  isCan,
} from '../../../../utils/helper-function';
import { Menu, TermConst } from '../../../../@types/common-types';
import { ReactComponent as Edit } from '../../../../assets/img/Edit.svg';
import { ReactComponent as Delete } from '../../../../assets/img/Delete.svg';
type IProps = {
  handleSorted: (key: string, ruls: 'ASC' | 'DESC') => void;
  deals: Array<EntityDeals>;
  editDeal: (id: number) => void;
  handleDeleteDeal: (id: number) => void;
};
type DataIndex = keyof EntityDeals;

export default function ColumnsDealList(props: IProps) {
  const [dropDownFilterState, setDropDownFilterState] =
    useState<boolean>(false);
  const [uncheckedId, setUncheckedId] = useState<Array<number>>([]);
  const terms = UseConstante();

  const onChangeFilterColumn = (key: number | undefined) => {
    const found = uncheckedId?.find((uncheckedTitle) => uncheckedTitle === key);
    if (found) {
      setUncheckedId(uncheckedId.filter((t) => t !== key));
    } else {
      setUncheckedId([...uncheckedId, key || 0]);
    }
  };

  const getColumnSearchProps = (
    dataIndex:
      | DataIndex
      | 'clientId'
      | 'inceptionDate'
      | 'expiryDate'
      | 'dealType'
      | 'partnerId',
  ): ColumnType<EntityDeals> => ({
    filterDropdown: ({ close }) =>
      FilterTab<EntityDeals>({
        columns: columns,
        dataIndex: dataIndex,
        handleSorted: (dataIndex: string, rule: 'ASC' | 'DESC') =>
          props.handleSorted(dataIndex, rule),
        onChangeFilterColumn: (key: number | undefined) =>
          onChangeFilterColumn(key),
        uncheckedId: uncheckedId,
        close: () => close(),
      }),
    align: 'left',

    filterIcon: () => {
      return dropDownFilterState ? (
        <ArrowDown width={12} height={12} />
      ) : (
        <ArrowUP width={12} height={12} />
      );
    },
    onFilterDropdownOpenChange: (visible) => {
      setDropDownFilterState(visible);
    },
    render: (text: string) => <span>{text}</span>,
  });

  const columns: ColumnsType<EntityDeals> = React.useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'number',
        key: 1,
        ...getColumnSearchProps('number'),
        render: (text: string) => <span> {text}</span>,
      },

      {
        title: 'STATUS',
        dataIndex: 'status',
        key: 2,
        ...getColumnSearchProps('status'),
        render: (text: number) => {
          if (text) {
            return (
              <span className={'status'}>
                <b>
                  {exportedDropdownValue(
                    terms?.getConst(TermConst.dealStatus) || [],
                    text,
                  )}
                </b>
              </span>
            );
          }
        },
      },
      {
        title: 'START DATE',
        dataIndex: 'inception_date',
        key: 3,
        ...getColumnSearchProps('inceptionDate'),
        render: (text: string) => {
          if (text) {
            return (
              <span>
                <b>{exportDateUi(text)?.toString()}</b>
              </span>
            );
          }
        },
      },
      {
        title: 'END DATE',
        dataIndex: 'expiry_date',
        key: 4,
        ...getColumnSearchProps('expiryDate'),
        render: (text: string) => {
          if (text) {
            return (
              <span>
                <b>{exportDateUi(text)?.toString()}</b>
              </span>
            );
          }
        },
      },
      {
        title: 'TYPE',
        dataIndex: 'deal_type',
        key: 5,
        ...getColumnSearchProps('dealType'),
        render: (text: number) => {
          if (text) {
            return (
              <span className={`gray-rounded`}>
                <b>
                  {exportedDropdownValue(
                    terms?.getConst(TermConst.dealType) || [],
                    text,
                  )}
                </b>
              </span>
            );
          }
        },
      },
      {
        title: 'PARTNER',
        dataIndex: 'partner_contact_id',
        key: 6,
        ...getColumnSearchProps('partnerId'),
        render: (text: string, rec: Partial<EntityDeals>) => {
          if (rec.partner) {
            return (
              <span>
                <b>{rec.partner?.name}</b>
              </span>
            );
          }
        },
      },
      {
        title: 'AMOUNT',
        dataIndex: 'amount',
        key: 7,
        ...getColumnSearchProps('amount'),
        render: (text: number) => {
          if (text) {
            return <span>{amountFormat(text?.toFixed(2))}</span>;
          }
          return <span>0.00</span>;
        },
      },
      {
        title: 'CURRENCY',
        dataIndex: 'currency',
        key: 8,
        ...getColumnSearchProps('currency'),
        render: (text: string) => {
          if (text) {
            return (
              <span>
                <b>{text.toUpperCase()}</b>
              </span>
            );
          }
        },
      },
      {
        title: '',
        dataIndex: 'id',
        key: 10,
        width: 120,
        onCell: () => {
          return {
            onClick: (event: any) => {
              event.stopPropagation();
            },
          };
        },
        hidden: !isCan('write', Menu.deal) && !isCan('delete', Menu.deal),
        render: (text: number) => {
          return (
            <>
              {isCan('write', Menu.mandate) && (
                <span style={{ minWidth: 95 }}>
                  <i
                    className="rounded-icon"
                    onClick={() => props.editDeal(text)}
                  >
                    <Edit height="30" />
                  </i>
                </span>
              )}
              {isCan('delete', Menu.mandate) && (
                <span style={{ minWidth: 55 }}>
                  <i
                    className="rounded-icon"
                    onClick={() => props.handleDeleteDeal(text)}
                  >
                    <Delete height="30" />
                  </i>
                </span>
              )}
            </>
          );
        },
      },
    ],
    [props.deals, dropDownFilterState, uncheckedId],
  );

  return { columns, uncheckedId };
}
