import { useState } from 'react';
import { Form, FormControl, FormControlProps } from 'react-bootstrap';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { isNumber, floorNumber } from '../../utils/helper-function';
import { ReactComponent as Warning } from '../../assets/img/Warning.svg';

type IProps = FormControlProps & {
  value: string | undefined;
  setValue: (value: string | undefined) => void;
  class?: string;
  onBlur?: any;
  unitFormat?: number;
  disabled?: boolean;
  isInPrefix?: boolean;
  maxValue?: number;
};
export default function CofarcoNumberPercentageForm(props: IProps) {
  const valueForm: any = props.value;

  const unit = props.unitFormat || 1;
  const maxLimit = props.maxValue || 100;
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const handlePerent = (v: any) => {
    if (props.isInPrefix) {
      return v + ' %';
    }
    return '% ' + v;
  };

  return (
    <Form.Label className="w-100" style={{ position: 'relative' }}>
      <div
        className={`d-flex  elementFormNumber flex-row mx-0  py-2 my-0  ${
          props?.disabled ? 'disabled-content' : ''
        }`}
      >
        <div className={`d-flex flex-fill `}>
          <FormControl
            type="text"
            disabled={props.disabled}
            value={valueForm}
            className={`flex-fill formNumber rounded-0`}
            onChange={(e: any) => {
              if (e?.target?.value) {
                const value: any = e.target.value;
                const d = props.maxValue
                  ? isNumber(value) && Number(value) <= maxLimit
                  : isNumber(value);
                if (d) {
                  props.setValue(value);
                  return;
                }
                props.setValue(valueForm || 0);
                return;
              }
              props.setValue(undefined);
            }}
            onBlur={() => {
              setIsFocused(false);

              const removePercent = floorNumber(valueForm as string);

              const value: any =
                Number.parseFloat(removePercent)?.toFixed(unit);

              props.setValue(handlePerent(value));
            }}
            onFocus={() => {
              setIsFocused(true);
              const floorValue = floorNumber(valueForm?.toString() as string);
              props.setValue(floorValue?.toString()?.trim());
            }}
          />
        </div>
        <div
          className={`d-flex justify-content-end flex-fill ${
            props?.disabled ? 'disabled' : ''
          }`}
        >
          <div
            className="flex flex-colummn justify-content-center pt-2 pe-2"
            style={{ color: '#D9D9D9' }}
          >
            <span>|</span>
          </div>
          <div className={`d-flex flex-column justify-content-between`}>
            <div className="py-0 my-0">
              <IoIosArrowUp
                onClick={() => {
                  const floorValue = floorNumber(
                    valueForm?.toString() as string,
                  );
                  const v = floorValue + 1;

                  if (Number(v) < 100) {
                    props.setValue('% ' + Number.parseFloat(v)?.toFixed(unit));
                  }
                }}
                style={{
                  fontSize: 16,

                  cursor: 'pointer',
                  color: '#851001',
                }}
              />
            </div>
            <div className="py-0 my-0">
              <IoIosArrowDown
                onClick={() => {
                  const floorValue = floorNumber(
                    valueForm?.toString() as string,
                  );
                  const v: any = floorValue - 1;

                  if (v >= 0) {
                    props.setValue('% ' + Number.parseFloat(v)?.toFixed(unit));
                  }
                }}
                style={{
                  fontSize: 16,
                  cursor: 'pointer',
                  color: '#851001',
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {isFocused && (
        <div style={{ position: 'absolute' }}>
          <Warning style={{ fontSize: 4 }} width={10} />{' '}
          <span style={{ fontSize: 12 }}>
            The maximum value for this field is {maxLimit}
          </span>
        </div>
      )}
    </Form.Label>
  );
}
