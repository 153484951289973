import { Col, Row } from 'antd';
import DashboardGlobalSearch from '../../components/Dashboard/DashboardGlobalSearch';
import './Dashboard.scss';
import { DashboadClock } from '../../components/Dashboard/DashboardClock';
import { DashboadCircles } from '../../components/Dashboard/DashboadCircles';
import { DashboardWhatsNew } from '../../components/Dashboard/DashboardWhatsNew';
import { DashboardQuickAdditions } from '../../components/Dashboard/DashboardQuickAdditions';
import { DashboardReminder } from '../../components/Dashboard/DashboardReminder';
import { DashboardAmountsCards } from '../../components/Dashboard/DashboardAmountsCards';
import { DashboardIndustryNews } from '../../components/Dashboard/DashboardIndustryNews';

const Dashboard = () => {
  return (
    <DashboardGlobalSearch>
      <Row gutter={[24, 24]} align="stretch" style={{ paddingBottom: 24 }}>
        <Col xs={24} xl={13}>
          <DashboardAmountsCards />
          <DashboadCircles />
          <DashboardWhatsNew />
          <DashboardQuickAdditions />
        </Col>
        <Col xs={24} xl={11}>
          <DashboadClock />
          <DashboardIndustryNews />
          <DashboardReminder />
        </Col>
      </Row>
    </DashboardGlobalSearch>
  );
};

export default Dashboard;
