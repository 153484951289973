import type { MenuProps } from "antd";
import { Dropdown } from "antd";
import { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import classes from "./TableDisplay.module.scss";

type Props = {
  currentPage: number;
  perPage: number;
  totalRow: number;
  onChage?: (page: number) => void;
  options: number[];
};

const TableDisplay = ({
  currentPage,
  perPage,
  totalRow,
  onChage,
  options,
}: Props) => {
  const [index, setIndex] = useState<number>(1);

  const onChangeDisplay = (value: number) => {
    const some: number = index + value;
    if (some < options.length && some >= 0) {
      setIndex(some);
      if (onChage) {
        onChage(options[some]);
      }
    }
  };

  return (
    <div className="d-flex py-2 mt-2">
      <div className="mt-2">
        Displaying {(currentPage - 1) * perPage + 1}-
        {Math.min(totalRow, currentPage * perPage)} of {totalRow}
      </div>
      <div className={`d-flex mx-2 ${classes.element} py-2 my-0`}>
        <div className={`${classes.elementItem}`}>
          <Dropdown
            menu={{
              items: options.map<any>((e, index) => {
                return {
                  key: index,
                  label: (
                    <div
                      onClick={() => {
                        if (onChage) {
                          onChage(e);
                        }
                        setIndex(options.indexOf(e));
                      }}
                    >
                      {e}
                    </div>
                  ),
                };
              }) as MenuProps["items"],
            }}
          >
            <a
              style={{ color: "#888", textDecoration: "none" }}
              onClick={(e) => e.preventDefault()}
            >
              {perPage}
            </a>
          </Dropdown>
        </div>
        <span className="mx-2" style={{ color: "#D9D9D9" }}>
          |
        </span>
        <div className={`d-block py-4 my-0 ${classes.actions}`}>
          <div className="py-0 my-0">
            <IoIosArrowUp
              onClick={() => onChangeDisplay(+1)}
              style={{
                fontSize: 16,
                marginTop: 8,
                cursor: "pointer",
                color: "#851001",
              }}
            />
          </div>
          <div className="py-0 my-0">
            <IoIosArrowDown
              onClick={() => onChangeDisplay(-1)}
              style={{
                fontSize: 16,
                marginBottom: 8,
                cursor: "pointer",
                color: "#851001",
              }}
            />
          </div>
        </div>
      </div>
      <div className="mt-2 mx-2">Entries per page</div>
    </div>
  );
};

export default TableDisplay;
