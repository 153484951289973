import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Typography } from 'antd';
import { assignWith, isEmpty, pick, pickBy } from 'lodash';
import { useForm } from 'react-hook-form';
import {
  useListTermsQuery,
  useListUserQuery,
} from '../../../../redux/api/ws/terms/get';
import { ClientFormInputs, ConterParty } from '../../../../@types/conterParty';
import {
  useLazyPeopleByIdQuery,
  useLazySearchPeopleQuery,
} from '../../../../redux/api/ws/databases/people';
import { People } from '../../../../@types/people';
import { LegalEntity } from '../../../../@types/conterParty';
import UseDynamicsTable from '../../../dynamic-table/use-dynamics-table';
import { ReactComponent as Edit } from '../../../../assets/img/Edit.svg';
import { ReactComponent as Delete } from '../../../../assets/img/Delete.svg';
import DropdownSelect, {
  DropDownOptionObjectType,
  DropDownOptionType,
} from '../../../common/DropDown/DropdownSelect';
import { useSearchLegalEntityNameMutation } from '../../../../redux/api/ws/databases/legale-entities';
import {
  useCreateCounterPartyMutation,
  useEditCounterPartyMutation,
  useSetCounterPartyLegalentityMutation,
} from '../../../../redux/api/ws/counterparties/post';

import classes from './ClientModal.module.scss';
import { useAppDispatch } from '../../../../hooks/redux';
import { updateNotificationMessage } from '../../../../redux/slice/notificationSlice';
import { updateModalAction } from '../../../../redux/slice/modalSlice';
import { Button } from 'react-bootstrap';
import { isCan, isExist } from '../../../../utils/helper-function';
import { Menu } from '../../../../@types/common-types';
type TLegalEntity = Pick<LegalEntity, 'id' | 'name'> & {
  key: any;
  legal_entity?: LegalEntity;
  temps?: LegalEntity;
};

const useClientModal = ({
  defaultFormValues,
  onSubmittedForm,
}: {
  onSubmittedForm?: () => void;
  defaultFormValues?: ConterParty | null;
}) => {
  const defaultValues: ClientFormInputs = useMemo(
    () => ({
      business: 'client',
      id: -1,
      name: '',
      commercial_name: '',
      type: '',
      country_id: '',
      legal_entity_id: -1,
      activity: '',
      market: '',
      market_commodity: '',
      market_other: '',
      main_people_id: null,
      most_senior_people_id: null,
      isDirty: 1,
      informations: {
        team: 'other',
        clientStatus: '',
        managerUserId: 0,
        kycValidationDate: undefined,
        kycRate: undefined,
      },
    }),
    [],
  );
  const [isCompletedForm, setIsCompletedForm] = useState(false);

  const formValues = useMemo(
    () =>
      defaultFormValues
        ? ({
            ...pick(defaultFormValues, Object.keys(defaultValues)),
          } as ClientFormInputs)
        : defaultValues,
    [defaultFormValues, defaultValues],
  );

  const isEdit = useMemo(() => isExist(formValues?.id), [formValues?.id]);

  const {
    handleSubmit,
    register,
    // trigger: triggerFormValidation,
    watch: watchFormValue,
    reset: resetForm,
    setValue: setFormValue,
    getValues: getFormValue,
    formState: { errors, isDirty, dirtyFields },
  } = useForm<ClientFormInputs>({
    shouldUseNativeValidation: false,
    defaultValues: formValues,
    reValidateMode: 'onChange',
    mode: 'all',
  });

  const [isShowModalUnsavedChange, setIsShowModalUnsavedChange] =
    useState<boolean>(false);
  const [showOtherModaldata, setShowOtherModal] = useState<any>();

  const updateDataSource = (
    key: any,
    v: string,
    legal_entity: any,
    isSave: boolean = false,
    isCancel: boolean = false,
  ) => {
    const index = dataSourceLegalEntity.findIndex((f) => f.key === key);
    if (index > -1) {
      const newDataSource = [...dataSourceLegalEntity];
      if (isSave) {
        newDataSource[index] = {
          ...newDataSource[index],
          name: v,
          temps: undefined,
          legal_entity: legal_entity,
        };
        setFormValue('legal_entity_id', legal_entity.id);
        setFormValue('isUpdatedTable', true, { shouldDirty: true });
      } else if (isCancel) {
        const removedEmptyEntity = newDataSource?.filter(
          (el) => el.legal_entity !== undefined,
        );
        setDataSourceLegalEntity(removedEmptyEntity);
        return;
      } else {
        newDataSource[index] = {
          ...newDataSource[index],
          name: v,
          temps: legal_entity as LegalEntity,
        };
      }
      setDataSourceLegalEntity(newDataSource);
    }
  };

  const columnLegalEntity: any = [
    {
      title: 'Legal Entity',
      dataIndex: 'name',
      key: 1,
      render: (text: number, record: TLegalEntity) => {
        const editable = isEdittingLegalEntity(record);
        return editable ? (
          <div className="d-flex flex-column">
            <div style={{ maxWidth: 420 }}>
              <DropdownSelect
                preValue={record.name}
                onSelectOption={(e) => {
                  updateDataSource(
                    record.key,
                    (e as DropDownOptionObjectType).value as string,
                    (e as DropDownOptionObjectType).key,
                  );
                }}
                options={[]}
                onSearch={(d, e) => searchLegalEntity(e, d)}
                loading={isLoading}
              />
            </div>
            <div className="d-flex justify-content-center py-4">
              <div className="d-inline px-2">
                <Button
                  disabled={editable && !isExist(record.name)}
                  className={`${classes.btnSave}`}
                  onClick={() => {
                    updateDataSource(
                      record.key,
                      record.temps?.name ?? '',
                      record.temps,
                      true,
                    );
                    if (record.temps?.id) {
                      setFormValue('legal_entity_id', record.temps?.id);
                    }
                    setLegalEntityEditingKey('');
                  }}
                >
                  Update
                </Button>
              </div>
              <div className="d-inline px-2">
                <Button
                  variant="default"
                  onClick={() => {
                    updateDataSource(
                      record.key,
                      record.legal_entity?.name ?? '',
                      undefined,
                      false,
                      true,
                    );
                    setLegalEntityEditingKey('');
                  }}
                  className={`${classes.btnCancel}`}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="py-3 ps-3">{record.name}</div>
        );
      },
    },
    {
      title: '',
      dataIndex: 'id',
      key: 2,
      width: 120,
      hidden:
        !isCan('write', Menu.legal_entity) &&
        !isCan('delete', Menu.legal_entity),
      render: (text: number, record: TLegalEntity) => {
        const editable = isEdittingLegalEntity(record);
        return editable ? (
          <></>
        ) : (
          <>
            {isCan('write', Menu.legal_entity) && (
              <Typography.Link
                disabled={editinKeyLegalEntity !== ''}
                onClick={() => {
                  updateDataSource(
                    record.key,
                    record.name,
                    record.legal_entity,
                  );
                  editLegalEntity(record);
                }}
                style={{ marginRight: 10 }}
              >
                <Edit height="30" />
              </Typography.Link>
            )}
            {isCan('delete', Menu.legal_entity) && (
              <Typography.Link
                disabled={editinKeyLegalEntity !== ''}
                onClick={() => {
                  setDeleteModal({
                    show: true,
                    id: record.key,
                    legal_entity: record.legal_entity,
                  });
                }}
              >
                <Delete height="30" />
              </Typography.Link>
            )}
          </>
        );
      },
    },
  ].filter((el) => !el.hidden);

  const {
    edit: editLegalEntity,
    mergedColumns: mergedLegalEntity,
    isEditing: isEdittingLegalEntity,
    editingKey: editinKeyLegalEntity,
    form: formLegalEntity,
    dataSource: dataSourceLegalEntity,
    removeItem: removeItemLegalEntity,
    add: addLegalEntity,
    setDataSource: setDataSourceLegalEntity,
    setEditingKey: setLegalEntityEditingKey,
    isInModeEditing,
  } = UseDynamicsTable<TLegalEntity>({
    rowItem: {
      id: 0,
      key: 0,
      name: '',
      legal_entity: undefined,
    },
    column: columnLegalEntity,
  });

  const { data: terms } = useListTermsQuery(undefined, {
    refetchOnMountOrArgChange: false,
  });
  const { data: users } = useListUserQuery(undefined);
  const [searchPeople] = useLazySearchPeopleQuery();
  const [searchLegalEntityQuery, { isLoading }] =
    useSearchLegalEntityNameMutation();
  const [createCounterParty] = useCreateCounterPartyMutation();
  const [editCounterParty] = useEditCounterPartyMutation();
  const [getPeopleById] = useLazyPeopleByIdQuery();
  const [setCounterPartyLegalEntity] = useSetCounterPartyLegalentityMutation();

  const [peoples, setPeoples] = useState<People[]>([]);
  const dispatch = useAppDispatch();

  const [deleteModal, setDeleteModal] = useState<{
    show: boolean;
    id?: number;
    legal_entity?: LegalEntity;
  }>({ show: false, id: undefined });

  const closeDeleteLegalEntity = () => {
    setDeleteModal({ show: false, id: undefined });
    if (dataSourceLegalEntity?.length > 0) {
      setFormValue('isUpdatedTable', true, { shouldDirty: true });
    }
  };
  const deleteLegalEntity = (id: number) => {
    removeItemLegalEntity(id);
    setFormValue('legal_entity_id', 0);
    closeDeleteLegalEntity();
  };

  const searchLegalEntity = useCallback(
    (
      value: string,
      dipatch?: Dispatch<SetStateAction<DropDownOptionType[]>>,
    ) => {
      // setIsLeagEntityLoading(true);
      searchLegalEntityQuery({
        page: 1,
        start: 0,
        limit: 100,
        query: `%${value}%`,
      })
        .then((resp) => {
          const rep = resp as any;
          if (rep.data) {
            const datas = (rep.data.legalentity as LegalEntity[])
              .filter((f) => f && f.name.trim() !== '')
              ?.map((e) => ({ key: e, value: e.name ?? '' }));
            // setLegalEntities(datas);
            if (dipatch) dipatch(datas);
          }
        })
        .finally(() => {
          // setIsLeagEntityLoading(false);
        });
    },
    [],
  );

  const searchPeopleCallback = useCallback(
    (
      legal_entity_id: number[],
      v: string,
      callback: (people: People[]) => void,
    ) => {
      if (legal_entity_id.length === 0) {
        return callback([]);
      }
      searchPeople({
        key: 'name',
        value: v,
        params: { limit: 1000, legalEntityId: legal_entity_id },
      }).then((res) => {
        if (res.data && res.data.people) {
          callback(res.data.people);
        }
      });
    },
    [],
  );

  const showPeopleModal = (p: number | string, noCheck?: boolean) => {
    getPeopleById({ id: p }).then((res) => {
      if (res.data?.people) {
        if (!isEmpty(dirtyFields) && !noCheck && isEdit) {
          setIsShowModalUnsavedChange(true);
          setShowOtherModal(p);
        } else {
          dispatch(
            updateModalAction({
              data: res.data?.people,
              isAdd: true,
              type: 'people',
            }),
          );
          setIsShowModalUnsavedChange(false);
          isEdit && resetForm(defaultValues);
        }
      }
    });
  };

  const handleClose = () => {
    resetForm(defaultValues);
    setDataSourceLegalEntity([]);
  };

  const onSubmitForm = (e: any) => {
    e.preventDefault();
    handleSubmit(saveCounterParty, onError)();
  };
  const onError = () => null;

  const setCounterPartyLegalEntityCallback = useCallback(
    (id: number, data: any) => {
      setCounterPartyLegalEntity({ data, id })
        .then(() => {})
        .finally(() => {
          handleClose();
          if (onSubmittedForm) onSubmittedForm();
        });
    },
    [],
  );

  const saveCounterParty = useCallback(
    (data: any) => {
      const les = dataSourceLegalEntity
        .filter((e) => e.legal_entity)
        ?.map((e) => ({
          legalEntityId: e.legal_entity?.id,
          roles: [],
        }));
      if (data.id && data.id > 0) {
        const dataToedit = assignWith(
          {},
          pickBy(
            {
              ...data,
              // main_people_id: getFormValue('_main_people'),
              // most_senior_people_id: Number(
              //   getFormValue('_most_senior_people')
              // ),
            },
            (v, k) =>
              Object.keys(formValues).includes(k) &&
              (formValues as Record<string, any>)[k] !== v &&
              !k.startsWith('_'),
          ) as Record<string, any>,
          (_, value) => (typeof value == 'undefined' ? '' : value),
        );
        editCounterParty({
          data: {
            ...dataToedit,
            legal_entity_id: dataSourceLegalEntity[0].legal_entity?.id,
          },
          id: data.id,
        })
          .then((resp: any) => {
            dispatch(
              updateNotificationMessage({
                show: true,
                title: 'Client update',
                body: 'Successfully updated !',
              }),
            );
            if (resp.data && resp.data.counterparty && les.length > 0) {
              const c = resp.data.counterparty as ConterParty;
              setCounterPartyLegalEntityCallback(c.id, les);
            } else {
              if (onSubmittedForm) onSubmittedForm();
            }
          })
          .finally(() => {
            handleClose();
          });
      } else {
        createCounterParty({
          data: {
            ...data,
            legal_entity_id: dataSourceLegalEntity[0].legal_entity?.id,
          },
          businessType: 'client',
        })
          .then((resp: any) => {
            dispatch(
              updateNotificationMessage({
                show: true,
                title: 'Client add',
                body: 'Successfully added !',
              }),
            );
            if (resp.data && resp.data.counterparty && les.length > 0) {
              const c = resp.data.counterparty as ConterParty;
              setCounterPartyLegalEntityCallback(c.id, les);
            } else {
              if (onSubmittedForm) onSubmittedForm();
            }
          })
          .finally(() => {
            handleClose();
          });
      }
    },
    [formValues, dataSourceLegalEntity],
  );

  useEffect(() => {
    const all_terms = terms?.term;
    const all_users = users?.user;
    resetForm(defaultFormValues as ClientFormInputs);
    if (all_terms) {
      setFormValue(
        '_type',
        all_terms.find((t) => t.key === defaultFormValues?.type)?.value ?? '',
      );
      setFormValue(
        '_status',
        all_terms.find(
          (t) => t.key === defaultFormValues?.informations.clientStatus,
        )?.value ?? '',
      );
      setFormValue(
        '_market',
        all_terms.find((t) => t.key === defaultFormValues?.market)?.value ?? '',
      );
      setFormValue(
        '_market_commodity',
        all_terms.find((t) => t.key === defaultFormValues?.market_commodity)
          ?.value ?? '',
      );
      setFormValue(
        '_team',
        all_terms.find((t) => t.key === defaultFormValues?.informations.team)
          ?.value ?? '',
      );
    }
    searchPeopleCallback(
      defaultFormValues?.legal_entities?.map((e) => e.id) ?? [],
      '',
      (people) => {
        const mainp = people?.find(
          (t) => t.id === defaultFormValues?.main_people_id,
        );
        if (mainp) {
          setFormValue(
            '_main_people_id',
            `${mainp?.firstname} ${mainp?.lastname}`,
          );
          setFormValue('_main_people', mainp);
        }
        const mostp = people.find(
          (t) => t.id === defaultFormValues?.most_senior_people_id,
        );
        if (mostp) {
          setFormValue(
            '_most_senior_people_id',
            `${mostp?.firstname} ${mostp?.lastname}`,
          );
          setFormValue('_most_senior_people', mostp);
        }
      },
    );
    if (all_users) {
      setFormValue(
        '_managerUserFullname',
        all_users.find(
          (t) => t.people_id === defaultFormValues?.informations.managerUserId,
        )?.fullname ?? '',
      );
    }
  }, [defaultFormValues, terms?.term, users?.user]);

  useEffect(() => {
    if (defaultFormValues?.legal_entity) {
      const dataSource = [
        {
          key: 0,
          id: 0,
          name: defaultFormValues?.legal_entity.name,
          legal_entity: defaultFormValues?.legal_entity,
        },
      ];
      if (
        defaultFormValues &&
        defaultFormValues?.legal_entities &&
        defaultFormValues?.legal_entities?.length > 0
      ) {
        setDataSourceLegalEntity([
          ...dataSource,
          ...(defaultFormValues?.legal_entities || [])
            .map((e, index) => ({
              key: index + 1,
              id: index + 1,
              name: e.name,
              legal_entity: e,
            }))
            .filter(
              (d) =>
                !dataSource
                  .map((d) => d.legal_entity.id)
                  .includes(d.legal_entity.id),
            ),
        ]);
      } else {
        setDataSourceLegalEntity(dataSource);
      }
    }
    //Cas legal_entity not exist but legal_entities exist
    else if (
      defaultFormValues &&
      defaultFormValues?.legal_entities &&
      defaultFormValues?.legal_entities?.length > 0
    ) {
      setDataSourceLegalEntity([
        ...(defaultFormValues?.legal_entities || []).map((e, index) => ({
          key: index,
          id: index,
          name: e.name,
          legal_entity: e,
        })),
      ]);
    }
    setLegalEntityEditingKey('');
  }, [defaultFormValues]);

  useEffect(() => {
    if (dataSourceLegalEntity.length > 0) {
      const legal_entity_id = dataSourceLegalEntity?.map(
        (e) => e.legal_entity?.id ?? -1,
      );
      searchPeopleCallback(legal_entity_id ?? [], '', (data) => {
        setPeoples(data);
      });
    } else {
      setPeoples([]);
    }
  }, [dataSourceLegalEntity]);

  useEffect(() => {
    const subscription = watchFormValue((value) => {
      const isCompletedRequired = isExist(value.name);
      setIsCompletedForm(isCompletedRequired ? true : false);
    });
    return () => subscription.unsubscribe();
  }, [watchFormValue]);

  return {
    errors,
    defaultValues: formValues,
    terms: terms?.term ?? [],
    users: users?.user ?? [],
    peoples,
    searchPeopleCallback,
    onSubmitForm,
    register,
    watchFormValue,
    resetForm,
    setFormValue,
    getFormValue,
    mergedLegalEntity,
    formLegalEntity,
    dataSourceLegalEntity,
    columnLegalEntity,
    addLegalEntity,
    setDataSourceLegalEntity,
    saveCounterParty,
    handleClose,
    showPeopleModal,
    deleteModal,
    closeDeleteLegalEntity,
    deleteLegalEntity,
    isInModeEditing,
    isDirty,
    isCompletedForm,
    isShowModalUnsavedChange,
    setIsShowModalUnsavedChange,
    showOtherModaldata,
  };
};

export default useClientModal;
