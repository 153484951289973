import React, { useEffect, useState, useContext } from 'react';
import '../../../assets/scss/cofar-custom/icons.scss';
import {
  useDeleteGroupeMutation,
  useGetCountryQuery,
  useGroupQuery,
  usePeopleQuery,
} from '../../../redux/api/ws/databases/groups';
import { EntityGroupe, GroupeFilterType } from '../../../@types/group/types';

import * as bootstrap from 'bootstrap';
import { FilterType } from '../../../@types';
import ColumnsGroupContent from './ColumnsGroupContent';
import { ParamsTable } from '../../../@types/common-types';
import { useAppDispatch } from '../../../hooks/redux';
import { updateModalAction } from '../../../redux/slice/modalSlice';
import { ContextDataBase } from '../DatabaseContent';
import { debounce } from 'lodash';
import {setGroupFilter} from "../../../redux/slice/groupFilterSlice";

export default function UseGroupContent() {
  const [paramsGroup, setParamsGroup] = useState<ParamsTable>({
    page: 1,
    start: 0,
    limit: 20,
    sort: JSON.stringify([{ property: 'name', direction: 'ASC' }]),
    filter: '[]',
  });
  const [modalConfirm, setModalConfirm] = useState(false);
  const [modalConfirmDelete, setModalConfirmDelete] = useState(false);
  const [dataSource, setDataSource] = useState<Array<EntityGroupe>>([]);
  const [totalData, setTotalData] = useState<number>(0);
  const [filterData, setFilterData] = useState<GroupeFilterType>({});
  const [selectedGroup, setSelectedGroup] = useState<EntityGroupe | null>(null);

  const [show, setShow] = useState(false);
  const { data: responseCountry } = useGetCountryQuery();
  const dispatch = useAppDispatch();

  const { txt } = useContext(ContextDataBase);

  const [
    deleteGroupe,
    { isLoading: isLoadingDelete, isSuccess: isSuccessDelete },
  ] = useDeleteGroupeMutation();
  const { data: responsePeople } = usePeopleQuery({
    page: 1,
    start: 0,
    limit: 20,
  });

  const { data, isLoading, isSuccess } = useGroupQuery(paramsGroup);

  const handleSorted = React.useCallback(
    (key: string, ruls: 'ASC' | 'DESC') => {
      const paramsFilter = JSON.stringify([{ property: key, direction: ruls }]);
      setParamsGroup({
        ...paramsGroup,
        sort: paramsFilter,
      });
    },
    [paramsGroup]
  );

  useEffect(() => {
    setFilterData({});
    dispatch(setGroupFilter({}));
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setDataSource(data?.group || []);
      setTotalData(data.total);
    }
  }, [isSuccess, data?.group]);

  const debounceSearch = React.useCallback(
    debounce((txt) => {
      const params = getFilterParams();
      const tempFilter = [
        ...params,
        { property: 'name', operator: 'like', value: `%${txt}%` },
      ];
      setParamsGroup({
        ...paramsGroup,
        page: 1,
        start: 0,
        limit: 20,
        filter: JSON.stringify(tempFilter),
      });
    }, 750),
    []
  );

  useEffect(() => {
    if (txt) {
      debounceSearch(txt);
    } else {
      setParamsGroup({
        page: 1,
        start: 0,
        limit: 20,
        sort: JSON.stringify([{ property: 'name', direction: 'ASC' }]),
        filter: '[]',
      });
    }
  }, [txt]);

  const handleModalConfirm = () => {
    setModalConfirm(!modalConfirm);
  };

  const handleShowModalDelete = () => {
    setModalConfirmDelete(!modalConfirmDelete);
  };

  const setPerPage = React.useCallback(
    (page: number) => {
      setParamsGroup({
        ...paramsGroup,
        limit: page,
      });
    },
    [paramsGroup]
  );

  const handleTableChange = React.useCallback(
    (current: number) => {
      setParamsGroup({
        ...paramsGroup,
        page: current,
      });
    },
    [paramsGroup]
  );

  const editGroup = async (idGroupe: number, data?: EntityGroupe) => {
    const foundGroup = data ?? dataSource.find(
      (gr: EntityGroupe) => gr.id?.toString() === idGroupe?.toString()
    );
    if (foundGroup) {
      dispatch(
        updateModalAction({
          data: foundGroup,
          isAdd: true,
          type: 'group',
        })
      );
    }
  };

  const deleteGroup = (idGroupe: number, data?: EntityGroupe) => {
    if (idGroupe) {
      handleShowModalDelete();
      const foundGroup = data ?? dataSource.find(
        (gr: EntityGroupe) => gr.id?.toString() === idGroupe?.toString()
      );
      setSelectedGroup(foundGroup || null);
    }
  };

  const { columns, uncheckedId } = ColumnsGroupContent({
    editGroup: editGroup,
    handleSorted: handleSorted,
    deleteGroup: deleteGroup,
    groupes: dataSource,
  });

  const handleModal = (group: EntityGroupe | null) => {
    if (group) {
      setDataSource([group, ...dataSource]);
    }
    setShow(!show);
  };

  const handleCreateGroupe = () => {
    dispatch(
      updateModalAction({
        data: null,
        isAdd: true,
        type: 'group',
      })
    );
  };

  const validDelete = () => {
    if (selectedGroup?.id) {
      deleteGroupe({ id: selectedGroup?.id || -1 });
    }
  };

  useEffect(() => {
    if (isSuccessDelete) {
      handleShowModalDelete();
    }
  }, [isSuccessDelete]);

  const toggleFilterCollapse = () => {
    if (Object.keys(filterData).length > 0) {
      setFilterData({});
      dispatch(setGroupFilter({}));
    }
    const collapseSearch = document.getElementById('collapseSearch')!;
    const collapse = new bootstrap.Collapse(collapseSearch, { toggle: true });
    collapse.toggle();
  };

  const updateFilter = React.useCallback(
    (key: keyof GroupeFilterType, values?: string[] | null) => {
      let newFilter = { ...filterData };
      if (!values || values.length === 0) {
        delete newFilter[key];
      } else {
        let operator =
          key === 'ownership'
            ? '='
            : key === 'origin_country_id'
              ? '='
              : 'like';
        if (key === 'created_at') {
          operator = '<=';
        }
        newFilter = {
          ...newFilter,
          [key]: {
            property: key,
            value: values,
            operator,
          },
        };
      }
      dispatch(setGroupFilter(newFilter));
      setFilterData(newFilter);
    },
    [filterData]
  );

  const getFilterParams = React.useCallback((): FilterType[] => {
    const values = Object.values(filterData).map((e) => {
      if (e.property === 'created_at' || e.property === 'end_date') {
        return {
          ...e,
          property: 'created_at',
        };
      }
      return {
        ...e,
        value: e.value,
      };
    });
    return values;
  }, [filterData]);

  const getGroupCallback = () => {
    setParamsGroup({
      ...paramsGroup,
      page: 1,
      start: 0,
      limit: 20,
      filter: JSON.stringify(getFilterParams()),
    });
  };

  useEffect(() => {
    const params = getFilterParams();
    if (params.length === 0) {
      setParamsGroup({
        ...paramsGroup,
        page: 1,
        start: 0,
        limit: 20,
        filter: JSON.stringify(params),
      });
    }
  }, [filterData]);

  return {
    selectedGroup,
    modalConfirm,
    modalConfirmDelete,
    uncheckedId,
    responsePeople,
    responseCountry,
    isLoading,
    handleModalConfirm,
    handleShowModalDelete,
    handleTableChange,
    editGroup,
    deleteGroup,
    handleCreateGroupe,
    validDelete,
    data,
    isLoadingDelete,
    dataSource,
    columns,
    totalData,
    setPerPage,
    toggleFilterCollapse,
    updateFilter,
    getGroupCallback,
    filterData,
    paramsGroup,
    handleModal,
    show,
  };
}
