import { TablePaginationConfig } from 'antd';
import { People } from './people';

export type User = {
  is_inactif?: number;
  id: number;
  people_id: number;
  email: string;
  is_admin: number;
  rights: {
    [key: string]: {
      read: boolean;
      write: boolean;
      create: boolean;
      delete: boolean;
      extract: boolean;
    };
  };
  people: People;
  rights_doc: number;
  team: string;
  title: string;
  updated_at: string;
  invoice_raising: string;
  avatar: string;
  avatar_filename: string;
};

export type LoginResponse = {
  token: string;
  refreshToken: string;
  token_duration: number;
  creation_date: string;
  expiration_date: string;
  success: boolean;
  user: User;
};

export type PermissionType = {
  read: boolean;
  write: boolean;
  create: boolean;
  delete: boolean;
  extract: boolean;
};

export enum Menu {
  client = 'client',
  deal = 'deal',
  enquiry = 'enquiry',
  financier = 'financier',
  group = 'group',
  history = 'history',
  indication = 'indication',
  legal_entity = 'legal_entity',
  limit = 'limit',
  mandate = 'mandate',
  note = 'note',
  obligor = 'obligor',
  participation = 'participation',
  partner = 'partner',
  payment = 'payment',
  people = 'people',
  underwriter = 'underwriter',
  user = 'user',
  pipe = 'pipe',
  dashboard = 'dashboard',
}

export enum Method {
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  UPDATE = 'UPDATE',
  GET = 'GET',
}

export enum ContentType {
  JSON = 'application/json',
  FORM_DATA = 'multipart/form-data',
}

export type ErrorRespose = {
  data: {
    error: string;
    error_type: string;
    success: boolean;
  };
  status: number;
};

export interface AuthState {
  creation_date?: string;
  expiration_date?: string;
  success?: boolean;
  token: string | undefined;
  token_duration?: number;
  user: User | null;
}

export interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, any>;
}

export const options = [
  { value: 'Mandate type', label: 'Mandate type' },
  { value: 'Consultancy', label: 'Consultancy' },
  { value: 'Debt Arrangement', label: 'Debt Arrangement' },
  { value: 'Hedge Introduction', label: 'Hedge Introduction' },
  { value: 'Insurance Brokerage', label: 'Insurance Brokerage' },
];

export interface SelectOption {
  readonly value: string | number;
  readonly key: string | number;
}

export enum TermConst {
  activity = 'activity',
  activityType = 'activityType',
  agency = 'agency',
  attachmentType = 'attachmentType',
  brokerRole = 'brokerRole',
  businessSource = 'businessSource',
  channelType = 'channelType',
  clientStatus = 'clientStatus',
  currency = 'currency',
  dealBooking = 'dealBooking',
  dealBrokerRole = 'dealBrokerRole',
  dealCalculation = 'dealCalculation',
  dealMarket = 'dealMarket',
  dealRisk = 'dealRisk',
  dealRiskTrigger = 'dealRiskTrigger',
  dealRole = 'dealRole',
  dealStatus = 'dealStatus',
  dealStructure = 'dealStructure',
  dealType = 'dealType',
  enquiryOutcome = 'enquiryOutcome',
  enquiryStatus = 'enquiryStatus',
  entityCounterparty = 'entityCounterparty',
  equitySize = 'equitySize',
  feePeriod = 'feePeriod',
  feeType = 'feeType',
  indicationPrice = 'indicationPrice',
  indicationType = 'indicationType',
  limitNature = 'limitNature',
  limitStatus = 'limitStatus',
  mandatePhase = 'mandatePhase',
  mandateStatus = 'mandateStatus',
  mandateType = 'mandateType',
  market = 'market',
  marketCom = 'marketCom',
  noteStatus = 'noteStatus',
  noteType = 'noteType',
  ownership = 'ownership',
  participationStatus = 'participationStatus',
  partnerRole = 'partnerRole',
  premiumType = 'premiumType',
  projectType = 'projectType',
  staffTeam = 'staffTeam',
  staffTitle = 'staffTitle',
  underwriterType = 'underwriterType',
  validation = 'validation',
}

export type ParamsTable = {
  page: number | undefined;
  start: number | undefined;
  limit: number | undefined;
  sort: string;
  filter: string;
};

export type FunctionName =
  | 'mandate'
  | 'client'
  | 'legal_entity'
  | 'people'
  | 'partner'
  | 'cofarco_team'
  | 'obligor'
  | 'partner_legal_entity'
  | 'counterparty'
  | 'legal_entity_client_id'
  | 'top_obligor_entity';
