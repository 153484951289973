import { useCallback, useEffect, useMemo, useState } from 'react';
import { FaEye } from 'react-icons/fa';
import {
  Indication,
  IndicationAttachment,
  IndicationFormInput,
} from '../../../../@types/Indication/indication';
import { EntityEnquiries } from '../../../../@types/enquiries';
import {
  useListTermsQuery,
  useListUserQuery,
} from '../../../../redux/api/ws/terms/get';
import {
  useGetIndicationAttachmentQuery,
  useLazyGetCounterPartyQuery,
  useLazyGetEnquiryByIdQuery,
  useListEnquiryQuery,
} from '../../../../redux/api/ws/indication/get';
import { assignWith, isEqual, pickBy } from 'lodash';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { ColumnsType } from 'antd/es/table';
import { useLazyPeopleByIdQuery } from '../../../../redux/api/ws/databases/people';
import {
  useCreateIndicationMutation,
  useDeleteIndicationAttachmentMutation,
  useEditIndicationMutation,
  useUpdateIndicationAttachmentMutation,
  useUploadIndicationAttachmentMutation,
} from '../../../../redux/api/ws/indication/post';
import { updateNotificationMessage } from '../../../../redux/slice/notificationSlice';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import {
  formateSize,
  getDifferentKeys,
  isCan,
} from '../../../../utils/helper-function';
import { Menu } from '../../../../@types/common-types';
import { ReactComponent as Delete } from '../../../../assets/img/Delete.svg';
import classes from './IndicationModal.module.scss';
import { ConterParty } from '../../../../@types/conterParty';
import { People } from '../../../../@types/people';
import { useLazyLegalEntityByIdQuery } from '../../../../redux/api/ws/databases/legale-entities';
import { updateModalAction } from '../../../../redux/slice/modalSlice';
import { API_URL } from '../../../../data/config';
import { FormControl } from 'react-bootstrap';

type Props = {
  defaultFormValues?: Indication | null;
  onSubmittedForm?: () => void;
};

const useIndicationModal = ({ defaultFormValues, onSubmittedForm }: Props) => {
  const { data: terms } = useListTermsQuery(undefined, {
    refetchOnMountOrArgChange: false,
  });
  const [selectedEnquiry, setSelectedEnquiry] = useState<
    EntityEnquiries | undefined
  >();

  const defaultValues: IndicationFormInput = useMemo(
    () => ({
      indication_date: null,
      id: -1,
      enquiry_id: selectedEnquiry?.id ?? -1,
      underwriter_id: -1,
      underwriter_idKey: '',
      approached: 0,
      contact_id: null,
      contact_idKey: '',
      contacted_date: moment().format('YYYY-MM-DD'),
      channel: '',
      indication_type: '',
      amount: 0,
      currency:
        (terms?.term ?? []).find(
          (t) => t.key === selectedEnquiry?.individual_currency,
        )?.key ?? '',
      indication_price: '',
      comments: null,
    }),
    [terms, selectedEnquiry],
  );

  const {
    handleSubmit,
    register,
    watch: watchFormValue,
    reset: resetForm,
    setError: setFomError,
    clearErrors: clearFormErrors,
    setValue: setFormValue,
    getValues: getFormValue,
    formState: { errors, defaultValues: defaultValuesForm },
  } = useForm<IndicationFormInput>({
    defaultValues: defaultValues,
    // reValidateMode: "onChange",
  });
  const dispatch = useAppDispatch();

  const { data: users } = useListUserQuery(null);

  const [createIndication] = useCreateIndicationMutation();
  const [editIndication] = useEditIndicationMutation();
  const [addAttachment] = useUploadIndicationAttachmentMutation();
  const [addAttachmentDesc] = useUpdateIndicationAttachmentMutation();
  const [deleteAttachment] = useDeleteIndicationAttachmentMutation();
  const [getLegalEntity] = useLazyLegalEntityByIdQuery();

  const [modalDeleteAttach, setModalDeleteAttach] = useState<boolean>(false);
  const [selectedAttachment, setSelectedAttachment] = useState<
    IndicationAttachment | undefined
  >();
  const [enquiryState, setEnquiryState] = useState<
    EntityEnquiries | undefined
  >();

  const [isShowModalUnsavedChange, setIsShowModalUnsavedChange] =
    useState<boolean>(false);
  const [dataToShowOnOtherModal, setDataToShowOnOtherModal] = useState<{
    data: any;
    type: string;
  }>();

  const token = useAppSelector((state) => state.auth.token);

  const {
    data: attachments,
    error: attachmentError,
    isLoading: isLoadingAttachment,
    refetch: refetchAttachment,
  } = useGetIndicationAttachmentQuery({
    page: 1,
    start: 0,
    limit: 25,
    id: defaultFormValues ? defaultFormValues.id : -1,
  });

  const [enquiryQuery, setEnquiryQuery] = useState<string>('');
  const [getEnquiry] = useLazyGetEnquiryByIdQuery();

  const [forceDate, setForceDate] = useState<Date>(new Date());
  const filter = [{ property: 'status', operator: '=', value: `working` }];
  const {
    data: enquiries,
    isLoading,
    isFetching,
  } = useListEnquiryQuery({
    query: enquiryQuery,
    date: forceDate,
    filter: JSON.stringify(filter),
  });

  const [underwriter, setUnderWritter] = useState<ConterParty | undefined>();
  const [people, setPeople] = useState<People | undefined>();
  const [getUnderwriter] = useLazyGetCounterPartyQuery();
  const [getPeople] = useLazyPeopleByIdQuery();
  const underwriter_id = watchFormValue('underwriter_id');
  const contact_id = watchFormValue('contact_id');

  // const []

  useEffect(() => {
    if (underwriter_id > 0) {
      getUnderwriter({ id: underwriter_id }).then((res) => {
        if (res && res.data) {
          setUnderWritter(res.data?.counterparty);
        } else {
          setUnderWritter(undefined);
        }
      });
    } else {
      setUnderWritter(undefined);
    }
  }, [underwriter_id]);

  useEffect(() => {
    if (contact_id !== null && (contact_id as number) > 0) {
      getPeople({ id: contact_id }).then((res) => {
        if (res && res.data) {
          setPeople(res.data?.people);
        } else {
          setPeople(undefined);
        }
      });
    } else {
      setPeople(undefined);
    }
  }, [contact_id]);

  const formValues = useMemo(
    () =>
      defaultFormValues
        ? ({
            ...pickBy(defaultFormValues, (v, k) =>
              Object.keys(defaultValues).includes(k),
            ),
          } as IndicationFormInput)
        : defaultValues,
    [defaultFormValues, defaultValues],
  );

  useEffect(() => {
    resetForm(formValues);
  }, [formValues]);

  useEffect(() => {
    if (underwriter) {
      setFormValue('underwriter_idKey', underwriter.name);
    } else {
      setFormValue('underwriter_idKey', '');
    }
  }, [underwriter]);

  useEffect(() => {
    if (people) {
      setFormValue('contact_idKey', `${people.firstname} ${people.lastname}`);
    } else {
      setFormValue('contact_idKey', '');
    }
  }, [people]);

  useEffect(() => {
    refetchAttachment();
  }, [selectedEnquiry]);

  const saveIndication = useCallback(
    (data: any) => {
      if (formValues.id > 0) {
        const dataToedit = assignWith(
          {},
          pickBy(
            {
              ...data,
              // amount: remove separator thousand and convert to number
              amount: parseInt(data.amount?.toString()?.replaceAll("'", '')),
            },
            (v, k) =>
              Object.keys(formValues).includes(k) &&
              (formValues as Record<string, any>)[k] !== v &&
              !k.endsWith('Key'),
          ) as Record<string, any>,
          (_, value) => (typeof value == 'undefined' ? '' : value),
        );
        editIndication({ id: formValues.id, data: dataToedit })
          .then(() => {
            dispatch(
              updateNotificationMessage({
                show: true,
                title: 'Indication update',
                body: 'Successfully updated !',
              }),
            );
            if (onSubmittedForm) onSubmittedForm();
          })
          .finally(() => {
            handleClose();
            setSelectedEnquiry(undefined);
            setEnquiryState(undefined);
          });
      } else {
        const dataToInsert = assignWith(
          {},
          pickBy(
            {
              ...data,
              // amount: remove separator thousand and convert to number
              amount: parseInt(data.amount?.toString()?.replaceAll("'", '')),
            },
            (v, k) => Object.keys(formValues).includes(k) && !k.endsWith('Key'),
          ) as Record<string, any>,
          (_, value) => (typeof value == 'undefined' ? '' : value),
        );
        createIndication({ data: dataToInsert })
          .then(() => {
            dispatch(
              updateNotificationMessage({
                show: true,
                title: 'Indication add',
                body: 'Successfully added !',
              }),
            );
            if (onSubmittedForm) onSubmittedForm();
          })
          .finally(() => {
            handleClose();
            setSelectedEnquiry(undefined);
            setEnquiryState(undefined);
            handleClose();
          });
      }
    },
    [formValues],
  );

  const onSubmitForm = (e: any) => {
    e.preventDefault();
    handleSubmit(saveIndication, () => {})();
  };

  const onDeleteAttachment = () => {
    deleteAttachment({ id: selectedAttachment?.id ?? '' }).finally(() => {
      setModalDeleteAttach(false);
    });
  };

  const confirmDeleteAttachment = (a: IndicationAttachment) => {
    setSelectedAttachment(a);
    setModalDeleteAttach(true);
  };

  const [modalAttach, setModalAttach] = useState<{
    show: boolean;
    id?: number;
  }>({ show: false });

  const onAddAttachment = (id: string | number, file: FileList | null) => {
    if (file && file.length > 0) {
      addAttachment({ item: file[0], id })
        .then((res: any) => {
          if (res.data && res.data.attachment) {
            const att = res.data.attachment as IndicationAttachment;
            setModalAttach({ id: att.id, show: true });
          }
        })
        .finally(() => {});
    }
  };

  const onAddAttachmentDesc = (c: string) => {
    if (modalAttach.id && defaultFormValues) {
      addAttachmentDesc({
        attachmentId: modalAttach.id,
        id: defaultFormValues.id,
        data: { comments: c, id: modalAttach.id },
      }).then(() => {
        setModalAttach({ id: undefined, show: false });
        refetchAttachment();
      });
    }
  };

  const updateAttachmentDesc = (id: number, c: string) => {
    if (defaultFormValues) {
      addAttachmentDesc({
        attachmentId: id,
        id: defaultFormValues.id,
        data: { comments: c, id },
      }).then(() => {
        refetchAttachment();
      });
    }
  };
  const FormDescription = ({
    defaultValue,
    onChanged,
  }: {
    defaultValue: string;
    onChanged: (v: string) => void;
  }) => {
    const [val, setVal] = useState<string>(defaultValue);
    const [foc, setFoc] = useState<boolean>(false);
    return (
      <FormControl
        style={!foc ? { border: 'none' } : {}}
        value={val}
        onChange={(e) => setVal(e.target.value)}
        onBlur={() => {
          if (foc) {
            setFoc(false);
            onChanged(val);
          }
        }}
        onFocus={() => setFoc(true)}
      />
    );
  };
  // use effect

  const columnAttachments: ColumnsType<IndicationAttachment> = [
    {
      title: 'ATTACHMENT(S)',
      dataIndex: 'filename',
      key: 'filename',
      render: (text) => {
        return <div>{text}</div>;
      },
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'comments',
      key: 'comments',
      render: (text, record) => {
        return (
          <FormDescription
            defaultValue={text}
            onChanged={(v) => {
              if (v !== text) {
                updateAttachmentDesc(record.id, v);
              }
            }}
          />
        );
      },
    },
    {
      title: 'SIZE',
      dataIndex: 'size',
      key: 'size',
      render: (text) => {
        return <div>{formateSize(text)}</div>;
      },
    },
    {
      title: 'UPLOAD DATE',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (text) => {
        return <div>{moment(text).format('DD/MM/YYYY')}</div>;
      },
    },
    {
      title: 'UPLOADED BY',
      dataIndex: 'create_user_id',
      key: 'create_user_id',
      render: (text: number) => {
        return (
          <div>{(users?.user ?? []).find((u) => u.id === text)?.fullname}</div>
        );
      },
    },
    {
      title: '',
      key: 'action',
      dataIndex: 'id',
      width: 120,
      render(value, record) {
        return (
          <div className="d-flex">
            {isCan('read', Menu.indication) && (
              <span
                className={`mx-1 rounded-icon  ${classes.action} pt-1`}
                onClick={() => {
                  window.open(
                    `${API_URL}attachment/${record.id || value}?token=${token}`,
                    '_blank',
                  );
                }}
              >
                <FaEye size="25" />
              </span>
            )}
            {isCan('write', Menu.indication) && (
              <span
                className={`mx-1 rounded-icon  ${classes.action}`}
                // onClick={() => onE(record)}
              >
                {/* <Edit height="30" /> */}
              </span>
            )}
            {isCan('delete', Menu.indication) && (
              <span
                className={`mx-1 rounded-icon ${classes.action}`}
                onClick={() => confirmDeleteAttachment(record)}
              >
                <Delete height="30" />
              </span>
            )}
          </div>
        );
      },
    },
  ];

  const handleClose = () => {
    resetForm(defaultValues);
    dispatch(
      updateModalAction({
        data: undefined,
        isAdd: false,
        type: 'indication',
      }),
    );
  };

  const initialData = useMemo(() => {
    return {
      ...defaultValuesForm,
      underwriter_idKey: underwriter?.name ?? '',
      contact_idKey: `${people?.firstname} ${people?.lastname}` ?? '',
    };
  }, [defaultValuesForm, underwriter, people]);

  const isEdit = useMemo(() => {
    return Boolean(defaultFormValues?.id);
  }, [defaultFormValues]);

  const showOtherModal = (data: any, type: string, noCheck?: boolean) => {
    const haveChange = !isEqual(initialData, watchFormValue());

    if (haveChange && !noCheck && isEdit) {
      setIsShowModalUnsavedChange(true);
      setDataToShowOnOtherModal({ data, type });
      return;
    }
    setDataToShowOnOtherModal(undefined);
    setIsShowModalUnsavedChange(false);
    isEdit && resetForm(formValues);
    dispatch(
      updateModalAction({
        data: data,
        type: type,
        isAdd: true,
      }),
    );
  };

  return {
    terms: terms?.term ?? [],
    enquiries: enquiries?.enquiry ?? [],
    isEnquiryLoading: isFetching || isLoading,
    selectedEnquiry,
    columnAttachments,
    defaultValues: formValues,
    attachments:
      attachmentError || !attachments ? [] : attachments?.attachment ?? [],
    isLoadingAttachment,
    modalDeleteAttach,
    selectedAttachment,
    underwriter: underwriter,
    people: people,
    modalAttach,
    enquiryState,
    setEnquiryState,
    getLegalEntity,
    getCounterParty: getUnderwriter,
    setModalDeleteAttach,
    setSelectedEnquiry,
    setEnquiryQuery,
    setForceDate,
    getEnquiry,
    onSubmitForm,
    register,
    watchFormValue,
    resetForm,
    setFormValue,
    getFormValue,
    setFomError,
    handleClose,
    clearFormErrors,
    onAddAttachment,
    errors,
    onDeleteAttachment,
    onAddAttachmentDesc,
    showOtherModal,
    isShowModalUnsavedChange,
    dirtyFields: getDifferentKeys(initialData, watchFormValue()),
    setIsShowModalUnsavedChange,
    dataToShowOnOtherModal,
  };
};

export default useIndicationModal;
