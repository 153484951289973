import { useDispatch } from 'react-redux';
import { EntityGroupe, InputFieldsGroup } from '../@types/group/types';
import { People } from '../@types/people';
import { getDataService } from '../redux/api/axiosBaseQuery';
import { url } from '../redux/api/url';
import { useGetCountryQuery } from '../redux/api/ws/databases/groups';
import { hideLoader, showLoader } from '../redux/slice/loaderSlice';
import { concatString, isExist } from '../utils/helper-function';

export default function UseFetchPeople() {
  const { data: responseCountry } = useGetCountryQuery();
  const dispatch = useDispatch();

  async function getAllPeople(ids: Array<number>) {
    try {
      dispatch(showLoader());
      const results = await Promise.all(
        ids.map((el) => getDataService(url.people.list + '/' + el)),
      );
      dispatch(hideLoader());
      return results;
    } catch (err) {
      return [];
    }
  }

  const getP = (allP: Array<any>, peopleId: string | number | undefined) => {
    const found = allP?.find((el) => el?.people?.id === peopleId);
    if (found) {
      return {
        id: found.people?.id,
        name: concatString(
          found?.people?.firstname,
          found?.people?.lastname,
          ' ',
        ),
      };
    }
  };

  const fetchPeople = (
    group: EntityGroupe | undefined | null,
    callBack: (
      params: InputFieldsGroup | null,
      allPeople: Array<People>,
    ) => void,
  ) => {
    const selectedGroup = group;

    if (selectedGroup?.id) {
      const selectedCountry = responseCountry?.country.find(
        (ct) => ct.key === selectedGroup.origin_country_id,
      );
      const ultimates = [
        selectedGroup?.ultimate_beneficial_owner_1 as number,
        selectedGroup?.ultimate_beneficial_owner_2 as number,
        selectedGroup?.ultimate_beneficial_owner_3 as number,
        selectedGroup?.ultimate_beneficial_owner_4 as number,
      ];

      (async function () {
        try {
          const response = await getAllPeople(
            ultimates.filter((el) => isExist(el)),
          );
          const allPeople = response.filter((el) => {
            if (isExist(el)) {
              return el?.people;
            }
          });

          const getPeoples = allPeople?.map((el) => el.people);

          const params = {
            ...selectedGroup,
            id: selectedGroup.id,
            origin_country_id: selectedCountry ? selectedCountry.key : '',
            origin_country_name: selectedCountry ? selectedCountry.name : '',
            regulated: selectedGroup.regulated ? true : false,

            ultimate_beneficial_owner_1: getP(
              allPeople,
              selectedGroup.ultimate_beneficial_owner_1,
            )?.id,

            ultimate_beneficial_owner_1_name: getP(
              allPeople,
              selectedGroup.ultimate_beneficial_owner_1,
            )?.name,

            ultimate_beneficial_owner_2: getP(
              allPeople,
              selectedGroup.ultimate_beneficial_owner_2,
            )?.id,

            ultimate_beneficial_owner_2_name: getP(
              allPeople,
              selectedGroup.ultimate_beneficial_owner_2,
            )?.name,

            ultimate_beneficial_owner_3: getP(
              allPeople,
              selectedGroup.ultimate_beneficial_owner_3,
            )?.id,

            ultimate_beneficial_owner_3_name: getP(
              allPeople,
              selectedGroup.ultimate_beneficial_owner_3,
            )?.name,

            ultimate_beneficial_owner_4: getP(
              allPeople,
              selectedGroup.ultimate_beneficial_owner_4,
            )?.id,

            ultimate_beneficial_owner_4_name: getP(
              allPeople,
              selectedGroup.ultimate_beneficial_owner_4,
            )?.name,
          };

          callBack(params, getPeoples);
        } catch (e) {
          console.error(e);
        }
      })();
    }
  };
  return { fetchPeople };
}
