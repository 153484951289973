import FormModal from "../../Modal/FormModal/FormModal";
import UseModalMandate from "./UseModalMandate";
import {
  EntityMandate,
  InputFormMandate,
} from "../../../@types/mandates/mandates";
import Forms from "./Forms/Forms";
import DealLimitsList from "./DealLimitsList/DealLimitsList";
import HeaderMultiple from "../../HeaderTab/HeaderMultiple";
import EnquiriesList from "./EnquiryList/EnquiriesList";
import { isCan } from "../../../utils/helper-function";
import { Menu } from "../../../@types/common-types";

type IProps = {
  show: boolean;
  handleClose: (mandate: EntityMandate | null) => void;
  defaultFormValues: InputFormMandate | null;
};
export default function ModalMandate(props: IProps) {
  const provider = UseModalMandate({
    defaultFormValues: props.defaultFormValues,
    closingModal: props.handleClose,
  });
  const isHiddenTab =
    provider.watch("mandate_type") === "advisory" ||
    provider.watch("mandate_type") === "other";

  const menus = [
    {
      id: 1,
      title: "Mandate - " + provider.number,
      isHidden: false,
    },

    {
      id: 2,
      title: `Enquiry(ies) List - ${provider.getValues("number")}`,
      isHidden: isHiddenTab,
    },
    {
      id: 3,
      title: `Deal(s) List - ${provider.getValues("number")}`,
      isHidden: isHiddenTab,
    },
  ];

  return (
    <>
      <FormModal
        show={props.show}
        handleClose={() => {
          provider.resetForm();
          props.handleClose(null);
        }}
        isLoading={provider.isLoadingCreateMandate}
        onSubmitForm={provider.handleSubmit(provider.submit)}
        isDisabledSave={
          !provider.isDirty ||
          !provider.isCompletedForm ||
          provider.isLoadingCreateMandate ||
          provider.isLoadingUpdateMandate
        }
        ishiddenfooter={
          provider.activeMenu === 2 ||
          provider.activeMenu === 3 ||
          (!isCan("write", Menu.mandate) && provider.isEdit)
        }
        header={
          <HeaderMultiple
            activeMenu={provider.activeMenu}
            menus={menus?.filter((el) => !el.isHidden)}
            createTitle={"New - Mandate"}
            setActiveMenu={(p: number) => provider.handleChangeToogle(p)}
            isEdit={provider.isEdit}
          />
        }
        children={
          <>
            {provider.activeMenu === 1 && (
              <Forms
                register={provider.register}
                errors={provider.errors}
                watch={provider.watch}
                setValue={provider.setValue}
                clearFormErrors={provider.clearFormErrors}
                setFormError={provider.setFormError}
                runSearch={(key: keyof InputFormMandate, value: string) =>
                  provider.runSearch(key, value)
                }
                clientsList={provider.clientsList}
                control={provider.control}
                isLoading={provider.isLoading}
                isLoadingLegalEntity={provider.isLoadingLegalEntity}
                legalentity={provider.legalEntityOption?.legalentity || []}
                optionUserNameOwner={provider.optionUserNameOwner}
                partenerList={provider.partenerList}
                partenersPeople={provider.partenersPeople}
                peoples={provider.peoples}
                showOtherModal={provider.showOtherModal}
                provider={provider}
              />
            )}

            {provider.activeMenu === 2 && (
              <EnquiriesList selectedMandateId={provider.id} />
            )}
            {provider.activeMenu === 3 && (
              <DealLimitsList selectedMandateId={provider.id} />
            )}
          </>
        }
      />
    </>
  );
}
