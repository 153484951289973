import React, { useEffect, useState, createContext } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import ContentNavBar from "../Admin/ContentNavBar";
import counterpartyLogo from "../../assets/img/counterparties.svg";
import CounterPartyItems from "./CounterPartiesItems";

export const ContextCounterparty = createContext<any>({
  txt: null,
});

const CounterPartiesContent: React.FC = () => {
  const [activeMenu, setActiveMenu] = useState<number>(1);

  const [searchTxt, setSearchTxt] = useState<string | undefined | null>(
    undefined
  );

  const navigate = useNavigate();
  const location = useLocation();
  const navigateTo = (route: string, index: number) => {
    setSearchTxt(null);
    setActiveMenu(index);
    navigate(route);
  };

  useEffect(() => {
    if (location.pathname.endsWith("client")) {
      setActiveMenu(1);
    } else if (location.pathname.endsWith("financier")) {
      setActiveMenu(2);
    } else if (location.pathname.endsWith("underwriter")) {
      setActiveMenu(3);
    } else if (location.pathname.endsWith("partner")) {
      setActiveMenu(4);
    } else if (location.pathname.endsWith("obligor")) {
      setActiveMenu(5);
    }
  }, [location]);

  return (
    <>
      <ContentNavBar
        title="Counterparties"
        logo={counterpartyLogo}
        items={
          <CounterPartyItems activeMenu={activeMenu} onClickItem={navigateTo} />
        }
        setTxt={(txt: any) => setSearchTxt(txt)}
        txt={searchTxt}
      />
      <ContextCounterparty.Provider value={{ txt: searchTxt }}>
        <Outlet />
      </ContextCounterparty.Provider>
    </>
  );
};

export default CounterPartiesContent;
