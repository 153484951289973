import React, { useState } from 'react';

import { ReactComponent as Edit } from '../../assets/img/Edit.svg';
import { ReactComponent as Delete } from '../../assets/img/Delete.svg';

import { ReactComponent as ArrowDown } from '../../assets/img/Arrow-Down-Gray.svg';
import { ReactComponent as ArrowUP } from '../../assets/img/Arrow-UP-Gray.svg';
import FilterTab from '../../utils/filter-tab';
import type { ColumnType } from 'antd/es/table';
import {
  exportDateUi,
  exportedDropdownValue,
  amountFormat,
  handleRegion,
  isCan,
  limitedText,
} from '../../utils/helper-function';

import FlagItem from '../../components/FlagItem/FlagItem';
import UseConstante from '../../hooks/use-constante';
import { Menu, TermConst } from '../../@types/common-types';
import { DealsExport, EntityDeals } from '../../@types/deals/deals';
import { useGetCountryQuery } from '../../redux/api/ws/databases/groups';
import { EntityCountry } from '../../@types/group/types';
import { Cartridge } from '../common/status/Cartridge';

type DataIndex = keyof EntityDeals;

type IProps = {
  handleSorted: (key: string, ruls: 'ASC' | 'DESC') => void;
  editDeals: (id: number, data?: Partial<EntityDeals>) => void;
  handleDeleteDeals: (id: number, deal?: EntityDeals) => void;
  deals: Array<EntityDeals>;
};
export default function ColumnDeals(props: IProps) {
  const [dropDownFilterState, setDropDownFilterState] =
    useState<boolean>(false);
  const terms = UseConstante();

  const [uncheckedId, setUncheckedId] = useState<Array<number>>([]);
  const onChangeFilterColumn = (key: number | undefined) => {
    const found = uncheckedId?.find((uncheckedTitle) => uncheckedTitle === key);
    if (found) {
      setUncheckedId(uncheckedId.filter((t) => t !== key));
    } else {
      setUncheckedId([...uncheckedId, key || 0]);
    }
  };

  const { data: responseCountry } = useGetCountryQuery();

  const countries: Array<EntityCountry> = responseCountry?.country || [];

  const columnsExport: Array<keyof DealsExport> = [
    'number',
    'client_id',
    'client_legal_entity_id',
    'deal_type',
    'status',
    'obligor_id',
    'country_id',
    'lead_line_id',
    'amount',
    'currency',
    'expiry_date',
    'owner',
    'mandate_number',
    'mandate_name',
    'market',
    'risk_trigger',
    'structure',
    'partner_id',
    'partner_legal_entity_id',
    'partner_contact_id',
    'partner_reference',
    'booking',
    'inception_date',
    'non_renewal_notice_date',
    'insured',
    'insured_gross',
    'individual_limits',
    'deductible',
    'risk_mode',
    'calculation_basis',
    'premium_rate',
    'minimum_deposit',
    'owner_role',
    'partner_role',
    'gross_brokerage',
    'net_brokerage',
    'created_at',
    'updated_at',
  ];

  const getColumnSearchProps = (
    dataIndex:
      | DataIndex
      | 'commercial_name'
      | 'obligor_country_id'
      | 'lead_line_name'
      | 'clientId'
      | 'client_name'
      | 'dealType'
      | 'obligorId'
      | 'obligor_name'
      | 'expiryDate'
      | 'leadLineId'
      | 'country_name'
      | 'lead_line_name',
  ): ColumnType<EntityDeals> => ({
    filterDropdown: ({ close }) =>
      FilterTab<EntityDeals>({
        columns: columns,
        dataIndex: dataIndex,
        handleSorted: (dataIndex: string, rule: 'ASC' | 'DESC') =>
          props.handleSorted(dataIndex, rule),
        onChangeFilterColumn: (key: number | undefined) =>
          onChangeFilterColumn(key),
        uncheckedId: uncheckedId,
        close: () => close(),
      }),
    filterIcon: () => {
      return dropDownFilterState ? (
        <ArrowDown width={12} height={12} />
      ) : (
        <ArrowUP width={12} height={12} />
      );
    },
    onFilterDropdownOpenChange: (visible) => {
      setDropDownFilterState(visible);
    },
    render: (text: string) => <span>{text}</span>,
  });

  const getCartridgeStatus = (status: string) => {
    switch (status.trim().toLowerCase()) {
      case 'bound':
        return 'nbi';
      case 'binding':
        return 'pending';
      case 'ended':
        return 'nty';
      case 'aborted':
        return 'nr';
      default:
        return status;
    }
  };
  const columns: any = React.useMemo(
    () =>
      [
        {
          title: 'ID',
          dataIndex: 'number',
          key: 1,
          ...getColumnSearchProps('number'),
          width: 100,
          render: (text: string) => <span> {text}</span>,
        },
        {
          title: 'CLIENT',
          dataIndex: 'client_name',
          key: 2,
          ...getColumnSearchProps('client_name'),
          width: 150,
          render: (text: string, record: EntityDeals) => (
            <div
              style={{
                minWidth: '150px',
                textOverflow: 'clip',
                overflow: 'hidden',
              }}
            >
              <span> {limitedText(text ?? record.client, 17)}</span>
            </div>
          ),
        },
        {
          title: 'DEAL TYPE',
          dataIndex: 'deal_type',
          key: 3,
          width: 170,
          ...getColumnSearchProps('dealType'),
          render: (text: string) => {
            if (text) {
              return (
                <div
                  style={{
                    minWidth: '150px',
                    textOverflow: 'clip',
                    overflow: 'hidden',
                  }}
                >
                  <span className={`gray-rounded`}>
                    <b>
                      {limitedText(
                        exportedDropdownValue(
                          terms?.getConst(TermConst.dealType) || [],
                          text,
                        ),
                        17,
                      )}
                    </b>
                  </span>
                </div>
              );
            }
          },
        },
        {
          title: 'TOP OBLIGOR',
          dataIndex: 'commercial_name',
          key: 4,
          ...getColumnSearchProps('obligor_name'),
          width: 160,
          render: (text: string, record: Partial<EntityDeals>) => {
            if (record.obligor?.name) {
              return (
                <div
                  style={{
                    minWidth: '160px',
                    textOverflow: 'clip',
                    overflow: 'hidden',
                  }}
                >
                  <span>{limitedText(record.obligor?.name, 17)}</span>
                </div>
              );
            }
          },
        },
        {
          title: 'TOP OBLIGOR COUNTRY',
          dataIndex: 'obligor_country_id',
          key: 5,
          ellipsis: true,
          ...getColumnSearchProps('country_name'),
          width: 150,
          render: (text: string, record: Partial<EntityDeals>) => {
            if (record.top_obligor_country_id) {
              const region = handleRegion(
                countries,
                record.top_obligor_country_id,
              );
              return (
                region && (
                  <div
                    style={{
                      minWidth: '150px',
                      textOverflow: 'clip',
                      overflow: 'hidden',
                    }}
                  >
                    <FlagItem
                      code={region.code}
                      region_name={limitedText(region?.name, 17)}
                    />
                  </div>
                )
              );
            }
          },
        },
        {
          title: 'LEAD FINANCIER / UNDERWRITER',
          dataIndex: 'lead_line_name',
          key: 6,
          ...getColumnSearchProps('lead_line_name'),
          width: 150,
          ellipsis: true,
          render: (text: string, record: Partial<EntityDeals>) => {
            if (record.lead_line?.name) {
              return (
                <div
                  style={{
                    minWidth: '150px',
                    textOverflow: 'clip',
                    overflow: 'hidden',
                  }}
                >
                  <span>{limitedText(record.lead_line?.name, 17)}</span>
                </div>
              );
            }
          },
        },
        {
          title: 'STATUS',
          dataIndex: 'status',
          key: 7,
          width: 120,
          ...getColumnSearchProps('status'),
          ellipsis: true,
          render: (text: string) => {
            if (text) {
              return (
                <Cartridge
                  label={exportedDropdownValue(
                    terms?.getConst(TermConst.dealStatus) || [],
                    text,
                  )}
                  value={getCartridgeStatus(text)}
                />
              );
            }
          },
        },
        {
          title: 'AMOUNT',
          dataIndex: 'amount',
          key: 8,
          ...getColumnSearchProps('amount'),
          width: 150,
          ellipsis: true,
          render: (text: number) => (
            <div
              style={{
                minWidth: '150px',
                textOverflow: 'clip',
                overflow: 'hidden',
              }}
            >
              <span>{amountFormat(text?.toFixed(2))}</span>
            </div>
          ),
        },
        {
          title: 'CURRENCY',
          dataIndex: 'currency',
          key: 9,
          ellipsis: true,
          width: 100,
          ...getColumnSearchProps('currency'),
          align: 'center',
          render: (text: string) => (
            <div style={{ minWidth: '50px' }}>
              <span>{text?.toUpperCase()}</span>
            </div>
          ),
        },
        {
          title: 'EXPIRY',
          dataIndex: 'expiry_date',
          key: 10,
          width: 120,
          ellipsis: true,
          ...getColumnSearchProps('expiryDate'),
          render: (text: string) => (
            <span>{exportDateUi(text)?.toString()}</span>
          ),
        },
        {
          title: '',
          dataIndex: 'id',
          key: 11,
          width: 120,
          onCell: () => {
            return {
              onClick: (event: any) => {
                event.stopPropagation();
              },
            };
          },
          hidden: !isCan('write', Menu.deal) && !isCan('delete', Menu.deal),
          render: (text: number, record: EntityDeals) => {
            return (
              <>
                {isCan('write', Menu.deal) && (
                  <span>
                    <i
                      className="rounded-icon"
                      onClick={() => props.editDeals(text, record)}
                    >
                      <Edit height="30" />
                    </i>
                  </span>
                )}

                {isCan('delete', Menu.deal) && (
                  <span>
                    <i
                      className="rounded-icon"
                      onClick={() => props.handleDeleteDeals(text, record)}
                    >
                      <Delete height="30" />
                    </i>
                  </span>
                )}
              </>
            );
          },
        },
      ].filter((el) => !el.hidden),
    [props.deals, dropDownFilterState, uncheckedId, terms],
  );

  return { columns, uncheckedId, columnsExport };
}
