import { NavLink } from "react-router-dom";
import { isCanReadMenu } from "../../utils/helper-function";
import { Menu } from "../../@types/common-types";

type Props = {
  activeMenu: number;
  onClickItem: (route: string, index: number) => void;
};

const SegregatedItems = ({ activeMenu, onClickItem }: Props) => {
  return (
    <ul>
      {isCanReadMenu(Menu.note) && (
        <li onClick={() => onClickItem("/segregated-accounts/notes", 1)}>
          <NavLink
            className={`btn-nav btn primary  ${
              activeMenu === 1 ? "active-link" : ""
            }`}
            to="#"
          >
            <span className="icon-notes"></span>Notes
          </NavLink>
        </li>
      )}

      {isCanReadMenu(Menu.payment) && (
        <li onClick={() => onClickItem("/segregated-accounts/payments", 2)}>
          <NavLink
            className={`btn-nav btn primary  ${
              activeMenu === 2 ? "active-link" : ""
            }`}
            to="#"
          >
            <span className="icon-payements"></span>Payments
          </NavLink>
        </li>
      )}
    </ul>
  );
};

export default SegregatedItems;
