import React, { useRef, useState, useEffect, useMemo } from 'react';
import { Button, Container } from 'react-bootstrap';
import classes from './ChildrenMarket.module.scss';
import { ReactComponent as Word } from '../../../../assets/img/Word.svg';
import { ReactComponent as Pdf } from '../../../../assets/img/Pdf.svg';
import useColumn from './ColumnMarket';
import {
  useDeleteIndicationInEnquiryMutation,
  useExportIndicationByEnquiryMutation,
  useGetIndicationByIdQuery,
  useUpdateIndicationByEnquiryIdMutation,
} from '../../../../redux/api/ws/enquiries/enquiries';

import {
  DataTypeMarket,
  EditableCellProps,
} from '../../../../@types/enquiries';
import { Form as FormAnt, Table } from 'antd';
import type { FormInstance } from 'antd/es/form';
import SelectDropDown from '../../../common/DropDown/SelectDropDown';
import {
  amountFormat,
  concatString,
  downloadCsv,
  exportDateWs,
  exportedDropdownValue,
  filterColumn,
  isCan,
  isExist,
  makeOptions,
  titleRequestError,
} from '../../../../utils/helper-function';
import DatePicker from 'react-datepicker';
import { Form as FormBT } from 'react-bootstrap';
import { getPeopleByCounterParty } from '../../../../redux/api/axiosBaseQuery';
import { url } from '../../../../redux/api/url';
import UseConstante from '../../../../hooks/use-constante';
import { Menu, TermConst } from '../../../../@types/common-types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { updateModalAction } from '../../../../redux/slice/modalSlice';
import { useAppDispatch } from '../../../../hooks/redux';
import { ReactComponent as Add } from '../../../../assets/img/add.svg';
import DeleteModal from '../../../Modal/DeleteModal';
import { Indication } from '../../../../@types/Indication/indication';
import { updateNotificationMessage } from '../../../../redux/slice/notificationSlice';
import HeaderCalendar from '../../../Calendar/HeaderCalendar';
import { API_URL } from '../../../../data/config';
import { cloneDeep, isEmpty } from 'lodash';
import ClickAwayListener from 'react-click-away-listener';

type IProps = {
  id: number;
  addMarket: () => void;
  addEnquiry: () => void;
  individual_limits?: number;
};

const EditableCell: React.FC<EditableCellProps> = ({
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  isOnEdit,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const [contactList, setContactList] = React.useState<Array<any>>([]);
  const [lineValue, setLineValue] = useState<number | null>(null);
  const [priceValue, setPriceValue] = useState<string | null>(null);
  const [commentValue, setCommentValue] = useState<string | null>(null);

  const lineRef = useRef<any>(null);
  const priceRef = useRef<any>(null);
  const commentRef = useRef<any>(null);
  const terms = UseConstante();

  const handePeople = async (key: number) => {
    try {
      const response = await getPeopleByCounterParty(url.people.list, key);
      setEditing(true);
      const updatedName = (response.people || []).map((el: any) => ({
        ...el,
        userName: concatString(el?.firstname, el?.lastname),
      }));
      setContactList(updatedName || []);
    } catch (error) {
      console.log(error);
    }
  };

  const toggleEdit = async (dataIndex: any) => {
    const isCompletedRequired =
      isExist(record.contact) &&
      isExist(record.contacted_date) &&
      isExist(record.channel)
        ? true
        : false;
    const isAcceptedIndicationType =
      record.indication_type !== 'pending' && record.indication_type !== 'nr';

    const isAcceptedLineStatus = record.indication_type !== 'nty';

    const isVerifiedCompleted =
      dataIndex === 'indication_type' ||
      (dataIndex === 'indication_date' && isAcceptedIndicationType) ||
      (dataIndex === 'amount' &&
        isAcceptedIndicationType &&
        isAcceptedLineStatus) ||
      (dataIndex === 'indication_price' && isAcceptedIndicationType) ||
      (dataIndex === 'comments' && isAcceptedIndicationType);

    const isAcceptedEditting =
      (isCompletedRequired && isVerifiedCompleted) ||
      dataIndex === 'contact' ||
      dataIndex === 'contacted_date' ||
      dataIndex === 'channel';

    const isHandleEditing = dataIndex !== 'underwriter' && dataIndex !== 'id';
    if (isHandleEditing && isAcceptedEditting) {
      setEditing(true);
      if (dataIndex === 'contact') {
        handePeople(record.underwriter_id);
      }
    }
  };

  useEffect(() => {
    if (editing && dataIndex === 'amount') {
      lineRef.current!.focus();
    }
    if (editing && dataIndex === 'indication_price') {
      priceRef.current!.focus();
    }
  }, [editing]);

  const save = async (key: string, value: any) => {
    try {
      if (value === -1) {
        setEditing(false);
        return;
      }
      setEditing(!editing);

      if (key === 'contact') {
        const newValue = {
          ...record,
          contact: concatString(value?.firstname || '', value?.lastname || ''),
          contact_id: value.id,
        };
        handleSave(newValue, 'contact_id');
        return;
      }
      if (key === 'contacted_date') {
        const newValue = {
          ...record,
          contacted_date: exportDateWs(value),
        };
        handleSave(newValue, key);
        return;
      }

      if (key === 'indication_date') {
        const newValue = {
          ...record,
          indication_date: exportDateWs(value),
        };
        handleSave(newValue, key);
        return;
      }

      if (key === 'indication_type') {
        const newValue = {
          ...record,
          indication_type: value.key,
        };
        handleSave(newValue, key);
        return;
      }

      if (key === 'channel') {
        const newValue = {
          ...record,
          channel: value.key,
        };
        handleSave(newValue, key);
        return;
      }
      if (key === 'amount') {
        const newValue = {
          ...record,
          amount: value,
        };
        handleSave(newValue, key);
        return;
      }
      if (key === 'indication_price') {
        const newValue = {
          ...record,
          indication_price: value,
        };
        handleSave(newValue, key);
        return;
      }
      if (key === 'comments') {
        const newValue = {
          ...record,
          comments: value || '',
        };

        handleSave(newValue, key);
        return;
      }
    } catch (errInfo) {
      console.log(errInfo);
    }
  };

  return (
    <td {...(restProps as any)}>
      {editable ? (
        editing && isOnEdit ? (
          <>
            {dataIndex === 'contact' && (
              <SelectDropDown
                modalId="modal-group"
                preValue={exportedDropdownValue(
                  makeOptions(contactList, 'userName', 'id'),
                  record.contact_id,
                )}
                noSearch
                //notDeletable
                onSelectOption={(e) => save(dataIndex, e)}
                loading={false}
                options={makeOptions(contactList, 'userName', 'id')}
                onSearch={() => handePeople(record.underwriter.id)}
              />
            )}
            {dataIndex === 'indication_type' && (
              <SelectDropDown
                modalId="modal-group"
                preValue={exportedDropdownValue(
                  makeOptions(terms?.getConst(TermConst.indicationType)),
                  record.indication_type,
                )}
                noSearch
                notDeletable
                onSelectOption={(e) => save(dataIndex, e)}
                loading={false}
                options={makeOptions(terms?.getConst(TermConst.indicationType))}
              />
            )}
            {dataIndex === 'channel' && (
              <SelectDropDown
                modalId="modal-group"
                preValue={exportedDropdownValue(
                  makeOptions(terms?.getConst(TermConst.channelType)),
                  record.channel,
                )}
                noSearch
                notDeletable
                onSelectOption={(e) => save(dataIndex, e)}
                loading={false}
                options={makeOptions(terms?.getConst(TermConst.channelType))}
              />
            )}
            {dataIndex === 'contacted_date' ||
            dataIndex === 'indication_date' ? (
              <DatePicker
                className="form-control"
                dateFormat="dd/MM/yyyy"
                popperPlacement="bottom-end"
                popperProps={{
                  strategy: 'fixed',
                }}
                renderCustomHeader={({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) =>
                  HeaderCalendar({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  })
                }
                placeholderText="Select a Date"
                autoComplete="off"
                onBlur={() => save(dataIndex, -1)}
                onChange={(date) => save(dataIndex, date)}
                selected={
                  dataIndex === 'contacted_date' && record.contacted_date
                    ? new Date(record.contacted_date)
                    : dataIndex === 'contacted_date' &&
                        !isExist(record.contacted_date)
                      ? null
                      : dataIndex === 'indication_date' &&
                          record.indication_date
                        ? new Date(record.indication_date)
                        : new Date()
                }
              />
            ) : (
              <></>
            )}
            {dataIndex === 'amount' && (
              <FormBT.Control
                type="text"
                ref={lineRef}
                style={{ minWidth: 100 }}
                value={lineValue || ''}
                onFocus={() => {
                  setLineValue(parseInt(record.amount.toString()));
                }}
                onChange={(evt) => {
                  const value: any = evt.target.value;
                  const parsedValue = value
                    .replace(/[^0-9.]/g, '')
                    .replace('.', '')
                    .replace(/\./g, '')
                    .replace('x', '.');
                  setLineValue(parsedValue);
                }}
                onBlur={() => save(dataIndex, lineValue?.toString())}
              />
            )}
            {dataIndex === 'indication_price' && (
              <FormBT.Control
                type="text"
                ref={priceRef}
                style={{ minWidth: 100 }}
                value={priceValue || ''}
                onFocus={() => {
                  setPriceValue(record.indication_price);
                }}
                onChange={(evt) => {
                  setPriceValue(evt.target.value);
                }}
                onBlur={() =>
                  save(dataIndex, priceValue || record.indication_price)
                }
              />
            )}
            {dataIndex === 'comments' && (
              <FormBT.Control
                as="textarea"
                value={(commentValue as string) || ''}
                ref={commentRef}
                onFocus={() => {
                  setCommentValue(record.comments);
                }}
                onChange={(evt) => {
                  setCommentValue(evt.target.value || '');
                }}
                onBlur={() => save(dataIndex, commentValue || '')}
              />
            )}
          </>
        ) : (
          <div
            className="editable-cell-value-wrap"
            onClick={() => toggleEdit(dataIndex)}
          >
            {children}
          </div>
        )
      ) : (
        children
      )}
    </td>
  );
};

export default function ChildrenMarket(props: IProps) {
  const [marketList, setMarketList] = React.useState<Array<DataTypeMarket>>([]);
  const [modalConfirmDelete, setModalConfirmDelete] = useState(false);
  const [selectedIndication, setSelectedIndication] =
    useState<Indication | null>(null);
  const [paramsFilter, setParamsFilter] = useState<any>({
    sort: undefined,
    filter: undefined,
  });

  const [params, setParams] = useState<any>({}); // for filter
  const [updateMarket, { isLoading: isUpddateMarkeLoading }] =
    useUpdateIndicationByEnquiryIdMutation();
  const [exportIndication] = useExportIndicationByEnquiryMutation();
  const [
    deleteEndication,
    {
      isLoading: isLoadingDelete,
      isSuccess: isSuccessDelete,
      isError: isErrorDelete,
      error: errorResponse,
    },
  ] = useDeleteIndicationInEnquiryMutation();
  const dispatch = useAppDispatch();
  const {
    data,
    isSuccess,
    isLoading: isLoadingGetIndication,
  } = useGetIndicationByIdQuery({ id: props.id, ...paramsFilter });

  const auth = useSelector((state: RootState) => state.auth);

  const contacted = data?.indication?.filter((el) => el.approached)?.length;
  const nbi = marketList?.filter((el) => el.indication_type === 'nbi')?.length;
  const eoi = marketList?.filter((el) => el.indication_type === 'eoi')?.length;
  const nty = marketList?.filter((el) => el.indication_type === 'nty')?.length;
  const nr = marketList?.filter((el) => el.indication_type === 'nr')?.length;
  const pending = marketList?.filter(
    (el) => el.indication_type === 'pending',
  )?.length;

  const offered = marketList
    ?.filter((el) => el.indication_type === 'nbi')
    ?.reduce((acc, { amount }) => acc + amount, 0);

  const validDelete = () => {
    if (selectedIndication?.id) {
      deleteEndication({ id: selectedIndication?.id });
    }
  };

  React.useEffect(() => {
    if (isSuccessDelete) {
      handleShowModalDelete();
    }
  }, [isSuccessDelete]);

  React.useEffect(() => {
    if (isErrorDelete) {
      handleShowModalDelete();
      dispatch(
        updateNotificationMessage({
          show: true,
          title: titleRequestError,
          body: errorResponse?.data,
        }),
      );
    }
  }, [isErrorDelete]);

  const EditableContext = React.createContext<FormInstance<any> | null>(null);

  const handleSorted = (key: string, ruls: 'ASC' | 'DESC') => {
    const handlelFilter = JSON.stringify([{ property: key, direction: ruls }]);
    setParamsFilter({
      ...paramsFilter,
      sort: handlelFilter,
      filter: undefined,
    });
  };

  const handleChecked = (rec: Partial<DataTypeMarket>) => {
    if (rec.id) {
      updateMarket({
        id: rec.id,
        enquiryId: props.id,
        approached: rec.approached === 1 ? true : false,
      });
    }
  };

  const handleDelete = (p: number) => {
    const found: any = data?.indication?.find((el) => el.id === p);
    if (found) {
      setSelectedIndication(found);
      handleShowModalDelete();
    }
  };

  const viewMarket = (p: number) => {
    const found = data?.indication?.find((el) => el.id === p);
    if (found) {
      dispatch(
        updateModalAction({
          data: found,
          isAdd: true,
          type: 'indication',
        }),
      );
    }
  };

  const { columns, uncheckedId } = useColumn({
    handleSorted: handleSorted,
    indication: marketList,
    handleChecked: handleChecked,
    handleDelete: handleDelete,
    viewMarket: viewMarket,
  });

  const EditableRow: React.FC<any> = ({ ...props }) => {
    const [form] = FormAnt.useForm();
    return (
      <FormAnt form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </FormAnt>
    );
  };

  const handleShowModalDelete = () => {
    setModalConfirmDelete(!modalConfirmDelete);
  };

  const handleSave = (row: DataTypeMarket, key: string) => {
    const newData = [...marketList];
    const index = newData.findIndex((item) => item.id === row.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    if (row.id && props.id) {
      const isUpdated =
        key === 'contact_id' ||
        key === 'contacted_date' ||
        key === 'indication_date' ||
        key === 'indication_type' ||
        key === 'channel' ||
        key === 'indication_price' ||
        key === 'amount' ||
        key === 'comments';

      if (isUpdated) {
        const tempCompletedDataItem = { ...item, [key]: row[key] };

        const isCompletedRequiredItem =
          tempCompletedDataItem?.channel !== '' &&
          tempCompletedDataItem?.contact_id !== null &&
          tempCompletedDataItem?.contacted_date !== null;

        const paramsToSave = {
          id: row.id,
          enquiryId: props.id,
          approached: isCompletedRequiredItem ? true : false,
          contacted_date: tempCompletedDataItem.contacted_date,
          channel: tempCompletedDataItem.channel,
          contact_id: tempCompletedDataItem.contact_id,
          indication_type:
            !isExist(tempCompletedDataItem?.indication_type) &&
            isCompletedRequiredItem
              ? 'pending'
              : tempCompletedDataItem?.indication_type,
          [key]: row[key],
        };

        if (isCompletedRequiredItem) {
          setParams(paramsToSave);
        }
      }
    }
    setMarketList(newData);
  };

  const [onEditRow, setOnEditRow] = useState<number | null>(null);

  const handleColumns = useMemo(() => {
    return columns.map((col: any) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record: DataTypeMarket, index: number) => ({
          index,
          record,
          handleSave,
          title: col.title,
          dataIndex: col.dataIndex,
          editable: col.editable,
          isOnEdit: index === onEditRow,
          style: col.title === 'contacted_date' ? { overflowX: 'visible' } : {},
        }),
      };
    });
  }, [columns, onEditRow, handleSave]);

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const exportExel = React.useCallback(async () => {
    const keys = [
      'number',
      'underwriter_id',
      'contact_id',
      'contacted_date',
      'channel',
      'indication_type',
      'indication_date',
      'amount',
      'indication_price',
      'comments',
    ];
    const res = await exportIndication({
      enquiryId: props.id,
      fields: keys,
    });
    if (res) {
      const rep = res as any;
      downloadCsv(rep.data, 'indications.csv');
    }
  }, [props.id]);

  const exportPdf = () => {
    const token = auth.token;
    if (props.id) {
      window.open(
        `${API_URL}export/pdf/indication/${props.id}?token=${token}`,
        '_blank',
      );
    }
  };

  useEffect(() => {
    if (isSuccess) {
      const resp = data?.indication?.map((el) => ({
        id: el.id,
        underwriter: el.underwriter?.name,
        contact: concatString(
          el?.contact?.firstname || '',
          el?.contact?.lastname || '',
        ),
        contact_id: el.contact_id,
        contacted_date: el.contacted_date,
        channel: el.channel,
        indication_type: el.indication_type,
        indication_date: el.indication_date,
        amount: el.amount,
        indication_price: el.indication_price,
        comments: el.comments,
        underwriter_id: el.underwriter_id,
        approached: el.approached,
        created_at: el.updated_at,
      }));
      resp.sort(function (a, b) {
        return b.approached - a.approached;
      });
      setMarketList(resp);
    }
  }, [isSuccess, data]);

  const lastAddedMarketByCreatedAt = useMemo(() => {
    const lastAddedMarket = cloneDeep(marketList)?.sort((a, b) => {
      if (a.created_at > b.created_at) return -1;
      if (a.created_at < b.created_at) return 1;
      return 0;
    })[0];

    return lastAddedMarket;
  }, [marketList]);

  const paginationOptions = {
    pageSize: 25,
  };

  return (
    <Container className="mt-1">
      <div className="d-flex align-items-baseline">
        <div>
          <h4 className="title ">
            Capacity offered&nbsp;:
            <span className={`px-2`}>{amountFormat(offered || 0)}</span>
          </h4>
        </div>
        <div>
          <h4 className="title ">
            Capacity Required&nbsp;:
            <span className={`px-2`}>{props.individual_limits}</span>
          </h4>
        </div>
      </div>
      <div
        className="d-flex align-items-baseline flex-row w-100  py-3"
        style={{ justifyContent: 'space-between' }}
      >
        <span className={classes.btnGrayFounced}>
          <b>Contacted</b>
          <span className={classes.roundedTxt}>{contacted}</span>
        </span>

        <span className={classes.btnOrange}>
          <b>Non Binding Indication</b>
          <span className={classes.roundedTxt}>{nbi}</span>
        </span>

        <span className={classes.btnFlour}>
          <b>Expression Of Interest </b>
          <span className={classes.roundedTxt2}>{eoi}</span>
        </span>

        <span className={classes.btnGray}>
          <b>No Thank You</b>
          <span className={classes.roundedTxt3}>{nty}</span>
        </span>

        <span className={classes.btnBorderGray}>
          <b>Not Responded</b>
          <span className={classes.roundedTxt3}>{nr}</span>
        </span>

        <span className={classes.pendingBtn}>
          <b>Pending</b>
          <span className={classes.pendingTxt}>{pending}</span>
        </span>
        <div>
          <span
            style={{ cursor: 'pointer', marginRight: 5 }}
            onClick={() => exportPdf()}
          >
            <Pdf />
          </span>
          <span style={{ cursor: 'pointer' }} onClick={() => exportExel()}>
            <Word />
          </span>
        </div>
      </div>
      <div
        className="content-table"
        style={{ overflow: 'scroll', height: '65vh' }}
      >
        <ClickAwayListener
          onClickAway={(e) => {
            if (!isEmpty(params)) {
              updateMarket({ ...params }).then(() => {
                setParams({});
              });
            }
            if (e?.cancelable) {
              setParams({});
            }
          }}
        >
          <div>
            <Table
              dataSource={marketList}
              columns={filterColumn<DataTypeMarket>(handleColumns, uncheckedId)}
              rowClassName={(_, index) => {
                const isTheLastAdded =
                  lastAddedMarketByCreatedAt?.created_at === _.created_at;
                const isAfterOne90Sec =
                  new Date().getTime() - new Date(_.created_at).getTime() <
                  90000;
                return `editable-row  ${classes.tablerow} ${
                  isTheLastAdded && isAfterOne90Sec ? classes.tableNew : ''
                }
               ${index % 2 === 1 ? classes.tablelight : classes.tableWhite}`;
              }}
              loading={
                isLoadingGetIndication ||
                isLoadingDelete ||
                isUpddateMarkeLoading
              }
              components={components}
              rowKey="id"
              //pagination={true}
              pagination={paginationOptions}
              onRow={(_, index) => {
                return {
                  onMouseEnter: () => {
                    setOnEditRow(index ?? 0);
                  },
                };
              }}
            />
          </div>
        </ClickAwayListener>
      </div>
      {isCan('create', Menu.indication) && (
        <div className="d-flex">
          <Button
            className="btn red mt-3 py-2 "
            onClick={() => {
              props.addEnquiry();
            }}
          >
            <Add />
            <span className="ms-1">Add Panel</span>
          </Button>

          <Button
            className="btn red mt-3 py-2 mx-3"
            onClick={() => props.addMarket()}
          >
            <Add />
            <span className="ms-1">Add Market</span>
          </Button>
        </div>
      )}

      <DeleteModal
        handleClose={handleShowModalDelete}
        show={modalConfirmDelete}
        validDelete={() => validDelete()}
        groupeName={selectedIndication?.underwriter?.name || ''}
        isLoadingDelete={isLoadingDelete}
        title="Delete Indication"
      />
    </Container>
  );
}
