import { HTMLAttributes } from 'react';
import classes from './CustomCheckBox.module.scss';

type Props = {
  value: any;
  label: any;
  selected: any[];
  onSelectElement: (check: boolean, e: any) => void;
} & HTMLAttributes<HTMLDivElement>;

const CustomCheckBox = ({
  value,
  selected,
  label,
  onSelectElement,
  ...props
}: Props) => {
  return (
    <div
      className={`d-flex mx-2 px-4 pt-1 ${classes.pointer} ${
        classes[selected.includes(value) ? `${value}Checked` : value]
      }  ${props.className}`}
      key={`${new Date().toDateString()}`}
      onClick={() => onSelectElement(!selected.includes(value), value)}
    >
      <div className='d-block p-auto m-auto'>{label}</div>
    </div>
  );
};

export default CustomCheckBox;
