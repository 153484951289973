import moment from 'moment';
import { ReactComponent as Delete } from '../../../../assets/img/Delete.svg';

import React from 'react';
import { EntityAttachementDeals } from '../../../../@types/deals/deals';
import { useListUserQuery } from '../../../../redux/api/ws/terms/get';
import { ReactComponent as Eye } from '../../../../assets/img/Eye.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { formateSize, limitedText } from '../../../../utils/helper-function';
import { config } from '../../../../data/config';
import UseConstante from '../../../../hooks/use-constante';
import { TermConst } from '../../../../@types/common-types';

type IProps = {
  handleDeleteAtt: (item: EntityAttachementDeals) => void;
};
export default function ColumnUpload(props: IProps) {
  const { data: users } = useListUserQuery(null);
  const auth = useSelector((state: RootState) => state.auth);
  const terms = UseConstante();
  const typeOption = terms?.getConst(TermConst.attachmentType) || [];

  const exportPdf = (id: number) => {
    const token = auth.token;
    if (id) {
      const subUrl = config.baseUrl + 'attachment/' + id + '?token=' + token;
      window.open(subUrl, '_blank');
    }
  };

  const columnAttachments: any = React.useMemo(
    () => [
      {
        title: 'ATTACHMENT(S)',
        dataIndex: 'filename',
        key: 'filename',
        editable: false,
        render: (text: string) => {
          return (
            <div>
              <b> {text}</b>
            </div>
          );
        },
      },
      {
        title: 'TYPE',
        dataIndex: 'attachment_type',
        key: 'attachment_type',
        editable: true,
        render: (text: string) => {
          if (text) {
            return (
              <div style={{ width: '100px' }}>
                <b>
                  {typeOption?.find((el: any) => el.key === text)?.value || '-'}
                </b>
              </div>
            );
          }
          return <b>-</b>;
        },
      },
      {
        title: 'COMMENTS',
        dataIndex: 'comments',
        key: 'comments',
        editable: true,
        render: (text: string) => {
          return (
            <div>
              <b> {limitedText(text, 38) || '-'}</b>
            </div>
          );
        },
      },
      {
        title: 'SIZE',
        dataIndex: 'size',
        key: 'size',
        editable: false,

        render: (text: string) => {
          return (
            <div style={{ fontWeight: 'bolder' }}>
              {formateSize(parseFloat(text))}
            </div>
          );
        },
      },
      {
        title: 'UPLOAD DATE',
        dataIndex: 'updated_at',
        editable: false,

        key: 'updated_at',
        render: (text: string) => {
          return (
            <div>
              <b>{moment(text).format('DD/MM/YYYY')}</b>{' '}
            </div>
          );
        },
      },
      {
        title: 'UPLOADED BY',
        dataIndex: 'create_user_id',
        key: 'create_user_id',
        editable: false,

        render: (text: string) => {
          return (
            <div>
              <b>
                {(users?.user ?? []).find((u: any) => u.id === text)?.fullname}
              </b>
            </div>
          );
        },
      },
      {
        title: '',
        key: 'action',
        dataIndex: 'id',
        width: 120,
        editable: false,
        render(id: string, record: any) {
          return (
            <>
              <span>
                <i
                  className="rounded-icon"
                  onClick={() => exportPdf(record?.id || id)}
                >
                  <Eye height="30" />
                </i>
              </span>

              <span>
                <i
                  className="rounded-icon"
                  onClick={() => props.handleDeleteAtt(record)}
                >
                  <Delete height="30" />
                </i>
              </span>
            </>
          );
        },
      },
    ],
    [terms],
  );

  return { columnAttachments };
}
