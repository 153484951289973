import React, { useCallback, useState } from 'react';
import {
  amountFormat,
  exportDateUi,
  formatTitleText,
  isExist,
  makeOptions,
} from '../../../../utils/helper-function';
import DropdownSelect, {
  DropDownOptionObjectType,
} from '../../../common/DropDown/DropdownSelect';
import { Form, FormControl } from 'react-bootstrap';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { Typography } from 'antd';
import { Item } from './ModalDetailNotes';
import { EntityPayements } from '../../../../@types/segregatedaccounts/payements';
import { ReactComponent as Edit } from '../../../../assets/img/Edit.svg';
import { ReactComponent as Delete } from '../../../../assets/img/Delete.svg';

import { useFilteredPayementMutation } from '../../../../redux/api/ws/segregatedaccounts/notes';
import { useAppDispatch } from '../../../../hooks/redux';
import { updateNotificationMessage } from '../../../../redux/slice/notificationSlice';
import { uniqBy } from 'lodash';
import {
  getShouldValue,
  shouldValueAfterSelect,
} from '../../../../utils/shouldAmount';

type IProps = {
  payementsDataSource: Array<Item>;
  setPayementsDataSource: any;
  currency: string;
  setValue: any;
  handleRemoveItem: (item: any) => void;
  handleEditItem: (id: number) => void;
  maxTotal: number;
};
export default function ColumnPayementAttached(props: IProps) {
  const [editingKey, setEditingKey] = useState<Item | null>(null);
  const [listpayementUnassigned, setListPayementUnassigned] = useState<
    Array<EntityPayements | any>
  >([]);

  const getDefAllocation = useCallback(() => {
    let excludeIndex = -1;
    if (editingKey && typeof editingKey.key == 'number') {
      excludeIndex = editingKey.key - 1;
    }
    const summAll = (props?.payementsDataSource || []).reduce(
      (acc, curr, index) => {
        if (index !== excludeIndex) {
          return acc + parseFloat(curr.payment_amount || '0');
        }
        return acc;
      },
      0,
    );
    return Math.max(props.maxTotal - summAll, 0);
  }, [props?.payementsDataSource, props.maxTotal]);

  const getValueSelectAttached = (rate: string, amount: string) => {
    const maxAllocation = getDefAllocation() || Number(amount || '0');
    return shouldValueAfterSelect(rate, amount, maxAllocation);
  };

  const getShouldValueAttached = (rate: string, amount: string) => {
    return getShouldValue(rate, amount, getDefAllocation());
  };

  const [lunchFilterPayement, { isLoading }] = useFilteredPayementMutation();
  const dispatch = useAppDispatch();

  const isEditing = (record: any) =>
    record?.key?.toString() === editingKey?.key?.toString() ? true : false;

  const getPayements = async (text: string) => {
    try {
      const resp = await lunchFilterPayement({
        query: `%${text?.trim()}%`,
      }).unwrap();
      const floorResult = resp.payment?.map((el) => {
        const tempNote = el?.complete_ref?.split(' - ') || [];
        let tempNoteCurr = '';
        let tempNoteAmmount = '0.0';
        if (tempNote && tempNote?.[2]) {
          const tempS = tempNote?.[2]?.trim();
          const splitSelected = tempS?.split(' ');
          tempNoteAmmount = splitSelected?.[0] || '';
          tempNoteCurr = splitSelected?.[1] || '';
        }
        return {
          ...el,
          legal_entity: el?.legal_entity?.name || '',
          currency: el?.currency?.toUpperCase() || '',
          net_rate: tempNoteAmmount,
          payment_currency: tempNoteCurr,
          payment_date: el?.payment_date,
          paymentId: {
            key: el?.id,
            value: el?.number,
          },
        };
      });

      setListPayementUnassigned(floorResult);
    } catch (error) {
      return [];
    }
  };

  const updateDataSource = (
    key: any,
    dataIndex: string,
    v: string | { key: any; value: any } | number | Item | undefined,
    isSave: boolean = false,
    isCancel: boolean = false,
  ) => {
    const index = props?.payementsDataSource.findIndex((f) => f.key === key);

    if (index > -1) {
      const newDataSource = [...(props?.payementsDataSource || {})];
      if (isSave) {
        const legEnt = uniqBy(
          [...listpayementUnassigned, v],
          'complete_ref',
        )?.find((el) => el.id === newDataSource[index]?.paymentId?.key);
        const isDifferentDevise =
          legEnt?.payment_currency?.toLocaleLowerCase() !==
          props.currency?.toLocaleLowerCase();

        if (isDifferentDevise) {
          dispatch(
            updateNotificationMessage({
              title: 'Warning',
              body: `Payment allocation can only be in the currency of the related Note regardless of the Payment currency`,
              show: true,
            }),
          );
        }
        newDataSource[index] = {
          ...newDataSource[index],
          payment_amount: getValueSelectAttached(
            legEnt.net_rate,
            newDataSource[index]?.payment_amount,
          ),
          paymentId: legEnt?.paymentId,
          legal_entity: legEnt?.legal_entity || '',
          currency: legEnt?.currency?.toUpperCase() || '',
          net_rate: legEnt?.net_rate,
          payment_currency: legEnt?.payment_currency,
          isCurrentEdit: false,
          complete_ref: legEnt?.complete_ref,
          payment_date: legEnt?.payment_date,
        };
        setEditingKey(null);
      } else if (isCancel) {
        newDataSource[index] = {
          ...newDataSource[index],
        };
      } else {
        const note = listpayementUnassigned.find(
          (el) => el.id === (v as any)?.key,
        );
        const rate = note?.net_rate || note?.amount || '';
        if (rate) {
          newDataSource[index].payment_amount = getValueSelectAttached(
            rate,
            newDataSource[index].payment_amount,
          );
          newDataSource[index].net_rate = rate;
        }
        newDataSource[index] = {
          ...newDataSource[index],
          [dataIndex]: v,
        };
      }
      props.setValue('attachedPayement', 'add', {
        shouldDirty: true,
      });
      props?.setPayementsDataSource(newDataSource);
    }
  };

  const edit = (record: any) => {
    const defaultV = record.paymentId?.value
      ? [
          {
            ...record,
            complete_ref: record?.complete_ref,
            id: record.paymentId?.key,
          },
        ]
      : [];
    if (defaultV.length > 0) {
      setListPayementUnassigned(defaultV);
    }

    setEditingKey({ ...record });
  };

  const runSearch = React.useCallback(
    async (key: keyof Item, value: string) => {
      if (key === 'paymentId' && value !== undefined && value !== null) {
        await getPayements(value);
      }
    },
    [],
  );

  const handleDeleteAttached = (id: number | string) => {
    const found: any = props?.payementsDataSource?.find(
      (el) => el.key?.toString() === id?.toString(),
    );

    if (found) {
      props.handleRemoveItem(found);
    }
  };

  const cancel = () => {
    const currentEdit = props.payementsDataSource?.find(
      (el) => el.key?.toString() === editingKey?.key?.toString(),
    );
    const isAlreadySave = currentEdit?.id !== 0;
    if (isAlreadySave) {
      const restoreV = props.payementsDataSource?.map((el) => {
        if (el.id?.toString() === editingKey?.id?.toString()) {
          return {
            ...editingKey,
            isCurrentEdit: false,
          };
        } else {
          return el;
        }
      });
      props.setPayementsDataSource(restoreV);
    } else {
      const tempD = props.payementsDataSource?.filter(
        (el) => el.isCurrentEdit !== true,
      );
      props.setPayementsDataSource(tempD);
    }
    setEditingKey(null);
  };

  const columns = React.useMemo(
    () => [
      {
        title: 'Payment ID',
        dataIndex: 'paymentId',
        key: 1,
        editable: true,
        required: false,
        render: (text: { key: any; value: any }, record: Item) => {
          const editable = isEditing(record);
          return editable ? (
            <div className="d-flex flex-column">
              <div style={{ width: 500 }}>
                <DropdownSelect
                  preValue={record.complete_ref}
                  loading={isLoading}
                  onSelectOption={(e) => {
                    updateDataSource(record.key, 'paymentId', {
                      key: (e as DropDownOptionObjectType).key as string,
                      value: (e as DropDownOptionObjectType).value,
                    });
                  }}
                  onSearch={(d, v) => runSearch('paymentId', v)}
                  options={makeOptions(
                    listpayementUnassigned || [],
                    'complete_ref',
                    'id',
                  )}
                />
              </div>
            </div>
          ) : (
            <div className="py-3 ps-3">
              <span
                className="c_pointer hover_color_primary"
                onClick={() => props.handleEditItem(record.paymentId?.key)}
                title="Edit Payment"
              >
                {text?.value}
              </span>
            </div>
          );
        },
      },
      {
        title: 'Legal Entity',
        dataIndex: 'legal_entity',
        key: 2,
        editable: false,
        required: false,
        width: 200,
      },
      {
        title: 'Payment Date',
        dataIndex: 'payment_date',
        key: 2,
        editable: false,
        required: false,
        width: 200,
        render: (text: string) => {
          return <span>{exportDateUi(text) as string}</span>;
        },
      },
      {
        title: 'Payment Amount',
        dataIndex: 'net_rate',
        ellipsis: true,
        key: 3,
        editable: false,
        required: false,
        width: 140,
        render: (text: string) => {
          return (
            <span>
              {amountFormat(Number.parseFloat(text || '0')?.toFixed(2))}
            </span>
          );
        },
      },
      {
        title: 'Payment Cur.',
        dataIndex: 'payment_currency',
        key: 4,
        editable: false,
        required: false,
        ellipsis: true,
        width: 110,
      },
      {
        title: 'Allocation',
        dataIndex: 'payment_amount',
        key: 5,
        editable: true,
        required: false,
        ellipsis: true,
        render: (text: number, record: any) => {
          const editable = isEditing(record);
          return editable ? (
            <div className="d-flex w-100 align-items-baseline">
              <div className="d-flex flex-column w-100">
                <div
                  className="d-flex  flex-center w-100 align-items-baseline"
                  style={{ minWidth: '140px' }}
                >
                  <Form.Label className="w-100">
                    <div
                      className={`d-flex  flex-row mx-0  elementFormNumber py-2 my-0`}
                    >
                      <div className={`d-flex flex-fill`}>
                        <FormControl
                          type="text"
                          value={text}
                          className={`formNumber flex-fill rounded-0 ${
                            Number(record.payment_amount) <= 0 && 'invalid'
                          }`}
                          onChange={(e) => {
                            const value: any = e.target.value || '';
                            const numberValue = value
                              .replace(/[^0-9.]/g, '')
                              .replace('.', 'x')
                              .replace(/\./g, '')
                              .replace('x', '.');
                            if (numberValue) {
                              // if (numberValue.endsWith(".")) return;
                              updateDataSource(
                                record.key,
                                'payment_amount',
                                getShouldValueAttached(
                                  record.net_rate,
                                  numberValue,
                                ),
                              );
                              return;
                            }
                            updateDataSource(
                              record.key,
                              'payment_amount',
                              undefined,
                            );
                          }}
                          onBlur={() => {
                            const tempV = `${record.payment_amount || '0'}`;
                            const isEndWith = tempV.endsWith('.00');
                            const restoreValue = tempV.replaceAll('.00', '');
                            if (isEndWith) {
                              updateDataSource(
                                record.key,
                                'payment_amount',
                                restoreValue?.trim() || 0,
                              );
                            } else {
                              const makeV =
                                parseInt(restoreValue) <= 0
                                  ? restoreValue || 0
                                  : restoreValue || 0;
                              updateDataSource(
                                record.key,
                                'payment_amount',
                                makeV,
                              );
                            }
                          }}
                          onFocus={() => {
                            const tempV = record.payment_amount;
                            const floorValue = (
                              tempV?.toString() as string
                            )?.replaceAll('.00', '');
                            updateDataSource(
                              record.key,
                              'payment_amount',
                              floorValue?.trim(),
                            );
                          }}
                        />
                      </div>
                      <div className="d-flex justify-content-end flex-fill">
                        <div
                          className="flex flex-colummn justify-content-center pt-2 pe-2"
                          style={{ color: '#D9D9D9' }}
                        >
                          <span>|</span>
                        </div>
                        <div
                          className={`d-flex flex-column justify-content-between`}
                        >
                          <div className="py-0 my-0">
                            <IoIosArrowUp
                              onClick={() => {
                                updateDataSource(
                                  record.key,
                                  'payment_amount',
                                  getShouldValueAttached(
                                    record.net_rate,
                                    `${parseInt(text?.toString()) + 1}`,
                                  ),
                                );
                              }}
                              style={{
                                fontSize: 16,
                                // marginTop: 16,
                                cursor: 'pointer',
                                color: '#851001',
                              }}
                            />
                          </div>
                          <div className="py-0 my-0">
                            <IoIosArrowDown
                              onClick={() => {
                                updateDataSource(
                                  record.key,
                                  'payment_amount',
                                  getShouldValueAttached(
                                    record.net_rate,
                                    `${Math.max(text - 1, 0.01)}`,
                                  ),
                                );
                              }}
                              style={{
                                fontSize: 16,
                                // marginBottom: 8,
                                cursor: 'pointer',
                                color: '#851001',
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form.Label>
                </div>
              </div>
            </div>
          ) : (
            <div className="py-3 ps-3">
              {amountFormat(
                Number.parseFloat(text?.toString() || '0')?.toFixed(2),
              )}
            </div>
          );
        },
      },

      {
        title: 'Allocation Cur.',
        dataIndex: 'currency',
        key: 6,
        editable: false,
        required: false,
        ellipsis: true,
        width: 110,
      },
      {
        title: 'Comment',
        dataIndex: 'payment_comments',
        key: 7,
        editable: true,
        required: false,
        width: 200,
        render: (text: string, record: any) => {
          const editable = isEditing(record);
          return editable ? (
            <div className="d-flex w-100 align-items-baseline">
              <div className="d-flex flex-column w-100">
                <Form.Label className="w-100">
                  <div className={`d-flex  flex-row mx-0  py-2 my-0`}>
                    <FormControl
                      as={'textarea'}
                      type="text"
                      rows={2}
                      value={text}
                      style={{ minWidth: '140px' }}
                      className={` flex-fill rounded-0`}
                      onChange={(e) => {
                        const value: any = e.target.value;
                        updateDataSource(record.key, 'payment_comments', value);
                      }}
                    />
                  </div>
                </Form.Label>
              </div>
            </div>
          ) : (
            <div
              style={{
                maxWidth: 150,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              className={`py-3 ps-3`}
              title={formatTitleText(text)}
            >
              {text}
            </div>
          );
        },
      },
      {
        title: '',
        dataIndex: 'id',
        key: 4,
        width: 150,
        render: (text: number, record: Item) => {
          const editable = isEditing(record);
          const newDataSource = props?.payementsDataSource.find(
            (f) => f.key === record.key,
          );
          const legEnt = uniqBy(
            [...listpayementUnassigned],
            'complete_ref',
          )?.find((el) => el.id === newDataSource?.paymentId?.key);
          return editable ? (
            <span>
              <Typography.Link
                style={{ marginRight: 8, color: 'gray' }}
                onClick={() =>
                  updateDataSource(record.key, 'save', record, true)
                }
                disabled={
                  Number(record.payment_amount) <= 0 ||
                  !isExist(record.payment_amount) ||
                  !isExist(record.paymentId?.value) ||
                  Number(legEnt?.net_rate ?? 0) < Number(record.payment_amount)
                    ? true
                    : false
                }
              >
                Update
              </Typography.Link>
              <Typography.Link
                style={{ marginRight: 8, color: 'gray' }}
                onClick={cancel}
              >
                Cancel
              </Typography.Link>
            </span>
          ) : (
            <>
              <Typography.Link
                disabled={editingKey !== null}
                onClick={() => {
                  edit(record);
                }}
                style={{ marginRight: 10 }}
              >
                <Edit height="30" />
              </Typography.Link>
              <Typography.Link
                disabled={editingKey !== null}
                onClick={() => {
                  handleDeleteAttached(record?.key);
                }}
              >
                <Delete height="30" />
              </Typography.Link>
            </>
          );
        },
      },
    ],
    [listpayementUnassigned, props],
  );

  return {
    columns,
    edit,
    setEditingKey,
    isEditing: editingKey?.id === 0 || isExist(editingKey?.id) ? true : false,
  };
}
