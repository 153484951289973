import { isEqual, pick, uniq } from 'lodash';
import { Col, ModalProps, Row } from 'react-bootstrap';
import { Divider, Table, Form as AntForm, Button, Radio } from 'antd';
import FormModal from '../../../Modal/FormModal/FormModal';
import classes from './FinancierModal.module.scss';
import { ConterParty } from '../../../../@types/conterParty';
import AddGroup from '../../../../assets/img/add.svg';
import useFinancierModal from './useFinancierModal';
import SelectDropDown from '../../../common/DropDown/SelectDropDown';
import { DropDownOptionObjectType } from '../../../common/DropDown/DropdownSelect';
import { FaCaretDown, FaUser } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import { People } from '../../../../@types/people';
import { financialFields } from '../../../../utils/mockFinancialFiels';
import CofarcoFormText from '../../../Form/CofarcoFormText';
import DeleteModal from '../../../Modal/DeleteModal';
import { checkIsEdit, isCan } from '../../../../utils/helper-function';
import { Menu } from '../../../../@types/common-types';
import RowContent from '../../../Form/RowContent';
import ModalUnsavedChange from '../../../common/Modal/ModalUnsavedChange';

type Props = {
  defaultFormValues?: ConterParty | null;
  onSubmittedForm?: () => void;
  onClose?: () => void;
} & ModalProps;

const FinancierModal = ({
  defaultFormValues,
  onSubmittedForm,
  onClose,
  ...props
}: Props) => {
  const {
    terms,
    defaultValues,
    errors,
    searchPeopleCallback,
    onSubmitForm,
    register,
    watchFormValue,
    isInModeEditing,
    setFormValue,
    getFormValue,
    handleClose,
    mergedLegalEntity,
    peoples,
    formLegalEntity,
    dataSourceLegalEntity,
    addLegalEntity,
    toggleFinancialFieldCollapse,
    showPeopleModal,
    deleteModal,
    closeDeleteLegalEntity,
    deleteLegalEntity,
    isShowModalUnsavedChange,
    setIsShowModalUnsavedChange,
    showOtherModaldata,
  } = useFinancierModal({ defaultFormValues, onSubmittedForm });

  const [isLegalEntityEdit, setIsLegalEntityEdit] = useState<boolean>(false);

  useEffect(() => {
    setIsLegalEntityEdit(false);
  }, [defaultFormValues]);

  const hiddenFooter = !!(
    !isCan('write', Menu.financier) &&
    checkIsEdit(defaultFormValues?.id as number)
  );

  return (
    <>
      <DeleteModal
        title="Delete Legal Entity"
        show={deleteModal.show}
        groupeName={`${deleteModal?.legal_entity?.name ?? ''}`}
        entityTitle={'Legal Entity List'}
        validDelete={() => deleteLegalEntity(deleteModal.id ?? -1)}
        handleClose={() => closeDeleteLegalEntity()}
      />
      <FormModal
        {...props}
        ishiddenfooter={hiddenFooter}
        header={
          defaultValues.id !== -1 ? (
            <div className="d-flex">
              <div
                className={`ms-3 ${
                  isLegalEntityEdit
                    ? classes.modalHeaderTitleEditNotActive
                    : classes.modalHeaderTitleEdit
                }`}
                onClick={() => {
                  setIsLegalEntityEdit(false);
                }}
              >
                Financier - {defaultValues.name}
              </div>

              <div
                className={`ms-3 ${
                  isLegalEntityEdit
                    ? classes.modalHeaderTitleEdit
                    : classes.modalHeaderTitleEditNotActive
                }`}
                onClick={() => {
                  setIsLegalEntityEdit(true);
                }}
              >
                Legal Entity(ies) List
              </div>
            </div>
          ) : (
            <div className={`ms-3 ${classes.modalHeaderTitle}`}>
              New - Financier
            </div>
          )
        }
        handleClose={() => {
          if (onClose) onClose();
          handleClose();
          setIsLegalEntityEdit(false);
        }}
        onSubmitForm={onSubmitForm}
        isDisabledSave={
          watchFormValue('name') === '' ||
          dataSourceLegalEntity.filter((f) => f.legal_entity).length === 0 ||
          Object.keys(errors).length > 0 ||
          isEqual(
            defaultValues,
            pick(watchFormValue(), Object.keys(defaultValues)),
          )
        }
      >
        {!isLegalEntityEdit && (
          <>
            <Row className="pt-2">
              <Col className="px-3">
                <RowContent>
                  <CofarcoFormText
                    label="ID"
                    class="w-100 align-items-baseline"
                    errors={errors}
                    key_form="name"
                    register={register('name', {
                      required: true,
                      validate: (value) => value.length > 0 || 'Required',
                    })}
                    isRequired
                    txtError="Required"
                  />
                </RowContent>

                <RowContent>
                  <CofarcoFormText
                    label="Commercial Name"
                    class="w-100 align-items-baseline"
                    errors={errors}
                    key_form="commercial_name"
                    register={register('commercial_name')}
                    txtError="Required"
                  />
                </RowContent>

                <RowContent>
                  <SelectDropDown
                    noPadding
                    dropDownClass="px-0"
                    title="Type"
                    id="activityType"
                    // placeholder="Type"
                    preValue={getFormValue('_type')}
                    onSearch={(d, v) => {
                      d(
                        uniq(
                          terms.filter(
                            (f) =>
                              f.type === 'activityType' &&
                              (f.opt === 2 || f.opt === 19 || f.opt === 3) &&
                              f.value.toLowerCase().includes(v.toLowerCase()),
                          ),
                        ),
                      );
                    }}
                    options={uniq(
                      terms.filter(
                        (f) =>
                          f.type === 'activityType' &&
                          (f.opt === 2 || f.opt === 19 || f.opt === 3),
                      ),
                    )}
                    onSelectOption={(e) => {
                      setFormValue(
                        'type',
                        ((e as DropDownOptionObjectType).key ?? '') as string,
                      );
                      setFormValue(
                        '_type',
                        ((e as DropDownOptionObjectType).value ?? '') as string,
                      );
                      setFormValue('activity', '');
                      setFormValue('_activity', '');
                    }}
                  />
                </RowContent>

                <RowContent>
                  <SelectDropDown
                    noPadding
                    dropDownClass="px-0"
                    title="Activity"
                    id="clientActivity"
                    placeholder=""
                    preValue={watchFormValue('_activity')}
                    onSearch={(d, v) => {
                      d(
                        uniq(
                          terms.filter(
                            (f) =>
                              f.type === 'activity' &&
                              f.opt === 2 &&
                              f.dependences
                                .split(',')
                                .includes(watchFormValue('type') ?? '') &&
                              f.value.toLowerCase().includes(v.toLowerCase()),
                          ),
                        ),
                      );
                    }}
                    options={uniq(
                      terms.filter(
                        (f) =>
                          f.type === 'activity' &&
                          f.opt === 2 &&
                          f.dependences
                            .split(',')
                            .includes(watchFormValue('type') ?? ''),
                      ),
                    )}
                    onSelectOption={(e) => {
                      setFormValue(
                        'activity',
                        (e as DropDownOptionObjectType).key as string,
                      );
                      setFormValue(
                        '_activity',
                        (e as DropDownOptionObjectType).value as string,
                      );
                    }}
                  />
                </RowContent>
              </Col>

              <Col>
                <RowContent>
                  <SelectDropDown
                    noPadding
                    dropDownClass="px-0"
                    title="Main Contact"
                    id="main_people"
                    placeholder=""
                    preValue={(getFormValue('_main_people_id') ?? '') as string}
                    onSearch={(d, v) => {
                      searchPeopleCallback(
                        dataSourceLegalEntity
                          .filter((f) => f.legal_entity)
                          .map((e) => e.legal_entity?.id ?? 0),
                        v,
                        (data) => {
                          d(
                            data.map((d) => ({
                              key: d,
                              value: `${d.firstname} ${d.lastname}`,
                            })),
                          );
                        },
                      );
                    }}
                    options={peoples.map((d) => ({
                      key: d,
                      value: `${d.firstname} ${d.lastname}`,
                    }))}
                    onSelectOption={(e) => {
                      if (e) {
                        setFormValue(
                          'main_people_id',
                          (e as DropDownOptionObjectType).key.id as number,
                        );
                        setFormValue(
                          '_main_people',
                          (e as DropDownOptionObjectType).key as People,
                        );
                      } else {
                        setFormValue('main_people_id', null);
                        setFormValue('_main_people', undefined);
                      }
                    }}
                    icon={
                      <FaUser
                        style={{
                          fontSize: 22,
                          cursor: 'pointer',
                          color: watchFormValue('main_people_id')
                            ? '#6d1206'
                            : '',
                        }}
                        onClick={() => {
                          const v = getFormValue('main_people_id');
                          if (v) {
                            showPeopleModal(v);
                          }
                        }}
                      />
                    }
                  />
                </RowContent>

                <RowContent>
                  <SelectDropDown
                    noPadding
                    dropDownClass="px-0"
                    title="Most Senior Contact"
                    id="most_senior_people"
                    placeholder=""
                    preValue={
                      (getFormValue('_most_senior_people_id') ?? '') as string
                    }
                    onSearch={(d, v) => {
                      searchPeopleCallback(
                        dataSourceLegalEntity
                          .filter((f) => f.legal_entity)
                          .map((e) => e.legal_entity?.id ?? 0),
                        v,
                        (data) => {
                          d(
                            data.map((d) => ({
                              key: d,
                              value: `${d.firstname} ${d.lastname}`,
                            })),
                          );
                        },
                      );
                    }}
                    options={peoples.map((d) => ({
                      key: d,
                      value: `${d.firstname} ${d.lastname}`,
                    }))}
                    onSelectOption={(e) => {
                      if (e) {
                        setFormValue(
                          'most_senior_people_id',
                          (e as DropDownOptionObjectType).key.id as number,
                        );
                        setFormValue(
                          '_most_senior_people',
                          (e as DropDownOptionObjectType).key as People,
                        );
                      } else {
                        setFormValue('most_senior_people_id', null);
                        setFormValue('_most_senior_people', undefined);
                      }
                    }}
                    icon={
                      <FaUser
                        style={{
                          fontSize: 22,
                          cursor: 'pointer',
                          color: getFormValue('most_senior_people_id')
                            ? '#6d1206'
                            : '',
                        }}
                        onClick={() => {
                          const v = getFormValue('most_senior_people_id');
                          if (v) {
                            showPeopleModal(v);
                          }
                        }}
                      />
                    }
                  />
                </RowContent>

                <RowContent>
                  <SelectDropDown
                    noPadding
                    dropDownClass="px-0"
                    title="Market"
                    id="Market"
                    placeholder=""
                    preValue={getFormValue('_market')}
                    onSearch={(d, v) => {
                      d(
                        uniq(
                          terms.filter(
                            (f) =>
                              f.type === 'market' &&
                              f.value.toLowerCase().includes(v.toLowerCase()),
                          ),
                        ),
                      );
                    }}
                    options={uniq(terms.filter((f) => f.type === 'market'))}
                    onSelectOption={(e) => {
                      setFormValue(
                        '_market',
                        (e as DropDownOptionObjectType).value as string,
                      );
                      setFormValue(
                        'market',
                        (e as DropDownOptionObjectType).key as string,
                      );
                    }}
                  />
                </RowContent>
              </Col>
            </Row>
            <Divider />

            <Row>
              <Col className="mt-0 mb-4">
                {watchFormValue('id') <= 0 && (
                  <>
                    <div className="font-bold mt-0">Legal Entities List</div>
                    <div className="content-table mt-4">
                      <div className="content-table">
                        <AntForm form={formLegalEntity} component={false}>
                          <Table
                            className="no-background"
                            style={{ backgroundColor: 'transparent' }}
                            rowClassName={classes.antdTable}
                            dataSource={dataSourceLegalEntity}
                            columns={mergedLegalEntity}
                            rowKey="id"
                            pagination={false}
                          />
                        </AntForm>
                      </div>
                      <Button
                        className="btn red mt-4 py-0"
                        disabled={isInModeEditing}
                        onClick={() => addLegalEntity()}
                      >
                        <img src={AddGroup} alt="" className="pr-2" /> Add Legal
                        Entity
                      </Button>
                    </div>
                  </>
                )}
              </Col>
            </Row>

            <div>
              <div
                className="d-flex bg-white rounded-top py-3 px-3 pe-auto justify-content-between"
                style={{ cursor: 'pointer' }}
                onClick={() => toggleFinancialFieldCollapse()}
              >
                <b>Financial Fields</b>
                <FaCaretDown className="mt-1" />
              </div>
              <div className="collapse px-4 py-3" id="collapseFinancialField">
                {financialFields.map((t, index) => {
                  return (
                    <div className="d-flex py-2 pe-5" key={`${t}-${index}`}>
                      <div style={{ width: '20%' }}>{t.name}</div>
                      <Radio.Group
                        onChange={(v) => {
                          console.log('Informations >>> ', v.target.value);
                          setFormValue(
                            'informations',
                            {
                              ...getFormValue('informations'),
                              [t.key]: v.target.value,
                            },
                            { shouldDirty: true },
                          );
                        }}
                        value={
                          (watchFormValue(`informations`) ?? ({} as any))[t.key]
                        }
                        className="d-flex justify-content-around align-tems-between w-100"
                      >
                        <Radio value="yes">Yes</Radio>
                        <Radio value="no">No</Radio>
                        <Radio value="unknown">Don't Know</Radio>
                      </Radio.Group>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}
        {isLegalEntityEdit && (
          <Row>
            {watchFormValue('id') > 0 && (
              <>
                <div className="content-table mt-4">
                  <div className="content-table">
                    <AntForm form={formLegalEntity} component={false}>
                      <Table
                        className="no-background"
                        rowClassName={classes.antdTable}
                        dataSource={dataSourceLegalEntity}
                        columns={mergedLegalEntity}
                        rowKey="id"
                        pagination={false}
                      />
                    </AntForm>
                  </div>
                  {!hiddenFooter && (
                    <Button
                      className="btn red mt-3 py-0"
                      disabled={isInModeEditing}
                      onClick={() => addLegalEntity()}
                    >
                      <img src={AddGroup} alt="" className="pr-2" /> Add Legal
                      Entity
                    </Button>
                  )}
                </div>
              </>
            )}
          </Row>
        )}
      </FormModal>
      <ModalUnsavedChange
        showModalUnsaved={isShowModalUnsavedChange}
        handleCancel={() => setIsShowModalUnsavedChange(false)}
        handleLeave={() => showPeopleModal(showOtherModaldata, true)}
        // dirtiFields={dirtyFields}
      />
    </>
  );
};

export default FinancierModal;
