import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {GroupeFilterType} from "../../@types/group/types";

export interface GroupFilterState {
  data: GroupeFilterType;
}

const initialState: GroupFilterState = {
  data: {},
};

export const groupFilterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setGroupFilter: (state, action: PayloadAction<GroupeFilterType>) => {
      state.data = action.payload;
      return state;
    },
  },
});

export const { setGroupFilter } = groupFilterSlice.actions;
export default groupFilterSlice.reducer;
