import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import {
  CofarcoResponse,
  EntityCofarcoTeam,
  InportFormCreateCorfacroTeam,
  InputFormDetailCofarcoTeam,
} from '../../../../@types/cofarco-teams/type';
import { ContentType, Method } from '../../../../@types/common-types';
import { datas } from '../../back-end-service';
import { baseApi } from '../../baseApi';
import { url } from '../../url';
import { ApiFilter } from '../../../../@types';

const cofarcoTeams = baseApi.injectEndpoints({
  endpoints: (build: EndpointBuilder<any, any, any>) => {
    return {
      cofarcoteam: build.query<
        CofarcoResponse,
        {
          page: number | undefined;
          start: number | undefined;
          limit: number | undefined;
          sort: string | null;
          filter: string | undefined;
          show_inactif: number | undefined;
        }
      >({
        query: (queryParams) => {
          const { start, page, limit, sort, filter, show_inactif } =
            queryParams;
          return {
            url: url.cofarco_teams.list,
            params: { start, page, limit, sort, filter, show_inactif },
          };
        },
        providesTags: ['LIST_COFARCO'],
      }),

      cofarcoTeamById: build.query<
        CofarcoResponse,
        {
          id: number;
        }
      >({
        query: (params) => {
          return {
            url: `${url.cofarco_teams.list}/${params.id}`,
          };
        },
        providesTags: ['GET_COFARCO'],
      }),

      createCofarcoTeams: build.mutation<
        {
          success: string;
          user: EntityCofarcoTeam;
        },
        InportFormCreateCorfacroTeam
      >({
        query: (body) => {
          return {
            url: url.cofarco_teams.list,
            method: Method.POST,
            data: datas(body, ContentType.FORM_DATA),
          };
        },
      }),

      updateCofarco: build.mutation<
        { success: boolean; user: EntityCofarcoTeam },
        InputFormDetailCofarcoTeam
      >({
        query: (body) => {
          console.log({ body });
          return {
            url: url.cofarco_teams.list + '/' + body.id,
            method: Method.PUT,
            data: body,
          };
        },
        invalidatesTags: ['LIST_COFARCO'],
      }),

      updateCofarcoPwd: build.mutation<
        { success: boolean; user: EntityCofarcoTeam },
        {
          data: any; //IPwd;
          id: number;
        }
      >({
        query: (body) => {
          return {
            url: url.cofarco_teams.list + '/' + body.id,
            method: Method.PUT,
            data: body.data,
          };
        },
        // invalidatesTags: ['LIST_COFARCO'],
      }),

      deleteCofarcoTeam: build.mutation<
        { success: boolean; legalentity: CofarcoResponse },
        { id: number }
      >({
        query: (body) => {
          return {
            url: url.cofarco_teams.list + '/' + body.id,
            method: Method.DELETE,
          };
        },
        invalidatesTags: ['LIST_COFARCO'],
      }),

      uploadAvatar: build.mutation<
        { success: boolean; data: { avatar: string } },
        { id: number; data: any }
      >({
        query: (body) => {
          return {
            url: url.cofarco_teams.avatar + '/' + body.id,
            method: Method.POST,
            data: datas({ item: body.data }, 'multipart/form-data'),
          };
        },
      }),
      exportCofarcoTeam: build.mutation<
        string,
        ApiFilter & { fields?: (keyof EntityCofarcoTeam)[] } & ApiFilter
      >({
        query: ({ fields, filter }) => {
          return {
            url: url.cofarco_teams.export,
            data: {
              fields,
            },
            params: {
              filter,
            },
            method: Method.POST,
          };
        },
      }),
    };
  },
});
export const {
  useCofarcoteamQuery,
  useLazyCofarcoTeamByIdQuery,
  useDeleteCofarcoTeamMutation,
  useCreateCofarcoTeamsMutation,
  useUpdateCofarcoMutation,
  useUpdateCofarcoPwdMutation,
  useUploadAvatarMutation,
  useExportCofarcoTeamMutation,
} = cofarcoTeams;
