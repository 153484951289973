import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthState } from "../../@types/common-types";
import { PersistConfig, persistReducer } from "redux-persist";
import storage from "redux-persist/es/storage";
import expireReducer from "redux-persist-expire";
const initialState: AuthState = {
  user: null,
  creation_date: "",
  expiration_date: "",
  success: false,
  token: undefined,
  token_duration: 0,
};

const persistConfig: PersistConfig<AuthState, any, any, any> = {
  key: "root",
  storage,
  transforms: [
    expireReducer("auth", {
      persistedAtKey: "__persisted_at",
      expireSeconds: 360,
      expiredState: initialState,
      autoExpire: false,
    }),
  ],
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state = {
        user: null,
        creation_date: "",
        expiration_date: "",
        success: false,
        token: undefined,
        token_duration: 0,
      };
      return state;
    },
    refresh: (state, action: PayloadAction<AuthState>) => {
      state = action.payload;
      return state;
    },
    logged: (state, action: PayloadAction<AuthState>) => {
      state = action.payload;
      return state;
    },
  },
});
export const { logout, logged, refresh } = authSlice.actions;
export default authSlice.reducer;

export const authReducer = persistReducer(persistConfig, authSlice.reducer);
