import {
    DashboardWhatsNewData,
    ModelsEnum,
    CounterPartyBusiness,
} from '../../@types/dashboard';

export const useWhatsNewUtils = () => {
    const getType = (objectType: string) => {
        const parts = objectType.split('\\');
        return parts[parts.length - 1].replace(/([a-z])([A-Z])/g, '$1_$2');
    };

    const getName = (record: DashboardWhatsNewData) => {
        switch (getType(record.object_type)) {
            case ModelsEnum.mandate:
                return record.mandate_name;
            case ModelsEnum.deal:
                return record.mandates_deals_name;
            case ModelsEnum.enquiry:
                return record.mandates_enquiry_name;
            case ModelsEnum.indication:
                return record.mandates_indication_name;
            case ModelsEnum.counterParty:
                switch (record.business) {
                    case CounterPartyBusiness.client:
                    case CounterPartyBusiness.financier:
                    case CounterPartyBusiness.partner:
                    case CounterPartyBusiness.underwriter:
                        return record.client_name;
                    case CounterPartyBusiness.obligor:
                        return record.legal_entity_name;
                }

            case ModelsEnum.people:
                return record.people_full_name;
            case ModelsEnum.note:
                return record?.note_id;
            default:
                return getType(record.object_type);
        }
    };

    const getStatus = (record: DashboardWhatsNewData) => {
        switch (getType(record.object_type)) {
            case ModelsEnum.mandate:
                /***
                 * Mandate lorsque la new value pour Mandate Status
                 * est "client termination" [Ended], "cofarco termination" [Ended],
                 * "no solution" [Ended], "completed"[Done], "gone" [Gone],
                 * "sent" [Offer]", "turned down" [Rejected]. (mettre dans tous les cas le Mandate Name)
                 */
                if (record.type === 'create') return 'New';

                const mandateStatus = record.mandate_status.toLowerCase();

                if (
                    ['client_termination', 'cofarco_termination', 'no_solution'].includes(
                        mandateStatus,
                    )
                ) {
                    return 'Ended';
                } else if (['completed', 'done'].includes(mandateStatus)) return 'Done';
                else if (mandateStatus === 'gone') return 'Gone';
                else if (mandateStatus === 'sent') return 'Offer';
                else if (['turned down', 'turned-down'].includes(mandateStatus))
                    return 'Offer Declined';
                return mandateStatus;

            case ModelsEnum.deal:
                /***
                 * Deal lorsque la new value pour Status est "bound" [On-risk], "ended" [Off-risk].
                 * (mettre dans tous les cas le Mandate Name du Mandat de rattachement)
                 */
                const dealStatus = record.deal_status?.toLowerCase();
                if (record.type === 'create') {
                    if (!dealStatus) return 'New';
                }
                if (dealStatus === 'bound') return 'Bound';
                else if (dealStatus === 'binding') return 'Binding';
                else if (dealStatus == 'ended') return 'Off-risk';
                return record.deal_status;
            case ModelsEnum.enquiry:
                /**
                 * Enquiry lorsque la new value pour Status passe à Closed:
                 si "deal", [Avatar Collab] [Date] [Heure] [Icone Enquiry] [Deal :-)]
                 [Mandate Name du mandate de Rattachement] [Client ID]
                 dans tous les autres cas [Avatar Collab] [Date] [Heure]
                 [Icone Enquiry] [No Deal :-(] [Mandate Name du mandate de Rattachement] [Client ID]
                 */
                if (record.type === 'create') return 'New';
                const newStatus = `${
                    (record?.changes as any)?.status || ''
                }`.toLocaleLowerCase();
                const newOutcome = `${
                    (record?.changes as any)?.outcome || ''
                }`.toLocaleLowerCase();

                const oldStatus = `${
                    (record?.original as any)?.status || record.enquiries_status || ''
                }`.toLocaleLowerCase();
                const oldOutcome = `${
                    (record?.original as any)?.outcome || record.outcome || ''
                }`.toLocaleLowerCase();

                const enquiryStatus = newStatus || oldStatus;
                const outCome = newOutcome || oldOutcome;

                if (enquiryStatus === 'closed' && outCome === 'deal') return 'Deal :-)';

                return 'No Deal :-(';
            case ModelsEnum.indication:
                /**
                 * Indication lorsque la "new value" pour indication est "nbi" ou "eoi".
                 * Afficher [NBI] ou [EOI] (mettre le Mandate Name du Mandat de rattachement)
                 */
                if (['nbi', 'eoi'].includes(record.indication_type.toLowerCase())) {
                    return record.indication_type.toUpperCase();
                }
                if (record.type === 'create') return 'New';
                return record.mandate_status;
            case ModelsEnum.counterParty:
            case ModelsEnum.people:
                if (record.type === 'create') return 'New';
                return 'Update';
            case ModelsEnum.note:
                /***
                 * Notes pour tous les types de Notes lorsque le status passe de
                 * "Draft" à "Raised" [Signed-off]
                 * (mettre dans tous les cas le Mandate Name du Mandat de rattachement)
                 */
                const originalStatus = (
                    (record?.original as any)?.status || record?.note_status
                )?.toLocaleLowerCase();
                const changeStatus = (record?.changes as any)?.status?.toLowerCase();
                if (originalStatus === 'draft' && changeStatus === 'raised') {
                    return 'Signed-off';
                } else if (changeStatus === 'past_due') return 'Past-Due';
                else if (['settled', 'settle'].includes(changeStatus)) return 'Settled';
                else if (originalStatus === 'raised') return 'Signed-off';
                if (record.type === 'create' && !changeStatus) return 'New';
                return changeStatus;
            default:
                return 'Unknown';
        }
    };

    const blink = (record: DashboardWhatsNewData) => {
        // if (record.type !== "create") return false;
        const currentDate = new Date();
        const date = new Date(record.log_at);
        return (
            date.getDate() === currentDate.getDate() &&
            date.getMonth() === currentDate.getMonth() &&
            date.getFullYear() === currentDate.getFullYear() &&
            record?.stateSeen !== 'seen'
        );
    };

    const getLastColumnValueByCounterParty = (record: DashboardWhatsNewData) => {
        if (record?.business === CounterPartyBusiness.obligor) {
            return record?.mandates_obligor_name ?? record?.mandates_obligor_legal_entity_name;
        }
        return record?.counterparties_commercial_name;
    }

    const getLastColumnValue = (record: DashboardWhatsNewData) => {
        switch (getType(record.object_type)) {
            case ModelsEnum.mandate:
                return record?.clients_name_mandate || record?.client_name;
            case ModelsEnum.deal:
                return record?.client_name_deals || record?.client_name;
            case ModelsEnum.enquiry:
                return record?.client_name_enquiry || record?.client_name;
            case ModelsEnum.indication:
                return record?.client_name_indication || record?.client_name;
            case ModelsEnum.counterParty:
                return getLastColumnValueByCounterParty(record)
            case ModelsEnum.people:
                return record?.legal_entity_people_name;

            case ModelsEnum.note:
                return record?.note_recipient;
            default:
                return '';
        }
    };

    return {
        getStatus,
        getLastColumnValue,
        getType,
        getName,
        blink,
    };
};
