import React, { useState, createContext } from 'react';
import { Outlet } from 'react-router-dom';
import ContentNavBar from '../Admin/ContentNavBar';
import EnquiriesWhite from '../../assets/img/EnquiriesWhite.svg';
export const ContextEnquiry = createContext<any>(null);

const EnquiriesContent: React.FC = () => {
  const [searchTxt, setSearchTxt] = useState<string | null>(null);

  return (
    <>
      <ContentNavBar
        title='Enquiries'
        logo={EnquiriesWhite}
        setTxt={(txt: any) => setSearchTxt(txt)}
        txt={searchTxt}
      />
      <ContextEnquiry.Provider value={{ txt: searchTxt }}>
        <Outlet />
      </ContextEnquiry.Provider>
    </>
  );
};

export default EnquiriesContent;
