import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { InputFieldsGroup } from '../../../../../@types/group/types';
import SelectDropDown from '../../../../common/DropDown/SelectDropDown';
import {
  concatString,
  getUniqueArray,
  isExist,
} from '../../../../../utils/helper-function';
import { useLazySearchPeopleQuery } from '../../../../../redux/api/ws/databases/people';
import { DropDownOptionObjectType } from '../../../../common/DropDown/DropdownSelect';
import { People } from '../../../../../@types/people';
import RowContent from '../../../../Form/RowContent';

type IProps = {
  watch: UseFormWatch<InputFieldsGroup>;
  setValue: UseFormSetValue<InputFieldsGroup>;
  showPeople: (idPeople: number) => void;
  setSelectedPeople: (people: People | undefined) => void;
};
export default function UltimateBeneficial(props: IProps) {
  const [searchPeople, { data, isLoading: isLoadingPeople }] =
    useLazySearchPeopleQuery();

  const options = (data?.people || [])?.map((el: any) => ({
    key: el.id,
    value: concatString(el.firstname, el.lastname, ' '),
  }));

  const getPeopleNameOption = async (text: string) => {
    try {
      const response: any = await searchPeople({
        query: text?.trim(),
      });
      const tempOption: Array<{ id: number; name: string }> =
        response?.data?.group;
      const options = tempOption?.map((el) => el.name);
      return options;
    } catch (error) {
      return [];
    }
  };

  const runSearch = React.useCallback(async (value: string) => {
    await getPeopleNameOption(value);
  }, []);

  const ultimate_beneficial_owner_1_name = props.watch(
    'ultimate_beneficial_owner_1_name',
  );
  const ultimate_beneficial_owner_1 = props.watch(
    'ultimate_beneficial_owner_1',
  );
  const ultimate_beneficial_owner_2_name = props.watch(
    'ultimate_beneficial_owner_2_name',
  );
  const ultimate_beneficial_owner_2 = props.watch(
    'ultimate_beneficial_owner_2',
  );
  const ultimate_beneficial_owner_3_name = props.watch(
    'ultimate_beneficial_owner_3_name',
  );
  const ultimate_beneficial_owner_3 = props.watch(
    'ultimate_beneficial_owner_3',
  );
  const ultimate_beneficial_owner_4_name = props.watch(
    'ultimate_beneficial_owner_4_name',
  );
  const ultimate_beneficial_owner_4 = props.watch(
    'ultimate_beneficial_owner_4',
  );

  const exCludeFilter = [
    ultimate_beneficial_owner_1 || 0,
    ultimate_beneficial_owner_2 || 0,
    ultimate_beneficial_owner_3 || 0,
    ultimate_beneficial_owner_4 || 0,
  ];

  const handleSelectedPeople = (id: number): People | undefined => {
    const found = data?.people?.find((el) => el.id === id);
    if (found) {
      return found;
    }
  };

  return (
    <Col>
      <div>
        <Form.Group className="d-flex">
          <div className="inputPerso">
            <div className={`contour`}>
              <span
                style={{
                  position: 'absolute',
                  top: '12px',
                  left: '5px',
                }}
                className="boldered-text"
              >
                Ultimate Beneficial Owner
              </span>
              <i
                style={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                }}
              ></i>
            </div>
          </div>
        </Form.Group>
      </div>

      <div className="ultimateBen">
        <div className="w-100 px-0 mx-0">
          <RowContent>
            <div className="d-flex align-items-center w-100 ">
              <Form.Label className="my-auto">
                Ultimate Beneficial Owner 1
              </Form.Label>
              <SelectDropDown
                modalId="modal-people"
                contentStyle="w-100"
                preValue={ultimate_beneficial_owner_1_name as string}
                loading={isLoadingPeople}
                onSearch={(d, e) => runSearch(e)}
                onSelectOption={(e) => {
                  props.setSelectedPeople(
                    handleSelectedPeople(
                      (e as DropDownOptionObjectType).key as number,
                    ),
                  );
                  props.setValue(
                    'ultimate_beneficial_owner_1',
                    (e as DropDownOptionObjectType).key,
                    { shouldDirty: true },
                  );
                  props.setValue(
                    'ultimate_beneficial_owner_1_name',
                    (e as DropDownOptionObjectType).value,
                    { shouldDirty: true },
                  );
                }}
                options={getUniqueArray(options || [], exCludeFilter)}
              />

              <span
                className={`icon-People ico ${
                  !isExist(ultimate_beneficial_owner_1_name) ? 'disabled' : ''
                }`}
                onClick={() => {
                  ultimate_beneficial_owner_1 &&
                    props.showPeople(ultimate_beneficial_owner_1 as number);
                }}
              />
            </div>
          </RowContent>
          <RowContent>
            <div className="d-flex align-items-center mt-2">
              <Form.Label className="my-auto">
                Ultimate Beneficial Owner 2
              </Form.Label>
              <SelectDropDown
                modalId="modal-people"
                contentStyle="w-100"
                preValue={ultimate_beneficial_owner_2_name as string}
                loading={isLoadingPeople}
                onSearch={(d, e) => runSearch(e)}
                onSelectOption={(e) => {
                  props.setSelectedPeople(
                    handleSelectedPeople(
                      (e as DropDownOptionObjectType).key as number,
                    ),
                  );
                  props.setValue(
                    'ultimate_beneficial_owner_2',
                    (e as DropDownOptionObjectType).key,
                    { shouldDirty: true },
                  );
                  props.setValue(
                    'ultimate_beneficial_owner_2_name',
                    (e as DropDownOptionObjectType).value,
                    { shouldDirty: true },
                  );
                }}
                options={getUniqueArray(options || [], exCludeFilter)}
              />
              <span
                onClick={() =>
                  ultimate_beneficial_owner_2 &&
                  props.showPeople(ultimate_beneficial_owner_2 as number)
                }
                className={`icon-People ico ${
                  !isExist(ultimate_beneficial_owner_2_name) ? 'disabled' : ''
                }`}
              />
            </div>
          </RowContent>

          <RowContent>
            <div className="d-flex align-items-center mt-2">
              <Form.Label className="my-auto">
                Ultimate Beneficial Owner 3
              </Form.Label>
              <SelectDropDown
                modalId="modal-people"
                contentStyle="w-100"
                preValue={ultimate_beneficial_owner_3_name as string}
                loading={isLoadingPeople}
                onSearch={(d, e) => runSearch(e)}
                onSelectOption={(e) => {
                  props.setSelectedPeople(
                    handleSelectedPeople(
                      (e as DropDownOptionObjectType).key as number,
                    ),
                  );
                  props.setValue(
                    'ultimate_beneficial_owner_3',
                    (e as DropDownOptionObjectType).key,
                    { shouldDirty: true },
                  );
                  props.setValue(
                    'ultimate_beneficial_owner_3_name',
                    (e as DropDownOptionObjectType).value,
                    { shouldDirty: true },
                  );
                }}
                options={getUniqueArray(options || [], exCludeFilter)}
              />
              <div>
                <span
                  onClick={() =>
                    ultimate_beneficial_owner_3 &&
                    props.showPeople(ultimate_beneficial_owner_3 as number)
                  }
                  className={`icon-People ico ${
                    !isExist(ultimate_beneficial_owner_3_name) ? 'disabled' : ''
                  }`}
                />
              </div>
            </div>
          </RowContent>

          <RowContent>
            <div className="d-flex align-items-center mt-2">
              <Form.Label className="my-auto">
                Ultimate Beneficial Owner 4
              </Form.Label>
              <SelectDropDown
                modalId="modal-people"
                contentStyle="w-100"
                preValue={ultimate_beneficial_owner_4_name as string}
                loading={isLoadingPeople}
                onSearch={(d, e) => runSearch(e)}
                onSelectOption={(e) => {
                  props.setSelectedPeople(
                    handleSelectedPeople(
                      (e as DropDownOptionObjectType).key as number,
                    ),
                  );
                  props.setValue(
                    'ultimate_beneficial_owner_4',
                    (e as DropDownOptionObjectType).key,
                    { shouldDirty: true },
                  );
                  props.setValue(
                    'ultimate_beneficial_owner_4_name',
                    (e as DropDownOptionObjectType).value,
                    { shouldDirty: true },
                  );
                }}
                options={getUniqueArray(options || [], exCludeFilter)}
              />
              <span
                onClick={() =>
                  ultimate_beneficial_owner_4 &&
                  props.showPeople(ultimate_beneficial_owner_4 as number)
                }
                className={`icon-People ico my-auto ${
                  !isExist(ultimate_beneficial_owner_4_name) ? 'disabled' : ''
                }`}
              />
            </div>
          </RowContent>
        </div>
      </div>
    </Col>
  );
}
