import React, { useCallback, useEffect, useState } from 'react';
import {
  EntityLimits,
  InputFormLimits,
  LimiteResponse,
} from '../../../../@types/limit/types';
import { useForm } from 'react-hook-form';
import {
  useCreateParticipationDealLimitMutation,
  useGetConterPartyIdMutation,
  useGetDealsByIdMutation,
  useGetLimitByIdMutation,
  useUpdateParticipationDealLimitMutation,
} from '../../../../redux/api/ws/participations/participations';
import { EntityMandate } from '../../../../@types/mandates/mandates';
import { EntityDeals } from '../../../../@types/deals/deals';
import { useGetMandateByIdMutation } from '../../../../redux/api/ws/deals/deals';
import { BusinessType, ConterParty } from '../../../../@types/conterParty';
import {
  amountFormat,
  exportDateUi,
  exportDateWs,
  floorNumber,
  getObjectDifferences,
  isExist,
  nullingEmptyValues,
  setDefaultValues,
  setEmptyUndefinedEditValues,
} from '../../../../utils/helper-function';
import { useSearchCofarcoMutation } from '../../../../redux/api/ws/mandates/mandates';
import { useGetGroupeByIdMutation } from '../../../../redux/api/ws/databases/groups';
import { useSearchLegalEntityNameMutation } from '../../../../redux/api/ws/databases/legale-entities';
import { useAppDispatch } from '../../../../hooks/redux';
import { updateNotificationMessage } from '../../../../redux/slice/notificationSlice';
import { updateModalAction } from '../../../../redux/slice/modalSlice';
import { useLazyGetCounterPartyByIdQuery } from '../../../../redux/api/ws/counterparties/get';
import { useSearchLimitMutation } from '../../../../redux/api/ws/participations/participations';
import { FunctionName } from '../../../../@types/common-types';
import { isEmpty } from 'lodash';
type IProps = {
  defaultFormValues: any | null;
  closingModal: (itemsDataSource: any | null) => void;
};
export default function UseFormCreateLimits(props: IProps) {
  const [isShowModalUnsavedChange, setIsShowModalUnsavedChange] =
    useState<boolean>(false);
  const [showOtherModal, setShowOtherModal] = useState<
    FunctionName | undefined
  >();

  const [seachLimits, { isLoading: isSearchLimitLoading }] =
    useSearchLimitMutation();
  const [isCompletedForm, setIsCompletedForm] = useState(false);
  const [counterpartyList, setCounterpartyList] = useState<Array<ConterParty>>(
    [],
  );
  const dispatch = useAppDispatch();
  const [getDeals, { isLoading: isGetDealLoading }] = useGetDealsByIdMutation();
  const [getMandate] = useGetMandateByIdMutation();
  const [getC] = useGetConterPartyIdMutation();
  const [getCofarco] = useSearchCofarcoMutation();
  const [searchLegalEntity] = useSearchLegalEntityNameMutation();
  const [getLimits, { isLoading: isLoadingGetLimit }] =
    useGetLimitByIdMutation();

  const [createDealLimit] = useCreateParticipationDealLimitMutation();

  const [updateDealLimit] = useUpdateParticipationDealLimitMutation();

  const [getGroup, { data: selectedGroup, reset: removeGroup }] =
    useGetGroupeByIdMutation();

  const [getFinancier] = useLazyGetCounterPartyByIdQuery();

  const [legalEntityList, setLegalEntityList] = useState<Array<any>>([]);

  const [selectedDeal, setSelectedDeal] = useState<EntityDeals | null>();
  const [selectedMandate, setSelectedMandate] =
    useState<EntityMandate | null>();

  const [selectedLimitDeal, setSelectedLimitDeal] =
    useState<EntityLimits | null>();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid, dirtyFields },
    control,
    watch,
    getValues,
    setValue,
    reset,
    resetField,
    setError: setFormError,
    clearErrors: clearFormErrors,
  } = useForm<InputFormLimits>({
    mode: 'onBlur',
    defaultValues: {
      limit_date: undefined,
      expiry_date: undefined,
      amount: '0.00',
      currency: selectedDeal?.currency,
      pricing: '',
    },
  });

  const counterparty_id = watch('counterparty_id');
  const obligor_legal_entity_id = watch('obligor_legal_entity_id');
  const country_id = watch('country_id');
  const currency = watch('currency');
  const amount = watch('amount');
  const status = watch('status');
  const pricing = watch('pricing');
  const getDealsById = async (id: number) => {
    try {
      const response = await getDeals({
        id,
      }).unwrap();
      setSelectedDeal(response?.deal);
      if (props.defaultFormValues?.event !== 'edit') {
        setValue('currency', response?.deal?.currency);
      }
      return response;
    } catch (error) {
      return [];
    }
  };

  const handlCounterPartie = async (text: string, type: BusinessType) => {
    try {
      const response = await getCofarco({
        page: 1,
        start: 0,
        limit: 100,
        query: `%${text?.trim()}%`,
        businessType: type,
      }).unwrap();

      const tempOption = response?.counterparty;
      setCounterpartyList(tempOption);
    } catch (error) {
      return [];
    }
  };

  const handleGetLimit = async (id: number) => {
    if (id) {
      try {
        const response = await getLimits({ id }).unwrap();
        const tempOption = response?.limit;
        setSelectedLimitDeal(tempOption);
      } catch (error) {
        return [];
      }
    }
  };

  const handleLegalEntity = async (text: string) => {
    try {
      const response = await searchLegalEntity({
        page: 1,
        start: 0,
        limit: 100,
        query: `%${text?.trim()}%`,
        counterpartyId: selectedDeal?.lead_line_id,
        underwriterType: 'stamp',
      }).unwrap();

      const tempOption = response?.legalentity;
      setLegalEntityList(tempOption);
    } catch (error) {
      return [];
    }
  };

  const handleGetCounterPartyById = async (
    id: string | number,
    isMarket: boolean = false,
  ) => {
    try {
      const response = await getC({
        id,
      }).unwrap();
      const tempOption = response?.counterparty;
      if (isMarket) {
        setValue('counterparty_id', tempOption.id);
        setCounterpartyList([tempOption]);
        return;
      }
    } catch (error) {
      return [];
    }
  };

  const getMandatesById = async (id: number) => {
    try {
      const response = await getMandate({
        id,
      }).unwrap();
      setSelectedMandate(response?.mandate);
      return response;
    } catch (error) {
      return [];
    }
  };

  React.useEffect(() => {
    if (selectedDeal && selectedDeal?.partner_id) {
      handleGetCounterPartyById(selectedDeal?.partner_id);
    }

    if (selectedDeal && selectedDeal?.lead_line_id) {
      handleGetCounterPartyById(selectedDeal?.lead_line_id, true);
    }
  }, [selectedDeal]);

  const resetForm = () => {
    const defaultValue = setDefaultValues<InputFormLimits>(watch());
    removeGroup();
    reset({
      ...defaultValue,
      amount: 0,
      currency: 'usd',
      status: undefined,
      comments: '',
      pricing: '',
    });
    resetField('expiry_date');
    resetField('limit_date');
    setCounterpartyList([]);
    setLegalEntityList([]);
    setSelectedLimitDeal(null);
  };

  const submit = async (data: InputFormLimits) => {
    const params: InputFormLimits = {
      counterparty_id: data.counterparty_id,
      comments: data.comments,
      obligor_legal_entity_id: data.obligor_legal_entity_id,
      status: data.status,
      participation_id: props.defaultFormValues?.participation_id as number,
      limit_id: props.defaultFormValues?.limit_id as number,
      amount: floorNumber(data.amount || 0),
      deal_id: props.defaultFormValues?.deal_id as number,
      nature: '',
      pricing: data.pricing || '',
      id: data.id || -1,
      currency: data.currency,
      limit_date: exportDateWs(data.limit_date) as string,
      expiry_date: exportDateWs(data.expiry_date) as string,
      type: 'participation_obligor',
    };

    if (props.defaultFormValues?.event === 'edit') {
      try {
        const objectDiff = getObjectDifferences(
          props.defaultFormValues,
          params,
        );
        const response = await updateDealLimit({
          form: setEmptyUndefinedEditValues({
            ...objectDiff,
            id: data.id || -1,
          }),
          participationId: data.participation_id as number,
        }).unwrap();
        if (response && response.success) {
          dispatch(
            updateNotificationMessage({
              title: 'Update Participation Obligor Limit',
              body: `Participation Obligor Limit ${response.limit?.number} has been updated !`,
              show: true,
            }),
          );
          props.closingModal(null);
        }
        resetForm();
      } catch (error: any) {
        console.log(error);
      }
    } else {
      try {
        const response = await createDealLimit({
          form: nullingEmptyValues(params),
          participationId: props.defaultFormValues?.participation_id as number,
        }).unwrap();
        if (response && response.success) {
          dispatch(
            updateNotificationMessage({
              title: 'New Participation Obligor Limit',
              body: `Participation Obligor Limit  ${response.limit.number} has been added  !`,
              show: true,
            }),
          );
          props.closingModal(null);
        }
        resetForm();
      } catch (error) {
        console.log(error);
      }
    }
  };

  React.useEffect(() => {
    if (props.defaultFormValues) {
      const selectLimits = props.defaultFormValues;
      if (selectLimits?.event === 'edit') {
        handleGetLimit(selectLimits?.limit_id);
        reset({
          ...selectLimits,
          limit_date: exportDateUi(selectLimits?.limit_date) as string,
          expiry_date: exportDateUi(selectLimits?.expiry_date) as string,
          amount: amountFormat(selectLimits.amount || 0),
        });
      } else {
        reset({
          ...selectLimits,
          amount: '0.00',
          currency: 'usd',
          status: undefined,
          limit_date: undefined,
          expiry_date: undefined,
          comments: '',
        });
      }

      if (selectLimits.entity_obligor) {
        setValue(
          'obligor_legal_entity_id',
          selectLimits.obligor_legal_entity_id,
        );
        setValue('country_id', selectLimits.country_id);
        setLegalEntityList([selectLimits.entity_obligor]);
        if (selectLimits?.group_id) {
          getGroup({ id: selectLimits?.group_id });
        }
      }

      if (selectLimits.mandate_id) {
        getMandatesById(selectLimits.mandate_id);
      }
      if (selectLimits?.deal_id) {
        getDealsById(selectLimits?.deal_id);
      }
    }
  }, [props.defaultFormValues]);

  const runSearch = React.useCallback(
    async (key: keyof InputFormLimits, value: string) => {
      if (key === 'counterparty_id') {
        const type =
          selectedMandate?.mandate_type === 'insurance'
            ? 'underwriter'
            : 'financier';
        await handlCounterPartie(value, type);
      }
      if (key === 'obligor_legal_entity_id') {
        handleLegalEntity(value);
      }
    },
    [props.defaultFormValues, selectedMandate?.mandate_type],
  );

  const showLegalEntity = () => {
    const found = (legalEntityList || []).find(
      (el) => el.id?.toString() === obligor_legal_entity_id?.toString(),
    );
    if (found) {
      dispatch(
        updateModalAction({
          data: {
            ...found,
            group: selectedGroup?.group,
            group_name: selectedGroup?.group.name,
          },
          isAdd: true,
          type: 'legal_entity',
        }),
      );
    }
  };

  const showCounterParty = async () => {
    const financierId = counterparty_id;
    if (financierId) {
      const resp = await getFinancier({
        id: financierId?.toString(),
      }).unwrap();
      if (resp?.success) {
        dispatch(
          updateModalAction({
            data: resp?.counterparty,
            isAdd: true,
            type:
              selectedMandate?.mandate_type === 'insurance'
                ? 'underwriter'
                : 'financier',
          }),
        );
      }
    }
  };

  React.useEffect(() => {
    const subscription = watch((value) => {
      let isCompletedCurrency = false;
      const isCompletedRequired =
        isExist(value.counterparty_id) &&
        isExist(value.obligor_legal_entity_id);

      if (((floorNumber(value?.amount) as number) || 0) > 0) {
        isExist(value.currency)
          ? (isCompletedCurrency = true)
          : (isCompletedCurrency = false);
      } else {
        isCompletedCurrency = true;
      }
      setIsCompletedForm(
        isCompletedRequired && isCompletedCurrency ? true : false,
      );
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (
      props.defaultFormValues?.deal_id &&
      props.defaultFormValues.event !== 'edit' &&
      isEmpty(selectedLimitDeal)
    ) {
      // meanng that the deal is not selected yet , so we need to get the deal by id,
      seachLimits({
        query: undefined,
        dealId: props.defaultFormValues?.deal_id,
      }).then((res) => {
        if ((res as { data: LimiteResponse }).data.limit?.length > 0) {
          console.log(
            '(res as { data: LimiteResponse }).data.limit props.defaultFormValues?.limit_id',
            (res as { data: LimiteResponse }).data.limit,
            props.defaultFormValues?.limit_id,
          );
          const dealLimit = (res as { data: LimiteResponse }).data.limit?.find(
            (el) => el.id === props.defaultFormValues?.limit_id,
          );
          if (!isEmpty(dealLimit)) {
            setSelectedLimitDeal(dealLimit);
          }
        }
      });
    }
  }, [selectedLimitDeal, props.defaultFormValues]);

  const isEdit = props.defaultFormValues?.event === 'edit';

  const handleShowOtherModal = (func: FunctionName) => {
    if (isDirty && isEdit) {
      setIsShowModalUnsavedChange(true);
      setShowOtherModal(func);
    } else {
      handleLeaveModalWithoutSave(func);
    }
  };

  const handleLeaveModalWithoutSave = (func?: FunctionName) => {
    setIsShowModalUnsavedChange(false);
    showFunctions(func);
    isEdit && resetForm();
  };

  const showFunctions = useCallback(
    (func?: FunctionName) => {
      switch (func) {
        case 'legal_entity':
          showLegalEntity();
          break;
        case 'counterparty':
          showCounterParty();
          break;
        default:
          break;
      }
    },
    [showLegalEntity, showLegalEntity],
  );

  return {
    register,
    handleSubmit,
    errors,
    isDirty,
    isValid,
    control,
    watch,
    getValues,
    setValue,
    reset,
    setFormError,
    clearFormErrors,
    resetForm,
    submit,
    isCompletedForm,
    selectedDeal,
    selectedMandate,
    runSearch,
    counterparty_id,
    showCounterParty,
    counterpartyList,
    legalEntityList,
    obligor_legal_entity_id,
    showLegalEntity,
    country_id,
    selectedGroup,
    currency,
    amount,
    status,
    pricing,
    selectedLimitDeal,
    removeGroup,
    getGroup,
    isLoading: isGetDealLoading || isLoadingGetLimit || isSearchLimitLoading,
    isShowModalUnsavedChange,
    handleShowOtherModal,
    handleLeaveModalWithoutSave,
    setIsShowModalUnsavedChange,
    showFunctions,
    showOtherModal,
    dirtyFields,
  };
}
