import React, { useState } from 'react';

import { ReactComponent as ArrowDown } from '../../assets/img/Arrow-Down-Gray.svg';
import { ReactComponent as ArrowUP } from '../../assets/img/Arrow-UP-Gray.svg';
import FilterTab from '../../utils/filter-tab';
import type { ColumnsType, ColumnType } from 'antd/es/table';
import {
  amountFormat,
  exportedDropdownValue,
  handleRegion,
  isCan,
  limitedText,
} from '../../utils/helper-function';

import UseConstante from '../../hooks/use-constante';
import { Menu, TermConst } from '../../@types/common-types';
import { useGetOwnerQuery } from '../../redux/api/ws/mandates/mandates';
import {
  EntityParticipation,
  ParticipationExport,
} from '../../@types/participation/participation';
import { exportedParticipations } from '../../utils/exportedParticipations';
import { ReactComponent as Delete } from '../../assets/img/Delete.svg';
import { ReactComponent as Edit } from '../../assets/img/Edit.svg';
import classes from './Participation.module.scss';
import { useGetCountryQuery } from '../../redux/api/ws/databases/groups';
import { EntityCountry } from '../../@types/group/types';
import FlagItem from '../FlagItem/FlagItem';
import { capitalize } from 'lodash';
import { Cartridge } from '../common/status/Cartridge';
type DataIndex = keyof EntityParticipation;

type IProps = {
  handleSorted: (key: string, ruls: 'ASC' | 'DESC') => void;
  handleDeleteParticipation: (id: number, data?: EntityParticipation) => void;
  participations: Array<EntityParticipation>;
  editParticipation: (id: number, data?: EntityParticipation) => void;
  otherColumns?: string[];
  columnsToRemove?: string[];
};
export default function ColumnParticipation(props: IProps) {
  const [dropDownFilterState, setDropDownFilterState] =
    useState<boolean>(false);

  const { data: responseCountry } = useGetCountryQuery();

  const countries: Array<EntityCountry> = responseCountry?.country || [];

  const terms = UseConstante();

  const [uncheckedId, setUncheckedId] = useState<Array<number>>([]);
  const onChangeFilterColumn = (key: number | undefined) => {
    const found = uncheckedId?.find((uncheckedTitle) => uncheckedTitle === key);
    if (found) {
      setUncheckedId(uncheckedId.filter((t) => t !== key));
    } else {
      setUncheckedId([...uncheckedId, key || 0]);
    }
  };

  const columnsExport: Array<keyof ParticipationExport> =
    exportedParticipations.map((el) => el.itemId as keyof ParticipationExport);

  const getColumnSearchProps = (
    dataIndex:
      | DataIndex
      | 'counterpartyId'
      | 'clientName'
      | 'obligorName'
      | 'deal_type'
      | 'obligor_commercial_name'
      | 'participantName',
  ): ColumnType<EntityParticipation> => ({
    filterDropdown: ({ close }) =>
      FilterTab<EntityParticipation>({
        columns: columns,
        dataIndex: dataIndex,
        handleSorted: (dataIndex: string, rule: 'ASC' | 'DESC') =>
          props.handleSorted(dataIndex, rule),
        onChangeFilterColumn: (key: number | undefined) =>
          onChangeFilterColumn(key),
        uncheckedId: uncheckedId,
        close: () => close(),
      }),
    filterIcon: () => {
      return dropDownFilterState ? (
        <ArrowDown width={12} height={12} />
      ) : (
        <ArrowUP width={12} height={12} />
      );
    },
    onFilterDropdownOpenChange: (visible) => {
      setDropDownFilterState(visible);
    },
    render: (text: string) => <span>{text}</span>,
  });

  const { data: ownerList } = useGetOwnerQuery();

  const getCartridgeStatus = (status: string) => {
    status = status.trim().toLowerCase().replaceAll('_', '-');
    switch (status) {
      case 'on-risk':
        return 'nbi';
      case 'binding':
        return 'pending';
      case 'off-risk':
        return 'nty';
      default:
        return 'status';
    }
  };

  const columns: ColumnsType<EntityParticipation> = React.useMemo(
    () =>
      [
        {
          title: 'ID',
          dataIndex: 'number',
          key: 1,
          className: classes.fixedSizeHeaderId,
          ...getColumnSearchProps('number'),
          render: (text: string) => (
            <div style={{ minWidth: '80px' }}>
              <span> {text}</span>
            </div>
          ),
        },
        {
          title: 'DEAL ID',
          dataIndex: 'deal_number',
          key: 2,
          className: classes.fixedSizeHeaderId,
          ...getColumnSearchProps('deal_number'),
          render: (text: string, record: any) => (
            <div style={{ minWidth: '80px' }}>
              <span> {text ?? record.deal.number}</span>
            </div>
          ),
        },
        {
          title: 'PARTICIPANT',
          dataIndex: 'participant_name',
          key: 3,
          className: classes.fixedSizeHeaderTxt,
          ...getColumnSearchProps('participantName'),
          render: (text: string) => (
            <div
              style={{
                minWidth: '140px',
                maxWidth: '140px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              <span>
                <span>{text}</span>
              </span>
            </div>
          ),
        },
        {
          title: 'CLIENT',
          dataIndex: 'client_name',
          key: 4,
          className: classes.fixedSizeHeaderTxt,
          ...getColumnSearchProps('clientName'),
          render: (text: string) => (
            <div
              style={{
                minWidth: '140px',
                maxWidth: '140px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              <span> {text}</span>
            </div>
          ),
        },
        {
          title: 'DEAL TYPE',
          dataIndex: 'deal_type',
          key: 7,
          hidden: !props?.otherColumns?.includes('deal_type'),
          className: classes.fixedSizeHeaderTxt,
          ...getColumnSearchProps('deal_type'),
          render: (text: string, record: EntityParticipation) => {
            return `Contrat ${capitalize(record.deal?.deal_type ?? '')}`;
          },
        },
        {
          title: 'TOP OBLIGOR',
          dataIndex: 'obligor_name',
          key: 5,
          ...getColumnSearchProps('obligorName'),
          className: classes.fixedSizeHeaderObligor,
          render: (text: string) => (
            <div
              style={{
                minWidth: '170px',
                maxWidth: '170px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              <span title={text}> {text}</span>
            </div>
          ),
        },
        {
          title: 'TOP OBLIGOR COUNTRY',
          dataIndex: 'obligor_country_id',
          key: 11,
          className: classes.fixedSizeHeaderTxt,
          hidden: !props?.otherColumns?.includes('top_obligor'),
          ...getColumnSearchProps('obligor_country_id'),
          render: (text: string) => {
            if (text) {
              const region = handleRegion(countries, text);
              return (
                region && (
                  <div
                    style={{
                      minWidth: '150px',
                      textOverflow: 'clip',
                      overflow: 'hidden',
                    }}
                    title={region?.name}
                  >
                    <FlagItem
                      code={region.code}
                      region_name={limitedText(region?.name, 17)}
                    />
                  </div>
                )
              );
            }
          },
        },
        {
          title: 'FINANCIER/UNDERWRITER',
          dataIndex: 'lead_line_name',
          key: 12,
          hidden: !props?.otherColumns?.includes('financier_underwriter'),
          className: classes.fixedSizeHeaderTxt,
          ...getColumnSearchProps('lead_line_name'),
          render: (text: string) => {
            return (
              <div
                style={{
                  minWidth: '150px',
                  textOverflow: 'clip',
                  overflow: 'hidden',
                }}
              >
                <span>
                  <span>{text ?? ''}</span>
                </span>
              </div>
            );
          },
        },
        {
          title: 'AMOUNT',
          dataIndex: 'amount',
          key: 6,
          className: classes.fixedSizeHeaderTxt,
          ...getColumnSearchProps('amount'),
          render: (text: number) => {
            if (text) {
              return (
                <div
                  style={{
                    minWidth: '140px',
                    maxWidth: '140px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  <span>
                    <span>{amountFormat(text)}</span>
                  </span>
                </div>
              );
            }
          },
        },

        {
          title: 'CUR.',
          dataIndex: 'currency',
          key: 8,
          className: classes.fixedSizeHeaderId,
          ...getColumnSearchProps('currency'),
          render: (text: string) => {
            if (text) {
              return (
                <span>
                  <span>{text.toUpperCase()}</span>
                </span>
              );
            }
          },
        },
        {
          title: 'STATUS',
          dataIndex: 'status',
          key: 9,
          className: classes.fixedSizeHeaderTxt,
          ...getColumnSearchProps('status'),
          render: (text: string) => {
            if (text) {
              return (
                <Cartridge
                  label={exportedDropdownValue(
                    terms?.getConst(TermConst.participationStatus) || [],
                    text,
                  )}
                  value={getCartridgeStatus(text)}
                />
              );
            }
          },
        },

        {
          title: '',
          dataIndex: 'id',
          key: 10,
          width: 120,
          onCell: () => {
            return {
              onClick: (event: any) => {
                event.stopPropagation();
              },
            };
          },
          hidden:
            !isCan('write', Menu.participation) &&
            !isCan('delete', Menu.participation),
          render: (text: number, record: EntityParticipation) => {
            return (
              <>
                {isCan('write', Menu.participation) && (
                  <span style={{ minWidth: 95 }}>
                    <i
                      className="rounded-icon"
                      onClick={() => props.editParticipation(text, record)}
                    >
                      <Edit height="30" />
                    </i>
                  </span>
                )}
                {isCan('delete', Menu.participation) && (
                  <span style={{ minWidth: 55 }}>
                    <i
                      className="rounded-icon"
                      onClick={() =>
                        props.handleDeleteParticipation(text, record)
                      }
                    >
                      <Delete height="30" />
                    </i>
                  </span>
                )}
              </>
            );
          },
        },
      ]
        .filter((el) => !el.hidden)
        .filter(
          (el) => !props?.columnsToRemove?.includes(el.dataIndex as DataIndex),
        ),
    [
      props.participations,
      dropDownFilterState,
      uncheckedId,
      terms,
      ownerList,
      props?.columnsToRemove,
      props?.otherColumns,
    ],
  );

  return { columns, uncheckedId, columnsExport };
}
