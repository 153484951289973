import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import {
  InputFormDetailCofarcoTeam,
  IPwd,
} from '../../../@types/cofarco-teams/type';
import { Checkbox, Popover, Table, Button as BtnAnt } from 'antd';
import classes from './ModalDetail.module.scss';
import { mockTitle } from '../../../utils/mock-title';
import { mockTreeBtn } from '../../../utils/mock-tre-btn';
import { ReactComponent as Loader } from '../../../assets/img/Loading.svg';
import { ReactComponent as Cle } from '../../../assets/img/Pwd.svg';
import { useUpdateCofarcoPwdMutation } from '../../../redux/api/ws/databases/cofarco-teams';
import SelectDropDown from '../../common/DropDown/SelectDropDown';
import { DropDownOptionObjectType } from '../../common/DropDown/DropdownSelect';
import UseModalDetail from './UseModalCofarcoDetail';
import FormModal from '../../Modal/FormModal/FormModal';
import CofarcoFormText from '../../Form/CofarcoFormText';
import UseConstante from '../../../hooks/use-constante';
import { Menu, TermConst } from '../../../@types/common-types';
import {
  concatTitle,
  isCan,
  makeOptions,
} from '../../../utils/helper-function';
import CofarcoCalendar from '../../Calendar/CofarcoCalendar';
import RowContent from '../../Form/RowContent';
import FormError from '../../FormError/FormError';

export type DataType = {
  object: string;
  read: boolean;
  create: boolean;
  delete: boolean;
  write: boolean;
  extract: boolean;
};

type IProps = {
  show: boolean;
  handleClose: () => void;
  defaultFormValues: InputFormDetailCofarcoTeam | null;
};

export default function ModalCofarcoTeamDetail(props: IProps) {
  const provider = UseModalDetail({
    defaultFormValues: props.defaultFormValues,
    closingModal: props.handleClose,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<IPwd>({ mode: 'onChange' });
  const [isCompletedForm, setIsCompletedForm] = useState<boolean>(false);

  const title = provider.watchDetail('username');
  const email = provider.watchDetail('email');
  const labelRef = useRef<HTMLLabelElement>(null);

  const isInactif = provider.watchDetail('is_inactif') ? true : false;
  const isAdmin = provider.watchDetail('is_admin') ? true : false;
  const titleCofarco = provider.watchDetail('title');
  const teamCofarco = provider.watchDetail('team');
  const id = provider.watchDetail('id');
  const password = React.useRef({});
  password.current = watch('password', '');
  const [open, setOpen] = useState(false);
  const [updatePwd, { isLoading: isLoading }] = useUpdateCofarcoPwdMutation();
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };
  const cancelPwdEdit = () => {
    reset();
    setOpen(false);
  };

  const submitUpdatePwd = async (data: IPwd) => {
    try {
      const response = await updatePwd({
        data: {
          ...data,
          people_id: props.defaultFormValues?.people_id,
          email,
        },
        id: id,
      }).unwrap();
      if (response && response.success) {
        cancelPwdEdit();
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const updatePrefetch = (tab: Array<any>, txt: string) =>
    tab?.find((el) => el.key === txt);

  React.useEffect(() => {
    const subscription = watch((value) => {
      const isCompletedRequired = value.password && value.password_confirmation;
      setIsCompletedForm(isCompletedRequired ? true : false);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const terms = UseConstante();
  const hiddenFooter = !isCan('write', Menu.user);

  const labelWidth = useMemo(() => {
    if (labelRef.current) {
      return labelRef.current.offsetWidth;
    }
    return 0;
  }, [labelRef.current]);

  useEffect(() => {
    if (isInactif) {
      provider.setEnquireValidate('none');
      provider.setMandate('none');
      provider.setInvoice('none');
      provider.setPolicy('none');
      provider.setDataSourceRigth([]);
    } else {
      provider.setEnquireValidate(provider.validateCopy.enquireValidate);
      provider.setMandate(provider.validateCopy.mandate);
      provider.setInvoice(provider.validateCopy.invoice);
      provider.setPolicy(provider.validateCopy.policy);
      provider.setDataSourceRigth(provider.dataSourceRigthCopy);
    }
  }, [isInactif]);

  return (
    <>
      <FormModal
        show={props.show}
        handleClose={() => {
          provider.resetForm();
          props.handleClose();
        }}
        header={<span> Cofarco Team {concatTitle(title)}</span>}
        onSubmitForm={(e) => {
          provider.handleSubmitDetail(provider.submitDetail)(e);
          e.stopPropagation();
        }}
        isDisabledSave={!provider.isDirty && !provider.isEditingForm}
        isLoading={provider.isLoadingUpdate}
        ishiddenfooter={hiddenFooter}
        children={
          <Container className="mt-4">
            <Row>
              <Col>
                <RowContent>
                  <CofarcoFormText
                    label="Email"
                    errors={provider.errorDetail}
                    key_form="email"
                    class="w-100 align-items-baseline"
                    register={provider.registerDetail('email')}
                  />
                </RowContent>

                <RowContent>
                  <Form.Group className="d-flex align-items-baseline">
                    <Form.Label className="txt-with-dropdown">Title</Form.Label>

                    <div className="d-flex d-flex flex-column w-100">
                      <SelectDropDown
                        noPadding
                        allowNew
                        modalId="modal-group"
                        preValue={
                          updatePrefetch(mockTitle, titleCofarco)?.value || ''
                        }
                        noSearch
                        onSelectOption={(e) => {
                          provider.setIsEditingForm(true);
                          provider.setValueDetail(
                            'title',
                            (e as DropDownOptionObjectType).key as string,
                          );
                        }}
                        options={mockTitle}
                      />
                    </div>
                  </Form.Group>
                </RowContent>

                <RowContent>
                  <Form.Group className="d-flex align-items-baseline">
                    <Form.Label className="txt-with-dropdown">Team</Form.Label>

                    <div
                      className="d-flex flex-column w-100"
                      style={{ flexDirection: 'column', width: '100%' }}
                    >
                      <SelectDropDown
                        allowNew
                        noPadding
                        modalId="modal-group"
                        preValue={
                          updatePrefetch(
                            makeOptions(terms?.getConst(TermConst.staffTeam)),
                            teamCofarco,
                          )?.value || ''
                        }
                        noSearch
                        onSelectOption={(e) => {
                          provider.setIsEditingForm(true);
                          provider.setValueDetail(
                            'team',
                            (e as DropDownOptionObjectType).key as string,
                          );
                        }}
                        options={makeOptions(
                          terms?.getConst(TermConst.staffTeam),
                        )}
                      />
                    </div>
                  </Form.Group>
                </RowContent>
              </Col>
              <Col>
                <div
                  className="d-flex align-items-baseline"
                  style={{ width: '150px', paddingTop: '.5rem' }}
                >
                  <Form.Group className="d-flex">
                    <Form.Label className="txt">Enquiry Validation</Form.Label>
                  </Form.Group>

                  {mockTreeBtn.map((el, index) => (
                    <div
                      className="list-btn"
                      style={{ flexDirection: 'column', width: '100%' }}
                      key={index}
                      onClick={() => {
                        setIsCompletedForm(true);
                        provider.setEnquireValidate(el.value);
                      }}
                    >
                      <span
                        className={`${
                          provider.enquireValidate === el.value
                            ? 'selected'
                            : 'all'
                        } `}
                      >
                        {el.label}
                      </span>
                    </div>
                  ))}
                </div>

                <div
                  className="d-flex align-items-baseline"
                  style={{ width: '150px', paddingTop: '2.5rem' }}
                >
                  <Form.Group className="d-flex ">
                    <Form.Label className="txt">Mandate Validation</Form.Label>
                  </Form.Group>

                  {mockTreeBtn.map((el, index) => (
                    <div
                      className="list-btn"
                      style={{ flexDirection: 'column', width: '100%' }}
                      onClick={() => {
                        provider.setMandate(el.value);
                        provider.setIsEditingForm(true);
                      }}
                      key={index}
                    >
                      <span
                        className={`${
                          provider.mandate === el.value ? 'selected' : 'all'
                        } `}
                      >
                        {el.label}
                      </span>
                    </div>
                  ))}
                </div>

                <div
                  className="d-flex align-items-baseline"
                  style={{ width: '150px', paddingTop: '2.2rem' }}
                >
                  <Form.Group className="d-flex ">
                    <Form.Label className="txt">Invoice Raising</Form.Label>
                  </Form.Group>

                  {mockTreeBtn.map((el, index) => (
                    <div
                      className="list-btn"
                      style={{ flexDirection: 'column', width: '100%' }}
                      onClick={() => {
                        provider.setIsEditingForm(true);
                        provider.setInvoice(el.value);
                      }}
                      key={index}
                    >
                      {/* selected */}
                      <span
                        className={`${
                          provider.invoice === el.value ? 'selected' : 'all'
                        } `}
                      >
                        {el.label}
                      </span>
                    </div>
                  ))}
                </div>

                <div
                  className="d-flex  align-items-baseline mb-4"
                  style={{
                    width: '150px',
                    paddingTop: '2.2rem',
                  }}
                >
                  <Form.Group className="d-flex ">
                    <Form.Label className="txt">Policy Validation</Form.Label>
                  </Form.Group>

                  {mockTreeBtn.map((el, index) => (
                    <div
                      className="list-btn"
                      style={{ flexDirection: 'column', width: '100%' }}
                      onClick={() => {
                        provider.setIsEditingForm(true);
                        provider.setPolicy(el.value);
                      }}
                      key={index}
                    >
                      {/* selected */}
                      <span
                        className={`${
                          provider.policy === el.value ? 'selected' : 'all'
                        } `}
                      >
                        {el.label}
                      </span>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <div>
                  <Form.Group className="mb-3 d-flex align-items-baseline">
                    <Form.Label className="txt">Entry Date</Form.Label>
                    <CofarcoCalendar
                      control={provider.controlDetail}
                      name="entry_date"
                      setFormValue={provider.setValueDetail}
                      watchFormValue={provider.watchDetail}
                    />
                  </Form.Group>
                </div>
              </Col>
              <Col>
                <div>
                  <Form.Group className="mb-3 d-flex align-items-baseline">
                    <Form.Label className="txt">Departure Date</Form.Label>
                    <CofarcoCalendar
                      control={provider.controlDetail}
                      name="departure_date"
                      setFormValue={provider.setValueDetail}
                      watchFormValue={provider.watchDetail}
                    />
                  </Form.Group>
                </div>
              </Col>
            </Row>
            <Row className="py-3">
              <Col>
                <div className="d-flex align-items-baseline">
                  <Form.Label className="radio-echange">
                    Administrator
                  </Form.Label>
                  <div className="mb-3 mt-1 ml-2 ml-spacer">
                    <Controller
                      name="is_admin"
                      control={provider.controlDetail}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          checked={!!field.value}
                          onChange={(e) =>
                            field.onChange(e.target.checked ? 1 : 0)
                          }
                        />
                      )}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="py-3">
              <Col>
                <div className="d-flex align-items-baseline">
                  <Form.Label className="radio-echange">Actif</Form.Label>
                  <div className="mb-3 mt-1 ml-2 ml-spacer">
                    <Controller
                      name="is_inactif"
                      control={provider.controlDetail}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          checked={!field.value || field.value === 0}
                          onChange={(e) =>
                            field.onChange(e.target.checked ? 0 : 1)
                          }
                        />
                      )}
                    />
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="py-1">
              <Col>
                <div className="d-flex align-items-baseline">
                  <div className="mb-3 mt-1 ml-2 ml-spacer">
                    <Popover
                      className="p-20"
                      content={
                        <form
                          key={'pwd-form'}
                          onSubmit={(e) => {
                            handleSubmit(submitUpdatePwd)(e);
                            e.stopPropagation();
                          }}
                        >
                          <Col className="p-20">
                            <div className="mb-3">
                              <Form.Group className="d-flex align-items-baseline">
                                <Form.Label
                                  className="txt"
                                  style={{ width: '160px' }}
                                  ref={labelRef}
                                >
                                  Password
                                </Form.Label>
                                <div
                                  className="d-flex"
                                  style={{
                                    flexDirection: 'column',
                                    minWidth: '250px',
                                    width: '100%',
                                  }}
                                >
                                  <Form.Control
                                    type="password"
                                    className={`${
                                      errors.password && 'invalid'
                                    }`}
                                    {...register('password', {
                                      required: true,
                                      minLength: 8,
                                    })}
                                    autoComplete="off"
                                  />
                                </div>
                              </Form.Group>
                              {errors.password?.type === 'required' && (
                                <FormError
                                  message="This field is required"
                                  classNameStyle="text-start"
                                  meesageStyle={{ fontSize: 12 }}
                                  style={{ marginInlineStart: labelWidth }}
                                />
                              )}
                              {errors.password?.type === 'minLength' && (
                                <FormError
                                  message="The minimum length for this field is 8"
                                  classNameStyle="text-start"
                                  meesageStyle={{ fontSize: 12 }}
                                  style={{ marginInlineStart: labelWidth }}
                                />
                              )}
                            </div>
                            <div className="mb-3">
                              <Form.Group className="d-flex align-items-baseline">
                                <Form.Label
                                  className="txt"
                                  style={{ width: '160px' }}
                                >
                                  Confirm Password
                                </Form.Label>
                                <div
                                  className="d-flex"
                                  style={{
                                    flexDirection: 'column',
                                    minWidth: '200px',
                                    width: '100%',
                                  }}
                                >
                                  <Form.Control
                                    type="password"
                                    className={`${
                                      errors.password_confirmation && 'invalid'
                                    }`}
                                    {...register('password_confirmation', {
                                      required: true,
                                      minLength: 8,
                                      validate: (txt: string) =>
                                        txt.toString() ===
                                        password.current?.toString(),
                                    })}
                                    autoComplete="off"
                                  />
                                </div>
                              </Form.Group>
                              {errors.password_confirmation?.type ===
                                'required' && (
                                <FormError
                                  message="This field is required"
                                  classNameStyle="text-start"
                                  meesageStyle={{ fontSize: 12 }}
                                  style={{ marginInlineStart: labelWidth }}
                                />
                              )}
                              {errors.password_confirmation?.type ===
                                'minLength' && (
                                <FormError
                                  message="The minimum length for this field is 8"
                                  classNameStyle="text-start"
                                  meesageStyle={{ fontSize: 12 }}
                                  style={{ marginInlineStart: labelWidth }}
                                />
                              )}

                              {errors.password_confirmation?.type ===
                                'validate' && (
                                <FormError
                                  message="Passwords does not match"
                                  classNameStyle="text-start"
                                  meesageStyle={{ fontSize: 12 }}
                                  style={{ marginInlineStart: labelWidth }}
                                />
                              )}
                            </div>
                            <div className="d-flex align-items-center justify-content-center">
                              <Button
                                className="btn primary mx-2"
                                onClick={() => cancelPwdEdit()}
                              >
                                Cancel
                              </Button>
                              <Button
                                className={`btn submit ${
                                  !isCompletedForm ? 'disabled' : ''
                                } `}
                                type="submit"
                                disabled={isLoading}
                                id="pwd-form"
                              >
                                {isLoading ? <Loader /> : 'Modify'}
                              </Button>
                            </div>
                          </Col>
                        </form>
                      }
                      getPopupContainer={(triggerNode) => triggerNode}
                      title="Modify password"
                      trigger="click"
                      placement="left"
                      open={open}
                      onOpenChange={handleOpenChange}
                    >
                      {!hiddenFooter && (
                        <BtnAnt type="primary" className="btn cutom-btn">
                          <Cle /> <span className="px-1">Modify password</span>
                        </BtnAnt>
                      )}
                    </Popover>
                  </div>
                </div>
              </Col>
            </Row>
            <div style={{ height: '1px', backgroundColor: '#DBDDDF' }} />
            {!isAdmin && (
              <>
                <Row>
                  <div className="d-flex align-items-baseline pt-4">
                    <Form.Label className="boldered-text-title">
                      Rights
                    </Form.Label>
                  </div>
                  <div
                    style={{ width: '55%', overflow: 'auto' }}
                    className="py-4"
                  >
                    <Table
                      style={{ border: '1px solid white', borderRadius: '8px' }}
                      dataSource={provider.dataSourceRigth}
                      columns={provider.columns}
                      rowClassName={(_, index) =>
                        `${index % 2 === 1 ? classes.tablelight : ''} ${
                          classes.tablerow
                        }`
                      }
                      tableLayout="fixed"
                      pagination={false}
                      rowKey="object"
                    />
                  </div>
                </Row>
                <Row className="py-4">
                  <Col>
                    <div className="d-flex align-items-baseline">
                      <Form.Label className="radio-echange">History</Form.Label>
                      <div className="mb-3 mt-1 ml-2 ml-spacer">
                        <Controller
                          name="history"
                          control={provider.controlDetail}
                          render={({ field }) => (
                            <Checkbox
                              {...field}
                              checked={field.value}
                              onChange={(e) => field.onChange(e.target.checked)}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </Container>
        }
      />
    </>
  );
}
