import { Card, Col, Flex, Row } from 'antd';
import { useEffect, useMemo } from 'react';
import {
  useClientsQuery,
  useMarketsQuery,
  useTransactionsQuery,
} from '../../redux/api/ws/dashboard/dashboard';

type Props = {
  id: any;
  title: string;
  data: Array<{ label: string; value: number; color: string }>;
};
const DrawCircle = ({ id, title, data }: Props) => {
  useEffect(() => {
    let tooltip: HTMLDivElement | null;

    const canvas = document.getElementById(
      `dashboad-circle-${id}`,
    ) as HTMLCanvasElement;

    if (!canvas) return;

    const context = canvas.getContext('2d');

    if (!context) return;

    // Reset canvas
    context.clearRect(0, 0, canvas.width, canvas.height);

    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    const radius = Math.min(centerX, centerY) - 10;

    let startAngle = 0;

    const total = data.reduce((sum, slice) => sum + slice.value, 0);

    context.lineWidth = 9; // Adjust the line width as needed

    if (total > 0) {
      data.forEach((slice) => {
        // Get percentage of the slice
        const endAngle = startAngle + (slice.value * 2 * Math.PI) / total;

        context.strokeStyle = slice.color; // Adjust the stroke color
        context.beginPath();
        context.arc(centerX, centerY, radius, startAngle, endAngle);

        context.stroke();

        // Update start angle for the next segment
        startAngle = endAngle;
      });
    } else {
      context.strokeStyle = '#F1F2F2';
      context.beginPath();
      context.arc(centerX, centerY, radius, 0, 2 * Math.PI);
      context.stroke();
    }

    // Draw the total at the center

    context.lineWidth = 1;
    context.fillStyle = '#1A171B';
    context.font = 'normal 24px Calibri, sans-serif';
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    context.fillText(`${total}`, centerX, centerY);

    const handleMouseHover = (event: MouseEvent) => {
      const rect = canvas.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;

      const angle = Math.atan2(y - centerY, x - centerX);
      let segmentIndex = -1;

      // Convert the angle to be between 0 and 2*PI
      const normalizedAngle = angle < 0 ? angle + 2 * Math.PI : angle;

      // Check which segment the mouse is over
      data.some((slice, index) => {
        const startAngle = data
          .slice(0, index)
          .reduce((sum, s) => sum + (s.value / total) * 2 * Math.PI, 0);
        const endAngle = startAngle + (slice.value / total) * 2 * Math.PI;

        if (normalizedAngle >= startAngle && normalizedAngle <= endAngle) {
          segmentIndex = index;
          return true; // exit the loop if we found the segment
        }

        return false;
      });

      if (segmentIndex !== -1) {
        showTooltip(
          event,
          `${data[segmentIndex].label} : ${data[segmentIndex].value}`,
          data[segmentIndex].color,
        );
      }
    };

    const handleMouseLeave = () => {
      if (tooltip) {
        try {
          document.body.removeChild(tooltip);
        } catch (e: any) {
          console.log(e?.message);
        }
      }
    };

    // Add event listeners
    canvas.addEventListener('mousemove', handleMouseHover);
    canvas.addEventListener('mouseleave', handleMouseLeave);

    // Function to show tooltips
    function showTooltip(event: MouseEvent, text: string, color: string) {
      // Remove the existing tooltip if it exists
      if (!tooltip) {
        tooltip = document.createElement('div');
      }

      // Create and show a new tooltip
      tooltip.style.background = color;
      tooltip.style.paddingLeft = '10px';
      tooltip.style.paddingRight = '10px';
      tooltip.style.color = 'white';
      tooltip.style.position = 'absolute';
      tooltip.style.borderRadius = '5px';
      tooltip.style.left = `${event.clientX + 10}px`;
      tooltip.style.top = `${event.clientY}px`;
      tooltip.textContent = text;

      document.body.appendChild(tooltip);
    }

    // Cleanup event listeners andt on component unmount
    return () => {
      canvas.removeEventListener('mousemove', handleMouseHover);
      canvas.removeEventListener('mouseleave', handleMouseLeave);
      if (tooltip) {
        try {
          document.body.removeChild(tooltip);
        } catch (err: any) {
          console.log(err?.message);
        } finally {
          tooltip = null;
        }
      }
    };
  }, [data, id]);

  return (
    <Card
      style={{ height: '100%' }}
      size="small"
      className="dashboard-base-card"
      bordered={false}
    >
      <Flex vertical justify="center" align="center">
        <div className="dashboard-title-center">{title}</div>
        <canvas
          style={{ marginTop: 14 }}
          id={`dashboad-circle-${id}`}
          width={130}
          height={130}
        />
        <div id={`tooltip`}></div>
      </Flex>
    </Card>
  );
};

export const DashboadCircles = () => {
  const { data: clients } = useClientsQuery({});
  const { data: transactions } = useTransactionsQuery({});
  const { data: markets } = useMarketsQuery({});

  const circles = useMemo(() => {
    return [
      {
        title: 'Clients',
        data: [
          {
            label: 'active clients',
            value: clients?.total_active_clients || 0,
            color: '#EB5D57',
          },
          {
            label: 'prospects',
            value: clients?.total_prospects || 0,
            color: '#6D1206',
          },
        ],
      },
      {
        title: 'Transactions',
        data: [
          {
            label: 'deals',
            value: transactions?.total_deal || 0,
            color: '#EB5D57',
          },
          {
            label: 'enquiries',
            value: transactions?.total_enquiry || 0,
            color: '#6D1206',
          },
          {
            label: 'leads',
            value: transactions?.total_lead || 0,
            color: '#53585F',
          },
          {
            label: 'other',
            value:
              (transactions?.total_offer || 0) +
              (transactions?.total_mandate || 0),
            color: '#F39E9A',
          },
        ],
      },
      {
        title: 'Markets',
        data: [
          {
            label: 'direct',
            value: markets?.direct || 0,
            color: '#6D1206',
          },
          {
            label: 'indirect',
            value: markets?.indirect || 0,
            color: '#C72506',
          },
        ],
      },
    ];
  }, [clients, transactions, markets]);

  return (
    <Row
      align="stretch"
      gutter={[24, 24]}
      style={{ marginTop: 24, marginBottom: 24 }}
    >
      {circles.map((v, index) => (
        <Col key={index} xs={24} md={8}>
          <DrawCircle data={v.data} title={v.title} id={index} />
        </Col>
      ))}
    </Row>
  );
};
