import { Row } from 'react-bootstrap';
import { FaEnvelope } from 'react-icons/fa';
import TierForm from '../TierForm/TierForm';
import {
  FieldErrorsImpl,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form';
import validator from 'validator';
import { PeopleFormInputs } from '../../../../../@types/people';

type Props = {
  register: UseFormRegister<PeopleFormInputs>;
  watchValue: UseFormWatch<PeopleFormInputs>;
  errors: Partial<FieldErrorsImpl<PeopleFormInputs>>;
  setIsValueTiersUpdate: (p: boolean) => void;
};

const EmailIcon = () => {
  return <FaEnvelope style={{ fontSize: 20, cursor: 'pointer' }} />;
};

const EmailPeople = ({
  register,
  watchValue,
  setIsValueTiersUpdate,
  errors
}: Props) => {
  const validateEmail = (value: string) => value?.trim().length === 0
    || validator.isEmail(value)
    || `Invalid email format, ex "user@example.com"`;
  return (
    <>
      <div className='font-bold px-4'>Mails</div>
      <div className='bg-white px-4 py-3 mx-4 mt-4'>
        <Row className='my-2'>
          <TierForm
            {...register('professional_mail', {
              validate: validateEmail,
            })}
            prev='mailto'
            value={watchValue('professional_mail')}
            errorMessage={errors.professional_mail?.message}
            title='Professionnal Mail'
            icon={<EmailIcon />}
            tagType='email'
            setIsValueTiersUpdate={(p: boolean) => setIsValueTiersUpdate(p)}
          />
        </Row>
        <Row className='my-2'>
          <TierForm
            {...register('personal_mail', {
              validate: validateEmail,
            })}
            prev='mailto'
            value={watchValue('personal_mail')}
            errorMessage={errors.personal_mail?.message}
            title='Personnal Mail'
            icon={<EmailIcon />}
            tagType='email'
            setIsValueTiersUpdate={(p: boolean) => setIsValueTiersUpdate(p)}
          />
        </Row>
        <Row className='my-2'>
          <TierForm
            {...register('other_mail', {
              validate: validateEmail,
            })}
            prev='mailto'
            value={watchValue('other_mail')}
            errorMessage={errors.other_mail?.message}
            title='Other Mail'
            icon={<EmailIcon />}
            tagType='email'
            setIsValueTiersUpdate={(p: boolean) => setIsValueTiersUpdate(p)}
          />
        </Row>
      </div>
    </>
  );
};

export default EmailPeople;
