import Table, { ColumnsType } from "antd/es/table";
import { useOutlookTodayAgendaQuery } from "../../redux/api/ws/dashboard/dashboard";
import { TodayAgenda } from "../../@types/dashboard";

export const DashboardTodayAgenda = () => {
  const { currentData, isLoading } = useOutlookTodayAgendaQuery({});

  function toLocalDate(date: string) {
    // Parse the input string into a JavaScript Date object
    const outlookDateTime = new Date(date);
    // Get the timezone offset in minutes
    const timezoneOffsetMinutes = new Date().getTimezoneOffset();
    // Calculate the local time by adding the offset
    return new Date(outlookDateTime.getTime() - timezoneOffsetMinutes * 60000);
  }
  function checkDateStatus(agenda: TodayAgenda) {
    const start = toLocalDate(agenda.start).getTime(); // Convertir la date cible en millisecondes
    const end = toLocalDate(agenda.end).getTime();
    const currentDateTime = new Date().getTime(); // Convertir la date actuelle en millisecondes
    // console.log(new Date("2024-02-06T12:00:00.0000000Z").toLocaleTimeString());
    if (currentDateTime > end) {
      return "agenda-past";
    } else if (start <= currentDateTime && end > currentDateTime) {
      return "agenda-inprogress";
    } else {
      return "agenda-future";
    }
  }

  function getLocalTime(date: string) {
    // Parse the input string into a JavaScript Date object
    const localDateTime = toLocalDate(date);
    // Extract local hour and minute
    const localHour = localDateTime.getHours();
    const localMinute = localDateTime.getMinutes();
    // Format the hours and minutes as "HHh:mm"
    return `${localHour}h${localMinute < 10 ? "0" : ""}${localMinute}`;
  }

  const columns: ColumnsType<TodayAgenda> = [
    {
      key: "start",
      dataIndex: "start",
      width: 50,

      render: (value) => getLocalTime(value),
    },
    {
      dataIndex: "subject",
      ellipsis: true,
      key: "subject",
    },
  ];

  return (
    <>
      <span className="base-card-title">TODAY'S AGENDA</span>
      <Table
        size="small"
        loading={isLoading}
        className="dashboard-table"
        pagination={false}
        showHeader={false}
        rowKey="index"
        rowClassName={(record) => checkDateStatus(record)}
        onRow={(record) => {
          return {
            onClick: () =>
              window.open(record.meetingUrl || record.webLink, "_blank"),
            rowClassName: `selectRowClassName`,
          };
        }}
        columns={columns}
        dataSource={currentData?.data || []}
        scroll={{ y: 110 }}
      />
    </>
  );
};
