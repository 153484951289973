import { Flex } from "antd";
import classes from "./Cartridge.module.scss";

type Props = {
  value: string;
  label?: string;
};
export const Cartridge = ({ label, value }: Props) => {
  return (
    <Flex
      className={`${classes[value]}`}
      style={{ maxWidth: 100 }}
      justify="center"
      align="center"
    >
      <b> {(label ?? "") as string}</b>
    </Flex>
  );
};
