import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { Method } from "../../../../@types/common-types";
import {
  BusinessType,
  ConterPartyResponse,
} from "../../../../@types/conterParty";
import {
  EntityMandate,
  InputFormMandate,
  MandatesResponse,
  OwnerNameResponse,
} from "../../../../@types/mandates/mandates";
import { People } from "../../../../@types/people";
import { baseApi } from "../../baseApi";
import { url } from "../../url";
import { invalidTags } from "../../../../utils/helper-function";

const mandatesEntity = baseApi.injectEndpoints({
  endpoints: (build: EndpointBuilder<any, any, any>) => {
    return {
      mandates: build.query<
        MandatesResponse,
        {
          page: number | undefined;
          start: number | undefined;
          limit: number | undefined;
          sort: string;
          filter: string;
        }
      >({
        query: (queryParams) => {
          const { start, page, limit, sort, filter } = queryParams;
          return {
            url: url.mandate.list,
            params: {
              start,
              page,
              limit,
              sort,
              filter,
              getLegalEntity: true,
              getGroup: true,
            },
          };
        },
        providesTags: ["LIST_MANDATES"],
      }),

      getMandateByIdInPipe: build.mutation<
        { mandate: EntityMandate; success: string },
        { id: string | number }
      >({
        query: ({ id }) => {
          return {
            url: `${url.mandate.list}/${id}`,
            params: {
              _dc: new Date().getTime(),
              withClientAndName: true,
              getLegalEntity: true,
              getGroup: true,
            },
          };
        },
      }),

      searchCofarco: build.mutation<
        ConterPartyResponse,
        {
          businessType: BusinessType;
          page?: number;
          start?: number;
          limit?: number;
          query: any;
          filter?: string;
        }
      >({
        query: (body) => {
          const { start, page, limit, query, businessType, filter } = body;
          return {
            url: url.counterparty.list,
            params: {
              start,
              page,
              limit,
              query,
              businessType,
              getLegalEntity: true,
              getGroup: true,
              filter,
            },
            method: Method.GET,
          };
        },
      }),

      exportMandates: build.mutation<
        string,
        { fields?: string[]; filter: any }
      >({
        query: (queryParams) => {
          return {
            url: url.mandate.export,
            method: Method.POST,
            data: queryParams,
          };
        },
      }),

      deleteMandate: build.mutation<
        { success: boolean; mandate: MandatesResponse },
        { id: number }
      >({
        query: (body) => {
          return {
            url: url.mandate.list + "/" + body.id,
            method: Method.DELETE,
          };
        },
        invalidatesTags: invalidTags.concat(["GLOBAL_RESULT_SEARCH"]),
      }),

      getOwner: build.query<OwnerNameResponse, void>({
        query: () => {
          return {
            url: url.mandate.owner_names,
          };
        },
      }),

      createMandate: build.mutation<
        { success: boolean; mandate: EntityMandate },
        InputFormMandate
      >({
        query: (body) => {
          return {
            url: url.mandate.list,
            method: Method.POST,
            data: body,
          };
        },
        invalidatesTags: invalidTags,
      }),

      updateMandate: build.mutation<
        { success: boolean; mandate: EntityMandate },
        InputFormMandate
      >({
        query: (body) => {
          return {
            url: url.mandate.list + "/" + body.id,
            method: Method.PUT,
            data: body,
          };
        },
        invalidatesTags: invalidTags,
      }),

      searchMandateName: build.mutation<
        MandatesResponse,
        {
          page: number;
          start: number;
          limit: number;
          query: any;
        }
      >({
        query: (body) => {
          const { start, page, limit, query } = body;
          return {
            url: url.mandate.list,
            params: {
              start,
              page,
              limit,
              query,
              withClientAndName: true,
            },
            method: Method.GET,
          };
        },
      }),

      getPeopleByConterPartyId: build.mutation<
        { people: Array<People>; success: string },
        { id: string | number; query?: string }
      >({
        query: ({ id, query }) => {
          return {
            url: `${url.people.list}`,
            params: {
              _dc: new Date().getTime(),
              counterpartyId: id,
              allEntities: true,
              query,
              page: 1,
              start: 0,
              limit: 100,
            },
          };
        },
      }),
    };
  },
});

export const {
  useMandatesQuery,
  useLazyMandatesQuery,
  useDeleteMandateMutation,
  useSearchCofarcoMutation,
  useGetOwnerQuery,
  useExportMandatesMutation,
  useCreateMandateMutation,
  useSearchMandateNameMutation,
  useGetPeopleByConterPartyIdMutation,
  useUpdateMandateMutation,
  useGetMandateByIdInPipeMutation,
} = mandatesEntity;
