import { Button, Modal, Form } from 'react-bootstrap';
import classes from './IbanForm.module.scss';
import InputInlineForm from '../../common/InputInlineForm/InputInlineForm';
import Select from 'react-select';
import UseConstante from '../../../hooks/use-constante';
import { TermConst } from '../../../@types/common-types';
import { useState } from 'react';
import { InputFormBankAccount } from '../../../@types/segregatedaccounts/payements';
import { checkIban } from '../../../redux/api/axiosBaseQuery';
import { url } from '../../../redux/api/url';
import NotifModalPreventLoading from '../../Modal/NotifModalPreventLoading';

const IBAN = require('iban');

const IbanFormModal = ({
  showModalAddIban,
  handleCancel,
  handleAdd,
  ...props
}: Props) => {
  const terms = UseConstante();

  const currency = terms.getConst(TermConst.currency);

  const currencyOptions2 = currency?.map((el: any) => {
    return {
      value: el.key,
      label: el.value,
    };
  });

  const [state, setState] = useState<InputFormBankAccount>({
    bank: '',
    iban: '',
    bic: '',
    currency: '',
    info: '',
    legal_entity_id: 0,
  });

  const [inputIban, setInputIban] = useState<any>({
    show: false,
    iban: '',
    legName: '',
    isInvalid: false,
    message: null,
  });

  const [inputBic, setInputBic] = useState<any>({
    isInvalid: false,
    message: null,
  });

  const validateIban = async (value: any) => {
    if (!IBAN.isValid(value)) {
      setInputIban({
        show: false,
        iban: value,
        isInvalid: true,
        message: `Iban ${value}  not valid`,
      });
      return;
    } else {
      try {
        const ibans: any = await checkIban(url.legalEntity.check_iban, value);
        const isInMyEntity = ibans.bankaccount.find(
          (el: any) => el.legal_entity_id === props.legal_entity_id,
        );
        if (ibans.bankaccount?.[0]?.legal_entity && !isInMyEntity) {
          setInputIban({
            show: true,
            iban: value,
            legName: ibans.bankaccount[0].legal_entity.name,
            isInvalid: true,
            message: `IBAN ${value} is already use by another Legal Entity`,
          });
          return;
        }
      } catch (error) {
        setInputIban({
          show: false,
          iban: value,
          isInvalid: true,
          message: 'Error checking iban',
        });
        return;
      }
    }
    setInputIban({
      show: false,
      iban: '',
      legName: '',
      isInvalid: false,
      message: null,
    });
  };

  const handleChange = async (name: string, value: string) => {
    setState({
      ...state,
      [name]: value,
    });

    if (name === 'iban') {
      await validateIban(value);
    }

    if (name === 'bic') {
      if (value) {
        if (value.length > 12) {
          setInputBic({
            isInvalid: true,
            message: 'The maximum length for this field is 12',
          });
        } else if (value.length < 8) {
          setInputBic({
            isInvalid: true,
            message: 'The bic must be between 8 and 12 characters.',
          });
        } else {
          setInputBic({
            isInvalid: false,
            message: null,
          });
        }
      } else {
        setInputBic({
          isInvalid: false,
          message: null,
        });
      }
    }
  };

  return (
    <>
      <NotifModalPreventLoading
        title="IBAN already use"
        body={`IBAN ${inputIban.iban} is already use by another Legal Entity ${inputIban.legName} And cannot be use.`}
        show={inputIban.show}
        closeModal={() =>
          setInputIban({
            show: false,
            iban: '',
            legName: '',
          })
        }
      />
      <Modal
        show={showModalAddIban}
        keyboard={false}
        centered
        enforceFocus
        backdropClassName="modal-backdrop"
        id="modalAddIban"
        onHide={() => handleCancel?.()}
      >
        <Modal.Header>
          <Modal.Title title={`Add IBAN to ${props?.recipientName}`}>
            Add IBAN to {props?.recipientName?.substring(0, 25)}{' '}
            {(props?.recipientName?.length ?? 0) > 25 ? '...' : ''}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputInlineForm
            title="Bank Name"
            isRequired={true}
            isValid={true}
            onChange={(e) => handleChange('bank', e.target.value)}
          />

          <InputInlineForm
            title="IBAN"
            isRequired={true}
            error={inputIban.message}
            isInvalid={inputIban.isInvalid}
            isValid={!inputIban.isInvalid}
            onChange={(e) => handleChange('iban', e.target.value)}
          />

          <InputInlineForm
            title="BIC"
            isRequired={true}
            error={inputBic.message}
            isInvalid={inputBic.isInvalid}
            isValid={!inputBic.isInvalid}
            onChange={(e) => handleChange('bic', e.target.value)}
          />

          <Form.Group className="d-flex mb-2 mt-2">
            <Form.Label
              htmlFor="Currency_Iban"
              className={`d-flex me-3 mt-2 ${classes.formLabel}`}
            >
              Currency <span className="span-required">*</span>
            </Form.Label>
            <div className="w-100 mx-1">
              <Select
                isClearable={true}
                placeholder="Select currency"
                name="currency"
                options={currencyOptions2}
                onChange={(obj: any) => {
                  handleChange('currency', obj?.value);
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#e5e5e5',
                    primary: '#e5e5e5',
                  },
                })}
                required={true}
              />
            </div>
          </Form.Group>

          <InputInlineForm
            title="Info"
            isValid={true}
            onChange={(e) => handleChange('info', e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end">
            <div className="d-inline px-2">
              <Button
                className={`${classes.btnSave}`}
                onClick={() => handleAdd?.(state)}
                disabled={
                  inputBic.isInvalid ||
                  inputIban.isInvalid ||
                  state.currency.trim() === '' ||
                  state.iban.trim() === '' ||
                  state.bic.trim() === '' ||
                  state.bank.trim() === ''
                }
              >
                Add
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default IbanFormModal;

type Props = {
  showModalAddIban: boolean;
  handleAdd?: (value: InputFormBankAccount) => void;
  handleCancel?: () => void;
  recipientName?: string;
  legal_entity_id?: number;
};
