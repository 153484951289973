import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { ApiPagination, ApiFilter } from "../../../../@types";
import { Method } from "../../../../@types/common-types";
import {
  People,
  PeopleFormInputs,
  PeopleResponse,
  SinglePeopleResponse,
} from "../../../../@types/people";
import { baseApi } from "../../baseApi";
import { url } from "../../url";

const people = baseApi.injectEndpoints({
  endpoints: (build: EndpointBuilder<any, any, any>) => {
    return {
      listPeople: build.query<PeopleResponse, ApiPagination & ApiFilter>({
        query: (queryParams) => {
          return {
            url: url.people.list,
            params: {
              ...queryParams,
              getLegalEntity: 1,
              _dc: new Date().getTime(),
            },
          };
        },
        providesTags: (result) =>
          result
            ? result.people.map((e) => ({
                type: "People",
                id: e.id,
              }))
            : ["People"],
      }),
      peopleById: build.query<SinglePeopleResponse, { id: string | number }>({
        query: (params) => {
          return {
            url: `${url.people.list}/${params.id}`,
            params: {
              getLegalEntity: 1,
              _dc: new Date().getTime(),
            },
          };
        },
        providesTags: (result, erro, arg) => [{ type: "People", id: arg.id }],
      }),
      searchPeople: build.query<
        PeopleResponse,
        {
          key?: string;
          value?: string;
          query?: string;
          params?: Record<string, any>;
        }
      >({
        query: ({ key, value, params, query }) => {
          return {
            url: url.people.list,
            params: {
              start: 0,
              page: 1,
              limit: 100,
              getLegalEntity: 1,
              query: query ? query : undefined,
              filter:
                key && value
                  ? [{ property: key, operator: "like", value: `%${value}%` }]
                  : undefined,
              ...params,
            },
          };
        },
        providesTags: ["LIST_PEOPLE_BY_SEARCH"],
      }),
      deletePeople: build.mutation<{ success: boolean }, number>({
        query: (id) => {
          return {
            url: `${url.people.list}/${id}`,
            params: { getLegalEntity: 1, _dc: new Date().getTime() },
            method: Method.DELETE,
          };
        },
        invalidatesTags: ["People", "GLOBAL_RESULT_SEARCH"],
      }),
      exportPeople: build.mutation<
        string,
        ApiFilter & { fields?: (keyof People)[] } & ApiFilter
      >({
        query: ({ fields, filter }) => {
          return {
            url: url.people.export,
            data: {
              fields,
            },
            params: {
              filter,
            },
            method: Method.POST,
          };
        },
      }),
      createPeople: build.mutation<
        { people: People; success: boolean },
        Omit<PeopleFormInputs, "legal_entity">
      >({
        query: (data) => {
          return {
            url: `${url.people.list}`,
            params: { getLegalEntity: 1, _dc: new Date().getTime() },
            method: Method.POST,
            data: data,
          };
        },
        invalidatesTags: ["People"],
      }),
      editPeople: build.mutation<
        { people: People; success: boolean },
        { data: Omit<PeopleFormInputs, "legal_entity">; id: string | number }
      >({
        query: ({ id, data }) => {
          return {
            url: `${url.people.list}/${id}`,
            params: { getLegalEntity: 1, _dc: new Date().getTime() },
            method: Method.PUT,
            data: data,
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: "People", id: arg.id },
        ],
      }),

      searchPeopleByCounterPartyId: build.mutation<
        PeopleResponse,
        { counterpartyId?: number; params?: Record<string, any> }
      >({
        query: ({ counterpartyId, params }) => {
          return {
            url: url.people.list,
            params: {
              start: 0,
              page: 1,
              limit: 100,
              getLegalEntity: 1,
              counterpartyId,
              ...(params ?? {}),
            },
          };
        },
      }),
    };
  },
});

export const {
  useLazyListPeopleQuery,
  useDeletePeopleMutation,
  useExportPeopleMutation,
  useLazySearchPeopleQuery,
  useCreatePeopleMutation,
  useEditPeopleMutation,
  useLazyPeopleByIdQuery,
  usePeopleByIdQuery,
  useListPeopleQuery,
  useSearchPeopleByCounterPartyIdMutation,
} = people;
