import { Input } from "antd";
import {
  ChangeEventHandler,
  useEffect,
  useState,
} from "react";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { RxDoubleArrowLeft, RxDoubleArrowRight } from "react-icons/rx";

type Props = {
  currentPage: number;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  totalPage: number;
  onNext?: (value: number) => void;
  onPrevious?: (value: number) => void;
  onStart?: (value: number) => void;
  onEnd?: (value: number) => void;
};

const TablePagination = ({
  currentPage,
  onChange,
  totalPage,
  onNext,
  onPrevious,
  onStart,
  onEnd,
}: Props) => {
  const [inputWidth, setInputWidth] = useState<number>(34);
  useEffect(() => {
    setInputWidth(Math.max(`${currentPage}`.length * 20, 34));
  }, [currentPage]);


  return (
    <div className="d-flex my-4">
      <RxDoubleArrowLeft
        className="mx-2"
        onClick={() => onStart && currentPage !== 1 && onStart(1)}
        style={{ fontSize: 22, marginTop: 2, cursor: "pointer" }}
      />
      <MdArrowBackIos
        className="mx-2"
        onClick={() =>
          onPrevious && currentPage > 1 && onPrevious(currentPage - 1)
        }
        style={{ fontSize: 18, marginTop: 4, cursor: "pointer" }}
      />
      <div className="d-flex mx-2">
        {" "}
        <span style={{ marginTop: 2 }}>Page</span>{" "}
        <Input
          type="text"
          onChange={onChange}
          value={currentPage}
          style={{
            width: inputWidth,
            backgroundColor: "transparent",
            color: "#888",
          }}
          className="mx-2 py-0 text-center "
        />
        <span style={{ marginTop: 2 }}>of {totalPage}</span>
      </div>
      <MdArrowForwardIos
        className="mx-2"
        onClick={() =>
          onNext && currentPage < totalPage && onNext(currentPage + 1)
        }
        style={{ fontSize: 18, marginTop: 4, cursor: "pointer" }}
      />
      <RxDoubleArrowRight
        className="mx-2"
        onClick={() => onEnd && currentPage !== totalPage && onEnd(totalPage)}
        style={{ fontSize: 22, marginTop: 2, cursor: "pointer" }}
      />
    </div>
  );
};

export default TablePagination;
