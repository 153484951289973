import { Col, Form, Row } from "react-bootstrap";
import {
  amountFormat,
  concatTitle,
  exportedCountryName,
  exportedDropdownValue,
  isCan,
  isExist,
  makeOptions,
} from "../../../../../utils/helper-function";
import UseConstante from "../../../../../hooks/use-constante";

import { ReactComponent as LegalentitiesActif } from "../../../../../assets/img/LegalentitiesActif.svg";
import { ReactComponent as LegalentitiesInactif } from "../../../../../assets/img/LegalentitiesInactif.svg";
import { useGetCountryQuery } from "../../../../../redux/api/ws/databases/groups";
import FormModal from "../../../../Modal/FormModal/FormModal";
import SelectDropDown from "../../../../common/DropDown/SelectDropDown";
import { Menu, TermConst } from "../../../../../@types/common-types";
import { DropDownOptionObjectType } from "../../../../common/DropDown/DropdownSelect";
import UseFormCreateDealObligorLimit from "./UseFormCreateDealObligorLimits";
import Flag from "react-world-flags";
import CofarcoCalendar from "../../../../Calendar/CofarcoCalendar";
import CofarcoNumberAmount from "../../../../Form/CofarcoNumberAmount";
import ModalUnsavedChange from "../../../../common/Modal/ModalUnsavedChange";
import HeaderMultiple from "../../../../HeaderTab/HeaderMultiple";
import ParticipationLimitsList from "../../../../Participations/ModalParticipations/ParticipationObligorList/LimitsList";

type IProps = {
  show: boolean;
  handleClose: () => void;
  defaultFormValues: any | null;
};
export default function FormCreateDealObligorLimit(props: IProps) {
  const provider = UseFormCreateDealObligorLimit({
    defaultFormValues: props.defaultFormValues,
    closingModal: props.handleClose,
  });
  const terms: any = UseConstante();
  const { data: responseCountry } = useGetCountryQuery();

  const showErrorCurrency = () => {
    provider.setFormError("currency", {
      message: "Currency is required",
    });
  };

  const title = provider.isEdit
    ? `Deal Obligor Limit ${concatTitle(
        props.defaultFormValues?.number
      )} ${concatTitle(provider.selectedDeal?.number)}  ${concatTitle(
        provider.selectedMandate?.number
      )}`
    : `New - Deal Obligor Limit ${concatTitle(
        provider.selectedDeal?.number
      )} ${concatTitle(provider.selectedMandate?.number)}`;

  const hiddenFooter =
    (!isCan("write", Menu.deal) && props.defaultFormValues?.event === "edit") ||
    provider.activeMenu === 2;

  const menus = [
    {
      id: 1,
      title: title,
    },
    {
      id: 2,
      title: `Participation Obligor Limits List ${concatTitle(
        props.defaultFormValues?.number
      )} ${concatTitle(provider.selectedDeal?.number)} ${concatTitle(
        provider?.selectedMandate?.number
      )}  `,
    },
  ];
  return (
    <FormModal
      show={props.show}
      handleClose={() => {
        provider.setActiveMenu(1);
        provider.resetForm();
        props.handleClose();
      }}
      ishiddenfooter={hiddenFooter}
      isDisabledSave={!provider.isDirty || !provider.isCompletedForm}
      isLoading={false}
      onSubmitForm={provider.handleSubmit(provider.submit)}
      header={
        <HeaderMultiple
          activeMenu={provider.activeMenu}
          menus={menus}
          createTitle={title}
          setActiveMenu={(p: number) => provider.handleChangeToogle(p)}
          isEdit={provider.isEdit}
        />
      }
      children={
        <>
          {provider.activeMenu === 1 && (
            <>
              <Row className="pt-4">
                <Col className="pe-4">
                  <div className="d-flex align-items-baseline">
                    <Form.Label className="txt-with-dropdown2">
                      Obligor <span className="span-required">*</span>
                    </Form.Label>
                    <div className="d-flex flex-column w-100">
                      <SelectDropDown
                        noPadding
                        icon={
                          <span style={{ cursor: "pointer" }}>
                            {provider.obligor_legal_entity_id ? (
                              <LegalentitiesActif
                                onClick={() => provider.handleShowOtherModal()}
                              />
                            ) : (
                              <LegalentitiesInactif />
                            )}
                          </span>
                        }
                        loading={provider.isGetLELoading}
                        error={provider.errors.obligor_legal_entity_id?.message}
                        dropDownClass="px-0"
                        id="obligor_legal_entity_id"
                        preValue={exportedDropdownValue(
                          makeOptions(
                            provider.legalEntityList || [],
                            "name",
                            "id"
                          ),
                          provider.obligor_legal_entity_id || 0
                        )}
                        onSearch={(d: any, v: any) =>
                          provider.runSearch("obligor_legal_entity_id", v)
                        }
                        options={makeOptions(
                          provider.legalEntityList || [],
                          "name",
                          "id"
                        )}
                        onSelectOption={(e: any) => {
                          provider.setValue(
                            "obligor_legal_entity_id",
                            (e as DropDownOptionObjectType).key?.toString(),
                            {
                              shouldDirty: true,
                            }
                          );
                          if (e?.country_id) {
                            provider.setValue("country_id", e.country_id);
                          } else {
                            provider.setValue("country_id", "-");
                          }
                          if (e?.group_id) {
                            provider.getGroup({
                              id: e?.group_id,
                            });
                          } else {
                            provider.resetCached();
                          }
                          if (e) {
                            provider.clearFormErrors("obligor_legal_entity_id");
                            return;
                          }
                          provider.setFormError("obligor_legal_entity_id", {
                            message: "Obligor is required",
                          });
                        }}
                        onBlur={() => {
                          if (!isExist(provider.obligor_legal_entity_id)) {
                            provider.setFormError("obligor_legal_entity_id", {
                              message: "Obligor ID is required",
                            });
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="my-2">
                    <Form.Group className="d-flex align-items-baseline w-100 pt-3">
                      <Form.Label style={{ width: 240 }}>Country</Form.Label>
                      <div className="d-flex flex-column w-100">
                        {isExist(provider.country_id) ? (
                          <div className="d-flex flex-row">
                            <Flag
                              code={provider.country_id}
                              height="20"
                              width={"20"}
                              style={{
                                borderRadius: "50%",
                                objectFit: "cover",
                                marginRight: 15,
                              }}
                            />
                            {exportedCountryName(
                              responseCountry?.country || [],
                              provider.country_id
                            )}
                          </div>
                        ) : (
                          "-"
                        )}
                      </div>
                    </Form.Group>
                  </div>
                  <div className="my-2">
                    <Form.Group className="d-flex align-items-baseline w-100 pt-3">
                      <Form.Label style={{ width: 240 }}>Group</Form.Label>
                      {provider.obligor_legal_entity_id && (
                        <div className="d-flex flex-column w-100">
                          {provider?.selectedGroup?.group.name}
                        </div>
                      )}
                    </Form.Group>
                  </div>

                  <div className="d-flex w-100 align-items-baseline pt-2">
                    <Form.Label style={{ width: 240 }}>Amount</Form.Label>
                    <div className="d-flex flex-row  justify-content-center w-100">
                      <div className="w-100">
                        <CofarcoNumberAmount
                          register={provider.register}
                          setValue={provider.setValue}
                          watch={provider.watch}
                          key_form="amount"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="d-flex align-items-baseline">
                    <Form.Label className="txt-with-dropdown2">
                      Currency
                    </Form.Label>
                    <div className={`d-flex w-100 flex-column `}>
                      <SelectDropDown
                        error={provider.errors?.currency?.message}
                        noSearch
                        modalId="mandate"
                        preValue={
                          provider.isLoading
                            ? ""
                            : exportedDropdownValue(
                                terms?.getConst(TermConst.currency),
                                provider.currency
                              )
                        }
                        disabled={(provider.amount as number) < 1}
                        options={terms?.getConst(TermConst.currency)}
                        onSelectOption={(e) => {
                          provider.setValue(
                            "currency",
                            (e as DropDownOptionObjectType).key,
                            { shouldDirty: true }
                          );
                          const isCleared =
                            isExist(provider.amount) &&
                            !isExist((e as DropDownOptionObjectType).key);
                          if (isCleared) {
                            showErrorCurrency();
                            return;
                          }

                          provider.clearFormErrors("currency");
                        }}
                        onBlur={() => {
                          const isCleared =
                            isExist(provider.amount) &&
                            !isExist(provider.currency);
                          if (isCleared) {
                            showErrorCurrency();
                            return;
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="d-flex align-items-baseline">
                    <Form.Label className="txt-with-dropdown2">
                      Status
                    </Form.Label>
                    <div className={`d-flex w-100 flex-column `}>
                      <SelectDropDown
                        modalId="mandate"
                        preValue={exportedDropdownValue(
                          terms?.getConst(TermConst.limitStatus),
                          provider.status
                        )}
                        noSearch
                        options={terms?.getConst(TermConst.limitStatus)}
                        onSelectOption={(e) => {
                          provider.setValue(
                            "status",
                            (e as DropDownOptionObjectType).key,
                            {
                              shouldDirty: true,
                            }
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="d-flex align-items-baseline">
                    <Form.Label className="txt-with-dropdown2">
                      Nature
                    </Form.Label>
                    <div className={`d-flex w-100 flex-column `}>
                      <SelectDropDown
                        modalId="mandate"
                        preValue={exportedDropdownValue(
                          terms?.getConst(TermConst.limitNature),
                          provider.nature
                        )}
                        noSearch
                        options={terms?.getConst(TermConst.limitNature)}
                        onSelectOption={(e) => {
                          provider.setValue(
                            "nature",
                            (e as DropDownOptionObjectType).key,
                            {
                              shouldDirty: true,
                            }
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="my-2">
                    <Form.Group className="d-flex align-items-baseline w-100">
                      <Form.Label style={{ width: 240 }}>Limit Date</Form.Label>

                      <CofarcoCalendar
                        name="limit_date"
                        control={provider.control}
                        setFormValue={provider.setValue}
                        watchFormValue={provider.watch}
                      />
                    </Form.Group>
                  </div>
                  <div className="my-3">
                    <Form.Group className="d-flex align-items-baseline w-100">
                      <Form.Label style={{ width: 240 }}>
                        Expiry Date
                      </Form.Label>
                      <CofarcoCalendar
                        name="expiry_date"
                        control={provider.control}
                        setFormValue={provider.setValue}
                        watchFormValue={provider.watch}
                      />
                    </Form.Group>
                  </div>
                  <div className="my-3">
                    <Form.Group className="d-flex align-items-baseline w-100">
                      <Form.Label style={{ width: 240 }}>Pricing</Form.Label>
                      <Form.Control
                        className="w-100"
                        {...provider.register("pricing")}
                      />
                    </Form.Group>
                  </div>
                  <div className={`my-1`}>
                    <Form.Group className="d-flex align-items-start w-100">
                      <Form.Label style={{ width: 240 }}>
                        Additional Information
                      </Form.Label>

                      <div className="d-flex flex-column w-100 mb-3">
                        <Form.Control
                          as="textarea"
                          rows={3}
                          className="w-100"
                          {...provider.register("comments")}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </Col>
                <Col
                  className="py-4"
                  style={{
                    border: "1px solid #a7a7a770",
                    borderRadius: 8,
                    backgroundColor: "#fff",
                    display: "flex",
                    flexDirection: "column",
                    overflowY: "scroll",
                    margin: "0 auto auto auto",
                  }}
                >
                  <div className="d-flex flex-row py-2 px-4">
                    <div style={{ width: "40%" }}>Deal ID</div>
                    <div style={{ width: "40%" }}>
                      {provider.selectedDeal?.number}
                    </div>
                  </div>
                  <div className="d-flex flex-row py-2 px-4">
                    <div style={{ width: "40%" }}>Client Name</div>
                    <div style={{ width: "40%" }}>
                      {provider.selectedMandate?.client?.name}
                    </div>
                  </div>
                  <div className="d-flex flex-row py-2 px-4">
                    <div style={{ width: "40%" }}>Mandate Name</div>
                    <div style={{ width: "40%" }}>
                      {" "}
                      {provider.selectedMandate?.name}
                    </div>
                  </div>
                  <div className="d-flex flex-row py-2 px-4">
                    <div style={{ width: "40%" }}>Deal Type</div>
                    <div style={{ width: "40%" }}>
                      {" "}
                      {exportedDropdownValue(
                        terms?.getConst(TermConst.dealType),
                        provider.selectedDeal?.deal_type || null
                      )}
                    </div>
                  </div>
                  <div className="d-flex flex-row py-2 px-4">
                    <div style={{ width: "40%" }}>Deal Status</div>
                    <div style={{ width: "40%" }}>
                      {" "}
                      {exportedDropdownValue(
                        terms?.getConst(TermConst.dealStatus),
                        provider.selectedDeal?.status || null
                      )}
                    </div>
                  </div>
                  <div className="d-flex flex-row py-2 px-4">
                    <div style={{ width: "40%" }}>Deal Amount</div>
                    <div style={{ width: "40%" }}>
                      {provider.selectedDeal?.amount
                        ? amountFormat(provider.selectedDeal?.amount)
                        : "-"}
                    </div>
                  </div>
                  <div className="d-flex flex-row py-2 px-4">
                    <div style={{ width: "40%" }}>Deal Currency</div>
                    <div style={{ width: "40%" }}>
                      {exportedDropdownValue(
                        terms?.getConst(TermConst.currency),
                        provider.selectedDeal?.currency || null
                      )}
                    </div>
                  </div>
                </Col>
              </Row>

              <ModalUnsavedChange
                dirtiFields={provider.dirtyFields}
                handleCancel={() => provider.setIsShowModalUnsavedChange(false)}
                handleLeave={() => provider.handleLeaveModalWithoutSave()}
                showModalUnsaved={provider.isShowModalUnsavedChange}
              />
            </>
          )}

          {provider.activeMenu === 2 && (
            <ParticipationLimitsList
              selectedParticipation={props.defaultFormValues?.id || 0}
              dealId={provider.selectedDeal?.id || 0}
              mandateId={provider.selectedMandate?.id || 0}
              limitId={props.defaultFormValues?.id as number}
              market={props.defaultFormValues?.market}
              deal_type={props.defaultFormValues?.deal_type}
            />
          )}
        </>
      }
    />
  );
}
