export const mockCurrency = [
  {
    label: 'CHF',
    value: 'chf',
  },
  {
    label: 'CNY',
    value: 'cny',
  },
  {
    label: 'EUR',
    value: 'eur',
  },
  {
    label: 'GBP',
    value: 'gbp',
  },
  {
    label: 'USD',
    value: 'usd',
  },
  {
    label: 'XOF',
    value: 'xof',
  },
];
