import React, { useState, useEffect, useContext } from "react";
import { DealsFilterType, EntityDeals } from "../../@types/deals/deals";
import {
  useDealsQuery,
  useDeleteDealsMutation,
  useExportDealsMutation,
} from "../../redux/api/ws/deals/deals";
import * as bootstrap from "bootstrap";
import ColumnDeals from "./ColumnDeals";
import { FilterType } from "../../@types";
import { downloadCsv, titleRequestError } from "../../utils/helper-function";
import { updateModalAction } from "../../redux/slice/modalSlice";
import { useAppDispatch } from "../../hooks/redux";
import { exportedDeals } from "../../utils/exported-deals";
import { updateNotificationMessage } from "../../redux/slice/notificationSlice";
import { debounce, isEqual } from "lodash";
import { ContextDeal } from "./DealsContent";

export default function UseDealsPage() {
  const [filterData, setFilterData] = useState<DealsFilterType>({
    team: {
      value: ["ldn", "par", "gva"],
      operator: "in",
      property: "team",
    },
  });
  const [paramsDeals, setParamsDeals] = useState<{
    page: number | undefined;
    start: number | undefined;
    limit: number | undefined;
    sort: string;
    filter: string;
  }>({
    page: 1,
    start: 0,
    limit: 20,
    sort: JSON.stringify([{ property: "number", direction: "DESC" }]),
    filter: "[]",
  });
  const [modalConfirmDelete, setModalConfirmDelete] = useState(false);
  const [dataSource, setDataSource] = useState<Array<EntityDeals>>([]);
  const { data, isLoading, isSuccess } = useDealsQuery(paramsDeals);
  const [totalData, setTotalData] = useState<number>(0);
  const [selectedDeals, setSelectedDeals] = useState<EntityDeals | null>(null);
  const [exportDeals] = useExportDealsMutation();
  const dispatch = useAppDispatch();
  const [toogleFilterValue, setToogleFilterValue] = useState<boolean>(false);
  const { txt } = useContext(ContextDeal);

  const debounceSearch = React.useCallback(
    debounce((txt) => {
      const params = getFilterParams();
      const tempFilter = [
        ...params,
        { property: "filter", operator: "like", value: `%${txt}%` },
      ];
      setParamsDeals({
        ...paramsDeals,
        page: 1,
        start: 0,
        limit: 20,
        filter: JSON.stringify(tempFilter),
      });
    }, 750),
    []
  );

  useEffect(() => {
    if (txt) {
      debounceSearch(txt);
    } else {
      setParamsDeals({
        page: 1,
        start: 0,
        limit: 20,
        sort: JSON.stringify([{ property: "number", direction: "DESC" }]),
        filter: "[]",
      });
    }
  }, [txt]);

  const [
    deletedDeals,
    {
      isLoading: isLoadingDelete,
      isSuccess: isSuccessDelete,
      isError: isErrorDelete,
      error: errorResponse,
    },
  ] = useDeleteDealsMutation();

  const toggleFilterCollapse = () => {
    setFilterData({
      team: {
        value: ["ldn", "par", "gva"],
        operator: "in",
        property: "team",
      },
    });
    const collapseSearch = document.getElementById("collapseSearch")!;
    const collapse = new bootstrap.Collapse(collapseSearch, { toggle: true });
    setToogleFilterValue(!toogleFilterValue);
    collapse.toggle();
  };

  useEffect(() => {
    if (isSuccess) {
      setDataSource(data?.deal || []);
      setTotalData(data.total);
    }
  }, [isSuccess, data?.deal]);

  const handleSorted = React.useCallback(
    (key: string, ruls: "ASC" | "DESC") => {
      const paramsFilter = JSON.stringify([{ property: key, direction: ruls }]);
      setParamsDeals({
        ...paramsDeals,
        sort: paramsFilter,
      });
    },
    [paramsDeals]
  );

  const handleTableDeals = React.useCallback(
    (current: number) => {
      setParamsDeals({
        ...paramsDeals,
        page: current,
      });
    },
    [paramsDeals]
  );

  const setPerPage = React.useCallback(
    (page: number) => {
      setParamsDeals({
        ...paramsDeals,
        limit: page,
      });
    },
    [paramsDeals]
  );

  const editDeals = (id: number, data?: Partial<EntityDeals>) => {
    const findDeals = data ?? dataSource?.find((el) => el.id === id);
    console.log(findDeals);
    if (findDeals) {
      dispatch(
        updateModalAction({
          data: {
            ...findDeals,
          },
          isAdd: true,
          type: "deals",
        })
      );
    }
  };

  const handleDeleteDeals = (id: number, deal?: EntityDeals) => {
    const foundDeal =
      deal ??
      data?.deal.find(
        (dl: EntityDeals) => dl.id?.toString() === id?.toString()
      );
    setSelectedDeals(foundDeal || null);
    handleShowModalDelete();
  };

  const { columns, uncheckedId, columnsExport } = ColumnDeals({
    editDeals: editDeals,
    handleSorted: handleSorted,
    handleDeleteDeals: handleDeleteDeals,
    deals: dataSource,
  });

  const handleShowModalDelete = () => {
    setModalConfirmDelete(!modalConfirmDelete);
  };

  const handleCreateDeals = () => {
    dispatch(
      updateModalAction({
        data: null,
        isAdd: true,
        type: "deals",
      })
    );
  };

  const exportDealsCallback = React.useCallback(
    async (fields: string[]) => {
      const newFields = exportedDeals
        .filter((e) => fields.includes(e.itemId))
        .map((e) => e.itemId);
      const res = await exportDeals({
        fields: newFields,
        filter: Object.values(filterData),
      });
      if (res) {
        const rep = res as any;
        downloadCsv(rep.data, "deals.csv");
      }
    },
    [filterData]
  );

  const validDelete = () => {
    if (selectedDeals?.id) {
      deletedDeals({ id: selectedDeals?.id || -1 });
    }
  };

  React.useEffect(() => {
    if (isSuccessDelete) {
      handleShowModalDelete();
    }
  }, [isSuccessDelete]);

  const getFilterParams = React.useCallback((): FilterType[] => {
    const values = Object.values(filterData).map((e) => {
      if (e.property === "team") {
        return {
          ...e,
          value: e.value,
        };
      }
      return {
        ...e,
        value: `${typeof e.value === "string" ? e.value?.trim() : e.value}`,
      };
    });
    return values;
  }, [filterData]);

  const updateFilter = React.useCallback(
    (key: keyof DealsFilterType, value?: string | null) => {
      let newFilter = { ...filterData };
      if (!value || (value as string)?.trim() === "") {
        delete newFilter[key];
      } else {
        let operator = "=";
        let tempValue: any = value;
        if (key === "team") {
          operator = "in";
          const filtered = (filterData.team?.value as Array<string>) || [];
          const found = filtered.find((el) => el === value);
          if (found) {
            tempValue = filtered.filter((el) => el !== value);
          } else {
            tempValue = [...filtered, value];
          }
        }
        newFilter = {
          ...newFilter,
          [key]: {
            property: key,
            value: tempValue,
            operator,
          },
        };
      }
      setFilterData(newFilter);
    },
    [filterData]
  );

  const getDealsCallback = () => {
    setParamsDeals({
      ...paramsDeals,
      page: 1,
      start: 0,
      limit: 20,
      filter: JSON.stringify(getFilterParams()),
    });
  };

  useEffect(() => {
    const params = getFilterParams();
    const isInit =
      params?.length === 1 &&
      isEqual(params?.[0]?.value, ["ldn", "par", "gva"]);
    const restoreFilter = (!toogleFilterValue && params.length === 1) || isInit;
    if (restoreFilter) {
      setParamsDeals({
        ...paramsDeals,
        page: 1,
        start: 0,
        limit: 20,
        filter: JSON.stringify(params),
      });
    }
  }, [filterData]);

  React.useEffect(() => {
    if (isErrorDelete) {
      handleShowModalDelete();
      dispatch(
        updateNotificationMessage({
          show: true,
          title: titleRequestError,
          body: errorResponse?.data,
        })
      );
    }
  }, [isErrorDelete]);

  return {
    dataSource,
    columns,
    uncheckedId,
    columnsExport,
    toggleFilterCollapse,
    totalData,
    paramsDeals,
    handleCreateDeals,
    exportDealsCallback,
    isLoading,
    handleTableDeals,
    setPerPage,
    modalConfirmDelete,
    selectedDeals,
    handleShowModalDelete,
    validDelete,
    isLoadingDelete,
    filterData,
    updateFilter,
    getDealsCallback,
    editDeals,
  };
}
