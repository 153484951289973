import { useEffect, useRef } from 'react';
import { Controller, Control } from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import FormError from '../FormError/FormError';
import { IoCalendarSharp, IoClose } from 'react-icons/io5';
import CustomInput from '../common/CustomInput/CustomInput';
import HeaderCalendar from './HeaderCalendar';
import { isExist, isToday } from '../../utils/helper-function';

type IProps = {
  control: Control<any>;
  name: string;
  errors?: any;
  clearFormErrors?: any;
  setFormError?: any;
  txtErrorMsg?: string;
  required?: boolean;
  setFormValue: any;
  watchFormValue: any;
  maxDate?: any;
  readOnly?: boolean;
  disabled?: boolean;
  isDirty?: boolean;
};

export default function CofarcoCalendar(props: IProps) {
  const selectedDate = props.watchFormValue(props.name);
  const datePickerRef: any = useRef(null);

  const handleDateSelect = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(false);
    }
  };

  const dateClassName = (date: any) => {
    if (isToday(date)) {
      return 'custom-datepicker-day-today';
    }
    if (props.maxDate && date > new Date()) {
      return 'custom-datepicker-day-disabled';
    }
    return null;
  };

  useEffect(() => {
    if (props.required && !isExist(selectedDate) && props.isDirty) {
      props.setFormError(props.name, {
        message: props.txtErrorMsg,
      });
    } else {
      props.clearFormErrors?.(props.name);
    }
  }, [props.required]);

  return (
    <div
      className="d-flex flex-column align-items-baseline w-100 relative "
      style={{ position: 'relative' }}
    >
      <Controller
        name={props.name}
        control={props.control}
        rules={{ required: props.required ? true : false }}
        render={({ field }) => {
          return (
            <ReactDatePicker
              className="form-control"
              dayClassName={dateClassName}
              shouldCloseOnSelect
              readOnly={props.readOnly ? true : false}
              disabled={props.disabled}
              maxDate={props.maxDate}
              customInput={
                <CustomInput
                  className="mx-0"
                  onChange={() => {}}
                  rightIcon={
                    isExist(selectedDate) &&
                    !isExist(props.readOnly) &&
                    !isExist(props.disabled) && (
                      <div className="d-flex">
                        <IoClose
                          color="#851001"
                          size={20}
                          className="mt-2 me-4"
                          onClick={() => {
                            props.setFormValue(props.name, null, {
                              shouldDirty: true,
                            });
                            if (props.required) {
                              props.setFormError(props.name, {
                                message: props.txtErrorMsg,
                              });
                            }
                          }}
                        />
                      </div>
                    )
                  }
                />
              }
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) =>
                HeaderCalendar({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                })
              }
              {...field}
              dateFormat="dd/MM/yyyy"
              placeholderText="Select a Date"
              autoComplete="off"
              selected={
                field?.value && field?.value !== null
                  ? moment(field?.value, 'DD/MM/YYYY').toDate()
                  : null
              }
              value={
                field?.value && field?.value !== null ? field?.value : undefined
              }
              ref={datePickerRef}
              onChange={(date) => {
                field.onChange(date);
                handleDateSelect();
                if (date && props?.clearFormErrors) {
                  props?.clearFormErrors(props.name);
                  return;
                }
              }}
              onBlur={field.onBlur}
            />
          );
        }}
      />

      <span style={{ position: 'absolute', right: 1, top: 1 }}>
        <IoCalendarSharp color="#851001" size={20} className="mt-2 mx-1" />
      </span>
      {props.errors && props.errors[props.name]?.message && (
        <FormError
          message={props.txtErrorMsg}
          style={{ position: 'absolute', bottom: -20, left: 0 }}
        />
      )}
    </div>
  );
}
