import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { baseApi } from '../../baseApi';
import { ApiFilter } from '../../../../@types';
import { url } from '../../url';
import { ContentType, Method } from '../../../../@types/common-types';
import {
  Indication,
  IndicationAttachment,
} from '../../../../@types/Indication/indication';
import { datas } from '../../back-end-service';

const indicationApi = baseApi.injectEndpoints({
  endpoints: (build: EndpointBuilder<any, any, any>) => {
    return {
      deleteIndication: build.mutation<{ success: boolean }, { id: number }>({
        query(arg) {
          return {
            url: `${url.indication.list}/${arg.id}`,
            params: {
              _dc: new Date().getTime(),
            },
            method: Method.DELETE,
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: 'Indication', id: arg.id },
        ],
      }),
      exportIndication: build.mutation<
        string,
        ApiFilter & { fields?: string[] }
      >({
        query: ({ fields, filter }) => {
          return {
            url: `export/${url.indication.list}`,
            data: {
              fields,
            },
            params: {
              filter,
            },
            method: Method.POST,
          };
        },
      }),
      createIndication: build.mutation<
        { success: boolean; indication: Indication },
        { data: any }
      >({
        query: ({ data }) => {
          return {
            url: `${url.indication.list}`,
            params: { _dc: new Date().getTime() },
            method: Method.POST,
            data,
          };
        },
        invalidatesTags: ['Indication'],
      }),
      editIndication: build.mutation<
        { indication: Indication; success: boolean },
        { data: any; id: string | number }
      >({
        query: ({ id, data }) => {
          return {
            url: `${url.indication.list}/${id}`,
            params: { _dc: new Date().getTime() },
            method: Method.PUT,
            data: data,
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: 'Indication', id: arg.id },
          'INDICATION_MARKET_LIST',
        ],
      }),

      uploadIndicationAttachment: build.mutation<
        { indication: IndicationAttachment; success: boolean },
        { item: File; id: string | number }
      >({
        query: ({ id, item }) => {
          return {
            url: `attachment/Indication/${id}`,
            params: { _dc: new Date().getTime() },
            method: Method.POST,
            data: datas({ item }, ContentType.FORM_DATA),
            headers: {
              'Content-type': ContentType.FORM_DATA,
            },
          };
        },
        invalidatesTags: ['IndicationAttachment'],
      }),


      updateIndicationAttachment: build.mutation<
        { indication: IndicationAttachment; success: boolean },
        { data: any; id: string | number; attachmentId: string | number }
      >({
        query: ({ id, data, attachmentId }) => {
          return {
            url: `attachment/Indication/${id}/${attachmentId}`,
            params: { _dc: new Date().getTime() },
            method: Method.PUT,
            data,
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: 'IndicationAttachment', id: arg.id },
        ],
      }),

      deleteIndicationAttachment: build.mutation<
        { success: boolean },
        { id: string | number }
      >({
        query: ({ id }) => {
          return {
            url: `attachment/${id}`,
            method: Method.DELETE,
          };
        },
        invalidatesTags: ['IndicationAttachment'],
      }),
    };
  },
});

export const {
  useDeleteIndicationMutation,
  useExportIndicationMutation,
  useCreateIndicationMutation,
  useEditIndicationMutation,
  useUploadIndicationAttachmentMutation,
  useUpdateIndicationAttachmentMutation,
  useDeleteIndicationAttachmentMutation,
} = indicationApi;
