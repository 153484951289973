import { Form } from 'antd';
import React, { useState } from 'react';
import { exportDateUi, isExist } from '../../utils/helper-function';

type IProps<T> = {
  rowItem: T;
  column: Array<any>;
};

export default function UseDynamicsTable<
  T extends { id?: number; key?: number; isCurrentEdit?: boolean },
>(props: IProps<T>) {
  const [editingKey, setEditingKey] = useState<number | string>('');
  const [dataSource, setDataSource] = useState<Array<T>>([]);

  const add = () => {
    const sizeOfDataSource = dataSource?.length;
    const lastItemId = sizeOfDataSource > 0 ? dataSource?.length : 0;

    const emptyRow = {
      ...props.rowItem,
      id: 0,
      key: (lastItemId || 0) + 1,
      editable: true,
      isCurrentEdit: true,
    };
    setDataSource([...dataSource, emptyRow]);
    edit(emptyRow);
  };

  const removeItem = (key: number) => {
    const filteredDataSource = dataSource?.filter(
      (el) => el.key?.toString() !== key?.toString(),
    );
    setDataSource(filteredDataSource);
  };

  const [form] = Form.useForm();

  const isEditing = (record: T) =>
    record?.key?.toString() === editingKey?.toString();

  const edit = (record: Partial<T> & { key: React.Key } & any) => {
    form.setFieldsValue({
      name: '',
      age: '',
      address: '',
      info: '',
      rating: '',
      ratting: '',
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    const currentEdit = dataSource?.find(
      (el) => el.key?.toString() === editingKey?.toString(),
    );
    const isAlreadySave = currentEdit?.id !== 0;
    if (isAlreadySave) {
      const restoreV = dataSource?.map((el) => {
        if (el.id?.toString() === editingKey?.toString()) {
          return {
            ...el,
            isCurrentEdit: false,
          };
        } else {
          return el;
        }
      });
      setDataSource(restoreV);
    } else {
      const tempD = dataSource.filter((el) => el.isCurrentEdit !== true);
      setDataSource(tempD);
    }
    setEditingKey('');
  };

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as any;
      console.log({ row });
      let hasError = false;
      if ('currency' in row && row.currency.trim() === '') {
        form.setFields([
          {
            name: 'currency',
            errors: ['Please enter currency'],
          },
        ]);
        hasError = true;
      }
      if ('iban' in row && row.iban.trim() === '') {
        form.setFields([
          {
            name: 'iban',
            errors: ['Please enter iban'],
          },
        ]);
        hasError = true;
      }
      if ('date' in row && row.date === '') {
        form.setFields([
          {
            name: 'date',
            errors: ['Please enter date'],
          },
        ]);
        hasError = true;
      }
      if ('outlook' in row && row.outlook.trim() === '') {
        form.setFields([
          {
            name: 'outlook',
            errors: ['Please enter outlook'],
          },
        ]);
        hasError = true;
      }
      if ('ratting' in row && row.ratting.trim() === '') {
        form.setFields([
          {
            name: 'ratting',
            errors: ['Please enter ratting'],
          },
        ]);
        hasError = true;
      }
      if (!hasError) {
        const newData = [...dataSource];
        const index = newData.findIndex((item) => key === item.key);
        if (index > -1) {
          const item = newData[index];
          newData.splice(index, 1, {
            ...item,
            ...row,
            date: exportDateUi(row?.date),
            isCurrentEdit: false,
          });
          setDataSource(newData);
          setEditingKey('');
        } else {
          newData.push(row);
          setDataSource(newData);
          setEditingKey('');
        }
      }
    } catch (errInfo) {
      console.log(errInfo);
    }
  };

  const mergedColumns = props.column.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: col.dataIndex,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return {
    edit,
    save,
    mergedColumns,
    cancel,
    isEditing,
    isInModeEditing: editingKey === 0 || isExist(editingKey) ? true : false,
    setEditingKey,
    editingKey,
    form,
    add,
    dataSource,
    removeItem,
    //EditableCell,
    setDataSource,
  };
}
