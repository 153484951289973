import { Button, Card, Divider, Flex, Table } from 'antd';
import {
  ReminderDealsProps,
  useDashboardReminder,
} from '../../hooks/dashboard/useDashboardReminder';
import { useState } from 'react';

// Expiry table

const ReminderView = (props: ReminderDealsProps) => {
  const { columns, editRow, rows } = useDashboardReminder(props);
  return (
    <Table
      size="small"
      className="dashboard-table"
      pagination={false}
      showHeader={false}
      rowKey={(record) => `${record.id}-${(record as any)[props.dateIndex]}`}
      id="dashboard-expiry-table"
      columns={columns}
      dataSource={rows}
      rowClassName="selectRowClassName"
      onRow={(record) => {
        return {
          onClick: () => editRow(record),
        };
      }}
      scroll={{ y: 265, scrollToFirstRowOnChange: false, x: 'max-content' }}
    />
  );
};

export const DashboardReminder = () => {
  const [active, setActive] = useState(0);

  return (
    <Card
      style={{ marginTop: 24 }}
      className="dashboard-table-wrapper"
      size="small"
      styles={{ body: { overflowX: 'hidden' } }}
    >
      <span className="base-card-title">Reminders</span>
      <Flex gap="small" wrap="wrap" style={{ marginTop: 24 }}>
        {['Premium', 'Expiry', 'Non-renewal'].map((label, index) => (
          <Button
            key={index}
            onClick={() => setActive(index)}
            className={`dashboard-reminder-btn-${
              index === active ? '' : 'in'
            }active`}
          >
            {label}
          </Button>
        ))}
      </Flex>

      <Divider style={{ marginTop: 10, marginBottom: 0 }} />

      {active === 0 && (
        <ReminderView
          legalEntityIndex="legal_entity"
          route="premium"
          dateIndex="payment_date"
        />
      )}
      {active === 1 && (
        <ReminderView
          legalEntityIndex="mandate_name"
          route="expiry"
          dateIndex="expiry_date"
        />
      )}
      {active === 2 && (
        <ReminderView
          legalEntityIndex="mandate_name"
          route="nonRenewal"
          dateIndex="non_renewal_notice_date"
        />
      )}
    </Card>
  );
};
