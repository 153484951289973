import React from 'react';
import { FormControl, FormControlProps } from 'react-bootstrap';

type InputProps = {
  rightIcon?: React.ReactNode;
  leftIcon?: React.ReactNode;
} & FormControlProps;

const CustomInput = React.forwardRef(
  ({ rightIcon, leftIcon, ...props }: InputProps, ref) => {
    const idkey = props.id ?? new Date().getTime().toString();
    return (
      <div className="d-flex form-control pe-1 ps-0 py-0">
        {leftIcon && (
          <label htmlFor={idkey} style={{ cursor: 'pointer' }}>
            {leftIcon}
          </label>
        )}

        <FormControl
          {...props}
          id={idkey}
          ref={ref}
          style={{ border: 'none' }}
        />
        {rightIcon && (
          <label htmlFor={idkey} style={{ cursor: 'pointer' }}>
            {rightIcon}
          </label>
        )}
      </div>
    );
  },
);
CustomInput.displayName = 'CustomInput';
export default CustomInput;
