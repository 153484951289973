import { useForm } from 'react-hook-form';
import {
  useLazyLegalEntityByIdQuery,
  useSearchLegalEntityNameMutation,
} from '../../../../redux/api/ws/databases/legale-entities';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { LegalEntity } from '../../../../@types/legal-entities';
import { DropDownOptionType } from '../../../common/DropDown/DropdownSelect';
import { People, PeopleFormInputs } from '../../../../@types/people';
import { isEmpty, isEqual, pick, pickBy } from 'lodash';
import { ConterParty } from '../../../../@types/conterParty';
import {
  useLazyGetPeopleConterPartyQuery,
  useLazyListConterPartyQuery,
  useSetPeopleConterPartyMutation,
} from '../../../../redux/api/ws/databases/counterParty';
import { useListTextQuery } from '../../../../redux/api/ws/terms/get';
import {
  useCreatePeopleMutation,
  useEditPeopleMutation,
  useLazySearchPeopleQuery,
} from '../../../../redux/api/ws/databases/people';
import { updateNotificationMessage } from '../../../../redux/slice/notificationSlice';
import { useAppDispatch } from '../../../../hooks/redux';
import { updateModalAction } from '../../../../redux/slice/modalSlice';

const usePeopleModal = ({
  defaultFormValues,
  onSubmittedForm,
}: {
  onSubmittedForm?: (isEdit: boolean) => void;
  defaultFormValues?: People | null;
}) => {
  const defaultValues: PeopleFormInputs = useMemo(
    () => ({
      id: -1,
      legal_entity_id: 0,
      legal_entity_name: '',
      firstname: '',
      lastname: '',
      title: '',
      department: '',
      business_title: '',
      professional_mail: '',
      personal_mail: '',
      other_mail: '',
      phone_number: '',
      company_number: '',
      mobile_number: '',
      other_number: '',
      fax_number: '',
      known_since: null,
      linkedin_profile: '',
      twitter_account: '',
      skype_name: '',
      whatsapp_id: '',
      additional_information: '',
    }),
    [],
  );

  const formValues = useMemo(
    () =>
      defaultFormValues
        ? ({
            ...pick(defaultFormValues, Object.keys(defaultValues)),
            legal_entity_name: defaultFormValues?.legal_entity?.name ?? '',
          } as PeopleFormInputs)
        : defaultValues,
    [defaultFormValues, defaultValues],
  );

  const [pPxits, setPexists] = useState(false);

  const dispatch = useAppDispatch();

  const [legalEntitiesCounterParties, setLegalEntitiesCounterParties] =
    useState<ConterParty[]>([]);
  const [peopleCounterParty, setPeopleCounterParty] = useState<ConterParty[]>(
    [],
  );
  const [peopleDefaultCounterParty, setPeopleDefaultCounterParty] = useState<
    ConterParty[]
  >([]);

  const [isShowModalUnsavedChange, setIsShowModalUnsavedChange] =
    useState<boolean>(false);
  const [showOtherModaldata, setShowOtherModal] = useState<any>();

  const [legalEntities, setLegalEntities] = useState<DropDownOptionType[]>([]);
  const [searchLegalEntityQuery] = useSearchLegalEntityNameMutation();
  const [isLeagEntityLoading, setIsLeagEntityLoading] =
    useState<boolean>(false);
  const [getListConterParty] = useLazyListConterPartyQuery();
  const [getPeopleListConterParty] = useLazyGetPeopleConterPartyQuery();

  const { data: textTerms } = useListTextQuery(undefined);

  ///
  const [createPeopleMutation] = useCreatePeopleMutation();
  const [editPeopleMutation] = useEditPeopleMutation();
  const [setPeopleConterPartyMutation] = useSetPeopleConterPartyMutation();

  const [getLegaleEntitiesByIdQuery] = useLazyLegalEntityByIdQuery();

  //Check people
  const [searchPeople] = useLazySearchPeopleQuery();

  const {
    handleSubmit,
    register,
    // trigger: triggerFormValidation,
    watch: watchFormValue,
    reset: resetForm,
    setValue: setFormValue,
    getValues: getFormValue,

    formState: { errors, isDirty, dirtyFields },
  } = useForm<PeopleFormInputs>({
    shouldUseNativeValidation: false,
    defaultValues: formValues,
    reValidateMode: 'onChange',
    mode: 'all',
  });

  const setPeopleCounterPartyCallback = useCallback(
    (id: string | number, counterparties: ConterParty[]) => {
      setPeopleConterPartyMutation({
        id: id,
        ids: counterparties.map((c) => c.id),
      });
    },
    [],
  );

  const createPeople = (data: any, counterparties: ConterParty[]) => {
    createPeopleMutation(data)
      .then((resp: any) => {
        if (resp.data && resp.data.people) {
          setPeopleCounterPartyCallback(resp.data.people.id, counterparties);
        }
      })
      .finally(() => {
        dispatch(
          updateNotificationMessage({
            title: 'Add People',
            body: 'Successfully added!',
            show: true,
          }),
        );
        if (onSubmittedForm) {
          onSubmittedForm(false);
        }
      });
  };

  const createPeopleCallback = useCallback(
    async (data: any, counterparties: ConterParty[]) => {
      const response = await searchPeople({
        params: {
          limit: 1,
          filter: {
            0: {
              property: 'firstname',
              value: data.firstname,
              operator: 'like',
            },
            1: {
              property: 'lastname',
              value: data.lastname,
              operator: 'like',
            },
          },
        },
      });

      if (response?.data?.people?.length) {
        setPexists(true);
      } else createPeople(data, counterparties);
    },
    [],
  );

  const confirmCreationExisting = () => {
    setPexists(false);
    createPeople(getFormValue(), peopleCounterParty);
  };

  const editPeopleCallback = useCallback(
    (id: string | number, data: any, counterparties: ConterParty[]) => {
      editPeopleMutation({ id, data: data })
        .then((resp: any) => {
          if (resp.data && resp.data.people) {
            setPeopleCounterPartyCallback(resp.data.people.id, counterparties);
          }
        })
        .finally(() => {
          dispatch(
            updateNotificationMessage({
              title: 'Update People',
              body: 'Successfully updated!',
              show: true,
            }),
          );
          if (onSubmittedForm) {
            onSubmittedForm(true);
          }
        });
    },
    [],
  );

  const handleFormModalSubmit = (
    data: PeopleFormInputs,
    counterparties: ConterParty[],
    //defaultValues: PeopleFormInputs
  ) => {
    if (data.id && data.id > 0) {
      const dataToedit = pickBy(
        data,
        (v, k) => !isEqual((formValues as any)[k], v),
      ) as Record<string, any>;
      editPeopleCallback(
        getFormValue('id') ?? '',
        Object.keys(dataToedit).length === 0
          ? { id: getFormValue('id') ?? '', isDirty: 3 }
          : dataToedit,
        counterparties,
      );
    } else {
      createPeopleCallback(data, counterparties);
    }
  };

  const getConterParty = useCallback(
    (id: string | number) => {
      getListConterParty({ legalEntityId: id }).then((resp) => {
        if (resp.data && resp.data.counterparty) {
          setLegalEntitiesCounterParties(resp.data.counterparty);
        }
      });
    },
    [getListConterParty, setLegalEntitiesCounterParties],
  );

  const getPeopleConterParty = useCallback(
    (id: string | number) => {
      getPeopleListConterParty({ id }).then((resp) => {
        if (resp.data && resp.data.counterparty) {
          setPeopleCounterParty(resp.data.counterparty);
          setPeopleDefaultCounterParty(resp.data.counterparty);
        }
      });
    },
    [
      getPeopleListConterParty,
      setPeopleCounterParty,
      setPeopleDefaultCounterParty,
    ],
  );

  const searchLegalEntity = useCallback(
    (
      value: string,
      dipatch?: Dispatch<SetStateAction<DropDownOptionType[]>>,
    ) => {
      setIsLeagEntityLoading(true);
      searchLegalEntityQuery({
        page: 1,
        start: 0,
        limit: 100,
        query: `%${value}%`,
      })
        .then((resp) => {
          const rep = resp as any;
          if (rep.data) {
            const datas =
              rep.data.legalentity &&
              (rep.data.legalentity as LegalEntity[])
                .filter((f) => f && f.name.trim() !== '')
                .map((e) => ({ key: e, value: e.name ?? '' }));
            setLegalEntities(datas);
            if (dipatch) dipatch(datas);
          }
        })
        .finally(() => {
          setIsLeagEntityLoading(false);
        });
    },
    [],
  );

  const onSubmit = (data: any, e: any) => {
    e.preventDefault();
    handleFormModalSubmit(data, peopleCounterParty);
  };
  const onError = () => null;

  const onSubmitForm = (e: any) => {
    handleSubmit(onSubmit, onError)(e);
  };
  const onCloseModal = () => {
    resetForm(formValues);
    setLegalEntitiesCounterParties([]);
  };

  const handleSelectCounterParty = (c: ConterParty) => {
    if (peopleCounterParty.map((e) => e.id).includes(c.id)) {
      setPeopleCounterParty(peopleCounterParty.filter((f) => f.id !== c.id));
    } else {
      setPeopleCounterParty([...peopleCounterParty, c]);
    }
  };

  const isEdit = useMemo(() => {
    return Boolean(defaultFormValues?.id);
  }, [formValues]);

  const onClickLegalEntity = (data: LegalEntity, noCheck?: boolean) => {
    if (!isEmpty(dirtyFields) && !noCheck && isEdit) {
      setShowOtherModal(data);
      setIsShowModalUnsavedChange(true);
    } else {
      setIsShowModalUnsavedChange(false);
      setShowOtherModal(undefined);
      dispatch(
        updateModalAction({
          data: { ...data },
          isAdd: true,
          type: 'legal_entity',
        }),
      );
      isEdit && resetForm(formValues);
    }
  };

  useEffect(() => {
    resetForm(formValues);
    if (formValues.id > 0) {
      getPeopleConterParty(formValues.id);
    } else {
      setPeopleCounterParty([]);
    }
  }, [formValues]);

  useEffect(() => {
    searchLegalEntity('');
  }, [searchLegalEntity]);

  const legal_entity_id = watchFormValue('legal_entity_id');

  useEffect(() => {
    if (legal_entity_id && legal_entity_id > 0) {
      getConterParty(legal_entity_id);
    }
  }, [legal_entity_id, formValues]);

  useEffect(() => {
    if (!defaultFormValues) {
      setLegalEntitiesCounterParties([]);
    } else {
      if (
        defaultFormValues.legal_entity_id &&
        defaultFormValues.legal_entity_id > 0
      ) {
        getConterParty(defaultFormValues.legal_entity_id);
      }
    }
  }, [defaultFormValues]);

  const isValidLinkedInUrl = (url: string) => {
    if (url.trim() === '') return true;
    /*const linkedInRegex =
      /^(https:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9%_-]{5,30}(\/[a-zA-Z0-9%_-]+)?\/?$/;*/
    const linkedInRegex =
      /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*/gm;
    return linkedInRegex.test(url);
  };

  return {
    errors,
    legalEntities,
    isLeagEntityLoading,
    defaultValues: formValues,
    legalEntitiesCounterParties,
    peopleCounterParty,
    peopleDefaultCounterParty,
    textTerms,
    setLegalEntitiesCounterParties,
    handleSelectCounterParty,
    watchFormValue,
    onCloseModal,
    register,
    onSubmitForm,
    searchLegalEntity,
    setFormValue,
    getFormValue,
    getLegaleEntitiesByIdQuery,
    setLegalEntities,
    isDirty,
    isShowModalUnsavedChange,
    setIsShowModalUnsavedChange,
    showOtherModaldata,
    onClickLegalEntity,
    dirtyFields,
    isValidLinkedInUrl,
    pPxits,
    setPexists,
    createPeople,
    confirmCreationExisting,
  };
};

export default usePeopleModal;
