import React, { Dispatch, SetStateAction, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  EntityMandate,
  EntityOwnerName,
  InputFormMandate,
} from '../../../@types/mandates/mandates';
import {
  useCreateMandateMutation,
  useGetOwnerQuery,
  useGetPeopleByConterPartyIdMutation,
  useSearchCofarcoMutation,
  useUpdateMandateMutation,
} from '../../../redux/api/ws/mandates/mandates';
import { useCallback } from 'react';
import { useSearchLegalEntityNameMutation } from '../../../redux/api/ws/databases/legale-entities';
import { BusinessType, ConterParty } from '../../../@types/conterParty';
import {
  amountFormat,
  checkIsEdit,
  concatString,
  exportDateUi,
  exportDateWs,
  floorNumber,
  getObjectDifferences,
  isExist,
  nullingEmptyValues,
  setDefaultValues,
  setEmptyUndefinedEditValues,
} from '../../../utils/helper-function';
import { useAppDispatch } from '../../../hooks/redux';
import { updateNotificationMessage } from '../../../redux/slice/notificationSlice';
import { People } from '../../../@types/people';
import { useGetCounterPartyPartnerByIdMutation } from '../../../redux/api/ws/enquiries/enquiries';
import { updateModalAction } from '../../../redux/slice/modalSlice';
import { useLazyCofarcoTeamByIdQuery } from '../../../redux/api/ws/databases/cofarco-teams';
import { isEmpty } from 'lodash';
import { useGetCountryQuery } from '../../../redux/api/ws/databases/groups';
import { DropDownOptionType } from '../../common/DropDown/DropdownSelect';
import { LegalEntity } from '../../../@types/group/types';
type IProps = {
  defaultFormValues: InputFormMandate | null;
  closingModal: (itemsDataSource: EntityMandate | null) => void;
};
export default function UseModalMandate(props: IProps) {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, dirtyFields },
    control,
    watch,
    getValues,
    setValue,
    reset,
    setError: setFormError,
    clearErrors: clearFormErrors,
  } = useForm<InputFormMandate>({
    mode: 'onChange',
    defaultValues: {
      brokerage: 0,
      activity: 'off',
      fee: 0,
    },
  });
  const in_charge_team = watch('in_charge_team');
  const number = watch('number');
  const partner_id = watch('partner_id');
  const client_id = watch('client_id');
  const id = watch('id');
  const country = watch('main_country_id');
  const obligor_legal_entity_id = watch('obligor_legal_entity_id');

  const [createMandate, { isLoading: isLoadingCreateMandate }] =
    useCreateMandateMutation();

  const [updateMandate, { isLoading: isLoadingUpdateMandate }] =
    useUpdateMandateMutation();

  const [getCofarcoTeam] = useLazyCofarcoTeamByIdQuery();
  const { data: responseCountry } = useGetCountryQuery();
  const [
    getOptionsLegalEntity,
    {
      data: legalEntityOption,
      isLoading: isLoadingLegalEntity,
      reset: resetMainObligor,
    },
  ] = useSearchLegalEntityNameMutation();

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isCompletedForm, setIsCompletedForm] = useState(false);
  const [activeMenu, setActiveMenu] = useState<number>(1);
  const [isShowModalUnsavedChange, setIsShowModalUnsavedChange] =
    useState<boolean>(false);
  const [showOtherModal, setShowOtherModal] = useState<{
    idToShow: any;
    showingFunction: string;
    type?: string;
  }>();

  const [getCofarco, { data: clients, isLoading }] = useSearchCofarcoMutation();

  const [clientsList, setClients] = useState<Array<ConterParty>>([]);
  const [partenerList, setPartenerList] = useState<Array<ConterParty>>([]);

  const [getPeopleByCounterPartie] = useGetPeopleByConterPartyIdMutation();
  const [peoples, setPeoples] = useState<Array<People>>([]);
  const [partenersPeople, setPartenersPeople] = useState<Array<People>>([]);
  const [legalEntities, setLegalEntities] = useState<Array<LegalEntity>>([]);

  const [getPartner, { isLoading: isGetPartnerLoading }] =
    useGetCounterPartyPartnerByIdMutation();

  const { data: ownerList } = useGetOwnerQuery();

  const optionUserNameOwner: Array<EntityOwnerName & { userName: string }> =
    in_charge_team
      ? ownerList?.user
          .map((el: EntityOwnerName) => {
            return {
              ...el,
              userName: concatString(el.lastname, el.firstname),
            };
          })
          ?.filter((el) => el.team === in_charge_team) || []
      : [];

  const dispatch = useAppDispatch();

  const handlCounterPartie = async (
    text?: string,
    type?: BusinessType,
    id?: number,
  ) => {
    try {
      const params = {
        page: 1,
        start: 0,
        limit: 100,
        query: text ? `%${text?.trim()}%` : undefined,
        businessType: type || 'client',
      };

      const response = await getCofarco(
        id
          ? {
              ...params,
              filter: JSON.stringify([
                { property: 'id', operator: '=', value: id },
              ]),
            }
          : { ...params },
      ).unwrap();
      const tempOption = response?.counterparty;
      if (type === 'client') {
        setClients(tempOption);
      }
      if (type === 'partner') {
        setPartenerList(tempOption);
      }
    } catch (error) {
      return [];
    }
  };

  const getLegalNameOption = async (
    text: string,
    limit: number = 30,
    id?: number,
  ) => {
    try {
      const params = {
        page: 1,
        start: 0,
        limit: limit || 30,
        query: `%${text?.trim()}%`,
      };

      const result: any = await getOptionsLegalEntity(
        id
          ? {
              ...params,
              filter: JSON.stringify([
                { property: 'id', operator: '=', value: id },
              ]),
            }
          : { ...params },
      );

      if (result && result.data && result.data.legalentity) {
        setLegalEntities(result.data.legalentity);
      }
    } catch (error) {
      return [];
    }
  };

  React.useEffect(() => {
    const objFiltered = legalEntities.filter(
      (el) => el.id === obligor_legal_entity_id,
    );
    if (objFiltered && objFiltered.length > 0) {
      const obj = objFiltered[0];
      setValue('main_country_id', obj.country_id);
    }
  }, [obligor_legal_entity_id]);

  const getPeople = async (
    id: number | string,
    type: string = 'partner_contact_id',
    query?: string,
  ) => {
    if (id) {
      try {
        const resp = await getPeopleByCounterPartie({ id, query }).unwrap();
        const floorData =
          resp.people?.map((el) => ({
            ...el,
            userName: concatString(el.lastname, el.firstname),
          })) || [];
        if (type === 'partner_contact_id') {
          setPartenersPeople(floorData);
          return;
        }
        setPeoples(floorData);
      } catch (error) {
        setPeoples([]);
      }
    }
  };

  const handlePartnerCounterParty = async (id: number) => {
    try {
      const resp = await getPartner({ id }).unwrap();
      setPartenerList([resp.counterparty]);
    } catch (error) {
      return [];
    }
  };

  const runSearch = useCallback(
    async (
      key: keyof InputFormMandate,
      value: string,
      dispatch?: Dispatch<SetStateAction<DropDownOptionType[]>>,
    ) => {
      if (value !== undefined && value !== null) {
        if (key === 'client_id') {
          await handlCounterPartie(value, 'client');
        }
        if (key === 'partner_id') {
          await handlCounterPartie(value, 'partner');
        }
        if (key === 'obligor_legal_entity_id') {
          await getLegalNameOption(value);
        }
        if (key === 'partner_contact_id') {
          await getPeople(partner_id as number, 'partner_contact_id', value);
        }
        if (key === 'main_people_client_id') {
          await getPeople(client_id as number, 'main_people_client_id', value);
        }
        if (key === 'most_senior_client_id') {
          await getPeople(client_id as number, 'main_people_client_id', value);
        }
        if (key === 'main_country_id') {
          const res =
            (responseCountry?.country || [])
              .map((el) => ({ key: el.key, value: el.name }))
              .filter((word) =>
                word.value
                  ?.toLocaleLowerCase()
                  .includes(value?.toLocaleLowerCase()),
              ) ?? [];
          if (dispatch) dispatch(res);
        }
      }
    },
    [partner_id, responseCountry?.country],
  );

  const resetForm = () => {
    const defaultValue = setDefaultValues<InputFormMandate>(watch());
    reset({
      ...defaultValue,
      activity: 'off',
      brokerage: 1,
      fee: 1,
      name: '',
      targeted_amount: '0.00',
    });
    setClients([]);
    setPartenerList([]);
    setPartenersPeople([]);
    setIsEdit(false);
    setActiveMenu(1);
    resetMainObligor();
  };

  React.useEffect(() => {
    const subscription = watch((value) => {
      const isRequiredEndDate =
        value.phase === 'mandate' &&
        isExist(value.status) &&
        value.status !== 'executing';

      const isRequiredInceptionDate = value.phase === 'mandate';

      // const isRequiredMainObligor = value.mandate_type === 'insurance';
      const isRequiredMainObligor = false;

      let isCompletedCurrency = false;
      let isCompletedEndDate: boolean = false;
      let isCompletedInceptionDate: boolean = false;
      let isCompletedMainObligor: boolean = false;

      if (isRequiredEndDate) {
        isCompletedEndDate = isExist(value.end_date) ? true : false;
        if (isExist(value.end_date)) {
          clearFormErrors('end_date');
        }
      } else {
        isCompletedEndDate = true;
        clearFormErrors('end_date');
      }

      if (isRequiredInceptionDate) {
        isCompletedInceptionDate = isExist(value.inception_date) ? true : false;
        if (isExist(value.inception_date)) {
          clearFormErrors('inception_date');
        }
      } else {
        isCompletedInceptionDate = true;
        clearFormErrors('inception_date');
      }

      if (isRequiredMainObligor) {
        isCompletedMainObligor = isExist(value.obligor_legal_entity_id)
          ? true
          : false;
        if (isExist(value.obligor_legal_entity_id)) {
          clearFormErrors('obligor_legal_entity_id');
        }
      } else {
        isCompletedMainObligor = true;
        clearFormErrors('obligor_legal_entity_id');
      }

      if (((floorNumber(value?.targeted_amount) as number) || 0) > 0) {
        isExist(value.currency)
          ? (isCompletedCurrency = true)
          : (isCompletedCurrency = false);
      } else {
        isCompletedCurrency = true;
      }
      const isCompletedRequired =
        value.client_id &&
        value.name &&
        value.mandate_type &&
        value.business_source &&
        value.first_discu_date &&
        value.status;

      const isValidateForm =
        isCompletedRequired &&
        isCompletedCurrency &&
        isCompletedEndDate &&
        isCompletedInceptionDate &&
        isCompletedMainObligor;

      setIsCompletedForm(isValidateForm ? true : false);
      setIsEdit(isExist(value.id) ? true : false);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const submit = async (data: InputFormMandate) => {
    const isUpdated = checkIsEdit(data.id || '');
    console.log({ data });
    const params: InputFormMandate = {
      ...data,
      end_date: exportDateWs(data.end_date) as string,
      first_discu_date: exportDateWs(data.first_discu_date) as string,
      inception_date: exportDateWs(data.inception_date) as string,
      activity: data.activity === 'on' ? true : false,
      brokerage: data.brokerage ? true : false,
      fee: data.fee ? true : false,
      targeted_amount: floorNumber(data.targeted_amount || 0),
      work_fee: data.work_fee || 0,
      main_people_client_id: data.main_people_client_id || null,
      partner_id: data.partner_id || null,
      partner_contact_id: data.partner_contact_id || null,
      most_senior_client_id: data.most_senior_client_id || null,
      in_charge_user_id: data.in_charge_user_id || null,
      obligor_legal_entity_id: data.obligor_legal_entity_id || null,
      main_country_id: data.main_country_id || null,
    };
    if (isUpdated) {
      const objectDiff = getObjectDifferences(props.defaultFormValues, params);
      try {
        const response = await updateMandate(
          setEmptyUndefinedEditValues({ ...objectDiff, id }),
        ).unwrap();
        if (response && response.success) {
          dispatch(
            updateNotificationMessage({
              title: 'Update Mandate',
              body: `Mandate ${response.mandate?.number} has been updated !`,
              show: true,
            }),
          );
          props.closingModal(null);
        }
        resetForm();
      } catch (error: any) {
        console.log(error);
      }
    } else {
      try {
        const respose = await createMandate(
          nullingEmptyValues(params),
        ).unwrap();
        if (respose && respose.success) {
          dispatch(
            updateNotificationMessage({
              title: 'New Mandate',
              body: `Mandate ${respose.mandate?.number} has been added  !`,
              show: true,
            }),
          );
          props.closingModal(respose.mandate);
        }

        resetForm();
      } catch (error: any) {
        console.log(error);
      }
    }
  };

  React.useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'client_id') {
        if (value.client_id) {
          getPeople(value.client_id, 'main_people_client_id');
        } else {
          setValue('main_people_client_id', null);
          setValue('most_senior_client_id', null);
          setPeoples([]);
        }
      }

      if (name === 'targeted_amount') {
        if (Number(floorNumber(value.targeted_amount)) > 0) {
          if (isEmpty(value.currency)) {
            setFormError('currency', {
              message: 'Currency is required',
            });
          }
        } else {
          clearFormErrors('currency');
        }
      }

      const isEmptyPartener =
        name === 'partner_id' && !isExist(value.partner_id as number);

      if (isEmptyPartener) {
        setValue('partner_contact_id', null);
      }

      if (name === 'partner_id' && value.partner_id) {
        getPeople(value.partner_id);
      }

      if (name === 'phase') {
        setValue('status', '');
        setValue('activity', 'off');
      }

      const isActiveActivity =
        name === 'status' &&
        (value.status === 'discussing' ||
          value.status === 'executing' ||
          value.status === 'sent');

      if (isActiveActivity) {
        setValue('activity', 'on');
      }

      const isActiveOff =
        name === 'status' &&
        (value.status === 'gone' ||
          value.status === 'completed' ||
          value.status === 'client_termination' ||
          value.status === 'cofarco_termination' ||
          value.status === 'no_solution' ||
          value.status === 'no_answer' ||
          value.status === 'pulled' ||
          value.status === 'turned_down');

      if (isActiveOff) {
        setValue('activity', 'off');
      }

      const isActiveBrokker =
        name === 'mandate_type' &&
        (value.mandate_type === 'hedge' || value.mandate_type === 'insurance');
      if (isActiveBrokker) {
        setValue('brokerage', 1);
      }

      const isInactiveBrokker =
        name === 'mandate_type' &&
        (value.mandate_type === 'other' ||
          value.mandate_type === 'advisory' ||
          value.mandate_type === 'financing');
      if (isInactiveBrokker) {
        setValue('brokerage', 0);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  React.useEffect(() => {
    if (props.defaultFormValues && props.defaultFormValues?.id) {
      const selectedMandate = props.defaultFormValues;
      if (selectedMandate?.client?.id) {
        handlCounterPartie(undefined, 'client', selectedMandate.client?.id);
      }

      if (selectedMandate?.client?.id) {
        getPeople(selectedMandate?.client?.id, 'main_people_client_id');
      }
      if (selectedMandate && selectedMandate?.obligor_legal_entity_id) {
        getLegalNameOption(
          '',
          50,
          selectedMandate?.obligor_legal_entity_id as number,
        );
      }

      if (selectedMandate?.partner_id) {
        handlePartnerCounterParty(selectedMandate?.partner_id);
        if (selectedMandate?.partner_contact_id) {
          getPeople(selectedMandate?.partner_id);
        }
      }
      reset({
        ...selectedMandate,
        end_date: exportDateUi(selectedMandate.end_date) as string,
        first_discu_date: exportDateUi(
          selectedMandate.first_discu_date,
        ) as string,
        // targeted_amount: amountFormat(
        //   selectedMandate?.targeted_amount || '0.00'
        // ),
        targeted_amount: amountFormat(
          Number.parseFloat(
            (selectedMandate?.targeted_amount as string) || '0.00',
          )?.toFixed(2),
        ) as string,

        inception_date: exportDateUi(selectedMandate.inception_date) as string,
        activity: selectedMandate.activity === 1 ? 'on' : 'off',
      });
    }
  }, [props.defaultFormValues]);

  const handleChangeToogle = (itemActive: number) => {
    setActiveMenu(itemActive);
  };

  const showClient = (idClient: string | number) => {
    const found = clientsList.find((el) => el.id === idClient);
    if (found) {
      dispatch(
        updateModalAction({
          data: found,
          isAdd: true,
          type: 'client',
        }),
      );
    }
  };

  const showLegalEntity = (idLgl: string | number) => {
    const found = (legalEntityOption?.legalentity || []).find(
      (el) => el.id === idLgl,
    );

    if (found) {
      dispatch(
        updateModalAction({
          data: {
            ...found,
            group_id: found?.group?.id,
            group_name: found?.group?.name,
          },
          isAdd: true,
          type: 'legal_entity',
        }),
      );
    }
  };

  const showPeople = (idPrt: number | null, type?: string) => {
    console.log('showPeople', idPrt, type);

    const peopleFilters =
      type === 'partnerContact' ? partenersPeople || [] : peoples;
    const found = (peopleFilters || []).find((el) => el.id === idPrt);
    if (found) {
      dispatch(
        updateModalAction({
          data: found,
          isAdd: true,
          type: 'people',
        }),
      );
    }
  };

  const showPartner = (id: number | null) => {
    const found = (partenerList || []).find((el) => el.id === id);
    if (found) {
      dispatch(
        updateModalAction({
          data: found,
          isAdd: true,
          type: 'partner',
        }),
      );
    }
  };

  const showCofarcoTeams = async (id: string | number) => {
    const found = (optionUserNameOwner || []).find((el) => el.id === id);
    if (found) {
      const resp = await getCofarcoTeam({ id: found?.id }).unwrap();

      if (resp.user && resp.success) {
        const userResult: any = resp.user;
        const tempRigth = userResult?.rights || {};
        const tempDataSourceRigth = [];
        for (const property in tempRigth) {
          tempDataSourceRigth.push({
            ...tempRigth[property],
            object: property,
          });
        }
        dispatch(
          updateModalAction({
            data: {
              ...userResult,
              entry_date: exportDateUi(userResult?.entry_date) as string,
              departure_date: exportDateUi(
                userResult?.departure_date,
              ) as string,
              username: concatString(
                userResult?.people?.firstname || '',
                userResult?.people?.lastname || '',
                ' ',
              ),
              history: userResult?.rights?.['history']?.read,
              data_source_rigth: tempDataSourceRigth,
            },
            isAdd: true,
            type: 'cofarco_team',
          }),
        );
      }
    }
  };

  const handleShowOtherModal = (id: any, func: string, type?: string) => {
    if (isDirty && isEdit) {
      setIsShowModalUnsavedChange(true);
      setShowOtherModal({ idToShow: id, showingFunction: func, type });
    } else {
      handleLeaveModalWithoutSave(id, func, type);
    }
  };

  const handleLeaveModalWithoutSave = (
    id: any,
    func: string,
    type?: string,
  ) => {
    setIsShowModalUnsavedChange(false);
    showFunctions(id, func, type);
    isEdit && resetForm();
  };

  const showFunctions = useCallback(
    (id: any, func: string, type?: string) => {
      switch (func) {
        case 'client':
          showClient(id);
          break;
        case 'legal_entity':
          showLegalEntity(id);
          break;
        case 'people':
          showPeople(id, type);
          break;
        case 'partner':
          showPartner(id);
          break;
        case 'cofarco_team':
          showCofarcoTeams(id);
          break;
        default:
          break;
      }
    },
    [showClient, showLegalEntity, showPeople, showPartner, showCofarcoTeams],
  );

  return {
    register,
    handleSubmit,
    control,
    watch,
    getValues,
    setValue,
    reset,
    errors,
    isDirty,
    runSearch,
    clients,
    isLoading: isGetPartnerLoading || isLoading,
    isLoadingLegalEntity,
    legalEntityOption,
    submit,
    isLoadingCreateMandate,
    peoples,
    clientsList,
    partenerList,
    setPeoples,
    partenersPeople,
    setPartenersPeople,
    optionUserNameOwner,
    resetForm,
    isEdit,
    isCompletedForm,
    setFormError,
    in_charge_team,
    partner_id,
    client_id,
    clearFormErrors,
    number,
    id,
    activeMenu,
    setActiveMenu,
    handleChangeToogle,
    showClient,
    showLegalEntity,
    showPeople,
    showPartner,
    showCofarcoTeams,
    isLoadingUpdateMandate,
    handlCounterPartie,
    dirtyFields,
    isShowModalUnsavedChange,
    setIsShowModalUnsavedChange,
    showFunctions,
    handleShowOtherModal,
    showOtherModal,
    handleLeaveModalWithoutSave,
    responseCountry,
    country,
  };
}
