export const mockOutlook = [
  {
    label: 'positive',
    value: 'positive',
  },
  {
    label: 'negative',
    value: 'negative',
  },
  {
    label: 'stable',
    value: 'stable',
  },
];
