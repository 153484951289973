import React from 'react';
import { Form, Row } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import {
  FieldErrorsImpl,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import InputInlineForm from '../../../../common/InputInlineForm/InputInlineForm';
import moment from 'moment';
import classes from './KycForm.module.scss';
import { ClientFormInputs } from '../../../../../@types/conterParty';
import CustomInput from '../../../../common/CustomInput/CustomInput';
import { FaTimes } from 'react-icons/fa';
import { IoCalendarSharp } from 'react-icons/io5';
import HeaderCalendar from '../../../../Calendar/HeaderCalendar';
import { isToday } from '../../../../../utils/helper-function';

type Info = Pick<ClientFormInputs, 'informations'>;
type Props = {
  register: UseFormRegister<Info>;
  watchValue: UseFormWatch<Info>;
  setValue: UseFormSetValue<Info>;
  errors: Partial<FieldErrorsImpl<Info>>;
};
const KycForm = React.forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ register, watchValue, setValue, errors }: Props, ref: any) => {
    const dateClassName = (date: any) => {
      if (isToday(date)) {
        return 'custom-datepicker-day-today';
      }
      return null;
    };

    return (
      <>
        <div className="font-bold font-weight-bold">KYC Fields</div>
        <div className="bg-white px-4 py-3  mt-4">
          <Row className="mt-3">
            <InputInlineForm
              title="KYC Rate"
              isValid={errors.informations?.kycRate?.message == undefined}
              error={
                errors.informations?.kycRate?.message as string | undefined
              }
              {...register('informations.kycRate')}
            />
          </Row>
          <Row className="mb-3">
            <Form.Group className="d-flex">
              <Form.Label
                htmlFor="forLastName"
                className={`d-flex me-3 mt-2 ${classes.formLabel}`}
              >
                KYC Validation Date
              </Form.Label>
              <div className="d-flex w-100 pe-1">
                <ReactDatePicker
                  className={`form-control picker-select ${classes.datePicker}`}
                  autoComplete="off"
                  dayClassName={dateClassName}
                  renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) =>
                    HeaderCalendar({
                      date,
                      changeYear,
                      changeMonth,
                      decreaseMonth,
                      increaseMonth,
                      prevMonthButtonDisabled,
                      nextMonthButtonDisabled,
                    })
                  }
                  selected={
                    watchValue('informations.kycValidationDate')
                      ? new Date(
                          watchValue(
                            'informations.kycValidationDate',
                          ) as string,
                        )
                      : null
                  }
                  value={
                    watchValue('informations.kycValidationDate')
                      ? moment(
                          watchValue('informations.kycValidationDate'),
                        ).format('DD/MM/YYYY')
                      : undefined
                  }
                  customInput={
                    <CustomInput
                      rightIcon={
                        <div className="d-flex">
                          {watchValue('informations.kycValidationDate') && (
                            <FaTimes
                              color="#851001"
                              className="mt-2 me-1"
                              size={20}
                              onClick={() => {
                                setValue(
                                  'informations.kycValidationDate',
                                  null,
                                );
                              }}
                            />
                          )}
                          <IoCalendarSharp
                            color="#851001"
                            className="mt-2 me-1"
                            size={20}
                          />
                        </div>
                      }
                    />
                  }
                  onChange={(e) => {
                    setValue(
                      'informations.kycValidationDate',
                      e ? moment(e).format('YYYY-MM-DD') : null,
                      { shouldDirty: true },
                    );
                  }}
                />
              </div>
            </Form.Group>
          </Row>
        </div>
      </>
    );
  },
);

KycForm.displayName = 'KycForm';

export default KycForm;
