import { Button } from 'react-bootstrap';
import classes from './IndicationFilter.module.scss';
import { FaSearch } from 'react-icons/fa';
import { IndicationFilterType } from '../../../@types/Indication/indication';
import DropdownSelect, {
  DropDownOptionObjectType,
  DropDownOptionType,
} from '../../common/DropDown/DropdownSelect';
import useIndicationFilter from './useIndicationFIlter';
import { Checkbox } from 'antd';

import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import CustomCheckBox from './CustomCheckBox/CustomCheckBox';
const CheckboxGroup = Checkbox.Group;

type Props = {
  onSearch?: () => void;
  filterData: IndicationFilterType;
  updateFilter: (
    key: keyof IndicationFilterType,
    value?: DropDownOptionType | null | string[],
    prevValue?: IndicationFilterType,
    callback?: (value: IndicationFilterType) => void
  ) => void;
};

const IndicationFilter = ({ filterData, onSearch, updateFilter }: Props) => {
  const {
    terms,
    underwriters,
    enquiries,
    clients,
    setUnderWriterQuery,
    setEnquiryQuery,
    setClientQuery,
  } = useIndicationFilter();

  const updateResultType = (isCheck: boolean, value: any) => {
    updateFilter(
      'result_type',
      isCheck
        ? [
            ...((filterData.result_type?.value as
              | string[]
              | null
              | undefined) ?? []),
            value,
          ]
        : (
            (filterData.result_type?.value as string[] | null | undefined) ?? []
          ).filter((p) => p !== value),
      filterData
    );
  };
  return (
    <div className='collapse' id='collapseSearchIndication'>
      <div className=' d-flex py-4 justify-content-end flex-wrap '>
        <DropdownSelect resetFormNoPreValue
          id='underwriter_id'
          placeholder='Market ID'
          preValue={(filterData.underwriter_idKey?.value as string) ?? ''}
          onSearch={(d, v) => {
            setUnderWriterQuery(v);
          }}
          options={underwriters.map((u) => ({ key: u, value: u.name }))}
          onSelectOption={(e) => {
            if (e) {
              updateFilter(
                'underwriter_id',
                `${
                  (e as DropDownOptionObjectType).key.id
                }` as DropDownOptionType,
                filterData,
                (v) => {
                  updateFilter(
                    'underwriter_idKey',
                    (e as DropDownOptionObjectType).value as DropDownOptionType,
                    v
                  );
                }
              );
            } else {
              updateFilter('underwriter_id', null, filterData, (v) => {
                updateFilter('underwriter_idKey', null, v);
              });
            }
          }}
        />
        <DropdownSelect resetFormNoPreValue
          id='enquiry_id'
          placeholder='Enquiry ID'
          preValue={(filterData.enquiry_idKey?.value as string) ?? ''}
          onSearch={(d, v) => {
            setEnquiryQuery(v);
          }}
          options={enquiries.map((u) => ({ key: u, value: u.number }))}
          onSelectOption={(e) => {
            if (e) {
              updateFilter(
                'enquiry_id',
                `${
                  (e as DropDownOptionObjectType).key.id
                }` as DropDownOptionType,
                filterData,
                (v) => {
                  updateFilter(
                    'enquiry_idKey',
                    (e as DropDownOptionObjectType).value as DropDownOptionType,
                    v
                  );
                }
              );
            } else {
              updateFilter('enquiry_id', null, filterData, (v) => {
                updateFilter('enquiry_idKey', null, v);
              });
            }
          }}
        />
        <DropdownSelect resetFormNoPreValue
          id='client_id'
          placeholder='Client ID'
          preValue={(filterData.client_idKey?.value as string) ?? ''}
          onSearch={(d, v) => {
            setClientQuery(v);
          }}
          options={clients.map((u) => ({ key: u, value: u.name }))}
          onSelectOption={(e) => {
            if (e) {
              updateFilter(
                'client_id',
                `${
                  (e as DropDownOptionObjectType).key.id
                }` as DropDownOptionType,
                filterData,
                (v) => {
                  updateFilter(
                    'client_idKey',
                    (e as DropDownOptionObjectType).value as DropDownOptionType,
                    v
                  );
                }
              );
            } else {
              updateFilter('client_id', null, filterData, (v) => {
                updateFilter('client_idKey', null, v);
              });
            }
          }}
        />

        <DropdownSelect resetFormNoPreValue
          id='market'
          placeholder='Enquiry Market'
          preValue={(filterData.marketKey?.value as string) ?? ''}
          onSearch={(d, v) => {
            d(
              terms.filter(
                (t) =>
                  t.type === 'dealMarket' &&
                  t.value.toLowerCase().includes(v.toLowerCase())
              )
            );
          }}
          options={terms.filter((t) => t.type === 'dealMarket')}
          onSelectOption={(e) => {
            if (e) {
              updateFilter(
                'market',
                `${(e as DropDownOptionObjectType).key}` as DropDownOptionType,
                filterData,
                (v) => {
                  updateFilter(
                    'marketKey',
                    (e as DropDownOptionObjectType).value as DropDownOptionType,
                    v
                  );
                }
              );
            } else {
              updateFilter('market', null, filterData, (v) => {
                updateFilter('marketKey', null, v);
              });
            }
          }}
        />

        <DropdownSelect resetFormNoPreValue
          id='enquiry_type'
          placeholder='Enquiry Type'
          preValue={(filterData.enquiry_typeKey?.value as string) ?? ''}
          onSearch={(d, v) => {
            d(
              terms.filter(
                (t) =>
                  t.type === 'dealType' &&
                  t.value.toLowerCase().includes(v.toLowerCase())
              )
            );
          }}
          options={terms.filter((t) => t.type === 'dealType')}
          onSelectOption={(e) => {
            if (e) {
              updateFilter(
                'enquiry_type',
                `${(e as DropDownOptionObjectType).key}` as DropDownOptionType,
                filterData,
                (v) => {
                  updateFilter(
                    'enquiry_typeKey',
                    (e as DropDownOptionObjectType).value as DropDownOptionType,
                    v
                  );
                }
              );
            } else {
              updateFilter('enquiry_type', null, filterData, (v) => {
                updateFilter('enquiry_typeKey', null, v);
              });
            }
          }}
        />
        <DropdownSelect resetFormNoPreValue
          id='status'
          placeholder='Enquiry Status'
          preValue={(filterData.statusKey?.value as string) ?? ''}
          noSearch
          disabled={filterData.outcome && filterData.outcome?.value !== null}
          options={terms.filter((t) => t.type === 'enquiryStatus')}
          onSelectOption={(e) => {
            if (e) {
              updateFilter(
                'status',
                `${(e as DropDownOptionObjectType).key}` as DropDownOptionType,
                filterData,
                (v) => {
                  updateFilter(
                    'statusKey',
                    (e as DropDownOptionObjectType).value as DropDownOptionType,
                    v
                  );
                }
              );
            } else {
              updateFilter('status', null, filterData, (v) => {
                updateFilter('statusKey', null, v);
              });
            }
          }}
        />
        <DropdownSelect resetFormNoPreValue
          id='outcome'
          placeholder='Enquiry Outcome'
          preValue={(filterData.outcomeKey?.value as string) ?? ''}
          noSearch
          options={terms.filter((t) => t.type === 'enquiryOutcome')}
          onSelectOption={(e) => {
            if (e) {
              updateFilter(
                'outcome',
                `${(e as DropDownOptionObjectType).key}` as DropDownOptionType,
                filterData,
                (v) => {
                  updateFilter(
                    'outcomeKey',
                    (e as DropDownOptionObjectType).value as DropDownOptionType,
                    v,
                    (v2) => {
                      updateFilter('status', 'closed', v2, (v3) => {
                        updateFilter('statusKey', 'Closed', v3);
                      });
                    }
                  );
                }
              );
            } else {
              updateFilter('outcome', null, filterData, (v) => {
                updateFilter('outcomeKey', null, v, (v2) => {
                  updateFilter('status', null, v2, (v3) => {
                    updateFilter('statusKey', null, v3);
                  });
                });
              });
            }
          }}
        />
        <div className='mt-3'>
          <CheckboxGroup
            options={[
              { label: 'GVA', value: 'gva' },
              { label: 'LDN', value: 'ldn' },
              { label: 'PAR', value: 'par' },
            ]}
            value={(filterData.team?.value as CheckboxValueType[]) ?? []}
            onChange={(v) => {
              updateFilter('team', v as string[], filterData);
            }}
          />
        </div>
        <div style={{ marginTop: 4, height: 48 }} className='d-flex py-2'>
          <CustomCheckBox
            value='eoi'
            label='EOI'
            selected={(filterData.result_type?.value as string[]) ?? []}
            onSelectElement={updateResultType}
          />
          <CustomCheckBox
            value='nbi'
            label='NBI'
            selected={(filterData.result_type?.value as string[]) ?? []}
            onSelectElement={updateResultType}
          />
          <CustomCheckBox
            value='nty'
            label='NTY'
            selected={(filterData.result_type?.value as string[]) ?? []}
            onSelectElement={updateResultType}
          />
          <CustomCheckBox
            value='nr'
            label='NR'
            selected={(filterData.result_type?.value as string[]) ?? []}
            onSelectElement={updateResultType}
          />
          <CustomCheckBox
            value='pending'
            label='Pending'
            selected={(filterData.result_type?.value as string[]) ?? []}
            onSelectElement={updateResultType}
          />
        </div>
        <div>
          <Button
            variant='default'
            className={`my-2 ms-2 text-white ${classes.btnSearch}`}
            onClick={onSearch}
          >
            <FaSearch />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default IndicationFilter;
