import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { Method } from "../../../../@types/common-types";

import { baseApi } from "../../baseApi";
import { url } from "../../url";
import { ConterPartyResponse } from "../../../../@types/conterParty";

const conterParty = baseApi.injectEndpoints({
  endpoints: (build: EndpointBuilder<any, any, any>) => {
    return {
      listConterParty: build.query<
        ConterPartyResponse,
        { legalEntityId: string | number }
      >({
        query: ({ legalEntityId }) => {
          return {
            url: url.counterparty.list,
            params: {
              legalEntityId,
              excludes: "obligor",
              _dc: new Date().getTime(),
            },
          };
        },
        providesTags: ["LIST_CONTERPARTY"],
      }),
      getPeopleConterParty: build.query<
        ConterPartyResponse,
        { id: string | number }
      >({
        query: ({ id }) => {
          return {
            url: `${url.people.list}/${id}/counterparty`,
            params: {
              _dc: new Date().getTime(),
            },
          };
        },
        providesTags: ["LIST_CONTERPARTY_PER_PEOPLE"],
      }),
      setPeopleConterParty: build.mutation<
        ConterPartyResponse,
        { id: string | number; ids: (string | number)[] }
      >({
        query: ({ id, ids }) => {
          return {
            url: `${url.people.list}/${id}/counterparty`,
            data: {
              counterparty: ids,
            },
            method: Method.PUT,
          };
        },
      }),
    };
  },
});

export const {
  useLazyGetPeopleConterPartyQuery,
  useLazyListConterPartyQuery,
  useSetPeopleConterPartyMutation,
} = conterParty;
