import React from 'react';
import { Form } from 'react-bootstrap';
import { UseFormRegister, UseFormWatch } from 'react-hook-form';
import { TermConst } from '../../../../@types/common-types';
import { InputFormMandate } from '../../../../@types/mandates/mandates';
import UseConstante from '../../../../hooks/use-constante';
import { exportedDropdownValue } from '../../../../utils/helper-function';
import { DropDownOptionObjectType } from '../../../common/DropDown/DropdownSelect';
import SelectDropDown from '../../../common/DropDown/SelectDropDown';

type IProps = {
  register: UseFormRegister<InputFormMandate>;
  watch: UseFormWatch<InputFormMandate>;
  setValue: (e: string) => void;
  errors: string | undefined;
  onBlur: any;
};

export default function MandateTypeForm(props: IProps) {
  const terms = UseConstante();
  const mandate_type = props.watch('mandate_type');

  return (
    <div>
      <Form.Group className='d-flex align-items-baseline'>
        <Form.Label className='txt-with-dropdown'>
          Mandate Type <span className='span-required'>*</span>
        </Form.Label>

        <div className='d-flex flex-column w-100'>
          <SelectDropDown
            noPadding
            modalId='mandate'
            {...props.register('mandate_type', {
              required: true,
            })}
            noSearch
            preValue={exportedDropdownValue(
              terms?.getConst(TermConst.mandateType),
              mandate_type as string
            )}
            options={terms?.getConst(TermConst.mandateType)}
            onSelectOption={(e) =>
              props.setValue((e as DropDownOptionObjectType).key)
            }
            onBlur={props.onBlur}
            error={props.errors}
          />
        </div>
      </Form.Group>
    </div>
  );
}
