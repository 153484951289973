import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import UseFormCreateLimits from "./UseFormCreateLimits";
import SelectDropDown from "../../../common/DropDown/SelectDropDown";
import { DropDownOptionObjectType } from "../../../common/DropDown/DropdownSelect";
import CofarcoFormNumber from "../../../Form/CofarcoFormNumber";
import { EntityLimits } from "../../../../@types/limit/types";
import FormModal from "../../../Modal/FormModal/FormModal";
import HeaderTab from "../../../HeaderTab/HeaderTab";
import classes from "../Forms/FormModal.module.scss";
import {
  amountFormat,
  concatTitle,
  exportDateUi,
  exportedCountryName,
  exportedDropdownValue,
  isCan,
  isExist,
  makeOptions,
} from "../../../../utils/helper-function";
import { Menu, TermConst } from "../../../../@types/common-types";
import UseConstante from "../../../../hooks/use-constante";
import { ReactComponent as UnderwriterActif } from "../../../../assets/img/UnderwriterActif.svg";
import { ReactComponent as UnderwriterInactif } from "../../../../assets/img/UnderwriterInactif.svg";
import { ReactComponent as FinancierActif } from "../../../../assets/img/FinancierActif.svg";
import { ReactComponent as FinancierInactif } from "../../../../assets/img/FinancierInactif.svg";
import { ReactComponent as LegalentitiesActif } from "../../../../assets/img/LegalentitiesActif.svg";
import { ReactComponent as LegalentitiesInactif } from "../../../../assets/img/LegalentitiesInactif.svg";
import { useGetCountryQuery } from "../../../../redux/api/ws/databases/groups";
import Flag from "react-world-flags";
import CofarcoCalendar from "../../../Calendar/CofarcoCalendar";
import { Skeleton } from "antd";
import ModalUnsavedChange from "../../../common/Modal/ModalUnsavedChange";

type IProps = {
  show: boolean;
  handleClose: () => void;
  defaultFormValues: EntityLimits | null;
};
export default function FormCreateLimit(props: IProps) {
  const provider = UseFormCreateLimits({
    defaultFormValues: props.defaultFormValues,
    closingModal: props.handleClose,
  });
  const terms = UseConstante();
  const { data: responseCountry } = useGetCountryQuery();

  const showErrorCurrency = () => {
    provider.setFormError("currency", {
      message: "Currency is required",
    });
  };

  const title =
    props.defaultFormValues?.event === "edit"
      ? `Participation Obligor Limit ${concatTitle(
          props.defaultFormValues?.number
        )} ${concatTitle(provider.selectedDeal?.number)} ${concatTitle(
          provider.selectedMandate?.number
        )}`
      : `New - Participation Obligor Limit ${concatTitle(
          provider.selectedDeal?.number
        )} ${concatTitle(provider.selectedMandate?.number)}`;

  // const hiddenFooter =
  //   !isCan('write', Menu.deal) && props.defaultFormValues?.event === 'edit';

  const hiddenFooter =
    !isCan("write", Menu.participation) &&
    props.defaultFormValues?.event === "edit";

  return (
    <FormModal
      show={props.show}
      handleClose={() => {
        provider.resetForm();
        props.handleClose();
      }}
      ishiddenfooter={hiddenFooter}
      isDisabledSave={!provider.isDirty || !provider.isCompletedForm}
      isLoading={false}
      onSubmitForm={provider.handleSubmit(provider.submit)}
      header={
        <HeaderTab
          title={title}
          activeMenu={1}
          setActiveMenu={() => null}
          titleRigth={``}
          isEdit={false}
        />
      }
      children={
        <>
          <Row className="pt-4">
            <Col className="pe-4">
              <div className="d-flex align-items-baseline">
                <Form.Label className="txt-with-dropdown2">
                  Market <span className="span-required">*</span>
                </Form.Label>
                <div className="d-flex flex-column w-100">
                  <SelectDropDown
                    error={provider.errors.counterparty_id?.message}
                    icon={
                      <>
                        {isExist(provider.counterparty_id) &&
                          (provider.selectedMandate?.mandate_type ===
                          "insurance" ? (
                            <span style={{ cursor: "pointer" }}>
                              <UnderwriterActif
                                onClick={() =>
                                  provider.handleShowOtherModal("counterparty")
                                }
                              />
                            </span>
                          ) : (
                            <span style={{ cursor: "pointer" }}>
                              <FinancierActif
                                onClick={() =>
                                  provider.handleShowOtherModal("counterparty")
                                }
                              />
                            </span>
                          ))}

                        {!isExist(provider.counterparty_id) &&
                          (provider.selectedMandate?.mandate_type ===
                          "insurance" ? (
                            <UnderwriterInactif />
                          ) : (
                            <FinancierInactif />
                          ))}
                      </>
                    }
                    dropDownClass="px-0"
                    id="counterparty_id"
                    preValue={exportedDropdownValue(
                      makeOptions(
                        provider.counterpartyList || [],
                        "name",
                        "id"
                      ),
                      provider.counterparty_id
                    )}
                    options={makeOptions(
                      provider.counterpartyList || [],
                      "name",
                      "id"
                    )}
                    onSelectOption={(e) => {
                      provider.setValue(
                        "counterparty_id",
                        (e as DropDownOptionObjectType).key?.toString(),
                        {
                          shouldDirty: true,
                        }
                      );
                      if (e) {
                        provider.clearFormErrors("counterparty_id");
                        return;
                      }
                      provider.setFormError("counterparty_id", {
                        message: "MARKET is required",
                      });
                    }}
                    onBlur={() => {
                      if (!isExist(provider.counterparty_id)) {
                        provider.setFormError("counterparty_id", {
                          message: "MARKET is required",
                        });
                      }
                    }}
                    onSearch={(d: any, v: any) =>
                      provider.runSearch("counterparty_id", v)
                    }
                  />
                </div>
              </div>
              <div className="d-flex align-items-baseline">
                <Form.Label className="txt-with-dropdown2">
                  Obligor <span className="span-required">*</span>
                </Form.Label>
                <div className="d-flex flex-column w-100">
                  <SelectDropDown
                    error={provider.errors.obligor_legal_entity_id?.message}
                    icon={
                      <span style={{ cursor: "pointer" }}>
                        {provider.obligor_legal_entity_id ? (
                          <LegalentitiesActif
                            onClick={() =>
                              provider.handleShowOtherModal("legal_entity")
                            }
                          />
                        ) : (
                          <LegalentitiesInactif />
                        )}
                      </span>
                    }
                    dropDownClass="px-0"
                    id="obligor_legal_entity_id"
                    preValue={exportedDropdownValue(
                      makeOptions(provider.legalEntityList || [], "name", "id"),
                      provider.obligor_legal_entity_id || 0
                    )}
                    onSearch={(d: any, v: any) =>
                      provider.runSearch("obligor_legal_entity_id", v)
                    }
                    options={makeOptions(
                      provider.legalEntityList || [],
                      "name",
                      "id"
                    )}
                    onSelectOption={(e: any) => {
                      provider.setValue(
                        "obligor_legal_entity_id",
                        (e as DropDownOptionObjectType).key?.toString(),
                        {
                          shouldDirty: true,
                        }
                      );
                      if (e?.country_id) {
                        provider.setValue("country_id", e.country_id);
                      } else {
                        provider.setValue("country_id", "-");
                      }

                      if (e?.group_id) {
                        provider.getGroup({
                          id: e?.group_id,
                        });
                      } else {
                        provider.removeGroup();
                      }

                      if (e) {
                        provider.clearFormErrors("obligor_legal_entity_id");
                        return;
                      }
                      provider.setFormError("obligor_legal_entity_id", {
                        message: "Obligor is required",
                      });
                    }}
                    onBlur={() => {
                      if (!isExist(provider.obligor_legal_entity_id)) {
                        provider.setFormError("obligor_legal_entity_id", {
                          message: "Obligor ID is required",
                        });
                      }
                    }}
                  />
                </div>
              </div>
              <div className="my-1">
                <Form.Group className="d-flex align-items-baseline w-100 pt-2">
                  <Form.Label style={{ width: 240 }}>Country</Form.Label>

                  <div className="d-flex flex-column w-100">
                    {isExist(provider.country_id) ? (
                      <div className="d-flex flex-row">
                        <Flag
                          code={provider.country_id}
                          height="20"
                          width={"20"}
                          style={{
                            borderRadius: "50%",
                            objectFit: "cover",
                            marginRight: 15,
                          }}
                        />
                        {exportedCountryName(
                          responseCountry?.country || [],
                          provider.country_id
                        )}
                      </div>
                    ) : (
                      "-"
                    )}
                  </div>
                </Form.Group>
              </div>
              <div className="my-1">
                <Form.Group className="d-flex align-items-baseline w-100 pt-3">
                  <Form.Label style={{ width: 240 }}>Group</Form.Label>
                  <div className="d-flex flex-column w-100">
                    {provider?.selectedGroup?.group.name}
                  </div>
                </Form.Group>
              </div>
              <div className="d-flex w-100 align-items-baseline pt-2">
                <Form.Label style={{ width: 240 }}>Amount</Form.Label>
                <div className="d-flex flex-row  justify-content-center  w-100">
                  <div className="w-100">
                    <CofarcoFormNumber
                      register={provider.register}
                      setValue={provider.setValue}
                      watch={provider.watch}
                      key_form="amount"
                      acceptFloat={false}
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-baseline">
                <Form.Label className="txt-with-dropdown2">Currency</Form.Label>
                <div className={`d-flex w-100 flex-column `}>
                  <SelectDropDown
                    error={provider.errors?.currency?.message}
                    noSearch
                    modalId="mandate"
                    preValue={
                      provider.isLoading
                        ? ""
                        : exportedDropdownValue(
                            terms?.getConst(TermConst.currency),
                            provider.currency
                          )
                    }
                    disabled={(provider.amount as number) < 1}
                    options={terms?.getConst(TermConst.currency)}
                    onSelectOption={(e) => {
                      provider.setValue(
                        "currency",
                        (e as DropDownOptionObjectType).key,
                        { shouldDirty: true }
                      );
                      const isCleared =
                        isExist(provider.amount) &&
                        !isExist((e as DropDownOptionObjectType).key);
                      if (isCleared) {
                        showErrorCurrency();
                        return;
                      }

                      provider.clearFormErrors("currency");
                    }}
                  />
                </div>
              </div>

              <div className="d-flex align-items-baseline">
                <Form.Label className="txt-with-dropdown2">Status</Form.Label>
                <div className={`d-flex w-100 flex-column `}>
                  <SelectDropDown
                    modalId="mandate"
                    preValue={exportedDropdownValue(
                      terms?.getConst(TermConst.limitStatus),
                      provider.status
                    )}
                    noSearch
                    options={terms?.getConst(TermConst.limitStatus)}
                    onSelectOption={(e) => {
                      provider.setValue(
                        "status",
                        (e as DropDownOptionObjectType).key,
                        {
                          shouldDirty: true,
                        }
                      );
                    }}
                  />
                </div>
              </div>
              <div className="my-2">
                <Form.Group className="d-flex align-items-baseline w-100">
                  <Form.Label style={{ width: 240 }}>Limit Date</Form.Label>
                  <CofarcoCalendar
                    name="limit_date"
                    control={provider.control}
                    setFormValue={provider.setValue}
                    watchFormValue={provider.watch}
                  />
                </Form.Group>
              </div>
              <div className="my-3">
                <Form.Group className="d-flex align-items-baseline w-100">
                  <Form.Label style={{ width: 240 }}>Expiry Date</Form.Label>
                  <CofarcoCalendar
                    name="expiry_date"
                    control={provider.control}
                    setFormValue={provider.setValue}
                    watchFormValue={provider.watch}
                  />
                </Form.Group>
              </div>
              <Form.Group className="my-3 d-flex align-items-baseline w-100">
                <Form.Label style={{ width: 240 }}>Pricing</Form.Label>
                <Form.Control
                  className="w-100"
                  {...provider.register("pricing")}
                />
              </Form.Group>
              <div className={`my-1`}>
                <Form.Group className="d-flex align-items-start w-100">
                  <Form.Label style={{ width: 240 }}>
                    Additional Information
                  </Form.Label>

                  <div className="d-flex flex-column w-100 mb-3">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      className=" w-100"
                      {...provider.register("comments")}
                    />
                  </div>
                </Form.Group>
              </div>
            </Col>

            <Col
              className={`${classes.enquiryInfo} py-4 mt-2`}
              style={{
                border: "1px solid #a7a7a770",
                borderRadius: 8,
                backgroundColor: "#fff",
                display: "flex",
                flexDirection: "column",
                overflowY: "scroll",
                margin: "0 auto auto auto",
              }}
            >
              {provider.isLoading && (
                <>
                  <Skeleton active paragraph />
                </>
              )}
              {!provider.isLoading && (
                <>
                  <div className="d-flex flex-row py-2 px-4">
                    <div style={{ width: "40%" }}>Deal ID</div>
                    <div style={{ width: "40%" }}>
                      {provider.selectedDeal?.number}
                    </div>
                  </div>
                  <div className="d-flex flex-row py-2 px-4">
                    <div style={{ width: "40%" }}>Client Name</div>
                    <div style={{ width: "40%" }}>
                      {provider.selectedMandate?.client?.name}
                    </div>
                  </div>
                  <div className="d-flex flex-row py-2 px-4">
                    <div style={{ width: "40%" }}>Mandate Name</div>
                    <div style={{ width: "40%" }}>
                      {" "}
                      {provider.selectedMandate?.name}
                    </div>
                  </div>
                  <div className="d-flex flex-row py-2 px-4">
                    <div style={{ width: "40%" }}>Deal Type</div>
                    <div style={{ width: "40%" }}>
                      {" "}
                      {exportedDropdownValue(
                        terms?.getConst(TermConst.dealType),
                        provider.selectedDeal?.deal_type || null
                      )}
                    </div>
                  </div>
                  <div className="d-flex flex-row py-2 px-4">
                    <div style={{ width: "40%" }}>Deal Status</div>
                    <div style={{ width: "40%" }}>
                      {" "}
                      {exportedDropdownValue(
                        terms?.getConst(TermConst.dealStatus),
                        provider.selectedDeal?.status || null
                      )}
                    </div>
                  </div>
                  <div className="d-flex flex-row py-2 px-4">
                    <div style={{ width: "40%" }}>Deal Amount</div>
                    <div style={{ width: "40%" }}>
                      {provider.selectedDeal?.amount
                        ? amountFormat(provider.selectedDeal?.amount)
                        : "-"}
                    </div>
                  </div>
                  <div className="d-flex flex-row py-2 px-4">
                    <div style={{ width: "40%" }}>Deal Currency</div>
                    <div style={{ width: "40%" }}>
                      {" "}
                      {exportedDropdownValue(
                        terms?.getConst(TermConst.currency),
                        provider.selectedDeal?.currency || null
                      )}
                    </div>
                  </div>
                  <div className="d-flex flex-row py-2 px-4">
                    <div style={{ width: "40%" }}>
                      Deal Obligor Limit Status
                    </div>
                    <div style={{ width: "40%" }}>
                      {exportedDropdownValue(
                        terms?.getConst(TermConst.limitStatus),
                        provider.selectedLimitDeal?.status || ""
                      ) || "-"}
                    </div>
                  </div>
                  <div className="d-flex flex-row py-2 px-4">
                    <div style={{ width: "40%" }}>Deal Obligor Limit Date</div>
                    <div style={{ width: "40%" }}>
                      {exportDateUi(
                        provider.selectedLimitDeal?.limit_date
                      )?.toLocaleString()}
                    </div>
                  </div>
                  <div className="d-flex flex-row py-2 px-4">
                    <div style={{ width: "40%" }}>
                      Deal Obligor Limit Expiry Date
                    </div>
                    <div style={{ width: "40%" }}>
                      {exportDateUi(
                        provider.selectedLimitDeal?.expiry_date
                      )?.toLocaleString()}
                    </div>
                  </div>
                  <div className="d-flex flex-row py-2 px-4">
                    <div style={{ width: "40%" }}>Additional Information</div>
                    <div style={{ width: "40%" }}>
                      {provider.selectedLimitDeal?.comments}
                    </div>
                  </div>
                </>
              )}
            </Col>
          </Row>

          <ModalUnsavedChange
            dirtiFields={provider.dirtyFields}
            handleCancel={() => provider.setIsShowModalUnsavedChange(false)}
            handleLeave={() =>
              provider.handleLeaveModalWithoutSave(provider.showOtherModal)
            }
            showModalUnsaved={provider.isShowModalUnsavedChange}
          />
        </>
      }
    />
  );
}
