import React, { useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import SelectDropDown from "../../../common/DropDown/SelectDropDown";
import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { InputFormParticipation } from "../../../../@types/participation/participation";
import {
  amountFormat,
  concatString,
  exportedDropdownValue,
  isExist,
  makeOptions,
} from "../../../../utils/helper-function";
import { FunctionName, TermConst } from "../../../../@types/common-types";
import UseConstante from "../../../../hooks/use-constante";
import { Radio } from "antd";

import { DropDownOptionObjectType } from "../../../common/DropDown/DropdownSelect";
import { ConterParty } from "../../../../@types/conterParty";
import { LegalEntity } from "../../../../@types/legal-entities";
import { EntityMandate } from "../../../../@types/mandates/mandates";
import { EntityDeals } from "../../../../@types/deals/deals";
import {
  useGetConterPartyIdMutation,
  useGetPeopleByIdMutation,
} from "../../../../redux/api/ws/participations/participations";
import { ReactComponent as UnderwriterActif } from "../../../../assets/img/UnderwriterActif.svg";
import { ReactComponent as UnderwriterInactif } from "../../../../assets/img/UnderwriterInactif.svg";
import { ReactComponent as LegalentitiesActif } from "../../../../assets/img/LegalentitiesActif.svg";
import { ReactComponent as LegalentitiesInactif } from "../../../../assets/img/LegalentitiesInactif.svg";

import { ReactComponent as FinancierActif } from "../../../../assets/img/FinancierActif.svg";
import { ReactComponent as FinancierInactif } from "../../../../assets/img/FinancierInactif.svg";

import CofarcoNumberAmount from "../../../Form/CofarcoNumberAmount";
import RowContent from "../../../Form/RowContent";
import ModalUnsavedChange from "../../../common/Modal/ModalUnsavedChange";

type IProps = {
  watch: UseFormWatch<InputFormParticipation>;
  setValue: UseFormSetValue<InputFormParticipation>;
  runSearch: (
    key: keyof InputFormParticipation,
    value: string
  ) => Promise<void>;
  register: UseFormRegister<InputFormParticipation>;
  errors: FieldErrors<InputFormParticipation>;
  control: Control<InputFormParticipation, any>;
  clearFormErrors: UseFormClearErrors<InputFormParticipation>;
  setFormError: UseFormSetError<InputFormParticipation>;
  counterparty: Array<ConterParty>;
  legalEntityList: Array<LegalEntity>;
  selectedMandate: EntityMandate | null | undefined;
  selectedDeal: EntityDeals | null | undefined;
  isGetDealLoading: boolean;
  isCounterpartyLoading: boolean;
  isGetEntityLoading: boolean;
  handleShowOtherModal: (id: any, func: FunctionName) => void;
  provider: any;
  showOtherModal: any;
};
export default function Forms({
  provider,
  handleShowOtherModal,
  showOtherModal,
  ...props
}: IProps) {
  const counterpartyId = props.watch("counterpartyId");
  const underwriterLegalEntityId = props.watch("underwriterLegalEntityId");
  const amount = props.watch("amount");
  const currency = props.watch("currency");
  const status = props.watch("status");
  const leadLine = props.watch("leadLine");
  const terms = UseConstante();
  const stampDropDown = useRef<any>();

  const showErrorCurrency = () => {
    props.setFormError("currency", {
      message: "Currency is required",
    });
  };

  const [partner, setPartner] = useState<ConterParty | null>();

  const [getC] = useGetConterPartyIdMutation();
  const [getPeople, { data: peopleRes }] = useGetPeopleByIdMutation();

  const handlCounterPartie = async (id: string | number) => {
    try {
      const response = await getC({
        id,
      }).unwrap();
      const tempOption = response?.counterparty;
      setPartner(tempOption);
    } catch (error) {
      return [];
    }
  };

  React.useEffect(() => {
    if (props.selectedDeal && props.selectedDeal?.partner_id) {
      handlCounterPartie(props.selectedDeal?.partner_id);
    }
    if (props.selectedDeal && props.selectedDeal?.partner_contact_id) {
      getPeople({ id: props.selectedDeal?.partner_contact_id });
    }
  }, [props.selectedDeal]);

  const commercialName = props.counterparty?.find(
    (el) => el.id === counterpartyId
  )?.commercial_name;

  const getDataSourceStamp = (): any[] => {
    if (counterpartyId && counterpartyId > 0) {
      const counterparties = props.counterparty || [];
      console.log({ counterparties });
      if (counterparties.length > 0) {
        const counterpartyFiltered = counterparties.filter(
          (el) => el.id === counterpartyId
        );
        const counterparty =
          counterpartyFiltered.length > 0 ? counterpartyFiltered[0] : null;
        if (counterparty) {
          const legal_entities = counterparty.legal_entities || [];
          return legal_entities.filter((el) =>
            el.pivot?.roles?.includes("stamp")
          );
        }
      }
    }
    return [];
  };

  return (
    <Row className="pt-4">
      <Col className="pe-4">
        <RowContent>
          <div className="d-flex align-items-baseline">
            <Form.Label className="txt-with-dropdown2">
              Market <span className="span-required">*</span>
            </Form.Label>
            <div className="d-flex flex-column w-100">
              <SelectDropDown
                noPadding
                icon={
                  <>
                    {isExist(counterpartyId) &&
                      (props.selectedMandate?.mandate_type === "insurance" ? (
                        <span style={{ cursor: "pointer" }}>
                          <UnderwriterActif
                            onClick={() =>
                              handleShowOtherModal(null, "counterparty")
                            }
                          />
                        </span>
                      ) : (
                        <span style={{ cursor: "pointer" }}>
                          <FinancierActif
                            onClick={() =>
                              handleShowOtherModal(null, "counterparty")
                            }
                          />
                        </span>
                      ))}

                    {!isExist(counterpartyId) &&
                      (props.selectedMandate?.mandate_type === "insurance" ? (
                        <UnderwriterInactif />
                      ) : (
                        <FinancierInactif />
                      ))}
                  </>
                }
                dropDownClass="px-0"
                id="underwriter_id"
                preValue={exportedDropdownValue(
                  makeOptions(props.counterparty || [], "name", "id"),
                  counterpartyId
                )}
                {...props.register("counterpartyId", {
                  required: true,
                })}
                onSearch={(d: any, v: any) =>
                  props.runSearch("counterpartyId", v)
                }
                options={makeOptions(props.counterparty || [], "name", "id")}
                onSelectOption={(e) => {
                  if (stampDropDown && stampDropDown.current) {
                    stampDropDown.current.click();
                  }
                  props.setValue(
                    "counterpartyId",
                    (e as DropDownOptionObjectType).key,
                    { shouldDirty: true }
                  );
                  if (e) {
                    props.clearFormErrors("counterpartyId");
                  } else {
                    props.setFormError("counterpartyId", {
                      message: "MARKET is required",
                    });
                  }
                }}
                onBlur={() => {
                  if (!isExist(counterpartyId)) {
                    props.setFormError("counterpartyId", {
                      message: "MARKET is required",
                    });
                  }
                }}
                error={props.errors.counterpartyId?.message}
                loading={props.isCounterpartyLoading}
              />
            </div>
          </div>
        </RowContent>

        {props.selectedMandate?.mandate_type !== "insurance" &&
          isExist(props.selectedMandate?.mandate_type) && (
            <RowContent>
              <div>
                <Form.Group className="d-flex align-items-baseline w-100 pt-2">
                  <Form.Label style={{ width: 240 }}>
                    Commercial Name
                  </Form.Label>
                  <div className="d-flex flex-column w-100">
                    {commercialName || "-"}
                  </div>
                </Form.Group>
              </div>
            </RowContent>
          )}

        <RowContent>
          <div className="d-flex align-items-baseline">
            <Form.Label className="txt-with-dropdown2">Stamp </Form.Label>
            <div className="d-flex flex-column w-100">
              <SelectDropDown
                noPadding
                icon={
                  <span style={{ cursor: "pointer" }}>
                    {underwriterLegalEntityId ? (
                      <LegalentitiesActif
                        onClick={() =>
                          handleShowOtherModal(
                            underwriterLegalEntityId,
                            "legal_entity"
                          )
                        }
                      />
                    ) : (
                      <LegalentitiesInactif />
                    )}
                  </span>
                }
                disabled={!counterpartyId || counterpartyId === 0}
                dropDownClass="px-0"
                id="underwriter_id"
                preValue={exportedDropdownValue(
                  makeOptions(getDataSourceStamp(), "name", "id"),
                  underwriterLegalEntityId || 0
                )}
                onSearch={(d: any, v: any) =>
                  props.runSearch("underwriterLegalEntityId", v)
                }
                options={makeOptions(getDataSourceStamp(), "name", "id")}
                onSelectOption={(e) =>
                  props.setValue(
                    "underwriterLegalEntityId",
                    (e as DropDownOptionObjectType).key,
                    { shouldDirty: true }
                  )
                }
                loading={props.isGetEntityLoading}
              />
            </div>
          </div>
        </RowContent>

        <RowContent>
          <div className="d-flex w-100 align-items-baseline">
            <Form.Label style={{ width: 240 }}>Participation Amount</Form.Label>
            <div className="d-flex flex-row  justify-content-center  w-100">
              <div className="w-100">
                <CofarcoNumberAmount
                  register={props.register}
                  setValue={props.setValue}
                  watch={props.watch}
                  key_form="amount"
                  unitFormat={2}
                />
              </div>
            </div>
          </div>
        </RowContent>
        <RowContent>
          <div className="d-flex align-items-baseline">
            <Form.Label className="txt-with-dropdown2">
              Participation Currency{" "}
              {(amount as number) > 1 && (
                <span className="span-required">*</span>
              )}
            </Form.Label>
            <div className={`d-flex w-100 flex-column `}>
              <SelectDropDown
                noPadding
                noSearch
                error={props.errors?.currency?.message}
                modalId="mandate"
                preValue={
                  props.isGetDealLoading
                    ? ""
                    : exportedDropdownValue(
                        terms?.getConst(TermConst.currency),
                        currency
                      )
                }
                disabled={(amount as number) < 1}
                options={terms?.getConst(TermConst.currency)}
                onSelectOption={(e) => {
                  props.setValue(
                    "currency",
                    (e as DropDownOptionObjectType).key,
                    { shouldDirty: true }
                  );
                  const isCleared =
                    isExist(amount) &&
                    !isExist((e as DropDownOptionObjectType).key);
                  if (isCleared) {
                    showErrorCurrency();
                    return;
                  }
                  props.clearFormErrors("currency");
                }}
                onBlur={() => {
                  const isCleared = isExist(amount) && !isExist(currency);
                  if (isCleared) {
                    showErrorCurrency();
                    return;
                  }
                }}
              />
            </div>
          </div>
        </RowContent>

        <RowContent>
          <div className="d-flex align-items-baseline pt-2">
            <Form.Label className="txt-with-dropdown2">Lead Line</Form.Label>
            <div className="d-flex flex-column w-100">
              <div className="d-flex w-100">
                <Radio.Group
                  onChange={(t) => {
                    props.setValue("leadLine", t.target.value, {
                      shouldDirty: true,
                    });
                  }}
                  value={leadLine}
                >
                  <Radio value={true} className="mr-5">
                    Yes
                  </Radio>
                  <Radio value={false} className="mx-5">
                    No
                  </Radio>
                </Radio.Group>
              </div>
            </div>
          </div>
        </RowContent>

        <RowContent>
          <div className="d-flex align-items-baseline">
            <Form.Label className="txt-with-dropdown2">
              Participation Status
            </Form.Label>
            <div className={`d-flex w-100 flex-column `}>
              <SelectDropDown
                noPadding
                modalId="mandate"
                preValue={exportedDropdownValue(
                  terms?.getConst(TermConst.participationStatus),
                  status
                )}
                {...props.register("status")}
                noSearch
                options={terms?.getConst(TermConst.participationStatus)}
                onSelectOption={(e) => {
                  props.setValue(
                    "status",
                    (e as DropDownOptionObjectType).key,
                    {
                      shouldDirty: true,
                    }
                  );
                }}
              />
            </div>
          </div>
        </RowContent>

        <RowContent>
          <Form.Group className={`d-flex align-items-start`}>
            <Form.Label style={{ width: 240 }}>
              {"Participation Reference \nNumber"}
            </Form.Label>
            <div
              className="d-flex"
              style={{
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Form.Control
                type={"text"}
                {...props.register("participantReference")}
              />
            </div>
          </Form.Group>
        </RowContent>

        <RowContent>
          <Form.Group className={`d-flex align-items-baseline`}>
            <Form.Label className="my-auto" style={{ width: 240 }}>
              Risk Premium
            </Form.Label>
            <div
              className="d-flex"
              style={{
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Form.Control type={"text"} {...props.register("premiumRate")} />
            </div>
          </Form.Group>
        </RowContent>

        <RowContent>
          <Form.Group className={`d-flex align-items-baseline`}>
            <Form.Label className="my-auto" style={{ width: 240 }}>
              Minimum Deposit Premium
            </Form.Label>
            <div
              className="d-flex"
              style={{
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Form.Control
                type={"text"}
                {...props.register("minimumDeposit")}
              />
            </div>
          </Form.Group>
        </RowContent>
      </Col>
      <Col
        className={`py-4`}
        style={{
          border: "1px solid #a7a7a770",
          borderRadius: 8,
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
          overflowY: "scroll",
          margin: "0 auto auto auto",
        }}
      >
        <div className="d-flex flex-row py-2 px-4">
          <div style={{ width: "40%" }}>Deal ID</div>
          <div style={{ width: "40%" }}>{props.selectedDeal?.number}</div>
        </div>
        <div className="d-flex flex-row py-2 px-4">
          <div style={{ width: "40%" }}>Client Name</div>
          <div style={{ width: "40%" }}>
            {props.selectedMandate?.client?.name}
          </div>
        </div>
        <div className="d-flex flex-row py-2 px-4">
          <div style={{ width: "40%" }}>Mandate Name</div>
          <div style={{ width: "40%" }}> {props.selectedMandate?.name}</div>
        </div>
        <div className="d-flex flex-row py-2 px-4">
          <div style={{ width: "40%" }}>Deal Type</div>
          <div style={{ width: "40%" }}>
            {" "}
            {exportedDropdownValue(
              terms?.getConst(TermConst.dealType),
              props.selectedDeal?.deal_type || null
            )}
          </div>
        </div>
        <div className="d-flex flex-row py-2 px-4">
          <div style={{ width: "40%" }}>Deal Status</div>
          <div style={{ width: "40%" }}>
            {" "}
            {exportedDropdownValue(
              terms?.getConst(TermConst.dealStatus),
              props.selectedDeal?.status || null
            )}
          </div>
        </div>
        <div className="d-flex flex-row py-2 px-4">
          <div style={{ width: "40%" }}>Deal Amount</div>
          <div style={{ width: "40%" }}>
            {props.selectedDeal?.amount
              ? amountFormat(props.selectedDeal?.amount)
              : "-"}
          </div>
        </div>
        <div className="d-flex flex-row py-2 px-4">
          <div style={{ width: "40%" }}>Deal Currency</div>
          <div style={{ width: "40%" }}>
            {" "}
            {exportedDropdownValue(
              terms?.getConst(TermConst.currency),
              props.selectedDeal?.currency || null
            )}
          </div>
        </div>
        <div className="d-flex flex-row py-2 px-4">
          <div style={{ width: "40%" }}>Partner</div>
          <div style={{ width: "40%" }}>{partner?.name}</div>
        </div>
        <div className="d-flex flex-row py-2 px-4">
          <div style={{ width: "40%" }}>Partner Contact</div>
          <div style={{ width: "40%" }}>
            {concatString(
              peopleRes?.people?.firstname || "",
              peopleRes?.people?.lastname || ""
            )}
          </div>
        </div>
        <div className="d-flex flex-row py-2 px-4">
          <div style={{ width: "40%" }}>Partner Reference Number</div>
          <div style={{ width: "40%" }}>
            {props.selectedDeal?.partner_reference || "-"}
          </div>
        </div>
      </Col>

      <ModalUnsavedChange
        dirtiFields={provider.dirtyFields}
        handleCancel={() => provider.setIsShowModalUnsavedChange(false)}
        handleLeave={() =>
          provider.handleLeaveModalWithoutSave(
            showOtherModal.id,
            showOtherModal.func
          )
        }
        showModalUnsaved={provider.isShowModalUnsavedChange}
      />
    </Row>
  );
}
