import { EntityEnquiries } from '../../../../@types/enquiries';
import IndicationEnquiryInfo from './IndicationInqueryInfo';
import { ReactComponent as Enquiry } from '../../../../assets/img/EnquiriesWhite.svg';
import { ReactComponent as Client } from '../../../../assets/img/client.svg';
import { ReactComponent as Obligor } from '../../../../assets/img/obligor.svg';
import FlagItem from '../../../FlagItem/FlagItem';
import { Term } from '../../../../@types/terms';
import { useMemo } from 'react';
import { useGetCountryQuery } from '../../../../redux/api/ws/databases/groups';
import { ConterParty } from '../../../../@types/conterParty';
import { addThousandSeparator } from '../../../../utils/helper-function';

type Props = {
  selectedEnquiry?: EntityEnquiries;
  terms: Term[];
  onClickEnquiry?: (en: EntityEnquiries) => void;
  onClickClient?: (client: ConterParty) => void;
  onCLickObligor?: (obligor: ConterParty) => void;
};
export const EnquiryInfosComponent = ({
  selectedEnquiry,
  terms,
  onClickEnquiry,
  onClickClient,
  onCLickObligor,
}: Props) => {
  const { data: responseCountry } = useGetCountryQuery();
  const selectedCountry = useMemo(
    () =>
      responseCountry?.country.find(
        (ct) =>
          ct.key ===
          (selectedEnquiry as any)?.mandate?.entity_obligor?.country_id,
      ),
    [responseCountry, selectedEnquiry],
  );

  return (
    <>
      <IndicationEnquiryInfo
        label={'Enquiry ID'}
        value={selectedEnquiry?.number}
        icon={
          <>
            {selectedEnquiry && (
              <div className="d-flex justify-content-end">
                <span
                  className="bg-danger p-1"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (onClickEnquiry) onClickEnquiry(selectedEnquiry);
                  }}
                >
                  <Enquiry />
                </span>
              </div>
            )}
          </>
        }
      />
      <IndicationEnquiryInfo
        label={'Client ID'}
        value={(selectedEnquiry as any)?.mandate?.client?.name ?? ''}
        icon={
          <>
            {(selectedEnquiry as any)?.mandate?.client && (
              <div className="d-flex justify-content-end">
                <span
                  className={` bg-danger p-1`}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    const client = (selectedEnquiry as any)?.mandate?.client;
                    if (onClickClient) {
                      onClickClient(client);
                    }
                  }}
                >
                  <Client color="white" />
                </span>
              </div>
            )}
          </>
        }
      />
      <IndicationEnquiryInfo
        label={'Mandate Name'}
        value={(selectedEnquiry as any)?.mandate?.name}
      />
      <IndicationEnquiryInfo
        label={'Enquiry Type'}
        value={
          terms.find(
            (t) =>
              t.key === selectedEnquiry?.enquiry_type && t.type === 'dealType',
          )?.value
        }
      />
      <IndicationEnquiryInfo
        label={'Enquiry Status'}
        value={
          terms.find(
            (t) =>
              t.key === selectedEnquiry?.status && t.type === 'enquiryStatus',
          )?.value
        }
      />
      <IndicationEnquiryInfo
        label={'Outcome'}
        value={terms.find((t) => t.key === selectedEnquiry?.outcome)?.value}
      />
      <IndicationEnquiryInfo
        label={'Top obligor'}
        value={(selectedEnquiry as any)?.mandate?.entity_obligor?.name ?? ''}
        icon={
          <>
            {(selectedEnquiry as any)?.mandate?.entity_obligor?.id && (
              <div className="d-flex justify-content-end">
                <span
                  className={`bg-danger p-1`}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    const entity_obligor = (selectedEnquiry as any).mandate
                      ?.entity_obligor;
                    if (onCLickObligor) {
                      onCLickObligor(entity_obligor);
                    }
                  }}
                >
                  <Obligor width={26} color="white" />
                </span>
              </div>
            )}
          </>
        }
      />
      <IndicationEnquiryInfo
        label={'Country'}
        value={
          <FlagItem
            code={selectedCountry?.key ?? ''}
            region_name={selectedCountry?.name ?? ''}
          />
        }
      />
      <IndicationEnquiryInfo
        label={'Limit Required'}
        value={
          <div className="d-flex justify-content-between">
            <div>
              {addThousandSeparator(selectedEnquiry?.individual_limits, "'")}
            </div>
            <div>
              Currency :{' '}
              {
                terms.find(
                  (t) => t.key === selectedEnquiry?.individual_currency,
                )?.value
              }
            </div>
          </div>
        }
      />
      <IndicationEnquiryInfo
        label={'Tenor'}
        value={`${selectedEnquiry?.protection} ${selectedEnquiry?.protection_unit}${(selectedEnquiry?.protection || 0) > 1 ? 's' : ''}`}
      />
      <IndicationEnquiryInfo
        label={'Insured Percentage'}
        value={`${parseFloat(
          selectedEnquiry?.insured_percent ?? '0.00',
        ).toFixed(2)} %`}
      />
      <IndicationEnquiryInfo
        label={'Deductible'}
        value={
          <div className="d-flex justify-content-between">
            <div>{addThousandSeparator(selectedEnquiry?.deductible, "'")}</div>
            <div>
              Currency :{' '}
              {
                terms.find((t) => t.key === selectedEnquiry?.exposure_currency)
                  ?.value
              }
            </div>
          </div>
        }
      />
      <IndicationEnquiryInfo
        label={'Proposed Pricing'}
        value={selectedEnquiry?.proposed_pricing}
      />
    </>
  );
};

export default EnquiryInfosComponent;
