import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LegalEntityFilterType } from '../../@types/legal-entities';

export interface LegalEntityFilterState {
  data: LegalEntityFilterType;
}

const initialState: LegalEntityFilterState = {
  data: {},
};

export const legalEntityFilterSlice = createSlice({
  name: 'filter_legal_entity',
  initialState,
  reducers: {
    setLegalEntityFilter: (state, action: PayloadAction<LegalEntityFilterType>) => {
      state.data = action.payload;
      return state;
    },
  },
});

export const { setLegalEntityFilter } = legalEntityFilterSlice.actions;
export default legalEntityFilterSlice.reducer;
