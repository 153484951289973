import React, { useEffect, useState, createContext } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import ContentNavBar from "../Admin/ContentNavBar";
import DB from "../../assets/img/Data-Base.svg";
import DatabaseItems from "./DatabaseItems";

export const ContextDataBase = createContext<any>({
  txt: null,
});

const DatabaseContent: any = () => {
  const [activeMenu, setActiveMenu] = useState<number>(3);

  const [searchTxt, setSearchTxt] = useState<string | undefined | null>(
    undefined
  );

  const navigate = useNavigate();

  const location = useLocation();

  const navigateTo = (route: string, index: number) => {
    setSearchTxt(null);
    setActiveMenu(index);
    navigate(route);
  };

  useEffect(() => {
    if (location.pathname.endsWith("group")) {
      setActiveMenu(1);
    } else if (location.pathname.endsWith("legal-entity")) {
      setActiveMenu(2);
    } else if (location.pathname.endsWith("people")) {
      setActiveMenu(3);
    }
  }, [location]);

  return (
    <>
      <ContentNavBar
        title="Data Base"
        logo={DB}
        items={
          <DatabaseItems activeMenu={activeMenu} onClickItem={navigateTo} />
        }
        setTxt={(txt: any) => setSearchTxt(txt)}
        txt={searchTxt}
      />
      <ContextDataBase.Provider value={{ txt: searchTxt }}>
        <Outlet />
      </ContextDataBase.Provider>
    </>
  );
};

export default DatabaseContent;
