import {useMemo, useState} from 'react';
import {Button, Col, Form, Row} from 'react-bootstrap';
import SelectDropDown from '../../../common/DropDown/SelectDropDown';
import HeaderMultiple from '../../../HeaderTab/HeaderMultiple';
import FormModal from '../../../Modal/FormModal/FormModal';
import UseModalDetailsNote from './UseModalDetailsNote';
import {ReactComponent as AddIban} from '../../../../assets/img/AddIban.svg';
import CofarcoFormNumber from '../../../Form/CofarcoFormNumber';
import UseConstante from '../../../../hooks/use-constante';
import {Menu, TermConst} from '../../../../@types/common-types';
import {DropDownOptionObjectType} from '../../../common/DropDown/DropdownSelect';

import {
    exportedDropdownValue,
    handleAmount,
    isCan,
    makeOptions,
} from '../../../../utils/helper-function';
import AddGroup from '../../../../assets/img/add.svg';
import {Table} from 'antd';
import ColumnPayementAttached from './ColumnPayementAttached';
import CofarcoNumberRateFormate from '../../../Form/CofarcoNumberRateFormate';
import CofarcoNumberPercentage from '../../../Form/CofarcoNumberPercentage';
import FormError from '../../../FormError/FormError';

import DeleteModal from '../../../Modal/DeleteModal';
import CofarcoCalendar from '../../../Calendar/CofarcoCalendar';
import RowContent from '../../../Form/RowContent';
import ColumnAttachedNotes from './ColumnAttachedNotes';
import {NavLink} from 'react-router-dom';
import ConfirmModal from '../../../Modal/ConfirmModal';
import {useSignedOffNoteMutation} from '../../../../redux/api/ws/segregatedaccounts/notes';
import {useAppSelector} from '../../../../hooks/redux';
import classes from '../../../../components/CofarcoTable/CofarcoTable.module.scss';
import moment from 'moment';
import ModalUnsavedChange from '../../../common/Modal/ModalUnsavedChange';
//import {postPdfToDropBox} from '../../../../redux/api/dropbBoxQuery';
import PdfViewer from './PdfViewer';

export default function ModalDetailNotes(props: TModaldetailsNotesProps) {
    const provider = UseModalDetailsNote({
        closingModal: props.handleClose,
        defaultFormValues: props.defaultFormValues,
    });
    const terms = UseConstante();
    const [modalConfirm, setShowmodalConfirm] = useState<boolean>(false);
    const authData = useAppSelector((state) => state.auth);
    const currency = provider.watch('currency');
    const status = props.defaultFormValues?.status;
    const [modalConfirmDelete, setModalConfirmDelete] = useState(false);
    const [modalConfirmDeleteAttachedNote, setModalConfirmDeleteAttachedNote] =
        useState(false);

    const invoiceRaising = authData?.user?.invoice_raising;

    const isInDraftMode = status === 'draft' ? true : false;

    const isShowSignedOff = invoiceRaising === 'alone' && status === 'draft';

    const handleDeleteModalPayement = () => {
        setModalConfirmDelete(!modalConfirmDelete);
    };

    const handleDeleteModalAttached = () => {
        setModalConfirmDeleteAttachedNote(!modalConfirmDeleteAttachedNote);
    };

    const [signOff] = useSignedOffNoteMutation();

    const handleDeleteItem = (item: any) => {
        if (item) {
            provider?.setSelectedItem(item);
            handleDeleteModalPayement();
        }
    };

    const handleDeleteNoteItem = (item: any) => {
        if (item) {
            provider?.setSelectedNoteAttachedItem(item);
            handleDeleteModalAttached();
        }
    };

    const handlaEditPayment = (id: number) => {
        provider.handleEditPayement(id);
    };

    const getTotals = () => {
        if (!props?.defaultFormValues)
            return {
                noteAmountNet: 0,
                payments: 0,
                notes: 0,
            };
        const noteAmountNet = handleAmount(props.defaultFormValues) as number;

        const payments = provider?.payementsDataSource?.reduce(
            (acc, curr) => acc + parseFloat(curr.payment_amount),
            0,
        );

        const notes = provider?.notesDataSource?.reduce(
            (acc, curr) => acc + parseFloat(curr.noteAmount),
            0,
        );

        return {
            noteAmountNet,
            payments,
            notes,
        };
    };

    //Get remain amount

    const columnsPayement = ColumnPayementAttached({
        payementsDataSource: provider.payementsDataSource,
        setPayementsDataSource: provider.setPayementsDataSource,
        currency: currency,
        setValue: provider.setValue,
        handleRemoveItem: handleDeleteItem,
        handleEditItem: handlaEditPayment,
        maxTotal: useMemo(() => {
            // Max total for payments
            const {noteAmountNet, notes} = getTotals();
            return noteAmountNet - notes;
        }, [
            props?.defaultFormValues?.amount,
            provider.notesDataSource,
            provider.payementsDataSource,
        ]),
    });

    const columnsNotes = ColumnAttachedNotes({
        notesDataSource: provider.notesDataSource,
        setValue: provider.setValue,
        setNotesDataSource: provider.setNotesDataSource,
        handleRemoveItem: handleDeleteNoteItem,
        netOffNoteId: props.defaultFormValues?.id,
        noteType: props.defaultFormValues?.note_type,
        currency: currency,
        maxTotal: useMemo(() => {
            // Max total for payments
            const {noteAmountNet, payments} = getTotals();
            return noteAmountNet - payments;
        }, [
            props?.defaultFormValues?.amount,
            provider.notesDataSource,
            provider.payementsDataSource,
        ]),
    });

    const addPayement = () => {
        const sizeOfDataSource = provider?.payementsDataSource?.length;
        const lastItemId =
            (provider?.payementsDataSource[sizeOfDataSource - 1]?.key as number) ?? 0;
        const {noteAmountNet, notes, payments} = getTotals();

        const defValAllocation = Math.max(noteAmountNet - notes - payments, 0);

        const emptyRow = {
            paymentId: null,
            legal_entity: '',
            payment_amount: Number(defValAllocation)?.toFixed(2)?.toString(),
            payment_currency: '',
            payment_comments: '',
            net_rate: '0.00',
            currency: '',
            id: 0,
            key: lastItemId + 1,
            editable: true,
            isCurrentEdit: true,
        };
        provider?.setPayementsDataSource([
            ...(provider?.payementsDataSource || {}),
            emptyRow,
        ]);
        columnsPayement.edit(emptyRow);
    };

    const addNote = () => {
        const {noteAmountNet, notes, payments} = getTotals();
        const defValAllocation = Math.max(noteAmountNet - notes - payments, 0);
        const sizeOfDataSource = provider?.notesDataSource?.length;
        const lastItemId =
            (provider?.notesDataSource[sizeOfDataSource - 1]?.key as number) ?? 0;

        const emptyRow = {
            id: 0,
            key: lastItemId + 1,
            noteId: null,
            recipients: '',
            noteAmount: `${defValAllocation}`,
            editable: true,
            isCurrentEdit: true,
        };
        provider?.setNotesDataSource([
            ...(provider?.notesDataSource || {}),
            emptyRow,
        ]);
        columnsNotes.edit(emptyRow);
    };

    const isActive = (type: string) => {
        const key = (type || 'Draft')?.toLocaleLowerCase();
        let isDisabledForm: boolean = false;
        switch (key) {
            case 'draft':
                isDisabledForm = false;
                break;
            default:
                /*const allActiveFormNO = [
                  "endorsement_number",
                  "broker_role",
                  "gross_rate",
                  "net_rate",
                ];
                isDisabledForm = allActiveFormNO.includes(fork_key) ? false : true;*/
                isDisabledForm = true;
                break;
        }
        return isDisabledForm;
    };

    const validRemoveItem = () => {
        const filteredDataSource = provider.payementsDataSource
            ?.filter(
                (el) => el.key?.toString() !== provider?.selectedItem?.key?.toString(),
            )
            .map((el, index) => ({
                // update key after delete
                ...el,
                key: index + 1,
            }));

        if (filteredDataSource) {
            provider?.setPayementsDataSource(filteredDataSource);
            provider.setValue('attachedPayement', 'add', {
                shouldDirty: true,
            });
            handleDeleteModalPayement();
        }
    };

    const removeAttachedManuel = () => {
        provider?.setNotesDataSource([]);
        provider.setSelectedNoteAttachedItem(null);
        provider.setValue('deletedAttachedNote', 'add', {
            shouldDirty: true,
        });
    };

    const validRemoveAttachedNoteItem = async () => {
        handleDeleteModalAttached();
        if (provider?.selectedNoteAttachedItem?.id === 0) {
            removeAttachedManuel();
            return;
        } else {
            provider?.setNotesDataSource([]);
            provider.setValue('deletedAttachedNote', 'add', {
                shouldDirty: true,
            });
        }
    };

    const hiddenFooter = !isCan('write', Menu.note);

    const cancelSignOff = () => {
        setShowmodalConfirm(false);
    };

    const getSingOffStatus = (value: any) => {
        const paymentDate = value.payment_date;
        if (paymentDate && moment(paymentDate).diff(moment(), 'day', true) <= 0) {
            return 'raised';
        }
        return 'past_due';
    };

    const confirmSignOff = async () => {
        if (props.defaultFormValues?.id) {
            if (provider.watch('issuing_date')) {
                await provider.updateForm({
                    ...props.defaultFormValues,
                    issuing_date: provider.watch('issuing_date'),
                });
            }

            await signOff({
                id: props.defaultFormValues.id,
                status: getSingOffStatus(props.defaultFormValues),
            }).unwrap();

            setShowmodalConfirm(false);
            props.handleClose(null);
        }
    };

    const onInvalid = (errors: any) => {
        console.log(errors);
    };

    return (
        <>
            <ConfirmModal
                show={modalConfirm}
                handleClose={() => cancelSignOff()}
                title="Sign-Off"
                confirm={() => {
                    confirmSignOff();
                }}
            >
                <div>
                    <li>
                        <span style={{fontSize: 20}}>Confirm issuance of invoice ?</span>
                    </li>
                </div>
            </ConfirmModal>
            <FormModal
                show={props.show}
                handleClose={() => {
                    provider.resetForm();
                    columnsNotes.setEditingKey(null);
                    columnsPayement.setEditingKey(null);
                    props.handleClose(null);
                }}
                header={
                    <HeaderMultiple
                        activeMenu={provider.activeMenu}
                        isEdit={false}
                        menus={[]}
                        setActiveMenu={() => null}
                        createTitle={`Notes - ${props.defaultFormValues?.number || ''}`}
                    />
                }
                onSubmitForm={provider.handleSubmit(provider.submit, onInvalid)}
                isDisabledSave={!provider.isDirty}
                ishiddenfooter={hiddenFooter}
                dirtiFields={provider.dirtyFields}
            >
                <DeleteModal
                    handleClose={handleDeleteModalPayement}
                    show={modalConfirmDelete}
                    validDelete={() => validRemoveItem()}
                    title="Remove Attached Payment"
                    groupeName={provider?.selectedItem?.paymentId?.value}
                    entityTitle="Attached Payment(s) List"
                />

                <DeleteModal
                    handleClose={handleDeleteModalAttached}
                    show={modalConfirmDeleteAttachedNote}
                    validDelete={() => validRemoveAttachedNoteItem()}
                    title="Remove Attached Note"
                    groupeName={provider?.selectedNoteAttachedItem?.noteId?.value}
                    entityTitle="Attached Note(s) List"
                />

                <Row>
                    <Col lg={12} xl={6} className="pe-4">
                        <div className="my-3">
                            <Form.Group className={`d-flex align-items-baseline w-100`}>
                                <Form.Label className="txt-with-dropdown">ID</Form.Label>

                                <div className="d-flex flex-column w-100">
                                    {props.defaultFormValues?.number}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="my-3">
                            <Form.Group className={`d-flex align-items-baseline w-100`}>
                                <Form.Label className="txt-with-dropdown">Mandate</Form.Label>
                                {provider.selectedMandate && (
                                    <div className="d-flex flex-column w-100">
                                        {provider.selectedMandate?.number}
                                    </div>
                                )}
                            </Form.Group>
                        </div>

                        <div className="my-3">
                            <Form.Group className={`d-flex align-items-baseline w-100`}>
                                <Form.Label className="txt-with-dropdown">Deal</Form.Label>

                                <div className="d-flex flex-column w-100">
                                    {props.defaultFormValues?.deal && (
                                        <div className="d-flex flex-column w-100">
                                            {props.defaultFormValues?.deal?.number}
                                        </div>
                                    )}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="my-3">
                            <Form.Group className={`d-flex align-items-baseline w-100`}>
                                <Form.Label className="txt-with-dropdown">Status</Form.Label>
                                {status && (
                                    <div className="d-flex flex-column w-100">
                                        {exportedDropdownValue(
                                            terms.getConst(TermConst.noteStatus),
                                            status,
                                        )}
                                    </div>
                                )}
                            </Form.Group>
                        </div>
                        <div className="my-3">
                            <Form.Group className={`d-flex align-items-baseline w-100`}>
                                <Form.Label className="txt-with-dropdown">Type</Form.Label>

                                {props.defaultFormValues?.note_type && (
                                    <div className="d-flex flex-column w-100">
                                        {exportedDropdownValue(
                                            terms.getConst(TermConst.noteType),
                                            props.defaultFormValues?.note_type,
                                        )}
                                    </div>
                                )}
                            </Form.Group>
                        </div>
                        <div className="my-3">
                            <Form.Group className={`d-flex align-items-baseline w-100`}>
                                <Form.Label className="txt-with-dropdown">Recipient</Form.Label>
                                <div className="d-flex flex-column w-100">
                                    {props.defaultFormValues?.legal_entity && (
                                        <div className="d-flex flex-column w-100">
                                            {props.defaultFormValues?.legal_entity?.name}
                                        </div>
                                    )}
                                </div>
                            </Form.Group>
                        </div>

                        <div className={`my-2 ${provider.subClassIbanReceipt}`}>
                            <RowContent>
                                <Form.Group className={`d-flex align-items-baseline`}>
                                    <Form.Label className="txt-with-dropdown">
                                        Recipient's IBAN
                                    </Form.Label>

                                    <div className="d-flex flex-column w-100">
                                        <SelectDropDown
                                            disabled={isActive(status)}
                                            notDeletable
                                            icon={
                                                <span style={{cursor: 'pointer'}}>
                          <AddIban
                              // onClick={() =>
                              //   provider.showPartner(provider?.counterparty_id)
                              // }
                          />
                        </span>
                                            }
                                            iconsClass="pt-2"
                                            noPadding
                                            modalId="mandate"
                                            noSearch
                                            preValue={exportedDropdownValue(
                                                provider.bankList,
                                                provider.entity_bank_account_id,
                                            )}
                                            options={provider.bankList}
                                            onSelectOption={(e) =>
                                                provider.setValue(
                                                    'entity_bank_account_id',
                                                    (e as DropDownOptionObjectType).key,
                                                    {
                                                        shouldDirty: true,
                                                    },
                                                )
                                            }
                                        />
                                    </div>
                                </Form.Group>
                            </RowContent>
                        </div>
                        <div>
                            <div>
                                <RowContent>
                                    <Form.Group
                                        className="d-flex"
                                        style={{alignItems: 'baseline'}}
                                    >
                                        <Form.Label className="txt">Issuing Date</Form.Label>

                                        <CofarcoCalendar
                                            readOnly={provider.isDisabledInStatue}
                                            disabled={isActive(status)}
                                            name="issuing_date"
                                            control={provider.control}
                                            setFormValue={provider.setValue}
                                            watchFormValue={provider.watch}
                                        />
                                    </Form.Group>
                                </RowContent>
                            </div>
                        </div>
                        <div className={`${provider.isHiddeEndorSementnumber}`}>
                            <RowContent>
                                <Form.Group className="d-flex align-items-baseline w-100">
                                    <Form.Label className="txt txt-center-fix">
                                        Endorsement n°
                                    </Form.Label>
                                    <div className="d-flex flex-row  justify-content-center  w-100">
                                        <div className="w-100">
                                            <CofarcoFormNumber
                                                minLength={3}
                                                acceptFloat={false}
                                                register={provider.register}
                                                setValue={provider.setValue}
                                                watch={provider.watch}
                                                key_form="endorsement_number"
                                                disabled={isActive(status)}
                                            />
                                        </div>
                                    </div>
                                </Form.Group>
                            </RowContent>
                        </div>
                        <div>
                            <RowContent>
                                <Form.Group className={`d-flex align-items-baseline`}>
                                    <Form.Label className="txt-with-dropdown">
                                        Premium Type
                                    </Form.Label>

                                    <div className="d-flex flex-column w-100">
                                        <SelectDropDown
                                            noPadding
                                            notDeletable
                                            disabled={isActive(status)}
                                            modalId="deal-deal_type"
                                            noSearch
                                            preValue={exportedDropdownValue(
                                                terms.getConst(TermConst.premiumType),
                                                provider.premium_type,
                                            )}
                                            options={makeOptions(
                                                terms.getConst(TermConst.premiumType),
                                            )}
                                            onSelectOption={(e) => {
                                                provider.setValue(
                                                    'premium_type',
                                                    (e as DropDownOptionObjectType).key,
                                                    {
                                                        shouldDirty: true,
                                                    },
                                                );
                                            }}
                                        />
                                    </div>
                                </Form.Group>
                            </RowContent>
                        </div>
                        <div className={`my-2 pb-3`}>
                            <Form.Group className="d-flex align-items-start w-100 align-items-baseline txt-started">
                                <Form.Label className="my-auto-text-area txt-two-line">
                                    {provider.labelCalculatrice}{' '}
                                </Form.Label>

                                <div className="d-flex flex-column w-100 ">
                                    <Form.Control
                                        as="textarea"
                                        rows={4}
                                        disabled={isActive(status)}
                                        className=" w-100"
                                        {...provider.register('premium_calculation_basis', {
                                            required: false,
                                        })}
                                    />
                                    {provider.errors.premium_calculation_basis && (
                                        <FormError
                                            message={`${provider.labelCalculatrice} is required`}
                                        />
                                    )}
                                </div>
                            </Form.Group>
                        </div>
                        <div className={`${provider.subClassBrokerole}`}>
                            <RowContent>
                                <Form.Group className={`d-flex align-items-baseline pt-1`}>
                                    <Form.Label className="txt-with-dropdown">
                                        Broker's role
                                    </Form.Label>

                                    <div className="d-flex flex-column w-100">
                                        <SelectDropDown
                                            noPadding
                                            disabled={isActive(status)}
                                            noSearch
                                            modalId="mandate"
                                            options={terms?.getConst(TermConst.brokerRole)}
                                            onSelectOption={(e) =>
                                                provider.setValue(
                                                    'broker_role',
                                                    (e as DropDownOptionObjectType).key,
                                                    {shouldDirty: true},
                                                )
                                            }
                                            preValue={exportedDropdownValue(
                                                terms?.getConst(TermConst.brokerRole),
                                                props.defaultFormValues?.broker_role,
                                            )}
                                        />
                                    </div>
                                </Form.Group>
                            </RowContent>
                        </div>
                        <div>
                            <RowContent>
                                <Form.Group className={`d-flex align-items-baseline`}>
                                    <Form.Label className="txt-with-dropdown">
                                        Currency
                                    </Form.Label>

                                    <div className="d-flex flex-column w-100">
                                        <SelectDropDown
                                            noPadding
                                            noSearch
                                            notDeletable
                                            error={provider.errors?.currency?.message as string}
                                            onSelectOption={(e) => {
                                                provider.setValue(
                                                    'currency',
                                                    (e as DropDownOptionObjectType).key,
                                                    {
                                                        shouldDirty: true,
                                                    },
                                                );
                                            }}
                                            disabled={isActive(status)}
                                            modalId="enquiry-exposure_currency"
                                            id="enquiry-exposure_currency"
                                            placeholder=""
                                            options={terms
                                                ?.getConst(TermConst.currency)
                                                ?.filter(
                                                    (el: any) => el?.key !== 'xof' && el?.key !== 'cny',
                                                )}
                                            preValue={exportedDropdownValue(
                                                terms
                                                    ?.getConst(TermConst.currency)
                                                    ?.filter(
                                                        (el: any) => el?.key !== 'xof' && el?.key !== 'cny',
                                                    ),
                                                provider.currency,
                                            )}
                                        />
                                    </div>
                                </Form.Group>
                            </RowContent>
                        </div>
                        <div>
                            <RowContent>
                                <Form.Group className="d-flex align-items-baseline w-100">
                                    <Form.Label className="txt txt-center-fix">
                                        {provider.labelAmout}
                                    </Form.Label>
                                    <div className="d-flex flex-row  justify-content-center  w-100">
                                        <div className="w-100">
                                            <CofarcoFormNumber
                                                disabled={isActive(status)}
                                                register={provider.register}
                                                setValue={provider.setValue}
                                                watch={provider.watch}
                                                key_form="amount"
                                            />
                                        </div>
                                    </div>
                                </Form.Group>
                            </RowContent>
                        </div>
                        <div className={`${provider.subClassBrokerage}`}>
                            <RowContent>
                                <Form.Group className="d-flex align-items-baseline w-100 ">
                                    <Form.Label className="txt txt-center-fix">
                                        Brokerage
                                    </Form.Label>
                                    <div className="d-flex flex-row  justify-content-center  w-100">
                                        <div className="w-100">
                                            <CofarcoNumberPercentage
                                                disabled={isActive(status)}
                                                register={provider.register}
                                                setValue={provider.setValue}
                                                key_form="commission_rate"
                                                watch={provider.watch}
                                                unitFormat={3}
                                                isInPrefix
                                            />
                                        </div>
                                    </div>
                                </Form.Group>
                            </RowContent>
                        </div>
                        <div>
                            <RowContent>
                                <Form.Group className="d-flex align-items-baseline w-100">
                                    <Form.Label className="txt txt-center-fix">
                                        Tax Amount
                                    </Form.Label>
                                    <div className="d-flex flex-row  justify-content-center  w-100">
                                        <div className="w-100">
                                            <CofarcoFormNumber
                                                disabled={isActive(status)}
                                                register={provider.register}
                                                setValue={provider.setValue}
                                                watch={provider.watch}
                                                key_form="tax_amount"
                                                maxValue={99999999}
                                                acceptFloat={true}
                                            />
                                        </div>
                                    </div>
                                </Form.Group>
                            </RowContent>
                        </div>
                        <div
                            className={`${
                                props.defaultFormValues?.note_type === 'pdni' ||
                                props.defaultFormValues?.note_type === 'pdnu'
                                    ? ''
                                    : 'd-none'
                            }`}
                        >
                            <RowContent>
                                <Form.Group
                                    className="d-flex"
                                    style={{alignItems: 'baseline'}}
                                >
                                    <Form.Label className="txt">Payment Due Date</Form.Label>
                                    <CofarcoCalendar
                                        name="payment_date"
                                        control={provider.control}
                                        setFormValue={provider.setValue}
                                        watchFormValue={provider.watch}
                                        readOnly={provider.isDisabledInStatue}
                                        disabled={isActive(status)}
                                    />
                                </Form.Group>
                            </RowContent>
                        </div>
                        <div
                            className={`${
                                props.defaultFormValues?.note_type === 'pdni' ||
                                props.defaultFormValues?.note_type === 'ccni'
                                    ? ''
                                    : 'd-none'
                            }`}
                        >
                            <RowContent>
                                <Form.Group
                                    className="d-flex"
                                    style={{alignItems: 'baseline'}}
                                >
                                    <Form.Label className="txt">
                                        Settlement Due Date
                                        {provider.isRequiredSettledDate && (
                                            <span className="span-required">*</span>
                                        )}
                                    </Form.Label>

                                    <CofarcoCalendar
                                        name="settlement_date"
                                        control={provider.control}
                                        setFormValue={provider.setValue}
                                        required={
                                            provider.isRequiredSettledDate && !isActive(status)
                                                ? true
                                                : false
                                        }
                                        errors={provider.errors}
                                        txtErrorMsg="Settlement Due Date is required"
                                        watchFormValue={provider.watch}
                                        clearFormErrors={provider.clearFormErrors}
                                        setFormError={provider.setFormError}
                                        readOnly={provider.isDisabledInStatue}
                                        disabled={isActive(status)}
                                    />
                                </Form.Group>
                            </RowContent>
                        </div>

                        <div className={`${provider.subClassRate}`}>
                            <RowContent>
                                <Form.Group className="d-flex align-items-baseline w-100">
                                    <Form.Label className="txt txt-center-fix">
                                        Gross Rate
                                    </Form.Label>
                                    <div className="d-flex flex-row  justify-content-center  w-100">
                                        <div className="w-100">
                                            <CofarcoNumberRateFormate
                                                register={provider.register}
                                                setValue={provider.setValue}
                                                watch={provider.watch}
                                                key_form="gross_rate"
                                                unitFormat=".000000 %"
                                                maxValue={100}
                                                setFocus={provider.setFocus}
                                                disabled={isActive(status)}
                                            />
                                        </div>
                                    </div>
                                </Form.Group>
                            </RowContent>

                            <div>
                                <RowContent>
                                    <Form.Group className="d-flex align-items-baseline w-100">
                                        <Form.Label className="txt txt-center-fix">
                                            Net Rate
                                        </Form.Label>
                                        <div className="d-flex flex-row  justify-content-center  w-100">
                                            <div className="w-100">
                                                <CofarcoNumberRateFormate
                                                    register={provider.register}
                                                    setValue={provider.setValue}
                                                    watch={provider.watch}
                                                    setFocus={provider.setFocus}
                                                    key_form="net_rate"
                                                    unitFormat=".000000 %"
                                                    maxValue={100}
                                                    disabled={isActive(status)}
                                                />
                                            </div>
                                        </div>
                                    </Form.Group>
                                </RowContent>
                            </div>
                        </div>
                        <div>
                            <RowContent>
                                <Form.Group className={`d-flex align-items-baseline`}>
                                    <Form.Label className="txt-with-dropdown">
                                        Attention Contact
                                    </Form.Label>

                                    <div className="d-flex flex-column w-100">
                                        <SelectDropDown
                                            noPadding
                                            disabled={isActive(status)}
                                            modalId="mandate"
                                            notDeletable
                                            noSearch
                                            options={provider.attPeople?.map((d) => ({
                                                key: d.id,
                                                value: `${d.firstname} ${d.lastname}`,
                                            }))}
                                            preValue={exportedDropdownValue(
                                                provider.attPeople?.map((d) => ({
                                                    key: d.id,
                                                    value: `${d.firstname} ${d.lastname}`,
                                                })),
                                                provider.attention_contact_id,
                                            )}
                                            onSelectOption={(e) =>
                                                provider.setValue(
                                                    'attention_contact_id',
                                                    (e as DropDownOptionObjectType).key,
                                                    {
                                                        shouldDirty: true,
                                                    },
                                                )
                                            }
                                        />
                                    </div>
                                </Form.Group>
                            </RowContent>
                        </div>

                        <RowContent>
                            <Form.Group
                                className={`d-flex  w-100  ${
                                    provider.isUnderWriterReference
                                        ? 'align-items-start'
                                        : 'align-items-baseline'
                                }`}
                            >
                                <Form.Label
                                    className={`txt ${
                                        provider.isUnderWriterReference ? 'mt-1' : ''
                                    }`}
                                    style={{whiteSpace: 'pre-wrap'}}
                                >
                                    {provider.labelReference}
                                </Form.Label>

                                <div className="d-flex flex-column w-100 ">
                                    <Form.Control
                                        className=" w-100"
                                        {...provider.register('client_reference')}
                                        disabled={isActive(status)}
                                    />
                                </div>
                            </Form.Group>
                        </RowContent>
                        {isShowSignedOff && (
                            <RowContent>
                                <li>
                                    <NavLink
                                        className="btn red"
                                        to="#"
                                        onClick={() => setShowmodalConfirm(true)}
                                    >
                                        {/* <img src={AddGroup} alt='' className='pr-2' /> Add */}
                                        {/* <IoPencil color='#FFF' size={20} className='me-1' />{' '} */}
                                        Sign-Off
                                    </NavLink>
                                </li>
                            </RowContent>
                        )}
                    </Col>

                    <Col lg={12} xl={6} className="h-100">
                        <div
                            style={{
                                height: '100vh',
                                width: '100%',
                                verticalAlign: 'top',
                            }}
                        >
                            {provider.noteUrl && (
                                <PdfViewer
                                    noteUrl={provider.noteUrl}
                                    customPdfName={
                                        props.defaultFormValues?.number +
                                        '_' +
                                        props.defaultFormValues?.id
                                    }
                                />
                            )}
                        </div>
                    </Col>
                </Row>
                {provider.isShowAttachedPayement && (
                    <div className={`${isInDraftMode ? 'd-none ' : ''}  mt-5`}>
                        <div style={{height: '1px', backgroundColor: '#DBDDDF'}}/>
                        <Row>
                            <>
                                <div className="content-table pt-4">
                                    <div className="font-bold mb-2">Attached Payment(s)</div>
                                    <div className="content-table">
                                        <Table
                                            dataSource={provider.payementsDataSource || []}
                                            columns={columnsPayement.columns}
                                            pagination={false}
                                            rowClassName={(_, index) =>
                                                `editable-row ${
                                                    index % 2 === 1
                                                        ? classes.tablelight
                                                        : classes.tableWhite
                                                } ${classes.tablerow}`
                                            }
                                            loading={provider.isGetPaymentNoteLoading}
                                            tableLayout="auto"
                                        />
                                    </div>
                                    {!hiddenFooter && (
                                        <div className="d-flex">
                                            <Button
                                                className="btn red mt-3 py-1"
                                                onClick={() => addPayement()}
                                                disabled={columnsPayement.isEditing ? true : false}
                                            >
                                                <img src={AddGroup} alt="" className="pr-2"/>
                                                New Payment Attachment
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </>
                        </Row>
                    </div>
                )}
                {provider.isShowAttachedNote && (
                    <div className={`${isInDraftMode ? 'd-none ' : ''} mt-5`}>
                        <div style={{height: '1px', backgroundColor: '#DBDDDF'}}/>
                        <Row>
                            <>
                                <div className="content-table pt-4">
                                    <div className="font-bold mb-2">Attached Note(s)</div>
                                    <div className="content-table" style={{maxHeight: '65vh'}}>
                                        <Table
                                            dataSource={provider.notesDataSource || []}
                                            columns={columnsNotes.columns}
                                            pagination={false}
                                            rowClassName={(_, index) =>
                                                `editable-row ${
                                                    index % 2 === 1
                                                        ? classes.tablelight
                                                        : classes.tableWhite
                                                } ${classes.tablerow}`
                                            }
                                        />
                                    </div>
                                    {!hiddenFooter && (
                                        <div className="d-flex">
                                            <Button
                                                className="btn red mt-3 py-1"
                                                onClick={() => addNote()}
                                                //disabled={provider.notesDataSource?.length > 0}
                                            >
                                                <img src={AddGroup} alt="" className="pr-2"/>
                                                New Note Attachment
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </>
                        </Row>
                    </div>
                )}
            </FormModal>

            <ModalUnsavedChange
                showModalUnsaved={provider.isShowModalUnsavedChange}
                handleCancel={() => provider.setIsShowModalUnsavedChange(false)}
                handleLeave={() => provider.viewEditPayement()}
                dirtiFields={provider.dirtyFields}
            />
        </>
    );
}

export interface Item {
    key: number | string;
    id: number;
    paymentId: { key: any; value: any } | null | undefined;
    legal_entity: string;
    payment_amount: string;
    payment_currency: string;
    payment_comments?: string;
    net_rate: string;
    currency: string;
    isCurrentEdit?: boolean;
    complete_ref?: string;
    payment_date?: string;
}

type TModaldetailsNotesProps = {
    show: boolean;
    handleClose: (notes: any | null) => void;
    defaultFormValues: any | null;
};
