import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { Method } from '../../../../@types/common-types';
import {
  GroupesResponse,
  InputFieldsGroup,
  LegalEntityInGroupResponse,
  PeoplesResponse,
  CoutryResponse,
  EntityGroupe,
} from '../../../../@types/group/types';
import { baseApi } from '../../baseApi';
import { url } from '../../url';
import { ApiFilter } from '../../../../@types';

const group = baseApi.injectEndpoints({
  endpoints: (build: EndpointBuilder<any, any, any>) => {
    return {
      group: build.query<
        GroupesResponse,
        {
          page: number | undefined;
          start: number | undefined;
          limit: number | undefined;
          sort: string;
          filter: string;
          query?: string;
        }
      >({
        query: (queryParams) => {
          const { start, page, limit, sort, filter, query } = queryParams;
          return {
            url: url.group.list,
            params: { start, page, limit, sort, filter, query: query ?? '' },
          };
        },
        providesTags: ['LIST_GROUPE'],
      }),

      createGroupe: build.mutation<
        { success: boolean; group: EntityGroupe },
        InputFieldsGroup
      >({
        query: (body) => {
          return {
            url: url.group.list,
            method: Method.POST,
            data: body,
          };
        },
        invalidatesTags: ['LIST_GROUPE', 'TEXT_TERMS_SITE'],
      }),

      updateGroupe: build.mutation<
        { success: boolean; group: EntityGroupe },
        InputFieldsGroup
      >({
        query: (body) => {
          return {
            url: url.group.list + '/' + body.id,
            method: Method.PUT,
            data: body,
          };
        },
        invalidatesTags: [
          'LIST_GROUPE',
          'GLOBAL_RESULT_SEARCH',
          'TEXT_TERMS_SITE',
        ],
      }),

      deleteGroupe: build.mutation<
        { success: boolean; group: EntityGroupe },
        { id: number }
      >({
        query: (body) => {
          return {
            url: url.group.list + '/' + body.id,
            method: Method.DELETE,
          };
        },
        invalidatesTags: [
          'LIST_GROUPE',
          'GLOBAL_RESULT_SEARCH',
          'TEXT_TERMS_SITE',
        ],
      }),

      people: build.query<
        PeoplesResponse,
        { page: number; start: number; limit: number }
      >({
        query: (queryParams) => {
          const { start, page, limit } = queryParams;
          return {
            url: url.people.list,
            params: { start, page, limit },
          };
        },
      }),
      legalentity: build.query<
        LegalEntityInGroupResponse,
        { page: number; start: number; limit: number }
      >({
        query: (queryParams) => {
          const { start, page, limit } = queryParams;
          return {
            url: url.legalentity,
            params: { start, page, limit },
          };
        },
      }),

      getCountry: build.query<CoutryResponse, void>({
        query: () => {
          return {
            url: url.country,
          };
        },
      }),

      getLegalentityInGroupe: build.mutation<
        LegalEntityInGroupResponse,
        { page: number; start: number; limit: number; selectedGroupe: number }
      >({
        query: (body) => {
          const { start, page, limit, selectedGroupe } = body;
          return {
            url: url.legalEntityInGroupe + selectedGroupe + '/legalentity',
            params: { start, page, limit },
            method: Method.GET,
          };
        },
      }),

      searchGroupByName: build.mutation<
        GroupesResponse,
        { page: number; start: number; limit: number; query: any }
      >({
        query: (body) => {
          const { start, page, limit, query } = body;
          return {
            url: url.group.list,
            params: { start, page, limit, query },
            method: Method.GET,
          };
        },
      }),
      findGroupByName: build.query<
        GroupesResponse,
        { page: number; start: number; limit: number; query: any }
      >({
        query: (body) => {
          const { start, page, limit, query } = body;
          return {
            url: url.group.list,
            params: { start, page, limit, query },
            method: Method.GET,
          };
        },
      }),

      filterGroupByName: build.query<
        GroupesResponse,
        { page: number; start: number; limit: number; filter: any }
      >({
        query: (body) => {
          const { start, page, limit, filter } = body;
          return {
            url: url.group.list,
            params: { start, page, limit, filter },
            method: Method.GET,
          };
        },
      }),

      getGroupeById: build.mutation<
        { group: EntityGroupe; success: string },
        { id: string | number }
      >({
        query: ({ id }) => {
          return {
            url: `${url.group.list}/${id}`,
          };
        },
      }),
      exportGroups: build.mutation<
        string,
        ApiFilter & { fields?: (keyof EntityGroupe)[] } & ApiFilter
      >({
        query: ({ fields, filter }) => {
          return {
            url: url.group.export,
            data: {
              fields,
            },
            params: {
              filter,
            },
            method: Method.POST,
          };
        },
      }),
    };
  },
});
export const {
  usePeopleQuery,
  useGroupQuery,
  useLegalentityQuery,
  useCreateGroupeMutation,
  useGetCountryQuery,
  useGetLegalentityInGroupeMutation,
  usePrefetch,
  useUpdateGroupeMutation,
  useDeleteGroupeMutation,
  useSearchGroupByNameMutation,
  useGetGroupeByIdMutation,
  useFindGroupByNameQuery,
  useExportGroupsMutation,
} = group;
