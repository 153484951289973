export const exportedParticipations = [
  {
    itemId: 'number',
    text: 'Number',
  },
  {
    itemId: 'mandate_id',
    text: 'Mandate',
  },
  {
    itemId: 'deal_id',
    text: 'Deal',
  },
  {
    itemId: 'counterparty_id',
    text: 'Market',
  },
  {
    itemId: 'underwriter_legal_entity_id',
    text: 'Stamp',
  },
  {
    itemId: 'amount',
    text: 'Amount',
  },
  {
    itemId: 'currency',
    text: 'Currency',
  },
  {
    itemId: 'lead_line',
    text: 'Lead Line',
  },
  {
    itemId: 'status',
    text: 'Status',
  },
  {
    itemId: 'premium_rate',
    text: 'Premium Rate',
  },
  {
    itemId: 'minimum_deposit',
    text: 'Minimum Deposit',
  },
  {
    itemId: 'participant_reference',
    text: 'Participant Reference',
  },
  {
    itemId: 'created_at',
    text: 'Created Date',
  },
  {
    itemId: 'updated_at',
    text: 'Updated Date',
  },
];


export const exportedOrderParticipations = [
  {
    itemId: 'number',
    text: 'Number',
  },
  {
    itemId: 'mandate_id',
    text: 'Mandate',
  },
  {
    itemId: 'deal_id',
    text: 'Deal',
  },
  {
    itemId: 'counterparty_id',
    text: 'Market',
  },
  {
    itemId: 'underwriter_legal_entity_id',
    text: 'Stamp',
  },
  {
    itemId: 'amount',
    text: 'Amount',
  },
  {
    itemId: 'currency',
    text: 'Currency',
  },
  {
    itemId: 'lead_line',
    text: 'Lead Line',
  },
  {
    itemId: 'status',
    text: 'Status',
  },
  {
    itemId: 'participant_reference',
    text: 'Participant Reference',
  },
  {
    itemId: 'premium_rate',
    text: 'Premium Rate',
  },
  {
    itemId: 'minimum_deposit',
    text: 'Minimum Deposit',
  },
  {
    itemId: 'created_at',
    text: 'Created Date',
  },
  {
    itemId: 'updated_at',
    text: 'Updated Date',
  },
];

