import React, { useEffect } from "react";
import { IoClose } from "react-icons/io5";
import { useLocation } from "react-router-dom";

type Props = {
  logo?: string;
  title: string;
  items?: React.ReactNode;
  txt?: string | null;
  setTxt?: (txt: string | undefined | null) => void;
};

const ContentNavBar = ({ logo, title, items, txt, setTxt }: Props) => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname) {
      setTxt && setTxt("");
    }
  }, [location]);

  return (
    <div
      className={`data-base`}
      style={{ backgroundColor: items ? "#f1f2f2" : "white" }}
    >
      <div className="database-search">
        <div className="database-logo">
          <img src={logo} alt="" /> <span>{title}</span>
        </div>
        <div className="quick-filter">
          <div className="search red">
            <span className="icon-search ml-2"></span>
            <input
              type="text"
              placeholder="Quick Filter..."
              value={txt || ""}
              onChange={(evt) => setTxt && setTxt(evt.target.value)}
            />
            {txt && (
              <div
                className="d-flex"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  setTxt && setTxt("");
                }}
              >
                <IoClose color="#FFF" size={20} className="me-4" />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="database-team">{items}</div>
    </div>
  );
};

export default ContentNavBar;
