import classes from './FormError.module.scss';
import { ReactComponent as Warning } from '../../assets/img/Warning.svg';

type Props = {
  message?: string;
  style?: React.CSSProperties;
  meesageStyle?: React.CSSProperties;
  classNameStyle?: string;
  classNameMessage?: string;
};

const FormError = ({ message, ...props }: Props) => {
  return (
    <div className={`${classes.formError} ${props?.classNameStyle}`} style={{
      ...props?.style
    }}>
      {message && (
        <>
          <Warning style={{ fontSize: 18 }} width={14} /> <span title={message}
            style={{
              ...props?.meesageStyle,
              color: '#ff0505',
            }}
            className={`${props?.classNameMessage}`}
          >{message}</span>
        </>
      )}
    </div>
  );
};

export default FormError;
