import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { EntityMandate } from '../../../../@types/mandates/mandates';
import { People } from '../../../../@types/people';
import {
  EntityNotes,
  InputFormsNotesDetails,
  InputPayemenySegregated,
} from '../../../../@types/segregatedaccounts/notes';
import { useGetMandateByIdMutation } from '../../../../redux/api/ws/deals/deals';
import {
  useAttachedNoteMutation,
  useDeleteAttachedNoteMutation,
  useGetNotesByNoteIdMutation,
  useGetPayementByNotesIdMutation,
  useGetPeopleInNoteMutation,
  usePostPayementInNoteMutation,
  useUpdateNotesMutation,
} from '../../../../redux/api/ws/segregatedaccounts/notes';

import { RootState } from '../../../../redux/store';
import {
  amountFormat,
  concatPercent,
  exportDateUi,
  exportDateWs,
  exportUniqueId,
  floorNumber,
  getObjectDifferences,
  isExist,
  setDefaultValues,
  setEmptyUndefinedEditValues,
} from '../../../../utils/helper-function';
import { Item } from './ModalDetailNotes';
import { Item as ItemNotes } from './ColumnAttachedNotes';

import { updateNotificationMessage } from '../../../../redux/slice/notificationSlice';
import {
  useLazyGetBanksAccountQuery,
  useLazyGetPaymentByIdQuery,
} from '../../../../redux/api/ws/segregatedaccounts/payement';
import moment from 'moment';
import { updateModalAction } from '../../../../redux/slice/modalSlice';
import { API_URL } from '../../../../data/config';

type IProps = {
  defaultFormValues: EntityNotes | null;
  closingModal: (itemsDataSource: any | null) => void;
};

export default function UseModalDetailsNote(props: IProps) {
  const [activeMenu, setActiveMenu] = useState<number>(1);
  const auth = useSelector((state: RootState) => state.auth);
  const [getMandate] = useGetMandateByIdMutation();
  const [updateNotes] = useUpdateNotesMutation();
  const [postPayements] = usePostPayementInNoteMutation();
  const [postNotesAttachement] = useAttachedNoteMutation();
  const [validDeletedAttached] = useDeleteAttachedNoteMutation();
  const [isShowModalUnsavedChange, setIsShowModalUnsavedChange] =
    useState<boolean>(false);
  const [paymentIdtoEdit, setPaymentIdtoEdit] = useState<number>(0);

  const [selectedMandate, setSelectedMandate] = useState<EntityMandate>();
  const [payementsDataSource, setPayementsDataSource] = useState<Array<Item>>(
    [],
  );

  const [notesDataSource, setNotesDataSource] = useState<Array<ItemNotes>>([]);

  const dispatch = useDispatch();
  const [getPayementByNotes, { isLoading }] = useGetPayementByNotesIdMutation();
  const [getPayment] = useLazyGetPaymentByIdQuery();
  const [getNotesByNotesId] = useGetNotesByNoteIdMutation();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, dirtyFields },
    control,
    watch,
    setFocus,
    getValues,
    setValue,
    reset,
    setError: setFormError,
    clearErrors: clearFormErrors,
  } = useForm<InputFormsNotesDetails | any>({
    mode: 'onBlur',
    defaultValues: {},
  });

  const id = watch('id');
  const currency = watch('currency');
  const attention_contact_id = watch('attention_contact_id');
  const entity_bank_account_id = watch('entity_bank_account_id');
  const premium_type = watch('premium_type');
  const attachedPayement = watch('attachedPayement');
  const attachedNote = watch('attachedNote');
  const [noteUrl, setNoteUrl] = useState<string | null>(null);
  const [getBankAccount] = useLazyGetBanksAccountQuery();
  const [bankList, setBankList] = useState<Array<any>>([]);
  const [ibanList, setIbanList] = useState<Array<any>>([]);
  const [attPeople, setAttPeople] = useState<Array<People>>([]);
  const [getAttPeople] = useGetPeopleInNoteMutation();
  const [selectedItem, setSelectedItem] = useState<any | null>(null);
  const [selectedNoteAttachedItem, setSelectedNoteAttachedItem] = useState<
    any | null
  >(null);
  const getUrlNotePdf = (noteId: number): string | null => {
    const token = auth.token;
    if (noteId) {
      const subUrl = `${API_URL}note/${noteId}/pdf?token=${token}`;
      return subUrl;
    }
    return null;
  };

  const handleGetAttPeople = async (
    counterparty_id: number,
    deal_id: number,
    note_type: string,
  ) => {
    try {
      const response = await getAttPeople({
        counterpartyId: counterparty_id || '',
        dealId: deal_id,
        noteType: note_type,
      }).unwrap();
      setAttPeople(exportUniqueId(response?.people || []));
      return response;
    } catch (error) {
      return [];
    }
  };

  const hanleBank = async (type?: string, legalEntityId?: number | null) => {
    try {
      const subParams = legalEntityId
        ? { legalEntityId }
        : {
            getOwner: true,
            segregated: 1,
            sort: [
              { property: 'bank', direction: 'ASC' },
              { property: 'currency', direction: 'ASC' },
              { property: 'info', direction: 'ASC' },
            ],
          };
      const resp = await getBankAccount({
        page: 1,
        start: 0,
        limit: 25,
        ...subParams,
      }).unwrap();
      const makeB = resp?.bankaccount?.map((el: any) => ({
        key: el.id,
        value: `${el.bank} - ${el.currency?.toUpperCase()} - ${el.iban} - ${
          el.info
        }`,
      }));
      if (type === 'account') {
        setBankList(makeB);
      }
      if (type === 'iban') {
        setIbanList(makeB);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getMandatesById = async (id: number) => {
    try {
      const response = await getMandate({
        id,
      }).unwrap();
      setSelectedMandate(response?.mandate);
      return response;
    } catch (error) {
      return [];
    }
  };

  const handleGetPayementById = async (id: number) => {
    const resp = await getPayementByNotes({ id }).unwrap();
    if (resp.success) {
      try {
        const makePayement: Array<Item> = resp.payment?.map(
          (el, index: number) => {
            const tempNote = el.complete_ref?.split(' - ') || [];
            let tempNoteCurr = '';

            let tempNoteAmmount = '';
            if (tempNote && tempNote?.[2]) {
              const tempS = tempNote?.[2]?.trim();
              const splitSelected = tempS?.split(' ');
              tempNoteAmmount = splitSelected?.[0] || '';
              tempNoteCurr = splitSelected?.[1] || '';
            }

            return {
              key: index + 1,
              id: el.id,
              paymentId: { key: el.id, value: el.number },
              net_rate: tempNoteAmmount,
              payment_currency: tempNoteCurr,
              payment_amount: el?.note_amount,
              payment_comments: el?.note_comments,
              legal_entity: el?.legal_entity?.name || '',
              currency: el?.currency?.toUpperCase() || '',
              complete_ref: el?.complete_ref || '',
              payment_date: el?.payment_date || '',
            };
          },
        );
        setPayementsDataSource(makePayement);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleGetNotesById = async (id: number) => {
    const resp = await getNotesByNotesId({ id }).unwrap();
    if (resp.success) {
      try {
        const makeNotes: Array<ItemNotes> = resp.note?.map(
          (el: any, index: number) => {
            return {
              key: index + 1,
              id: el.id,
              recipients: el?.legal_entity?.name || '',
              noteAmount: el.amount,
              nummber: el?.number,
              noteId: {
                key: el?.id,
                value: el?.number,
              },
              complete_ref: el?.complete_ref || '',
            };
          },
        );
        setNotesDataSource(makeNotes);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const showNotifUpdateSuccess = (resP: EntityNotes) => {
    dispatch(
      updateNotificationMessage({
        title: 'Update Payment',
        body: `Note ${resP.number} has been updated !`,
        show: true,
      }),
    );
  };

  const floorRate = (v: string, unit = 1) => {
    const isHave = v.includes(' %');
    let tempV: string;
    if (isHave) {
      tempV = Number(v.replaceAll(' %', '')).toFixed(unit);
    } else {
      tempV = Number(v).toFixed(unit);
    }
    return tempV;
  };

  const submit = async (data: InputFormsNotesDetails) => {
    const defaultValue = setDefaultValues<InputFormsNotesDetails>(watch());
    reset(defaultValue);
    const params = {
      ...data,
      endorsement_number: floorNumber(data?.endorsement_number),
      gross_rate: data?.gross_rate
        ? floorRate(data.gross_rate as string, 6)
        : null,
      commission_rate: data?.commission_rate
        ? (data.commission_rate as string).replaceAll(' %', '')
        : null,
      net_rate: data.net_rate ? floorRate(data.net_rate as string, 6) : null,
      amount: data.amount ? floorNumber(data.amount as string) : null,
      tax_amount: data.tax_amount
        ? floorNumber(data.tax_amount as string)
        : null,
      issuing_date: data.issuing_date ? exportDateWs(data.issuing_date) : null,
      settlement_date: data.settlement_date
        ? exportDateWs(data.settlement_date)
        : null,
      payment_date: isExist(data.payment_date)
        ? (exportDateWs(data.payment_date) as string)
        : null,
    };

    try {
      const objectDiff = getObjectDifferences(props.defaultFormValues, params);

      if (id) {
        if (isExist(attachedPayement)) {
          const makePayementParams: Array<InputPayemenySegregated> =
            payementsDataSource?.map((el) => {
              return {
                paymentId: el?.paymentId?.key,
                amount: el.payment_amount,
                currency: el.payment_currency,
                comments: el.payment_comments || '',
              };
            });

          await postPayements({
            payements: makePayementParams,
            id: id,
          }).unwrap();
        }

        if (selectedNoteAttachedItem?.id) {
          const resp: any = await validDeletedAttached({
            noteAttachedId: selectedNoteAttachedItem?.id,
            noteId: id
          });

          if (resp?.data?.success) setSelectedNoteAttachedItem(null);
        }

        if (isExist(attachedNote)) {
          const noteAttachedIds = notesDataSource?.map((el) => el?.noteId?.key);
          console.log({ noteAttachedIds });
          const res: any = await postNotesAttachement({
            noteAttachedId: noteAttachedIds,
            noteId: props.defaultFormValues?.id as number,
          });
          if (res?.error?.data) {
            dispatch(
              updateNotificationMessage({
                title: 'Update Payment Error',
                body: `${res?.error?.data}`,
                show: true,
              }),
            );
            props.closingModal(null);
            return;
          }
        }

        const response: any = await updateNotes(
          setEmptyUndefinedEditValues({
            ...objectDiff,
            id,
          }),
        ).unwrap();
        if (response?.success) {
          showNotifUpdateSuccess(response?.note);
          props.closingModal(null);
        }
      }
      resetForm();
    } catch (error) {
      console.log(error);
    }
  };

  const updateForm = async (data: InputFormsNotesDetails) => {
    const defaultValue = setDefaultValues<InputFormsNotesDetails>(watch());
    reset(defaultValue);
    const params = {
      ...data,
      endorsement_number: floorNumber(data?.endorsement_number),
      gross_rate: data?.gross_rate
        ? floorRate(data.gross_rate as string, 6)
        : null,
      commission_rate: data?.commission_rate
        ? (data.commission_rate as string).replaceAll(' %', '')
        : null,
      net_rate: data.net_rate ? floorRate(data.net_rate as string, 6) : null,
      amount: data.amount ? floorNumber(data.amount as string) : null,
      tax_amount: data.tax_amount
        ? floorNumber(data.tax_amount as string)
        : null,
      issuing_date: data.issuing_date ? exportDateWs(data.issuing_date) : null,
      settlement_date: data.settlement_date
        ? exportDateWs(data.settlement_date)
        : null,
      payment_date: isExist(data.payment_date)
        ? (exportDateWs(data.payment_date) as string)
        : null,
    };

    try {
      const objectDiff = getObjectDifferences(props.defaultFormValues, params);
      if (id) {
        if (isExist(attachedPayement)) {
          const makePayementParams: Array<InputPayemenySegregated> =
            payementsDataSource?.map((el) => {
              return {
                paymentId: el?.paymentId?.key,
                amount: el.payment_amount,
                currency: el.payment_currency,
                comments: el.payment_comments || '',
              };
            });
          await postPayements({
            payements: makePayementParams,
            id: id,
          }).unwrap();
        }
        if (selectedNoteAttachedItem?.id) {
          const resp: any = await validDeletedAttached({
            noteAttachedId: selectedNoteAttachedItem?.id,
            noteId: id
          });

          if (resp?.data?.success) setSelectedNoteAttachedItem(null);
        }

        if (isExist(attachedNote)) {
          const noteAttachedId = notesDataSource[0]?.noteId?.key;
          await postNotesAttachement({
            noteAttachedId: noteAttachedId,
            noteId: props.defaultFormValues?.id as number,
          });
        }

        await updateNotes(
          setEmptyUndefinedEditValues({
            ...objectDiff,
            id,
          }),
        ).unwrap();
      }
      resetForm();
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'settlement_date') {
        const dateDiffNow = moment(value.settlement_date).subtract(9, 'day');

        const dateDiff = dateDiffNow.diff(moment(new Date()), 'day');
        if (dateDiff >= 0) {
          setValue(
            'payment_date',
            exportDateUi(dateDiffNow.toDate()) as string,
          );
        } else {
          setValue('payment_date', exportDateUi(new Date()) as string);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const resetForm = () => {
    setNoteUrl(null);
    setAttPeople([]);
    setBankList([]);
    setIbanList([]);
    setNotesDataSource([]);
    setPayementsDataSource([]);
  };

  React.useEffect(() => {
    if (props.defaultFormValues) {
      const selectedNotes = props.defaultFormValues;

      setNoteUrl(getUrlNotePdf(selectedNotes?.id));
      if (selectedNotes?.mandate_id) {
        getMandatesById(selectedNotes?.mandate_id);
      }
      if (selectedNotes?.legal_entity) {
        hanleBank('account', selectedNotes?.legal_entity?.id);
      }

      const isCompletedToGetAttContact =
        isExist(selectedNotes?.note_type) && isExist(selectedNotes?.deal_id);

      if (isCompletedToGetAttContact) {
        handleGetAttPeople(
          selectedNotes.counterparty_id,
          selectedNotes.deal_id,
          selectedNotes.note_type,
        );
      }

      handleGetPayementById(selectedNotes?.id);
      handleGetNotesById(selectedNotes?.id);

      reset({
        ...selectedNotes,
        issuing_date: isExist(selectedNotes?.issuing_date)
          ? (exportDateUi(
              (selectedNotes?.status || '').toLowerCase() === 'draft'
                ? new Date()
                : selectedNotes?.issuing_date,
            ) as string)
          : null,
        amount: amountFormat(selectedNotes?.amount),
        tax_amount: amountFormat(selectedNotes?.tax_amount),
        gross_rate: concatPercent(selectedNotes?.gross_rate),
        net_rate: concatPercent(selectedNotes?.net_rate),
        commission_rate: concatPercent(
          selectedNotes?.commission_rate,
        ) as string,
        settlement_date: isExist(selectedNotes.settlement_date)
          ? (exportDateUi(selectedNotes.settlement_date) as string)
          : null,
        payment_date: isExist(selectedNotes.payment_date)
          ? (exportDateUi(selectedNotes.payment_date) as string)
          : null,
      });

      //Format endorsement_number
      let number = amountFormat(
        (props.defaultFormValues as any)?.endorsement_number,
        0,
      );

      while (number.length < 3) number = '0' + number;
      setValue('endorsement_number', number);
    }
  }, [props.defaultFormValues]);

  const viewEditPayement = async (id?: number) => {
    setIsShowModalUnsavedChange(false);
    const findP = await getPayment({ id: id ?? paymentIdtoEdit }).unwrap();
    if (findP.payment) {
      dispatch(
        updateModalAction({
          data: findP.payment,
          isAdd: true,
          type: 'payements',
        }),
      );
    }
  };

  const handleEditPayement = async (id: number) => {
    if (isDirty || Object.keys(dirtyFields).length > 0) {
      setPaymentIdtoEdit(id);
      setIsShowModalUnsavedChange(true);
    } else {
      const findP = await getPayment({ id }).unwrap();
      if (findP.payment) {
        dispatch(
          updateModalAction({
            data: findP.payment,
            isAdd: true,
            type: 'payements',
          }),
        );
      }
    }
  };

  const noteType = props.defaultFormValues?.note_type || '';
  const noteStatus = props.defaultFormValues?.status || '';

  const subClassIbanReceipt = ['ccni', 'pcni', 'pcnu'].includes(noteType)
    ? ''
    : 'd-none';

  const isHiddeEndorSementnumber = ['cdnu', 'ccni'].includes(noteType)
    ? 'd-none'
    : '';

  const subClassBrokerage = ['pcnu', 'pdnu'].includes(noteType) ? '' : 'd-none';

  const subClassRate = [
    'pcni',
    'ccni',
    'pdni',
    'cdnu',
    'pcnu',
    'pdnu',
  ].includes(noteType)
    ? 'd-none'
    : '';

  const subClassUnderwriter = ['ccnb', 'cdnb'].includes(noteType)
    ? 'd-none'
    : '';

  const labelAmout = ['cdnu', 'ccni'].includes(noteType)
    ? 'Claim Amount'
    : 'Premium Amount';

  const subClassBrokerole = [
    'ccni',
    'pcni',
    'pdni',
    'pcnu',
    'pdnu',
    'cdnu',
  ].includes(noteType)
    ? 'd-none'
    : '';

  const isUnderWriterReference = ['cdnu', 'pcnu', 'pdnu'].includes(noteType);

  const isClientReference = ['pcni', 'pdni', 'ccni'].includes(noteType);

  const labelReference =
    noteType === 'ccnb' || noteType === 'cdnb'
      ? 'Broker Reference'
      : isClientReference
        ? 'Client Reference'
        : isUnderWriterReference
          ? 'Underwriter \nReference'
          : 'Partner Reference.';

  const labelCalculatrice =
    noteType === 'cdnu' || noteType === 'ccni'
      ? 'Claim Calculation \nBasis'
      : 'Premium Calculation \nBasis';

  const isRequiredSettledDate = noteType === 'ccni' || noteType === 'pdni';

  const isDisabledInStatue = ['past_due', 'settle', 'net_off'].includes(
    noteStatus,
  );

  const isShowAttachedPayement = ['past_due', 'raised', 'settle'].includes(
    noteStatus,
  );

  const isShowAttachedNote = ['past_due', 'net_off', 'raised'].includes(
    noteStatus,
  );

  return {
    resetForm,
    register,
    handleSubmit,
    errors,
    isDirty,
    control,
    watch,
    getValues,
    setValue,
    reset,
    setFormError,
    clearFormErrors,
    activeMenu,
    setActiveMenu,
    noteUrl,
    selectedMandate,
    currency,
    attPeople,
    attention_contact_id,
    ibanList,
    entity_bank_account_id,
    bankList,
    premium_type,
    payementsDataSource,
    setPayementsDataSource,
    submit,
    setFocus,
    notesDataSource,
    setNotesDataSource,
    selectedItem,
    setSelectedItem,
    selectedNoteAttachedItem,
    setSelectedNoteAttachedItem,
    handleEditPayement,
    isGetPaymentNoteLoading: isLoading,
    subClassIbanReceipt,
    isHiddeEndorSementnumber,
    subClassBrokerage,
    subClassRate,
    subClassUnderwriter,
    labelAmout,
    subClassBrokerole,
    labelReference,
    labelCalculatrice,
    isRequiredSettledDate,
    isUnderWriterReference,
    isClientReference,
    isDisabledInStatue,
    dirtyFields,
    isShowModalUnsavedChange,
    setIsShowModalUnsavedChange,
    viewEditPayement,
    isShowAttachedPayement,
    isShowAttachedNote,
    updateForm,
    noteType,
  };
}
