import { Card, Col, Flex, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import { DashboardTodayAgenda } from "./DashboardTodayAgenda";

export const DashboadClock = () => {
  const [date, setDate] = useState<Date>(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDate(new Date());
    }, 60000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures the effect runs only once on mount
  return (
    <Card
      style={{ paddingBottom: 0 }}
      size="small"
      className="dashboard-base-card"
      bordered={false}
    >
      <Row>
        <Col md={24} lg={12}>
          <DashboardTodayAgenda />
        </Col>
        <Col md={24} lg={12}>
          <Flex justify="flex-end">
            <div className="dashboad-timer">
              <Typography.Text className="day">
                {date.toLocaleString("en-us", { weekday: "long" })}
              </Typography.Text>
              <Typography.Title>
                {`${String(date.getDate()).padStart(2, "0")}.${String(
                  date.getMonth() + 1
                ).padStart(2, "0")}`}
              </Typography.Title>
              <Typography.Text className="time">
                {date.toLocaleString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                })}
              </Typography.Text>
            </div>
          </Flex>
        </Col>
      </Row>
    </Card>
  );
};
