import { withNProgress } from '@tanem/react-nprogress';
import PropTypes from 'prop-types';

import Bar from './Bar';
import Container from './Container';
import Spinner from './Spinner';

const Progress = ({ isFinished, progress, animationDuration }) => {
  return (
    <Container animationDuration={animationDuration} isFinished={isFinished}>
      <Bar animationDuration={animationDuration} progress={progress} />
      <Spinner />
    </Container>
  );
};

Progress.propTypes = {
    isFinished: PropTypes.bool,
    progress: PropTypes.any,
    animationDuration: PropTypes.number
};


export default withNProgress(Progress);
