import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "react-datepicker/dist/react-datepicker.css";
import DynamicsTable from "../../../dynamic-table/index";
import { mockCity } from "../../../../utils/mock-city";
import CofarcoFormText from "../../../Form/CofarcoFormText";
import SelectDropDown from "../../../common/DropDown/SelectDropDown";
import { DropDownOptionObjectType } from "../../../common/DropDown/DropdownSelect";
import UseModalLegalEntity from "./UseModalLegalEntity";
import {
  InputFormLegalEntity,
  LegalEntity,
} from "../../../../@types/legal-entities";
import {
  checkIsEdit,
  exportedCountryName,
  isCan,
} from "../../../../utils/helper-function";
import HeaderTab from "../../../HeaderTab/HeaderTab";
import FormModal from "../../../Modal/FormModal/FormModal";
import { ReactComponent as GroupActif } from "../../../../assets/img/GroupActif.svg";
import { ReactComponent as GroupNotActif } from "../../../../assets/img/Group-Not-Actif.svg";

import CounterPatyList from "./CounterPatyList/CounterPatyList";
import { Menu } from "../../../../@types/common-types";
import RowContent from "../../../Form/RowContent";
import { mockAgency } from "../../../../utils/mock-agency";
import { mockCurrency } from "../../../../utils/mock-currency";
import ModalUnsavedChange from "../../../common/Modal/ModalUnsavedChange";
import useClientModal from "../../../CounterParties/Client/Modal/useClientModal";
import { People } from "../../../../@types/people";
import ConfirmModal from "../../../Modal/ConfirmModal";

const ModalLegalEntity = (props: IProps) => {
  const provider = UseModalLegalEntity({
    defaultFormValues: props.defaultFormValues,
    closingModal: props.handleClose,
  });
  const { searchPeopleCallback } = useClientModal({});
  const [activeMenu, setActiveMenu] = useState<number>(1);
  const [people, setPeople] = useState<People>();

  React.useEffect(() => {
    setActiveMenu(1);
  }, [props.show]);

  React.useEffect(() => {
    if (provider?.id) {
      searchPeopleCallback([provider.id], "", (data) => {
        if (data && data.length > 0) {
          setPeople(data[0]);
        }
      });
    }
  }, [provider?.id]);

  const countryOptions =
    (provider.responseCountry?.country || []).map((el) => ({
      key: el.key,
      value: el.name,
    })) || [];

  const isLoading =
    provider.isLoadingUpdateGroupe || provider.isLoadingCreateLegalEntity;

  const id = provider.watch("id");

  const floorRattingDataSource = (dataS: TRattings[]) => {
    const tempDatas = dataS.map((el) => {
      return {
        ...el,
        agency: mockAgency?.find((i) => i.value === el.agency)?.label || "",
      };
    });
    return tempDatas;
  };

  const floorPayementDataSource = (dataS: TPaymentInfo[]) => {
    const tempDatas = dataS.map((el) => {
      return {
        ...el,
        currency:
          mockCurrency?.find((i) => i.value === el.currency)?.label || "",
      };
    });
    return tempDatas;
  };

  const hiddenFooter = !!(
    activeMenu === 2 ||
    (!isCan("write", Menu.legal_entity) && checkIsEdit(id))
  );

  return (
    <>
      <FormModal
        show={props.show}
        handleClose={() => {
          provider.resetForm();
          props.handleClose(null);
        }}
        ishiddenfooter={hiddenFooter}
        isDisabledSave={
          (!provider.isFormEditing && !provider.isDirty) ||
          isLoading ||
          !provider.isCompletedForm /*||
          provider.rattingsFormNotValid ||
          provider.currencyFormNotValid*/
        }
        onSubmitForm={provider.handleSubmit(provider.submitEntity)}
        isLoading={isLoading}
        header={
          <HeaderTab
            isEdit={provider.isEdit}
            title={provider.modalTitle}
            activeMenu={activeMenu}
            setActiveMenu={setActiveMenu}
            titleRigth="Counterparty(ies) List"
          />
        }
        children={
          <>
            {activeMenu === 1 && (
              <Container className="mt-4">
                <Row>
                  <Col>
                    <RowContent>
                      <CofarcoFormText
                        label="Name"
                        class="w-100 align-items-baseline"
                        errors={provider.errors}
                        key_form="name"
                        register={provider.register("name", {
                          required: true,
                        })}
                        isRequired
                        txtError="Name is required"
                      />
                    </RowContent>

                    <RowContent>
                      <Form.Group
                        className="d-flex align-items-baseline w-100"
                        style={{ position: "relative" }}
                      >
                        <Form.Label className="txt-with-dropdown">
                          Group
                        </Form.Label>
                        <div className="d-flex flex-column w-100">
                          <SelectDropDown
                            noPadding
                            icon={
                              provider.watch("group_name") ? (
                                <span
                                  onClick={() => {
                                    if (provider.dataGroup) {
                                      provider.onClickGroup(provider.dataGroup);
                                    }
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <GroupActif color="#EB5D57" />
                                </span>
                              ) : (
                                <span style={{ cursor: "pointer" }}>
                                  <GroupNotActif />
                                </span>
                              )
                            }
                            modalId="modal-legal-entity"
                            className="w-100 "
                            preValue={provider.groupName}
                            loading={provider.isGrpLoading}
                            onSearch={(d, e) =>
                              provider.runSearch(e, "group_id", d)
                            }
                            onSelectOption={(e) => {
                              if (e) {
                                provider.setValue(
                                  "group_id",
                                  (e as DropDownOptionObjectType).key,
                                  { shouldDirty: true }
                                );
                                provider.setDataGroup(
                                  provider.groupeOption?.group.find(
                                    (el) =>
                                      el.id ===
                                      (e as DropDownOptionObjectType).key
                                  )
                                );
                              } else {
                                provider.setValue("group_id", null, {
                                  shouldDirty: true,
                                });
                                provider.setDataGroup(undefined);
                              }
                            }}
                            options={(provider.groupeOption?.group || [])?.map(
                              (el: any) => ({
                                key: el.id,
                                value: el.name,
                                ...el,
                              })
                            )}
                          />
                        </div>
                      </Form.Group>
                    </RowContent>
                    <RowContent>
                      <CofarcoFormText
                        label="Address"
                        errors={provider.errors}
                        key_form="address"
                        register={provider.register("address")}
                        class="w-100 align-items-baseline"
                      />
                    </RowContent>
                    <RowContent>
                      <CofarcoFormText
                        label="Post Code"
                        errors={provider.errors}
                        key_form="zip"
                        register={provider.register("zip")}
                        maxLength={12}
                        class="w-100 align-items-baseline"
                      />
                    </RowContent>
                  </Col>

                  <Col>
                    <RowContent>
                      <Form.Group className="d-flex align-items-baseline">
                        <Form.Label className="txt-with-dropdown">
                          City
                        </Form.Label>
                        <div className="d-flex flex-column w-100">
                          <SelectDropDown
                            noPadding
                            id="city"
                            modalId="modal-legal-entity"
                            allowNew
                            preValue={provider.city as string}
                            onSearch={(d, e) =>
                              provider.runSearch(e, "city", d)
                            }
                            options={mockCity || []}
                            onSelectOption={(e) => {
                              if (typeof e === "string") {
                                provider.setValue("city", e, {
                                  shouldDirty: true,
                                });
                              } else {
                                provider.setValue(
                                  "city",
                                  (e as DropDownOptionObjectType).value,
                                  { shouldDirty: true }
                                );
                              }
                            }}
                          />
                        </div>
                      </Form.Group>
                    </RowContent>
                    <RowContent>
                      <Form.Group className="d-flex align-items-baseline">
                        <Form.Label className="txt-with-dropdown">
                          Country <span className="span-required">*</span>
                        </Form.Label>
                        <div
                          className="d-flex"
                          style={{ flexDirection: "column", width: "100%" }}
                        >
                          <SelectDropDown
                            error={provider.errors?.country_name?.message}
                            noPadding
                            modalId="modal-legal-entity"
                            preValue={exportedCountryName(
                              provider.responseCountry?.country || [],
                              provider.country as string
                            )}
                            onSearch={(d, e) =>
                              provider.runSearch(e, "country_id", d)
                            }
                            onSelectOption={(e) => {
                              if (e) {
                                provider.setValue(
                                  "country_name",
                                  (e as DropDownOptionObjectType).value,
                                  { shouldDirty: true }
                                );
                                provider.setValue(
                                  "country_id",
                                  (e as DropDownOptionObjectType).key,
                                  { shouldDirty: true }
                                );
                                provider.clearErrors("country_name");
                              } else {
                                provider.setError("country_name", {
                                  message: "Country is required",
                                });
                              }
                            }}
                            options={countryOptions}
                          />
                        </div>
                      </Form.Group>
                    </RowContent>
                    <RowContent>
                      <CofarcoFormText
                        label="VAT Number"
                        errors={provider.errors}
                        key_form="vat_number"
                        register={provider.register("vat_number")}
                        class="w-100 align-items-baseline"
                      />
                    </RowContent>
                    <RowContent>
                      <CofarcoFormText
                        label="Firm Number"
                        errors={provider.errors}
                        key_form="firm_number"
                        class="w-100 align-items-baseline"
                        register={provider.register("firm_number")}
                      />
                    </RowContent>
                  </Col>
                </Row>
                <div className="divider" style={{ marginTop: "24px" }}></div>
                <h4 className="boldered-header">Payement instructions</h4>
                <div className="content-table">
                  <DynamicsTable
                    add={provider.add}
                    column={provider.column}
                    mergedColumns={provider.mergedColumns}
                    form={provider.form}
                    dataSource={floorPayementDataSource(provider.dataSource)}
                    txtAdd="Add IBAN"
                    entityId={provider.getValues("id")}
                    isEditing={provider.isEditingPayement}
                    hiddenBtn={!hiddenFooter}
                  />
                </div>

                <h4 className="boldered-header">Ratings</h4>
                <div className="content-table">
                  <DynamicsTable
                    add={provider.addRattings}
                    column={provider.columnRating}
                    mergedColumns={provider.mergedRattings}
                    form={provider.formRattings}
                    txtAdd="Add Agency"
                    dataSource={floorRattingDataSource(
                      provider.dataSourceRattings
                    )}
                    isEditing={provider.isEditingRatting}
                    hiddenBtn={!hiddenFooter}
                  />
                </div>
              </Container>
            )}
            {activeMenu === 2 && (
              <CounterPatyList
                selectedLegalEntityId={provider.id}
                people={people}
              />
            )}
          </>
        }
      />

      <ModalUnsavedChange
        showModalUnsaved={provider.isShowModalUnsavedChange}
        handleCancel={() => provider.setIsShowModalUnsavedChange(false)}
        handleLeave={() =>
          provider.onClickGroup(provider.showOtherModaldata, true)
        }
        dirtiFields={provider.dirtyFields}
      />

      {/*** Creation confirmation if exists*/}
      <ConfirmModal
        show={provider.pLxits}
        handleClose={() => provider.setLexists(false)}
        title="A legal entity already exists with the same name"
        confirm={provider.confirmCreationExisting}
      >
        <div style={{ fontSize: 20 }}>
          Are you sure you want to add this new legal Entity ?
        </div>
      </ConfirmModal>
    </>
  );
};

export default ModalLegalEntity;

export type TPaymentInfo = {
  id: number;
  key: any;
  bank_name: string;
  iban: string;
  bic: string;
  info: string;
  currency: string;
  dataIndex: string;
  editable: boolean;
};

export type TRattings = {
  id: number;
  key: any;
  agency: string;
  ratting: string;
  outlook: string;
  date: string;
};

type IProps = {
  show: boolean;
  handleClose: (legalEntity: LegalEntity | null) => void;
  defaultFormValues: InputFormLegalEntity | null;
};
