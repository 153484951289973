import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/authContext';
import { useEffect } from 'react';
import { useLazyCofarcoTeamByIdQuery } from '../../redux/api/ws/databases/cofarco-teams';
import { STORAGE_KEY } from '../../utils/user-session';

const AuthRequired = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();

  const auth = useAuth();

  const [getUser] = useLazyCofarcoTeamByIdQuery();

  useEffect(() => {
    if (auth?.user) {
      getUser({ id: auth.user.id });
    }
  }, []);

  if (location.pathname === '/logout') {
    return <Navigate to="/login" />;
  }
  if (!auth?.token) {
    const authSession = sessionStorage.getItem(STORAGE_KEY.REFRESH_TOKEN);
    if (!authSession) {
      return <Navigate to="/login" />;
    }
  }

  return children;
};

export default AuthRequired;
