import React, { useState, useContext, useEffect } from "react";
import {
  useDeleteLegalEntityMutation,
  useLegaleEntitiesQuery,
} from "../../../redux/api/ws/databases/legale-entities";
import {
  InputFormLegalEntity,
  LegalEntityFilterType,
  LegalEntity,
} from "../../../@types/legal-entities";
import { useGetCountryQuery } from "../../../redux/api/ws/databases/groups";
import * as bootstrap from "bootstrap";
import { FilterType } from "../../../@types";
import { DropDownOptionType } from "../../common/DropDown/DropdownSelect";
import ColumnsLegalEntity from "./ColumnsLegalEntity";
import { ParamsTable } from "../../../@types/common-types";
import { useAppDispatch } from "../../../hooks/redux";
import { updateModalAction } from "../../../redux/slice/modalSlice";
import { ContextDataBase } from "../DatabaseContent";
import { debounce } from "lodash";
import { setLegalEntityFilter } from "../../../redux/slice/legalEntityFilterSlice";
export default function UseLegalEntity(props?: Props) {
  const [modalConfirm, setModalConfirm] = useState(false);
  const [txtModal, setTxtModal] = useState<string>("");
  const [modalConfirmDelete, setModalConfirmDelete] = useState(false);
  const searchInput = React.useRef<any>(null);
  const [show, setShow] = useState(false);
  const [selectedLegalEntity, setSelectedLegalEntity] =
    useState<LegalEntity | null>(null);
  const [dataSourceLegalEntity, setDataSourceLegalEntity] = useState<
    Array<LegalEntity>
  >([]);
  const [totalData, setTotalData] = useState<number>(0);
  const [defaultFormValues, setDefaultFormValues] =
    useState<InputFormLegalEntity | null>(null);

  const [filterData, setFilterData] = useState<LegalEntityFilterType>({});

  const [paramsLegalEntity, setParamsLegalEntity] = useState<ParamsTable>({
    page: 1,
    start: 0,
    limit: 20,
    sort: JSON.stringify([{ property: "name", direction: "ASC" }]),
    filter: "[]",
  });
  const { txt } = useContext(ContextDataBase);

  const [
    deletedLegalEntity,
    { isLoading: isLoadingDelete, isSuccess: isSuccessDelete },
  ] = useDeleteLegalEntityMutation();

  const { data: responseCountry } = useGetCountryQuery();

  const {
    data: legalEntities,
    isLoading,
    isSuccess,
  } = useLegaleEntitiesQuery(paramsLegalEntity);

  const dispatch = useAppDispatch();

  const handleTableChange = (current: number) => {
    setParamsLegalEntity({
      ...paramsLegalEntity,
      page: current,
    });
  };

  const handleShowModalDelete = () => {
    setModalConfirmDelete(!modalConfirmDelete);
  };

  const handleSorted = (key: string, ruls: "ASC" | "DESC") => {
    const paramsFilter = JSON.stringify([{ property: key, direction: ruls }]);
    setParamsLegalEntity({
      ...paramsLegalEntity,
      sort: paramsFilter,
    });
  };

  const debounceSearch = React.useCallback(
    debounce((txt) => {
      const params = getFilterParams();
      const tempFilter = [
        ...params,
        { property: "allname", operator: "like", value: `%${txt}%` },
      ];
      setParamsLegalEntity({
        ...paramsLegalEntity,
        page: 1,
        start: 0,
        limit: 20,
        filter: JSON.stringify(tempFilter),
      });
    }, 750),
    []
  );

  useEffect(() => {
    if (txt) {
      debounceSearch(txt);
    } else {
      setParamsLegalEntity({
        page: 1,
        start: 0,
        limit: 20,
        sort: JSON.stringify([{ property: "name", direction: "ASC" }]),
        filter: "[]",
      });
    }
  }, [txt]);

  const setPerPage = (page: number) => {
    setParamsLegalEntity({
      ...paramsLegalEntity,
      limit: page,
    });
  };

  useEffect(() => {
    setFilterData({});
    dispatch(setLegalEntityFilter({}));
  }, []);

  const editLegalEntity = (id: number, data?: LegalEntity) => {
    const foundLegalEntity =
      data ??
      dataSourceLegalEntity.find(
        (gr: LegalEntity) => gr.id?.toString() === id?.toString()
      );
    if (foundLegalEntity) {
      dispatch(
        updateModalAction({
          data: {
            ...foundLegalEntity,
            group_id: foundLegalEntity?.group?.id,
            group_name: foundLegalEntity?.group?.name,
          },
          isAdd: true,
          type: "legal_entity",
        })
      );
    }
  };

  const deleteLegalEntity = (idLegalEntity: number, data?: LegalEntity) => {
    const foundLegalEntity =
      data ??
      dataSourceLegalEntity.find(
        (gr: LegalEntity) => gr.id?.toString() === idLegalEntity?.toString()
      );
    setSelectedLegalEntity(foundLegalEntity || null);
    handleShowModalDelete();
  };

  React.useEffect(() => {
    if (isSuccessDelete) {
      handleShowModalDelete();
    }
  }, [isSuccessDelete]);

  const { columns, uncheckedId } = ColumnsLegalEntity({
    editLegalEntity: editLegalEntity,
    handleSorted: handleSorted,
    deleteLegalEntity: deleteLegalEntity,
    legalEntity: dataSourceLegalEntity,
    otherColumns: props?.otherColumns,
  });

  const handleModal = (dataLegalEntity: LegalEntity | null) => {
    if (dataLegalEntity) {
      setDataSourceLegalEntity([dataLegalEntity, ...dataSourceLegalEntity]);
    }
    setShow(!show);
    dispatch(
      updateModalAction({
        data: null,
        isAdd: true,
        type: "legal_entity",
      })
    );
  };

  const handleCreateLegalEntity = () => {
    setDefaultFormValues(null);
    handleModal(null);
  };

  const validDelete = () => {
    if (selectedLegalEntity?.id) {
      deletedLegalEntity({ id: selectedLegalEntity?.id || -1 });
    }
  };

  React.useEffect(() => {
    if (isSuccess) {
      setDataSourceLegalEntity(legalEntities.legalentity || []);
      setTotalData(legalEntities.total);
    }
  }, [isSuccess, legalEntities?.legalentity]);

  const handleModalConfirm = () => {
    setModalConfirm(!modalConfirm);
  };

  const updateFilter = (
    key: keyof LegalEntityFilterType,
    v?: DropDownOptionType | null
  ) => {
    let newFilter = { ...filterData };
    const value = v as string | null | undefined;
    if (!value || value?.trim() === "") {
      delete newFilter[key];
    } else {
      let operator = key === "country_id" ? "=" : "like";
      if (key === "created_at") {
        operator = "<=";
      }

      newFilter = {
        ...newFilter,
        [key]: {
          property: key,
          value: value,
          operator,
        },
      };
    }
    dispatch(setLegalEntityFilter(newFilter));
    setFilterData(newFilter);
  };

  const getFilterParams = React.useCallback((): FilterType[] => {
    const values = Object.values(filterData).map((e) => {
      if (e.property === "created_at" || e.property === "end_date") {
        return {
          ...e,
          property: "created_at",
        };
      }
      return {
        ...e,
        value:
          e.operator === "like"
            ? `%${(e.value as string).trim()}%`
            : `${(e.value as string).trim()}`,
      };
    });
    return values;
  }, [filterData]);

  const getLegalEntityCallback = () => {
    setParamsLegalEntity({
      ...paramsLegalEntity,
      page: 1,
      start: 0,
      limit: 20,
      filter: JSON.stringify(getFilterParams()),
    });
  };

  React.useEffect(() => {
    const params = getFilterParams();
    if (params.length === 0) {
      setParamsLegalEntity({
        ...paramsLegalEntity,
        page: 1,
        start: 0,
        limit: 20,
        filter: JSON.stringify(params),
      });
    }
  }, [filterData]);

  const toggleFilterCollapse = () => {
    if (Object.keys(filterData).length > 0) {
      setFilterData({});
      dispatch(setLegalEntityFilter({}));
    }
    const collapseSearch = document.getElementById("collapseSearch")!;
    const collapse = new bootstrap.Collapse(collapseSearch, { toggle: true });
    collapse.toggle();
  };

  return {
    deleteLegalEntity,
    editLegalEntity,
    handleTableChange,
    searchInput,
    legalEntities,
    isLoading,
    show,
    setShow,
    handleModal,
    handleCreateLegalEntity,
    responseCountry,
    handleShowModalDelete,
    modalConfirmDelete,
    selectedLegalEntity,
    validDelete,
    isLoadingDelete,
    dataSourceLegalEntity,
    handleModalConfirm,
    modalConfirm,
    txtModal,
    setTxtModal,
    columns,
    setPerPage,
    totalData,
    setTotalData,
    filterData,
    updateFilter,
    getLegalEntityCallback,
    toggleFilterCollapse,
    paramsLegalEntity,
    defaultFormValues,
    uncheckedId,
  };
}

type Props = {
  otherColumns?: string[];
};
