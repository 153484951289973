import { Form, FormControlProps } from "react-bootstrap";
import FormError from "../FormError/FormError";
import { useEffect } from "react";

type IProps = FormControlProps & {
  label: string;
  register: any;
  errors: any;
  key_form: string;
  txtError?: string;
  isRequired?: boolean;
  txtErrorValidate?: string;
  class?: string;
  value?: any;
  maxLength?: number;
  type?: string;
  maxWidth?: string;
  min?: number;
  onBlur?: any;
  noLabel?: boolean;
  labelClass?: string;
  disabled?: boolean;
  clearError?: () => void;
};

export default function CofarcoFormText({ ...props }: IProps) {
  useEffect(() => {
    if (!props.isRequired && !props.value) {
      props.clearError?.();
    }
  }, [props.isRequired, props.value]);

  return (
    <Form.Group
      className={`d-flex ${props.class || ""}`}
      style={{
        // paddingTop: '0.7rem',
        textOverflow: "ellipsis",
        alignItems: "center",
      }}
    >
      <Form.Label
        className={`txt ${props.noLabel === true ? "d-none" : ""} ${
          props.labelClass ?? ""
        }`}
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "pre-wrap",
          margin: "auto 0",
        }}
      >
        {props.label}{" "}
        {props.isRequired && <span className="span-required">*</span>}
      </Form.Label>

      <div
        className="d-flex"
        style={{
          position: "relative",
          flexDirection: "column",
          width: "100%",
          maxWidth: props.maxWidth,
        }}
      >
        <Form.Control
          style={{ width: "99%", borderRadius: "4px" }}
          type={props.type || "text"}
          className={`${props.errors[props.key_form] ? "invalid" : ""}`}
          defaultValue={""}
          maxLength={props?.maxLength || null}
          min={props?.min || null}
          onBlur={() => {
            if (props?.onBlur) props.onBlur();
          }}
          {...props.register}
          disabled={props.disabled}
        />
        {props.errors[props.key_form]?.type === "required" && (
          <FormError
            message={props.txtError}
            style={{
              position: "absolute",
              bottom: "-1.25rem",
              left: "0",
            }}
          />
        )}
        {props.errors[props.key_form]?.type === "validate" && (
          <FormError
            message={props.txtErrorValidate}
            style={{
              width: "100%",
              position: "absolute",
              bottom: "-1.25rem",
              left: "0",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          />
        )}
      </div>
    </Form.Group>
  );
}
