export const exportedNotes = [
  {
    itemId: "number",
    text: "Number",
  },
  {
    itemId: "note_type",
    text: "Type",
  },
  {
    itemId: "premium_type",
    text: "Premium Type",
  },
  {
    itemId: "status",
    text: "Status",
  },
  {
    itemId: "mandate_id",
    text: "Mandate",
  },
  {
    itemId: "deal_id",
    text: "Deal",
  },
  {
    itemId: "issuing_date",
    text: "Issuing Date",
  },
  {
    itemId: "legal_entity_id",
    text: "Legal Entity",
  },
  {
    itemId: "currency",
    text: "Currency",
  },
  {
    itemId: "amount",
    text: "Premium Amount",
  },
  {
    itemId: "participation_id",
    text: "Participation",
  },
  {
    itemId: "settlement_date",
    text: "Settlement Due Date",
  },
  {
    itemId: "payment_date",
    text: "Payment Due Date",
  },
  {
    itemId: "group_id",
    text: "Group",
  },
  {
    itemId: "counterparty_id",
    text: "Counterparty",
  },
  {
    itemId: "attention_contact_id",
    text: "Attention Contact",
  },
  {
    itemId: "broker_role",
    text: "Broker's Role",
  },
  {
    itemId: "tax_amount",
    text: "Premium Tax",
  },
  {
    itemId: "commission_rate",
    text: "Commissions",
  },
  {
    itemId: "gross_rate",
    text: "Gross Rate",
  },
  {
    itemId: "net_rate",
    text: "Net Rate",
  },
  {
    itemId: "umr_code",
    text: "UMR",
  },
  {
    itemId: "client_reference",
    text: "Recipient Reference",
  },
  {
    itemId: "premium_calculation_basis",
    text: "Premium Calculation Basis",
  },
  {
    itemId: "created_at",
    text: "Created Date",
  },
  {
    itemId: "updated_at",
    text: "Updated Date",
  },
];
