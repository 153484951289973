import { ReactComponent as indicationsRed } from "../assets/img/Indications_24px.svg";
import { ReactComponent as notesRed } from "../assets/img/notes_24px.svg";
import { ReactComponent as payments } from "../assets/img/payments_24px.svg";
import { ReactComponent as legalentitiesRed } from "../assets/img/legalentities_24px.svg";
import { ReactComponent as peopleRed } from "../assets/img/people_red_24px.svg";
import { ReactComponent as mandateRed } from "../assets/img/mandates_24px_red.svg";
import { ReactComponent as dealRed } from "../assets/img/Deals_24px.svg";
import { ReactComponent as enquiriesRed } from "../assets/img/Enquiries.svg";
import { ReactComponent as counterPartyRed } from "../assets/img/counterparties_red.svg";
import { ModelsEnum } from "../@types/dashboard";

export const MainMenu = [
  {
    label: "Mandate",
    imgRed: mandateRed,
    key: ModelsEnum.mandate.toLowerCase(),
  },

  {
    label: "Enquiry",
    imgRed: enquiriesRed,
    key: ModelsEnum.enquiry.toLowerCase(),
  },

  {
    label: "Indication",
    imgRed: indicationsRed,
    key: ModelsEnum.indication.toLowerCase(),
  },

  {
    label: "Deal",
    imgRed: dealRed,
    key: ModelsEnum.deal.toLowerCase(),
  },

  {
    label: "Note",
    imgRed: notesRed,
    key: ModelsEnum.note.toLowerCase(),
  },

  {
    label: "Payment",
    imgRed: payments,
    key: ModelsEnum.payment.toLowerCase(),
  },

  {
    label: "Legal Entity",
    imgRed: legalentitiesRed,
    key: ModelsEnum.legalEntity.toLowerCase(),
  },

  {
    label: "People",
    imgRed: peopleRed,
    key: ModelsEnum.people.toLowerCase(),
  },

  {
    label: "Counter Party",
    imgRed: counterPartyRed,
    key: ModelsEnum.counterParty.toLowerCase(),
  },
];
