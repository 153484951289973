import React, { useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { EntityLimits } from '../../../../@types/limit/types';
import {
  useContDealLimitQuery,
  useDeleteParticipationDealLimitMutation,
  useGetLimitbyDealIdQuery,
  useLazyGetLimitsByLimitIdQuery,
  useLazyGetLimitsByParticipationIdQuery,
  useSearchLimitMutation,
} from '../../../../redux/api/ws/participations/participations';
import {
  downloadCsv,
  filterColumn,
  isCan,
  isExist,
  makeOptions,
  titleRequestError,
} from '../../../../utils/helper-function';
import Table from '../../../CofarcoTable/Table';
import ColumnLimits from './ColumnLimits';
import SelectDropDown from '../../../common/DropDown/SelectDropDown';
import PromptModal from '../../../Modal/PromptModal';
import { DropDownOptionObjectType } from '../../../common/DropDown/DropdownSelect';
import { useAppDispatch } from '../../../../hooks/redux';
import DeleteModal from '../../../Modal/DeleteModal';
import { updateNotificationMessage } from '../../../../redux/slice/notificationSlice';
import { updateModalAction } from '../../../../redux/slice/modalSlice';
import { Menu } from '../../../../@types/common-types';
import {
  useExportDealsLimitObligorMutation,
  useGetParticipationByDealsIdQuery,
} from '../../../../redux/api/ws/deals/deals';
import { EntityParticipation } from '../../../../@types/participation/participation';
import { Button } from 'antd';
import { ReactComponent as ExcelIcon } from './../../../../assets/img/excel.svg';
import { ReactComponent as AddGroup } from './../../../../assets/img/add.svg';

type IProps = {
  selectedParticipation: number | null;
  dealId: number | null;
  mandateId: number | null;
  participation_id?: number;
  limitId?: number;
  market?: string;
  deal_type?: string;
};
export default function ParticipationLimitsList(props: IProps) {
  const [getObligorLimit, { data: participationList, isLoading }] =
    useLazyGetLimitsByParticipationIdQuery();

  // if The modal is for deal means from Deals > Limits List > Add
  const { data: participationByDealID, isLoading: isloadingLimit } =
    useGetParticipationByDealsIdQuery({ id: props.dealId ?? 0 });

  const polList = useMemo(
    () => participationByDealID?.participation ?? [],
    [participationByDealID],
  );

  const [
    getObligorLimitByLimitId,
    { data: poLimitList, isLoading: isLoadingPoLimit },
  ] = useLazyGetLimitsByLimitIdQuery();
  const [modalConfirmDelete, setModalConfirmDelete] = React.useState(false);

  const { data: dealNbr, isLoading: isLoadingDealNbr } = useContDealLimitQuery({
    pId: props.dealId || 0,
  });

  const [selectedLimitToDelete, setSelectedLimitToDelete] =
    React.useState<EntityLimits | null>(null);

  const dispatch = useAppDispatch();

  const [
    seachLimits,
    { data: LimitList, isLoading: isLoadingisLoadingSearchLimit },
  ] = useSearchLimitMutation();

  const { data: limitsListByDealID, isLoading: isLoadingLimitsList } =
    useGetLimitbyDealIdQuery({ id: props.dealId || 0 });

  const [
    deleteLimit,
    {
      isLoading: isLoadingDelete,
      isSuccess: isSuccessDelete,
      isError: isErrorDelete,
      error: errorResponse,
    },
  ] = useDeleteParticipationDealLimitMutation();

  const [showPrompt, setShowPrompt] = React.useState<boolean>(false);

  const [selectedLimit, setSelectedLimit] = React.useState<EntityLimits | null>(
    null,
  );
  const [participationSelected, setSelectedParticipation] = React.useState<
    EntityParticipation | EntityLimits | null
  >(null);

  const handleShowModalDelete = () => {
    setModalConfirmDelete(!modalConfirmDelete);
  };

  const handleSorted = React.useCallback(
    (key: string, ruls: 'ASC' | 'DESC') => {
      if (props.selectedParticipation) {
        getObligorLimit({
          id: props.selectedParticipation || 0,
          sort: `[{"property":"${key}","direction":"${ruls}"}]`,
        });
      }
    },
    [],
  );

  React.useEffect(() => {
    if (props.participation_id) {
      getObligorLimit({
        id: props.participation_id || 0,
      });
    }
  }, [props.participation_id]);

  React.useEffect(() => {
    if (props.limitId) {
      getObligorLimitByLimitId({
        id: props.limitId || 0,
      });
    }
  }, [props.limitId]);

  const [exportLimit] = useExportDealsLimitObligorMutation();

  const edit = (id: number, record: EntityLimits) => {
    dispatch(
      updateModalAction({
        data: {
          number: record?.number,
          event: 'edit',
          deal_id: props.dealId,
          mandate_id: props.mandateId,
          participation_id: props.participation_id,
          comments: record?.comments ?? '',
          expiry_date: record?.expiry_date,
          id: record?.id,
          limit_date: record?.limit_date,
          status: record?.status,
          currency: record?.currency,
          amount: record?.amount,
          entity_obligor: record?.entity_obligor,
          obligor_legal_entity_id: record?.entity_obligor.id,
          group_id: record?.entity_obligor?.group_id,
          country_id: record?.entity_obligor.country_id,
          nature: record?.nature,
          limit_id: record?.limit_id,
          pricing: record?.pricing,
        },
        isAdd: true,
        type: 'limits',
      }),
    );
  };

  const handleDelete = (id: number) => {
    const limit = (participationList?.limit || poLimitList?.limit || []).find(
      (el: any) => el.id === id,
    );
    if (limit) {
      setSelectedLimitToDelete(limit || null);
      handleShowModalDelete();
    }
  };

  const validDelete = () => {
    if (selectedLimitToDelete?.id) {
      deleteLimit({ id: selectedLimitToDelete?.id || -1 });
    }
  };

  const { columns, uncheckedId } = ColumnLimits({
    limitsList: participationList?.limit || [],
    handleSorted,
    edit: edit,
    handleDelete: handleDelete,
    market: props.market,
    deal_type: props.deal_type,
  });

  const handlePrompt = () => {
    setShowPrompt(!showPrompt);
    seachLimits({
      query: undefined,
      dealId: props.dealId || 0,
    }).unwrap();
  };

  const addClick = () => {
    if (selectedLimit) {
      dispatch(
        updateModalAction({
          data: {
            limit_id: selectedLimit.id,
            deal_id: props.dealId,
            mandate_id: props.mandateId,
            participation_id: props.participation_id,
            entity_obligor: selectedLimit?.entity_obligor,
            obligor_legal_entity_id: selectedLimit?.entity_obligor?.id,
            country_id: selectedLimit.entity_obligor?.country_id,
            group_id: selectedLimit?.entity_obligor?.group_id,
            limitDeal: selectedLimit,
          },
          isAdd: true,
          type: 'limits',
        }),
      );
    } else if (participationSelected) {
      dispatch(
        updateModalAction({
          data: {
            limit_id: props.limitId,
            deal_id: props.dealId,
            mandate_id: props.mandateId,
            participation_id: participationSelected?.id,
            entity_obligor: limitsListByDealID?.limit[0]?.entity_obligor,
            obligor_legal_entity_id:
              limitsListByDealID?.limit[0]?.entity_obligor?.id,
            country_id:
              limitsListByDealID?.limit[0]?.entity_obligor?.country_id,
            group_id: limitsListByDealID?.limit[0]?.entity_obligor?.group_id,
            limitDeal: limitsListByDealID?.limit[0],
          },
          isAdd: true,
          type: 'limits',
        }),
      );
    }
  };

  React.useEffect(() => {
    if (isSuccessDelete) {
      handleShowModalDelete();
    }
  }, [isSuccessDelete]);

  React.useEffect(() => {
    if (isErrorDelete) {
      handleShowModalDelete();
      dispatch(
        updateNotificationMessage({
          show: true,
          title: titleRequestError,
          body: errorResponse?.data,
        }),
      );
    }
  }, [isErrorDelete]);

  const handleExportParticipationObligorLimit = async () => {
    const filter = [
      {
        property: 'participation_id',
        value: props.participation_id || 0,
        operator: '=',
      },
    ];
    const fields = [
      'obligor_legal_entity_id',
      'country_id',
      'currency',
      'status',
      'nature',
      'limit_date',
      'expiry_date',
      'amount',
    ];
    const res = await exportLimit({
      fields,
      filter,
    });
    if (res) {
      const rep = res as any;
      downloadCsv(rep.data, 'limit_obligors.csv');
    }
  };

  return (
    <>
      <div
        className="content-table"
        style={{ overflow: 'auto', maxHeight: '65vh' }}
      >
        <Table
          dataSource={participationList?.limit || poLimitList?.limit || []}
          columns={filterColumn<EntityLimits>(columns, uncheckedId)}
          loading={
            isLoading ||
            isloadingLimit ||
            isLoadingPoLimit ||
            isLoadingLimitsList
          }
          onRow={(record: EntityLimits) => {
            return {
              onClick: () => edit(record?.id, record),
            };
          }}
        />
      </div>
      {/*(dealNbr?.nbr || 0) > 0 &&
        !isLoadingDealNbr &&
        isCan('write', Menu.participation) && (
          <AddBtn
            disabled={false}
            onClick={() => handlePrompt()}
            title='Add Participation Obligor Limit'
          />
        )*/}
      {(dealNbr?.nbr || 0) > 0 &&
        !isLoadingDealNbr &&
        isCan('write', Menu.participation) && (
          <div className="d-flex">
            <Button className="btn red mt-3" onClick={() => handlePrompt()}>
              <AddGroup />
              <span className="ms-1">Add Participation Obligor Limit</span>
            </Button>

            <Button
              className="btn red mt-3 mx-3"
              onClick={() => handleExportParticipationObligorLimit()}
            >
              <ExcelIcon />
              <span className="ms-1">Export</span>
            </Button>
          </div>
        )}

      <DeleteModal
        title="Delete Limit"
        handleClose={handleShowModalDelete}
        show={modalConfirmDelete}
        validDelete={() => validDelete()}
        groupeName={selectedLimitToDelete?.number || ''}
        isLoadingDelete={isLoadingDelete}
      />

      <PromptModal
        show={showPrompt}
        addClick={() => addClick()}
        handleClose={() => handlePrompt()}
        title="New - Participation Obligor Limit"
        isLoading={isLoadingisLoadingSearchLimit}
        isDisabled={
          isExist(selectedLimit?.id || participationSelected?.id) ? false : true
        }
      >
        <div className="my-3">
          <Form.Group className="d-flex align-items-center w-100">
            <Form.Label className="txt mx-4 my-auto">
              {
                // from Participation > Participation Obligor Limits List > Add ||
                // from Deals > Limits List > Participation Obligor Limits List > Add
                props.participation_id ? 'Limit' : 'Participation'
              }
            </Form.Label>

            <div className="d-flex flex-column">
              <SelectDropDown
                modalId="enquiries-client"
                id="client_legal_entity_id"
                placeholder=""
                noSearch
                loading={isLoadingisLoadingSearchLimit}
                // onSearch={(d, v) => handleSeach(v)}
                options={
                  props.participation_id
                    ? makeOptions(LimitList?.limit || [], 'number', 'id')
                    : makeOptions(polList, 'number', 'id')
                }
                onSelectOption={(e) => {
                  if (props.participation_id) {
                    const selected = LimitList?.limit?.find(
                      (el) => el.id === (e as DropDownOptionObjectType).key,
                    );
                    if (selected) {
                      setSelectedLimit(selected as EntityLimits);
                    }
                  } else {
                    const selected = polList?.find(
                      (el) => el.id === (e as DropDownOptionObjectType).key,
                    );
                    if (selected) {
                      setSelectedParticipation(selected as EntityParticipation);
                    }
                  }
                }}
              />
            </div>
          </Form.Group>
        </div>
      </PromptModal>
    </>
  );
}
