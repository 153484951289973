import React from 'react';
import { EntityLimits } from '../../../../@types/participation/participation';
import {
  filterColumn,
  isCan,
  titleRequestError,
} from '../../../../utils/helper-function';
import Table from '../../../CofarcoTable/Table';
import ColumnLimit from './ColumnLimit';
import AddBtn from '../../../Button/AddBtn';
import { useAppDispatch } from '../../../../hooks/redux';
import { updateModalAction } from '../../../../redux/slice/modalSlice';
import DeleteModal from '../../../Modal/DeleteModal';
import { useDeleteParticipationDealLimitMutation } from '../../../../redux/api/ws/participations/participations';
import { updateNotificationMessage } from '../../../../redux/slice/notificationSlice';
import { Menu } from '../../../../@types/common-types';

type IProps = {
  dataSource: Array<any>;
  isLoading: boolean;
  selectedDeals: any | null;
  sortedLimits: (sortedParams: string) => void;
  market: string;
  deal_type: string;
};

export default function LimitsList(props: IProps) {
  const handleSorted = React.useCallback(
    (key: string, ruls: 'ASC' | 'DESC') => {
      props.sortedLimits(`[{"property":"${key}","direction":"${ruls}"}]`);
    },
    [],
  );

  const [
    deleteLimit,
    {
      isLoading: isLoadingDelete,
      isSuccess: isSuccessDelete,
      isError: isErrorDelete,
      error: errorResponse,
    },
  ] = useDeleteParticipationDealLimitMutation();

  const [modalConfirmDelete, setModalConfirmDelete] = React.useState(false);

  const [selectedLimitToDelete, setSelectedLimitToDelete] =
    React.useState<EntityLimits | null>(null);
  const dispatch = useAppDispatch();

  const editLimit = (id: number) => {
    const limit = (props.dataSource || []).find((el: any) => el.id === id);

    dispatch(
      updateModalAction({
        data: {
          number: limit?.number,
          event: 'edit',
          deal_id: props.selectedDeals?.id,
          legal_entity_id: limit.entity_obligor?.id,
          mandate_id: props.selectedDeals?.mandate_id,
          comments: limit.comments,
          expiry_date: limit.expiry_date,
          id: limit.id,
          limit_date: limit.limit_date,
          status: limit.status,
          currency: limit.currency,
          amount: limit.amount,
          obligor_legal_entity_id: limit.entity_obligor?.id,
          nature: limit.nature,
          market: props.market,
          deal_type: props.deal_type,
          pricing: limit.pricing,
        },
        isAdd: true,
        type: 'deal_obligor_limit',
      }),
    );
  };

  const handleShowModalDelete = () => {
    setModalConfirmDelete(!modalConfirmDelete);
  };

  const handleDeleteLimit = (id: number) => {
    const limit = (props.dataSource || []).find((el: any) => el.id === id);

    if (limit) {
      setSelectedLimitToDelete(limit || null);
      handleShowModalDelete();
    }
  };

  const { columns, uncheckedId } = ColumnLimit({
    limitsList: props.dataSource || [],
    handleSorted,
    editLimit,
    handleDeleteLimit,
    market: props.market,
    deal_type: props.deal_type,
  });

  const handleCreateLimits = () => {
    dispatch(
      updateModalAction({
        data: {
          event: 'create',
          deal_id: props.selectedDeals?.id,
          legal_entity_id: props.selectedDeals?.obligor?.legal_entity_id,
          mandate_id: props.selectedDeals?.mandate_id,
        },
        isAdd: true,
        type: 'deal_obligor_limit',
      }),
    );
  };

  const validDelete = () => {
    if (selectedLimitToDelete?.id) {
      deleteLimit({ id: selectedLimitToDelete?.id || -1 });
    }
  };

  React.useEffect(() => {
    if (isSuccessDelete) {
      handleShowModalDelete();
    }
  }, [isSuccessDelete]);

  React.useEffect(() => {
    if (isErrorDelete) {
      handleShowModalDelete();
      dispatch(
        updateNotificationMessage({
          show: true,
          title: titleRequestError,
          body: errorResponse?.data,
        }),
      );
    }
  }, [isErrorDelete]);

  return (
    <>
      <div
        className="content-table"
        style={{ overflow: 'auto', maxHeight: '65vh' }}
      >
        <Table
          dataSource={props.isLoading ? [] : props.dataSource || []}
          columns={filterColumn<EntityLimits>(columns, uncheckedId)}
          loading={props?.isLoading}
          onRow={(record: any) => {
            return {
              onClick: () => {
                editLimit(record?.id);
              },
            };
          }}
        />
      </div>
      <DeleteModal
        title="Delete Limit"
        handleClose={handleShowModalDelete}
        show={modalConfirmDelete}
        validDelete={() => validDelete()}
        groupeName={selectedLimitToDelete?.number || ''}
        isLoadingDelete={isLoadingDelete}
      />
      {isCan('write', Menu.obligor) && (
        <AddBtn
          disabled={false}
          onClick={() => {
            handleCreateLimits();
          }}
          title="Add"
        />
      )}
    </>
  );
}
