import React, { useEffect } from 'react';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import { useAppDispatch, useAppSelector } from './hooks/redux';
import { ToastContainer, Zoom } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import { AppRouter } from './routes';
import NotificationModal from './components/Modal/NotificationModal';
import PeopleModal from './components/Database/People/Modal/PeopleModal';
import ModalLegalEntity from './components/Database/LegalEntity/ModalLegalEntity/ModalLegalEntity';
import { InputFormLegalEntity } from './@types/legal-entities';
import IndicationModal from './components/Indication/Modal/IndicationModal/IndicationModal';
import ClientModal from './components/CounterParties/Client/Modal/ClientModal';
import UnderwriterModal from './components/CounterParties/Underwriter/Modal/UnderwriterModal';
import ModalEnquiries from './components/Enquiries/ModalEnquiries/ModalEnquiries';
import ModalGroup from './components/Database/Group/ModalGroup/ModalGroup';
import { updateModalAction } from './redux/slice/modalSlice';
import FinancierModal from './components/CounterParties/Financier/Modal/FinancierModal';
import PartnerModal from './components/CounterParties/Partner/Modal/PartnerModal';
import ObligorModal from './components/CounterParties/Obligor/Modal/ObligorModal';
import { useLocation } from 'react-router-dom';
import { closeAllModal } from './redux/slice/modalSlice';
import ModalMandate from './components/Mandates/ModalMandate/ModalMandate';
import ModalDeals from './components/Deals/ModalDeals/ModalDeals';
import ModalParticipations from './components/Participations/ModalParticipations/ModalParticipations';
import ModalPayements from './components/SegregatedAccounts/Payements/ModalPayements/ModalPayements';
import ModalNotes from './components/SegregatedAccounts/Notes/ModalNotes/ModalNotes';
import ModalDetailNotes from './components/SegregatedAccounts/Notes/ModalDetailNotes/ModalDetailNotes';
import FormCreateLimit from './components/Participations/ModalParticipations/FormCreateLimit/FormCreateLimit';
import FormCreateDealObligorLimit from './components/Deals/ModalDeals/LimitsList/FormCreateDealObligorLimit/FormCreateDealObligorLimit';
import ModalCofarcoTeamDetail from './components/CofarcoTeams/ModalCofarcoTeam/ModalCofarcoDetail';
import ModalCofarcoTeam from './components/CofarcoTeams/ModalCofarcoTeam/ModalCofarcoTeam';
import { initSocket } from './utils/laravel-socket';

initSocket();

const App = () => {
  const isLoading = useAppSelector((state) => state.loader.isLoading);
  const dispatch = useAppDispatch();
  const notification: any = useAppSelector((state) => state.notification);

  const { pathname } = useLocation();

  /// Modal state
  const modal = useAppSelector((state) => state.modal);

  const ref = React.useRef<LoadingBarRef>(null);

  useEffect(() => {
    if (isLoading) {
      ref.current!.staticStart();
    } else {
      ref.current!.complete();
    }
  }, [isLoading]);

  useEffect(() => {
    dispatch(closeAllModal());
  }, [pathname]);

  return (
    <>
      <LoadingBar ref={ref} color="#eb5d57 " height={3} />
      <ToastContainer
        limit={1}
        transition={Zoom}
        autoClose={1500}
        closeOnClick
      />
      {/* {AppRouter(routes)} */}
      <ModalGroup
        defaultFormValues={
          modal.currentModal?.type === 'group'
            ? modal.currentModal?.data
            : undefined
        }
        show={
          (modal.show &&
            modal.data.length > 0 &&
            modal.currentModal?.type === 'group') ??
          false
        }
        handleClose={() =>
          dispatch(
            updateModalAction({ data: undefined, isAdd: false, type: 'group' }),
          )
        }
      />
      <NotificationModal
        show={notification.show ?? false}
        body={notification.body ?? ''}
        title={notification.title ?? ''}
      />
      <ModalEnquiries
        defaultFormValues={
          modal.currentModal?.type === 'enquiry'
            ? modal.currentModal?.data
            : undefined
        }
        show={(modal.show && modal.currentModal?.type === 'enquiry') ?? false}
        handleClose={() => {
          dispatch(
            updateModalAction({
              data: undefined,
              isAdd: false,
              type: 'enquiry',
            }),
          );
          // dispatch(updateModalEnquiryAction({ data: undefined, show: false }));
        }}
      />
      <IndicationModal
        defaultFormValues={
          modal.currentModal?.type === 'indication'
            ? modal.currentModal?.data
            : undefined
        }
        show={modal.show && modal.currentModal?.type === 'indication'}
        onClickClient={(u) => {
          dispatch(
            updateModalAction({
              data: u,
              type: 'client',
              isAdd: true,
            }),
          );
        }}
        onClickPeople={(p) => {
          dispatch(updateModalAction({ data: p, isAdd: true, type: 'people' }));
        }}
        onClickEnquiry={(eq) => {
          dispatch(
            updateModalAction({
              data: {
                ...eq,
                client_name: eq?.mandate?.client.name,
              },
              isAdd: true,
              type: 'enquiry',
            }),
          );
        }}
        onCLickObligor={(l) => {
          dispatch(
            updateModalAction({ data: l, isAdd: true, type: 'obligor' }),
          );
        }}
      />
      <ClientModal
        onSubmittedForm={() => {
          dispatch(
            updateModalAction({
              data: undefined,
              type: 'client',
              isAdd: false,
            }),
          );
        }}
        defaultFormValues={
          modal.currentModal?.type === 'client'
            ? modal.currentModal?.data
            : undefined
        }
        show={modal.show && modal.currentModal?.type === 'client'}
        onClose={() => {
          dispatch(
            updateModalAction({
              data: undefined,
              isAdd: false,
              type: 'client',
            }),
          );
        }}
      />
      <FinancierModal
        onSubmittedForm={() => {
          dispatch(
            updateModalAction({
              data: undefined,
              type: 'financier',
              isAdd: false,
            }),
          );
        }}
        defaultFormValues={
          modal.currentModal?.type === 'financier'
            ? modal.currentModal?.data
            : undefined
        }
        show={modal.show && modal.currentModal?.type === 'financier'}
        onClose={() => {
          dispatch(
            updateModalAction({
              data: undefined,
              isAdd: false,
              type: 'financier',
            }),
          );
        }}
      />
      <UnderwriterModal
        onSubmittedForm={() => {
          dispatch(
            updateModalAction({
              data: undefined,
              isAdd: false,
              type: 'underwriter',
            }),
          );
        }}
        defaultFormValues={
          modal.currentModal?.type === 'underwriter'
            ? modal.currentModal?.data
            : undefined
        }
        show={modal.show && modal.currentModal?.type === 'underwriter'}
        onClose={() => {
          dispatch(
            updateModalAction({
              data: undefined,
              isAdd: false,
              type: 'underwriter',
            }),
          );
        }}
      />
      <PartnerModal
        onSubmittedForm={() => {
          dispatch(
            updateModalAction({
              data: undefined,
              type: 'partner',
              isAdd: false,
            }),
          );
        }}
        defaultFormValues={
          modal.currentModal?.type === 'partner'
            ? modal.currentModal?.data
            : undefined
        }
        show={modal.show && modal.currentModal?.type === 'partner'}
        onClose={() => {
          dispatch(
            updateModalAction({
              data: undefined,
              isAdd: false,
              type: 'partner',
            }),
          );
        }}
      />
      <ObligorModal
        onSubmittedForm={() => {
          dispatch(
            updateModalAction({
              data: undefined,
              type: 'obligor',
              isAdd: false,
            }),
          );
        }}
        defaultFormValues={
          modal.currentModal?.type === 'obligor'
            ? modal.currentModal?.data?.obligor
            : undefined
        }
        top_obligor_country_id={
          modal.currentModal?.data?.top_obligor_country_id
        }
        show={modal.show && modal.currentModal?.type === 'obligor'}
        onClose={() => {
          dispatch(
            updateModalAction({
              data: undefined,
              isAdd: false,
              type: 'obligor',
            }),
          );
        }}
      />
      <PeopleModal
        defaultFormValues={
          modal.currentModal?.type === 'people'
            ? modal.currentModal?.data
            : undefined
        }
        show={
          modal.show &&
          modal.data.length > 0 &&
          modal.currentModal?.type === 'people'
        }
        onClose={() => {
          dispatch(
            updateModalAction({
              data: undefined,
              isAdd: false,
              type: 'people',
            }),
          );
        }}
        onSubmittedForm={() => {
          dispatch(
            updateModalAction({
              data: undefined,
              isAdd: false,
              type: 'people',
            }),
          );
        }}
        dialogClassName="modal-90w"
      />
      <ModalLegalEntity
        defaultFormValues={
          modal.currentModal?.type === 'legal_entity'
            ? modal.currentModal?.data
              ? ({
                  ...modal.currentModal?.data,
                  group_name: modal.currentModal?.data?.group?.name,
                } as InputFormLegalEntity)
              : null
            : null
        }
        show={
          (modal.show &&
            modal.data.length > 0 &&
            modal.currentModal?.type === 'legal_entity') ??
          false
        }
        handleClose={() => {
          dispatch(
            updateModalAction({
              data: null,
              isAdd: false,
              type: 'legal_entity',
            }),
          );
        }}
      />
      <ModalMandate
        show={
          (modal.show &&
            modal.data.length > 0 &&
            modal.currentModal?.type === 'mandate') ??
          false
        }
        defaultFormValues={
          modal.currentModal?.type === 'mandate'
            ? modal.currentModal?.data
            : undefined
        }
        handleClose={() =>
          dispatch(
            updateModalAction({
              data: null,
              isAdd: false,
              type: 'mandate',
            }),
          )
        }
      />
      <ModalCofarcoTeamDetail
        show={
          (modal.show &&
            modal.data.length > 0 &&
            modal.currentModal?.type === 'cofarco_team') ??
          false
        }
        defaultFormValues={
          modal.currentModal?.type === 'cofarco_team'
            ? modal.currentModal?.data
            : undefined
        }
        handleClose={() =>
          dispatch(
            updateModalAction({
              data: null,
              isAdd: false,
              type: 'cofarco_team',
            }),
          )
        }
      />
      <ModalCofarcoTeam
        show={
          (modal.show &&
            modal.data.length > 0 &&
            modal.currentModal?.type === 'add_cofarco_team') ??
          false
        }
        defaultFormValues={
          modal.currentModal?.type === 'add_cofarco_team'
            ? modal.currentModal?.data
            : undefined
        }
        handleClose={() =>
          dispatch(
            updateModalAction({
              data: null,
              isAdd: false,
              type: 'add_cofarco_team',
            }),
          )
        }
      />
      <ModalDeals
        show={
          (modal.show &&
            modal.data.length > 0 &&
            modal.currentModal?.type === 'deals') ??
          false
        }
        defaultFormValues={
          modal.currentModal?.type === 'deals'
            ? modal.currentModal?.data
            : undefined
        }
        handleClose={() =>
          dispatch(
            updateModalAction({
              data: null,
              isAdd: false,
              type: 'deals',
            }),
          )
        }
      />
      <ModalParticipations
        show={
          (modal.show &&
            modal.data.length > 0 &&
            modal.currentModal?.type === 'participations') ??
          false
        }
        handleClose={() =>
          dispatch(
            updateModalAction({
              data: null,
              isAdd: false,
              type: 'participations',
            }),
          )
        }
        defaultFormValues={
          modal.currentModal?.type === 'participations'
            ? modal.currentModal?.data
            : undefined
        }
      />
      <ModalPayements
        show={
          (modal.show &&
            modal.data.length > 0 &&
            modal.currentModal?.type === 'payements') ??
          false
        }
        handleClose={() =>
          dispatch(
            updateModalAction({
              data: null,
              isAdd: false,
              type: 'payements',
            }),
          )
        }
        defaultFormValues={
          modal.currentModal?.type === 'payements'
            ? modal.currentModal?.data
            : undefined
        }
      />
      <ModalNotes
        show={
          (modal.show &&
            modal.data.length > 0 &&
            modal.currentModal?.type === 'notes') ??
          false
        }
        handleClose={() =>
          dispatch(
            updateModalAction({
              data: null,
              isAdd: false,
              type: 'notes',
            }),
          )
        }
        defaultFormValues={
          modal.currentModal?.type === 'notes'
            ? modal.currentModal?.data
            : undefined
        }
      />
      <ModalDetailNotes
        show={
          (modal.show &&
            modal.data.length > 0 &&
            modal.currentModal?.type === 'notes_details') ??
          false
        }
        handleClose={() =>
          dispatch(
            updateModalAction({
              data: null,
              isAdd: false,
              type: 'notes_details',
            }),
          )
        }
        defaultFormValues={
          modal.currentModal?.type === 'notes_details'
            ? modal.currentModal?.data
            : undefined
        }
      />
      <FormCreateLimit
        show={
          (modal.show &&
            modal.data.length > 0 &&
            modal.currentModal?.type === 'limits') ??
          false
        }
        handleClose={() =>
          dispatch(
            updateModalAction({
              data: null,
              isAdd: false,
              type: 'limits',
            }),
          )
        }
        defaultFormValues={
          modal.currentModal?.type === 'limits'
            ? modal.currentModal?.data
            : undefined
        }
      />
      <FormCreateDealObligorLimit
        show={
          (modal.show &&
            modal.data.length > 0 &&
            modal.currentModal?.type === 'deal_obligor_limit') ??
          false
        }
        handleClose={() =>
          dispatch(
            updateModalAction({
              data: null,
              isAdd: false,
              type: 'deal_obligor_limit',
            }),
          )
        }
        defaultFormValues={
          modal.currentModal?.type === 'deal_obligor_limit'
            ? modal.currentModal?.data
            : undefined
        }
      />

      <AppRouter />
    </>
  );
};

export default App;
