import { useCallback, useMemo, useState } from 'react';
import { ColumnsType } from 'antd/es/table';

import {
  Indication,
  IndicationFilterType,
} from '../../@types/Indication/indication';
import { SortType } from '../../@types';
import useStateAsync from '../../utils/useStateCallback';
import { useAppDispatch } from '../../hooks/redux';
import { Collapse } from 'bootstrap';
import customTableColumn from '../../utils/tableColumn';
import classes from './IndicationTableContent.module.scss';
import { Menu } from '../../@types/common-types';
import { isCan } from '../../utils/helper-function';
import { ReactComponent as Edit } from '../../assets/img/Edit.svg';
import { ReactComponent as Delete } from '../../assets/img/Delete.svg';
import FlagItem from '../FlagItem/FlagItem';
import { useGetCountryQuery } from '../../redux/api/ws/databases/groups';
import { useListTermsQuery } from '../../redux/api/ws/terms/get';
import toCamelCase from '../../utils/toCamelCase';
import { DropDownOptionType } from '../common/DropDown/DropdownSelect';
import { omit } from 'lodash';
import moment from 'moment';
import { useDeleteIndicationMutation } from '../../redux/api/ws/indication/post';
import { setIndicationFilter } from '../../redux/slice/indicationFIlterSlice';
import { updateModalAction } from '../../redux/slice/modalSlice';

const columns: string[] = [
  'enquiry_ID',
  'indication_ID',
  'indication',
  'underwriter_ID',
  'top_obligor',
  'country',
  'enquery_coverage_type',
  'contacted_date',
  'indication_date',
  'action',
];

const ONE_MINUTE = 60;

const useIndication = () => {
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [indication, setIndication] = useState<Indication | null>();
  const [filterData, setFilterData] = useStateAsync<IndicationFilterType>({});
  const [sortData, setSortData] = useState<SortType[]>([]);
  const [currentColumns, setCurrentColumns] = useState<string[]>(columns);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const defaultFilter: IndicationFilterType = {
    team: {
      property: 'team',
      operator: 'in',
      value: ['gva', 'ldn', 'par'],
    },
    result_type: {
      property: 'result_type',
      operator: 'in',
      value: ['eoi', 'nbi', 'nty', 'nr', 'pending'],
    },
  };

  const { data: countries } = useGetCountryQuery();
  const { data: terms } = useListTermsQuery(undefined, {
    refetchOnMountOrArgChange: false,
  });

  const [deleteIndication] = useDeleteIndicationMutation();

  const dispatch = useAppDispatch();

  const onDeleteElement = (row: Indication) => {
    setIndication(row);
    setShowDeleteModal(true);
  };

  const deleteIndicationCallback = useCallback(() => {
    setDeleteLoading(true);
    if (!indication?.id) return;
    deleteIndication({ id: indication.id }).finally(() => {
      setDeleteLoading(false);
      setShowDeleteModal(false);
      setIndication(undefined);
    });
  }, [indication]);

  const onEditElement = (c: Indication) => {
    dispatch(
      updateModalAction({
        data: c,
        isAdd: true,
        type: 'indication',
      }),
    );
  };
  const onAddElement = () => {
    dispatch(
      updateModalAction({
        data: undefined,
        isAdd: true,
        type: 'indication',
      }),
    );
  };

  const getFilter = useCallback((filter: IndicationFilterType) => {
    return Object.values({
      ...omit(
        filter,
        Object.keys(filter).filter((k) => k.endsWith('Key')),
      ),
    });
  }, []);

  const toggleFilterCollapse = () => {
    setFilterData(defaultFilter);
    dispatch(setIndicationFilter(defaultFilter));
    const collapseSearch = document.getElementById('collapseSearchIndication')!;
    const collapse = new Collapse(collapseSearch, { toggle: true });
    collapse.toggle();
  };

  const updateFilter = (
    key: keyof IndicationFilterType,
    v?: DropDownOptionType | null | string[],
    prevValue?: IndicationFilterType,
    callback?: (value: IndicationFilterType) => void,
  ) => {
    let newFilter = { ...(prevValue ?? filterData) };
    if (Array.isArray(v)) {
      const valueArray = v as string[];
      if (valueArray.length === 0) {
        delete newFilter[key];
      } else {
        newFilter = {
          ...newFilter,
          [key]: {
            property: key,
            value: valueArray,
            operator: 'in',
          },
        };
      }
    } else {
      const value = v as string | null;
      if (!value || value?.trim() === '') {
        delete newFilter[key];
      } else {
        const operator = '=';
        newFilter = {
          ...newFilter,
          [key]: {
            property: key,
            value: value,
            operator,
          },
        };
      }
    }
    setFilterData(newFilter).then((v) => {
      dispatch(setIndicationFilter(v));
      if (callback) callback(v);
    });
  };

  const toColumn = (key: string) => {
    return customTableColumn<Indication>({
      minLength: 2,
      columns: columns,
      currentColums: currentColumns,
      setCurrentColumn: (cu) => {
        setCurrentColumns(cu);
      },
      onAsc: () => {
        setSortData([
          {
            property: key === 'legal_entity' ? 'legalEntityId' : key,
            direction: 'ASC',
          },
        ]);
      },
      onDesc: () => {
        setSortData([
          {
            property: key === 'legal_entity' ? 'legalEntityId' : key,
            direction: 'DESC',
          },
        ]);
      },
    });
  };

  const onHeaderCell = () => ({
    onClick: () => {},
    className: classes.headerCustom,
  });

  const indicationsColumns: ColumnsType<Indication> = useMemo(
    () =>
      [
        {
          title: 'ENQUIRY ID',
          dataIndex: 'enquiry_number',
          key: 'enquiry_ID',
          defaultSortOrder: 'ascend',
          width: 100,
          ...toColumn('enquiry_number'),
          onHeaderCell: onHeaderCell,
          render: (value: any) => <b>{value ?? ''}</b>,
        },

        {
          title: 'INDICATION ID',
          dataIndex: 'number',
          key: 'indication_ID',
          defaultSortOrder: 'ascend',
          width: 100,
          onHeaderCell: onHeaderCell,
          ...toColumn('number'),
          render: (value: any, record: Indication) => {
            const isUpdated = moment(record.updated_at).isAfter(
              moment(record.created_at),
            );
            const isAfterOneMinute =
              moment().diff(moment(record.created_at), 'seconds') > ONE_MINUTE;
            return (
              <b>
                {value
                  ? value
                  : isUpdated || isAfterOneMinute
                    ? '-'
                    : 'New indication'}
              </b>
            );
          },
        },
        {
          title: 'INDICATION',
          dataIndex: 'indication_type',
          key: 'indication',
          defaultSortOrder: 'ascend',
          width: 120,
          onHeaderCell: onHeaderCell,
          ...toColumn('indication_type'),
          render: (value: any) => {
            // return <b>{(terms ? terms.term : []).find((t) => t.key === value)?.value ?? ""}</b>
            return (
              <div className="d-flex justify-content-center">
                <div
                  className={`${classes[value]} flex flex-fill justify-content-center`}
                  style={{ maxWidth: 100 }}
                >
                  <b> {(value ?? '') as string}</b>
                </div>
              </div>
            );
          },
        },
        {
          title: 'UNDERWRITER ID ',
          dataIndex: 'underwriter_name',
          key: 'underwriter_ID',
          defaultSortOrder: 'ascend',
          width: 180,
          ...toColumn('underwriter_name'),
          onHeaderCell: onHeaderCell,
          render: (value: any) => <b>{value ?? ''}</b>,
        },
        {
          title: 'TOP OBLIGOR',
          dataIndex: 'obligor_name',
          key: 'top_obligor',
          width: 120,
          defaultSortOrder: 'ascend',
          ...toColumn('obligor_name'),
          onHeaderCell: onHeaderCell,
          render: (value: any) => <b>{value ?? ''}</b>,
        },

        {
          title: 'COUNTRY',
          dataIndex: 'country_id',
          key: 'country',
          width: 150,
          defaultSortOrder: 'ascend',
          ...toColumn('country_name'),
          onHeaderCell: onHeaderCell,
          render: (value: any) => {
            const region = countries?.country.find((f) => f.key === value) ?? {
              key: '',
              name: '',
            };
            return (
              region && (
                <FlagItem code={region?.key} region_name={region?.name} />
              )
            );
          },
        },

        {
          title: 'ENQUIRY COVERAGE TYPE',
          dataIndex: 'enquiry_type',
          key: 'enquery_coverage_type',
          defaultSortOrder: 'ascend',
          width: 200,
          ...toColumn('enquiry_type'),
          onHeaderCell: onHeaderCell,
          render: (value: any) => (
            <b className={`${value ? classes.aqt : ''}`}>
              {toCamelCase(
                (terms ? terms.term : []).find(
                  (t) => t.key === value && t.type === 'dealType',
                )?.value ?? '',
                0,
                ' ',
              )}
            </b>
          ),
        },

        {
          title: 'CONTACTED DATE',
          dataIndex: 'contacted_date',
          key: 'contacted_date',
          defaultSortOrder: 'ascend',
          ...toColumn('contacted_date'),
          onHeaderCell: onHeaderCell,
          width: 100,
          render: (value: any) => (
            <b>{value ? moment(value).format('DD/MM/YYYY') : ''}</b>
          ),
        },
        {
          title: 'INDICATION DATE',
          dataIndex: 'indication_date',
          key: 'indication_date',
          defaultSortOrder: 'ascend',
          ...toColumn('indication_date'),
          onHeaderCell: onHeaderCell,
          width: 100,
          render: (value: any) => (
            <b>{value ? moment(value).format('DD/MM/YYYY') : ''}</b>
          ),
        },
        {
          title: '',
          key: 'action',
          className: classes.actionRow,
          hidden:
            !isCan('write', Menu.indication) &&
            !isCan('delete', Menu.indication),
          width: 100,
          onCell: () => {
            return {
              onClick: (event: any) => {
                event.stopPropagation();
              },
            };
          },
          render: (_: any, record: any) => (
            <div className="d-flex">
              {isCan('write', Menu.indication) && (
                <span
                  className={`mx-1 rounded-icon  ${classes.action}`}
                  onClick={() => onEditElement(record)}
                >
                  <Edit height="30" />
                </span>
              )}
              {isCan('delete', Menu.indication) && (
                <span
                  className={`mx-1 rounded-icon ${classes.action}`}
                  onClick={() => onDeleteElement(record)}
                >
                  <Delete height="30" />
                </span>
              )}
            </div>
          ),
        },
      ].filter((el) => !el.hidden) as ColumnsType<Indication>,
    [terms, countries, currentColumns, columns],
  );

  return {
    indication,
    indicationsColumns,
    currentColumns,
    filterData,
    sortData,
    defaultFilter,
    showDeleteModal,
    deleteLoading,
    onAddElement,
    setIndication,
    setShowDeleteModal,
    toggleFilterCollapse,
    updateFilter,
    getFilter,
    deleteIndicationCallback,
    onEditElement,
  };
};

export default useIndication;
