import React from 'react';
import { Form } from 'react-bootstrap';
import { ReactComponent as Warning } from '../../../../../assets/img/Warning.svg';

type Iprops = {
  register: any;
  errors: any;
  paddingTop?: string;
};
export default function OwnerShip(props: Iprops) {
  return (
    <div
      className='d-flex align-items-baseline'
      style={{ paddingTop: props.paddingTop ?? '1.2rem' }}
    >
      <Form.Label className='radio'>
        Ownership <span className='span-required'>*</span>
      </Form.Label>
      <div style={{ flexDirection: 'column', width: '100%' }} className='mt-1'>
        <div className='d-flex' style={{ maxHeight: '1.5rem' }}>
          <div className='mb-3'>
            <Form.Check
              type='radio'
              id='Administration'
              label='Administration'
              value={'administration'}
              {...props.register}
            />
          </div>
          <div className='mb-3 '>
            <Form.Check
              type='radio'
              id='Private'
              label='Private'
              value={'private'}
              {...props.register}
            />
          </div>
          <div className='mb-3'>
            <Form.Check
              type='radio'
              id='Public'
              label='Public'
              value={'public'}
              {...props.register}
            />
          </div>
          <div className='mb-3'>
            <Form.Check
              type='radio'
              id='State-Owned'
              label='State Owned'
              value={'state_owned'}
              {...props.register}
            />
          </div>
        </div>
        {props.errors.ownership && (
          <span style={{ justifyContent: 'start', color: 'red' }}>
            <i>
              <Warning />
            </i>
            Required
          </span>
        )}
      </div>
    </div>
  );
}
